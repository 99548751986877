/* eslint-disable */
define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat','../lib/backbone-1.1.2', './OverviewPageHtml', './StatisticsView', './ShortcutBaseView', '../helper/iosapp', '../helper/shortcuts/shortcuts', './Autocomplete', '../helper/externalParameters', '../model/TrackedPaperworkInfo',
	'../model/projects', '../model/currentProject', '../model/completions', '../model/currentInspection', '../model/user', '../helper/watchdog', '../helper/offlineQueue', '../model/currentDefects'
], function (
	$, _, Backbone, Template, StatisticsView, ShortcutBaseView, iosapp, shortcuts, Autocomplete, externalParameters, TrackedPaperworkInfo,
	projects, currentProject, completions, currentInspection, user, watchdog, offlineQueue, currentDefects
) {

	return ShortcutBaseView.extend({

		id: 'overview',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		events: {
			// 'change .projects':                         'changeProject',
			'change #chooseProject':                    'changeProject',
			'focus #chooseProject':                     'focusProjectSelector',
			'blur #chooseProject':                     'unFocusProjectSelector',
			'vclick .actionstrigger':                   'triggerActionsMenu',
			'vclick .editProjectInfo':                  'setProjectInfoEditMode',
			'vclick .editHeadline:not(.save)':          'editHeadline',
			'vclick .editHeadline.save':                'saveHeadline',
			'vclick .editRow:not(.save)':               'editRow',
			'vclick .editRow.save':                     'saveRow',
			'vclick .removeRow':                        'removeRow',
			'vclick .addRow':                           'addRow',
			'vclick .uploadImage':                      'uploadImageClick',
			'vclick .syncplans':                        'syncplans',
			'vclick .syncattachments':                  'syncattachments',
			'change .imageContainer input':             'uploadImage',
			'vclick .reload': function () {
				// $('body').data('cancelbeforeunload', true);
				// window.location.reload();
				// _.defer(function () { $('body').data('cancelbeforeunload', false); });
			},
			'vclick .backup': function (e) {
				// e.preventDefault();
				// e.stopPropagation();
				// this.actionsmenu.popup({
				// 	afterclose: _.bind(function () {
				// 		this.actionsmenu.popup({ afterclose: null });
				//
				// 		this.$backupDialog.find('.backup-intro').text(user.translate('overview.backup.info').replace('%s', currentProject.get('name')));
				// 		this.$backupNotificationEmail.val(currentProject.get('email'));
				//
				// 		this.$backupDialog.show().popup('open').trigger('create');
				//
				// 		var $buttons = this.$backupDialog.find('button');
				// 		$buttons.one('vclick', _.bind(function (e) {
				// 			e.preventDefault();
				// 			e.stopPropagation();
				// 			$buttons.off('vclick');
				// 			this.$backupDialog.popup('close');
				// 			if ($(e.currentTarget).hasClass('ok')) {
				// 				$.post( '/api/request-backup', { project: currentProject.id, email: this.$backupNotificationEmail.val() })
				// 				.done(function () { window.alertPopup(user.translate('overview.backup.queue.success')); })
				// 				.fail(function () { window.alertPopup(user.translate('overview.backup.queue.fail')); });
				// 			}
				// 		}, this));
				//
				// 	}, this)
				// }).popup('close');
			},
			'vclick .logout': function () {
				//copied from SidePanel.js
				// localStorage.removeItem('location', location.hash);
				// if (iosapp.appavailable) {
				// 	location.href =  '/logout?iosapp=true';
				// } else if (window.location !== window.parent.location) {
				// 	location.href =  '/logout?iframe=true';
				// } else {
				// 	location.href =  '/logout';
				// }
			},
			'vclick .projectmanager': function () {
				// open( '/projectmanager/?from=' + encodeURIComponent(window.location), '_blank');
			},
			'vclick .download-statistics': function () {
				// open( '/api/project-stats', '_blank');
			},
			// 'vclick .duplicate-test': function () {
			// 	var projectId = 'SCHUL';
			// 	$.ajax({
			// 		url:  '/api/duplicate-project',
			// 		type: 'POST',
			// 		data: {
			// 			customerId: 'normal1',
			// 			projectId: projectId,
			// 			duplicateProjectId: projectId + '_DUPLICATE_TEST5',
			// 			duplicateProjectName: 'Duplicate Test'
			// 		}
			// 	}).done(function () {
			// 		alert('SUCCESS');
			// 	}).fail(function() {
			// 		alert('FAIL');
			// 	});
			// },
			'vclick .allDefects': 'gotoDefectList',
			'vclick .openDefects': function (e) {
				// e.preventDefault();
				// e.stopPropagation();
				// currentDefects.resetFilter();
				// currentDefects.elementFilter.status = "pending-subcontractor-pending";
				// // currentDefects.elementFilter.status = "pending";
				// // currentDefects.elementFilter.extraStatusAN = "G";
				// currentDefects.groupBy
				// Backbone.history.navigate('defects/' + currentProject.id, { trigger: true });
			},
			'vclick .noSubcontractor': function (e) {
				// e.preventDefault();
				// e.stopPropagation();
				// currentDefects.resetFilter();
				// currentDefects.elementFilter.subcontractor = "null";
				// Backbone.history.navigate('defects/' + currentProject.id, { trigger: true });
			},
			'vclick .afterDeadline1-action': function (e) {
				// e.preventDefault();
				// e.stopPropagation();
				// currentDefects.resetFilter();
				// currentDefects.elementFilter.escalationLevels = ['one', 'two', 'three'];
				// currentDefects.elementFilter.deadlineFilters = ['expired'];
				// currentDefects.elementFilter.status = "pending";
				// Backbone.history.navigate('defects/' + currentProject.id, { trigger: true });
				// Backbone.Events.trigger("selectAllDefects");
			},
			'vclick .afterDeadline2-action': function (e) {
				// e.preventDefault();
				// e.stopPropagation();
				// currentDefects.resetFilter();
				// currentDefects.elementFilter.escalationLevels = ['readyfortermination'];
				// currentDefects.elementFilter.deadlineFilters = ['expired'];
				// currentDefects.elementFilter.status = "pending";
				// Backbone.history.navigate('defects/' + currentProject.id, { trigger: true });
				// Backbone.Events.trigger("selectAllDefects");
			},
			'vclick .newDefect':                 function (e) {
				// e.preventDefault();
				// e.stopPropagation();
				// Backbone.history.navigate('defect', { trigger: true });
			},
			'vclick .subcontractor-all-changed-action': function (e) {
				// e.preventDefault();
				// e.stopPropagation();
				// currentDefects.resetFilter();
				// currentDefects.elementFilter.allSubChanges = true;
				// Backbone.history.navigate('defects/' + currentProject.id, { trigger: true });
			},
			'vclick .solved-rejected': function (e) {
				// e.preventDefault();
				// e.stopPropagation();
				// currentDefects.resetFilter();
				// currentDefects.elementFilter.principalStatus = "rejected";
				// currentDefects.elementFilter.status = "fixed";
				// currentDefects.elementFilter.extraStatus = "P";
				// Backbone.history.navigate('defects/' + currentProject.id, { trigger: true });
			},
			'vclick .unsentOrders': function (e) {
				// e.preventDefault();
				// e.stopPropagation();
				// Backbone.history.navigate('subcontractors/' + currentProject.id, { trigger: true });
				// Backbone.Events.trigger("sendAllOrders");
			},
			'vclick .defectList': 'gotoDefectList',
			'vclick .allRequests': 'gotoRequestsList'
		},
		
		shortcuts: {
			// 'num': function(num) {
			// 	var project = projects.at(num);
			// 	if (project && project.id) {
			// 		$('select.projects').val(project.id);
			// 		this.changeProject();
			// 	}
			// }
			'focusinput1': function () {
				// $('#chooseProject').focus();
			}
		},

		projectInfoEditMode: false,

		initialize: function (options) {
			// this.router = options.router;
			// if(user.isSubcontractor()) {
			// 	this.trackedPaperworkInfo = new TrackedPaperworkInfo();
			// }
			// _.bindAll(this, 'resize');
			// this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			// // this.statsView = new StatisticsView({ el: this.$el.find('.projectstatscontainer').children('.content'), showNumbers: false });
			// if (!user.isAdmin() || window.oxmobile) {
			// 	this.$('.backup').remove();
			// }
			//
			// this.header           = this.$('[data-role="header"]');
			// this.footer           = this.$('[data-role="footer"]');
			// this.menutrigger      = this.$('.menutrigger');
			// this.projects         = this.$('.projects');
			// this.chooseProject         = this.$('#chooseProject');
			// this.actionsmenu      = this.$('.actionsmenu');
			// this.optionsContainer = this.$('.actionstrigger');
			// this.$syncplans       = this.$('.syncplans');
			// this.$syncattachments = this.$('.syncattachments');
			// this.editProjectInfo  = this.actionsmenu.find('.editProjectInfo');
			//
			// this.projectInfo               = this.$('.projectinfo');
			// this.projectInfoHeadline       = this.projectInfo.find('.headline');
			// this.projectInfoImageContainer = this.projectInfo.find('.imageContainer');
			// this.projectInfoImage          = this.projectInfo.find('.image');
			// this.projectInfoImageInput     = this.projectInfoImageContainer.find('input');
			// this.projectinfoTable          = this.projectInfo.find('table');
			// this.$subcontractorActions     = this.$('.subcontractorActions');
			// this.$defectActions            = this.$('.defectActions');
			// this.$quickAccess              = this.$('.quickAccess');
			// this.$projectStats             = this.$('.projectstatscontainer').hide();
			// this.$backupDialog             = this.$('.backup-dialog');
			// this.$backupNotificationEmail  = this.$('.backup-notification-email');
			// this.$news 					   = this.$('#news');
			// this.$projectManagerButtons	   = this.$('.projectManagerButtons');
			// if (user.isReadonly()) {
			// 	this.editProjectInfo.remove();
			// }
			// if(iosapp.appavailable)
			// {
			// 	this.$projectManagerButtons.hide();
			// }
			//
			// this.$groupNote = this.$('.groupNote');
			// this.$principalGroupNote = this.$('.principalGroupNote');
			// this.$button = this.$('.newDefectContainer');
			// //this.$button = $('<div class="ui-btn-inline"><button style="max-width: 200px" data-shortcut="newDefect" class="newDefect newDefectOverviewButton ui-btn ui-btn-inline  ui-btn-icon-left ui-icon-plus ui-corner-all">' + user.translate('defects.newdefect') + '</button></div>').insertAfter(this.$('.defectActions .headline'));
			// this.$button.toggle(!user.isReadonly());
			// this.hideActions =(user.isReadonly() || user.isPrincipal());
			// if (this.hideActions) {
			// 	this.$defectActions && this.$defectActions.remove();
			// }
			// if (user.isReadonly()) {
			// 	this.$quickAccess && this.$quickAccess.find('.hideReadonly').remove();
			// }
			// if (user.isPrincipal()) {
			// 	this.$quickAccess && this.$quickAccess.find('.hidePrincipal').remove();
			// }
			//
			// if (!user.isSubcontractor()) {
			// 	this.$subcontractorActions && this.$subcontractorActions.remove();
			// }
			//
			// this.optionsContainer.hide();
			// this.listenTo(user, 'change', function () {
			// 	this.optionsContainer.toggle(user.isAdmin() && !window.oxmobile);
			// });
			//
			// this.listenTo(watchdog, 'change', _.bind(this.watchdogChanged, this));
			// this.watchdogChanged();
			//
			// this.$el.on('pageshow', _.bind(function () {
			// 	this.resize();
			// 	if (iosapp.appavailable && iosapp.functionavailable('appLoggedInAndOverviewLoaded')) {
			// 		iosapp.appLoggedInAndOverviewLoaded();
			// 	}
			// }, this));
			// $(window).on('throttledresize', this.resize);
			// new Autocomplete({
			// 	input: this.chooseProject,
			// 	lookup: function (str) {
			// 		var result;
			// 		result = completions.projectLookup(str);
			// 		return result;
			// 	},
			// 	toggleButton: true
			// }).render();
			// this.$('.autocomplete').css('max-height', ($(window).outerHeight() * 0.6) + 'px');
		},

		gotoDefectList: function(e) {
			// e && e.preventDefault();
			// e && e.stopPropagation();
			// currentDefects.resetFilter();
			// window.localStorage && window.localStorage.removeItem('defects_filter');
			// Backbone.history.navigate('defects/' + currentProject.id, { trigger: true });
		},
		
		gotoRequestsList : function (e) {
			// e && e.preventDefault() && e.stopPropagation();
			// this.router.navigate('documents/' + currentProject.id, { trigger: true });
		},
		
		watchdogChanged: function() {
			// this.updatePlansInSync();
			// this.updateAttachmentsInSync();
		},

		updatePlansInSync: function() {
			// var plansinsync = watchdog.get('plansinsync');
			// if (plansinsync != null && plansinsync.value != null) {
			// 	this.$syncplans.toggleClass('needssync', !plansinsync.value);
			// }
			//
			// var showicon = (plansinsync != null && typeof currentProject.id !== 'undefined' && plansinsync.project == currentProject.id && plansinsync.value != null);
			// if (!showicon) {
			// 	this.$syncplans.removeClass('needssync');
			// 	this.$syncplans.addClass('dontshowicon');
			// } else {
			// 	this.$syncplans.removeClass('dontshowicon');
			// }
		},
		
		updateAttachmentsInSync: function() {
			// var attachmentsinsync = watchdog.get('attachmentsinsync');
			// if (attachmentsinsync != null && attachmentsinsync.value != null) {
			// 	this.$syncattachments.toggleClass('needssync', !attachmentsinsync.value);
			// }
			//
			// var showicon = (attachmentsinsync != null && typeof currentProject.id !== 'undefined' && attachmentsinsync.project == currentProject.id && attachmentsinsync.value != null);
			// if (!showicon) {
			// 	this.$syncattachments.removeClass('needssync');
			// 	this.$syncattachments.addClass('dontshowicon');
			// } else {
			// 	this.$syncattachments.removeClass('dontshowicon');
			// }
		},
		
		remove: function() {
			// $(window).off('throttledresize', this.resize);
			// // Backbone.View.prototype.remove.apply(this.statsView, arguments);
			// Backbone.View.prototype.remove.apply(this, arguments);
		},

		render: function () {
			// this.$('.projectbuttons .syncplans'      ).toggle(iosapp.appavailable && iosapp.functionavailable('syncplans')   && !currentProject.isNew());
			// this.$('.projectbuttons .syncattachments').toggle(iosapp.appavailable && iosapp.functionavailable('attachments') && !currentProject.isNew());
			//
			// if ( !!(user.get('settings') && !!user.get('settings').hideNews)) {
			// 	this.$('#news').remove();
			// }
			//
			// var defectTypeName = "";
			// if(currentInspection.id && currentInspection.getType()) {
			// 	var defectType = _.find(currentProject.get('types'), function(el) {
			// 		return el.defectTypeId === currentInspection.getType().defectType;
			// 	}.bind(this))
			// 	defectTypeName = defectType && defectType.importExportLabel || "";
			// }
			// defectTypeName = defectTypeName != "" ? defectTypeName : user.translate('defects.newdefect');
			// $('.quickAccess .newDefect').text(defectTypeName);
			//
			// $('#chooseProject').parent().toggle(!externalParameters.isForceCustomerProject());
			//
			// this.projects.empty();
			// var projectSelected = !currentProject.isNew();
			//
			// //if (!(user.isSubcontractor() && projects.length === 1)) {
			// 	if(projectSelected) {
			// 		// $('<option>').text(user.translate('overview.choose')).val(null).appendTo(this.projects);
			// 		this.chooseProject.val(currentProject.get('name'));
			// 		this.chooseProject.attr('value', currentProject.get('id'));
			// 	} else {
			// 		// $('<option>').text(user.translate('overview.choose2')).val(null).appendTo(this.projects);
			// 		this.chooseProject.val(user.translate('overview.choose2'));
			// 		this.chooseProject.attr('value', '');
			// 	}
			// //}
			// if (currentProject.isNew()) {
			// 	this.menutrigger.hide();
			// }

			// var groupNoteText = user.translate('info.groupNote').replace('%', currentProject.get('groupName'));
			// this.$groupNote.text(groupNoteText);
			// this.$principalGroupNote.text(groupNoteText);
			// this.$groupNote.toggle(currentProject.get('groupFilterActive') && !user.isPrincipal());
			// this.$principalGroupNote.toggle(currentProject.get('groupFilterActive') && user.isPrincipal());
			//
			// projects.each(function (project) {
			// 	if(!project.get("isArchived")){
			// 	 	$('<option>').text(project.get('name')).val(project.id).appendTo(this.projects);
			// 	}
			// }, this);

			// if (!user.isFullAdmin() || window.oxmobile) {
			// 		this.$('.projectmanager').remove();
			// 	if(!user.isAdmin() || window.oxmobile) {
			// 		this.$('.download-statistics').remove();
			// 	}
			// }
			// this.$('.no-project-selected').toggle(!projectSelected);
			// if (projectSelected) {
			// 	this.$news.hide();
			// 	this.projects.val(currentProject.id);
			// 	this.menutrigger.show();
			//
			// 	this.renderProjectInfo();
			// 	this.projectInfo.show();
			// 	this.$defectActions.show();
			// 	if (!user.isSubcontractor()) {
			// 		this.$quickAccess.show();
			// 	} else {
			// 		this.$quickAccess.hide();
			// 	}
			// 	//this.$groupNote.show();  //show is above
			// 	//this.$principalGroupNote.show();  //show is above
			//
			// 	//this.menutrigger.click();
			//
			// 	this.optionsContainer.show();
			// } else {
			// 	this.$news.show();
			// 	this.resize();
			// 	this.projects.val(null);
			// 	this.projectInfo.hide();
			// 	this.$defectActions.hide();
			// 	this.$quickAccess.hide();
			// 	this.$groupNote.hide();
			// 	this.$principalGroupNote.hide();
			// 	this.optionsContainer.hide();
			//
			// 	if (watchdog.isConnected()) {
			// 		offlineQueue.queue.getSize(function (size) {
			// 			if (size > 0) {
			// 				offlineQueue.queue.getProject(function (queueProject) {
			// 					Backbone.history.navigate('overview/' + window.encodeURIComponent(queueProject), { trigger: true });
			// 					watchdog.check(); // for updating offline stuff synchronization status (ios app)
			// 				}.bind(this));
			// 			}
			// 		}.bind(this));
			// 	}
			// }
			// this.projects.selectmenu('refresh');
			// this.updateProjectInfoEditButton();

			// var showStats = user.get('settings') && !!user.get('settings').showStats && !currentProject.isNew() && !(user.isPrincipal() && user.isReadonly());
			// this.$projectStats.toggle(showStats);
			// if (showStats) {
			// 	this.statsView.render();
			// }
			
			// if (this.trackedPaperworkInfo && this.trackedPaperworkInfo.get('downloaded')) {
			// 	var amount = _.filter(this.trackedPaperworkInfo.get('downloaded'), function (e) {
			// 		return !e;
			// 	});
			// 	$('#unreadRequestsNumber').text(amount.length);
			// 	$('#unreadRequestsNumber').addClass('highlight');
			// } else {
			// 	$('#unreadRequestsNumber').text('0');
			// 	$('#unreadRequestsNumber').removeClass('highlight');
			// }
			//
			// return this;
		},
		
		renderProjectInfo: function () {
			// var info = currentProject.get('info') || {};
			// this.renderHeadline(this.projectInfoEditMode && !info.headline ? user.translate('overview.edit.title') : (info.headline || ''));
			// this.renderImage(this.projectInfoEditMode && !info.image ?  '/images/dummy-project-picture.svg' : info.image);
			// this.renderTable();
			// this.projectInfo.toggleClass('editMode', this.projectInfoEditMode);
			// if (!this.hideActions) {
			// 	this.renderActions();
			// }
		},

		renderHeadline: function (headline, edit) {
			// this.projectInfoHeadline.contents().filter(function() { return this.nodeType == Node.TEXT_NODE; }).remove();
			// if (edit) {
			// 	var $input = $('<input type="text" class="ui-input-text" data-inset="true">').val(headline);
			// 	this.projectInfoHeadline.append($input).enhanceWithin();
			// 	this.projectInfoHeadline.children('.editHeadline').addClass('save').text('OK');
			// } else {
			// 	this.projectInfoHeadline.find('.ui-input-text').remove();
			// 	this.projectInfoHeadline.append(document.createTextNode(headline));
			// 	this.projectInfoHeadline.children('.editHeadline').removeClass('save').text(user.translate('overview.edit.title'));
			// }
		},

		renderImage: function (image) {
			// this.projectInfoImage.attr('src', image || '').toggle(!!image);
		},

		renderTable: function () {
			// var info = currentProject.get('info');
			// var table = info && info.table || [];
			// this.projectinfoTable.empty().append('<tr class="lastRow"><td></td><td><button class="edit addRow ui-btn ui-btn-inline">' + user.translate('overview.edit.add') + '</button></td></tr>');
			// for (var i = 0; i < table.length; i++) {
			// 	this.renderRow(i);
			// }
		},

		renderRow: function (i, edit) {
			// var $row = $(this.projectinfoTable.find('tr[data-index="' + i + '"]').get(0) || $('<tr>').attr('data-index', i).appendTo(this.projectinfoTable).insertBefore('tr.lastRow')).empty();
			// var info = currentProject.get('info');
			// var columns = info.table[i];
			// for (var j = 0; j < columns.length; j++) {
			// 	var column = columns[j];
			// 	var $td = $('<td>');
			// 	if (edit) {
			// 		var $input = $('<input type="text" class="ui-input-text" data-inset="true">').val(column);
			// 		$td.append($input).enhanceWithin();
			// 	} else {
			// 		$td.text(column);
			// 	}
			// 	if (j == columns.length - 1) {
			// 		$('<button class="edit editRow ui-btn ui-btn-inline"></button>').text(edit ? 'OK' : user.translate('overview.edit.text')).toggleClass('save', !!edit).appendTo($td);
			// 		if (edit) {
			// 			$('<button class="edit removeRow ui-btn ui-btn-inline"></button>').text(user.translate('overview.edit.remove')).appendTo($td);
			// 		}
			// 	}
			// 	$td.appendTo($row);
			// }
			// $($row.find('input').get(0)).focus();
		},

		renderActions: function(){
			// currentDefects.resetFilter();
			// currentDefects.elementFilter.subcontractor = "null";
			// this.$(".noSubLbl-overview-filter").text(currentDefects.filtered().length);
            // currentDefects.resetFilter();
			//
			//
			// currentDefects.elementFilter.status = "pending";
			// currentDefects.elementFilter.deadlineFilters = ['expired'];
			// currentDefects.elementFilter.escalationLevels = ['one', 'two', 'three'];
            // this.$(".overdueLbl1-overview-filter").text(currentDefects.filtered().length);
            // currentDefects.resetFilter();
			//
			// currentDefects.elementFilter.status = "pending";
			// currentDefects.elementFilter.deadlineFilters = ['expired'];
			// currentDefects.elementFilter.escalationLevels = ['readyfortermination'];
			// this.$(".overdueLbl2-overview-filter").text(currentDefects.filtered().length);
            // currentDefects.resetFilter();
			//
            // currentDefects.elementFilter.allSubChanges = true;
			// this.$(".openNotRejctedLbl-overview-filter").text(currentDefects.filtered().length);
            // currentDefects.resetFilter();
			//
            // currentDefects.elementFilter.principalStatus = "rejected";
            // currentDefects.elementFilter.status = "fixed";
            // currentDefects.elementFilter.extraStatus = "P";
			// this.$(".solvedRejectedLbl-overview-filter").text(currentDefects.filtered().length);
            // currentDefects.resetFilter();

		},
		
		focusProjectSelector: function (e) {
			// e.target.value = ""
		},
		
		unFocusProjectSelector: function (e) {
			// if (e.target.value === '') {
			// 	if (!currentProject.isNew()) e.target.value = currentProject.get('name')
			// 	if (currentProject.isNew()) e.target.value = user.translate('overview.choose2')
			// }
		},

		changeProject: function () {
			
			// var newProject = this.chooseProject.attr('value');
			// if (!newProject) {
			// 	this.$news.show();
			// 	currentProject.clear();
			// 	Backbone.history.navigate('overview', { trigger: true });
			// 	return;
			// }
			// this.$news.hide();
			// $.xhrPool.abortAll();
			//
			// this.router.defectPage.model = undefined;
			//
			// Backbone.history.navigate('overview/' + window.encodeURIComponent(newProject), { trigger: true });
			// watchdog.check(); // for updating offline stuff synchronization status (ios app)
		},
		
		isActionsMenuOpen: function () {
			// return $('div.ui-popup-container.ui-popup-active').length > 0;
		},

		triggerActionsMenu: function (e) {
			// e.preventDefault();
			// e.stopPropagation();
			// this.actionsmenu.popup('open', { transition: 'flip', positionTo: this.$('.actionstrigger') });
		},
		
		closeActionsMenu: function (e) {
			// e.preventDefault();
			// e.stopPropagation();
			// this.actionsmenu.popup('close', { transition: 'flip', positionTo: this.$('.actionstrigger') });
		},

		setProjectInfoEditMode: function (e) {
			// e.preventDefault();
			// e.stopPropagation();
			// this.projectInfoEditMode = !this.projectInfoEditMode;
			// this.projectInfo.toggleClass('editMode', this.projectInfoEditMode);
			// this.updateProjectInfoEditButton();
			// this.actionsmenu.popup('close');
			// this.renderProjectInfo();
		},

		updateProjectInfoEditButton: function () {
			// this.editProjectInfo.text(this.projectInfoEditMode ? user.translate('overview.edit.apply') : user.translate('overview.edit'));
		},

		editHeadline: function (e) {
			// e.preventDefault();
			// e.stopPropagation();
			// var info = currentProject.get('info');
			// this.renderHeadline(info.headline, true);
		},

		saveHeadline: function (e) {
			// e.preventDefault();
			// e.stopPropagation();
			// var info = currentProject.get('info');
			// info.headline = this.projectInfoHeadline.find('input').val();
			// currentProject.saveInfo();
			// this.renderHeadline(info.headline, false);
		},

		editRow: function (e) {
			// e.preventDefault();
			// e.stopPropagation();
			// var i = parseInt($(e.currentTarget).parents('tr').attr('data-index'));
			// var info = currentProject.get('info');
			// this.renderRow(i, true);
		},

		saveRow: function (e) {
			// e.preventDefault();
			// e.stopPropagation();
			// var $row = $(e.currentTarget).parents('tr');
			// var i = parseInt($row.attr('data-index'));
			// var info = currentProject.get('info');
			// var columns = [];
			// $row.children().each(_.bind(function (i, el) {
			// 	columns.push($(el).find('input').val());
			// }, this));
			// info.table[i] = columns;
			// currentProject.saveInfo();
			// this.renderRow(i, false);
		},

		addRow: function (e) {
			// e.preventDefault();
			// e.stopPropagation();
			// var info = currentProject.get('info');
			// info.table.push(['', '']);
			// currentProject.saveInfo();
			// this.renderRow(info.table.length - 1, true);
		},

		removeRow: function (e) {
			// e.preventDefault();
			// e.stopPropagation();
			// var $row = $(e.currentTarget).parents('tr');
			// var i = parseInt($row.attr('data-index'));
			// var info = currentProject.get('info');
			// info.table.splice(i, 1);
			// currentProject.saveInfo();
			// this.projectinfoTable.find('tr[data-index="' + i + '"]').remove();
			// for (var j = i+1; j <= info.table.length; j++) {
			// 	this.projectinfoTable.find('tr[data-index="' + j + '"]').attr('data-index', j-1);
			// }
		},

		uploadImageClick: function (e) {
			// e.preventDefault();
			// e.stopPropagation();
			// this.projectInfoImageInput.click();
		},

		uploadImage: function () {
			// if (!this.projectInfoImageInput[0].files.length) {
			// 	return;
			// }
			//
			// var maxWidth = 800, maxHeight = maxWidth * 3 / 4;
			// var reader = new FileReader();
			// reader.onload = _.bind(function (e) {
			// 	var img = new Image();
			// 	img.onload = _.bind(function (e) {
			// 		var width = Math.min(maxWidth, img.width);
			// 		var height = width * img.height / img.width;
			// 		if (height > maxHeight) {
			// 			height = maxHeight;
			// 			width = height * img.width / img.height;
			// 		}
			// 		canvasResize(this.projectInfoImageInput[0].files[0], {
			// 			width:    width,
			// 			height:   height,
			// 			crop:     true,
			// 			quality:  80,
			// 			rotate:   0,
			// 			callback: _.bind(function (data, width, height) {
			// 				var info = currentProject.get('info');
			// 				info.image = data;
			// 				currentProject.saveInfo();
			// 				this.renderImage(info.image);
			// 			}, this)
			// 		});
			// 	}, this);
			// 	img.src = e.target.result;
			// }, this);
			// reader.readAsDataURL(this.projectInfoImageInput[0].files[0]);
		},

		syncplans: function () {
			// if (iosapp.appavailable && iosapp.functionavailable('syncplans') && !!currentProject.id) {
			// 	iosapp.syncplans(currentProject.id);
			// }
		},

		syncattachments: function () {
			// if (iosapp.appavailable && iosapp.functionavailable('attachments') && !!currentProject.id) {
			// 	iosapp.getsysteminfo(function(sysinfo) {
			// 		var defer;
			// 		if (sysinfo && sysinfo['appversion'] && (sysinfo['appversion'] == "1.4.4(39)" || sysinfo['appversion'] == "1.4.4(38)")) {
			// 			defer = window.alertPopup(user.translate('overview.syncattachmentserror'));
			// 		} else {
			// 			defer = $.Deferred().resolve().promise();
			// 		}
			//
			// 		defer.then(function() {
			// 			iosapp.syncattachments(currentProject.id);
			// 		});
			// 	})
			// }
		},

		resize: function () {
			// if(this.$('iframe').length > 0) {
			// 	this.$('iframe')[0].height = $(window).height()-200;
			// 	this.$('iframe')[0].width = $(window).width();
			// }
//			if (this.$el.hasClass('ui-page-active')) {
//				this.projectInfo.css('height', $(window).height() - this.header.outerHeight(true) - this.footer.outerHeight(true) - this.$('.ui-select').outerHeight(true));
//			}
		}

	});
});