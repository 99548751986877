import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton, StyledIconButton} from "@bau/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {Button} from "@mui/material";
import {EllipsisHorizontalIcon} from "@heroicons/react/24/outline";
import WarningIcon from '@mui/icons-material/Warning';
import {useLocation} from "react-router-dom";
import {translate} from "../utils/utils";
import {useAppDispatch} from "../store/hooks";


const Image = () => {

    const {state} = useLocation();
    const dispatch = useAppDispatch();

    useEffect(()=>{
        if(!state || !state.noUseEffect ) {
            // @ts-ignore
            window.imageUseEffect(!!state && state.pageId);
        }

        // @ts-ignore
        if(!!state && !!state.callback && !! window.showPageCallback ) {
            // @ts-ignore
            window.showPageCallback()
            // @ts-ignore
            window.showPageCallback = null;
        }
    },[])


    let right = [
        <FormControlLabel style={{visibility: "hidden"}} key="enableMarkerPlacement" className="enableMarkerPlacement" control={<Switch defaultChecked={true} className="enableMarkerPlacementSelect"/>} label="polygon" />,
        <StyledButton key="createDefect" className="createDefect">{translate("image.newdefect")}</StyledButton>,
        <Button style={{visibility: "hidden"}} key="offlineWarning" className="offlineWarning" variant="outlined" startIcon={<WarningIcon/>}>
            {translate("image.offlinewarning")}
        </Button>,
        <StyledIconButton key="actionstrigger" className="options-menu-button actionstrigger">
            <EllipsisHorizontalIcon/>
        </StyledIconButton>
    ]


    return <PageContainer newPage={false} headerText={translate("image.title")}
                          left={<StyledButton className="back">{translate("image.backtofiles")}</StyledButton>}
                          right={right}
    />
}

export default Image;