define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div class=\"bars\"><div class=\"tryingIndicator\"></div><span class=\"q1\"></span><span class=\"q2\"></span><span class=\"q3\"></span><span class=\"q4\"></span><span class=\"q5\"></span></div>\n" +
        "\n" +
        "<div class=\"pending\"><span class=\"pendingStatus\"></span> <span class=\"syncingStatus\"></span></div>\n" +
        "\n" +
        "<div class=\"connection\"><span class=\"connectionIndicator\"></span> <span class=\"connectionText\"></span></div>\n" +
        "\n" +
        "<div class=\"syncdefects\"><span></span></div>";
    return page;
});