define([
	'../lib/backbone-1.1.2'
], function (
	Backbone
) {
	return Backbone.Model.extend({

		getLabel: function () {
			var label = '';
			if (this.get('role')) {
				label += this.get('role');
				if (this.get('name')) {
					label += ', ';
				}
			}
			if (this.get('name')) {
				label += this.get('name');
			}
			return label;
		}

	});
});