define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<h2></h2>\n" +
        "\n" +
        "<!--\n" +
        "<div class=\"gotoSyncTarget\" style=\"float: right\">\n" +
        "\t<button class=\"ui-btn-active ui-corner-all\" style=\"margin: 0\">Ausloggen und zu<br/>diesem Account wechseln</button>\n" +
        "</div>\n" +
        "-->\n" +
        "\n" +
        "<table></table>";
    return page;
});