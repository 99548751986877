define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './RequestBundlePageHtml', '../view/Sketcher',
	'../model/user', '../model/paperwork', '../model/Inspection', '../model/currentProject', '../model/currentInspection',
	'../helper/backboneReactCommunicationUtils'
], function (
	$, _, Backbone, Template, Sketcher,
	user, paperwork, Inspection, currentProject, currentInspection, backboneReactCommunicationUtils
) {
	return Backbone.View.extend({

		id: 'request-bundle',

		attributes: {
			'data-role': 'page'
		},

		events: {
			'vclick .generate': 'request',
			'change .whichDefects': 'defectsChosen',
			'vclick .previousPage': 'gotoPreviousPage',
			'vclick .saveDocuments': 'saveAlteredFops',
			'vclick .openSafetyPopup': 'openSafetyPopup',
			'vclick .safetyPopup .cancelSafety': 'cancelSafetyPopup',
			// 'vclick .safetyPopup .saveAlteredFopsPermanently':  'saveAlteredFopsPermanently',
			'vclick .cancelFopPopup': 'cancelFopPopup',
			'change .selectResponsible': 'selectResponsible',

			'change [name=requestbundle-autodeadline]': 'renderDeadline'
		},

		template: _.template(Template),

		initialize: function (options) {
			this.router = options.router;
			_.bindAll(this, ['requestBundle', 'renderPreviews', 'renderSignatures', 'hideGenerationOption']);
			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.$whichDefects = this.$('.whichDefects');
			this.$previousPage = this.$('.previousPage');
			this.$previews = this.$('.previews');
			this.$deadline = this.$('.deadline');
			this.$noDeadline = this.$('#requestbundle-autodeadline-disable');
			this.$('.rdeadline-container').toggle(!!user.get('rdeadline'));
			this.$rdeadline = this.$('.rdeadline');
			this.$alterFopPopup = this.$('.alterFop');
			this.$safetyPopup = this.$('.safetyPopup');
			this.$safetyWordinput = this.$('.safetyWordinput');
			this.$chooseFolder = this.$('.chooseFolder');
			this.$safetyWarning = this.$('.safetyWarning');
			this.alteredDocumentIndex = null;
			this.alteredDocumentIndexes = [];
			this.$orderByRadioContainer = this.$('.request-bundle-generate-orderby-container');

			this.projectPersonSignature = new Sketcher({
				el: this.$('.projectPersonSignature'),
				size: { width: 450, height: 150 },
				color: 'navy',
				mode: 'single'
			}).render();

			this.userSignature = new Sketcher({
				el: this.$('.userSignature'),
				size: { width: 450, height: 150 },
				color: 'navy',
				mode: 'single'
			}).render();
		},

		render: function () {
			$('.previousPage').off('click');
			$('.previousPage').on('click', function (e) {
				this.gotoPreviousPage(e);
			}.bind(this));
			var isInspection = this.isInspectionBundle(), isPrincipal = this.isPrincipalBundle();
			this.$whichDefects.filter('.inspection').parent().toggle(isInspection && !isPrincipal);
			this.$whichDefects.filter('.principal').parent().toggle(!isInspection && isPrincipal);
			this.$whichDefects.filter('.subcontractor').parent().toggle(!isInspection && !isPrincipal);
			this.responsible = null;

			this.renderPreviews();
			this.renderSignatures();

			this.$deadline.val(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 14).toISOString().substring(0, 10));
			this.$rdeadline.val(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 3).toISOString().substring(0, 10));
			this.renderDeadline();
			this.hideGenerationOption();

			this.$('.ui-popup-container').css('height', '100%');
			this.$('.ui-popup-container').css('overflow-x', 'auto');


			return this;
		},

		renderDeadline: function () {
			var checked = this.$('input[name="requestbundle-autodeadline"][value="enable"]').prop('checked');
			if (this.hasExpiredDeadlines && this.hasExpiredDeadlines === true && !!checked) window.alertPopup(user.translate('request.deadlineExpired.info1') + '\n\n' + user.translate('request.deadlineExpired.info2'));
			this.$deadline.parent().toggle(!this.$noDeadline.is(':checked'));
		},

		renderPreviews: function () {
			var documents = this.paperwork.documents;
			if (documents.length > 0) {
				this.$previews.empty();
				var allowedDocuments = [];
				var disallowedDocuments = [];
				for (var i = 0; i < documents.length; i++) {
					if (documents[i].isDisallowedByGroup) {
						disallowedDocuments.push({ doc: documents[i], idx: i });
					} else {
						allowedDocuments.push({ doc: documents[i], idx: i });
					}
				}
				_.each(allowedDocuments, function (e) {
					this.fillSinglePreview(e.doc, e.idx);
				}.bind(this));
				if (disallowedDocuments.length > 0) {
					$('<div class="disallowed-by-group">')
						.text(user.translate('subcontractor.bundle.docDisallowedByGroup').replace('%%', currentProject.get('groupName')))
						.appendTo(this.$previews);
					_.each(disallowedDocuments, function (e) {
						this.fillSinglePreview(e.doc, e.idx);
					}.bind(this));
				}
			} else {
				this.$previews.html($('<div >').append($('<h4>').text(user.translate('subcontractor.bundle.nodocuments'))));
			}
			//this.$previews.collapsibleset('refresh');

			this.onlyTermination = true;
			_.forEach(this.paperwork.documents, function (d) {
				if (d.request !== 4) this.onlyTermination = false;
			}.bind(this))
			this.$('input[name="requestbundle-autodeadline"][value="disable"]').prop('checked', this.onlyTermination)
			this.$('input[name="requestbundle-autodeadline"][value="enable"]').prop('checked', !this.onlyTermination)
			this.$('input[name="requestbundle-autodeadline"]').checkboxradio('refresh')
		},

		fillSinglePreview: function (doc, i) {
			var $container = $('<div style="display: flex;' +
				'justify-content: space-between;"/>').appendTo(this.$previews);
			var $docListEntry = $('<h4>').text(doc.filename)
				.appendTo($container);
			if (doc.isDisallowedByGroup) {
				$docListEntry.addClass("disallowed-by-group");
			}
			if (!doc.isDisallowedByGroup) {
				$container.append($('<button class="doc_' + i + ' ui-btn ui-btn-inline ui-corner-all only-icon-on-small-screen ui-btn-icon-left ui-icon-gear" style="' +
					'margin: 16px;">').text(user.translate('requestBundlePage.review.edit')));
			}
			this.$('.doc_' + i).on('vclick', _.bind(function (e) {
				if (_.isEmpty(doc.fileMap)) alertPopup(user.translate('settings.noEditableText'));
				this.resetFopPopup();
				var _func = function () {
					var width = window.innerWidth - 200;
					var height = window.innerHeight - 200;
					this.$alterFopPopup.css({
						width: width,
						height: height
					})
					this.$alterFopPopup.popup('open');
					this.$alterFopPopup.popup('close');
					this.$alterFopPopup.popup('open');
				}.bind(this)

				var data = { fileMap: doc.fileMap, isEditable: doc.isEditableMap }
				// data = this.fixData(data, doc.request);
				doc.fileMap = data.fileMap;
				doc.isEditableMap = data.isEditable;

				this.fillFopPopup(doc, i, _func);

				if (window.innerWidth <= 500) this.$('.textBlocks').css('width', '88%');

				$('textarea').trigger('input');

			}, this));
		},

		// fixData: function (data, request) {
		// 	var fixedData = {fileMap: {}, isEditable: {}};
		// 	_.each(data.fileMap, function (value, key) {
		// 		key = this.replaceKey(key, request);
		// 		fixedData.fileMap[key] = value;
		// 	}.bind(this))
		// 	_.each(data.isEditable, function (value, key) {
		// 		key = this.replaceKey(key, request);
		// 		fixedData.isEditable[key] = value;
		// 	}.bind(this))
		// 	return fixedData;
		// },
		//
		// replaceKey: function (key, request) {
		// 	if(/AUFF\d-/.test(key)) {
		// 		key = key.replace(/AUFF\d-/, 'AUFF' + (request > 1 ? request : '') + '-');
		// 	} else if(/AUFF-/.test(key)) {
		// 		key = key.replace(/AUFF-/, 'AUFF' + (request > 1 ? request : '') + '-');
		// 	}
		// 	return key;
		// },

		resetFopPopup: function () {
			this.$('.project-name').text('');
			this.$('.textBlocks').html('');
		},

		cancelFopPopup: function () {
			for (var i = 0; i < this.alteredDocumentIndexes.length; i++) {
				if (this.alteredDocumentIndex == this.alteredDocumentIndexes[i]) this.alteredDocumentIndexes.splice(i, i);
			}
			this.alteredDocumentIndex = null;
			this.$alterFopPopup.popup({
				afterclose: null
			}).popup('close');
		},

		addFopElementTo: function ($textBlock, val, i, key, isEditable) {
			if (isEditable)
				$textBlock.append($('<li>').append($('<textarea class="input_' + key + '" style="white-space: pre-wrap; width: calc(100% - 8px);">').text(val)));
			else
				$textBlock.append($('<li>').append($('<div class="uneditable_' + key + '" style="margin: 10px; width: 96%; white-space: pre-wrap">').text(val)));
		},

		fillFopPopup: function (doc, i, callback) {
			this.$('.project-name').text(doc.filename);
			var $textBlock = this.$('.textBlocks');
			var j = 0;
			if (doc.fileMap && doc.isEditableMap) {
				var sortedKeyList = this.sortFileMap(doc.fileMap);
				var promise = new Promise(function (resolve, reject) {
					_.each(sortedKeyList, function (fopElement) {
						this.addFopElementTo($textBlock, doc.fileMap[fopElement], i, fopElement, doc.isEditableMap[fopElement]);
						j++;
						if (j === sortedKeyList.length) resolve();
					}.bind(this));
				}.bind(this));
				promise.then(function () {
					callback();
				});
			}
			this.alteredDocumentIndex = i;
			if (!this.alteredDocumentIndexes.includes(i))
				this.alteredDocumentIndexes.push(i)
		},

		sortFileMap: function (fileMap) {
			return Object.keys(fileMap).slice().sort();
		},

		saveAlteredFops: function () {
			var document = this.paperwork.documents[this.alteredDocumentIndex];

			for (var fopElement in document.fileMap) {
				if (document.isEditableMap[fopElement])
					document.fileMap[fopElement] = this.$('.input_' + fopElement).val();
				else
					document.fileMap[fopElement] = this.$('.uneditable_' + fopElement).text();
			}
			this.sendChangedFopToServer(document.fileMap).done(_.bind(function () {
				this.$alterFopPopup.popup('close')
			}, this));
		},

		openSafetyPopup: function () {
			if (user.isAdmin()) {
				this.$alterFopPopup.popup({
					afterclose: _.bind(function () {
						this.$safetyWarning.hide();
						this.$safetyWordinput.val('');
						this.$safetyPopup.popup('open');
					}, this)
				}).popup('close');
				this.$alterFopPopup.popup({
					afterclose: null,
				})
			} else {
				alertPopup(user.translate('requestbundle.noAdminRights'))
			}

		},

		cancelSafetyPopup: function (shouldOpenFopPopup) {
			this.$safetyPopup.popup({
				afterclose: _.bind(function () {
					if (shouldOpenFopPopup) this.$alterFopPopup.popup('open');
					this.$safetyWordinput.val('');

					for (var i = 0; i < this.alteredDocumentIndexes.length; i++) {
						if (this.alteredDocumentIndex == this.alteredDocumentIndexes[i]) this.alteredDocumentIndexes = this.alteredDocumentIndexes.splice(i, i);
					}
					this.alteredDocumentIndex = null;
				}, this)
			}).popup('close')
		},

		saveAlteredFopsPermanently: function () {
			var isProjectSelected = this.$chooseFolder.val() === 'project';
			if (!(user.get('authorities').includes('ROLE_ADMIN') ||
				(user.get('authorities').includes('ROLE_PROJECTADMIN') && isProjectSelected))) {
				alertPopup('requestbundle.noSuitableAdminRights');
				return;
			}
			var document = this.paperwork.documents[this.alteredDocumentIndex];
			if (this.$safetyWordinput.val() !== user.translate('requestbundle.change')) {
				this.$safetyWarning.show();
				this.$safetyWordinput.val('');
				return;
			}

			for (var fopElement in document.fileMap) {
				document.fileMap[fopElement] = this.$('.input_' + fopElement).val();
			}
			var project = isProjectSelected ? currentProject.get('id') : 'null';
			$.ajax({
				url:  '/onlineBauabnahme/api/saveFopToTemplate/' + project,
				type: 'POST',
				data: JSON.stringify(document.fileMap),
				contentType: 'application/json'
			}).done(_.bind(function () {
				this.cancelSafetyPopup();

			}, this))
				.fail(_.bind(function () {
					alertPopup(user.translate('requestbundle.error'));
				}, this));
		},

		sendChangedFopToServer: function (fop) {
			return $.ajax({
                url: '/onlineBauabnahme/api/saveFopToCurrentBundle/' + '?documentIndex=' + this.alteredDocumentIndex + '&bundleSessionId=' + (paperwork.get('bundleSessionId') || ''),
				type: 'POST',
				data: JSON.stringify(fop),
				contentType: 'application/json'
			})
		},

		renderSignatures: function () {

			var signatureConfig = currentProject.get('signatureConfig');

			var signatureSetting = (currentProject.get('signatureSetting') && currentProject.get('signatureSetting') !== ''
				? currentProject.get('signatureSetting')
				: user.get('settings').signatures);
			if ((!(signatureSetting === "WITHOUT" || signatureSetting === "PAPER")) && this.paperwork.documents && 0 !== this.paperwork.documents.length) {


				this.$('.userSignatureBox')
					.find('h4')
					.text(user.translate('requestbundle.signature.user') + ' ' + user.get('name'));


				this.projectPersonSignature.setModel({ annotation: this.paperwork.projectPersonSignature });
				this.userSignature.setModel({ annotation: this.paperwork.userSignature });

				if (signatureConfig === "RESPONSIBLE" || signatureConfig === "DEFAULT") {
					this.$('.projectPersonSignatureBox').show();
					this.$('.projectPersonSignature').show();
				} else {
					this.$('.projectPersonSignature').hide();
					this.$('.projectPersonSignatureBox').hide();
				}
				//this.$('.projectPersonSignatureBox').show();
				if (signatureConfig === "USER" || signatureConfig === "DEFAULT") {
					this.$('.userSignature').show();
					this.$('.userSignatureBox').show();
				} else {
					this.$('.userSignature').hide();
					this.$('.userSignatureBox').hide();
				}

			} else {
				this.$('.projectPersonSignature').hide();
				this.$('.projectPersonSignatureBox').hide();

				this.$('.userSignature').hide();
				this.$('.userSignatureBox').hide();
			}
			this.renderResponsible();
		},

		getPrepareParams: function (shouldTakeFop) {
			var isInspection = this.isInspectionBundle(), isPrincipal = this.isPrincipalBundle(), selector = this.$whichDefects;
			if (isInspection && !isPrincipal) selector = selector.filter('.inspection');
			if (!isInspection && isPrincipal) selector = selector.filter('.principal');
			if (!isInspection && !isPrincipal) selector = selector.filter('.subcontractor');
			var defectChoice = selector.val();

			var request;
			var onlyUnsent;

			switch (defectChoice) {
				case 'allUnsent': request = null; onlyUnsent = true; break;
				case 'all': request = null; onlyUnsent = false; break;
				case 'firstUnsent': request = 1; onlyUnsent = true; break;
				case 'first': request = 1; onlyUnsent = false; break;
				case 'secondUnsent': request = 2; onlyUnsent = true; break;
				case 'second': request = 2; onlyUnsent = false; break;
				case 'thirdUnsent': request = 3; onlyUnsent = true; break;
				case 'third': request = 3; onlyUnsent = false; break;
				case 'fourthUnsent': request = 4; onlyUnsent = true; break;
				case 'fourth': request = 4; onlyUnsent = false; break;
				case 'divider':
				default: return;
			}

			var takeFopsFromIndexes = null;
			if (shouldTakeFop) {
				takeFopsFromIndexes = (_.isObject(this.alteredDocumentIndexes) && _.isArray(this.alteredDocumentIndexes)) ? JSON.stringify(this.alteredDocumentIndexes) : undefined;
			}

			return {
				project: currentProject.id,
				inspection: (_.isObject(this.model) && !_.isArray(this.model) && isInspection) ? this.model.id : undefined,
				subcontractor: (_.isObject(this.model) && !_.isArray(this.model) && !isInspection) ? this.model.id : undefined,
				principal: this.isPrincipalBundle() ? 'true' : 'false',
				type: _.isString(this.model) && !this.isPrincipalBundle() ? this.model : undefined,
				selectedIds: (_.isObject(this.model) && _.isArray(this.model)) ? JSON.stringify(this.model) : undefined,
				request: request,
				onlyUnsent: onlyUnsent,
				responsible: this.responsible,
				userSignature: this.userSignature.getCurrentAttachment().annotation,
				projectPersonSignature: this.projectPersonSignature.getCurrentAttachment().annotation,
				autodeadline: !this.$noDeadline.is(':checked') ? this.$deadline.val() : null,
				rdeadline: !!user.get('rdeadline') ? (this.$rdeadline.val() ? this.$rdeadline.val() : null) : null,
				takeFopsFromIndexes: takeFopsFromIndexes,
				orderBy: $('input[name="request-bundle-orderby"]:checked', this.$orderByRadioContainer).val()
			};
		},

		requestBundle: function (shouldTakeFop) {
			this.paperwork = null;
			var params = this.getPrepareParams(shouldTakeFop);
			$.mobile.loading('show');
			return paperwork.preparePaperwork(params)
				.then(_.bind(function (data) {
					this.hasExpiredDeadlines = false;
					_.forEach(data.documents, function (d) {
						if (d.hasExpiredDeadlines === true) this.hasExpiredDeadlines = true;
					}.bind(this));
					this.paperwork = data;
					$.mobile.loading('hide');
				}, this))
				.fail(function (jqXHR) {
					$.mobile.loading('hide');

					if (jqXHR && jqXHR.status === 410) {
						alertPopup(user.translate('paperwork.showdocument.sessionexpired')).then(function() {
							window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection());
						}.bind(this));
					} else {
						alertPopup(user.translate('paperwork.showdocument.error'));
					}
				});
		},

		request: function () {
			if (this.responsible == null && this.multiple) {
				window.alertPopup(user.translate("bundle.noresponsible"));
				return;
			}
			$.mobile.loading('show');
			this.requestBundle(true)
				.then(_.bind(function () {
					if (!this.isPrincipalBundle() && this.$noDeadline.is(':checked') && _.some(this.paperwork.documents, function (document) {
						return document.containsNullDeadline;
					})) {
						$.mobile.loading('hide');
						return window.confirmPopup(user.translate('subcontractor.bundle.nodeadlinewarning'));
					} else {
						return $.Deferred().resolve();
					}
				}, this))
				.then(_.bind(function () {
					this.router.paperworkPage.setModel(this.model);
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/paperWork');
				}, this))
				.fail(function () {
					$.mobile.loading('hide');
				});
		},

		setModel: function (model) {
			this.model = model;
		},

		setIsPrincipal: function (isPrincipal) {
			this.isPrincipal = isPrincipal;
		},

		isInspectionBundle: function () {
			return _.isObject(this.model) && this.model instanceof Inspection;
		},
		isPrincipalBundle: function () {
			return _.isString(this.model) && this.model == 'principal';
		},

		defectsChosen: function () {
			this.alteredDocumentIndexes = []; //reset current state
			this.requestBundle(false)
				.then(this.renderPreviews)
				.then(this.renderSignatures)
				.then(this.hideGenerationOption);
		},

		gotoPreviousPage: function (e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			if (_.isString(this.model) && this.model != 'principal') {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/subcontractors');
			} else if (this.isPrincipalBundle()) {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/principalBundle');
			} else if (this.isInspectionBundle()) {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection());
			} else if (this.model === null) {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/subcontractors');
			} else if (_.isObject(this.model) && _.isArray(this.model)) {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject()+'/defects');
			} else {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/subcontractorBundle/'+this.model.id);
			}
		},
		hideGenerationOption: function () {
			var allowedDocumentsExist = false;
			_.each(this.paperwork.documents || [], function (doc) {
				if (!doc.isDisallowedByGroup) {
					allowedDocumentsExist = true;
				}
			});
			if (allowedDocumentsExist) {
				this.$(".documentsRelevant").show();
			} else {
				this.$(".documentsRelevant").hide();
			}
		},

		renderResponsible: function () {
			var projectPerson = currentProject.get('responsible');
			if (!projectPerson || projectPerson.trim().toLowerCase() == user.get('name').trim().toLowerCase()) {
				this.$('.projectPersonSignature').hide();
				this.$('.projectPersonSignatureBox').hide();
				this.$('.projectPersonSignatureBox').find('.ui-select').hide();
				return;
			}
			var signatureSetting = (currentProject.get('signatureSetting') && currentProject.get('signatureSetting') !== ''
				? currentProject.get('signatureSetting')
				: user.get('settings').signatures);
			var dontShowSignature = (signatureSetting === "WITHOUT" || signatureSetting === "PAPER");
			var responsiblePeople = projectPerson.split(/[;,]+/);
			if (responsiblePeople.length == 1 || currentProject.get('signatureConfig') == "USER") {
				this.$('.projectPersonSignatureBox').toggle(true).find('h4')
					.text(user.translate('requestbundle.signature' + (dontShowSignature ? '.no' : '')) + ' ' + projectPerson);
				this.$('.projectPersonSignatureBox').find('.ui-select').hide();
			} else {
				this.multiple = true;
				this.$('.projectPersonSignatureBox').toggle(true).find('h4')
					.text(user.translate('requestbundle.signature' + (dontShowSignature ? '.no' : '')) + ':');
				var $select = this.$('.projectPersonSignatureBox').find('select');
				$select.empty();
				$("<option>").text("- Projektverantwortlicher auswählen - ").val(null).appendTo($select);
				_.each(responsiblePeople, _.bind(function (person) {
					$("<option>").text(person.trim()).val(person).appendTo($select);
				}), this);
				this.$('.projectPersonSignatureBox').find('.ui-select').show();
				$select.val(null);
				$select.selectmenu('refresh');
			}

		},
		selectResponsible: function (e) {
			var signatureSetting = (currentProject.get('signatureSetting') && currentProject.get('signatureSetting') !== ''
				? currentProject.get('signatureSetting')
				: user.get('settings').signatures);
			var dontShowSignature = (signatureSetting === "WITHOUT" || signatureSetting === "PAPER");

			this.responsible = e.target.value && e.target.value.trim();
			this.$('.projectPersonSignature ').toggle(!dontShowSignature && !(this.responsible && this.responsible.toLowerCase() === user.get('name').trim().toLowerCase()));

		}

	});
});