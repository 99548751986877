define([
	'../lib/backbone-1.1.2'
], function (Backbone) {

	return new (Backbone.Model.extend({
		url:  '/api/projectmanager/state',

		defaults: {
			project:       {},
			subcontractor: {},
			user:          {},
			group:         {},
			limits:        {}
		},

		project: function (value) {
			if (value == null) {
				return this.get('project');
			} else {
				this.set('project', _.extend({}, this.get('project'), value));
			}
		},

		subcontractor: function (value) {
			if (value == null) {
				return this.get('subcontractor');
			} else {
				this.set('subcontractor', _.extend({}, this.get('subcontractor'), value));
			}
		},

		user: function (value) {
			if (value == null) {
				return this.get('user');
			} else {
				this.set('user', _.extend({}, this.get('user'), value));
			}
		},

		group: function (value) {
			if (value == null) {
				return this.get('group');
			} else {
				this.set('group', _.extend({}, this.get('group'), value));
			}
		}
	}))();

});