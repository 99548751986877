define([
    '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2',
    '../model/Person', '../model/currentProject', '../model/user', '../helper/interactiveSync'
], function (
    $, _, Backbone,
    Person, currentProject, user, interactiveSync
) {
    return new (Backbone.Collection.extend({

        model: Person,

        loadingAll: false,

        url: function () {
            return this.loadingAll ?
                 '/onlineBauabnahme/api/allpersons/' + user.get('customer') + '/' + this.project :
                 '/onlineBauabnahme/api/persons/' + user.get('customer') + '/' + this.project + '/?projectStruc=' + this.projectStruc.join(',');
        },

        setProjectStrucAndProject: function (projectStruc, project) {
            this.loadingAll = false;
            this.projectStruc = projectStruc;
            this.project = project;

            if (!projectStruc || !project) {
                this.reset();
                return $.Deferred().resolve();
            }
            return this.fetch({ reset: true });
        },

        setProject: function (project, refresh) {
            if (project == this.project) {
                if (this.pending && !refresh) {
                    return this.pending;
                } else if (!refresh) {
                    return $.Deferred().resolve();
                }
            }

            this.loadingAll = true;
            this.projectStruc = [];

            this.project = project;
            if (!project) {
                this.reset();
                return $.Deferred().resolve();
            }
            this.pending = this.fetch({ reset: true })
                .fail(_.bind(function () {
                    alertPopup(user.translate('gdpr.error'));
                }, this))
                .always(_.bind(function () {
                    delete this.pending;
                }, this));
            return this.pending;
        },
    }));
});