define([
	'../lib/backbone-1.1.2'
], function (
	Backbone
) {
	return Backbone.Model.extend({

		idAttribute: 'hash'

	});
});
