define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2',
	'../model/user', '../model/currentProject', '../model/Inspection', '../model/inspections', '../model/proxyInfo',
	'../helper/offlineQueue'
], function (
	$, _, Backbone,
	user, currentProject, Inspection, inspections, proxyInfo,
	offlineQueue
) {
	return new (Inspection.extend({

		urlRoot:  '/onlineBauabnahme/api/inspection',

		/*
		sync: function (method, model, options) {
		    if ((method == 'create' || method == 'update') && (proxyInfo.get('proxyMode'))) {
		        if (!this.id) {
		            throw new Exception("inspection id must be set");
		        }
		        if (!options || !options.url) {
		            options = _.extend({url: this.urlRoot + "/" + this.id}, options)
		        }
                return offlineQueue.sync.apply(offlineQueue, arguments);
            } else {
			    return Backbone.sync.apply(this, [method, model, proxyInfo.syncAddHeaders(options)]);
            }
		},
		 */

		setProject: function (project) {
			if (project != this.project) {
				this.clear({ silent: true });
			}
			this.project = project;
			return $.Deferred().resolve();
		},

        finish: function (attributes, includeAll, send, responsible) {
            return this.save(attributes, _.extend({silent: true}, {url: _.result(this, 'url') + '/finish/' + (!!includeAll ? 1 : 0) + '/' + (!!send ? 1 : 0) + (responsible ? ('?responsible=' + responsible) : '')}));
        },

		getTypeName: function () {
			return user.get('inspectionTypeNames')[this.get('type')];
		},

		getLocation: function () {
			var location = [];
			var tree = currentProject.get('unitTree');
			var choice = (this.get('unit') || '').split('-');
			for (var i = 0; i < choice.length && tree.children; i++) {
				tree = tree.children[choice[i]];
				if (tree) {
					location.push(tree.name);
				}
			}
			return location;
		},

		generateOfflineId: function() {
            return -Math.floor(Math.random() * 2000000000) - 1;
		}


	}))();
});