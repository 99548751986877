define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat','../lib/backbone-1.1.2', '../model/user'
], function ($, _,Backbone, user) {
	return Backbone.View.extend({

		tagName: 'ul',
		className: 'multiselect',
		attributes: {
			'data-role':  'listview',
			'data-inset': 'true'
		},
		
		selectedItems: [],

		events: {
			'listviewbeforefilter': 'createFilter'
		},

		initialize: function (options) {
			this.options = options;
			this.reposition = _.bind(this.reposition, this);
			this.hideItems = _.bind(this.hideItems, this);
			this.preventBlurIfListInteraction = _.bind(this.preventBlurIfListInteraction, this);
			$(window).on('throttledresize', this.reposition);
			$('body').on('mousedown', this.preventBlurIfListInteraction);
		},

		render: function () {
			var self = this;
			this.input = this.options.input.attr({
				autocomplete: 'off',
				autocorrect:  'off'
			}).on('click', function (e) {
				self.createFilter();
				e.stopPropagation();
			});
			
			this.$el.appendTo(this.options.container || this.input.parent()).hide();
			if (this.options.toggleButton) {
				var wrapperClasses = (this.input.attr('data-wrapper-class') || '').split(' ');
				if (!_.contains(wrapperClasses, 'ui-icon-carat-d ui-btn-icon-right')) {
					wrapperClasses.push('ui-icon-carat-d');
					wrapperClasses.push('ui-btn-icon-right');
					wrapperClasses.push('multiselectToggleWrapper');
				}
				this.input.attr('data-wrapper-class', wrapperClasses.join(' '));
				try {
					this.input.textinput('refresh');
				} catch (e) {}
				$(document).on('click', '.multiselectToggleWrapper', _.bind(function (e) {
					if ($(e.currentTarget).children('input').is(this.input)) {
						if (this.$el.css('display') === 'none') {
							this.createFilter(true);
							e.stopPropagation();
						} else {
							this.hideItems();
						}
					}
				}, this));
				$(document).on('focus', 'input', _.bind(function (e) {
					if ($(e.currentTarget).children('input').is(this.input) && this.$el.css('display') !== 'none') {
						this.hideItems();
					}
				}, this));
			}
			return this;

		},

		createFilter: function (ignoreValue) {
			this.remover = $('<div style="width: 100%; height: 100%; position: relative; z-index: 999;">').on('click', function () {
				this.hideItems();
			}.bind(this)).appendTo($('body'));
			
			var value = this.val();
			if (this.input.prop('readonly') || this.input.prop('disabled')) {
				this.hideItems();
				return;
			}
			this.showItems(this.options.items, this.options.input);
		},
		
		showItems: function (items) {
			this.$el.show().empty();
			$('body').off('click', this.hideItems);
			
			this.$el.append($('<li class="ui-btn">').append($('<fieldset>').append($('<label style="text-align: center; color: orange;" class="ui-btn ui-corner-all ui-btn-inherit">').text('Zurücksetzen')
				.on('mousedown', function (e) {
					this.resetSelection();
				}.bind(this))))
				.on('mousedown', function (e) {
					this.resetSelection();
				}.bind(this)));
			
			_.each(items, function (item) {
				var key = Array.isArray(item) ? item[0] : item;
				var name = Array.isArray(item) ? (item.length > 1 ? item[1] : item[0]) : item;
				var selected = false;
				if(Array.isArray(item)) {
					_.each(this.selectedItems, function (i) {
						if(item.length > 1) {
							if(item[0] === i[0] && item[1] === i[1]) selected = true;
						} else {
							if(item[0] === i[0]) selected = true;
						}
					}.bind(this))
				} else {
					selected = _.contains(this.selectedItems, item);
				}
				var listOption = $('<li class="ui-btn ' + (selected ? 'ui-btn-active' : '') + '">').on('mousedown', function (e) {
					e.stopPropagation(); e.preventDefault();
					this.selectItem($(e.target).find('fieldset'));
				}.bind(this));
				this.$el.append(
					listOption.append(
						$('<fieldset class="ui-checkbox">').append(
							$('<input class="' + key + '" type="checkbox" name="' + name + '" value="' + key + '" ' + (selected ? 'checked' : '') + '>')
						).append(
							$('<label for="' + key + ' " class="' + key + ' ui-btn ui-corner-all ui-btn-inherit ' + key.toLowerCase() + ' ' + (selected ? 'ui-btn-active' : '') + '">').html(name).on('mousedown', function (e) {
								e.stopPropagation(); e.preventDefault();
								this.selectItem($(e.target).closest('fieldset'));
							}.bind(this))
						)
				));
			}.bind(this));
			if(this.$el.listview) this.$el.listview('refresh');
			this.reposition();
		},
		
		selectItem: function (fs) {
			var lb = fs.find('label');
			var inp = fs.find('input');
			var li = lb.parent().parent();
			inp.prop('checked', !inp.prop('checked'));
			lb.toggleClass('ui-btn-active', inp.prop('checked'));
			li.toggleClass('ui-btn-active', inp.prop('checked'));
			var selectedVal = inp.val() === inp.prop('name') ? inp.val() : [inp.val(), inp.prop('name')];
			if(inp.prop('checked')) {
				this.selectedItems.push(selectedVal);
			} else {
				if(Array.isArray(selectedVal)) {
					var index = -1;
					_.each(this.selectedItems, function (s, i) {
						if(s[0] === selectedVal[0]) index = i;
					})
					this.selectedItems.splice(index, 1);
				} else {
					this.selectedItems.splice(this.selectedItems.indexOf(selectedVal), 1);
				}
			}
			
			this.options.callback();
		},
		
		resetSelection: function () {
			this.$el.find('li').each(function () {
				$(this).removeClass('ui-btn-active');
				$(this).find('label').removeClass('ui-btn-active');
				$(this).find('input').prop('checked', false);
			})
			this.selectedItems = [];
			this.options.callback();
			this.hideItems();
		},

		hideItems: function () {
			this.firstSuggestion = null;
			this.$el.hide().empty();
			this.remover.remove();
//			this.$el.listview('refresh');
		},

		val: function (value) {
			if (!arguments.length) {
				return this.input.val();
			}
			if (this.input) {
				if(this.input.attr('id') !== 'chooseProject') this.input.val(value).change();
				if (this.input.attr('id') === 'chooseProject') {
					this.input.attr('value', value[1])
					this.input.val(value[1] === '' ? user.translate('overview.choose2') : value[0]).change()
				}
			} else {
				this.options.value = val;
			}
			return this;
		},

		remove: function () {
			$(window).off('throttledresize', this.reposition);
			$('body').off('mousedown', this.preventBlurIfListInteraction);
			Backbone.View.prototype.remove.apply(this, arguments);
		},

		reposition: function () {
			var inputParent = this.input.parent();

			if (inputParent) {
                var inputOffset = this.options.offset || inputParent.position();

                if (inputOffset) {
                    this.$el.css({ top: inputOffset.top + window.parseInt(inputParent.css('marginTop'), 10) + inputParent.outerHeight(),
						right: this.options.rightOffset == null ? inputOffset.right : this.options.rightOffset + "px",
						left: this.options.leftOffset == null ? inputOffset.left : this.options.leftOffset + "px",
						width: this.options.autowidth ? 'auto' : this.options.width == null ? inputParent.outerWidth(true): this.options.width + "%"});
                }
			}
		},

		preventBlurIfListInteraction: function(e) {
			if (this.$el.css('display') !== 'none' && (this.$el.is(e.target) || this.$el.has(e.target).length)) {
				e.preventDefault();
			}
		}

	});
});
