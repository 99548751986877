define([
	'./lib/jquery-1.11.0', './lib/numeral-1.5.3', 'moment', './helper/iosapp'
], function ($, numeral, moment, iosapp) {

	$(document).on('mobileinit', function () {
		$.mobile.ajaxEnabled = false;
		$.mobile.hoverDelay = 0;
		$.mobile.buttonMarkup.hoverDelay = 0;
		$.mobile.linkBindingEnabled = false;
		$.mobile.hashListeningEnabled = false;
		$.mobile.ignoreContentEnabled = true;
		$.mobile.page.prototype.options.domCache = true;
		$.mobile.toolbar.prototype.options.tapToggle = false;
		$.mobile.toolbar.prototype.options.hideDuringFocus = false;
		$.mobile.resetTimerDuration = 700;
	});

	$.xhrPool = [];
	$.xhrPool.abortAll = function () {
		$(this).each(function (i, jqXHR) {
			jqXHR.abort();
			$.xhrPool.splice(i, 1);
		});
	};
	$.ajaxSetup({
		beforeSend: function (jqXHR) {
			$.xhrPool.push(jqXHR);
		},
		complete: function (jqXHR) {
			var i = $.xhrPool.indexOf(jqXHR);
			if (i > -1) {
				$.xhrPool.splice(i, 1);
			}
		}
	});

	$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
		options.crossDomain = { crossDomain: true };
		if (iosapp.appavailable) {
			//hack to set third-party-cookie
			if (iosapp.getCookie() != null) {
				options.headers = { Cookie: iosapp.getCookie() };
			}

			var successhandler = options.success;
			var errorhandler = options.error;
			
			options.success = function(data, textStatus, jqXHR) {
				var responseHeader = jqXHR.getResponseHeader("Set-Cookie");
				if (typeof responseHeader !== 'undefined' && responseHeader != null) {
					iosapp.setCookie(responseHeader);
				}
				if (typeof successhandler !== 'undefined' && successhandler != null) {
					successhandler.apply(this, arguments); //call original callback
				}
			};
			
			options.error = function(jqXHR) {
				var responseHeader = jqXHR.getResponseHeader("Set-Cookie");
				if (typeof responseHeader !== 'undefined' && responseHeader != null) {
					iosapp.setCookie(responseHeader);
				}
				if (typeof errorhandler !== 'undefined' && errorhandler != null) {
					errorhandler.apply(this, arguments); //call original callback
				}
			};
		}
		options.xhrFields = { withCredentials: true };
	});

	$.fn.shake = function (intShakes, intDistance, intDuration) {
		this.each(function () {
			$(this).css('position', 'relative'); 
			for (var x = 1; x <= intShakes; x++) {
				$(this)
				.animate({ left: -intDistance }, intDuration / intShakes / 4)
				.animate({ left:  intDistance }, intDuration / intShakes / 2)
				.animate({ left:            0 }, intDuration / intShakes / 4);
			}
		});
		return this;
	};

	RegExp.escape = function (str) {
		return String(str).replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
	};

	numeral.language('de', {
	    delimiters: {
	        thousands: ' ',
	        decimal: ','
	    },
	    abbreviations: {
	        thousand: 'k',
	        million: 'm',
	        billion: 'b',
	        trillion: 't'
	    },
	    ordinal: function (number) {
	        return '.';
	    },
	    currency: {
	        symbol: '€'
	    }
	});
	numeral.language('de');

	function processRelativeTime (number, withoutSuffix, key, isFuture) {
		var format = {
			'm': ['eine Minute', 'einer Minute'],
			'h': ['eine Stunde', 'einer Stunde'],
			'd': ['ein Tag', 'einem Tag'],
			'dd': [number + ' Tage', number + ' Tagen'],
			'M': ['ein Monat', 'einem Monat'],
			'MM': [number + ' Monate', number + ' Monaten'],
			'y': ['ein Jahr', 'einem Jahr'],
			'yy': [number + ' Jahre', number + ' Jahren']
		};
		return withoutSuffix ? format[key][0] : format[key][1];
	}
	moment.lang('de', {
		months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
		monthsShort: 'Jan._Febr._Mrz._Apr._Mai_Jun._Jul._Aug._Sept._Okt._Nov._Dez.'.split('_'),
		weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
		weekdaysShort: 'So._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
		weekdaysMin: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
		longDateFormat: {
			LT: 'H:mm [Uhr]',
			L: 'DD.MM.YYYY',
			LL: 'D. MMMM YYYY',
			LLL: 'D. MMMM YYYY LT',
			LLLL: 'dddd, D. MMMM YYYY LT'
		},
		calendar: {
			sameDay: '[Heute um] LT',
			sameElse: 'L',
			nextDay: '[Morgen um] LT',
			nextWeek: 'dddd [um] LT',
			lastDay: '[Gestern um] LT',
			lastWeek: '[letzten] dddd [um] LT'
		},
		relativeTime: {
			future: 'in %s',
			past: 'vor %s',
			s: 'ein paar Sekunden',
			m: processRelativeTime,
			mm: '%d Minuten',
			h: processRelativeTime,
			hh: '%d Stunden',
			d: processRelativeTime,
			dd: processRelativeTime,
			M: processRelativeTime,
			MM: processRelativeTime,
			y: processRelativeTime,
			yy: processRelativeTime
		},
		ordinal: '%d.',
		week: {
			dow: 1, // Monday is the first day of the week.
			doy: 4  // The week that contains Jan 4th is the first week of the year.
		}
	});

	window.generateUUID = function () {
		var d = new Date().getTime();
		if (window.performance && typeof window.performance.now === 'function') {
			d += performance.now();
		}
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	}

});
