define([
    '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2'
], function (
    $, _, Backbone
) {

    var idutil = {

        _init: function() {
            this._addPolyfill();
            this._attachTest();
        },

        _attachTest: function() {
            /*
            window.execTest = function() {
                var tests = [ 'abc', 'AbC012', 'a_-!@#$%^&*(){}[]=+,./:"|;\'\\', 'Niederlassung MÃ¼nchen', 'Bembé Parkett GmbH & Co. KG', 'eCon “Die Sanierer“ GmbH', 'Co KG • Bauu', '弗格斯节能材', '🦊 abc🐶.', 'a\nb\tc', '/?', '±®°³…€a', 'ифик', 'संगठित' ]
                var expected = [ 'abc', 'abc012', 'a___________________________', 'niederlassung_m__nchen', 'bemb__parkett_gmbh___co__kg', 'econ__die_sanierer__gmbh', 'co_kg___bauu', '.5f17.683c.65af.8282.80fd.6750', '..0001f98a_abc..0001f436_', 'a_b_c', '__', '______a', '.0438.0444.0438.043a', '.0938.0902.0917.0920.093f.0924' ]

                var count = 0;
                var success = 0;
                _.each(tests, function(test, idx) {
                    var result = this.locationToLocationId(tests[idx])
                    count++;
                    if (result === expected[idx]) {
                        success++;
                    } else {
                        console.log("failed test (idx: " + idx + "): " + tests[idx] + ' ' + expected[idx] + ' ' + result);
                    }
                }.bind(this));
                console.log('completed: ' + success + '/' + count);
            }.bind(this)
             */
        },

        _addPolyfill: function() {
            var self = this;
            //from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/codePointAt
            //(modified)

            /*! https://mths.be/codepointat v0.2.0 by @mathias */
            (function() {
                'use strict'; // needed to support `apply`/`call` with `undefined`/`null`
                var codePointAt = function(position) {
                    if (this == null) {
                        throw TypeError();
                    }
                    var string = String(this);
                    var size = string.length;
                    // `ToInteger`
                    var index = position ? Number(position) : 0;
                    if (index != index) { // better `isNaN`
                        index = 0;
                    }
                    // Account for out-of-bounds indices:
                    if (index < 0 || index >= size) {
                        return undefined;
                    }
                    // Get the first code unit
                    var first = string.charCodeAt(index);
                    var second;
                    if ( // check if it's the start of a surrogate pair
                        first >= 0xD800 && first <= 0xDBFF && // high surrogate
                        size > index + 1 // there is a next code unit
                    ) {
                        second = string.charCodeAt(index + 1);
                        if (second >= 0xDC00 && second <= 0xDFFF) { // low surrogate
                            // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
                            return (first - 0xD800) * 0x400 + second - 0xDC00 + 0x10000;
                        }
                    }
                    return first;
                };
                self._codePointAt = function(str, position) {
                    return codePointAt.apply(str, [ position ]);
                };
            }());
        },

        _isCodePointInLegacyBlock: function(codePoint) {
            /*blocks to be handled as before:

            U+0000 - U+007F	Basic Latin
            U+0080 - U+00FF	Latin-1 Supplement
            U+0100 - U+017F	Latin Extended-A
            U+0180 - U+024F	Latin Extended-B
            U+0250 - U+02AF	IPA Extensions
            U+02B0 - U+02FF	Spacing Modifier Letters
            U+0300 - U+036F	Combining Diacritical Marks

            U+1AB0 - U+1AFF	Combining Diacritical Marks Extended

            U+1DC0 - U+1DFF	Combining Diacritical Marks Supplement
            U+1E00 - U+1EFF	Latin Extended Additional

            U+2000 - U+206F	General Punctuation

            U+20A0 - U+20CF	Currency Symbols
            U+20D0 - U+20FF	Combining Diacritical Marks for Symbols

            U+2190 - U+21FF	Arrows

             */

            return (
                codePoint >= 0x0000 && codePoint <= 0x036F ||
                codePoint >= 0x1AB0 && codePoint <= 0x1AFF ||
                codePoint >= 0x1DC0 && codePoint <= 0x1EFF ||
                codePoint >= 0x2000 && codePoint <= 0x206F ||
                codePoint >= 0x20A0 && codePoint <= 0x20FF ||
                codePoint >= 0x2190 && codePoint <= 0x21FF
            );
        },

        _pad: function(num, count) {
            var str = '' + num;
            var pad = '000000000000000000000';
            return pad.substr(0, count - str.length) + str;
        },

        unicodeSimplify: function(str) {
            //do NOT just loop with codePointAt through the indexes, this doesn't work for UTF-16 multibyte characters (e.g. emojis)
            var result = '';
            for (var i = 0; i < str.length; i++) {
                var c = str[i];
                var codePoint = this._codePointAt(c, 0);
                if ( // check if it's the start of a surrogate pair
                    codePoint >= 0xD800 && codePoint <= 0xDBFF && // high surrogate
                    str.length > i + 1 // there is a next code unit
                ) {
                    i = i + 1;
                    c = c + str[i];
                    codePoint = this._codePointAt(c, 0);
                }
                if (this._isCodePointInLegacyBlock(codePoint)) {
                    result = result + c.toLowerCase().replace(/[^a-z0-9]/g, '_');
                } else {
                    if (codePoint <= 0xFFFF) {
                        result = result + '.' + this._pad(codePoint.toString(16), 4);
                    } else {
                        result = result + '..' + this._pad(codePoint.toString(16), 8);
                    }
                }
            }
            return result;
        },

        locationPartToId: function(location) {
            return this.unicodeSimplify(location);
        },

        locationArrayToId: function(location) {
            var idArray = _.map(location, function(part) {
                return this.locationPartToId(part);
            }.bind(this));
            return idArray.join('-');
        },

        //technically dots are allowed as HTML element IDs, but jquery uses it in the selector as special character
        //we just go around that problem by replacing dots with a special string which should not occur
        escapeIdForHtml: function(id) {
            return id.replaceAll('.', '--YQDOTSQY--');
        }
    };

    idutil._init();

    return idutil;
});
