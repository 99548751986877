import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton, StyledIconButton} from "@bau/material";
import {useLocation, useNavigate} from "react-router-dom";
import {EllipsisHorizontalIcon} from "@heroicons/react/24/outline";
import {getCustomerProjectWithPrefix, translate} from "../utils/utils";

const Subcontractors = () => {


    const navigate = useNavigate();
    const {customerProject} = getCustomerProjectWithPrefix();
    const {state} = useLocation();

    useEffect(()=>{
        // @ts-ignore
        window.subcontractorsUseEffect(!!state && state.requestBundle);
    },[])

    let backToProject = ()=>{
        navigate(customerProject);
    }

    let right = [
        <StyledIconButton className="options-menu-button actionstrigger">
            <EllipsisHorizontalIcon/>
        </StyledIconButton>
    ]

    return <PageContainer newPage={false} headerText={translate("subcontractors.title")}
                          left={<StyledButton onClick={backToProject}>{translate("goback")}</StyledButton>}
                          right={right}
    />
}

export default Subcontractors;