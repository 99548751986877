define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './FilterPageHtml',
	'../model/user', '../model/currentProject', '../model/subcontractors', '../model/inspections', '../model/currentDefects',
	'../helper/backboneReactCommunicationUtils'
], function (
	$, _, Backbone, Template,
	user, currentProject, subcontractors, inspections, currentDefects,
	backboneReactCommunicationUtils
) {
	return Backbone.View.extend({

		id: 'filter',

		attributes: {
			'data-role': 'page'
		},

		defaultState: {
			external:            'all',
			type:                'all',
			locations:           'all',
			crews:               'all',
			subcontractors:      'all',
			inspections:         'all',
			status:              'all',
			subcontractorStatus: 'all',
			principalStatus:     'all',
			requests:            'all',
			deadline:            'all',
			createdBy:           'all',
			description:         'all',
			combinedStatusFilter:''
		},

		template: _.template(Template),

		previousSelection: null,
		previousPage: 'defects',

		events: {
			'click .apply': function () {
				currentDefects.detailFilter = this.getState();
				switch (this.previousPage) {
				case 'inspection':
					this.router.inspectionPage.filterMode = 'detail';
					break;
				case 'subcontractor':
					this.router.subcontractorPage.filterMode = 'detail';
					break;
				default:
					this.router.defectsPage.filterMode = 'detail';
				}
				this.goBack();
			},
			'click .previousPage': 'goBack',
			'change .ui-checkbox': function (e) {
				var $target = $(e.target).parent().find('input'),
					$cg = $target.closest('.ui-controlgroup'),
					$all = $cg.find('input[value=all]');
				if ($target.val() === 'all') {
					_.defer(function () {
						$all.parent().removeClass('ineffective');
						$cg.find('input')
						.prop({ checked: $all.is(':checked') })
						.checkboxradio('refresh');
					});
				} else {
					$all.parent().addClass('ineffective');
				}
				this.$presetList.val('').selectmenu('refresh');
			},
			'click .preset-save': function () {
				var key = this.$presetName.val() || user.translate('filter.unnamed'), filters = user.get('settings').customFilters, newState = this.getState(), later;
				if (_.has(filters, key) && !_.isEqual(filters[key], newState)) {
					later = window.confirmPopup(user.translate('filter.confirm.overwrite'));
				} else {
					later = $.Deferred().resolve();
				}
				later.done(_.bind(function () {
					filters[key] = newState;
					user.save({ settings: user.get('settings') }, { silent: true, url:  '/onlineBauabnahme/api/settings' })
					.done(_.bind(function () {
						this.renderPresetList();
						this.$presetList.val(key).selectmenu('refresh');
					}, this));
				}, this));
			},
			'click .preset-delete': function () {
				window.confirmPopup(user.translate('filter.confirm.delete'))
				.done(_.bind(function () {
					var key = this.$presetList.val();
					delete user.get('settings').customFilters[key];
					user.save({ settings: user.get('settings') }, { silent: true, url:  '/onlineBauabnahme/api/settings' })
						.done(_.bind(function () {
							this.setState(this.defaultState);
							this.$presetName.val('');
							this.renderPresetList();
							this.$presetList.val('').selectmenu('refresh');
						}, this));
				}, this));
			},
			'change #saved-options': function () {
				var key = this.$presetList.val(), later;
				if (this.previousValue === '' && !_.isEqual(this.getState(), this.defaultState)) {
					later = window.confirmPopup(user.translate('filter.confirm.loosechanges'));
				} else {
					later = $.Deferred().resolve();
				}
				later.then(_.bind(function () {
					this.setState(key ? user.get('settings').customFilters[key] : this.defaultState);
					this.$presetName.val(this.previousSelection = (key ? key : ''));
				}, this), _.bind(function () {
					this.$presetList.val(this.previousValue).selectmenu('refresh');
				}, this));
			},
			'click #saved-options': function () {
				this.previousValue = this.$presetList.val();
			},
			'change #combinedStatusFilter': function (e) {
				if(e.target.value !== "") {
                    this.$notCombinedStatusContainer.hide();
				} else {
                    this.$notCombinedStatusContainer.show();
				}

			}
		},

		initialize: function (options) {
			this.router = options.router;

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.$previousPage = this.$('.previousPage');
			this.$presetList = this.$('#saved-options');
			this.$presetName = this.$('.preset-name');
			this.$notCombinedStatusContainer = this.$('.not-combined-status-container');
			this.$combinedStatusContainer = this.$('.combined-status-container');
			this.$combinedStatusFilter = this.$('#combinedStatusFilter');
		},

		goBack: function () {
			switch (this.previousPage) {
				case 'inspection':
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection());
					break;
				case 'subcontractor':
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/subcontractor/'+this.router.subcontractorPage.currentSubcontractorId);
					break;
				default:
					if(this.previousPage === 'principal') {
						window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defects', {principal: true});
						return;
					}
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/'+this.previousPage);
			}
		},

		render: function () {
			$('.apply').off('click');
			$('.apply').on('click', function () {
				currentDefects.detailFilter = this.getState();
				switch (this.previousPage) {
					case 'inspection':
						this.router.inspectionPage.filterMode = 'detail';
						break;
					case 'subcontractor':
						this.router.subcontractorPage.filterMode = 'detail';
						break;
					default:
						this.router.defectsPage.filterMode = 'detail';
				}
				this.goBack();
			}.bind(this));
			$('.previousPage').off('click');
			$('.previousPage').on('click', function (e) {
				this.goBack();
			}.bind(this));

			var $cg, $container, prefix, id;

			var tree = currentProject.get('unitTree'), elements = tree.children ? _.values(tree.children) : [],
				level = 0, oldLength = null;
			while (elements.length < 100 && elements.length !== oldLength) {
				oldLength = elements.length;
				elements = _.compact(_.flatten(_.map(elements, function (element) {
					return element && element.children ? _.values(element.children) : element;
				})));
				level++;
			}

			var categories = currentProject.get('categories');

			$cg = this.$('.external-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-external-';
			_.each([ 'true', 'false' ], function (external) {
				id = prefix + external;
				$('<label>').attr('for', id).text(user.translate('filter.external.' + external)).appendTo($container);
				$('<input type="checkbox" value="' + external + '">').attr('id', id).appendTo($container).checkboxradio();
			}, this);
			$cg.controlgroup('refresh').toggle(!currentDefects.elementFilter.external);

			$cg = this.$('.type-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-type-';
			{
				id = prefix + 'all';
				$('<label>').attr('for', id).text(user.translate('filter.all')).appendTo($container);
				$('<input type="checkbox" value="all">').attr('id', id).appendTo($container).checkboxradio();
			}
            var defectTypes = currentProject.get('types');
            _.each(defectTypes, function (type) {
                id = prefix + type.defectTypeId;
                $('<label>').attr('for', id).text(user.translate(type.label)).appendTo($container);
                $('<input type="checkbox" value="' + type.defectTypeId + '">').attr('id', id).appendTo($container).checkboxradio();
            }, this);
			$cg.controlgroup('refresh');

			$cg = this.$('.locations-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-locations-';
			// level = (currentProject.get('structureKeys') ? currentProject.get('structureKeys').length : 0);
			this.appendUnitTreeElements(tree, level, $container, prefix);
			$cg.controlgroup('refresh');

			$cg = this.$('.crews-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-type-';
			{
				id = prefix + 'all';
				$('<label>').attr('for', id).text(user.translate('filter.all')).appendTo($container);
				$('<input type="checkbox" value="all">').attr('id', id).appendTo($container).checkboxradio();
			}
            var crews = currentProject.get('crews');
            _.each(crews, function (crew) {
                id = prefix + crew;
                $('<label>').attr('for', id).text(crew).appendTo($container);
                $('<input type="checkbox" value="' + crew + '">').attr('id', id).appendTo($container).checkboxradio();
            }, this);
			$cg.controlgroup('refresh');

			$cg = this.$('.subcontractors-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-subcontractors-';
			{
				id = prefix + 'all';
				$('<label>').attr('for', id).text(user.translate('filter.all')).appendTo($container);
				$('<input type="checkbox" value="all">').attr('id', id).appendTo($container).checkboxradio();
			}
			subcontractors.each(function (subcontractor) {
				id = prefix + subcontractor.id;
				$('<label>').attr('for', id).text(subcontractor.getLabel(true)).appendTo($container);
				$('<input type="checkbox" value="' + subcontractor.id + '">').attr('id', id).appendTo($container).checkboxradio();
			}, this);
			$cg.controlgroup('refresh');

			$cg = this.$('.inspections-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-inspections-';
			{
				id = prefix + 'all';
				$('<label>').attr('for', id).text(user.translate('filter.all')).appendTo($container);
				$('<input type="checkbox" value="all">').attr('id', id).appendTo($container).checkboxradio();
			}
			// { already handled if nothing is selected
			// 	id = prefix + 'null';
			// 	$('<label>').attr('for', id).text(user.translate('defects.filter.emptyinspection')).appendTo($container);
			// 	$('<input type="checkbox" value="none">').attr('id', id).appendTo($container).checkboxradio();
			// }
			$('<label>').attr('for', prefix + -1).text(user.translate('defects.filter.emptyinspection')).appendTo($container);
			$('<input type="checkbox" value="-1">').attr('id', prefix + -1).appendTo($container).checkboxradio();
			inspections.each(function (inspection) {
				id = prefix + inspection.id;
				$('<label>').attr('for', id).text(inspection.getLabel()).appendTo($container);
				$('<input type="checkbox" value="' + inspection.id + '">').attr('id', id).appendTo($container).checkboxradio();
			}, this);
			$cg.controlgroup('refresh');

			$cg = this.$('.status-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-status-';
			{
				id = prefix + 'all';
				$('<label>').attr('for', id).text(user.translate('filter.all')).appendTo($container);
				$('<input type="checkbox" value="all">').attr('id', id).appendTo($container).checkboxradio();
			}
			var statuus = [ 'irrelevant', 'fix', 'open', 'discount', 'fixed', 'rejected' ];
			if (categories && categories.warranty) {
				statuus.push('observed');
			}
			_.each(statuus, function (status) {
				id = prefix + status;
				$('<label>').attr('for', id).text(user.translate('defect.status.' + status)).appendTo($container);
				$('<input type="checkbox" value="' + status + '">').attr('id', id).appendTo($container).checkboxradio();
			}, this);
			$cg.controlgroup('refresh');

			$cg = this.$('.subcontractorstatus-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-subcontractorstatus-';
			//{
			//	id = prefix + 'all';
			//	$('<label>').attr('for', id).text(user.translate('filter.all')).appendTo($container);
			//	$('<input type="checkbox" value="all">').attr('id', id).appendTo($container).checkboxradio();
			//}
			_.each([ 'pending', 'fixed' ], function (subcontractorStatus) {
				id = prefix + subcontractorStatus;
				$('<label>').attr('for', id).text(user.translate('defect.subcontractorstatus.' + subcontractorStatus)).appendTo($container);
				$('<input type="checkbox" value="' + subcontractorStatus + '">').attr('id', id).appendTo($container).checkboxradio();
			}, this);
			$cg.controlgroup('refresh');

			$cg = this.$('.principalstatus-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-principalstatus-';
			{
				id = prefix + 'all';
				$('<label>').attr('for', id).text(user.translate('filter.all')).appendTo($container);
				$('<input type="checkbox" value="all">').attr('id', id).appendTo($container).checkboxradio();
			}
			_.each([ 'pending', 'accepted', 'discount', 'rejected', 'fixed' ], function (principalStatus) {
				id = prefix + principalStatus;
				$('<label>').attr('for', id).text(user.translate('defect.principalstatus.' + principalStatus)).appendTo($container);
				$('<input type="checkbox" value="' + principalStatus + '">').attr('id', id).appendTo($container).checkboxradio();
			}, this);
			$cg.controlgroup('refresh');

			$cg = this.$('.deadline-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-deadline-';
			{
				id = prefix + 'all';
				$('<label>').attr('for', id).text(user.translate('filter.all')).appendTo($container);
				$('<input type="checkbox" value="all">').attr('id', id).appendTo($container).checkboxradio();
			}
			_.each([ 'expired', 'notexpired', 'nodate', 'today' ], function (e) {
				id = prefix + e;
				$('<label>').attr('for', id).text('' + user.translate('defects.filter.deadline.' + (e === 'nodate' ? 'nodeadline' : e))).appendTo($container);
				$('<input type="checkbox" value="' + e + '">').attr('id', id).appendTo($container).checkboxradio();
			}, this);
			$cg.controlgroup('refresh');

			$cg = this.$('.requests-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-requests-';
			{
				id = prefix + 'all';
				$('<label>').attr('for', id).text(user.translate('filter.all')).appendTo($container);
				$('<input type="checkbox" value="all">').attr('id', id).appendTo($container).checkboxradio();
			}
			var translations = ['defect.escalationlevel.dialog.firstlevel', 'defect.escalationlevel.dialog.secondlevel', 'defect.escalationlevel.dialog.thirdlevel', 'defects.filter.escalationlevel.terminated', 'defect.menu.noEscalationlevel']
			_.each([ 1, 2, 3, 'X', 'NotSent' ], function (requests, i) {
				id = prefix + requests;
				$('<label>').attr('for', id).text('' + user.translate(translations[i])).appendTo($container);
				$('<input type="checkbox" value="' + requests + '">').attr('id', id).appendTo($container).checkboxradio();
			}, this);
			$cg.controlgroup('refresh');

			$cg = this.$('.createdby-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-createdby-';
			{
				id = prefix + 'all';
				$('<label>').attr('for', id).text(user.translate('filter.all')).appendTo($container);
				$('<input type="checkbox" value="all">').attr('id', id).appendTo($container).checkboxradio();
			}

			_.each(this.extractListOfDefectsCreator(), function (createdBy) {
				// 'encoding' to use only allowed chars
				id = prefix + this.normalizeString(createdBy);
				$('<label>').attr('for', id).text('' + createdBy).appendTo($container);
				$('<input type="checkbox" value="' + createdBy + '">').attr('id', id).appendTo($container).checkboxradio();
			}, this);
			$cg.controlgroup('refresh');

			$cg = this.$('.description-list'); $container = $cg.controlgroup('container').empty(); prefix = 'filter-description-';
			{
//				id = prefix + 'all';
//				$('<label>').attr('for', id).text(user.translate('filter.all')).appendTo($container);
//				$('<input type="checkbox" value="all">').attr('id', id).appendTo($container).checkboxradio();
			}

			_.each([ 'empty', 'notempty' ], function (state) {
				id = prefix + state;
				$('<label>').attr('for', id).text(user.translate('defect.description.' + state)).appendTo($container);
				$('<input type="checkbox" value="' + state + '">').attr('id', id).appendTo($container).checkboxradio();
			}, this);
			$cg.controlgroup('refresh');

			this.renderPresetList();
			this.renderCombinedFilter();
			this.setState(currentDefects.detailFilter ? currentDefects.detailFilter : this.defaultState);

			return this;
		},

		renderCombinedFilter: function () {
            var showFilter =
                user.get('customer') === 'ox' ||
                user.get('customer') === 'ox2' ||
                (user.get('customer') === 'implenia_muenchen' && currentProject.get('id') === 'PROJEKT_ATLAS') ||
                (user.get('customer') === 'bv_atlas' && currentProject.get('id') === 'ATLAS') ||
                (user.get('customer') === 'rom' && currentProject.get('id') === 'PROJEKT_ATLAS');
            if(!showFilter) {
                this.$combinedStatusContainer.remove();
			}
		},

		extractListOfDefectsCreator: function () {
			var creators = currentDefects.models.map(function (item) {
				return item.get('createdBy');
			});
			return creators.filter(function (item, idx, creators) {
				return !!item && idx == creators.indexOf(item);
			}).sort();
		},

		normalizeString: function (input) {
			return input.replace(/\s|\(|\)|\.|@|ä|ö|ü|Ä|Ö|Ü/g, "");
		},

		renderPresetList: function () {
			this.$presetList.empty();
			$('<option>').val('').text('(neu)').appendTo(this.$presetList);
			_.forOwn(user.get('settings').customFilters || {}, function (value, key) {
				$('<option>').val(key).text(key).appendTo(this.$presetList);
			}, this);
			if (this.previousSelection !== null) {
				this.$presetList.val(this.previousSelection);
			}
			this.$presetList.selectmenu('refresh');
		},

		appendUnitTreeElements: function (node, level, $container, prefix) {
			var val = node.path || 'all', id = prefix + val;
			$('<label>').attr('for', id).text(!node.path ? user.translate('filter.all') : node.title).appendTo($container);
			$('<input type="checkbox" value="' + val + '">').attr('id', id).appendTo($container).checkboxradio();
			var children = _.values(node.children);
			if (children && children.length > 0 && level > 0) {
				_.each(children, function (child) {
					this.appendUnitTreeElements(child, level - 1, $container, prefix);
				}, this);
			}
		},

		setState: function (state) {
			_.each([ 'external', 'type', 'locations', 'crews', 'subcontractors', 'inspections', 'status', 'subcontractorStatus', 'principalStatus', 'deadline', 'requests', 'createdBy', 'description' ], function (key) {
				if ('all' === state[key] || 'none' === state[key]) {
					this.$('#filter-' + key.toLowerCase() + '-all')
						.parent()
						.toggleClass('ineffective', false)
						.end()
					.add(this.$('.' + key.toLowerCase() + '-list input'))
					.prop({ checked: 'all' === state[key] })
					.checkboxradio('refresh');
				} else {
					this.$('#filter-' + key.toLowerCase() + '-all').parent().toggleClass('ineffective', true);
					this.$('.' + key.toLowerCase() + '-list').find('input').prop('checked', false);
					_.each(state[key], function (val) {
						this.$('#filter-' + (key.toLowerCase() + '-' + ((key === 'createdBy' || key === "crews" )? this.normalizeString(val) : val))).prop('checked', true);
					}, this);
					this.$('.' + key.toLowerCase() + '-list').find('input').checkboxradio('refresh');
				}
			}, this);
            if(!!state.combinedStatusFilter && state.combinedStatusFilter !== null) {
                this.$combinedStatusFilter.val(state.combinedStatusFilter).change();
            } else {
                this.$combinedStatusFilter.val('').change();
			}
		},

		getState: function () {
			var result = {};
			if(!!this.$combinedStatusFilter) {
				if(this.$combinedStatusFilter.val() !== null) {
					result.combinedStatusFilter = this.$combinedStatusFilter.val();
				} else {
				result.combinedStatusFilter = null;
				}
			}
			_.each([ 'external', 'type', 'locations', 'crews', 'subcontractors', 'inspections', 'status', 'subcontractorStatus', 'principalStatus', 'deadline', 'requests', 'createdBy', 'description'], function (key) {
				var allBox = this.$('#filter-' + key.toLowerCase() + '-all');
				if (allBox.length && !allBox.parent().hasClass('ineffective')) {
					result[key] = allBox.is(':checked') ? 'all' : 'none';
				} else {
					var inputs = this.$('.' + key.toLowerCase() + '-list input[id!="filter-' + key.toLowerCase() + '-all"]');
					result[key] = _.map(inputs.filter(':checked'), function (el) {
						// use the value instead of id for createdBy, the el.id is 'encoded'
						var result = (key === 'crews' || key === "createdBy") ? el.value :  el.id.split('filter-' + key.toLowerCase() + '-')[1];
						return key === 'inspections' ? parseInt(result) : result;
					});
					if (result[key].length === inputs.length) {
						result[key] = 'all';
					} else if (result[key].length === 0) {
						result[key] = 'none';
					}
				}
			}, this);
			return result;
		}

	});
});
