define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './CostPageHtml',
	'../model/currentDefects', '../model/subcontractors', '../model/Subcontractor', '../model/currentProject', '../model/currentInspection', '../model/user',
	'../lib/numeral-1.5.3', '../helper/backboneReactCommunicationUtils', '../helper/iosapp'
], function (
	$, _, Backbone, Template,
	currentDefects, subcontractors, Subcontractor, currentProject, currentInspection, user,
	numeral, backboneReactCommunicationUtils, iosapp
) {
	return Backbone.View.extend({

		id: 'cost',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		events: {
			'vclick .actionstrigger': 'triggerActionsMenu',
			'vclick .moreRows button':             function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.renderList({ dontEmpty: true });
				var $children = this.$list.children();
				var $lastElement = $children.get($children.length - 1);
				this.$list.scrollTo($lastElement, 500);
			},
		    'change input[name=order]:radio': function (e) {
			    var comparator = this[$(e.currentTarget).val()];
			    if (comparator !== this.comparator) {
				    this.comparator = comparator;
				    this.refreshList();
			    }
			    this.$actionsMenu.popup('close');
		    },
			'vclick .statusAll': function (e) {

				e.preventDefault();
				e.stopPropagation();

				var subcontractorId = $(e.currentTarget).parent().parent().attr('data-id');
				currentDefects.resetFilter();
				currentDefects.elementFilter.subcontractor = subcontractorId;
				if (currentProject.get('costType') === 'GLOBAL') {
					currentDefects.elementFilter.cost = 'cost1000';
				} else {
					currentDefects.elementFilter.cost = 'costnone';
				}
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/subcontractor/' + subcontractorId);
			},
			'change .separate-switch': 'render',
			'vclick .costDownloadDialog': 'costPdf'
		},

		showFirstCount: 0,
		itemCount: 0,
		pageSize: 30,
		comparator: null,

		initialize: function (options) {
			this.router = options.router;

			this.resize       = _.bind(this.resize, this);
			this.total        = _.bind(this.total, this);
			this.pending      = _.bind(this.pending, this);

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);

			this.$header             = this.$('[data-role=header]');
			this.$footer             = this.$('[data-role=footer]');
			this.$list               = this.$('.list');
			this.$actionsMenu        = this.$('.actionsmenu');
			this.$separateSwitch     = this.$('.separate-switch');
			this.$costDownloadDialog = this.$('.costDownloadDialog');

			this.comparator = null;
			this.$('input[name=order][value=standard]').prop('checked', true);

			this.$el.on('pageshow', _.bind(function () {
				this.resize();
			}, this));
			$(window).on('throttledresize', this.resize);
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active') && !self.$el.hasClass('hide-page')) {
					var listMargin = parseInt(self.$list.css('margin-bottom'));
					self.$list.hide();
					var visible = self.$('.ui-content > *:visible');
					var last = $(visible[visible.length - 1]);
					var pageOffset = 0;//parseInt(self.$el.css('padding-top').split('px')[0]);
					var height = Math.floor(self.$el.height() - (last.length > 0 ? last.position().top : 0) - (last.outerHeight(true)||0) + pageOffset - listMargin);
					self.$list.height(height).show();
				}
			}, this);
		},

		render: function () {
			$('.download').off('click');
			$('.download').on('click', function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.costLink =  '/onlineBauabnahme/webStatic/cost.pdf?project=' + window.encodeURIComponent(currentProject.id) + '&costAg=1&' + new Date().getTime();
				this.$costDownloadDialog.show().popup('open');
			}.bind(this));
			var costType = currentProject.get('costType');
			this.$('.legend'   ).text(user.translate(costType === 'GLOBAL' ? 'cost.legend.global' : 'cost.legend.specific'));
			this.$('.costTitle').text(user.translate(costType === 'GLOBAL' ? 'cost.title.global'  : 'cost.title.specific' ));

			this.$separateSwitch.toggle(costType === 'SEPARATE');

			var useCostAG = costType === 'SEPARATE' && this.$separateSwitch.find(':checked').val() === 'principal';
			$('.download').toggle(!window.oxmobile);//.toggle(useCostAG);

			this.sumInfo = {};
			currentDefects.each(function (defect) {
				var subcontractor = defect.get('subcontractor');
				if (!subcontractors.get(subcontractor)) {
					subcontractor = null;
				}
				if (!this.sumInfo[subcontractor]) {
					this.sumInfo[subcontractor] = {
						total: 0,
						totalCount: 0,
						count: 0,
						nullCount: 0
					};
				}
				var cost = defect.get(useCostAG ? 'costAG' : 'cost');
				this.sumInfo[subcontractor].totalCount++;
				if (cost == null) {
					this.sumInfo[subcontractor].nullCount++;
				} else if (_.isFinite(cost)) {
					this.sumInfo[subcontractor].count++;
					this.sumInfo[subcontractor].total += cost;
				}
			}, this);

			this.refreshList();

			return this;
		},

		refreshList: function (options) {
			var modelsAttributes = [];
			for (var i = 0; i < subcontractors.models.length; i++) {
				modelsAttributes.push(subcontractors.models[i].attributes);
			}
			this.subcontractorKeys = _.pluck(this.comparator ? modelsAttributes.sort(this.comparator) : modelsAttributes, 'id');

			this.itemCount = 0;

			if (options && options.newListContext) {
				this.showFirstCount = 0;
			}

			this.renderList();
		},

		renderList: function (options) {
			options = options || {};
			if (!options.dontEmpty) {
				this.$list.empty();
			}

			for (var i = 0; i < this.pageSize && this.hasMoreItemsInList(); i++, this.itemCount++) {
				this.renderSubcontractor(subcontractors.get(this.subcontractorKeys[this.itemCount]));
			}

			if (this.hasMoreItemsInList()) {
				if (!options.noRecursion) {
					var recursionOptions = {
						dontEmpty:   true,
						noRefresh:   true,
						noRecursion: true
					};
					while(this.itemCount < this.showFirstCount && this.hasMoreItemsInList()) {
						this.renderList(recursionOptions);
					}
					if (this.hasMoreItemsInList()) {
						if (this.$moreRows) {
							this.$moreRows.detach();
							this.$list.append(this.$moreRows);
						} else {
							this.$moreRows = $('<li class="moreRows"><button>' + user.translate('subcontractors.more') + '</button></li>').appendTo(this.$list).enhanceWithin();
						}
					}
				}

			} else if (!options.noRecursion && this.$moreRows) {
				this.$moreRows.detach();
			}

			if (!options.noRecursion) {
				this.showFirstCount = this.itemCount;
			}

			if (!options.noRefresh) {
				this.$list.listview('refresh');
			}
		},

		hasMoreItemsInList: function () {
			return this.itemCount < this.subcontractorKeys.length;
		},

		renderSubcontractor: function (subcontractor) {
//			var defectIndex = currentDefects.indexOf(subcontractor);

			var totalSum = 0, nullCount = 0, count = 0, totalCount = 0;
			if (this.sumInfo[subcontractor.id]) {
				totalSum = this.sumInfo[subcontractor.id].total;
				nullCount = this.sumInfo[subcontractor.id].nullCount;
				count = this.sumInfo[subcontractor.id].count;
				totalCount = this.sumInfo[subcontractor.id].totalCount;
			}

			var $item = $('<li data-id="' + subcontractor.id + '" style="position: relative"></li>').appendTo(this.$list);
			$item = $('<div class="ui-grid-a"></div>').appendTo($item);

			var text = '';
			switch (currentProject.get('costType')) {
			case 'SPECIFIC':
			case 'SEPARATE':
				text = user.translate('cost.label.specific').replace('%s', numeral(totalSum).format('#,##0.00 $')).replace('%d', nullCount);
				break;
			case 'GLOBAL':
				text = user.translate('cost.label.global').replace('%d', count).replace('%d', totalCount);
				break;
			}
			$item.append(
				'<button class="statusAll ui-btn ui-block-a">' +
					'<span class="ui-btn-icon-notext ui-icon-arrow-r"></span>' +
					'<span class="sum">' +
						'<span class="total">' + text + '</span>' +
					'</span>' +
				'</button>'
			);

			var useCostAG = currentProject.get('costType') === 'SEPARATE' && this.$separateSwitch.find(':checked').val() === 'principal';
			if (!window.oxmobile || !iosapp.appavailable) {
				$item.parent().append($('<span class="ui-btn" style="position: absolute; right: 0; display: block; top: 0; margin: 0">PDF</span>')
				.appendTo($item)
				.click(_.bind(function () {
                    this.costLink =  '/onlineBauabnahme/webStatic/cost.pdf?project=' + window.encodeURIComponent(currentProject.id) +'&subcontractor=' + window.encodeURIComponent(subcontractor.id) + (useCostAG ? '&costAg=1' : '') + '&' + new Date().getTime()
                    this.$costDownloadDialog.show().popup('open');
				}, this)));
			}

			$item.append($('<a href="javascript:void(0)" class="ui-btn ui-btn-a ui-block-b"></a>').text(subcontractor.getLabel()));
//			$item.enhanceWithin();
		},

		costPdf: function(e) {
		    e.preventDefault();
            e.stopPropagation();
            this.$costDownloadDialog.popup('close');
            if ($(e.target).hasClass('ok')) {
                var costSplitSelection = this.$costDownloadDialog.find('input[name="costSplit"]:checked').val();
                var link = this.costLink;
                link = link + '&split=' + costSplitSelection;
				window.open(link);
            }
            this.$costDownloadDialog.find('#no-cost-split').attr('checked', true);

		},
		
		isActionsMenuOpen: function () {
			return $('div.ui-popup-container.ui-popup-active').length > 0;
		},

		triggerActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.$actionsMenu.popup('open', { transition: 'flip', positionTo: this.$('.actionstrigger') });
		},
		
		closeActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.$actionsMenu.popup('close', { transition: 'flip', positionTo: this.$('.actionstrigger') });
		},

		alphabetical: function (a, b) {
			if (a.crew == b.crew) {
				if (a.name > b.name || !b.name) return 1;
				if (a.name < b.name || !a.name) return -1;
				return 0;
			}
			if (a.crew > b.crew || !b.crew) return 1;
			if (a.crew < b.crew || !a.crew) return -1;
			return 0;
		},

		total: function (a, b) {
			var totalA = this.sumInfo[a.id] ? this.sumInfo[a.id].total : 0;
			var totalB = this.sumInfo[b.id] ? this.sumInfo[b.id].total : 0;
			if (totalA < totalB) return 1;
			if (totalA > totalB) return -1;
			return this.alphabetical(a, b);
		},

		pending: function (a, b) {
			var pendingA = this.sumInfo[a.id] ? this.sumInfo[a.id].pending : 0;
			var pendingB = this.sumInfo[b.id] ? this.sumInfo[b.id].pending : 0;
			if (pendingA < pendingB) return 1;
			if (pendingA > pendingB) return -1;
			return this.alphabetical(a, b);
		}

	});
});