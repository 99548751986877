define([
	'../lib/backbone-1.1.2'
], function (
	Backbone
) {
	return Backbone.Model.extend({

		getLabel: function (withoutCrew) { // on change update completions.subcontractorLookup
			var label = '';
			if (!withoutCrew && this.get('crew')) {
				label += this.get('crew');
				if (this.get('name')) {
					label += ', ';
				}
			}
			if (this.get('name')) {
				label += this.get('name');
			}

			var brackets = [];
			if (this.get('attn')) {
				brackets.push(this.get('attn'));
			}
			if (this.get('bereich')) {
				brackets.push(this.get('bereich'));
			}
			if (brackets.length) {
				label += ' (' + brackets.join(', ') + ')';
			}
			
			label += '[' + this.get('shortname') + ']'
			return label;
		},

		toJSON: function () {
			var result = Backbone.Model.prototype.toJSON.apply(this, arguments);
			result.label = this.getLabel();
			return result;
		}

	});
});