define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<!--<button class=\"offlineWarning\" style=\"display: none\" data-mini=\"true\"><%= t('files.offlinewarning') %></button>-->\n" +
        "\n" +
        "<div id=\"serviceNameDiv\">\n" +
        "\t<h2><img src=\"images/icons/mstile-144x144.png\" width=\"30px\" style=\"vertical-align: sub;\" /> <%= t('serviceName') %></h2>\n" +
        "</div>\n" +
        "<div id=\"overviewLinkDiv\"><a href=\"#overview\" class=\"overviewlink\"><img class=\"logo\"/></a></div>\n" +
        "\n" +
        "<!--<div class=\"myaccount-link\" style=\"float: right\"><a href=\"javascript:void(0)\" target=\"_blank\" title=\"Experience-ID\"><img src=\"images/myaccount.png\"/></a></div>-->\n" +
        "\t<h2 id=\"projectHeadline\"><a href=\"#overview\" class=\"overviewlink ui-btn ui-btn-a ui-icon-carat-r ui-btn-icon-right\"></a><!--<img class=\"projectMenuArrow\" src=\"images/icons-png/carat-r-orange.png\">--></h2>\n" +
        "\n" +
        "<!--<h3><a href=\"#inspection\" class=\"inspectionLink ui-btn-icon-right ui-icon-carat-r\"></a></h3>-->\n" +
        "\n" +
        "<ul data-role=\"listview\" style=\"margin-bottom: 0\">\n" +
        "\t<li class=\"inspections\">\n" +
        "\t\t<a href=\"#inspections\" data-shortcut=\"inspectionList\" class=\"ui-btn ui-btn-icon-left ui-icon-bullets\" ><%= t('sidepanel.inspections') %></a>\n" +
        "\t\t<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-inspection\"/>\n" +
        "\t</li>\n" +
        "\t<li class=\"inspection\">\n" +
        "\t\t<a href=\"#inspection\" data-shortcut=\"inspectionCurrent\" class=\"ui-btn ui-btn-icon-right ui-icon-carat-r\"><!--<%= t('sidepanel.inspection') %>--></a>\n" +
        "\t\t<!--<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-inspection\"/>-->\n" +
        "\t</li>\n" +
        "\t<li class=\"location\">\n" +
        "\t\t<a href=\"#location\" data-shortcut=\"defectList\" class=\"ui-btn ui-btn-icon-left ui-icon-location\"><%= t('sidepanel.defects') %></a>\n" +
        "\t\t<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-location\"/>\n" +
        "\t</li>\n" +
        "\t<li class=\"files\">\n" +
        "\t\t<a href=\"#files\"            class=\"ui-btn ui-btn-icon-left ui-icon-eye\"     ><%= t('sidepanel.files') %></a>\n" +
        "\t\t<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-files\"/>\n" +
        "\t</li>\n" +
        "\t<li class=\"documents\">\n" +
        "\t\t<a href=\"#documents\" class=\"ui-btn ui-btn-icon-left ui-icon-bars\"><%= t('sidepanel.documents') %></a>\n" +
        "\t\t<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-documents\"/>\n" +
        "\t</li>\n" +
        "\t<li class=\"subcontractors\">\n" +
        "\t\t<a href=\"#subcontractors\"   class=\"ui-btn ui-btn-icon-left ui-icon-user\"    ><%= t('sidepanel.subcontractors') %><span class=\"newDefects zarro\"></span></a>\n" +
        "\t\t<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-subcontractors\"/>\n" +
        "\t</li>\n" +
        "\t<li class=\"instant\">\n" +
        "\t\t<a href=\"#instant\" data-shortcut=\"newInstantMessage\" class=\"ui-btn ui-btn-icon-left ui-icon-mail\"    ><%= t('sidepanel.instant') %></a>\n" +
        "\t\t<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-instant\"/>\n" +
        "\t</li>\n" +
        "\t<li class=\"principal\">\n" +
        "\t\t<a href=\"#principal-bundle\" class=\"ui-btn ui-btn-icon-left ui-icon-home\"    ><%= t('sidepanel.principal') %><span class=\"newDefects zarro\"></span></a>\n" +
        "\t\t<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-principal\"/>\n" +
        "\t</li>\n" +
        "\t<li class=\"cost\">\n" +
        "\t\t<a href=\"#cost\"       class=\"ui-btn ui-btn-icon-left ui-icon-tag\"           ><%= t('sidepanel.cost') %></a>\n" +
        "\t\t<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-cost\"/>\n" +
        "\t</li>\n" +
        "\t<li class=\"statistics\">\n" +
        "\t\t<a href=\"#statistics\"       class=\"ui-btn ui-btn-icon-left ui-icon-stats\"   ><%= t('sidepanel.statistics') %></a>\n" +
        "\t\t<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-statistics\"/>\n" +
        "\t</li>\n" +
        "\t<li class=\"fe-link\">\n" +
        "\t\t<a href=\"javascript:void(0)\" class=\"ui-btn darkicon ui-btn-icon-left ui-icon-arrow-r\">\n" +
        "\t\t\t<%= t('sidepanel.felink') %>\n" +
        "\t\t</a>\n" +
        "\t</li>\n" +
        "</ul>\n" +
        "<ul class=\"project-sync-links\" data-role=\"listview\" style=\"margin-top: -1px\"></ul>\n" +
        "\n" +
        "<ul data-role=\"listview\" style=\"margin-top: 2.5em\">\n" +
        "\t<li class=\"help\">\n" +
        "\t\t<a href=\"#help\"   data-theme=\"d\"  class=\"darkicon ui-btn ui-btn-icon-left ui-icon-info \"><%= t('sidepanel.help') %></a>\n" +
        "\t\t<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-help\"/>\n" +
        "\t</li>\n" +
        "<!--\tShow the button again when the site is ready-->\n" +
        "\t<li class=\"newslink\">\n" +
        "\t\t<a href=\"#news\" data-theme=\"d\" class=\"newslink darkicon ui-btn ui-btn-icon-left ui-icon-news\"><%= t('sidepanel.whatsnew') %><img id=\"newNewsIndicator\" src=\"images/newNewsIndicator.svg\"></a>\n" +
        "\t</li>\n" +
        "\t<li class=\"settings\">\n" +
        "\t\t<a href=\"#settings\" data-shortcut=\"settingsPage\" class=\"ui-btn darkicon ui-btn-icon-left ui-icon-gear\"><%= t('sidepanel.settings') %></a>\n" +
        "<!--\t\t<a data-popup=\"settings\" class=\"mainMenuPopupButton ui-btn darkicon ui-btn-icon-left ui-icon-gear\"><%= t('sidepanel.settings') %></a><img class=\"popupMenuArrow\" src=\"images/icons-png/carat-r-black.png\">-->\n" +
        "\t\t<!--<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-settings\"/>-->\n" +
        "\t</li>\n" +
        "<!--\t<li class=\"chooseCustomer\">-->\n" +
        "<!--\t\t<a href=\"#choose-customer\"       class=\"ui-btn darkicon ui-btn-icon-left ui-icon-recycle\"><%= t('sidepanel.switchCustomer') %></a>-->\n" +
        "<!--\t\t&lt;!&ndash;<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-settings\"/>&ndash;&gt;-->\n" +
        "<!--\t</li>-->\n" +
        "<!--\t<li>-->\n" +
        "<!--\t\t<a href=\"javascript:void(0)\" class=\"logout darkicon ui-btn ui-btn-icon-left ui-icon-arrow-l\"><%= t('sidepanel.logout') %></a>-->\n" +
        "<!--\t</li>-->\n" +
        "<!--\t<li>-->\n" +
        "<!--\t\t<a href=\"\" target=\"_blank\" class=\"dataprotection openLink darkicon ui-btn ui-btn-icon-left ui-icon-lock\"><%= t('sidepanel.dataprotection') %></a>-->\n" +
        "<!--\t</li>-->\n" +
        "\t<li>\n" +
        "\t\t<a href=\"#account\" class=\"account darkicon ui-btn ui-btn-icon-left ui-icon-home\"><%= t('sidepanel.accountDataProtection') %></a>\n" +
        "\t</li>\n" +
        "</ul>\n" +
        "\n" +
        "<!--<div id=\"accountPopup\" data-role=\"popup\" class=\"mainMenuPopup\">-->\n" +
        "<!--\t<ul data-role=\"listview\" data-inset=\"true\" style=\"/*min-width: 250px*/\">-->\n" +
        "<!--\t\t<li class=\"settings\">-->\n" +
        "<!--\t\t\t<a href=\"#choose-customer\" class=\"ui-btn darkicon ui-btn-icon-left ui-icon-recycle\"><%= t('sidepanel.switchCustomer') %></a>-->\n" +
        "<!--\t\t</li>-->\n" +
        "<!--\t\t<li class=\"settings\">-->\n" +
        "<!--\t\t\t<a href=\"javascript:void(0)\" class=\"myaccount-link ui-btn darkicon ui-btn-icon-left ui-icon-user\"><%= t('sidepanel.myaccount') %></a>-->\n" +
        "<!--\t\t</li>-->\n" +
        "<!--\t\t<li>-->\n" +
        "<!--\t\t\t<a href=\"javascript:void(0)\" class=\"logout darkicon ui-btn ui-btn-icon-left ui-icon-arrow-l\"><%= t('sidepanel.logout') %></a>-->\n" +
        "<!--\t\t</li>-->\n" +
        "<!--\t</ul>-->\n" +
        "<!--</div>-->\n" +
        "\n" +
        "<!--<div id=\"settingsPopup\" data-role=\"popup\" class=\"mainMenuPopup\">-->\n" +
        "<!--\t<ul data-role=\"listview\" data-inset=\"true\" style=\"/*min-width: 250px*/\">-->\n" +
        "<!--\t\t<li class=\"settings\">-->\n" +
        "<!--\t\t\t<a href=\"#settings\" id=\"settingsPageButton\" data-shortcut=\"settingsPage\" class=\"ui-btn darkicon ui-btn-icon-left ui-icon-gear\"><%= t('sidepanel.usersettings') %></a>-->\n" +
        "<!--\t\t\t&lt;!&ndash;<input type=\"checkbox\" data-enhance=\"false\" class=\"check check-settings\"/>&ndash;&gt;-->\n" +
        "<!--\t\t</li>-->\n" +
        "<!--\t\t<li class=\"projectManager adminOnly\">-->\n" +
        "<!--\t\t\t<a data-shortcut=\"projectManager\" class=\"ui-btn\">></a>-->\n" +
        "<!--\t\t</li>-->\n" +
        "<!--\t\t<li class=\"adminTables adminOnly\">-->\n" +
        "<!--\t\t\t<a data-shortcut=\"adminTables\" class=\"ui-btn\">></a>-->\n" +
        "<!--\t\t</li>-->\n" +
        "<!--\t</ul>-->\n" +
        "<!--</div>-->\n" +
        "\n" +
        "<button class=\"toggleEdit ui-btn ui-mini ui-corner-all\" style=\"display: none; margin-top: 3em; width: 240px\"><%= t('sidepanel.apply') %></button>\n" +
        "\n" +
        "<div class=\"ui-footer ui-bar-inherit\" style=\"position: absolute; left: 0; right: 0; bottom: 0; padding-bottom: 1px\">\n" +
        "\t<h4 id=\"sidePanelTitle\" class=\"ui-title\" style=\"margin: 0; background: url(images/ox.png) no-repeat left\">Open Experience 2019</h4>\n" +
        "</div>\n";
    return page;
});