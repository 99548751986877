define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './InstantPageHtml', '../view/Sketcher', '../view/Autocomplete', '../view/ShortcutBaseView',
	'../model/subcontractors', '../model/currentProject', '../model/Defect', '../model/completions', '../model/currentInspection', '../model/user', '../helper/watchdog', '../helper/iosapp', '../model/polygonsAndCfgs', '../model/projectFiles',
	'../helper/rocketMode', '../helper/idutil', '../helper/backboneReactCommunicationUtils'
], function (
	$, _, Backbone, Template, Sketcher, Autocomplete, ShortcutBaseView,
	subcontractors, currentProject, Defect, completions, currentInspection, user, watchdog, iosapp, polygonsAndCfgs, projectFiles,
	rocketModeHelper, idutil, backboneReactCommunicationUtils
) {

	return ShortcutBaseView.extend({

		id: 'instant',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		changed: false,

		events: {
			'vclick .actionstrigger':              'triggerActionsMenu',

			'change .location select':             'selectLocation',
			'change .description': 			       'changeDescription',
			'mouseup .description': 	           'changeDescription',
			'touchend .description': 			   'changeDescription',
			'keyup .description': 			       'changeDescription',

			'change .location input[type="text"]': 'editLocation',
			'change .subcontractors':              'subcontractorChange',
			'change .noDeadline':                  'renderDeadline',
			'change .cost input':                  'editCost',
			'blur   .cost input':                  'editCost',

			'focus .subcontractors': function () {
				this.subcontractors.val('');
			},

            'change .property1': 'customProp1',
            'change .property2': 'customProp2',
 	        'change .property3': 'customProp3',

    		'change input:not(.recipient), select, .sketcher':     'change',

			'click .moreDefectAttachmentOptions': 'toggleMoreDefectAttachmentOptions',
			'click .fileTrigger':                  'triggerFile',
			'click .fileTriggerToPlan': 	'gotoPlanAtLocation',
			'click .feTrigger':                    'triggerFe',
            'click .photoTrigger': 					'triggerPhoto',

			'vclick .send':                        'send',
			'vclick .previousPage':                'goBack',

			'touchstart .qrcodetrigger': 'beginqrcodescan',
			'mousedown  .qrcodetrigger': 'beginqrcodescan'

		},

		initialize: function (options) {
			this.router = options.router;

			this.resize = _.bind(this.resize, this);
			this.send = _.bind(this.send, this);

			this.$el.on('pagebeforeshow', _.bind(function () {
				this.pageVisible = true;
			}, this));
			this.$el.on('pagebeforehide', _.bind(function () {
				this.pageVisible = false;
			}, this));

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);

			this.location           = this.$('.location');
			this.locationText       = this.$('.location input[type="text"]');
			this.descriptionText    = this.$('.description');
			this.subcontractors     = this.$('.subcontractors');
			this.type               = this.$('.type');
			this.recipient          = this.$('.recipient');
			this.offlineWarning     = this.$('.offlineWarning');
			this.$descriptionDialog = this.$('.descriptionDialog');
			this.sendButton         = this.$('.send');
			this.$content           = this.$el.children('[data-role="content"]');
			this.$footer            = this.$('[data-role="footer"]');
			this.$header            = this.$('[data-role="header"]');
			this.$options           = this.$('.options');
			this.$deadline          = this.$('.deadline');
			this.$noDeadline        = this.$('.noDeadline');
			this.$deadlineContainer = this.$('.deadlineContainer');

			if ((iosapp.appavailable || iosapp.androidappavailable) && iosapp.functionavailable('qrcode')) {
				iosapp.addQrCodeHandler(_.bind(function(code) {
					if (code && this.pageVisible) {
						this.selectLocation(null, code, 'usehack');
					}
				}, this));
			} else {
				this.$('.qrcodetrigger').remove();
			}

			new Autocomplete({
				input: this.locationText,

				lookup: function (input) {
					if (!input || input.length < 2) {
						return [];
					}

					var inputRegex = new RegExp(input.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i');
					var foundLocationForLength = 0;
					var selectControl = this.location.find('span');

					var allLocations = [];
					var filteredLocations = [];
					var originLocation = [];

					for (var i = 0; i < selectControl.length; i++) {
						originLocation.push(selectControl[i].textContent);
					}

					var currentLocation = _.clone(originLocation);
					allLocations = completions.locationLookup(currentProject.get('unitTree'), allLocations);
					for (var iAll = 0; iAll < allLocations.length; iAll++) {
						if (allLocations[iAll].search(inputRegex) === -1) {
							continue;
						}
						while (!_.isEmpty(currentLocation)) {
							if (checkForCurrentLocation(currentLocation, allLocations[iAll])) {
								var highlighted = allLocations[iAll].replace(inputRegex, '<b style="background-color:#f2923a;">' + input + '</b>');
								filteredLocations.push(highlighted);
								break;
							}
							currentLocation.pop();
						}
						if (filteredLocations.length > 100) break;
						currentLocation = _.clone(originLocation);
					}

					function checkForCurrentLocation (currentLocation, fromLocation) {
						if (currentLocation.length < foundLocationForLength) {
							return false;
						}
						for (var iCurr = 0; iCurr < currentLocation.length; iCurr++) {
							if ((iCurr != 0 && fromLocation.indexOf(' ' + currentLocation[iCurr] + ' ') === -1)
								|| fromLocation.indexOf(currentLocation[iCurr] + ' ') === -1) {
								return false;
							}
						}
						if (currentLocation.length > foundLocationForLength) {
							foundLocationForLength = currentLocation.length;
							filteredLocations = [];
						}
						return true;
					}

					if (_.isEmpty(filteredLocations)) {
						for (var iAll = 0; iAll < allLocations.length && filteredLocations.length < 100; iAll++) {
							if (allLocations[iAll].search(inputRegex) > -1) {
								var highlighted = allLocations[iAll].replace(inputRegex, '<b style="background-color:#f2923a;">' + input + '</b>');
								filteredLocations.push(highlighted);
							}
						}
					}

					var fragments = completions.lookup(input, completions.get('locationFragments'));

					return filteredLocations.concat(fragments);
				}.bind(this)

			}).render();
			new Autocomplete({
				input: this.descriptionText,
				lookup: function (str) {
					return completions.lookup(str, completions.get('descriptionFragments'))
				}
			}).render();
			new Autocomplete({
				input: this.subcontractors,
				lookup: completions.subcontractorLookup,
				toggleButton: true
			}).render();

			this.sketcher = new Sketcher({
				el:         this.$('.sketcher'),
				photo:      true,
				file:       !user.isPrincipal(),
				readonly:   false,
				color:      'red',
				disabled:   false,//this.model.has('attachments') ? false : 'ohne Internetverbindung nicht verfügbar',
				mode:       'multiple',
				defect:     _.bind(function () { return this.defectToTags(); }, this)
			});

//2015-05-10 instant messaging always possible
			//this.listenTo(watchdog, 'change:connected', _.bind(function () {
			//	if (!this.$el.is(':visible')) {
			//		return;
			//	}
			//	this.render();
			//}, this));

			this.$el.on('pageshow', this.resize);
    		$(window).on('throttledresize', this.resize);
		},

		remove: function() {
			$(window).off('throttledresize', this.resize);
			Backbone.View.prototype.remove.apply(this, arguments);
		},

		render: function () {
			$('.send').off('click');
			$('.send').on('click', function (e) {
				this.send(e);
			}.bind(this));
			$('.previousPage').off('click');
			$('.previousPage').on('click', function (e) {
				this.goBack(e);
			}.bind(this));
			this.router.defectPage.model = undefined;
			if (this.renderedProject !== currentProject.id) {
				this.model = new Defect({ type: this.getDefectType() || "technical" });
				this.renderedProject = currentProject.id;
				this.type.empty();
			}

			if (!this.getDefectType()) {
				alertPopup( user.translate("instant.disallowedByGroup"))
					.then(function() {
						window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject());
					}.bind(this));
				return;
			}

			// COST ===========================================================

			var costType = currentProject.get('costType');
			this.$('.cost-container').parent().toggleClass('cost', !!costType);
			this.$costGlobal   = this.$('.cost-container.global'  ).toggle(costType === 'GLOBAL'   && !user.isSubcontractor());
			this.$costSpecific = this.$('.cost-container.specific').toggle(costType === 'SPECIFIC' && !user.isSubcontractor() || (costType === 'SEPARATE' && (user.isSubcontractor() || user.isPrincipal())));
			this.$costSeparate = this.$('.cost-container.separate').toggle(costType === 'SEPARATE' && !user.isSubcontractor() && !user.isPrincipal());

			// /COST ==========================================================

			this.descriptionText.val(this.model.get('description'));

			var subcontractor = this.model.get('subcontractor');
			subcontractor = subcontractors.findWhere({ id: subcontractor });
			this.subcontractors.val(subcontractor ? subcontractor.getLabel() : '');

			this.sketcher
				.setAddTimestamp(user.get('settings').addTimestamp)
				.setModel(this.model.get('attachments'));
			this.type.val(this.model.get('type')).selectmenu('refresh');

			this.toggleFileTriggerGoToPlan();
			this.updateSketcher();

//2015-05-10 instant messaging always possible
			//var connected = watchdog.isConnected();
			$('.offlineWarning').toggle(false);//!connected);
			//this.sendButton.toggle(connected);

			var defaultDeadline = user.get('settings').instantMessageDefaultDeadlineDays;
			defaultDeadline = (defaultDeadline < 0 ? 0 : defaultDeadline);
			this.$deadline.val(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * defaultDeadline).toISOString().substring(0, 10));
			this.$noDeadline.prop('checked', !user.get('settings').instantMessageDeadline);
			this.$deadline.parent().toggle(user.get('settings').instantMessageDeadline);
			this.$noDeadline.checkboxradio('refresh');
			this.$deadlineContainer.controlgroup('refresh');
			// this.renderDeadline();


			//render defectTypes
            var defectTypes = currentProject.get('types');
            var allowedTypes = this.getFirstRequestAllowedDefectTypes();
            _.forEach(defectTypes, function (type) {
                if (this.type.find('option[value="' + type.defectTypeId + '"]').length === 0) {
                	var $option = $('<option value="' + type.defectTypeId + '">').text(type.content + ' (' + user.translate(type.label) + ')')
					if (!_.contains(allowedTypes, type.defectTypeId)) {
						$option.attr('disabled','disabled');
					}
                    this.type.append($option);
                }
            }.bind(this));

			this.type.val(this.model.get('type')).selectmenu('refresh');

			this.renderLocation();
			this.subcontractorChange();
            this.renderCustomProperties();
			this.renderNavigationElements();
			this.resize();
			
			this.fillEmailField();
			
			if(!user.isPrincipal() && !user.isSubcontractor()) this.automaticallyChooseSubcontractor();
			$('#beforeSketcherDiv').toggle(true);
			return this;
		},
		
		shortcuts: {
			'send': function (e) {
				this.send(e);
			},
			'focusinput1': function () {
				$('#instant .description').focus();
			},
			'focusinput2': function () {
				$('#instant input.subcontractors').focus();
			}
		},
		
		automaticallyChooseSubcontractor: function () {
			if(!currentProject.get('chooseSubAutomatically')) return;
			if(subcontractors.length === 1 && this.subcontractors.val() === '') {
				this.subcontractors.val(subcontractors.models[0].getLabel());
				this.subcontractorChange();
				return;
			}
			// if(!this.checkIfChosenSubIsCrew(this.getAllCrewsOfProject())) return;
			// var crewSubs = [], crew = this.subcontractors.val();
			// subcontractors.forEach(function (s) {
			// 	if(s.get('crew') && s.get('crew') !== '' && crew === s.get('crew')) crewSubs.push(s);
			// })
			// if(crewSubs.length === 1) {
			// 	this.subcontractors.val(crewSubs[0].getLabel());
			// 	this.subcontractorChange();
			// 	this.save();
			// }
		},
		
		checkIfChosenSubIsCrew: function (crews) {
			var currentSub = this.subcontractors.val(), isCrew = false;
			crews.forEach(function (c) {
				if(currentSub && currentSub !== '' && currentSub === c) isCrew = true;
			})
			return isCrew;
		},
		
		getAllCrewsOfProject: function () {
			var crews = [];
			subcontractors.forEach(function (s) {
				if(s.get('crew') && s.get('crew') !== '' && !_.include(crews, s.get('crew'))) crews.push(s.get('crew'));
			})
			return crews;
		},
		
		fillEmailField: function () {
			var defectType = _.find(currentProject.get('types'), function(el) {
				return el.defectTypeId === this.model.get('type');
			}.bind(this));
			if(defectType && defectType.isPrincipal) {
				this.recipient.val(currentProject.get('principalInfo').email);
			} else {
				if (this.subcontractors.val() !== '') {
					var subcontractorId = this.getSubcontractorId();
					this.recipient.val(subcontractors.get(subcontractorId).get('email'));
				} else {
					this.recipient.val('');
				}
			}
		},

		renderLocation: function () {

			var unrecognizedParts = [];
			var lastRecognizedIndex = 0;
			var tree = currentProject.get('unitTree');
			var parts = this.model.get('location') || [];

			var inspectionParts = '';//(currentInspection.get('unit') || '').split('-'); //wacker.2014-06-25: first part not static any more
			var choices = [];
			for (var i = 0; i <= parts.length; i++) {
				var part = i < parts.length ? Defect.toId(parts[i]) : '';
				if (part && i < inspectionParts.length && inspectionParts[i] === part) {
					choices.push($('<button class="ui-btn">').text(parts[i]));
					tree = tree.children[part];
					continue;
				}
				if (tree && tree.children) {
					var choice = $('<select>');
					$('<option>').text('\u2014').val(tree.path).appendTo(choice);
					_.each(_.compact(_.values(tree.children)), function (child) {
						$('<option>')
							.text(child.name)
							.val(child.path)
							.appendTo(choice);
					}, this);
					choices.push(choice);
					if (tree.children[part]) {
						lastRecognizedIndex++;
						tree = tree.children[part];
						choice.val(tree.path);
					} else {
						unrecognizedParts = unrecognizedParts.concat(parts.slice(i));
						//choice.val(tree.path);
						break;
					}
				} else {
					if(!!parts && !!part) {
						unrecognizedParts = unrecognizedParts.concat(parts.slice(i));
					}
				}
			}
			this.location.find('select, button').remove();
			var container = this.location.controlgroup().controlgroup('container');
			for (var j = choices.length - 1; j >= 0; j--) {
				choices[j].prependTo(container);
				if (choices[j].is('select')) {
					choices[j].selectmenu();
				}
			}
			var locationTextValue = '';
			if(unrecognizedParts !== null) {
				locationTextValue = _.map(_.without(unrecognizedParts, ""), function (str) {
					if(!!str) {
						return str.trim();
					}
				}).join(' > ')
				this.locationText.val(locationTextValue);
			}
			var location = this.model.get('location').slice(0,lastRecognizedIndex);
			location.push(locationTextValue);
			this.setModelLocation(location);
			this.location.controlgroup('refresh');

			this.resizeLocation();
		},

		renderCustomProperties: function() {
			this.$('.customProp3 > *').toggle(false).prop('disabled', false).parent().toggleClass('disabled', false).toggleClass('anf', false);
			this.$('.customProp2 > *').toggle(false).prop('disabled', false).parent().toggleClass('disabled', false).toggleClass('anf', false);
			this.$('.customProp1 > *').toggle(false).prop('disabled', false).parent().toggleClass('disabled', false).toggleClass('anf', false);

            this.props = currentProject && currentProject.get('defectTypeProps') && currentProject.get('defectTypeProps')[this.model.get('type')] || [];
			_.each(this.props, _.bind(function(prop, i) {
                var id = i+1;
                if (prop != null) {
                    this.$('.customProp'+id).toggle(true);
                    switch (prop.type){
                        case "BOOLEAN":
                            this.$("label.property"+id).text(prop.label);
                            this.$('.customProp'+id+" > .ui-checkbox").toggle(true);
                            this.$("input.boolean.property"+id)
                                .prop('checked', this.model.get('customProp'+id) === "true")
                                .checkboxradio().checkboxradio('refresh');

                            break;
                        case "STRING":
                            this.$("input.string.property"+id)[0].placeholder = prop.label;
                            this.$('.customProp'+id+" > .ui-input-text").toggle(true);
                            if (this.model.get('customProp'+id) != null) {
                                this.$('input.property'+id).val(this.model.get('customProp'+id));
                            } else {
                                this.$('input.property'+id).val("");
                            }

                            break;
                        case "OPTIONS":
                            this.$('select.property'+id).remove();
                            var el = $("<select>").addClass('property'+id);
                            $('<option class="customprop-none">').text('— ' + prop.label + ' —').appendTo(el);
                            _.each(_.compact(_.values(prop.options.split(','))), function (child) {
                                $('<option>')
                                    .text(child)
                                    .val(child)
                                    .appendTo(el);
                            }, this);
                            if (this.model.get('customProp'+id) != null) {
                                el.val(this.model.get('customProp'+id))
                            };
                            this.$('.customProp'+id).append(el);
                            el.selectmenu();
                            this.$('.customProp'+id+" > .ui-select").toggle(true);

                            break;
                        default:
                            break;
                    }
                } else{
                    this.$('.customProp'+id).toggle(false);
                }
            },this));
            this.resizeLocation();
		},
		
		renderDeadline: function () {
			this.$deadline.parent().toggle(!this.$noDeadline.is(':checked'));
			this.$noDeadline.checkboxradio('refresh');
			this.$deadlineContainer.controlgroup('refresh');
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active') && !self.$el.hasClass('hide-page')) {
					self.sketcher.$el.hide();
					self.sketcher.resize(
						self.$content.width() - 2,
						parseInt(self.$el.css('min-height')) - self.$content.height() - 8
					);
					self.resizeLocation();
					self.resizeSubcontractor();
					self.sketcher.$el.show();
				}
			}, this);
		},

		resizeLocation: function () {

			var availWidth = this.location.width() - 2,//2014-10-29, wacker: added -2 for 'safety'
				locationChoice = this.$('.location .ui-select, .location button');

			locationChoice.css('max-width', Math.floor(availWidth * .7 / locationChoice.length));

			var usedWidth = locationChoice.length > 0 ? Math.ceil($(locationChoice[locationChoice.length - 1]).offset().left + $(locationChoice[locationChoice.length - 1]).outerWidth() - $(locationChoice[0]).offset().left) : 0;
			this.$('.location .ui-input-text').css('width', availWidth - usedWidth - 2);

			this.resizeSubcontractor();
		},

		resizeSubcontractor: function () {
			this.subcontractors.parent().width(this.$options.width() - this.type.parent().outerWidth(true) - 4);
		},

		selectLocation: function (e, choice, hackoption) {
			if (!this.model || !this.model.set) {
				return;
			}
			var location = [];
			var tree = currentProject.get('unitTree');
			if (!choice) {
				choice = $(e.target).val() || '';
			}
			choice = choice.split('-');
			for (var i = 0; i < choice.length; i++) {
				tree = (tree && tree.children) ? tree.children[choice[i]] : null;
				if (tree) {
					location.push(tree.name);
				}
			}

			if (hackoption === 'usehack' && user && user.get('customer') === 'implenia_muenchen' && currentProject && currentProject.get('id') === 'SUDW' ) {
                if (tree && tree.children) {
                    var keys = _.keys(tree.children);
                    if (keys && keys.length === 1) {
                        tree = tree.children[keys[0]];
                        if (tree) {
                            location.push(tree.name);
                        }
                    }
                }
            }

			location.push(this.locationText.val());
			this.setModelLocation(location);
			this.renderLocation();
		},

		editLocation: function () {
			var location = _.clone(this.model.get('location'));
			location[Math.max(0,location.length - 1)] = this.locationText.val();

			if (_.contains(location[location.length - 1], "<b style=" )) {
				//TODO: do refactoring
				var noHTMLString = location[location.length - 1].replace(new RegExp(/(<([^>]+)>)/ig), "");
				var locationParts = noHTMLString.replace(new RegExp(/\s>\s/g), ">").split('>');
				var normalizedLoc = idutil.locationArrayToId(locationParts);
				location[location.length - 1] = "";
				this.locationText.val("");
				this.selectLocation(null, normalizedLoc);
				return;
			}

			this.setModelLocation(location);
		},


		editCost: function () {
			var oldCost   = this.model.get('cost');
			var oldCostAG = this.model.get('costAG');

			switch (currentProject.get('costType')) {
			case 'GLOBAL':
				this.model.set('cost', this.$costGlobal.find('input').prop('checked') ? 1000 : null);
				break;
			case 'SPECIFIC':
				var $input = this.$costSpecific.find('input'), cost = $input.val();
				if (cost === null || ('' + cost).trim().length == 0) {
					cost = null;
				} else if (_.isFinite(cost)) {
					cost = window.parseFloat(cost);
				} else {
					window.alertPopup(user.translate('defect.wrongcost'));
					cost = _.isFinite(oldCost) ? oldCost : null;
				}
				$input.val(cost);
				this.model.set('cost', cost);
				break;
			case 'SEPARATE':
				var $inputAN = this.$costSeparate.find('.an'), costAN = $inputAN.val();
				if (costAN === null || ('' + costAN).trim().length == 0) {
					costAN = null;
				} else if (_.isFinite(costAN)) {
					costAN = window.parseFloat(costAN);
				} else {
					window.alertPopup(user.translate('defect.wrongcost'));
					costAN = _.isFinite(oldCost) ? oldCost : null;
				}
				$inputAN.val(costAN);
				var $inputAG = this.$costSeparate.find('.ag'), costAG = $inputAG.val();
				if (costAG === null || ('' + costAG).trim().length == 0) {
					costAG = null;
				} else if (_.isFinite(costAG)) {
					costAG = window.parseFloat(costAG);
				} else {
					window.alertPopup(user.translate('defect.wrongcost'));
					costAG = _.isFinite(oldCostAG) ? oldCostAG : null;
				}
				$inputAG.val(costAG);
				this.model.set('cost', costAN);
				this.model.set('costAG', costAG);
				break;
			}
		},

        customProp1: function(){
            this.saveCustomValue(1);
        },
        customProp2: function(){
            this.saveCustomValue(2);
        },
        customProp3: function(){
             this.saveCustomValue(3);
        },
        saveCustomValue: function(id){
            switch(this.props[id-1].type){
                case "BOOLEAN":
                    this.model.set('customProp'+id, '' + $('#instant input.boolean.property'+id)[0].checked);
                    break;
                case "STRING":
                    this.model.set('customProp'+id, $('#instant input.string.property'+id)[0].value);
                    break;
                case "OPTIONS":
                    this.model.set('customProp'+id, $('#instant select.property'+id)[0].value);
                    break;
            }
        },


		beginqrcodescan: function(e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			if ((iosapp.appavailable || iosapp.androidappavailable) && iosapp.functionavailable('qrcode')) { //shouldn't be necessary (element is hidden if we are not in the ios app) but safety first
				iosapp.scanQrCode();
			}
		},
		changeDescription: function () {
			this.changed = true;
			var val = this.descriptionText.val();
			this.model.set('description', val);
			if (val) {
				val = val.replace(/↩/g, '\n');
			}
			if (this.descriptionTooLong()) {
				this.showDescriptionPopup();
			}
			this.change(true);
		},

		change: function (noresize) {
			var type = this.type.val();
			if (!type) {
				type = this.type.find('option:first-child').val();
			}
			if(this.model) this.model.set('type', type);
			this.renderCustomProperties();
			if (!noresize) {
				this.resize();
			}
			this.renderNavigationElements();
			this.fillEmailField();
		},
		
		renderNavigationElements: function () {
			var defectType = _.find(currentProject.get('types'), function(el) {
				return el.defectTypeId === this.model.get('type');
			}.bind(this));
			if (currentProject.get('extManual')) {
				
				if (defectType && defectType.isPrincipal) { //this.model.get('type') === 'obstruction2'
					this.$('.mode-defect-type-not-principal > td:first-child').css("display", "none");
					this.$('.mode-defect-type-principal'    ).css("display", "block");
					this.resizeLocation();
					
				} else {
					this.$('.mode-defect-type-not-principal > td:first-child').css("display", "block");
					this.$('.mode-defect-type-principal'    ).css("display", "none");
				}
			} else {
				var width = $(this.el).find('> div.ui-content').width() * 0.7 - 1;
				this.$('.mode-defect-type-not-principal').closest('div').width(!(defectType && defectType.isPrincipal) ? width + 'px' : '0%');
				this.type.closest('div.ui-select').width(!(defectType && defectType.isPrincipal) ? '30%' : '100%');
				this.$('.mode-defect-type-not-principal').closest('div').toggle(!(defectType && defectType.isPrincipal));
			}
		},
		
		showDescriptionPopup: function () {
			this.$descriptionDialog.show().popup('open');
			var description = this.descriptionText.val();
			description = description.replace(/↩/g, '\n');
			var $textarea = this.$descriptionDialog.find('textarea').val(description).textinput('refresh');
			$textarea[0].setSelectionRange(this.descriptionText[0].selectionStart, this.descriptionText[0].selectionEnd);
			$textarea.focus();
			var $buttons = this.$descriptionDialog.find('button');
			$buttons.one('vclick', _.bind(function (e) {
				e.preventDefault();
				e.stopPropagation();
				$buttons.off('vclick');
				var val = $textarea.val();
				valwonl = val.replace(/(?:\r\n|\r|\n)/g, '↩');
				this.descriptionText.val(valwonl).textinput('refresh');
				this.model.set('description', val);
				this.$descriptionDialog.popup('close');
			}, this));
		},
		descriptionTooLong: function () {
			return this.$('.descriptionlengthindicator').text(this.descriptionText.val()).width() > .9 * this.descriptionText.width();
		},

		setNewModel: function () {
			this.changed = false;
			this.recipient.val('');
			this.model = new Defect({ type: this.getDefectType() || "technical" });
			this.render();
		},

		triggerMenu: function () {
			return this.confirmation();
		},
		
		isActionsMenuOpen: function () {
			return $('div.ui-popup-container.ui-popup-active').length > 0;
		},

		triggerActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.$('#actionsmenu').popup('open', { transition: 'flip', positionTo: '.actionstrigger' });
		},
		
		closeActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.$('#actionsmenu').popup('close', { transition: 'flip', positionTo: this.$('.actionstrigger') });
		},

		triggerFile: function () {
			this.router.triggerFiles(currentProject.id, currentInspection.id, false);
		},

        triggerPhoto: function() {
            this.sketcher.triggerPhoto(this.model);
        },

		triggerFe: function() {
            this.sketcher.triggerFe(this.model);
        },

		send: function (e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			var defectType = _.find(currentProject.get('types'), function(el) {
				return el.defectTypeId === this.model.get('type');
			}.bind(this));
			if ((!this.model.get('subcontractor') && defectType && !defectType.isPrincipal) || !this.recipient.val().trim()) {
				this.savePending = false;
				alertPopup(user.translate('instant.incomplete'));
				return $.Deferred().reject();
			}

			if (this.savePending) {
				return $.Deferred().reject();
			}
			this.savePending = true;

			var attachments = [];
			if (!this.sketcher.isEmpty()) {
				attachments = this.sketcher.model;
			}

			//make sure that location is not empty (happens only when location or location text was not changed), and add the empty element to it;
            var notEmptyLocation = _.cloneDeep(this.model.get('location'));
			if(notEmptyLocation.length <= 0) {
                notEmptyLocation.push("");
			}

			this.model.set({
				project:       currentProject.id,
				external:      false,
				attachments:   attachments,
				recipient:     this.recipient.val(),
				deadline:      !this.$noDeadline.is(':checked') ? this.$deadline.val() : null,
				location:		notEmptyLocation
			});

			var result = $.Deferred(),
				saveOffline = _.bind(function () {
					return this.model.save()
					.done(_.bind(function () {

						currentDefects.add(this.model);
						completions.addString(this.locationText.val(), completions.get('locationFragments'));
						completions.addString(this.descriptionText.val(), completions.get('descriptionFragments'));

						alertPopup(user.translate('instant.sentoffline')).done(_.bind(function () {
							this.setNewModel();
							if (e) {
								window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject());
							}
							result.resolve();
						}, this));

					}, this))
					.fail(_.bind(function () {
						result.reject();
						alertPopup(user.translate('instant.notsent').replace('%s', this.model.get('recipient')));
					}, this))
					.always(_.bind(function () {
						this.savePending = false;
						$.mobile.loading('hide');
					}, this));
				}, this);

			$.mobile.loading('show');

			if (!watchdog.isConnected()) {
				saveOffline();
				return result;
			}

			Backbone.ajax({
				type: 'POST',
				url:  '/onlineBauabnahme/api/instantrequest',
				data: JSON.stringify(this.model.toJSON()),
				contentType: 'application/json'
			})
			.done(_.bind(function (sendStatus) {
				this.savePending = false;
				$.mobile.loading('hide');
				if (sendStatus.success) {

					completions.addString(this.locationText.val(), completions.get('locationFragments'));
					completions.addString(this.descriptionText.val(), completions.get('descriptionFragments'));

					alertPopup(user.translate('instant.sent')).done(_.bind(function () {
						this.setNewModel();
						if (e) {
							window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject());
						}
						result.resolve();
					}, this));
				} else {
					result.reject();
					alertPopup(user.translate('instant.notsent').replace('%s', sendStatus.failedAddresses.join('\n')));
				}
			}, this))
			.fail(saveOffline);

			return result;
		},

		subcontractorChange: function (e) {
			if (this.subcontractors.val() === '') {
				return;
			}

			var subcontractorId = this.getSubcontractorId();
			if (subcontractorId == null) {
				alertPopup(user.translate('defect.wrongsubcontractor'));
				return;
			}

			this.recipient.val(subcontractors.get(subcontractorId).get('email'));
			this.model.set('subcontractor', subcontractorId);
		},

		getSubcontractorId: function () {
			var subcontractorLabel = this.subcontractors.val();
			var subcontractor = subcontractors.find(function (model) { return model.getLabel() === subcontractorLabel; });
			return subcontractor ? subcontractor.id : null;
		},

		confirmation: function () {
			if (!this.changed) {
				return $.Deferred().resolve();
			}
			return yesNoCancelPopup(user.translate('instant.sent.confirm'))
			.then(_.bind(function () {
				var deferred = $.Deferred();
				this.send()
				.fail(function () {
					deferred.reject();
				})
				.done(function () {
					deferred.resolve();
				});
				return deferred.promise();
			}, this), _.bind(function (option) {
				if (option === 'no') {
					this.setNewModel();
					return $.Deferred().resolve().promise();
				}
			}, this));
		},

		goBack: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.confirmation().done(_.bind(function () {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject());
				}, this));
		},

		defectToTags: function () {
			//TODO: replace with defectToTags in DefectPage.js
			var result = [];
			result.push('_packet:general');
			result.push('_customer:' + user.get('customer'));
			result.push('_project:' + currentProject.get('id'));
			if (this.model.has('description')) {
				result.push('Beschreibung:' + this.model.get('description'));
			}
			var subcontractorId = this.model.get('subcontractor');
			if (subcontractorId) {
				var subcontractor = subcontractors.get();
				var subcontractor = subcontractors.get();
				result.push('_subcontractor:' + subcontractors.get(subcontractorId).getLabel());
				result.push('_subcontractor_name:' + subcontractors.get(subcontractorId).get('name'));
				result.push('_subcontractor_crew:' + subcontractors.get(subcontractorId).get('crew'));
			}
			result.push('_external:' + this.model.get('external'));
			var structureKeys = currentProject.get('structureKeys');
			var locationTagValue = '';
			_.each(this.model.get('location'), function (location, i) {
				if (!(location || '').trim()) {
					return;
				}
				locationTagValue += location + '::';
				// result.push((i < structureKeys.length && structureKeys[i] ? structureKeys[i] : 'Ebene ' + (i + 1)) + ':' + location);
				// result.push('_level' + i + ':' + location);
			}, this);
			var locationLast = this.model.get('location').slice(-1)[0];
			if (locationLast && locationLast.trim()) {
                //freetext is non-empty
            } else {
                //freetext is empty, add :: to make ios happy (for now, remove after update)
                if (iosapp.appavailable && !iosapp.androidappavailable && !iosapp.windowsappavailable) {
                    locationTagValue = locationTagValue + '::';
                }
            }
			if (locationTagValue) {
				result.push('_location:' + locationTagValue);
			}
            if(this.model.get('type')){
                result.push('_scope:' + this.model.get('type'));
            }

			return result;
		},

		getFirstRequestAllowedDefectTypes: function() {
			if (currentProject.get('groupFilterActive')) {
				var types = _(_.pairs(currentProject.get('groupDefectTypes') || {}))
					.filter(function (i) {
						return _.contains(i[1], 1);
					})
					.map(function (i) {
						return i[0];
					})
					.value();
				return types;
			} else {
				return _.pluck(currentProject.get('types'), 'defectTypeId');
			}
		},

		getDefectType: function() {
			var allowedTypes = this.getFirstRequestAllowedDefectTypes();
			if (_.contains(allowedTypes, 'technical')) {
				return 'technical';
			} else {
				return allowedTypes && allowedTypes.length > 0 && allowedTypes[0] || null;
			}
		},

		setModelLocation: function(location){
			this.model.set('location', location);
			this.toggleFileTriggerGoToPlan();
		},

		gotoPlanAtLocation: function(){
			var locationToProcess = rocketModeHelper.cleanLocationId(this.model.get('locationId'));

			if (!locationToProcess && this.model.get('location').length > 0){ // check if locationID was not loaded fast enough
				locationToProcess = rocketModeHelper.locationToLocationId(this.model.get('location'))
			}

			if (Object.keys(polygonsAndCfgs.get('polygons')).length && !!locationToProcess && locationToProcess.length){

				var locationToCheck = rocketModeHelper.getLocationToCheckForFileHashes(locationToProcess);

				var fileHashs = rocketModeHelper.getFileIdsToLocation(locationToCheck)
				var allFiles = rocketModeHelper.getAllPlanFilesOfProject(projectFiles.get('children'))
				var rocketModeFiles = [];
				// the following is needed because there could be multiple files with the same file hash
				allFiles.forEach(function (item) {
					if (_.indexOf(Object.keys(fileHashs), item.get('hash')) !== -1) {
						rocketModeFiles.push(item);
					}
				}, this);

				if(!fileHashs){
					this.triggerFile();
				}else if (rocketModeFiles.length === 1 ){
					var fileModel = rocketModeFiles[0];
					if (fileModel){
						this.router.filesPage.openImageInRocketMode(fileModel, locationToProcess);
					}else{
						this.triggerFile() // fallback
					}
				}else{
					this.router.filesPage.rocketModeFiles = rocketModeFiles;
					this.triggerFile();
				}
			}else{
				// should not be reached
				this.triggerFile();
			}
		},

		updateSketcher: function () {
			var canVerify = this.model.canVerify();
			this.sketcher
				.setReadonly(this.readonly && !(canVerify && user.isSubcontractor()) || 'onlyOld')//.setReadonly(canVerify && user.isSubcontractor() ? 'onlyOld' : this.readonly)//.setReadonly(this.readonly || 'onlyOld')
				.setDisabled(this.model.has('attachments') ? false : user.translate('defect.offlineimage'))
				.setAufmass(this.model.get('type') === 'aufmass')
				.clearPostProcessing();

			if (canVerify) {
				this.sketcher.addPostProcessing(function () {
					var text = user.translate('defect.image.verification.' + canVerify);
					var textRenderer = new Sketcher.TextRenderer();
					textRenderer.verticalAlign = 'top';
					textRenderer.fontSize = 15;
					textRenderer.call(this, text, 0, 0);
				});
			}

			this.sketcher.$el.toggleClass('verification', canVerify);
			this.sketcher.render();
		},

		toggleMoreDefectAttachmentOptions: function(){
			// same as in DefectPage.js
			this.sketcher.moreDefectAttachmentOptionsVisible = !this.sketcher.moreDefectAttachmentOptionsVisible;
			this.sketcher.updateTrigger();
		},

		toggleFileTriggerGoToPlan: function(){
			var polys = Object.keys(polygonsAndCfgs.get('polygons'));
			var locID = rocketModeHelper.cleanLocationId(this.model.get('locationId'));
			if (!locID && this.model.get('location').length > 0){ // check if locationID was not loaded fast enough
				locID = rocketModeHelper.locationToLocationId(this.model.get('location'))
			}
			var found = false;
			if (polys && locID && polys.length) {
				for (var i = 0; i < polys.length; i++) {
					if (polys[i].substring(0, locID.length) === locID) {
						found = true;
						break;
					}
				}
			}
			if(found){
				this.sketcher.plansOfCurrentLocationAreAvailable = true;
			}else{
				this.sketcher.plansOfCurrentLocationAreAvailable = false;
			}
			this.sketcher.updateTrigger();
		},
	});
});