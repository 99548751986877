define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../model/user', '../helper/watchdog', 'moment',
	'../helper/interactiveSync', '../model/proxyInfo'
], function (
	$, _, Backbone, user, watchdog, moment,
	interactiveSync, proxyInfo
) {
	return new (Backbone.Model.extend({

		defaults: function () {
			return {
				subcontractorId: user.isSubcontractor() ? this.id : null,
				externalOnly:    user.isPrincipal()
			};
		},

		urlRoot: function () {
			 return  '/onlineBauabnahme/api/projectStats/' + this.project;
		},

		initialize: function () {
			this.listenTo(this, 'change:subcontractorId change:externalOnly', function () {
				this.dirty = true;
			});
			this.listenTo(user, 'change:settings', function () {
				if (user.changed.settings) {
					var currentShowStatsSetting = user.get('settings') && user.get('settings').showStats;
					var previousShowStatsSetting = user.changed.settings.showStats;
					if (currentShowStatsSetting != previousShowStatsSetting) {
						this.dirty = true;
					}
				}
			});
		},

		setProject: function (project) {
			if (project != this.project) {
				this.clear({ silent: true });
				this.set(this.defaults());
				this.dirty = true;
			}
			this.project = project;
			return this.getStats();
		},

		sync: function (method, model, options) {
			return interactiveSync.sync.apply(interactiveSync, [method, model, proxyInfo.syncAddHeaders(options)]);
		},

		fetch: function (options) {
			options = options || {};
			var data = options.data = {};
			if (this.get('subcontractorId') != null) {
				data.subcontractorId = this.get('subcontractorId');
			}
			if (this.get('externalOnly') != null) {
				data.externalOnly = this.get('externalOnly');
			}
			return this.fetchInProgress = Backbone.Model.prototype.fetch.call(this, options)
				.always(_.bind(function () {
					this.fetchInProgress = null;
				}, this))
				.then(_.bind(function () {
					this.dirty = false;
				}, this));
		},

		getStats: function (subcontractorId, externalOnly) {
			if (arguments.length) {
				this.set('subcontractorId', subcontractorId);
				this.set('externalOnly', externalOnly);
			}
			if (this.fetchInProgress) {
				return this.fetchInProgress;
			} else if (this.fetchNeeded() && watchdog.isConnected()) {
				return this.fetch();
			} else {
				return $.Deferred().resolve();
			}
		},

		fetchNeeded: function () {
			return this.dirty;
		},

		parse: function (response) {
			var statsArray = response.stats;

			if (statsArray.length == 0) {
				unsetStats.call(this);
				return;
			}

			try {
				var startDate = moment([ response.start.year, 7 ])
					.isoWeek(response.start.week)
					.isoWeekday(1);

				var endDate = moment([ response.end.year, 7 ])
					.isoWeek(response.end.week)
					.isoWeekday(1);

				var numOfWeeks = 1 + endDate.diff(startDate, 'weeks');

				var formattedStats = {
					weeks:      new Array(numOfWeeks + 1),
					countAll:   new Array(numOfWeeks + 1),
					countFixed: new Array(numOfWeeks + 1),
					deltaAll:   new Array(numOfWeeks + 1),
					deltaFixed: new Array(numOfWeeks + 1)
				};
				var yearLines = [];
				var date = moment(startDate);
				var previousYear = date.isoWeekYear();

				formattedStats.weeks[0] = 'weeks';
				formattedStats.countAll[0] = 'countAll';
				formattedStats.countFixed[0] = 'countFixed';
				formattedStats.deltaAll[0] = 'deltaAll';
				formattedStats.deltaFixed[0] = 'deltaFixed';

				for (var i = 0, nextI = 1; i < numOfWeeks; i++, nextI++, previousYear = date.isoWeekYear(), date.add('weeks', 1)) {
					var stat = statsArray[i];
					var previousStat = statsArray[i - 1];

					formattedStats.weeks[nextI] = /*date.weekYear() + '-' + */date.isoWeek();
					formattedStats.countAll[nextI] = stat.countAll;
					formattedStats.countFixed[nextI] = stat.countFixed;
					formattedStats.deltaAll[nextI] = stat.countAll - (i - 1 >= 0 ? previousStat.countAll : 0);
					formattedStats.deltaFixed[nextI] = stat.countFixed - (i - 1 >= 0 ? previousStat.countFixed : 0);

					if (previousYear != date.isoWeekYear()) {
						yearLines.push({ value: i - 0.5, text: date.isoWeekYear() })
					}
				}

				var deltaLast4Weeks = _.partial(deltaLast, 4),
				    deltaLastWeek = _.partial(deltaLast, 1);

				var tmp1, tmp2;
				return {
					stats:                               _.values(formattedStats),
					yearLines:                           yearLines,

					lastWeekAll:                         tmp1 = deltaLastWeek(formattedStats.countAll),
					lastWeekFixed:                       tmp2 = deltaLastWeek(formattedStats.countFixed),
					lastWeekOpen:                        tmp1 - tmp2,
					lastWeekSubcontractorOkAndPending:   deltaLastWeek(statsArray, 'countSubcontractorOkAndPending'),

					last4WeeksAll:                       tmp1 = deltaLast4Weeks(formattedStats.countAll),
					last4WeeksFixed:                     tmp2 = deltaLast4Weeks(formattedStats.countFixed),
					last4WeeksOpen:                      tmp1 - tmp2,
					last4WeeksSubcontractorOkAndPending: deltaLast4Weeks(statsArray, 'countSubcontractorOkAndPending'),

					totalAll:                            tmp1 = formattedStats.countAll[formattedStats.countAll.length - 1],
					totalFixed:                          tmp2 = formattedStats.countFixed[formattedStats.countFixed.length - 1],
					totalOpen:                           tmp1 - tmp2,
					totalSubcontractorOkAndPending:      statsArray[statsArray.length - 1].countSubcontractorOkAndPending,

					deadlineSummary:                     response.deadlineSummary
				};
			} catch (e) {
				//console.warn('error occurred when parsing stats, ', e);
				unsetStats.call(this);
			}

			function deltaLast(n, array, key) {
				var one = array[array.length - 1];
				var another = array[array.length - n - 1];
				if (key) {
					one = one[key];
					another = another != null ? another[key] : 0;
				} else if (another == null) {
					another = 0;
				}
				if (formattedStats.countAll.length > n) {
					return one - another;
				} else {
					return one;
				}
			}

			function unsetStats () {
				this.unset('stats');
				this.unset('statsStart');
				this.unset('statsEnd');
			}
		}

	}))();
});