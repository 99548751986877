define([
    '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../helper/interactiveSync'
], function (
    _, Backbone, interactiveSync
) {
    return Backbone.Model.extend({

        sync: function () {
            return interactiveSync.sync.apply(interactiveSync, arguments);
        },

        getPath: function () {
            var location = this.get('location').slice();
            for (var i = 0; i < location.length; i++) {
                location[i] = Defect.toId(location[i]);
            }
          return location.join('-');
        },
        
        isEmpty: function () {
            return (this.get('firstName') === '' || this.get('firstName') === null) &&
                (this.get('surname') === '' || this.get('surname') === null) &&
                (this.get('address') === '' || this.get('address') === null) &&
                (this.get('phone') === '' || this.get('phone') === null) &&
                (this.get('mobile') === '' || this.get('mobile') === null) &&
                (this.get('email') === '' || this.get('email') === null) &&
                (this.get('sex') === '' || this.get('sex') === null) &&
                (this.get('type') === '' || this.get('type') === null);
        }

    });
});
