define([
	'../lib/backbone-1.1.2', '../model/currentProject',
	'../helper/interactiveSync'
], function (
	Backbone, currentProject,
	interactiveSync
) {
	return Backbone.Model.extend({

		defaults: function () {
			return {
				folder: {
					children: []
				}
			}
		},

		urlRoot: function () {
			 return  '/onlineBauabnahme/api/subcontractorPaperworkInfo?project=' + window.encodeURIComponent(currentProject.id) + '&subcontractor=' + this.get('subcontractor');
		},

		sync: function () {
			return interactiveSync.sync.apply(interactiveSync, arguments);
		}

	});
});