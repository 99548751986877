import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {getCustomerProjectWithPrefix, getProjectIdFromCustomerProject, translate} from "../utils/utils";
import {page} from "../utils/constants";


const BuyerRoles = () => {
    const {customerProject} = getCustomerProjectWithPrefix();
    const project = getProjectIdFromCustomerProject(customerProject);

    useEffect(()=>{
        // @ts-ignore
        window.buyerRolesUseEffect(project);
    },[])


    return <PageContainer pageId={page.BUYER_ROLES} newPage={false} headerText={translate("settings.buyerroles.title")}
    left={<StyledButton className="back">{translate("settings.buyerroles.backtoaccess")}</StyledButton>}
    />
}

export default BuyerRoles;