define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" data-position=\"fixed\">\n" +
        "\n" +
        "\t<button data-shortcut=\"leftMenu\" class=\"menutrigger ui-btn ui-btn-left ui-btn-inline ui-icon-bars ui-btn-icon-notext ui-corner-all\" style=\"display: none\"></button>\n" +
        "\n" +
        "\t<h1 data-role=\"heading\"><%= t('serviceName') %></h1>\n" +
        "\n" +
        "\t<div class=\"ui-btn-right ui-btn-inline options\">\n" +
        "\t\t<button data-shortcut=\"rightMenu\" class=\"actionstrigger ui-btn ui-btn-inline ui-icon-dots ui-btn-icon-notext ui-corner-all\"></button>\n" +
        "\t</div>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "<!--\t<select class=\"projects\">-->\n" +
        "<!--\t\t<option></option>-->\n" +
        "<!--\t</select>-->\n" +
        "\t<input id=\"chooseProject\" data-shortcut=\"focusInput1\" data-inputname=\"projectSelection\" type=\"text\" data-wrapper-class=\"controlgroup-textinput ui-btn\" placeholder=\"<%- t('settings.project') %>\"/>\n" +
        "\n" +
        "\t<div class=\"no-project-selected\" style=\"position: absolute; bottom: 3em; right: 1em; text-align: right\">\n" +
        "\t\t<div class=\"projectManagerButtons\">\n" +
        "<!--\t\t\t<p style=\"position: relative; display: none\"><a href=\"javascript:void(0)\" class=\"duplicate-test\">Duplicate test</a></p>-->\n" +
        "\t\t\t<p style=\"position: relative\"><a href=\"javascript:void(0)\" class=\"download-statistics ui-icon-arrow-d ui-btn-icon-right\">projektübergreifende Statistik</a></p>\n" +
        "\t\t\t<p><a href=\"javascript:void(0)\" class=\"projectmanager\" data-shortcut=\"projectManager\">zum Projektmanager</a></p>\n" +
        "\t\t</div>\n" +
        "\t\t<p><a href=\"javascript:void(0)\" class=\"logout\">Logout</a></p>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"projectbuttons\">\n" +
        "\t\t<button class=\"syncplans ui-btn ui-btn-icon-right ui-corner-all ui-icon-recycle dontshowicon\"><%= t('overview.plans.sync') %></button>\n" +
        "\t\t<button class=\"syncattachments ui-btn ui-btn-icon-right ui-corner-all ui-icon-recycle dontshowicon\"><%= t('overview.defect.images.sync') %></button>\n" +
        "\t</div>\n" +
        "<!--\t<iframe id=\"news\" src=\"<%- contextPath %>/news/OverviewPageNews.html\"  scrolling=\"yes\" frameborder=\"0\" style=\"visibility:hidden;\" onload=\"this.style.visibility = 'visible';\"></iframe>-->\n" +
        "\t<div class=\"projectinfo\">\n" +
        "\t\t<p class=\"headline clearfix\"><button class=\"edit editHeadline ui-btn ui-btn-inline\"></button></p>\n" +
        "\t\t<div class=\"imageContainer clearfix\">\n" +
        "\t\t\t<input type=\"file\" data-enhance=\"false\">\n" +
        "\t\t\t<img src=\"\" class=\"image\"/>\n" +
        "\t\t\t<button class=\"edit uploadImage ui-btn ui-btn-inline\"><%= t('overview.edit.image') %></button>\n" +
        "\t\t</div>\n" +
        "\t\t<table></table>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"subcontractorActions\">\n" +
        "\t\t<p class=\"headline\"><%= t('overview.directlinks.title') %></p>\n" +
        "\t\t<ul data-role=\"listview\" class=\"units defectButtonsList\">\n" +
        "\t\t\t<li class=\"ui-li-static ui-body-inherit ui-first-child\">\n" +
        "\t\t\t\t<div class=\"ui-grid-a\">\n" +
        "\t\t\t\t\t<button  style=\"width: 100%\" class=\"openDefects ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<%= t('overview.directlinks.opendefects') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t</li>\n" +
        "\t\t\t<li class=\"ui-li-static ui-body-inherit ui-first-child\">\n" +
        "\t\t\t\t<div class=\"ui-grid-a\">\n" +
        "\t\t\t\t\t<button  style=\"width: 100%\" class=\"allDefects ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<%= t('overview.directlinks.alldefects') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t</li>\n" +
        "\t\t\t<li class=\"ui-li-static ui-body-inherit ui-first-child\">\n" +
        "\t\t\t\t<div class=\"ui-grid-a\">\n" +
        "\t\t\t\t\t<button href=\"#documents\" style=\"width: 100%\" class=\"allRequests ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<inline id=\"unreadRequestsNumber\">0</inline> <%= t('overview.directlinks.unreadRequests') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t</li>\n" +
        "\t\t</ul>\n" +
        "\t</div>\n" +
        "\t<div class=\"principalGroupNote\">\n" +
        "\t\tSie sind Teil der Gruppe XXX. Nicht alle Positionen werden dargestellt.\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"defectActions\">\n" +
        "\t\t<p class=\"headline\"><%= t('overview.actions.title') %></p>\n" +
        "\t\t<!-- <div class=\"ui-btn-inline newDefectContainer\"><button style=\"max-width: 200px\" class=\"newDefect newDefectOverviewButton ui-btn ui-btn-inline  ui-btn-icon-left ui-icon-plus ui-corner-all\">' + user.translate('defects.newdefect') + '</button></div> -->\n" +
        "\t\t<p class=\"groupNote\">Sie sind Teil der Gruppe XXX. Nicht alle Positionen werden dargestellt.</p>\n" +
        "\t\t<ul data-role=\"listview\" class=\"units defectButtonsList\">\n" +
        "\t\t\t<li class=\"ui-li-static ui-body-inherit ui-first-child\">\n" +
        "\t\t\t\t<!-- Normal view -->\n" +
        "\t\t\t\t<div class=\"ui-grid-a overview-normal-view\">\n" +
        "\t\t\t\t\t<button class=\"noSubcontractor ui-btn ui-block-a\" style=\"text-align: left;\">\n" +
        "\t\t\t\t\t\t<div class=\"count\" style=\"width: 100%\">\n" +
        "\t\t\t\t\t\t\t<table style=\"width:100%\">\n" +
        "\t\t\t\t\t\t\t\t<tr style=\"width:90%\">\n" +
        "\t\t\t\t\t\t\t\t\t<td style=\"width:15%; text-align: center\"><span class=\"noSubLbl-overview-filter\" style=\"font-weight: bold\">0</span></td>\n" +
        "\t\t\t\t\t\t\t\t\t<td style=\"width:85%\"><span class=\"hideActionsLbl\"><%= t('actions.filter.nosub') %></span></td>\n" +
        "\t\t\t\t\t\t\t\t</tr>\n" +
        "\t\t\t\t\t\t\t</table>\n" +
        "\t\t\t\t\t\t</div>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t\t<button class=\"noSubcontractor ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<%= t('actions.action.nosub') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t\t<!-- Mobile view -->\n" +
        "\t\t\t\t<div class=\"ui-grid-a overview-mobile-view\">\n" +
        "\t\t\t\t\t<button class=\"noSubcontractor ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t <span class=\"noSubLbl-overview-filter\" style=\"font-weight: bold\">0</span> <%= t('actions.action.nosub.mobile') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t</li>\n" +
        "\t\t\t<li class=\"ui-li-static ui-body-inherit ui-first-child\">\n" +
        "\t\t\t\t<!-- Normal view -->\n" +
        "\t\t\t\t<div class=\"afterDeadline1-action ui-grid-a overview-normal-view\">\n" +
        "\t\t\t\t\t<button class=\"ui-btn ui-block-a\" style=\"text-align: left;\">\n" +
        "\t\t\t\t\t\t<div class=\"count\" style=\"width: 100%\">\n" +
        "\t\t\t\t\t\t\t<table style=\"width:100%\">\n" +
        "\t\t\t\t\t\t\t\t<tr style=\"width:90%\">\n" +
        "\t\t\t\t\t\t\t\t\t<td style=\"width:15%; text-align: center\"><span class=\"overdueLbl1-overview-filter\" style=\"font-weight: bold;\">0</span></td>\n" +
        "\t\t\t\t\t\t\t\t\t<td style=\"width:85%\"><span class=\"hideActionsLbl\"><%= t('overview.filter.overdue1') %></span></td>\n" +
        "\t\t\t\t\t\t\t\t</tr>\n" +
        "\t\t\t\t\t\t\t</table>\n" +
        "\t\t\t\t\t\t</div>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t\t<button class=\"afterDeadline1-action ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<%= t('overview.action.overdue1') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t\t<!-- Mobile view -->\n" +
        "\t\t\t\t<div class=\"ui-grid-a overview-mobile-view\">\n" +
        "\t\t\t\t\t<button class=\"afterDeadline1-action ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<span class=\"overdueLbl1-overview-filter\" style=\"font-weight: bold;\">0</span> <%= t('overview.action.overdue1.mobile') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t</li>\n" +
        "\t\t\t<li class=\"ui-li-static ui-body-inherit ui-first-child\">\n" +
        "\t\t\t\t<!-- Normal view -->\n" +
        "\t\t\t\t<div class=\"ui-grid-a overview-normal-view\">\n" +
        "\t\t\t\t\t<button class=\"afterDeadline2-action ui-btn ui-block-a\" style=\"text-align: left;\">\n" +
        "\t\t\t\t\t\t<div class=\"count\" style=\"width: 100%\">\n" +
        "\t\t\t\t\t\t\t<table style=\"width:100%\">\n" +
        "\t\t\t\t\t\t\t\t<tr style=\"width:90%\">\n" +
        "\t\t\t\t\t\t\t\t\t<td style=\"width:15%; text-align: center\"><span class=\"overdueLbl2-overview-filter\" style=\"font-weight: bold;\">0</span></td>\n" +
        "\t\t\t\t\t\t\t\t\t<td style=\"width:85%\"><span class=\"hideActionsLbl\"><%= t('overview.filter.overdue2') %></span></td>\n" +
        "\t\t\t\t\t\t\t\t</tr>\n" +
        "\t\t\t\t\t\t\t</table>\n" +
        "\t\t\t\t\t\t</div>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t\t<button class=\"afterDeadline2-action ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<%= t('overview.action.overdue2') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t\t<!-- Mobile view -->\n" +
        "\t\t\t\t<div class=\"ui-grid-a overview-mobile-view\">\n" +
        "\t\t\t\t\t<button class=\"afterDeadline2-action ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<span class=\"overdueLbl2-overview-filter\" style=\"font-weight: bold;\">0</span> <%= t('overview.action.overdue2.mobile') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t</li>\n" +
        "\t\t\t<li class=\"ui-li-static ui-body-inherit ui-first-child\">\n" +
        "\t\t\t\t<!-- Normal view -->\n" +
        "\t\t\t\t<div class=\"ui-grid-a overview-normal-view\">\n" +
        "\t\t\t\t\t<button class=\"subcontractor-all-changed-action ui-btn ui-block-a\" style=\"text-align: left;\">\n" +
        "\t\t\t\t\t\t<div class=\"count\" style=\"width: 100%\">\n" +
        "\t\t\t\t\t\t\t<table style=\"width:100%\">\n" +
        "\t\t\t\t\t\t\t\t<tr style=\"width:90%\">\n" +
        "\t\t\t\t\t\t\t\t\t<td style=\"width:15%; text-align: center\"><span class=\"openNotRejctedLbl-overview-filter\" style=\"font-weight: bold;\">0</span></td>\n" +
        "\t\t\t\t\t\t\t\t\t<td style=\"width:85%\"><span class=\"hideActionsLbl\"><%= t('actions.filter.subcontractor-fixed') %></span></td>\n" +
        "\t\t\t\t\t\t\t\t</tr>\n" +
        "\t\t\t\t\t\t\t</table>\n" +
        "\t\t\t\t\t\t</div>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t\t<button class=\"subcontractor-all-changed-action ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<%= t('actions.action.subcontractor-all-changed') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t\t<!-- Mobile view -->\n" +
        "\t\t\t\t<div class=\"ui-grid-a overview-mobile-view\">\n" +
        "\t\t\t\t\t<button class=\"subcontractor-all-changed-action ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<span class=\"openNotRejctedLbl-overview-filter\" style=\"font-weight: bold;\">0</span> <%= t('actions.action.subcontractor-all-changed.mobile') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t</li>\n" +
        "\t\t\t<li class=\"ui-li-static ui-body-inherit ui-first-child\">\n" +
        "\t\t\t\t<!-- Normal view -->\n" +
        "\t\t\t\t<div class=\"ui-grid-a overview-normal-view\">\n" +
        "\t\t\t\t\t<button class=\"solved-rejected ui-btn ui-block-a\" style=\"text-align: left;\">\n" +
        "\t\t\t\t\t\t<div class=\"count\" style=\"width: 100%\">\n" +
        "\t\t\t\t\t\t\t<table style=\"width:100%\">\n" +
        "\t\t\t\t\t\t\t\t<tr style=\"width:90%\">\n" +
        "\t\t\t\t\t\t\t\t\t<td style=\"width:15%; text-align: center\"><span class=\"solvedRejectedLbl-overview-filter\" style=\"font-weight: bold;\">0</span></td>\n" +
        "\t\t\t\t\t\t\t\t\t<td style=\"width:85%\"><span class=\"hideActionsLbl\"><%= t('actions.filter.solvedrejected') %></span></td>\n" +
        "\t\t\t\t\t\t\t\t</tr>\n" +
        "\t\t\t\t\t\t\t</table>\n" +
        "\t\t\t\t\t\t</div>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t\t<button class=\"solved-rejected ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<%= t('actions.action.solvedrejected') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t\t<!-- Mobile view -->\n" +
        "\t\t\t\t<div class=\"ui-grid-a overview-mobile-view\">\n" +
        "\t\t\t\t\t<button class=\"solved-rejected ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<span class=\"solvedRejectedLbl-overview-filter\" style=\"font-weight: bold;\">0</span> <%= t('actions.action.solvedrejected.mobile') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t</li>\n" +
        "\t\t</ul>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"quickAccess\">\n" +
        "\t\t<p class=\"headline\"><%= t('overview.directlinks.title') %></p>\n" +
        "\t\t<div class=\"listview-container\">\n" +
        "\t\t\t<ul data-role=\"listview\">\n" +
        "\t\t\t\t<li class=\"hideReadonly ui-li-static ui-body-inherit ui-first-child\">\n" +
        "\t\t\t\t\t<button class=\"newDefectContainer newDefect ui-btn ui-btn-a ui-btn-icon-right ui-icon-plus\">\n" +
        "\t\t\t\t\t\t<%= t('defects.newdefectfull') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</li>\n" +
        "\t\t\t\t<li class=\"hideReadonly hidePrincipal ui-li-static ui-body-inherit ui-first-child\">\n" +
        "\t\t\t\t\t<button class=\"unsentOrders ui-btn ui-btn-a ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<%= t('actions.action.unsent') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</li>\n" +
        "\t\t\t\t<li class=\"ui-li-static ui-body-inherit ui-first-child\">\n" +
        "\t\t\t\t\t<button class=\"defectList ui-btn ui-btn-a ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\t\t\t\t\t\t<%= t('actions.action.defectlist') %>\n" +
        "\t\t\t\t\t</button>\n" +
        "\t\t\t\t</li>\n" +
        "\t\t\t</ul>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\n" +
        "<!--\t<div class=\"projectstatscontainer\">-->\n" +
        "<!--\t\t<p class=\"headline\"><%= t('overview.stats.title') %></p>-->\n" +
        "<!--\t\t<div class=\"content projectstats\"></div>-->\n" +
        "<!--\t</div>-->\n" +
        "\n" +
        "\t<div data-role=\"popup\" class=\"backup-dialog\" data-dismissible=\"false\" style=\"max-width: 400px; display: none\">\n" +
        "\t\t<div class=\"ui-content\" style=\"padding-top: 0.5em\">\n" +
        "\t\t\t<p class=\"backup-intro\"></p>\n" +
        "\t\t\t<fieldset data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t\t<legend><%= t('overview.backup.email') %></legend>\n" +
        "\t\t\t\t<input type=\"email\" class=\"backup-notification-email\" data-wrapper-class=\"controlgroup-textinput\"/>\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t\t<button class=\"cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\" data-transition=\"flow\"><%= t('overview.backup.cancel') %></button>\n" +
        "\t\t\t<button class=\"ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\"><%= t('overview.backup.queue') %></button>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"popup\" class=\"actionsmenu\">\n" +
        "\t<div class=\"actionsmenucg\" data-role=\"controlgroup\" style=\"margin: 0\">\n" +
        "\t\t<button class=\"editProjectInfo\"></button>\n" +
        "\t\t<button class=\"backup\"><%- t('overview.backup') %></button>\n" +
        "\t\t<button class=\"reload\"><%- t('overview.reload') %></button>\n" +
        "\t</div>\n" +
        "</div>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<div data-role=\"footer\" data-position=\"fixed\"></div>";
    return page;
});