define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" data-position=\"fixed\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "    <h2><%= t('settings.buyer.roleSettings') %></h2>\n" +
        "    <div>\n" +
        "        <label for=\"role\"><%= t('settings.role') %></label>\n" +
        "        <select class=\"role\" name=\"role\" id=\"role\"></select>\n" +
        "    </div>\n" +
        "    <div class=\"role-details\">\n" +
        "        <div class=\"role-name-container\">\n" +
        "            <label for=\"role-name\"><%= t('settings.buyer.roleName') %></label>\n" +
        "            <input type=\"text\" class=\"role-name\" name=\"role-name\" id=\"role-name\" placeholder=\"<%= t('settings.buyer.roleNamePlaceholder') %>\"/>\n" +
        "        </div>\n" +
        "        <div class=\"ui-field-contain\">\n" +
        "            <div><label><%= t('settings.buyer.activatedFields') %></label></div>\n" +
        "            <fieldset data-role=\"controlgroup\" class=\"activated-fields-options\">\n" +
        "                <label for=\"role-enable-location\"><%= t('settings.buyer.roleEnableLocation') %></label>\n" +
        "                <input type=\"checkbox\" name=\"role-enable-location\" id=\"role-enable-location\"/>\n" +
        "                <label for=\"role-enable-crew\"><%= t('settings.buyer.roleEnableCrew') %></label>\n" +
        "                <input type=\"checkbox\" name=\"role-enable-crew\" id=\"role-enable-crew\"/>\n" +
        "                <label for=\"role-enable-images\"><%= t('settings.buyer.roleEnableImages') %></label>\n" +
        "                <input type=\"checkbox\" name=\"role-enable-images\" id=\"role-enable-images\"/>\n" +
        "                <label for=\"role-enable-comments\"><%= t('settings.buyer.roleEnableComments') %></label>\n" +
        "                <input type=\"checkbox\" name=\"role-enable-comments\" id=\"role-enable-comments\"/>\n" +
        "                <label for=\"role-enable-documents\"><%= t('settings.buyer.roleEnableDocuments') %></label>\n" +
        "                <input type=\"checkbox\" name=\"role-enable-documents\" id=\"role-enable-documents\"/>\n" +
        "                <label for=\"role-enable-contactdata\"><%= t('settings.buyer.roleEnableContactData') %></label>\n" +
        "                <input type=\"checkbox\" name=\"role-enable-contactdata\" id=\"role-enable-contactdata\"/>\n" +
        "            </fieldset>\n" +
        "        </div>\n" +
        "        <div>\n" +
        "            <div><label for=\"role-notes\"><%= t('settings.buyer.roleNotes') %></label></div>\n" +
        "                <textarea name=\"role-notes\" id=\"role-notes\"></textarea>\n" +
        "        </div>\n" +
        "        <button class=\"role-save\"><%= t('settings.buyer.roleSave') %></button>\n" +
        "        <button class=\"role-delete\"><%= t('settings.buyer.roleDelete') %></button>\n" +
        "        <div class=\"page-end-spacer\">\n" +
        "    </div>\n" +
        "</div>\n";
    return page;
});