define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div id='documents-page-header' data-role=\"header\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\t<div id=\"filters\" class=\"ui-grid-c on-big-screen\" data-mini=\"true\" data-role=\"controlgroup\" data-type=\"horizontal\" >\n" +
        "\t\t<div class=\"ui-block-a ui-corner-all\" data-icon=\"false\" style=\"width: 33%\">\n" +
        "\t\t\t<input id=\"documentsSubcontractorFilter\" type=\"text\"  data-icon=\"false\" data-wrapper-class=\"controlgroup-textinput ui-btn\" placeholder=\"<%- t('defects.filter.anysubcontractor') %>\" data-filter=\"subcontractor\"/>\n" +
        "\t\t</div>\n" +
        "\t\t<div class=\"ui-block-d ui-corner-all\" data-icon=\"false\" style=\"width: 33%\">\n" +
        "\t\t\t<a class=\"setFrom ui-btn\"><%- t('documents.filter.from') %></a>\n" +
        "\t\t</div>\n" +
        "\t\t<div class=\"ui-block-d ui-corner-all\" data-icon=\"false\" style=\"width: 33%\">\n" +
        "\t\t\t<a class=\"setTo ui-btn\"><%- t('documents.filter.to') %></a>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\t<div id=\"filters\">\n" +
        "\t\t<div class=\"ui-grid-c on-small-screen\" data-mini=\"true\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t<div class=\"ui-block-d ui-corner-all\" data-icon=\"false\" style=\"width: 100%\">\n" +
        "\t\t\t\t<input id=\"documentsSubcontractorFilter\" type=\"text\"  data-icon=\"false\" data-wrapper-class=\"controlgroup-textinput ui-btn\" placeholder=\"<%- t('defects.filter.anysubcontractor') %>\" data-filter=\"subcontractor\"/>\n" +
        "\t\t\t</div>\n" +
        "\t\t</div>\n" +
        "\t\t<div class=\"ui-grid-c on-small-screen\" data-mini=\"true\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t<div class=\"ui-block-d ui-corner-all\" data-icon=\"false\" style=\"width: 50%\">\n" +
        "\t\t\t\t<a class=\"setFrom ui-btn\"><%- t('documents.filter.from') %></a>\n" +
        "\t\t\t</div>\n" +
        "\t\t\t<div class=\"ui-block-d ui-corner-all\" data-icon=\"false\" style=\"width: 50%\">\n" +
        "\t\t\t\t<a class=\"setTo ui-btn\"><%- t('documents.filter.to') %></a>\n" +
        "\t\t\t</div>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\t\n" +
        "\t<p class=\"not-proxy\" style=\"margin-bottom: 1ex\"><%= t('subcontractor.bundle.sentdocuments') %></p>\n" +
        "\t\n" +
        "\t<ul data-role=\"listview\" data-inset=\"true\" class=\"list checkboxList ui-corner-all not-proxy\"></ul>\n" +
        "\t\n" +
        "\t<button class=\"removeFromList ui-btn-active not-proxy\" style=\"display: none\"><%= t('subcontractor.bundle.removedocuments') %></button>\n" +
        "\t\n" +
        "\t<div data-role=\"popup\" class=\"fromDialog\" data-dismissible=\"false\" style=\"max-width:400px; display: none;\">\n" +
        "\t\t<div class=\"ui-content\">\n" +
        "\t\t\t<fieldset class=\"fromcg\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t\t<legend><%= t('documents.filter.from') %></legend>\n" +
        "\t\t\t\t<input type=\"date\" class=\"from\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t\t<button class=\"cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\" data-transition=\"flow\"><%= t('defect.deadline.cancel') %></button>\n" +
        "\t\t\t<button class=\"ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\"><%= t('defect.deadline.apply') %></button>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\t\n" +
        "\t<div data-role=\"popup\" class=\"toDialog\" data-dismissible=\"false\" style=\"max-width:400px; display: none;\">\n" +
        "\t\t<div class=\"ui-content\">\n" +
        "\t\t\t<fieldset class=\"tocg\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t\t<legend><%= t('documents.filter.to') %></legend>\n" +
        "\t\t\t\t<input type=\"date\" class=\"to\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t\t<button class=\"cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\" data-transition=\"flow\"><%= t('defect.deadline.cancel') %></button>\n" +
        "\t\t\t<button class=\"ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\"><%= t('defect.deadline.apply') %></button>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='documents-page-footer' data-position=\"fixed\"></div>";
    return page;
});