import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {useNavigate} from "react-router-dom";
import {getCustomerProjectWithPrefix, translate} from "../utils/utils";


const Location = () => {

    const navigate = useNavigate();
    const {customerProject} = getCustomerProjectWithPrefix();

    useEffect(()=>{
        // @ts-ignore
        window.locationUseEffect();
    },[])


    const goToProjectPage = ()=>{
        navigate(customerProject);
    }

    const left = [
        <StyledButton key="back" className="back" onClick={goToProjectPage} >{translate("location.back")}</StyledButton>,
        <StyledButton key="parent" className="parent"></StyledButton>
    ]

    const right = [
        <StyledButton key="openCost" className="openCost" >{translate("location.cost")}</StyledButton>,
        <StyledButton key="nextPage" className="nextPage">{translate("location.showall")}</StyledButton>
        ]


    return <PageContainer newPage={false} headerText={translate("location.title")}
                          left={left}
                          right={right}
    />
}

export default Location;