define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', 'moment', '../lib/md5', './QueueToolHtml', '../view/BaseView',
	'../model/user',
	'../helper/watchdog', '../helper/offlineQueue', '../helper/iosapp'
], function (
	$, _, Backbone, moment, md5js, Template, BaseView,
	user,
	watchdog, offlineQueue, iosapp
) {
	return BaseView.extend({

		id: 'queuetool',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		events: {
			'vclick .queuetool-back': 'back',
			'vclick .queuetool-send': 'sendLocalStorage',
			'vclick .queuetool-reset-one': 'resetOne',
			'vclick .queuetool-reset-all': 'resetStorage',
			'vclick .queuetool-sendOne': 'sendOne',
			'vclick .queuetool-removeOne': 'removeOne',
			'vclick .queuetool-update-size': 'updateQueueSize',
			'vclick .queuetool-applyOne': 'applyOne'
		},

		maxChunkSize: 1000000,

		version: 'v9',
		sessionId: Date.now().toString(36).substring(0, 16) + (Math.random().toString(36).slice(2) + '      ').substring(0, 4),
		sessionCntr: 0,

		initialize: function () {
			this.id = '_' + Math.random().toString(36).substr(2, 9);
			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.$sectionBrowser = $('.browser');
			this.$sectionNative = $('.native');
			this.$sendStatus = $('.sendStatus');
			this.$queueSize = $('.queuetool-queuesize');
			this.$applyStatus = $('.applyStatus');
			this.$applyId = $('.applyId');
			this.$qtversion = $('.qtversion');
		},

		render: function () {
			this.$qtversion.text('Version: ' + this.version);
			if (true || iosapp.appavailable) {
				this.$sectionBrowser.hide();
				this.$sectionNative.show();
				this.updateQueueSize();
			} else {
				this.$sectionBrowser.show();
				this.$sectionNative.hide();
			}
			return this;
		},

		back: function () {
			window.location.href = '/onlineBauabnahme/service';
		},

		sendToServer: function (elements) {
			var chain = $.Deferred().resolve();
			chain = chain.pipe(function () {
				_.each(elements, function (element) {
					if (!element) {
						return;
					}
					var request = $.post('/onlineBauabnahme/api/error', element);
					this.$sendStatus.text("Übertrage: " + element.key);
					request.then(function () {
						console.log("done:" + element.key);
					}, function () {
						console.log("fail:" + element.key);
					});
					return request;
				}.bind(this));
			}.bind(this));
			return chain;
		},

		sendToFragmentServer: function (elements) {
			var chain = $.Deferred().resolve();
			_.each(elements, function (element) {
				chain = chain.then(function () {
					if (!element) {
						return;
					}
					var request = $.ajax({
						url: '/onlineBauabnahme/api/queuetool/fragments/sendfragment',
						type: 'POST',
						data: element.message,
						contentType: 'text/plain; charset=utf-8',
						dataType: 'json'
					});
					this.$sendStatus.text('Übertrage: ' + element.key);
					request.then(function () {
						console.log('done:' + element.key);
					}, function () {
						console.log('fail:' + element.key);
					});
					return request;
				}.bind(this));
			}.bind(this));
			return chain;
		},

		// 1. Daten sammeln
		// 2. String aufteilen und zusammensetzen (am server)
		// 3. Fehlertoleranz
		// 4. einzelne stücke müssen identifizierbar sein

		// auf server
		// endpunkt

		sendLocalStorage: function () {

			var elements = [];
			for (var i in localStorage) {

				var value = localStorage[i].toString();
				if (value.length > this.maxChunkSize) {

					var md5 = this.md5(value).toString();
					// console.log('md5: ' + md5);
					var chunks = [];
					var chunkCount = Math.ceil(value.length / this.maxChunkSize);
					for (var j = 0; j < chunkCount; j++) {
						var datalen = Math.min(value.length - this.maxChunkSize * j, this.maxChunkSize);
						var message = this.padWithSpaces(md5, 40) + this.padWithSpaces(j, 4) + this.padWithSpaces(chunkCount, 4) + this.padWithSpaces(datalen, 8) + value.substring(j * this.maxChunkSize, (j + 1) * this.maxChunkSize);
						// console.log(message)
						chunks.push({
							key: 'x',
							systeminfo: 'debugtools id ' + this.id,
							source: 'x',
							message: message,
							type: 'queue'
						});
					}
					this.sendToFragmentServer(chunks);
				} else {
					elements.push({
						key: i,
						systeminfo: 'debugtools id ' + this.id,
						source: '' + i,
						message: '' + localStorage[i],
						type: 'queue'
					});
				}
			}

			var chain = this.sendToServer(elements);
			chain.done(function () {
				this.$sendStatus.text("Übertragung abgeschlossen!");
			}.bind(this));
			chain.fail(function () {
				this.$sendStatus.text("Übertragung fehlgeschlagen!");
			}.bind(this));
		},

		sendAndRemove: function() {
			this.internalSendOne(true);
		},
		sendOne: function() {
			this.internalSendOne(false);
		},
		removeOne: function() {
			confirmPopup('Sie sind dabei, das erste Element in der Warteschlange zu löschen, dies kann nicht rückgängig gemacht werden.').then(function() {
				this.$sendStatus.text("Lösche Element...");
				offlineQueue.queue.removeFirst();
				this.$sendStatus.text("Element gelöscht");
			}.bind(this));
		},
		internalSendOne: function() {
			this.$sendStatus.text('');
			if (!this.gotLock && offlineQueue.get('syncing')) {
				this.$sendStatus.text("Synchronisation noch im Gange, bitte gleich noch einmal versuchen!");
				return;
			}
			offlineQueue.set('syncing', 'true');
			this.gotLock = true;

			this.$sendStatus.text('Starte, hole Daten');

			var chain = $.Deferred().resolve();
			chain = chain.then(function () {
				var deferred = $.Deferred();

				window.setTimeout(function() {
					offlineQueue.queue.getFirst(function (el) {
						this.$sendStatus.text('Verarbeite Daten Schritt 1');
						window.setTimeout(function() {
							var value = JSON.stringify(el);
							this.$sendStatus.text('Verarbeite Daten Schritt 2');
							window.setTimeout(function() {
								this.sessionCntr++;
								var id = this.sessionId + '-' + this.sessionCntr;
								var md5 = this.md5(value).toString();
								this.$sendStatus.text('Verarbeite Daten Schritt 3');
								window.setTimeout(function() {
									var chunks = [];
									var chunkCount = Math.ceil(value.length / this.maxChunkSize);
									for (var j = 0; j < chunkCount; j++) {
										var datalen = Math.min(value.length - this.maxChunkSize * j, this.maxChunkSize);
										var message = this.padWithSpaces(id, 40) + this.padWithSpaces(md5, 40) + this.padWithSpaces(j, 4) + this.padWithSpaces(chunkCount, 4) + this.padWithSpaces(datalen, 8) + value.substring(j * this.maxChunkSize, (j + 1) * this.maxChunkSize);
										// console.log(message)
										chunks.push({
											key: 'Änderung ' + this.sessionCntr + ' ' + (j+1) + '/' + (chunkCount),
											systeminfo: 'debugtools id ' + this.id,
											source: 'x',
											message: message,
											type: 'queue'
										});
									}
									this.sendToFragmentServer(chunks).then(function() {
										deferred.resolve();
									}.bind(this))
									.fail(function() {
										deferred.reject.apply(this, arguments);
									}.bind(this));
									// only continue if we have a callback
								}.bind(this), 100);
							}.bind(this), 100);
						}.bind(this), 100);
					}.bind(this));
				}.bind(this), 100);
				return deferred;
			}.bind(this));
			chain = chain.then(function (el) {
				var elements = [
					{
						key: 'x',
						systeminfo: 'debugtools id ' + this.id,
						source: 'x',
						message: '' + el,
						type: 'queue'
					}
				];
				return this.sendToServer(elements);
			}.bind(this));
			chain = chain.then(function () {
				var deferred = $.Deferred();
				// if (doRemove) {
				// 	this.$sendStatus.text("Lösche Element...");
				// 	offlineQueue.queue.removeFirst();
				// 	deferred.resolve(); //unfortunately we don't get a callback...
				// } else {
					deferred.resolve();
				// }
				return deferred;
			}.bind(this));
			chain.done(function () {
				this.$sendStatus.text("Abgeschlossen!");
				alertPopup('Abgeschlossen!');
			}.bind(this));
			chain.fail(function () {
				this.$sendStatus.text("Fehlgeschlagen!");
				alertPopup('Fehlgeschlagen!');
			}.bind(this));
			chain.always(function () {
				this.updateQueueSize();
			}.bind(this));

			return chain;
		},

		applyOne: function() {
			this.$applyStatus.text('');
			var id = this.$applyId.val().trim();
			if (!id) {
				alertPopup('Keine ID angegeben');
				return;
			}

			var chain = $.Deferred();
			chain.resolve();
			var md5, jsondata;
			chain = chain.then(function() {
				this.$applyStatus.text("Lade Prüfsumme...");
				return $.get('/onlineBauabnahme/api/queuetool/fragments/calculateMd5?id=' + encodeURIComponent(id));
			}.bind(this));
			chain = chain.then(function(data) {
				md5 = data.split(' ')[0];
			}.bind(this));
			chain = chain.then(function() {
				this.$applyStatus.text("Lade Daten...");
				return $.get('/onlineBauabnahme/api/queuetool/fragments/getdata?id=' + encodeURIComponent(id));
			}.bind(this));
			chain = chain.then(function(data) {
				this.$applyStatus.text("Daten geladen");
				var deferred = $.Deferred();
				var localmd5 = this.md5(data).toString();
				if (localmd5 !== md5) {
					deferred.reject();
					alertPopup('Die Daten wurden nicht korrekt übertragen, breche ab.');
					return deferred;
				}
				jsondata = offlineQueue._deserializeValue(data);
				return confirmPopup('Die Daten wurden vom Server heruntergeladen: ' + (data.length / 1024 / 1024) + " MB. Wollen Sie die Daten anwenden?").then(function() {
					this.$applyStatus.text("Wende Daten an...");
					var defect;
					if (jsondata[0] === 'create') {
						defect = new window.Defect();
						defect.attributes = jsondata[1];
						jsondata[1] = defect;
					} else if (jsondata[0] === 'update') {
						//actually the same as above, because we don't have any additional data
						//lets hope this is alright.
						defect = new window.Defect();
						defect.attributes = jsondata[1];
						jsondata[1] = defect;
					} else {
						alertPopup('Nicht implementiert, breche ab.');
						return;
					}

					Backbone.sync.apply(Backbone, jsondata);
				}.bind(this));
			}.bind(this));
			chain.fail(function() {
				alertPopup('Vorgang fehlgeschlagen.');
			}.bind(this));
			chain.done(function() {
				this.$applyStatus.text("abgeschlossen");
				alertPopup('Abgeschlossen.');
			}.bind(this));

		},

		padWithSpaces: function (str, length) {
			// create a string of all zeroes
			var padded = new Array(length + 1).join(' ');
			var temp = ('' + str) + padded;
			return temp.substring(0, length);
		},

		updateQueueSize: function () {
			this.$queueSize.text('');
			offlineQueue.queue.getSize(function (size) {
				this.$queueSize.text('' + size);
			}.bind(this));
		},

		resetStorage: function () {
			for (var i in localStorage) {
				localStorage.removeItem(i);
			}
			alertPopup('Element gelöscht.');
		},

		resetOne: function () {
			var lowestEl = 999999;
			for (var i in localStorage) {
				var match = /^d[0-9]*$/.test(i);
				if (match) {
					var idx = parseInt(i.substr(1));
					if (idx < lowestEl) {
						lowestEl = idx;
					}
				}
			}
			localStorage.removeItem('d' + lowestEl)
			console.log(lowestEl);
			alertPopup('Daten gelöscht.');
		},

		toUTF8Array: function(str) {
			//from: https://stackoverflow.com/questions/18729405/how-to-convert-utf8-string-to-byte-array
			var utf8 = [];
			for (var i=0; i < str.length; i++) {
				var charcode = str.charCodeAt(i);
				if (charcode < 0x80) utf8.push(charcode);
				else if (charcode < 0x800) {
					utf8.push(0xc0 | (charcode >> 6),
						0x80 | (charcode & 0x3f));
				}
				else if (charcode < 0xd800 || charcode >= 0xe000) {
					utf8.push(0xe0 | (charcode >> 12),
						0x80 | ((charcode>>6) & 0x3f),
						0x80 | (charcode & 0x3f));
				}
				// surrogate pair
				else {
					i++;
					// UTF-16 encodes 0x10000-0x10FFFF by
					// subtracting 0x10000 and splitting the
					// 20 bits of 0x0-0xFFFFF into two halves
					charcode = 0x10000 + (((charcode & 0x3ff)<<10)
						| (str.charCodeAt(i) & 0x3ff));
					utf8.push(0xf0 | (charcode >>18),
						0x80 | ((charcode>>12) & 0x3f),
						0x80 | ((charcode>>6) & 0x3f),
						0x80 | (charcode & 0x3f));
				}
			}
			return utf8;
		},


		md5: function(s) {
			//var bytes = this.toUTF8Array(s);
			//return binl2hex(core_md5(bytes, bytes.length));
			var arr = this.toUTF8Array(s);
			return md5js.binl2hex(md5js.core_md5(md5js.arr2binl(arr), arr.length * 8));
		}
	});

});