import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {useLocation, useNavigate} from "react-router-dom";
import {getCustomerProjectWithPrefix, getProjectIdFromCustomerProject, translate} from "../utils/utils";
import {page} from "../utils/constants";


const PersonCSV = () => {

    const navigate = useNavigate();
    const {customerProject} = getCustomerProjectWithPrefix();
    const project = getProjectIdFromCustomerProject(customerProject);
    const {state} = useLocation();

    useEffect(()=>{
        // @ts-ignore
        window.personCSVUseEffect(project);
    },[])

    const goToProject = ()=>{
        navigate(`${customerProject}/settings`,{state});
    }


    return <PageContainer pageId={page.PERSON_CSV} newPage={false} headerText={translate("persons.title")}
                          left={<StyledButton onClick={goToProject}>{translate("help.backtoproject")}</StyledButton>}
    />
}

export default PersonCSV;