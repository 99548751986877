import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {useNavigate} from "react-router-dom";
import {getCustomerProjectWithPrefix, translate} from "../utils/utils";


const Cost = () => {

    const navigate = useNavigate();

    const {customerProject} = getCustomerProjectWithPrefix();

    useEffect(()=>{
        // @ts-ignore
        window.costUseEffect();
    },[])


    const goToProject = () => {
        navigate(customerProject);
    }

    return <PageContainer newPage={false} headerText={translate("sidepanel.cost")}
                          left={<StyledButton onClick={goToProject}>{translate("cost.back")}</StyledButton>}
                          right={<StyledButton className="download">{translate("cost.pdfAG")}</StyledButton>}
    />
}

export default Cost;