import {CenterContainer, FullPage} from "../components/styledComponents";
import {Box, Stack, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../store/hooks";
import {CommonStoreStateKeys, setStateValue} from "../store/slices/commonStoreSlice";

const ErrorPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(()=>{
        dispatch(setStateValue({
            key: CommonStoreStateKeys.currentCustomer,
            value: ""
        }))
    },[])

    const goToHomepage = ()=>{
        navigate('/');
    }
    const goToHotline = ()=>{
        navigate('/help');
    }

    return <FullPage>
        <CenterContainer>
            <Stack flexDirection={'column'} spacing={3}>
                <Typography variant={'h1'}>Whoops!</Typography>
                <Typography variant={'h3'}>Looks like something went wrong.</Typography>
                <Box>
                    You can go to <a className='clickable' onClick={goToHomepage}>home page</a> or look for <a className='clickable' onClick={goToHotline}>help</a> from our customer service.
                </Box>
            </Stack>

        </CenterContainer>
    </FullPage>

}

export default ErrorPage;
