define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id='request-bundle-page-header' data-position=\"fixed\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\t<select class=\"whichDefects subcontractor\">\n" +
        "\t\t<option value=\"allUnsent\"><%= t('subcontractor.bundle.allunsent') %></option>\n" +
        "\t\t<option value=\"all\"><%= t('subcontractor.bundle.all') %></option>\n" +
        "\t\t<option value=\"divider\" disabled=\"disabled\">--</option>\n" +
        "\t\t<option value=\"firstUnsent\"><%= t('subcontractor.bundle.firstunset') %></option>\n" +
        "\t\t<option value=\"first\"><%= t('subcontractor.bundle.first') %></option>\n" +
        "\t\t<option value=\"secondUnsent\"><%= t('subcontractor.bundle.secondunset') %></option>\n" +
        "\t\t<option value=\"second\"><%= t('subcontractor.bundle.second') %></option>\n" +
        "\t\t<option value=\"thirdUnsent\"><%= t('subcontractor.bundle.thirdunset') %></option>\n" +
        "\t\t<option value=\"third\"><%= t('subcontractor.bundle.third') %></option>\n" +
        "\t\t<option value=\"fourthUnsent\"><%= t('subcontractor.bundle.fourthunset') %></option>\n" +
        "\t\t<option value=\"fourth\"><%= t('subcontractor.bundle.fourth') %></option>\n" +
        "\t</select>\n" +
        "\n" +
        "\t<select class=\"whichDefects inspection\">\n" +
        "\t\t<option value=\"allUnsent\"><%= t('inspection.bundle.allunsent') %></option>\n" +
        "\t\t<option value=\"all\"><%= t('inspection.bundle.all') %></option>\n" +
        "\t</select>\n" +
        "\n" +
        "\t<select class=\"whichDefects principal\">\n" +
        "\t\t<option value=\"allUnsent\"><%= t('principal.bundle.allunsent') %></option>\n" +
        "\t\t<option value=\"all\"><%= t('principal.bundle.all') %></option>\n" +
        "\t</select>\n" +
        "\n" +
        "\t<h4 style=\"margin: 1.5em 0 .5em\"><%= t('requestbundle.previews') %></h4>\n" +
        "\t<div class=\"previews\" ></div>\n" +
        "\n" +
        "\t<div class=\"projectPersonSignatureBox documentsRelevant signatureBox\">\n" +
        "\t\t<h4 style=\"margin: 1.5em 0 .5em\"></h4>\n" +
        "\t\t<select class = \"selectResponsible\"></select>\n" +
        "\t\t<div class=\"ui-field-contain\"><div class=\"projectPersonSignature sketcher\" data-enhance=\"false\"></div></div>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"userSignatureBox documentsRelevant signatureBox\">\n" +
        "\t\t<h4 style=\"margin: 1.5em 0 .5em\"></h4>\n" +
        "\t\t<div class=\"ui-field-contain\"><div class=\"userSignature sketcher\" data-enhance=\"false\"></div></div>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<h4 class= \"documentsRelevant\" style=\"margin: 1.5em 0 0\"><%- t('requestbundle.autodeadline') %></h4>\n" +
        "\n" +
        "\t<table class= \"documentsRelevant\" style=\"margin-bottom: 1em\">\n" +
        "\t<tr>\n" +
        "\t\t<td>\n" +
        "\t\t\t<fieldset data-role=\"controlgroup\" style=\"margin-top: 0\">\n" +
        "\t\t\t\t<input type=\"radio\" name=\"requestbundle-autodeadline\" id=\"requestbundle-autodeadline-disable\" value=\"disable\"/>\n" +
        "\t\t\t\t<label for=\"requestbundle-autodeadline-disable\"><%- t('requestbundle.autodeadline.disable') %></label>\n" +
        "\t\t\t\t<input type=\"radio\" name=\"requestbundle-autodeadline\" id=\"requestbundle-autodeadline-enable\" value=\"enable\" checked=\"checked\"/>\n" +
        "\t\t\t\t<label for=\"requestbundle-autodeadline-enable\"><%- t('requestbundle.autodeadline.enable') %></label>\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t</td>\n" +
        "\t\t<td style=\"vertical-align: bottom\">\n" +
        "\t\t\t<%- t('requestbundle.autodeadline.hint') %><br/>\n" +
        "\t\t\t<input class=\"deadline\" type=\"date\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t</td>\n" +
        "\t</tr>\n" +
        "\t</table>\n" +
        "\n" +
        "\t<div class=\"rdeadline-container\" style=\"margin-bottom: 1em\">\n" +
        "\t\t<h4 style=\"margin: 1em 0 0\"><%- t('requestbundle.rdeadline') %></h4>\n" +
        "\n" +
        "\t\t<input class=\"rdeadline\" type=\"date\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t</div>\n\n    <h4  style=\"margin: 1.5em 0 0\"><%- t(\'inspection.orderby.header\') %></h4>\n    <table style=\"margin-bottom: 1em;\" class=\"request-bundle-generate-orderby-container\">\n        <tr>\n            <td>\n                <fieldset data-role=\"controlgroup\" style=\"margin-top: 0\">\n                    <input id=\"request-bundle-orderBy-numberasc\" type=\"radio\" name=\"request-bundle-orderby\" value=\"numberasc\" checked=\"checked\"/>\n                    <label for=\"request-bundle-orderBy-numberasc\"><%- t(\'inspection.orderby.numberasc\') %></label>\n                    <input id=\"request-bundle-orderBy-numberdesc\" type=\"radio\" name=\"request-bundle-orderby\" value=\"numberdesc\"/>\n                    <label for=\"request-bundle-orderBy-numberdesc\"><%- t(\'inspection.orderby.numberdesc\') %></label>\n                </fieldset>\n            </td>\n        </tr>\n    </table>\n" +
        "\n" +
        "\t<button class=\"generate ui-btn ui-btn-active ui-corner-all documentsRelevant \"><%= t('bundle.createdocuments') %></button>\n" +
        "\t\n" +
        "\t<div data-role=\"popup\" class=\"alterFop\" style=\"background-color: #eeeeee\">\n" +
        "\t\t<div style=\"height: 100%; width: 100%; overflow-y: auto; padding: 0 14px; overflow-x: auto\">\n" +
        "\t\t\t\n" +
        "\t\t\t<h3 class=\"document-name\"></h3>\n" +
        "\t\t\t\n" +
        "\t\t\t<ul class=\"textBlocks\" style=\"background-color: #eeeeee; list-style: none; padding-left: 0; width: calc(100% - 28px); height: calc(100% - 90px); overflow: auto;\">\n" +
        "\t\t\t</ul>\n" +
        "\t\t\t\n" +
        "\t\t\t<div class=\"buttons\" style=\"display: flex; flex-direction: row; bottom: 0; position: absolute; width: 100%; left: 0;\">\n" +
        "\t\t\t\t<button class=\"cancelFopPopup\" style=\"margin: 10px;\"><%= t('requestbundle.cancel') %></button>\n" +
        "<!--\t\t\t\t<button class=\"openSafetyPopup\" style=\"margin: 10px;\"><%= t('requestbundle.savePermanently') %></button>-->\n" +
        "\t\t\t\t<button class=\"saveDocuments\" style=\"margin: 10px; background-color: rgba(238,127,0,1)\"><%= t('requestbundle.save') %></button>\n" +
        "\t\t\t</div>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div data-role=\"popup\" class=\"safetyPopup\">\n" +
        "\n" +
        "\t\t<h3><%= t('requestbundle.safetyQuestion') %></h3>\n" +
        "\n" +
        "\t\t<input class=\"safetyWordinput\" type=\"text\"/>\n" +
        "\n" +
        "\t\t<div class=\"safetyWarning\"><%= t('requestbundle.safetyWarning') %></div>\n" +
        "\n" +
        "\t\t<select class=\"chooseFolder\">\n" +
        "\t\t\t<option value=\"project\"><%= t('requestbundle.project') %></option>\n" +
        "\t\t\t<option value=\"customer\"><%= t('requestbundle.customer') %></option>\n" +
        "\t\t</select>\n" +
        "\n" +
        "\t\t<div class=\"buttons\" style=\"display: flex; flex-direction: row\" style=\"margin: 10px\">\n" +
        "\t\t\t<button class=\"cancelSafety\" style=\"margin: 10px;\"><%= t('requestbundle.cancel') %></button>\n" +
        "\t\t\t<button class=\"saveAlteredFopsPermanently\" style=\"margin: 10px;\"><%= t('requestbundle.save') %></button>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "</div>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='request-bundle-page-footer' data-position=\"fixed\"></div>";
    return page;
});