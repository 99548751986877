define([
	'../lib/jquery-1.11.0', '../lib/backbone-1.1.2', './StatusPanelHtml', '../view/Popup', '../model/user', '../model/currentProject',
	'../helper/offlineQueue', '../helper/watchdog', '../helper/iosapp', '../model/currentDefects', '../model/proxyInfo'
], function (
	$, Backbone, Template, Popup, user, currentProject,
	offlineQueue, watchdog, iosapp, currentDefects, proxyInfo
) {
	return Backbone.View.extend({

		id: 'status',

		template: _.template(Template),

		attributes: {
			'data-enhance':  false
		},

		events: {
			'mousedown':  'trigger',
			'touchstart': 'trigger',
			'mousedown  .syncdefects': 'syncdefects',
			'touchstart .syncdefects': 'syncdefects',
			'dblclick':   'enforceOffline'
		},

		initialize: function (options) {
			//
			// this.$el
			// .html(this.template({ t: user.translate }))
			// .appendTo($.mobile.pageContainer);
			//
			// this.bars                = this.$('.bars');
			// this.tryingIndicator     = this.$('.tryingIndicator').hide();
			// this.pendingStatus       = this.$('.pendingStatus');
			// this.syncingStatus       = this.$('.syncingStatus').hide();
			// this.connectionIndicator = this.$('.connectionIndicator');
			// this.connectionText      = this.$('.connectionText');
			// this.$sync               = this.$('.syncdefects');
			//
			// this.listenTo(offlineQueue, 'change:syncing',      this.updateSyncing);
			// this.listenTo(offlineQueue, 'change:pending',      this.updatePending);
			// this.listenTo(watchdog,     'change:connected',    this.updateOnlineStatus);
			// this.listenTo(watchdog,     'change:forceOffline', this.updateOnlineStatus);
			// this.listenTo(watchdog,     'change:latency',      this.updateLatency);
			// this.listenTo(watchdog,     'change:trying',       this.updateTrying);
			// this.listenTo(watchdog,     'change:defectdate',   this.updateSyncStatus);
			// this.listenTo(watchdog,     'change:defectlocaldate', this.updateSyncStatus);
			// this.listenTo(offlineQueue, 'change:pending',      this.updateSyncStatus);
			// this.listenTo(watchdog,     'change:connected',    this.updateSyncStatus);
			//
			// this.updateSyncing();
			// this.updatePending();
			// this.updateOnlineStatus();
			// this.updateLatency();
			// this.updateTrying();
			// this.updateSyncStatus();
		},

		updateSyncing: function () {
			// this.syncingStatus.toggle(offlineQueue.get('syncing'));
		},

		updatePending: function () {
			// var pending = offlineQueue.get('pending');

			/* Too slow on mobiles
			if (pending >= 20 && window.oxmobile && !iosapp.appavailable
				&& !(navigator.userAgent.match(/(android)/i) != null  && typeof androidapp !== 'undefined')
				&& !iosapp.windowsappavailable) {
				_.defer(function () {
					window.alertPopup(user.translate('popup.offline.manydefects')); // slow on mobile
				});
			}*/

			// if (pending > 0) {
			// 	var msg = user.translate(pending == 1 ? 'status.notsavedone' : 'status.notsaved');
			// 	msg = msg.replace('%d', pending);
			// 	this.pendingStatus.text(msg);
			// } else {
			//     if (proxyInfo.get('proxyMode')) {
			//         this.pendingStatus.text(user.translate(''));
			//     } else {
			// 	    this.pendingStatus.text(user.translate('status.synced'));
            //     }
			// }
			// offlineQueue.preventLogout();
		},

		updateOnlineStatus: function () {
			// var connected = watchdog.isConnected();
			// if (proxyInfo.get('proxyMode')) {
			//     this.connectionText.text(user.translate('status.offlinemode'));
			//     connected = true;
			// } else {
            //     this.connectionText.text(
            //             watchdog.get('forceOffline')
            //             ? user.translate('status.offline')
            //             : (connected ? user.translate('status.connected') : user.translate('status.disconnected'))
            //     );
			// }
			//
			// this.connectionIndicator.toggleClass('connected', connected);
		},

		updateLatency: function () {
			// var latency = watchdog.get('latency');
			// var quality;
			// if (latency === null || latency === -1) {
			// 	quality = 0;
			// } else if (latency < 100) {
			// 	quality = 5;
			// } else if (latency < 250) {
			// 	quality = 4;
			// } else if (latency < 750) {
			// 	quality = 3;
			// } else if (latency < 1500) {
			// 	quality = 2;
			// } else if (latency < 2000) {
			// 	quality = 1;
			// } else {
			// 	quality = 0;
			// }
			// this.bars.find('.q1').toggleClass('set', quality >= 1);
			// this.bars.find('.q2').toggleClass('set', quality >= 2);
			// this.bars.find('.q3').toggleClass('set', quality >= 3);
			// this.bars.find('.q4').toggleClass('set', quality >= 4);
			// this.bars.find('.q5').toggleClass('set', quality >= 5);
		},

		updateTrying: function () {
			// this.tryingIndicator.toggle(watchdog.get('trying'));
		},

		trigger: function (e) {
			// if (e) {
			// 	e.preventDefault();
			// 	e.stopImmediatePropagation();
			// }
			// if (this.timeout) {
			// 	window.clearTimeout(this.timeout);
			// 	this.timeout = null;
			// 	this.enforceOffline();
			// 	return;
			// }
			// this.timeout = window.setTimeout(_.bind(function () {
			// 	window.clearTimeout(this.timeout);
			// 	this.timeout = null;
			// 	this.triggerSync();
			// }, this), 500);
		},

		triggerSync: function (e) {
			//console.log('trigger sync');
			// if (e) {
			// 	e.preventDefault();
			// 	e.stopPropagation();
			// }
			// watchdog.set('forceOffline', false);
			// watchdog.check();
		},

		enforceOffline: function () {
		    //new meaning: if in proxy-offline mode, double click will go to online mode
		    // if (proxyInfo.get('proxyMode')) {
            //     window.confirmPopup(user.translate('popup.enforceoffline.proxyGoOnline')).then(_.bind(function () {
            //         iosapp.proxyConfig({toOnlineMode: true});
            //     }, this));
		    // } else {
            //     window.confirmPopup(user.translate('popup.enforceoffline.confirm')).then(_.bind(function () {
            //         watchdog.set('forceOffline', true);
            //         watchdog.setDisonnected();
            //     }, this));
		    // }
		},

		updateSyncStatus: function () {
			//console.log('local: ' + watchdog.get('defectlocaldate') + ", server: " + watchdog.get('defectdate'));
			// this.$sync.toggle(watchdog.isConnected() && !offlineQueue.get('pending') && (watchdog.get('defectlocaldate') || '') < watchdog.get('defectdate'));
		},

		syncdefects: function (e) {
			// if (e) {
			// 	e.stopPropagation();
			// 	e.preventDefault();
			// }
			// //console.log('trigger fetch');
			// this.$sync.addClass('spin');
			// currentDefects.fetch()
			// .done(_.bind(function () {
			//
			// }))
			// .always(_.bind(function () {
			// 	this.$sync.removeClass('spin');
			// }, this));
		},

		projectChanged: function () {
			// offlineQueue.queue.getSize(function (size) {
			// 	if (size > 0) {
			// 		offlineQueue.queue.getProject(_.bind(function(queueProject) {
			// 			if (currentProject.id == queueProject) {
							// var popup = new Popup({
							// 	header: user.translate('popup.title'),
							// 	message: user.translate('status.offlinedefects.confirm').replace('%d', size),
							// 	buttons: [{
							// 		text:  user.translate('status.offlinedefects.delete'),
							// 		event: 'delete'
							// 	}, {
							// 		text:  user.translate('status.offlinedefects.submit'),
							// 		event: 'submit'
							// 	}]
							// }).render();
							// popup.on('submit', _.bind(function () {
								offlineQueue.online();
							// }, this));
							// popup.on('delete', _.bind(function () {
							// 	offlineQueue.queue.clear();
							// }, this));
			// 			} else {
			// 				window.confirmPopup(
			// 						user.translate('status.offlinedefects.otherproject')
			// 						.replace('%d', size)
			// 						.replace('%s', queueProject)
			// 				).then(_.bind(function () {
			// 					offlineQueue.queue.clear();
			// 				}, this), _.bind(function () {
			// 					currentProject.clear();
			// 					Backbone.history.navigate('overview', { trigger: true });
			// 				}, this));
			// 			}
			// 		}, this));
			// 	}
			// }, this);
		}

	});
});
