define([
	'../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2',
	'../helper/offlineQueue', '../helper/watchdog'
], function (
	_, Backbone,
	offlineQueue, watchdog
) {

	return Backbone.Model.extend({

		go: function (project) {
			if (!watchdog.isConnected()) {
				window.alertPopup('Der Wechsel kann nur bei bestehender Internetverbindung erfolgen.');
				return;
			}
			if (offlineQueue.get('pending')) {
				window.alertPopup('Der Wechsel kann nicht erfolgen solange Änderungen noch nicht übertragen wurden.');
				return;
			}
			$('body').data('cancelbeforeunload', true);
			window.location.href = '/myaccount/api/service-account/go-account/' + encodeURIComponent(this.get('id')) + (project ? '/' + encodeURIComponent(project) : '');
			_.defer(function () { $('body').data('cancelbeforeunload', false); });
		}

	});

});
