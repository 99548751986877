define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', './AccountPageHtml', '../helper/iosapp', '../model/accessibleAccounts', '../view/ShortcutBaseView',
	'../model/projects', '../model/currentProject', '../model/user', '../helper/watchdog', '../helper/offlineQueue', '../model/currentDefects', '../model/subcontractors'
], function (
	$, _, Template, iosapp, accessibleAccounts, ShortcutBaseView,
	projects, currentProject, user, watchdog, offlineQueue, currentDefects, subcontractors
) {

	return ShortcutBaseView.extend({

		id: 'account',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		events: {
			'click #customerList li': function (e) {
				// this.chooseCustomer(e.target);
			},
			'click a.dataprotection': 'dataprotectionClicked',
			'click a.myaccount-link': 'myaccountClicked',
			'click .logout': 'logout'
		},
		
		initialize: function (options) {
			// this.router = options.router;
			// this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			// this.accountInfo = this.$('div.accountInfo');
			// this.accountInfoTable = this.$('table.accountInfo');
			// this.accountInfoNameValue = this.$('#nameValue');
			// this.accountInfoIdValue = this.$('#idValue');
			// this.accountButtons = this.$('#accountButtons');
			// this.myaccountButton = this.$('.myaccount-link');
			// this.logoutButton = this.$('.logout');
			// this.customerInfo = this.$('div.customerInfo');
			// this.customerList = this.$('#customerList');
		},

		render: function () {
			// this.accountInfoNameValue.text(user.get('name'));
			// this.accountInfoIdValue.text(user.get('experienceId'));
			// this.customerList.empty();
			// this.customerList.append(_.map(accessibleAccounts.get('accounts'), function (account) {
			//
			// 	return $('<li>').append($('<a href="javascript:void(0)">').text(account.label).data('account', account));
			// }));
			// this.customerList.listview('refresh');
			//
			// if (iosapp.appavailable) {
			// 	this.myaccountButton.remove();
			// }
			// return this;
		},
		
		chooseCustomer: function (e) {
			// var account = $(e).data('account');
			// sessionStorage && sessionStorage.setItem('oa-customer', account.id);
			// $.post( '/onlineBauabnahme/api/myaccount/oa/' + encodeURIComponent(account.id))
			// 	.then(function () {
			// 		$.mobile.loading('show');
			// 		//$('body').addClass('loading');
			// 		user.fetch()
			// 			.then(function () {
			// 				$('body').data('cancelbeforeunload', true);
			// 				Backbone.history.navigate('');
			// 				location.reload();
			// 			})
			// 			.catch(function (err) {
			// 				// show error message
			// 				$.mobile.showPageLoadingMsg($.mobile.pageLoadErrorMessageTheme, user.translate('error.unexpected'));
			// 			})
			// 			.finally(function () {
			// 				$.mobile.loading('hide');
			// 			});
			// 	}.bind(this));
		},

		dataprotectionClicked: function(e) {
			// var url = 'https://openexperience.de/' + (user.get('settings') && user.get('settings').lang !== 'DE' ? 'en' : 'de') + '/Datenschutzerklaerung';
			// if (iosapp.appavailable) {
			// 	iosapp.openUrlInNativeBrowser(url);
			// } else {
			// 	window.open(url, '_blank');
			// }

		},

		myaccountClicked: function(e) {
			// var url = 'https://openexperience.de/' + (user.get('settings') && user.get('settings').lang !== 'DE' ? 'en' : 'de') + '/Myaccount';
			// window.open(url, '_blank');
		},
		
		logout: function (e) {
			// e.preventDefault();
			// e.stopPropagation();
			// //copy exists in OverviewPage.js
			// localStorage.removeItem('location', location.hash);
			// if (iosapp.appavailable) {
			// 	location.href =  '/logout?iosapp=true';
			// } else if (window.location !== window.parent.location) {
			// 	location.href =  '/logout?iframe=true';
			// } else {
			// 	location.href =  '/logout';
			// }
		},
	});
});
