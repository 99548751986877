define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" data-position=\"fixed\">\n" +
        "\n" +
        "\t<div class=\"ui-btn-left ui-btn-inline\">\n" +
        "\t\t<button data-shortcut=\"leftMenu\" class=\"menutrigger ui-btn ui-btn-inline ui-icon-bars ui-btn-icon-notext ui-corner-all\"></button>\n" +
        "\t\t <a href=\"#overview\" class=\"previousPage back ui-btn ui-btn-inline ui-btn-icon-left ui-icon-back ui-corner-all\" style=\"margin-left: 5px\"><%= t('help.backtoproject') %></a>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<h1 data-role=\"heading\"><%= t('help.title') %></h1>\n" +
        "\n" +
        "\t<button class=\"hotline ui-btn ui-btn-a ui-btn-right ui-btn-inline ui-corner-all\">Hotline</button>\n" +
        "<!--\n" +
        "\t<div class=\"ui-btn-right ui-btn-inline\">\n" +
        "\t\t<button class=\"actionsTrigger ui-btn  ui-btn-a ui-btn-inline ui-btn-icon-left ui-icon-dots ui-btn-icon-notext ui-corner-all\"></button>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div data-role=\"popup\" class=\"actionsMenu\">\n" +
        "\t\t<ul data-role=\"listview\" data-inset=\"true\">\n" +
        "\t\t\t<li><a class=\"editEmail\"><%= t('paperwork.editemail') %></a></li>\n" +
        "\t\t</ul>\n" +
        "\t</div>\n" +
        "-->\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\t<input class=\"helpSearchFilter\" data-filter=\"search\" type=\"text\" placeholder=\"<%= t('help.search') %>\"/>\n" +
        "\n" +
        "\t<ul data-role=\"listview\" data-inset=\"true\" class=\"list checkboxList ui-corner-all\"></ul>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" data-position=\"fixed\">\n" +
        "\t<button class=\"version ui-btn ui-btn-a ui-btn-left ui-btn-inline ui-btn-icon-left ui-icon-info ui-corner-all\">Version</button>\n" +
        "\t<button class=\"download ui-btn ui-btn-a ui-btn-right ui-btn-inline ui-btn-icon-left ui-icon-arrow-d ui-corner-all\">PDF</button>\n" +
        "</div>";
    return page;
});