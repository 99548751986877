define([
	'../../lib/jquery-1.11.0', '../../lib/lodash-2.4.1.compat', '../../lib/backbone-1.1.2', '../../model/user'
], function (
	$, _, Backbone, user
) {
	
	var infoText = {
		getInfoText: function () {
			return $('<table id="infoTable">').append($('<colgroup width="20%">')).append($('<colgroup width="80%">'))
				.append($('<tr>').append($('<th colspan="2">').text(user.translate('shortcut.infoTable.headline'))))
				.append($('<tr>').append($('<td>').text(user.translate('shortcut.infoTable.r1.c1'))).append($('<td>').text(user.translate('shortcut.infoTable.r1.c2'))))
				.append($('<tr>').append($('<td>').text(user.translate('shortcut.infoTable.r2.c1'))).append($('<td>').text(user.translate('shortcut.infoTable.r2.c2'))))
				.append($('<tr>').append($('<td>').text(user.translate('shortcut.infoTable.r3.c1'))).append($('<td>').text(user.translate('shortcut.infoTable.r3.c2'))))
				.append($('<tr>').append($('<td>').text(user.translate('shortcut.infoTable.r4.c1'))).append($('<td>').text(user.translate('shortcut.infoTable.r4.c2'))))
				.append($('<tr>').append($('<td>').text(user.translate('shortcut.infoTable.r5.c1'))).append($('<td>').text(user.translate('shortcut.infoTable.r5.c2'))))
				.append($('<tr>').append($('<td>').text(user.translate('shortcut.infoTable.r6.c1'))).append($('<td>').text(user.translate('shortcut.infoTable.r6.c2'))))
				.append($('<tr>').append($('<td>').text(user.translate('shortcut.infoTable.r7.c1'))).append($('<td>').text(user.translate('shortcut.infoTable.r7.c2'))))
				.append($('<tr>').append($('<td>')).append($('<td>').text(user.translate('shortcut.infoTable.r8.c2'))))
				.append($('<tr>').append($('<th colspan="2" style="padding-top: 10px">').text(user.translate('shortcut.infoTable.r9'))))
		}
	};
	return infoText;
});