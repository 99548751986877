define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../model/user','../lib/backbone-1.1.2'
], function ($, _, user, Backbone) {
	return Backbone.View.extend({

		tagName: 'ul',
		className: 'autocomplete',
		attributes: {
			'data-role':  'listview',
			'data-inset': 'true'
		},

		events: {
			'listviewbeforefilter': 'createFilter'
		},

		initialize: function (options) {
			this.options = options;
			this.reposition = _.bind(this.reposition, this);
			this.hideSuggestions = _.bind(this.hideSuggestions, this);
			this.preventBlurIfListInteraction = _.bind(this.preventBlurIfListInteraction, this);
			$(window).on('throttledresize', this.reposition);
			$('body').on('mousedown', this.preventBlurIfListInteraction);
		},

		render: function () {
			var self = this;
			this.input = this.options.input.attr({
				autocomplete: 'off',
				autocorrect:  'off'
			}).on('change', function (e, allowPropagation) {
				if (!allowPropagation) {
					e.stopPropagation();
				}
			}).on('keyup blur', function (e) {
				if (e.keyCode == 13 && self.firstSuggestion) {
					self.input.attr('id') === 'chooseProject' ? self.input.val(self.firstSuggestion[0]) : self.input.val(self.firstSuggestion);
					if(self.input.attr('id') === 'chooseProject') self.input.attr('value', self.firstSuggestion[1])
				}
				if (e.type == 'blur' || e.keyCode == 13) {
					self.input.trigger('change', true);
				}
				if (e.type == 'blur' || e.keyCode == 13 || e.keyCode == 27) {
					setTimeout(function () {
						self.hideSuggestions();
					}, 100);
					e.preventDefault();
				} else {
					self.createFilter();
				}
			}).on('focus', function () {
				self.createFilter();
				var val = self.val();
				var pos = val !== null ? val.length : 0;
				if (self.input[0].setSelectionRange) {
					self.input[0].setSelectionRange(pos, pos);
				}
			}).on('click', function (e) {
				e.stopPropagation();
			}).val(this.options.value);
			this.$el.appendTo(this.options.container || this.input.parent()).hide();
			if (this.options.toggleButton) {
				var wrapperClasses = (this.input.attr('data-wrapper-class') || '').split(' ');
				if (!_.contains(wrapperClasses, 'ui-icon-carat-d ui-btn-icon-right')) {
					wrapperClasses.push('ui-icon-carat-d');
					wrapperClasses.push('ui-btn-icon-right');
					wrapperClasses.push('autocompleteToggleWrapper');
				}
				this.input.attr('data-wrapper-class', wrapperClasses.join(' '));
				try {
					this.input.textinput('refresh');
				} catch (e) {}
				$(document).on('click', '.autocompleteToggleWrapper', _.bind(function (e) {
					if ($(e.currentTarget).children('input').is(this.input)) {
						if (this.$el.css('display') === 'none') {
							this.createFilter(true);
							e.stopPropagation();
						} else {
							this.hideSuggestions();
						}
					}
				}, this));
				$(document).on('focus', 'input', _.bind(function (e) {
					if ($(e.currentTarget).children('input').is(this.input) && this.$el.css('display') !== 'none') {
						this.hideSuggestions();
					}
				}, this));
			}
			return this;

		},

		createFilter: function (ignoreValue) {
			var value = this.val();
			if (this.input.prop('readonly') || this.input.prop('disabled')) {
				this.hideSuggestions();
				return;
			}
			if (this.options.searchLocation) {
				this.showSuggestionsForLocation(this.options.lookup(ignoreValue ? undefined : value));
			} else {
				this.showSuggestions(this.options.lookup(ignoreValue ? undefined : value), this.options.input);
			}


		},

		showSuggestionsForLocation: function(foundSuggestions) {
			this.firstSuggestion = null;
			var startIndexLocations;
			var userInput
			var suggestions;


			if (foundSuggestions[0] instanceof Array) { // check if there is a wrapper. If such there are some further values (not only locations) passed as arguments.
				startIndexLocations = foundSuggestions.pop();
				userInput = foundSuggestions.pop();
				suggestions = foundSuggestions[0];

			} else { // only locations passed
				suggestions = foundSuggestions;
			}

			if (suggestions.length) {
				var i = 0;

				this.$el.show().empty();
				$('body').off('click', this.hideSuggestions);
				_.each(suggestions, function (suggestion) {
					if (!this.firstSuggestion) {
						this.firstSuggestion = userInput;
					}
					var listOptions;

					if (i == 0 || (startIndexLocations && i == startIndexLocations - 1)) {
						listOptions = $('<li class="non-interactive-li-item"></li>');
					}else if (i >= startIndexLocations){
						listOptions = $('<li class="location-suggestion"></li>');
					} else {
						listOptions = $('<li></li>');
					}

					this.$el.append(listOptions.append(
						$('<a href="javascript:void(0)"></a>')
							.html(suggestion)
							.on('mousedown', _.bind(function (e) {
								e.preventDefault();
								$('body').off('click', this.hideSuggestions);
								if (!e.currentTarget.parentElement.classList.contains('non-interactive-li-item')) {
									if (e.currentTarget.parentElement.classList.contains('location-suggestion')){
										this.val("location " + suggestion);
									} else {
										this.val(userInput + "userinputstring" + suggestion);
									}
									this.hideSuggestions();
									this.input.trigger('blur');
								}

							}, this)))
					);
					i++;

				}, this);

				if(this.$el.listview)
					this.$el.listview('refresh');
				setTimeout($.proxy(function () {
					this.reposition();
					$('body').one('click', this.hideSuggestions);
				}, this), 100);
			} else if (this.$el.get(0).childNodes.length) {
				this.hideSuggestions();
			}
		},

		showSuggestions: function (foundSuggestions, input) {
            		var  suggestions = foundSuggestions
			this.firstSuggestion = null;

			var isProjectChooser = input.attr('id') === 'chooseProject'

			if (suggestions.length) {
				var i = 0;

				this.$el.show().empty();
				$('body').off('click', this.hideSuggestions);
				_.each(suggestions, function (suggestion) {
					var useValue = isProjectChooser ? !!suggestion[1] : true;
					if (!this.firstSuggestion && useValue) {
						this.firstSuggestion = suggestion;
					}

					var listOptions = $('<li></li>');
					
					var html = suggestion
					if (isProjectChooser) html = suggestion[0] + (suggestion[1] ? ' (' + suggestion[1] + ')' : '');
					
					this.$el.append(listOptions.append(
						$('<a href="javascript:void(0)"></a>')
							.html(html)
							.on('mousedown', _.bind(function (e) {
								e.preventDefault();
								$('body').off('click', this.hideSuggestions);
								this.val(suggestion);
								this.hideSuggestions();
								this.input.trigger('blur');
							}, this)))
					);
					i++;

				}, this);

				if(this.$el.listview)
					this.$el.listview('refresh');
				setTimeout($.proxy(function () {
					this.reposition();
					$('body').one('click', this.hideSuggestions);
				}, this), 100);
			} else if (this.$el.get(0).childNodes.length) {
				this.hideSuggestions();
			}
		},

		hideSuggestions: function () {
			this.firstSuggestion = null;
			this.$el.hide().empty();
//			this.$el.listview('refresh');
		},

		val: function (value) {
			if (!arguments.length) {
				return this.input.val();
			}
			if (this.input) {
				if(this.input.attr('id') !== 'chooseProject') this.input.val(value).change();
				if (this.input.attr('id') === 'chooseProject') {
					this.input.attr('value', value[1])
					this.input.val(value[1] === '' ? user.translate('overview.choose2') : value[0]).change()
				}
			} else {
				this.options.value = val;
			}
			return this;
		},

		remove: function () {
			$(window).off('throttledresize', this.reposition);
			$('body').off('mousedown', this.preventBlurIfListInteraction);
			Backbone.View.prototype.remove.apply(this, arguments);
		},

		reposition: function () {
			var inputParent = this.input.parent();

			if (inputParent) {
                var inputOffset = this.options.offset || inputParent.position();

                if (inputOffset) {
                    this.$el.css({ top: inputOffset.top + window.parseInt(inputParent.css('marginTop'), 10) + inputParent.outerHeight(),
						right: this.options.rightOffset == null ? inputOffset.right : this.options.rightOffset + "px",
						left: this.options.leftOffset == null ? inputOffset.left : this.options.leftOffset + "px",
						width: this.options.autowidth ? 'auto' : this.options.width == null ? inputParent.outerWidth(true): this.options.width + "%"});
                }
			}
		},

		preventBlurIfListInteraction: function(e) {
			if (this.$el.css('display') !== 'none' && (this.$el.is(e.target) || this.$el.has(e.target).length)) {
				e.preventDefault();
			}
		}

	});
});
