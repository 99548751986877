define([
	'../../lib/jquery-1.11.0', '../../lib/lodash-2.4.1.compat', '../../lib/backbone-1.1.2', '../../model/user', './shortcutsHighlighter', './shortcutsKeys', '../iosapp'
], function (
	$, _, Backbone, user, shortcutsHighlighter, shortcutsKeys, iosapp
) {
	var router;
	
	var isActive = false;
	
	var shortcuts = {
		initialize: function (options) {
			var ua = window.navigator.userAgent;
			var msie = ua.indexOf("MSIE ");
			if (!iosapp.appavailable && !iosapp.androidappavailable && !iosapp.windowsappavailable && !window.oxmobile && !(msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))) {
				isActive = true;
				router = options.router;
				settings = user.get('settings');
				shortcutsKeys.initialize(settings, this);
				shortcutsHighlighter.initialize(this);
				shortcuts.addListeners();
			}
		},
		
		listenerLogic: function (e) {
//			Check if focused element is an input field, so shortcuts dont get used here and the user can still write
			if ((e.target.tagName.toLowerCase() === 'input' || e.target.tagName.toLowerCase() === 'textarea' || e.target.tagName.toLowerCase() === 'select') && !e.altKey && (e.keyCode < 112 || e.keyCode > 123)) {
				return;
			}
			
			var customerSelected = user.get('customer');
			var projectSelected = router.currentProject.id;
			var inspection = router.currentInspection.id;

//			Project/Customer selection
//			Use 1-9 in the upper row or on the numpad to choose a project/customer
// 			if ((!e.altKey && !e.ctrlKey && !e.shiftKey) && (e.keyCode >= 48 && e.keyCode <= 57 || e.keyCode >= 96 && e.keyCode <= 105)) {
// 				var num = shortcutsKeys.getNumberOfKeyCode(e.keyCode);
// 				router.currentPage && router.currentPage.trigger('shortcut', 'num', num);
// 			}
//			Projectmanager
			if (shortcutsKeys.checkKey(e, 'projectManager')) {
				e.preventDefault();
//				Only if user is an admin
				if (user.isAdmin()) {
//				Open projectmanager in new tab
					worked = window.open( '/projectmanager')
//					Check if it worked
					if (!worked) {
						shortcuts.doCheck(function () {
							window.location.href =  '/projectmanager';
						});
					}
				}
			}
//			Admin tables
			if (shortcutsKeys.checkKey(e, 'adminTables')) {
				e.preventDefault();
				var link =  '/docs/customers/' + user.get('customer') + '/inspections.json';
//				only if user is an admin
				if (user.isAdmin()) {
//					Open admin tables in new tab
					worked = window.open(link)
//					Check if it worked
					if (!worked) {
						shortcuts.doCheck(function () {
							window.location.href = link;
						});
					}
				}
			}
//			Settings page
			if (shortcutsKeys.checkKey(e, 'settingsPage')) {
				e.preventDefault();
				shortcuts.doCheck(function () {
					if (projectSelected) router.settings(projectSelected)
				});
			}
//			Defect list
			if (shortcutsKeys.checkKey(e, 'defectList')) {
				e.preventDefault();
				shortcuts.doCheck(function () {
					if (projectSelected) router.defects(projectSelected)
				});
			}
//			Save current defect or send instantmessage
			if (shortcutsKeys.checkKey(e, 'defectSave')) {
				e.preventDefault();
				if(router.currentPage.el.id === 'instant') {
					router.currentPage && router.currentPage.trigger('shortcut', 'send', e);
				} else {
					router.currentPage && router.currentPage.trigger('shortcut', 'save', e);
				}
			}
//			Save current defect and create new defect
			if (shortcutsKeys.checkKey(e, 'defectSaveAndNew')) {
				e.preventDefault();
				router.currentPage && router.currentPage.trigger('shortcut', 'savenew', e);
			}
//			Save current defect and duplicate it
			if (shortcutsKeys.checkKey(e, 'defectSaveAndDuplicate')) {
				e.preventDefault();
				router.currentPage && router.currentPage.trigger('shortcut', 'saveduplicate', e);
			}
//			Save current defect and go to next
			if (shortcutsKeys.checkKey(e, 'defectSaveAndNext')) {
				e.preventDefault();
				router.currentPage && router.currentPage.trigger('shortcut', 'savenext', e);
			}
//			Go to next defect
			if (shortcutsKeys.checkKey(e, 'defectNext')) {
				e.preventDefault();
				router.currentPage && router.currentPage.trigger('shortcut', 'next', e);
			}
//			Go to previous defect
			if (shortcutsKeys.checkKey(e, 'defectPrevious')) {
				e.preventDefault();
				router.currentPage && router.currentPage.trigger('shortcut', 'previous', e);
			}
//			Inspection list
			if (shortcutsKeys.checkKey(e, 'inspectionList')) {
				e.preventDefault();
				shortcuts.doCheck(function () {
					if (projectSelected) router.inspections(projectSelected)
				});
			}
//			Current inspection
			if (shortcutsKeys.checkKey(e, 'inspectionCurrent')) {
				e.preventDefault()
				if (router.currentInspection.id) {
					shortcuts.doCheck(function () {
						if (projectSelected) router.inspection(projectSelected, router.currentInspection.id)
					});
				}
			}
//			New defect or inspection depending on the page
			if (shortcutsKeys.checkKey(e, 'newDefect')) {
				e.preventDefault();
				shortcuts.doCheck(function () {
					router.defectPage.goNew(e)
				});
			}
//			New defect or inspection depending on the page
			if (shortcutsKeys.checkKey(e, 'newInspection')) {
				e.preventDefault();
				if (projectSelected) router.inspection(projectSelected, undefined, true)
			}
//			New instant message
			if (shortcutsKeys.checkKey(e, 'newInstantMessage')) {
				e.preventDefault();
				if (projectSelected) router.instant(projectSelected, inspection);
			}
//			Open or close the left side menu
			if (shortcutsKeys.checkKey(e, 'leftMenu')) {
				e.stopPropagation();
				e.preventDefault();
				router.defectPage.confirmation().then(function () {
//				Only works when customer and project are selected
					if (customerSelected && projectSelected) {
//					Check if menu is allrdy open
						if (router.sidePanel.isSidePanelOpen()) {
//						Close menu
							router.sidePanel.close();
						} else {
//						Open menu
							router.sidePanel.open();
						}
					}
				})
			}
//			Open or close the right side menu
			if (shortcutsKeys.checkKey(e, 'rightMenu')) {
//				Only works when customer and project are selected
				if (customerSelected && projectSelected) {
//					Check if menu is allrdy open
					if (router.currentPage.triggerActionsMenu && router.currentPage.isActionsMenuOpen) {
						if (router.currentPage.isActionsMenuOpen()) {
//						Close menu
							router.currentPage.closeActionsMenu(e);
						} else {
//						Open menu
							router.currentPage.triggerActionsMenu(e);
						}
					}
				}
			}
//			Focus input 1
			if (shortcutsKeys.checkKey(e, 'focusInput1')) {
				router.currentPage && router.currentPage.trigger('shortcut', 'focusinput1');
			}
//			Focus input 2
			if (shortcutsKeys.checkKey(e, 'focusInput2')) {
				router.currentPage && router.currentPage.trigger('shortcut', 'focusinput2');
			}
		},
		
		doCheck: function (doIt) {
			var later;
			if (router.currentPage && router.currentPage.triggerMenu) {
				later = router.currentPage.triggerMenu();
			} else {
				later = $.Deferred().resolve();
			}
			later.done(_.bind(function () {
				doIt();
			}, this));
		},
		
		addListeners: function () {
			$('#news object').load(function () {
				$(this).contents().find('body')[0].addEventListener('keydown', function (e) {
					shortcuts.listenerLogic(e);
				});
			});
			$('body').on('keydown', function (e) {
				shortcuts.listenerLogic(e);
			});
		},
		
		getShortcutsHighlighter: function () {
			return shortcutsHighlighter;
		},
		
		getShortcutsKeys: function () {
			return shortcutsKeys;
		},
		
		getRouter: function () {
			return router;
		},
		
		getIsActive: function () {
			return isActive;
		}
	};
	return shortcuts;
});