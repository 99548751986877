import {CustomizedDialog, TableHeadCell} from "../../../components/muiCustomizedComponents";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {copyToClipboard, translate} from "../../../utils/utils";
import React from "react";
import {getVariableDescription, REQUEST_VARIABLE} from "./EmailCustomizerHelpDefaults";


type EmailCustomizerHelpProps = {
    open: boolean;
    onClose: () => void;
};


const EmailCustomizerHelp = ({open, onClose}: EmailCustomizerHelpProps) => {

    const copyVariable = async (variable: REQUEST_VARIABLE) => {
        let res = await copyToClipboard(variable);
        if (res) {
            onClose();
        }
    }

    return (
        <CustomizedDialog open={open} onClose={onClose}>
            <DialogTitle>{translate("settings.emailvariableshint")}</DialogTitle>
            <DialogContent>
                <Paper>
                    <TableContainer sx={{border: '1px solid #eee'}}>
                        <Table stickyHeader={true}>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{translate("settings.emailvariable")}</TableHeadCell>
                                    <TableHeadCell>{translate("settings.emailvariablemeaning")}</TableHeadCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Object.values(REQUEST_VARIABLE)
                                        .map(variable => {
                                            return <TableRow key={variable} className="copiable" onClick={() => {
                                                copyVariable(variable)
                                            }}>
                                                <TableCell>{variable}</TableCell>
                                                <TableCell>{getVariableDescription(variable)}</TableCell>
                                            </TableRow>
                                        })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{translate("close")}</Button>
            </DialogActions>
        </CustomizedDialog>
    );
};

export default EmailCustomizerHelp;