define([
    '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2',
    '../model/Person', '../model/currentProject', '../model/user', '../helper/interactiveSync'
], function (
    $, _, Backbone,
    Person, currentProject, user, interactiveSync
) {
    return new (Backbone.Collection.extend({

        model: Person,

        loadingAll: false,

        url: function () {
             return  '/onlineBauabnahme/api/warrantyData/' + this.subcontractor + '/' + this.lo_id + '/'  + user.get('customer') + '/' + this.project;
        },

        // setSubcontractorAndDepth: function (subcontractor, project , depth) {
        //     this.loadingAll = false
        //     this.subcontractor = subcontractor;
        //     this.project = project;
        //     this.depth = depth;
        //
        //     if (!depth || !project || !subcontractor) {
        //         this.reset();
        //         return $.Deferred().resolve();
        //     }
        //     return this.fetch({ reset: true })
        // },

        setSubcontractorAndLocation: function (subcontractor, project , lo_id, refresh) {
            if (subcontractor == this.subcontractor && lo_id == this.lo_id) {
                if (this.pending && !refresh) {
                    return this.pending;
                } else if (!refresh) {
                    return $.Deferred().resolve();
                }
            }

            this.subcontractor = subcontractor;
            this.project = project;
            this.lo_id = lo_id;
            if (!subcontractor) {
                this.reset();
                return $.Deferred().resolve();
            }
            this.pending = this.fetch({ reset: true })
                .fail(_.bind(function () {
                    alertPopup(user.translate('warrantyData.error'));
                }, this))
                .always(_.bind(function () {
                    delete this.pending;
                }, this));
            return this.pending;
        },
    }));
});