define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div class=\"offline\">\n" +
        "\t<%= t('statistics.note.nostatsinofflinemode') %>\n" +
        "</div>\n" +
        "<div class=\"content\">\n" +
        "\t<div class=\"ui-grid-b\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t<select></select>\n" +
        "\t\t<label><input type=\"checkbox\" name=\"onlyExternal\"/> <%= t('statistics.onlyexternal') %></label>\n" +
        "\t</div>\n" +
        "\t<div class=\"noStats\">\n" +
        "\t\t<%= t('statistics.note.nostats') %>\n" +
        "\t</div>\n" +
        "\t<div class=\"noStatsGroup\">\n" +
        "\t\t<%= t('statistics.note.nostatsgroup') %>\n" +
        "\t</div>\n" +
        "\t<div id=\"chartContainer\"></div>\n" +
        "\t<% if (showNumbers) { %>\n" +
        "\t<div class=\"numbers clearfix\">\n" +
        "\t\t<table>\n" +
        "\t\t\t<thead><tr><th colspan=\"2\"><%= t('statistics.numbers.deadlines') %></th></tr></thead>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.deadlineexpired') %></td>\n" +
        "\t\t\t\t<td class=\"deadlineexpired\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.deadlineexpiringin3days') %></td>\n" +
        "\t\t\t\t<td class=\"deadlineexpiringin3days\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.deadlineexpiringin7days') %></td>\n" +
        "\t\t\t\t<td class=\"deadlineexpiringin7days\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr><td></td><td></td></tr>\n" +
        "\t\t</table>\n" +
        "\t\t<table>\n" +
        "\t\t\t<thead><tr><th colspan=\"2\"><%= t('statistics.numbers.lastweek') %></th></tr></thead>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.all') %></td>\n" +
        "\t\t\t\t<td class=\"lastweekall\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.fixed') %></td>\n" +
        "\t\t\t\t<td class=\"lastweekfixed\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.open') %></td>\n" +
        "\t\t\t\t<td class=\"lastweekopen\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.subcontractorokandpending') %></td>\n" +
        "\t\t\t\t<td class=\"lastweeksubcontractorokandpending\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t</table>\n" +
        "\t\t<table>\n" +
        "\t\t\t<thead><tr><th colspan=\"2\"><%= t('statistics.numbers.last4weeks') %></th></tr></thead>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.all') %></td>\n" +
        "\t\t\t\t<td class=\"last4weeksall\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.fixed') %></td>\n" +
        "\t\t\t\t<td class=\"last4weeksfixed\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.open') %></td>\n" +
        "\t\t\t\t<td class=\"last4weeksopen\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.subcontractorokandpending') %></td>\n" +
        "\t\t\t\t<td class=\"last4weekssubcontractorokandpending\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t</table>\n" +
        "\t\t<table>\n" +
        "\t\t\t<thead><tr><th colspan=\"2\"><%= t('statistics.numbers.total') %></th></tr></thead>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.all') %></td>\n" +
        "\t\t\t\t<td class=\"totalall\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.fixed') %></td>\n" +
        "\t\t\t\t<td class=\"totalfixed\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.open') %></td>\n" +
        "\t\t\t\t<td class=\"totalopen\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td><%= t('statistics.numbers.subcontractorokandpending') %></td>\n" +
        "\t\t\t\t<td class=\"totalsubcontractorokandpending\"></td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t</table>\n" +
        "\t</div>\n" +
        "\t<% } %>\n" +
        "\t<div><sub><%= t('statistics.note.collectiondaily') %></sub></div>\n" +
        "</div>";
    return page;
});