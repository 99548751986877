import {translate} from "../../utils/utils";

export enum Language {
    DE = 'DE', EN = 'EN', ZH = 'ZH', PL = 'PL'
}

export namespace Language {
    export function getLanguageDescription(lang: Language): string {
        switch (lang) {
            case Language.DE:
                return "Deutsch";
            case Language.EN:
                return "English";
            case Language.PL:
                return "Polski";
            case Language.ZH:
                return "中文";
            default:
                return "Deutsch";
        }
    }
}

export enum PrintMode {
    NORMAL = "NORMAL",
    DETAIL = "DETAIL",
    FULLHISTORY = "FULLHISTORY",
    COMPACT = "COMPACT",
    COMPACTEXTRA = "COMPACTEXTRA",
    COMPACTPLANFIRST = "COMPACTPLANFIRST",
    PLANEXTRACOLUMN = "PLANEXTRACOLUMN",
    NOIMAGES = "NOIMAGES",
    ECO = "ECO",
    LONG = "LONG"
}

export namespace PrintMode {
    export function getPrintModeDescription(mode: PrintMode): string {
        switch (mode) {
            case PrintMode.NORMAL:
                return translate("settings.printing.normal");
            case PrintMode.DETAIL:
                return translate("settings.printing.detail");
            case PrintMode.FULLHISTORY:
                return translate("settings.printing.fullhistory");
            case PrintMode.COMPACT:
                return translate("settings.printing.compact");
            case PrintMode.COMPACTEXTRA:
                return translate("settings.printing.compactextra");
            case PrintMode.COMPACTPLANFIRST:
                return translate("settings.printing.compactplanfirst");
            case PrintMode.PLANEXTRACOLUMN:
                return translate("settings.printing.planextracolumn");
            case PrintMode.NOIMAGES:
                return translate("settings.printing.noimages");
            case PrintMode.ECO:
                return translate("settings.printing.eco");
            case PrintMode.LONG:
                return translate("settings.printing.long");
            default:
                return translate("settings.printing.normal");

        }
    }
}

export enum CostType {
    NONE = "NONE",
    GLOBAL = "GLOBAL",
    SPECIFIC = "SPECIFIC",
    SEPARATE = "SEPARATE"
}

export namespace CostType {
    export function getDescription(type: CostType) {
        switch (type) {
            case CostType.NONE:
                return translate("settings.costType.none");
            case CostType.GLOBAL:
                return translate("settings.costType.global");
            case CostType.SPECIFIC:
                return translate("settings.costType.specific");
            case CostType.SEPARATE:
                return translate("settings.costType.separate");
            default:
                return translate("settings.costType.none");
        }
    }
}

export enum UploadSetting {
    NONE = "NONE",
    AG = "AG",
    AN = "AN",
    AGAN = "AGAN"
}

export namespace UploadSetting {
    export function getDescription(setting: UploadSetting) {
        switch (setting) {
            case UploadSetting.NONE:
                return translate("settings.defaultuploadsettings.none");
            case UploadSetting.AG:
                return translate("settings.defaultuploadsettings.ag");
            case UploadSetting.AN:
                return translate("settings.defaultuploadsettings.an");
            case UploadSetting.AGAN:
                return translate("settings.defaultuploadsettings.agan");
        }
    }
}

export enum SubcontractorOrder {
    TABLE = "TABLE",
    NAME = "NAME",
    CREW = "CREW"
}

export namespace SubcontractorOrder {
    export function getDescription(order: SubcontractorOrder) {
        switch (order) {
            case SubcontractorOrder.TABLE:
                return translate("settings.subcontractororder.table");
            case SubcontractorOrder.NAME:
                return translate("settings.subcontractororder.name");
            case SubcontractorOrder.CREW:
                return translate("settings.subcontractororder.crew");

        }
    }
}

export enum FILENAME_SCHEMA {
    STANDARD = "STANDARD",
    OTHER = "OTHER",
    LONG = "LONG"
}

export namespace FILENAME_SCHEMA {
    export function getDescription(schema: FILENAME_SCHEMA) {
        switch (schema) {
            case FILENAME_SCHEMA.STANDARD:
                return translate("settings.filenameschema.standard");
            case FILENAME_SCHEMA.OTHER:
                return translate("settings.filenameschema.other");
            case FILENAME_SCHEMA.LONG:
                return translate("settings.filenameschema.long");

        }
    }
}

export enum DOCUMENT_TYPE {
    PROTOCOL_PREVIEW = "PROTOCOL_PREVIEW",
    SINGLE_PREVIEW = "SINGLE_PREVIEW",
    REQUEST_PREVIEW = "REQUEST_PREVIEW"
}

export namespace DOCUMENT_TYPE {
    export function getDownloadUrlPrefix(type: DOCUMENT_TYPE) {
        switch (type) {
            case DOCUMENT_TYPE.PROTOCOL_PREVIEW:
                return "/api/downloadProtocolPreview?";

            case DOCUMENT_TYPE.SINGLE_PREVIEW:
                return  "/api/downloadSinglePreview?";
            case DOCUMENT_TYPE.REQUEST_PREVIEW:
                return "/api/downloadPreview?";
        }
    }

    export function getPreviewUrl(type: DOCUMENT_TYPE) {
        switch (type) {
            case DOCUMENT_TYPE.PROTOCOL_PREVIEW:
                return "/api/showProtocolPreview";

            case DOCUMENT_TYPE.SINGLE_PREVIEW:
                return  "/api/showSinglePreview";
            case DOCUMENT_TYPE.REQUEST_PREVIEW:
                return "/api/showPreview";
        }
    }

    export function getTemplateEditUrl(type: DOCUMENT_TYPE, projectId: string, attributes: any) {
        let suffix = Object.values(attributes).join('/');
        switch (type) {
            case DOCUMENT_TYPE.PROTOCOL_PREVIEW:
                return `/api/getTextFromTemplateInspection/${projectId}/${suffix}`;
            case DOCUMENT_TYPE.SINGLE_PREVIEW:
                return  `/api/getTextFromTemplateSingle/${projectId}/${suffix}`;
            case DOCUMENT_TYPE.REQUEST_PREVIEW:
                return `/api/getTextFromTemplate/${projectId}/${suffix}`;
        }
    }
}


export enum DEFECT_SYNC_DIRECTION {
    TO_PRINCIPAL, TO_SUBCONTRACTOR
}

export namespace DEFECT_SYNC_DIRECTION{
    export function getDescription(direction: DEFECT_SYNC_DIRECTION) {
        switch (direction) {
            case DEFECT_SYNC_DIRECTION.TO_SUBCONTRACTOR:
                return translate("settings.defectsync.tosubcontractor");
            case DEFECT_SYNC_DIRECTION.TO_PRINCIPAL:
                return translate("settings.defectsync.toprincipal");
        }
    }
}

export enum USER_AUTHORITIES {
    ROLE_SUBCONTRACTOR = "ROLE_SUBCONTRACTOR",
    ROLE_PRINCIPAL = "ROLE_PRINCIPAL",
    ROLE_PRINCIPAL_READONLY = "ROLE_PRINCIPAL_READONLY",
    ROLE_READONLY = "ROLE_READONLY",
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_PROJECT_ADMIN = "ROLE_PROJECTADMIN"
}

