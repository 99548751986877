import {useAppSelector} from "../store/hooks";
import {CommonStoreStateKeys} from "../store/slices/commonStoreSlice";
import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {useNavigate} from "react-router-dom";
import {getCustomerProjectWithPrefix, isUserReadonly, translate} from "../utils/utils";


const Inspections = () => {

    const navigate = useNavigate();
    const {customerProject} = getCustomerProjectWithPrefix();

    const user = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.user]);
    const watchdog = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.watchdog]);


    useEffect(()=>{
        // @ts-ignore
        window.inspectionsUseEffect();
    }, [])

    const newInspection = ()=>{
        navigate(`${customerProject}/inspection`);
    }

    const goToProject = ()=>{
        navigate(`${customerProject}`)
    }

    return <PageContainer newPage={false} headerText={translate("inspections.title")}
                          left={<StyledButton onClick={goToProject}>{translate("inspections.back")}</StyledButton>}
                          right={!isUserReadonly(user) && watchdog.connected && <StyledButton onClick={newInspection}>{translate("inspections.new")}</StyledButton>}
    />
}

export default Inspections;