import {CenterContainer, FullPage} from "./styledComponents";
import {CircularProgress, useMediaQuery} from "@mui/material";
import React from "react";
import {theme} from "./muiCustomizedComponents";

export const Spinner = ()=> {
    const length = useMediaQuery(theme.breakpoints.up('sm')) ? 100 : 60;
    return <FullPage>
        <CenterContainer>
            <CircularProgress color={"primary"} style={{width: length, height: length}}/>
    </CenterContainer>
    </FullPage>
}