define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" data-position=\"fixed\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "    <header style=\"margin: 10px 0 40px 0;\n" +
        "    font-weight: bold;\n" +
        "    font-size: 22px;\"><%= t('person.header') %></header>\n" +
        "    <div style=\"overflow-x:auto;\">\n" +
        "        <table class=\"peopleTable\" style=\"margin: 0 0 0 18px; overflow: scroll; white-space: nowrap; display: table;\n" +
        "    border-collapse: collapse;\n" +
        "    border-spacing: 2px;\"></table>\n" +
        "    </div>\n" +
        "    <div data-role=\"popup\" class=\"addPersonPopup\">\n" +
        "        <div class=\"editPersonsPopup\" style=\" flex-direction: column; height: 100%;\">\n" +
        "            <div class=\"editPersonsPopupContent\">\n" +
        "                <div style=\"overflow-y: auto\">\n" +
        "                    <div style=\"display: flex; flex-direction: column\">\n" +
        "                        <h2><%= t('person.gui') %></h2>\n" +
        "                        <div>\n" +
        "                            <h3><%= t('person.primaryContact') %></h3>\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.firstname') %>\" class=\"firstNamePerson1\" id=\"firstNamePerson1\"/>\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.surname') %>\" class=\"surnamePerson1\"  id=\"surnamePerson1\"/>\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.address') %>\" class=\"addressPerson1\" id=\"addressPerson1\"/>\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.phone') %>\" class=\"phonePerson1\"/>\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.mobile') %>\" class=\"mobilePerson1\"/>\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.email') %>\" class=\"emailPerson1\"/>\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <select class=\"sexPerson1\" >\n" +
        "                                    <option value=\"\"></option>\n" +
        "                                    <option value=\"male\"><%= t('person.male') %></option>\n" +
        "                                    <option value=\"female\"><%= t('person.female') %></option>\n" +
        "                                    <option value=\"divers\"><%= t('person.divers') %></option>\n" +
        "                                </select>\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.type') %>\" class=\"typePerson1\" />\n" +
        "                            </div>\n" +
        "                        </div>\n" +
        "\n" +
        "                        <div>\n" +
        "                            <h3><%= t('person.secondaryContact') %></h3>\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.firstname') %>\" class=\"firstNamePerson2\" />\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.surname') %>\" class=\"surnamePerson2\" />\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.address') %>\" class=\"addressPerson2\"/>\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.phone') %>\" class=\"phonePerson2\"/>\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.mobile') %>\" class=\"mobilePerson2\"/>\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.email') %>\" class=\"emailPerson2\"/>\n" +
        "                            </div>\n" +
        "\n" +
        "                            <div>\n" +
        "                                <select class=\"sexPerson2\" >\n" +
        "                                    <option value=\"\"></option>\n" +
        "                                    <option value=\"male\"><%= t('person.male') %></option>\n" +
        "                                    <option value=\"female\"><%= t('person.female') %></option>\n" +
        "                                    <option value=\"divers\"><%= t('person.divers') %></option>\n" +
        "                                </select>\n" +
        "                            </div>\n" +
        "\n" +
        "\n" +
        "                            <div>\n" +
        "                                <input type=\"text\" placeholder=\"<%= t('person.type') %>\" class=\"typePerson2\" />\n" +
        "                            </div>\n" +
        "                        </div>\n" +
        "                    </div>\n" +
        "                    <div class=\"deadlineChooserPerson\">\n" +
        "                        <div><%= t('person.expirationDate') %></div>\n" +
        "                        <input id=\"deadline-for-selected-defects-picker\" type=\"date\" class=\"deadline\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "                    </div>\n" +
        "                </div>\n" +
        "                <div class=\"buttons\" style=\"display: flex; flex-direction: row\" style=\"margin: 10px\">\n" +
        "                    <button id=\"closeAddPersonPopupButton\" class=\"cancel\" style=\"margin: 10px;\"><%= t('person.cancel') %></button>\n" +
        "                    <button id=\"sendAddPersonPopupButton\" class=\"send\" style=\"margin: 10px;\"><%= t('person.save') %></button>\n" +
        "                </div>\n" +
        "            </div>\n" +
        "        </div>\n" +
        "    </div>\n" +
        "    <div id=\"confirmCancelPopup\" style=\"flex-direction: column; height: 100%;\">\n" +
        "        <div class=\"editPersonsPopupContent\">\n" +
        "            <div id=\"confirmCancelPopupHeader\"><!--  -->\n" +
        "                <h1 class=\"ui-title\"><%= t('popup.title') %></h1> <!--  -->\n" +
        "            </div>\n" +
        "            <div id=\"confirmCancelPopupContent\">\n" +
        "                <p><%= t('person.cancelEditing') %></p>\n" +
        "            </div>\n" +
        "            <div class=\"buttons\" style=\"display: flex; flex-direction: row\" style=\"margin: 10px\">\n" +
        "                <button id=\"confirmCancelPopupCancelButton\"  style=\"margin: 10px;\"><%= t('person.cancel') %></button>\n" +
        "                <button id=\"confirmCancelPopupCloseButton\" style=\"margin: 10px;\"><%= t('person.close') %></button>\n" +
        "            </div>\n" +
        "        </div>\n" +
        "    </div>\n" +
        "</div>\n";
    return page;
});