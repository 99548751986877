import {EmailCustomConfiguration, EmailTemplate, EmailType} from "../types/types";
import {useEffect, useState} from "react";

export const useEmailConfiguration = (initialConfig: EmailCustomConfiguration,
                                      updateProjectSettings: (settings: {
                                          emailCustomConfiguration: EmailCustomConfiguration
                                      }) => void,
                                      isResetTriggered: boolean) => {
    const [config, setConfig] = useState(initialConfig);

    const updateConfig = (emailType: EmailType, field: keyof EmailTemplate, value: string) => {
        setConfig((prevState) => ({
            ...prevState,
            [emailType]: {
                ...prevState[emailType],
                [field]: value,
            }
        }));
    };

    const resetConfig = () => {
        setConfig(initialConfig);
    }

    useEffect(() => {
        updateProjectSettings({emailCustomConfiguration: config});
    }, [config]);


    useEffect(() => {
        if (isResetTriggered) resetConfig();
    }, [isResetTriggered]);

    return [config, updateConfig, resetConfig] as const;
};
