define([
    '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../view/BaseView'
], function (
    $, _, Backbone, BaseView
) {

    var ShortcutBaseView = function(options) {
        BaseView.call(this, options);

        this.on('shortcut', function() {
            var args = Array.prototype.slice.call(arguments); //to array
            var type = args.shift();
            this.shortcuts[type] && this.shortcuts[type].apply(this, args);
        }.bind(this));
    }

    _.extend(ShortcutBaseView.prototype, BaseView.prototype, {
        // base functions will be implemented here
    });

    ShortcutBaseView.extend = BaseView.extend;
    /*
    ShortcutBaseView.extend = function() {
        var result = Backbone.View.extend.apply(Backbone.View, arguments);

        result.on('shortcut', function() {
            var args = Array.prototype.slice.call(arguments); //to array
            var type = args.shift();
            this.shortcuts[type] && this.shortcuts[type].apply(this, args);
        }.bind(result));

        return result;
    };

     */
    return ShortcutBaseView;
});