define([
    '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2'
], function (
    $, _, Backbone
) {

    var BaseView = function(options) {
        Backbone.View.call(this, options);
    }

    var orientationListeners = {};

    $(window).on('orientationchange', function(event, param) {
        var args = arguments;
        if (param === 'baseview') {
            //it's a bubbled event we triggered ourselves
            return;
        }
        _.each(_.keys(orientationListeners), function(key) {
            if (orientationListeners[key]) {
                orientationListeners[key].trigger('orientationchange', [ 'baseview' ]);
            }
        });
    });

    _.extend(BaseView.prototype, Backbone.View.prototype, {
        delegateEvents: function() {
            var result =  Backbone.View.prototype.delegateEvents.apply(this, arguments);
            orientationListeners[this.cid] = this.$el;
            return result;
        },

        undelegateEvents: function() {
            var result = Backbone.View.prototype.undelegateEvents.apply(this, arguments);
            orientationListeners[this.cid] = undefined;
            return result;
        }
    });

    BaseView.extend = Backbone.View.extend;

    return BaseView;
});