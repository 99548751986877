define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './LocationPageHtml',
	'../model/currentProject', '../model/currentDefects', '../model/user', '../helper/watchdog', '../helper/backboneReactCommunicationUtils'
], function (
	$, _, Backbone, Template,
	currentProject, currentDefects, user, watchdog, backboneReactCommunicationUtils
) {
	return Backbone.View.extend({

		id: 'location',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		path: null,

		events: {
			'change .projects':   'changeProject',
			'vclick  .parent':    'toParent',
			'vclick  .nextPage':  'nextPage',

			'vclick .statusAll': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var unitPath = $(e.currentTarget).parent().parent().attr('data-path');
				currentDefects.resetFilter();
				currentDefects.elementFilter.unit = unitPath;
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defects');
			},

			'vclick .openCost': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var url =  '/onlineBauabnahme/webStatic/cost.pdf?project=' + window.encodeURIComponent(currentProject.id) + '&path=' + this.path + '&costAg=1&' + new Date().getTime();
				window.open(url);
			}

		},

		initialize: function (options) {
			this.resize = _.bind(this.resize, this);

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);

			this.back     = this.$('.back');
			this.parent   = this.$('.parent');
			this.$nextPage = this.$('.nextPage');
//			this.location = this.$('.location');
//			this.path     = this.$('.path');

			this.units    = this.$('.units');

			this.$el.on('pageshow', this.resize);
			$(window).on('throttledresize', this.resize);
			this.listenTo(watchdog, 'change:connected', _.bind(function () {
				if (!this.$el.is(':visible')) {
					return;
				}
				this.render();
			}, this));
		},

		render: function () {
			$('.nextPage').off('click');
			$('.nextPage').on('click', function (e) {
				this.nextPage(e);
			}.bind(this));
			$('.parent').off('click');
			$('.parent').on('click', function (e) {
				this.toParent(e);
			}.bind(this));
			$('.openCost').off('click');
			$('.openCost').on('click', function (e) {
				e.preventDefault();
				e.stopPropagation();
				var url =  '/onlineBauabnahme/webStatic/cost.pdf?project=' + window.encodeURIComponent(currentProject.id) + '&path=' + this.path + '&costAg=1&' + new Date().getTime();
				window.open(url);
			}.bind(this));
//			if (this.path === null) {
				this.setUnit('');
//			}

			$('.openCost').toggle(user.isPrincipal() && !!currentProject.get('costType'));

			$('.back').toggle(watchdog.isConnected());

			return this;
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active') && !self.$el.hasClass('hide-page')) {
					self.units.height(self.$el.css('min-height'));
				}
			}, this);
		},

		setUnit: function (path) {
			this.path = path;
			var unit = currentProject.get('unitTree'), parent = null;
			this.parentPath = path.substr(0, path.lastIndexOf('-'));
			if (path) {
				var parts = path.split('-');
				while (parts.length > 0) {
					unit = unit.children[parts.splice(0, 1)];
				}
//				this.path.val(unit.title);
				$('.parent').text(unit.name).show();
				$('.back').hide();
				//this.$nextPage.hide();
			} else {
				$('.parent').hide();
				$('.back').show();
				//this.$nextPage.show();
			}

			if (unit.children) {
				this.units.empty();
				var countInfo = this.countDefectsOf(unit.children); //TODO optimize
				_.each(_.compact(_.values(unit.children)), function (u) {
					var element = $('<li data-path="' + u.path + '"></li>').appendTo(this.units);
					element = $('<div class="ui-grid-a"></div>').appendTo(element);

					var pendingCount = 0;
					var totalCount = 0;

					if (countInfo[u.path]) {
						pendingCount = countInfo[u.path].pending;
						totalCount = countInfo[u.path].total;
					}

					element.append('<button class="statusAll ui-btn ui-block-a' + (!totalCount ? ' noDefects' : '') + '">' +
						             '<span class="ui-btn-icon-notext ui-icon-arrow-r"></span>' +
						             '<span class="count">' +
							             '<span class="pending">' + pendingCount + '</span>' +
							             '<span class="slash">/</span>' +
							             '<span class="total">' + totalCount + '</span>' +
						             '</span>' +
					             '</button>');

					if (u.children) {
						element = $('<a class="ui-block-b ui-btn ui-btn-inline ui-icon-carat-r ui-btn-icon-right" href="javascript:void(0)">')
							.on('click', _.bind(function () {
								this.setUnit(u.path);
							}, this))
							.appendTo(element);
					} else {
						element = $('<a class="ui-block-b ui-btn ui-btn-inline ui-icon-bullets ui-btn-icon-right">')
							.on('vclick', _.bind(function () {
								currentDefects.resetFilter();
								currentDefects.elementFilter.unit = u.path;
								window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defects');
							}, this))
							.appendTo(element);
					}
					element.text(u.name);
				}, this);
				this.units.show().listview('refresh');
			} else {
				this.units.hide();
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defects');
			}
		},

		countDefectsOf: function (units) {
			var countInfo = {};

			currentDefects.each(function (defect) {
				_.each(_.compact(_.values(units)), function (unit) {
					if (defect.belongsTo(unit.path)) {
						if (!countInfo[unit.path]) {
							countInfo[unit.path] = {
								total:   0,
								pending: 0
							};
						}

						countInfo[unit.path].total++;
						if (defect.isStatusPending()) {
							countInfo[unit.path].pending++;
						}
					}
				}, this);
			}, this);

			return countInfo;
		},

		toParent: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.setUnit(this.parentPath);
		},

		nextPage: function (e) {
			e.preventDefault();
			e.stopPropagation();
			currentDefects.resetFilter();
			currentDefects.elementFilter.unit = this.path;
			window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defects');
		}

	});
});