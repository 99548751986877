import PageContainer from "../components/PageContainer";
import {HelpSearchField, PageCreator, ReadMe, StyledButton} from "@bau/material";
import {translate} from "../utils/utils";
import {useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "../store/hooks";
import {CommonStoreStateKeys} from "../store/slices/commonStoreSlice";

const Help = () => {

   const currentCustomer = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentCustomer]);
   const {helpTextId} = useParams();

   const navigate = useNavigate();

   const goToHotline = () => {
      navigate("/hotline", {state:{helpTextId: helpTextId}});
   }

   const goBack = () => {
      navigate("/help");
   }

   const onClickHelpTextItem = (data:{group:string, options:string} | null) => {
      if(!!data) {
         const startIndex = data.options.indexOf(":");
         const trimmedValue = startIndex !== -1 ? data.options.substring(0, startIndex).trim() : data;
         navigate(`/help/${trimmedValue}`);
      }
   }

   return <PageContainer newPage={true} headerText={translate("web.help")}
                         left={helpTextId && <StyledButton onClick={goBack}>{translate("back")}</StyledButton>}
                         right={<StyledButton onClick={goToHotline}>{translate("web.hotline")}</StyledButton>}>
       <HelpSearchField onChange={onClickHelpTextItem}
                        helpTextId={helpTextId}
                        placeholder={translate("web.search")}
                        helpString={translate("web.help")}/>
      {currentCustomer && !helpTextId && <ReadMe customerId={currentCustomer} withoutDataString={translate("web.help.nodatafound")}/>}
      {currentCustomer && helpTextId && <PageCreator helpTextId={helpTextId}/>}
   </PageContainer>
}

export default Help;