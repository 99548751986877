import PageContainer from "../components/PageContainer";
import {translate} from "../utils/utils";

const News = () => {

    return (
        <PageContainer newPage={true} headerText={translate("sidepanel.whatsnew")}>
            <iframe style={{height: "calc(100vh - 114px)", width: "100%"}}
                    src={"https://openexperience.de/de/new-features.html"}>
            </iframe>
        </PageContainer>
    )
}

export default News;