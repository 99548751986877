import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {useLocation, useNavigate} from "react-router-dom";
import {getCustomerProjectWithPrefix, translate} from "../utils/utils";


const Documents = () => {

    const navigate = useNavigate();
    const {state} = useLocation();
    const {customerProjectWithInspection} = getCustomerProjectWithPrefix();

    useEffect(()=>{
        if(!state || !state.noUseEffect ) {
            // @ts-ignore
            window.documentsUseEffect();
        }

        // @ts-ignore
        if(!!state && !!state.callback && !! window.showPageCallback ) {
            // @ts-ignore
            window.showPageCallback()
            // @ts-ignore
            window.showPageCallback = null;
        }
    },[])


    return <PageContainer newPage={false} headerText={translate("documents.title")}
                          left={<StyledButton className='previousPage'>{translate("back")}</StyledButton>}
    />
}

export default Documents;