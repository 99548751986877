define([
    '../lib/backbone-1.1.2',
    '../helper/iosapp', '../lib/lodash-2.4.1.compat'
], function (
    Backbone,
    iosapp, _
) {
    var ProxyInfoClass = (Backbone.Model.extend({

		defaults: function () {
			return {
                lastCustomer: null,
                proxyMode: false,
                //javascriptVersion: 'someJavascriptVersion',
			}
		},


        url:  '/onlineBauabnahme/api/proxyConfig',

        wasFetched: false,

		sync: function (method, model, options) {
		    var deferred = new Backbone.$.Deferred();
		    deferred.always(function() {
		        this.wasFetchCalled = true;
		    }.bind(this));

            //for testing
            /*
		    this.set('proxyMode', true);
		    this.set('lastCustomer', 'ox');
		    deferred.resolve();
		    return deferred;
		    */

            // console.log("proxyConfig request");
			if (method == "read") {
				var success = options.success;
                // console.log("proxyConfig request 2");
				iosapp.proxyConfig(null, function(data) {
				    // console.log("my data:", data);
				    if (data) {
				        var json = JSON.parse(data);
				        if (success) {
                            success.call(this, json || {})
                        }
                        // console.log('proxyInfo: resolving...');
                        deferred.resolve();
                        // console.log('proxyInfo: resolved');
				    } else {
				        if (options.error) {
				            options.error("failed to fetch");
				        }
                        deferred.reject();
				    }
				}.bind(this));
			} else {
                deferred.reject();
			}

			return deferred;
		},

        syncAddHeaders: function (options) {

            options = options || {};
            if (true || proxyInfo.get('needProxy')) {
                //options.url = 'someProxy'; //TODO
                options.beforeSend = function (xhr) {
                    var customer = proxyInfo.get('customer');
                    var javascriptVersion = proxyInfo.get('javascriptVersion');
                    xhr.setRequestHeader('X-OA-Customer', customer);
                    xhr.setRequestHeader('X-OA-Version', javascriptVersion);
                    xhr.setRequestHeader('X-OA-ProxyCache', !!this.get('proxyMode'));
                }.bind(this);
            }

            return options;
        }

    }));

    var proxyInfo = new ProxyInfoClass();
    return proxyInfo;
})