define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" data-position=\"fixed\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "    <div style=\"display: flex; flex-direction: column\">\n" +
        "        <div style=\"display: flex; flex-direction: row\">\n" +
        "            <div class=\"ui-block-a ui-corner-all\">\n" +
        "                    <div id=\"locationFilter\" data-mini=\"true\" data-role=\"controlgroup\" data-type=\"horizontal\"></div>\n" +
        "            </div>\n" +
        "\n" +
        "            <div class=\"ui-block-a ui-corner-all\" data-icon=\"false\">\n" +
        "                <input id=\"subcontractorFilter\" type=\"text\"  data-icon=\"false\" data-wrapper-class=\"controlgroup-textinput ui-btn\" placeholder=\"<%- t('defects.filter.anysubcontractor') %>\" data-filter=\"subcontractor\"/>\n" +
        "            </div>\n" +
        "            <div style=\"margin: 15px;\">\n" +
        "                <button class=\"help ui-block-b ui-btn ui-btn-a ui-icon-info ui-btn-icon-right ui-btn-icon-notext\" style=\"background-color: rgb(238, 238, 238); border: none;\">...</button>\n" +
        "            </div>\n" +
        "\n" +
        "        </div>\n" +
        "\n" +
        "        <div style=\"overflow-x:auto; margin: 10px\">\n" +
        "            <table class=\"warrantyDataOverviewTable\" style=\"margin: 0px; overflow: scroll; white-space: nowrap; display: table;\n" +
        "    border-collapse: collapse;\n" +
        "    border-spacing: 2px;\"></table>\n" +
        "\n" +
        "        </div>\n" +
        "        <div style=\"margin: 10px\">\n" +
        "            <button class=\"addToSelected ui-btn ui-btn-inline ui-corner-all\"><%= t('warrantyDataOverview.addWarrantyToSelected') %></button>\n" +
        "        </div>\n" +
        "\n" +
        "\n" +
        "    </div>\n" +
        "\n" +
        "    <div data-role=\"popup\" class=\"helpPopup\" style=\"width: 50%;\n" +
        "    left: 27%;\n" +
        "    padding: 11px;\n" +
        "    border: 1px solid black;\">\n" +
        "        <div><%= t('warrantyDataOverview.help') %></div>\n" +
        "    </div>\n" +
        "\n" +
        "\n" +
        "    <div data-role=\"popup\" class=\"addWarrantyData\">\n" +
        "\n" +
        "        <h3 class=\"location\"></h3>\n" +
        "\n" +
        "        <div class=\"subcontractorChooser\" style=\"margin: 10px\">\n" +
        "            <div><%= t('warrantyDataOverview.subcontractor') %></div>\n" +
        "            <select id=\"subcontractor-picker\" class=\"subcontractor-picker\" data-wrapper-class=\"controlgroup-textinput ui-btn\">\n" +
        "            </select>\n" +
        "        </div>\n" +
        "\n" +
        "        <div class=\"fromChooser\" style=\"margin: 10px\">\n" +
        "            <div><%= t('warrantyDataOverview.from') %></div>\n" +
        "            <input id=\"from-picker\" type=\"date\" class=\"from\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "        </div>\n" +
        "\n" +
        "        <div class=\"toChooser\" style=\"margin: 10px\">\n" +
        "            <div><%= t('warrantyDataOverview.to') %></div>\n" +
        "            <input id=\"to-picker\" type=\"date\" class=\"to\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "        </div>\n" +
        "\n" +
        "        <div style=\"display: flex; flex-direction: row\">\n" +
        "            <div class=\"isPersonalChooser\" style=\"margin: 10px 10px 25px;\">\n" +
        "                <div><%= t('warrantyDataOverview.isPersonalContribution') %></div>\n" +
        "                <input id=\"isPersonal-picker\" type=\"checkbox\" class=\"isPersonal\" style=\"margin: -4px 10px;\"/>\n" +
        "            </div>\n" +
        "\n" +
        "            <!--\n" +
        "            <div class=\"isNew\" style=\"margin: 10px 10px 25px;\">\n" +
        "                <div><%= t('warrantyDataOverview.shouldBeNew') %></div>\n" +
        "                <input id=\"isNew-Picker\" type=\"checkbox\" class=\"isPersonal\" style=\"margin: -4px 10px;\"/>\n" +
        "            </div>\n" +
        "            -->\n" +
        "        </div>\n" +
        "\n" +
        "        <div class=\"buttons\" style=\"display: flex; flex-direction: row\" style=\"margin: 10px\">\n" +
        "                <button class=\"cancel\" style=\"margin: 10px;\"><%= t('warrantyDataOverview.cancel') %></button>\n" +
        "                <button class=\"send\" style=\"margin: 10px;\"><%= t('warrantyDataOverview.save') %></button>\n" +
        "            </div>\n" +
        "    </div>\n" +
        "    </div>\n" +
        "</div>\n";
    return page;
});