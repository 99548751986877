define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id='paperwork-page-header' data-position=\"fixed\">\n" +
        "</div>\n" +
        "\t<div data-role=\"popup\" class=\"actionsMenu\">\n" +
        "\t\t<ul data-role=\"listview\" data-inset=\"true\">\n" +
        "\t\t\t<li><a class=\"editEmail\"><%= t('paperwork.editemail') %></a></li>\n" +
        "\t\t\t<li><a class=\"selectAll\"><%= t('paperwork.selectall') %></a></li>\n" +
        "\t\t</ul>\n" +
        "\t</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\t<ul data-role=\"listview\" data-inset=\"true\" class=\"list checkboxList ui-corner-all\"></ul>\n" +
        "\n" +
        "\t<div class=\"buttonContainer\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t<button class=\"sendPaper ui-btn-active\"><%= t('paperwork.sendpaper') %></button>\n" +
        "\t\t<button class=\"sendEmail ui-btn-active\"><%= t('paperwork.sendemail') %></button>\n" +
        "\t</div>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='paperwork-page-footer' data-position=\"fixed\"></div>";
    return page;
});