// Copyright 2014, Open Experience GmbH. All rights reserved.

define([
    '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './BuyerAccessHtml', '../model/user', '../model/currentProject', '../model/currentInspection', '../model/buyerAccesses', '../model/BuyerAccess', '../model/buyerRoles', '../model/BuyerRole', '../model/ProjectFile',
    '../view/Popup', '../helper/backboneReactCommunicationUtils'
], function ($, _, Backbone, Template, user, currentProject, currentInspection, buyerAccesses, BuyerAccess, buyerRoles, BuyerRole, ProjectFile,
             Popup, backboneReactCommunicationUtils) {
    'use strict';

    return Backbone.View.extend({

        id: 'buyeraccess',

        attributes: {
            'data-role': 'page'
        },

        events: {
            'change input[name="accessTable-cb-all"]': 'accessSelectAll',
            'change .accesses-filter-role': 'accessFilterRoleChanged',
            'change .accesses-filter-access': 'accessFilterLocationChanged',
            'vclick .access-create-qr-codes': 'createQrCodes',
            'vclick .access-remove-selected': 'accessesRemoveSelected',
            'vclick .newAccess-create': 'createAccess',
            'vclick .access-toRoles': 'toRoleView',
            'vclick .documents-toroot': 'documentsToRoot',
            'vclick .documents-torole': 'documentsToRole',
            'vclick .documents-toaccess': 'documentsToAccess',
            'vclick .documents-preview': 'documentsPreview',
            'vclick .link-mailto': 'linkMailtoClicked'
        },

        template: _.template(Template),
        initialize: function (options) {
            this.router = options.router;

            this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);

            this.$sectionAccesses = this.$('.section-existing-accesses'); //FIXME: do rename the css class renaming
            this.$accessesExisting = this.$('.accesses-existing');
            this.$accessesNoExisting = this.$('.accesses-noexisting');
            this.$accessFilterRoleContainer = this.$('.accesses-filter-role-container');
            this.accessFilterRole = null;
            this.$accessFilterLocationContainer = this.$('.accesses-filter-location-container');
            this.accessFilterLocation = null;

            this.$accessTable = this.$('.accessTable');
            this.$accessSelectAll = this.$('input[name="accessTable-cb-all"]');
            this.$accessesTableNoExistingFilter = this.$('.accessesTable-noexistingfilter');

            this.$newAccess = this.$('.newAccess');
            this.$newAccessLocation = this.$('.newAccess-location');
            this.$newAccessRole = this.$('.newAccess-role');
            this.$newAccessComment = this.$('.newAccess-comment');

            this.$mustConfigureRoles = this.$('.access-mustConfigureRoles');
            this.$documentsRoleSelect = this.$('.documents-torole-select');

            this.$documents = this.$('.documents');

            this.$qrCodeDialog = this.$('.qrCodeDialog');
        },

        render: function () {
            console.log('buyeracess: render: buyerroles', buyerRoles.length);
            var noRoles = !buyerRoles.length;
            this.$mustConfigureRoles.toggle(noRoles);
            this.$sectionAccesses.toggle(!noRoles);
            this.$documents.toggle(!noRoles);
            console.log('noRoles', noRoles);

            this.renderAccessFilter();
            this.renderAccess();
            this.renderNewAccess();
            this.renderDocumentRoleSelect();
        },

        renderAccessFilter: function() {
            this.$accessFilterRoleContainer.empty();
            var $roleSelect = $('<select>').addClass('accesses-filter-role');
            $roleSelect.append($('<option>').text('- alle -').val(''));
            _.each(buyerRoles.sortBy(function(i) { return i.get('name').toLowerCase(); }), function(role) {
                if (!role.id) {
                    return;
                }
                $('<option>')
                    .text(role.get('name'))
                    .val(role.id)
                    .appendTo($roleSelect);
            }.bind(this));

            this.$accessFilterRoleContainer.append($roleSelect);
            $roleSelect.selectmenu().val(this.accessFilterRole || '');
            $roleSelect.selectmenu('refresh');

            //location

            this.$accessFilterLocationContainer.empty();
            var $locationSelect = $('<select>').addClass('accesses-filter-access');

            var units = [];
            for (var depth = 1; depth <= (currentProject.get('contactIndex') || 0); depth++) {
                units = units.concat(this.getUnitsOfDepth(currentProject.get('unitTree') || {}, depth));
            }

            $locationSelect.append($('<option>').text('- alle -').val(''));
            _.each(_.sortBy(units, function(i) { return i.title; }), function(unit) {
                $('<option>')
                    .text(unit.title)
                    .val(unit.path)
                    .appendTo($locationSelect);
            }.bind(this));
            this.$accessFilterLocationContainer.append($locationSelect);
            $locationSelect.selectmenu().val(this.accessFilterLocation || '');
            $locationSelect.selectmenu('refresh');
        },

        renderAccess: function() {

            var noAccesses = (buyerAccesses.length === 0);
            this.$accessesExisting.toggle(!noAccesses);
            this.$accessesNoExisting.toggle(noAccesses);

            var roleMap = {};
            buyerRoles.each(function(i) {
                roleMap[i.id] = i.get('name');
            });

            var accesses = buyerAccesses.sortBy(function(i) { return roleMap[i.get('roleId')] + '-' + i.get('locationId'); });
            if (this.accessFilterRole) {
                accesses = _.filter(accesses, function(i) { return i.get('roleId') === this.accessFilterRole; }.bind(this));
            }
            if (this.accessFilterLocation) {
                accesses = _.filter(accesses, function(i) { return i.get('locationId') === this.accessFilterLocation || (i.get('locationId') || '').indexOf(this.accessFilterLocation + '-') === 0; }.bind(this));
            }

            var noAccessesFilter = (accesses.length == 0);
            this.$accessTable.toggle(!noAccessesFilter);
            this.$accessesTableNoExistingFilter.toggle(noAccessesFilter)

            var $body = this.$accessTable.find('tbody');
            $body.empty();
            _.each(accesses, function(i) {
                var $checkbox = $('<input type="checkbox" class="accessTable-cb-item"/>').data('id', i.id);
                $('<tr>')
                    .append($('<td class="column-cb">')
                        .append($checkbox)
                    )
                    .append($('<td class="column-role">')
                        .text(roleMap[i.get('roleId')])
                    )
                    .append($('<td class="column-location">')
                        .text((this.getUnitFromLocationId(i.get('locationId').split('-'), currentProject.get('unitTree') || {}) || {}).title || '<unbekannter Ort>')
                    )
                    .append($('<td class="column-comment">')
                        .text(i.get('comment'))
                    )
                    .append($('<td>')
                        .append($('<button class="button-with-icon button-icon-link">')
                            .click(function() {
                                //TODO: input field with stuff
                                window.linkPopup(i.get('accessLink'));
                            }.bind(this))
                        )
                    )
                    .append($('<td>')
                        .append($('<button class="button-with-icon button-icon-remove">')
                            .click(function() {
                                window.confirmPopup("Sind Sie sicher?").then(function() {
                                    //TODO: show spinner
                                    i.destroy().then(function() {
                                        //TODO: hide spinner
                                        this.renderAccess();
                                    }.bind(this), function() {
                                        //TODO: hide spinner
                                        window.alertPopup("Fehler beim Löschen des Zugangs");
                                    }.bind(this));
                                }.bind(this));
                            }.bind(this))
                        )
                    )
                    .append($('<td>')
                        .append($('<button class="button-with-icon button-icon-documents">')
                            .data('role', i.get('roleId'))
                            .data('location', i.get('locationId'))
                            .addClass('documents-toaccess')
                        )
                    )
                    .append($('<td>')
                        .append($('<button class="button-with-icon button-icon-preview">')
                            .data('role', i.get('roleId'))
                            .data('location', i.get('locationId'))
                            .addClass('documents-preview')
                        )
                    )
                    .append($('<td>')
                        .append($('<button class="button-with-icon button-icon-mail">')
                            .data('link', i.get('accessLink'))
                            .addClass('link-mailto')
                        )
                    )
                    .appendTo($body);
                $checkbox.checkboxradio();
            }.bind(this));
        },

        renderNewAccess: function() {
            this.$newAccessLocation.empty();
            this.$newAccessLocation.append($('<option>'));
            this.$newAccessLocation.append(
                $('<option>')
                    .text('<für alle Orte jeweils einen Zugang erstellen>') //TODO: translations
                    .val('--all')
            );
            _.each(this.getUnitsOfDepth(currentProject.get('unitTree') || {}, currentProject.get('contactIndex') || 0), function(unit) {
                $('<option>')
                    .text(unit.title)
                    .val(unit.path)
                    .appendTo(this.$newAccessLocation);
            }.bind(this));
            this.$newAccessLocation.val();
            this.$newAccessLocation.selectmenu().selectmenu('refresh');

            this.$newAccessRole.empty();
            this.$newAccessRole.append($('<option>'));
            _.each(buyerRoles.sortBy(function(i) { return i.get('name').toLowerCase(); }), function(role) {
                $('<option>')
                    .text(role.get('name'))
                    .val(role.id)
                    .appendTo(this.$newAccessRole);
            }.bind(this));
            this.$newAccessRole.selectmenu().selectmenu('refresh');

            this.$newAccessComment.val('');//this function is also used to reset the fields after creating a new access;
        },

        renderDocumentRoleSelect: function() {
            this.$documentsRoleSelect.empty();
            this.$documentsRoleSelect.append($('<option>'));
            _.each(buyerRoles.sortBy(function(i) { return i.get('name').toLowerCase(); }), function(role) {
                $('<option>')
                    .text(role.get('name'))
                    .val(role.id)
                    .appendTo(this.$documentsRoleSelect);
            }.bind(this));
            this.$documentsRoleSelect.selectmenu().selectmenu('refresh');
        },

        accessFilterRoleChanged: function(e) {
            var value = $(e.target).val();
            this.accessFilterRole = value || null;
            this.renderAccess();
        },

        accessFilterLocationChanged: function(e) {
            var value = $(e.target).val();
            this.accessFilterLocation = value || null;
            this.renderAccess();
        },

        accessSelectAll: function() {
            var newval = !!this.$accessSelectAll.is(':checked');
            this.$('input.accessTable-cb-item').prop("checked", newval).checkboxradio("refresh");
        },

        _getSelected: function(allIfNone) {
            var selected = [];
            var all = [];
            this.$('input.accessTable-cb-item').each(function(idx, el) {
                var $el = $(el);
                all.push($el.data('id'));
                if ($el.is(':checked')) {
                    selected.push($el.data('id'));
                }
            });
            if (selected.length === 0 && allIfNone) {
                selected = all;
            }
            return selected;
        },

        createQrCodes: function() {
            var selected = this._getSelected(true);
            if (selected.length === 0) {
                alertPopup("Keine Zugänge vorhanden. Bitte erstellen Sie neue Zugänge oder passen Sie die Filter an.");
                return;
            }

            $.ajax( '/onlineBauabnahme/api/buyer/admin/' + currentProject.id + '/prepareBuyerAccessCodes', {
                method: 'POST',
                contentType: 'application/json; charset=UTF-8',
                data: JSON.stringify({
                    tokens: selected
                })
            }).then(function(data) {
                if (data.success) {
                    var download = function (separate, line1, line2) {
                       window.open( '/onlineBauabnahme/api/buyer/admin/downloadQrCodes/' + data.downloadToken + '?separate=' + (!!separate) + '&line1=' + encodeURIComponent(line1) + '&line2=' + encodeURIComponent(line2), '_blank');
                    };

                    this.$qrCodeDialog.show().popup('open');
                    var $firstline = this.$qrCodeDialog.find('input.firstline');
                    var $secondline = this.$qrCodeDialog.find('input.secondline');

                    $firstline.val('{role}')
                    $secondline.val('{location}');

                    var $buttons = this.$qrCodeDialog.find('button');
                    $buttons.one('vclick', _.bind(function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        var $ct = $(e.currentTarget)
                        if ($ct.hasClass('one')) {
                            download(false, $firstline.val(), $secondline.val());
                        } else if ($ct.hasClass('separate')) {
                            download(true, $firstline.val(), $secondline.val());
                        }
                        $buttons.off('vclick');
                        this.$qrCodeDialog.popup('close');
                    }, this));
                } else if (data.notAllFound) {

                } else {

                }
            }.bind(this));
        },

        accessesRemoveSelected: function() {
            var selected = this._getSelected(false);
            if (selected.length === 0) {
                alertPopup("Keine Zugänge ausgewählt.");
                return;
            }

            window.confirmPopup("Sie sind dabei, " + selected.length + " Zugänge zu löschen. Sind Sie sicher?").then(function() {
                $.ajax( '/onlineBauabnahme/api/buyer/admin/' + currentProject.id + '/buyerAccess', {
                    method: 'DELETE',
                    contentType: 'application/json; charset=UTF-8',
                    data: JSON.stringify(selected)
                }).then(function () {
                    return buyerAccesses.fetch();
                }.bind(this))
                    .then(function () {
                        this.render();
                    }.bind(this));
            }.bind(this));

        },

        createAccess: function() {
            var location = this.$newAccessLocation.val();
            var role = this.$newAccessRole.val();
            var comment = this.$newAccessComment.val() || '';
            var bulk = (location === '--all');

            if (!location) {
                alertPopup("Bitte wählen Sie einen Ort aus");
                return;
            }
            if (!role) {
                alertPopup("Bitte wählen Sie eine Rolle aus");
                return;
            }

            var buyerAccess = new BuyerAccess();
            buyerAccess.set('project', currentProject.id);
            buyerAccess.set('locationId', location);
            buyerAccess.set('roleId', role);
            buyerAccess.set('comment', comment);

            if (!bulk) {
                buyerAccesses.add(buyerAccess);
            } else {
                buyerAccess.urlRoot = buyerAccesses.url();
            }
            //TODO: show loading thingy
            var deferred = buyerAccess.save();
            window.d1=deferred;
            if (bulk) {
                deferred = deferred.then(function() {
                    return buyerAccesses.fetch();
                }.bind(this));
            }
            window.d2=deferred;
            deferred.then(function() {
                //TODO: hide loading
                this.renderNewAccess(); //for emptying all fields
                this.renderAccess();
            }.bind(this), function() {
                //TODO: hide loading
                alertPopup("Fehler beim Speichern.");
            }.bind(this));
        },

        roleChanged: function() {
            this.currentRole = this.$roleSelect.val();
            this.renderRoleDetails();
        },

        getUnitFromLocationId: function(idArr, unit) {
            if (idArr.length == 0) {
                return unit;
            } else {
                var idPart = idArr[0];
                var newArr = idArr.slice(1);
                var newUnit = (unit.children || {})[idPart];
                if (!newUnit) {
                    return null;
                }
                return this.getUnitFromLocationId(newArr, newUnit);
            }
        },

        getUnitsOfDepth: function(unit, depth) {
            if (depth > 0) {
                return _.flatten(_.map(unit.children || [], function(i) {
                    return this.getUnitsOfDepth(i, depth - 1);
                }.bind(this)));
            } else {
                return unit;
            }
        },

        documentsToRoot: function(e) {
            e.preventDefault();
            e.stopPropagation();
            this.router.filesPage.model = new ProjectFile({
                path: '/_buyer/',
                type: 'Folder'
            });
            this.router.filesPage.model.project = currentProject.id;
            this.router.filesPage.model.load().done(_.bind(function () {
                this.router.triggerFiles(currentProject.id, currentInspection.id, false, true);
            }, this));
        },

        documentsToRole: function(e) {
            e.preventDefault();
            e.stopPropagation();

            var roleId = this.$documentsRoleSelect.val();
            if (!roleId) {
                return;
            }

            this.router.filesPage.model = new ProjectFile({
                path: '/_buyer/_' + roleId + '/',
                type: 'Folder'
            });
            this.router.filesPage.model.project = currentProject.id;
            this.router.filesPage.model.load().done(_.bind(function () {
                this.router.triggerFiles(currentProject.id, currentInspection.id, false, true);
            }, this));
        },

        documentsToAccess: function(e) {
            e.preventDefault();
            e.stopPropagation();

            var $target = $(e.target);
            var roleId = $target.data('role');
            var locationId = $target.data('location');

            this.router.filesPage.model = new ProjectFile({
                path: '/_buyer/_' + roleId + '/_' + locationId + '/',
                type: 'Folder'
            });
            this.router.filesPage.model.project = currentProject.id;
            this.router.filesPage.model.load().done(_.bind(function () {
                this.router.triggerFiles(currentProject.id, currentInspection.id, false, true);
            }, this));
        },

        documentsPreview: function(e) {
            e.preventDefault();
            e.stopPropagation();

            var $target = $(e.target);
            var roleId = $target.data('role');
            var locationId = $target.data('location');

            this.router.filesPage.model = new ProjectFile({
                path: '/_buyer/_preview/_' + roleId + '/_' + locationId + '/',
                type: 'Folder'
            });
            this.router.filesPage.model.project = currentProject.id;
            this.router.filesPage.model.load().done(_.bind(function () {
                this.router.triggerFiles(currentProject.id, currentInspection.id, false, true);
            }, this));
        },

        linkMailtoClicked: function(e) {
            e.preventDefault();
            e.stopPropagation();

            var $target = $(e.target);
            window.open('mailto:?subject=' + encodeURIComponent('Zugang Bau-Mängel') + '&body=' + encodeURIComponent('Sehr geehrte Damen und Herren,\n\nhiermit erhalten Sie Ihren persönlichen Zugang zu Bau-Mängel. Bitte klicken Sie auf untenstehenden Link, um Zugang zum System zu erhalten.\n\n'+ $target.data('link')), '_blank');
        },

        toRoleView: function() {
            window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject()+'/buyerRoles');
        }

    });
});
