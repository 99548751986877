// Copyright 2014, Open Experience GmbH. All rights reserved.

define([
	'../lib/jquery-1.11.0', '../lib/backbone-1.1.2', './HelpArticlePageHtml', '../model/user', '../model/help'
], function ($, Backbone, Template, user, help) {
	"use strict";

	return Backbone.View.extend({

		id: 'help-article',

		attributes: {
			'data-role': 'page'
		},
		
		className: 'textselect',

		events: {
			'vclick .previous': 'previous',
			'vclick .next':     'next'
		},

		template: _.template(Template),

		initialize: function (options) {
			this.router = options.router;
			
			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.$content  = this.$('.content');
			this.$previous = this.$('.previous');
			this.$next     = this.$('.next');
		},

		render: function () {
			this.$content.empty();
			$('<h1>').html(help.hilight(this.model.title1)).appendTo(this.$content);
			$('<h2>').html(help.hilight(this.model.title2)).appendTo(this.$content);
			_.each(this.model.contents, function (paragraph) {
				if ((paragraph.text || '').toLowerCase().indexOf('image:') === 0) {
					var src = paragraph.text.substr(6).trim();
					$('<div class="help-image">').append($('<img>').attr({ src: src })).appendTo(this.$content);
					return;
				}

				var html = help.hilight((paragraph.text + '\n').replace(/(.*?)\n(.*?)/g, '<p>$1</p>'));
				var $div = $('<div>').html(html).appendTo(this.$content);
				if (paragraph.level >= 3) {
					$div.addClass('level3');
				}
			}, this);
			this.$previous.toggle(this.model.previous != -1);
			this.$next.toggle(this.model.next != -1);
		},

		setModel: function (model) {
			this.model = model;
		},

		previous: function (e) {
			e.stopPropagation();
			e.preventDefault();
			this.setModel(help.getParagraph(this.model.previous));
			this.render();
		},

		next: function (e) {
			e.stopPropagation();
			e.preventDefault();
			this.setModel(help.getParagraph(this.model.next));
			this.render();
		}

	});
});