// Copyright 2014, Open Experience GmbH. All rights reserved.

define([
    '../lib/jquery-1.11.0', '../lib/backbone-1.1.2', './WarrantyDataOverviewHtml', '../model/user', '../model/warrantyDatas', '../model/currentProject', '../model/subcontractors', '../model/completions', '../view/Autocomplete', '../lib/moment-2.5.1'
], function ($, Backbone, Template, user, warrantyDatas, currentProject, subcontractors, completions, Autocomplete, moment) {
    'use strict';

    return Backbone.View.extend({

        id: 'warrantyDataOverview',

        attributes: {
            'data-role': 'page'
        },

        events: {
            'vclick .addWarrantyData .buttons .cancel': 'closeAddWarrantyDataPopup',
            'vclick .help': 'showHelpPopup',
            'change #locationFilter select'           : 'filter',
            'change #subcontractorFilter'      : 'filter',
            'focus #subcontractorFilter': function () {
                this.$subcontractorFilter.val('');
            },
            'vclick .addToSelected': function (e) {
                var selectedItems = $('.selectItem:checkbox:checked');
                var locTemp = this.processLoc();
                if(selectedItems.length === 0)
                {
                    return;
                }
                var selectedLocs = [];
                for(var index=0;index<selectedItems.length;index++)
                {
                    var lodId = selectedItems.eq(index).attr('data-id');
                    selectedLocs.push(locTemp[lodId]);
                }
                this.showAddWarrantyData(e, selectedLocs[0],selectedLocs.length > 1);
                this.$('.addWarrantyData .buttons .send').off('vclick');
                this.$('.addWarrantyData .buttons .send').on('vclick', _.bind(function (e) {
                    this.uploadWarrantyData(e, selectedLocs);
                }, this));
            },
            'vclick .selectItem': function(e){
                var selectedItems = $('.selectItem:checkbox:checked');
                var addToSelected = $('.addToSelected');
                if(selectedItems.length>0)
                {
                    addToSelected.show();
                }
                else
                {
                    addToSelected.hide();
                }
            },
        },

        filterObj: {unit: 'null', subcontractor: '*'},

        template: _.template(Template),
        initialize: function (options) {
            this.router = options.router;

            this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);

            this.$addWarrantyData = this.$('.addWarrantyData');
            this.$subcontractorPicker = this.$('.addWarrantyData .subcontractor-picker');
            this.$warrantyDataOverviewTable = this.$('.warrantyDataOverviewTable');
            this.$locationFilter = this.$('#locationFilter');
            this.$subcontractorFilter = this.$('#subcontractorFilter');
            this.$showHelpPopup = this.$('.helpPopup');

            new Autocomplete({
                input: this.$subcontractorFilter,
                leftOffset: 8.0,
                rightOffset: 8.0,
                autowidth: true,
                lookup: function (str) {
                    var result = [];
                    if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
                        result.push(user.translate('defects.filter.anycrew'));
                        result.push(user.translate('defects.filter.onlyEntries'));
                        Array.prototype.push.apply(result, currentProject.get('crews'));
                    } else {
                        result.push(user.translate('defects.filter.anysubcontractor'));
                        result.push(user.translate('defects.filter.onlyEntries'));
                        Array.prototype.push.apply(result, completions.subcontractorLookup(str));
                    }
                    return result;
                },
                toggleButton: true
            }).render();
            $('.addToSelected').hide();
        },

        render: function () {
            this.refreshLocation();
            this.refreshSubcontractorFilterList(this.filterObj.subcontractor);
            this.filter();
            this.refreshList();
        },

        fillSubcontractorSelect: function (select) {
            var subcontractorOpts = '';
            for (var i = 0; i < subcontractors.length; i++) {
                subcontractorOpts += '<option value="' + subcontractors.at(i).get('id') + '">' + subcontractors.at(i).get('crew') + ' ' + subcontractors.at(i).get('name') + '</option>';
            }
            select.html(subcontractorOpts);
        },

        processLoc: function()
        {
            var locations = this.dfs(this.getDepth(), currentProject.get('unitTree').children, [], this.filterObj.unit.split('-'));
            if (this.filterObj.unit === '' || this.filterObj.unit === 'null')
            {
                locations.unshift({title: user.translate('warrantyData.locationRoot'), path: 'root'});
            }
            var doubledEntries = [];
            for (var i = 0; i < warrantyDatas.length; i++) {
                _.each(locations, function(locElement, j) {
                    if (locElement.path === warrantyDatas.at(i).get('loc_id') || (locElement && locElement.path === 'root' && warrantyDatas.at(i).get('loc_id') === '')) {
                        if (!locElement.warrantyData) {
                            locElement.warrantyData = warrantyDatas.at(i);
                        } else if (!locElement.added) {
                            var newValue = _.extend({}, locElement, { warrantyData: warrantyDatas.at(i) });
                            doubledEntries.push({indexLocation: j, value: newValue });
                        }
                    }
                }.bind(this));
            }
            i = 0;
            _.each(doubledEntries, function (entry) {
                locations.splice(entry.indexLocation + i, 0 , entry.value);
                i++;
            });
            if (this.filterObj.subcontractor) {
                locations = _.filter(locations, _.bind(function (locElement) {
                    if (this.filterObj.subcontractor === '*') return !!locElement.warrantyData;
                    return locElement.warrantyData && locElement.warrantyData.get('subcontractor') === this.filterObj.subcontractor;
                }, this));
            }
            return locations;
        },

        renderList: function (){
            this.$warrantyDataOverviewTable.html('');

            var loc = this.processLoc();

            this.$warrantyDataOverviewTable.append('<td></td><td style=" padding: 5px;">' + user.translate('warrantyDataOverview.location') + '</td>'
                + '<td>' + user.translate('warrantyDataOverview.subcontractor') + '</td>'
                + '<td>' + user.translate('warrantyDataOverview.from') + '</td>'
                + '<td>' + user.translate('warrantyDataOverview.to') + '</td>'
                + '<td>' + user.translate('warrantyDataOverview.isPersonalContribution') + '</td>'
                + '<td></td>');

            _.each(loc, function(locElement, i) {
                var color = i % 2 === 0 ? 'white' : '#f1f1f1';
                var content = this.warrantyDataToString(locElement.warrantyData, i);
                var $item = $('<tr class="warrantyDataDiv_' + i + '" style="margin-bottom: 8px; text-align: left; background-color:' + color + '">').appendTo(this.$warrantyDataOverviewTable);
                var $checkboxPlace = $('<td>');
                var $checkbox = $('<input type="checkbox" class="selectItem ui-btn ui-block-a" style="margin-left: 10px">').attr('data-id', i);
                var $location = $('<td style=" padding: 5px;">' + locElement.title + ': ' + '</td>');
                var $content = $(content);
                $checkboxPlace.appendTo($item);
                // $checkbox.click(function(){
                //     console.log(i);
                // }.bind(this));
                $checkbox.appendTo($checkboxPlace);
                $location.appendTo($item);
                $content.appendTo($item);

                this.$('.warrantyDataDiv_' + i + ' .delete').on('vclick', _.bind(function (e) {
                    $.ajax({
                        url:  '/onlineBauabnahme/api/warrantyData/' + locElement.warrantyData.get('id'),
                        type: 'DELETE',
                        contentType: 'application/json'
                    }).then(_.bind(function (){this.render();}, this));
                }, this));
                this.$('.warrantyDataDiv_' + i + ' .edit').on('vclick', _.bind(function (e) {
                    this.showAddWarrantyData(e, locElement,false);
                    this.$('.addWarrantyData .buttons .send').off('vclick');
                    this.$('.addWarrantyData .buttons .send').on('vclick', _.bind(function (e) {
                        this.uploadWarrantyData(e, [locElement]);
                    }, this));
                }, this));
                /**
                this.$warrantyDataOverviewTable.append('<tr class="warrantyDataDiv_' + i + '" style="margin-bottom: 8px; text-align: left; background-color:' + color + '">' +
                    $item + '<td style=" padding: 5px;">' + locElement.title + ": " + '</td>' + content + '</tr>');
                this.$('.warrantyDataDiv_' + i + ' .delete').on('vclick', _.bind(function (e) {
                    $.ajax({
                        url:  '/api/warrantyData/' + locElement.warrantyData.get('id'),
                        type: 'DELETE',
                        contentType: 'application/json'
                    }).then(_.bind(function (){this.render();}, this));
                }, this));
                this.$('.warrantyDataDiv_' + i + ' .edit').on('vclick', _.bind(function (e) {
                    this.showAddWarrantyData(e, locElement);
                    this.$('.addWarrantyData .buttons .send').off('vclick');
                    this.$('.addWarrantyData .buttons .send').on('vclick', _.bind(function (e) {
                        this.uploadWarrantyData(e, locElement.warrantyData, locElement.title.split(' > '));
                    }, this));
                }, this));
                **/
            }.bind(this));
            //this.$warrantyDataOverviewTable.append('');

        },

        getDepth: function () {
            var l = this.filterObj.unit.split('-').length;
            l = l ? l : 0;
            return (l === 1 && this.filterObj.unit === '') ? 0 : l;
        },

        closeAddWarrantyDataPopup: function (e) {
            this.$addWarrantyData.popup({afterclose: null});
            this.$addWarrantyData.popup('close');
        },

        showAddWarrantyData: function (e, locElement, multipleEdit) {
            e.preventDefault();
            e.stopPropagation();
            this.fillWarrantyData(locElement, multipleEdit);
            this.$addWarrantyData.popup('open');
        },

        nullifyAttributes: function (attribute) {
            return user.translate('person.notGiven') === attribute ? null : attribute;
        },

        uploadWarrantyData: function (e, warrantyDataToPush) {
            e.preventDefault();
            e.stopPropagation();

            this.$pickFrom = this.$('#from-picker').parents().eq(0);
            this.$pickTo = this.$('#to-picker').parents().eq(0);
            var dataToPush = [];
            for(var i=0;i<warrantyDataToPush.length;i++)
            {
                var location = warrantyDataToPush[i].title.split(' > ');
                if (location && location.length === 1 && location[0] === user.translate('warrantyData.locationRoot'))
                {
                    location = [];
                }
                var data = {
                    subcontractor: this.$subcontractorPicker.val(),
                    project: currentProject.get('id'),
                    customer: user.get('customer'),
                    location: location,
                    from: new Date(this.$('.fromChooser').find('input[type=date]').val()).toISOString().split('T')[0],
                    to: new Date(this.$('.toChooser').find('input[type=date]').val()).toISOString().split('T')[0],
                    isPersonalContribution: this.$('.isPersonalChooser').find('input[type=checkbox]').is(':checked'),
                    //id: this.$('.isNew').find('input[type=checkbox]').is(':checked') ? '-1' : warrantyData && warrantyData.get('id') || '-1'
                    id: warrantyDataToPush[i].warrantyData == null ? '-1' : warrantyDataToPush[i].warrantyData.id
                };
                dataToPush.push(data);
            }
            this.closeAddWarrantyDataPopup();
            var url =  '/onlineBauabnahme/api/warrantyData';
            var ajaxParam = {
                    url: url,
                    type: 'POST',
                    data: JSON.stringify(dataToPush),
                    contentType: 'application/json'
                };
            $.ajax(ajaxParam)
            .then(null, function(xhr)
            {
                if (xhr && xhr.status == 400 && xhr.responseJSON && xhr.responseJSON.errorid == "ENTRY_EXISTS") {
                    return yesNoCancelPopup(user.translate('defect.warrantyData.send.entryExists'),'warrantyData.popup.no','warrantyData.popup.yes')
                    .then(_.bind(function () {
                        ajaxParam.url = url + '?force=true';
                        return $.ajax(ajaxParam);
                    }, this), function (clicked) {
                        if(clicked==='no')
                        {
                            ajaxParam.url = url + '?force=true&create=true';
                            return $.ajax(ajaxParam);
                        }
                        else
                        {
                            return $.Deferred().reject('ignore');
                        }
                    }.bind(this));
                } else {
                    return $.Deferred().reject.call(this, arguments);
                }
            }.bind(this))
            .then(function (result)
            {
                console.log(result);
                warrantyDatas.setSubcontractorAndLocation(this.filterObj.subcontractor || '*', currentProject.get('id'), this.filterObj.unit || '*', true)
                    .then(_.bind(function (e) {
                        this.render();
                    }, this))
                    .fail(function (e) {
                        alertPopup(user.translate('defect.warrantyData.fetch.fail'));
                    });
            }.bind(this))
            .fail(function (x)
            {
                if (x !== 'ignore') {
                    alertPopup(user.translate('defect.warrantyData.send.fail'));
                }
            }.bind(this));

        },


        fillWarrantyData: function (locElement, multipleEdit) {
            var warrantyData = locElement && locElement.warrantyData;
            var title = multipleEdit ? user.translate('warrantyDataOverview.multipleElementsSelected') : locElement && locElement.title;

            this.fillSubcontractorSelect(this.$subcontractorPicker);

            this.$('.location').text(title);
            this.$('.fromChooser').find('input[type=date]').val(warrantyData ? warrantyData.get('from') : moment(new Date()).format("YYYY-MM-DD"));
            this.$('.toChooser').find('input[type=date]').val(warrantyData ? warrantyData.get('to') : moment(new Date()).format("YYYY-MM-DD"));
            this.$subcontractorPicker.val(warrantyData && warrantyData.get('subcontractor') || '').change();
            this.$('.isPersonal').find('input[type=checkbox]').val(warrantyData && warrantyData.get('isPersonalContribution') === 'true' || false);
        },

        dfs: function(depth, tree, arr, unitArr) {
            var isDeepSearch = _.last(unitArr) === 'null';
            return this.dfsDeep(depth, tree, arr, isDeepSearch ? _.initial(unitArr) : unitArr, 0, isDeepSearch);
        },

        dfsDeep: function(depth, tree, arr, unitArr, treeDepth, isDeepSearch) {
            _.each(_.values(tree), function(treeElement) {

                var fillCond = (depth <= 1 && isDeepSearch) || (depth === 0 && !isDeepSearch) || (depth === 1 && !isDeepSearch);
                var breakCond = _.last(treeElement.path.split('-')) !== unitArr[treeDepth];
                var addBreakCond = treeDepth < unitArr.length + 1;
                var added = false;

                if (fillCond && breakCond && (treeDepth === unitArr.length || (unitArr.length === 1 && unitArr[0] === ""))) {
                    added = true;
                    arr.push({title: treeElement.title, path: treeElement.path});
                }

                if (isDeepSearch && unitArr[treeDepth] && breakCond && addBreakCond) return;
                if (!isDeepSearch && breakCond && addBreakCond) return;

                if (fillCond && !added)
                    arr.push({title: treeElement.title, path: treeElement.path});

                if (treeElement.children)
                    arr = this.dfsDeep(depth - 1, treeElement.children, arr, unitArr, treeDepth + 1, isDeepSearch);
            }.bind(this));
            return arr;
        },


        warrantyDataToString: function (warrantyData, i) {
            if (!warrantyData) return '<td></td><td></td><td></td><td></td><td><button class="edit ui-block-b ui-btn ui-btn-a ui-icon-plus ui-btn-icon-right ui-btn-icon-notext">...</button></td>';
            var subcontractor = warrantyData.has('subcontractor') ? subcontractors.findWhere({id: warrantyData.get('subcontractor')}) : null;
            var warrantyDataString = '<td style="font-weight: bold;" class="edit">' + (subcontractor ? (subcontractor.get('crew') + ', ' + subcontractor.get('name')) + ' ' : '') + '</td>';
            warrantyDataString = warrantyDataString.concat('<td style="padding: 0 15px;" class="edit">' + (warrantyData && warrantyData.has('from') ? (warrantyData.get('from')) : '') + '</td>' + '<td>' + (warrantyData && warrantyData.has('to') ? (warrantyData.get('to')) : '') + '</td>')

            warrantyDataString = warrantyDataString.concat('<td style="text-align: end;" class="edit">' + (warrantyData ? (!!warrantyData.get('isPersonalContribution') ? user.translate('warrantyDataOverview.yes') : user.translate('warrantyDataOverview.no')) : '') + '</td>');

            warrantyDataString = warrantyDataString.concat('<td><button class="edit ui-block-b ui-btn ui-btn-a ui-icon-plus ui-btn-icon-right ui-btn-icon-notext">...</button></td>')
            warrantyDataString = warrantyDataString.concat('<td><button class="delete ui-block-b ui-btn ui-btn-a ui-icon-delete ui-btn-icon-left ui-btn-icon-notext">...</button></td>')

            return warrantyDataString
        },


        filter: function (e) {
            var $target = e && $(e.target);

            var subcontractorVal = this.$subcontractorFilter.val();
            if (subcontractorVal === user.translate('defects.filter.anysubcontractor') || subcontractorVal === user.translate('defects.filter.anycrew')) {
                this.$subcontractorFilter.val('');
            }

            var newFilter =  {
                unit:           ($target && $target.parents('#locationFilter').length > 0) ? $target.val() : this.filterObj.unit,
                subcontractor:  this.subcontractorId(subcontractorVal),
            };

            this.refreshSubcontractorFilterList(newFilter.subcontractor);

            if (newFilter.unit !== null && newFilter.unit.indexOf('*') !== -1 && user.get('settings').altLocationFilter) {
                var p = newFilter.unit.split('-');
                while (p.length > 1 && p[p.length - 1] == '*' && p[p.length - 2] == '*') { //remove trailing asterisks
                    p.pop();
                }
                newFilter.regexpUnit = new RegExp('^' + p.join('-').replace(/\*/g, '[^\\-]*'));
            }

            if (!_.isEqual(newFilter, this.filterObj)) {
                _.extend(this.filterObj, newFilter);
                this.refreshList();
            }
            this.refreshLocation();
        },

        refreshList: function () {
            warrantyDatas.setSubcontractorAndLocation(this.filterObj.subcontractor || '*', currentProject.get('id'), this.filterObj.unit || '*', true).then(_.bind(function () {
                this.renderList()
            }, this))
        },

        refreshLocation: function () {
            var i;

            var $container = this.$locationFilter.controlgroup('container');
            if (currentProject.isNew()) {
                $container.empty();
            } else {
                var unit = currentProject.get('unitTree');
                var parts = (this.filterObj.unit || '').split('-');

                var choices = [];


                for (i = 0; i <= parts.length && unit.children; i++) {
                    var choice = $('<select>');
                    $('<option>').text(currentProject.getStructureKey(i)).val(unit.path).appendTo(choice);
                    if (i > 0) {
                        $('<option>').text(user.translate('warrantyData.showAllFromHere')).val("" + unit.path + "-null").appendTo(choice);
                    } else if (i === 0) {
                        $('<option>').text(user.translate('warrantyData.showAllFromHere')).val("null").appendTo(choice);
                    }

                    _.each(_.compact(_.values(unit.children)), function (child) {
                        if (child) {
                            $('<option>')
                                .text(child.name)
                                .val(child.path)
                                .appendTo(choice);
                        }
                    }, this);
                    choices.push(choice);
                    if (i < parts.length && parts[i]) {
                        if (parts[i] == "null"){
                            if (unit.path) {
                                choice.val("" + unit.path + "-null");
                            } else {
                                choice.val("null");
                            }
                            break;
                        };
                        unit = unit.children[parts[i]];
                        if (unit) {
                            choice.val(unit.path);
                        } else {
                            break;
                        }
                    } else {
                        break;
                    }
                }
            }

            this.$locationFilter.find('select').remove();
            for (i = choices.length - 1; i >= 0; i--) {
                choices[i]
                    .prependTo($container)
                    .selectmenu();
            }

            this.$locationFilter.controlgroup('refresh');

            this.resizeLocationInput();
        },

        resizeLocationInput: function () {
            //var availWidth = this.$locationFilter.width();
            var availWidth = 100;
            var locationChoice = this.$locationFilter.find('.ui-select, button');
            locationChoice.each(function (index) {
                var optionSize = (availWidth / locationChoice.length);//+ (index === locationChoice.length - 1 ? -1 : 0));
                $(this).css('width', (optionSize > 100 ? 100 : optionSize) + "%");
                $(this).children().css({'padding': '12px', 'font-size': '15px'})
            });
        },

        refreshSubcontractorFilterList: function (value) {
            if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
                if (value === null) {
                    this.$subcontractorFilter.val(user.translate('defects.filter.anycrew'));
                } else if (value === '*') {
                    this.$subcontractorFilter.val(user.translate('defects.filter.onlyEntries'))
                } else {
                    this.$subcontractorFilter.val(value);
                }
            } else {
                if (value === null) {
                    this.$subcontractorFilter.val(user.translate('defects.filter.anysubcontractor'));
                } else if (value === '*') {
                    this.$subcontractorFilter.val(user.translate('defects.filter.onlyEntries'))
                }  else {
                    var subcontractor = (value ? subcontractors.findWhere({ id: value }) : null);
                    this.$subcontractorFilter.val(subcontractor ? subcontractor.getLabel() : value);
                }
            }
        },

        subcontractorId: function (val) {
            if (!val || val === user.translate('defects.filter.anysubcontractor') || val === user.translate('defects.filter.anycrew')) {
                return null;
            }
            if (val === user.translate('defects.filter.onlyEntries')) return '*';
            var subcontractor = subcontractors.find(function (model) { return model.getLabel() === val; });
            return subcontractor ? subcontractor.id : val;
        },

        showHelpPopup: function () {
            this.$showHelpPopup.popup('open')
        }

    });
});
