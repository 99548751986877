import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton, StyledIconButton} from "@bau/material";
import {useParams} from "react-router-dom";
import {EllipsisHorizontalIcon} from "@heroicons/react/24/outline";
import {useAppSelector} from "../store/hooks";
import {CommonStoreStateKeys} from "../store/slices/commonStoreSlice";
import {isUserReadonly, translate} from "../utils/utils";


const Subcontractor = () => {

    const {subcontractorId} = useParams();

    const user = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.user])

    useEffect(()=>{
        // @ts-ignore
        window.subcontractorUseEffect(!!subcontractorId ? subcontractorId : "null");
    },[])

    let right = [
        <StyledIconButton className="options-menu-button actionsTrigger">
            <EllipsisHorizontalIcon/>
        </StyledIconButton>
    ]
    if(isUserReadonly(user)) {
        right.splice(0,1);
    }

    return <PageContainer newPage={false} headerText={translate("subcontractor")}
                          left={<StyledButton className="subcontractor-previousPage">{translate("goback")}</StyledButton>}
                          right={right}
    />
}

export default Subcontractor;