define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2',
	'../model/Inspection', '../model/currentProject', '../model/currentInspection',
	'../model/user', '../helper/interactiveSync', '../model/proxyInfo'
], function (
	$, _, Backbone,
	Inspection, currentProject, currentInspection,
	user, interactiveSync, proxyInfo
) {
	return window.currentInspections = new (Backbone.Collection.extend({

		model: Inspection,

		initialize: function () {
			this.resetFilter();

			this.listenTo(currentProject, 'change:id', this.resetFilter);
			this.listenTo(currentInspection, 'change:id', this.indexLabels);
			this.on('sync', this.indexLabels, this);
		},

		url: function () {
			 return  '/onlineBauabnahme/api/inspections/' + this.project;
		},

		setProject: function (project) {
			if (project == this.project) {
				if (this.pending) {
					return this.pending;
				} else {
					return $.Deferred().resolve();
				}
			}
			var oldProject = this.project;
			this.project = project;
			if (!project) {
				this.reset();
				return $.Deferred().resolve();
			}
			this.pending = this.fetch({ reset: true })
				.fail(_.bind(function () {
					this.project = oldProject;
				}, this))
				.always(_.bind(function () {
					delete this.pending;
				}, this));
			return this.pending;
		},

		indexLabels: function () {
			this.labelIndex = [];
			this.each(function (inspection) {
				var label = inspection.getLabel(), baseLabel = label, index = 1;
				while (_.contains(this.labelIndex, label)) {
					label = baseLabel + ' (' + (++index) + ')';
				}
				this.labelIndex.push(label);
			}, this);
			if (!currentInspection.isNew()) {
				var idx = this.indexOf(this.findWhere({ id: currentInspection.id }));
				this.labelIndex[idx] = '* ' + this.labelIndex[idx] + ' ' + user.translate('defects.filter.currentinspection');
			}
		},

		matchesFilter: function (inspection, includeFulltext) {
			if (!_.isUndefined(this.filter.archive) && inspection.get('archive') != this.filter.archive) {
				return false;
			}
			if (!_.isUndefined(this.filter.finished) && inspection.get('finished') != this.filter.finished) {
				return false;
			}
			var type = inspection.getType();
			if (!_.isUndefined(this.filter.external) && !!(type && type.external != 'no') != this.filter.external) {
				return false;
			}
			if (this.filter.from && inspection.get('date') < this.filter.from) {
				return false;
			}
			if (this.filter.to && inspection.get('date') > this.filter.to) {
				return false;
			}
			if (this.filter.unit && (!inspection.get('unit') || inspection.get('unit').substr(0, this.filter.unit.length) != this.filter.unit)) {
				return false;
			}
			if (this.filter.type && inspection.get('type') != this.filter.type) {
				return false;
			}
			// check if the search string is contained in the label
			if (this.filter.search) {
				var title = inspection.get('title').toLowerCase().indexOf(this.filter.search.toLowerCase());
				if (this.filter.includeFulltext) {
					var fulltext = inspection.get('descr').toLowerCase().indexOf(this.filter.search.toLowerCase());
					if (title === -1 && fulltext === -1) {
						return false;
					}
				} else {
					if (title === -1) {
						return false;
					}
				}
			}
			if(inspection.get('descr') && inspection.get('descr').length > 0 && includeFulltext) {
				console.log("descr: " + inspection.get('descr'));
			}

			return true;
		},

		sync: function (method, model, options) {
			return interactiveSync.sync.apply(interactiveSync, [method, model, proxyInfo.syncAddHeaders(options)]);
		},

		resetFilter: function () {
			this.filter = {
				onlyPending: true,
				archive: false
			};
		},

		//there's actually no point that this function is here, but it is needed in two views
		//and I don't want to put it in an extra file. Move if needed.
		getVisibleInspectionTypes: function (forceTypesIdList) {
			var hidden = currentProject.get('inspectionVisibilityConfig') && currentProject.get('inspectionVisibilityConfig').hidden || [];
			var visibleInspectionTypes = _.filter(user.get('inspections'), function (inspectionType) {
				if (forceTypesIdList && _.contains(forceTypesIdList, inspectionType.id)) {
					return true;
				}
				return (hidden.indexOf(inspectionType.id) == -1) || (!currentInspection.isNew() && inspectionType.id === currentInspection.get('type'));
			});

			var result = [];
			_.each(visibleInspectionTypes, function (inspectionType) {
				var additions = [];
				if (inspectionType.defectType) {
					if (user.translate('defect.type.' + inspectionType.defectType).indexOf("defect.type") !== -1) {
						var defect = $.grep(currentProject.get('types'), function (item) {
							return item.defectTypeId === inspectionType.defectType
						})[0];
						additions.push(!!defect ? defect.label : "");
					} else {
						additions.push(user.translate('defect.type.' + inspectionType.defectType));
					}
				}
				if (inspectionType.external && inspectionType.external !== 'no') {
					additions.push(user.translate('terms.defect.external'));
				} else {
					additions.push('intern');
				}
				var extendedName = inspectionType.name + (additions.length > 0 ? '\xa0\xa0\xa0\xa0\xa0(Art: ' + additions.join(', ') + ')' : '');
				result.push({
					id: inspectionType.id,
					name: inspectionType.name,
					extendedName: extendedName,
					type: inspectionType
				});
			}, this);
			return result;
		}

	}));
});