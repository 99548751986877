define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"content\">\n" +
        "\n" +
        "\t<div class=\"hover panel\">\n" +
        "\t\t<div id=\"logo\" class=\"front\">\n" +
        "\t\t\t<img src=\"images/oa.400.png\" style=\"max-width: 400px; max-height: 100px; width: 100%;\"/>\n" +
        "\t\t</div>\n" +
        "\t\t<form class=\"back\">\n" +
        "\t\t\t<p><img src=\"images/oa.400.png\" style=\"max-width: 400px; max-height: 100px; width: 100%;\"/></p>\n" +
        "\t\t\t<div data-role=\"controlgroup\">\n" +
        "\t\t    <input name=\"username\" type=\"text\" placeholder=\"Benutzername / Code\" data-wrapper-class=\"controlgroup-textinput ui-btn\" autocomplete=\"off\" autocorrect=\"off\"/>\n" +
        "\t\t    <input name=\"password\" type=\"password\" placeholder=\"Passwort / PIN\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t\t<input type=\"submit\" value=\"Login\" data-icon=\"arrow-r\" data-iconpos=\"right\"/>\n" +
        "\t\t\t</div>\n" +
        "\t\t</form>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"info\" style=\"display: none\">\n" +
        "\t\t<!--<p style=\"text-align: center; margin-top: 2em\"><a href=\"http://old.onlinebauabnahme.de/\">» zur vorherigen Version vom Service Bau-Mängel</a></p>-->\n" +
        "\t\t<p style=\"text-align: center; margin-top: 2em;\">kein Zugang? <a style=\"color: #ee7f00;\" href=\"http://openexperience.de/onlineBauabnahme.html\">hier informieren und bestellen</a></p>\n" +
        "\t\t<p style=\"text-align: center; margin-top: 2em\">\n" +
        "\t\t\t<a href=\"https://itunes.apple.com/de/app/id952266237\" target=\"_blank\"><img src=\"images/Download_on_the_App_Store_Badge_DE_Source_135x40.svg\" style=\"vertical-align: middle; width: 135px; height: 40px; border: none\"/></a>\n" +
        "\t\t\t<a href='https://play.google.com/store/apps/details?id=de.openexperience.onlinebauabnahme&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target=\"_blank\"><img alt='Jetzt bei Google Play' src='/onlineBauabnahme/images/google-play-badge.png' style=\"vertical-align: middle; height: 60px\"/></a>\n" +
        "\t\t</p>\n" +
        "\t</div>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<!--\n" +
        "<div data-role=\"footer\" data-position=\"fixed\">\n" +
        "\t<h4 data-role=\"heading\">ein Service von <a href=\"http://openexperience.de/\" target=\"_blank\" style=\"color: white\">Open Experience</a></h4>\n" +
        "</div>\n" +
        "-->";
    return page;
});