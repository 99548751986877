import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {useNavigate} from "react-router-dom";
import {getCustomerProjectWithPrefix, translate} from "../utils/utils";


const PrincipalBundle = () => {

    const navigate = useNavigate();

    const {customerProjectWithInspection} = getCustomerProjectWithPrefix();
    useEffect(()=>{
        // @ts-ignore
        window.principalBundleUseEffect();
    }, [])

    const goToProject = () => {
        navigate(customerProjectWithInspection);
    }


    const goToPrincipalDefects = ()=>{
        navigate(`${customerProjectWithInspection}/defects`,{state:{principal:true}});
    }

    return <PageContainer newPage={false} headerText={translate("principal.bundle.title")}
                          left={<StyledButton onClick={goToProject}>{translate("goback")}</StyledButton>}
                          right={<StyledButton onClick={goToPrincipalDefects}>{translate("principal.defects")}</StyledButton>}
    />
}

export default PrincipalBundle;