// Copyright 2014, Open Experience GmbH. All rights reserved.
define([
	'../lib/jquery-1.11.0', '../lib/backbone-1.1.2', './HelpPageHtml', '../model/user', '../model/help', '../model/currentProject', '../helper/watchdog', '../helper/iosapp'
], function ($, Backbone, Template, user, help, currentProject, watchdog, iosapp) {
	'use strict';

	return Backbone.View.extend({

		id: 'help',

		attributes: {
			'data-role': 'page'
		},

		events: {
			'keyup .helpSearchFilter':  'filter',
			'vclick .download':         'download',
			'vclick .version':          'version',
			'vclick .hotline': function () {
			 	var clientOf = user.get('clientOf');
				 var number = '072148699530';
				 var emailAddress = 'support@openexperience.de';
				var message = user.translate('popup.hotline');
 				//if (customer.indexOf('bilfinger_') === 0 || customer.indexOf('rum_') === 0) {
			 	if (clientOf === 'bilfinger'){
					 number = '0080008000888';
					message = user.translate('popup.hotline.bilfinger');
				//} else if (customer.indexOf('pmg') === 0 || customer === 'siemens' || customer === 'berger' || customer ==- 'swm') {
				} else if (clientOf === 'pmg'){
					message = user.translate('popup.hotline.pmg');
					number = '0893303782100';
					emailAddress = 'info@pmgnet.de';
				} else if (clientOf === 'brz') {
					message = user.translate('popup.hotline.brz');
					emailAddress = 'baudokumentation.support.de@brz.eu';
				}
				window.hotlinePopup(number, emailAddress, message);
			}
		},

		template: _.template(Template),

		initialize: function (options) {
			this.resize = _.bind(this.resize, this);

			this.router = options.router;

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.$backlink = this.$('.back');
			this.$filter   = this.$('.helpSearchFilter');
			this.$list     = this.$('.list');

			this.$el.on('pageshow', this.resize);
			$(window).on('throttledresize', this.resize);
			this.listenTo(watchdog, 'change:connected', _.bind(function () {
				if (!this.$el.is(':visible')) {
					return;
				}
				this.render();
			}, this));
		},

		filter: function () {
			_.defer(function (self) {
				help.filter(self.$filter.val());
				self.render();

				var searchstr = self.$filter.val() || '';
				if (self.debugsearchstr != searchstr) {
					self.debugsearchstr = searchstr;
					if (searchstr.indexOf('$$debug360:') == 0 && searchstr.lastIndexOf('$$') == searchstr.length - 2) {
						var arg = searchstr.split(':').slice(1).join(':');
						arg = arg.substr(0, arg.length - 2);
						console.log(arg)
						iosapp.digibau360(arg)
					}
				}
			}, this);
		},

		render: function () {
			var $container;
			this.$list.empty();
			_.each(help.get('paragraphs'), function (paragraph, index) {
				if (paragraph.level == 0 && !paragraph.exfiltrated) {
					$container = $('<li data-role="list-divider">').text(paragraph.text).appendTo(this.$list);
				} else if ($container && paragraph.level == 1 && !paragraph.exfiltrated) {
					$('<li>').appendTo(this.$list).append(
						$('<a href="javascript:void(0)">')
						.html(help.hilight(paragraph.text))
						.click(_.bind(function () {
							this.router.helpArticlePage.setModel(help.getParagraph(index));
							this.router.show(this.router.helpArticlePage);
						}, this))
					);
				}				
			}, this);
			this.$list.listview('refresh');
			var connected = watchdog.isConnected();
			this.$('.download').toggleClass('ui-disabled', !connected);
			this.$backlink.toggleClass('ui-disabled', !connected);
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active')) {
					var listMargin = parseInt(self.$list.css('margin-bottom'));
					self.$list.hide();
					var visible = self.$('.ui-content > *:visible');
					var last = $(visible[visible.length - 1]);
					var pageOffset = parseInt(self.$el.css('padding-top').split('px')[0]);
					var height = Math.floor(self.$el.height() - (last.length > 0 ? last.position().top : 0) - (last.outerHeight(true)||0) + pageOffset - listMargin);
					self.$list.height(height).show();
				}
			}, this);
		},

		download: function (e) {
			e.stopPropagation();
			e.preventDefault();
			
			var link =  '/api/help.pdf?project=' + window.encodeURIComponent(currentProject.id);
			if (iosapp.appavailable) {
				iosapp.downloadAndSharePdf(window.location.origin + link, "Hilfe.pdf");
			} else {
				window.open(link, '_blank').focus();
			}
		},
		
		version: function(e) {
			var apptext = "Web-Version: %WEBVERSION%\nApp-Version: %APPVERSION%\nSystem-Version: %SYSTEMVERSION%";
			var webtext = "Web-Version: %WEBVERSION%\n Web-Browser: %BROWSERVERSION%";
			
			var deferred = $.Deferred();
			
			if (iosapp.appavailable && iosapp.functionavailable('getsysteminfo')) {
				iosapp.getsysteminfo(function (sysinfo) {
					var text = apptext;
					
					if (sysinfo && sysinfo.appversion) {
						text = text.replace('%APPVERSION%', sysinfo.appversion);
					} else {
						text = text.replace('%APPVERSION%', 'N/A');
					}

					if (sysinfo && sysinfo.iosversion) {
						text = text.replace('%SYSTEMVERSION%', sysinfo.iosversion);
					} else {
						text = text.replace('%SYSTEMVERSION%', 'N/A');
					}
					
					deferred.resolve(text);
				});
			} else {
				var ua= navigator.userAgent, tem,
					M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
				if(/trident/i.test(M[1])){
					tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
					webtext = webtext.replace('%BROWSERVERSION%', 'IE '+(tem[1] || ''));

				} else {
					if (M[1] === 'Chrome') {
						tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
						if (tem != null)
							webtext = webtext.replace('%BROWSERVERSION%', tem.slice(1).join(' ').replace('OPR', 'Opera'));

					}
					M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
					if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
					webtext = webtext.replace('%BROWSERVERSION%', M.join(' '));
				}
				deferred.resolve(webtext);
			}
			
			deferred.done( function(text) {
				window.alertPopup(text.replace('%WEBVERSION%', window.version), 'Version');
			});
		}

	});
});
