// Copyright 2014, Open Experience GmbH. All rights reserved.

define([
	'../lib/jquery-1.11.0', '../lib/backbone-1.1.2', './PrincipalDetailsHtml', '../model/user', '../model/currentProject'
], function ($, Backbone, Template, user, currentProject) {
	'use strict';

	return Backbone.View.extend({

		className: 'principalDetails ui-bar ui-bar-a textselect',

		events: {},

		initialize: function () {
			this.$el.append(Template);

			this.$table  = this.$el.children('table');
		},

		render: function () {
			this.$table.empty();
			var principalInfo = currentProject.get('principalInfo');
			if (principalInfo.address) {
				this.$table.append($('<tr><td>' + user.translate('principal.contact') + '</td></tr>').append($('<td></td>').html(principalInfo.address.replace(/\n/g,'<br/>'))));
			}
			if (principalInfo.phone) {
				this.$table.append($('<tr><td>' + user.translate('principal.phone') + '</td></tr>').append($('<td></td>').text(principalInfo.phone)));
			}
			if (principalInfo.email) {
				this.$table.append($('<tr><td>' + user.translate('principal.email') + '</td></tr>').append($('<td></td>').text(principalInfo.email)));
			}
		}

	});

});