define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './StatisticsPageHtml', '../view/StatisticsView',
	'../model/user'
], function (
	$, _, Backbone, Template, StatisticsView,
	user
) {
	return Backbone.View.extend({

		id: 'statistics',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		initialize: function (options) {
			_.bindAll(this, 'resize');
			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.statsView = new StatisticsView({ el: this.$el.find('.projectstats') });

			this.$projectStats = this.$('.projectstats');

			this.$el.on('pageshow', _.bind(function () {
				this.resize();
			}, this));
			$(window).on('throttledresize', this.resize);
		},

		remove: function() {
			Backbone.View.prototype.remove.apply(this.statsView, arguments);
			Backbone.View.prototype.remove.apply(this, arguments);
		},

		render: function () {
			this.statsView.render();

			return this;
		},

		resize: function () {
//			if (this.$el.hasClass('ui-page-active')) {
//				this.projectInfo.css('height', $(window).height() - this.header.outerHeight(true) - this.footer.outerHeight(true) - this.$('.ui-select').outerHeight(true));
//			}
		}

	});
});