import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export enum CommonStoreStateKeys {
    backboneDataUpdateTimeStamp = "backboneDataUpdateTimeStamp",
    user ="user",
    proxy = "proxyInfo",
    myaccounts = "ma_accounts",
    loading = "loading",
    showDialog = "showDialog",
    showSnackbar = "showSnackbar",
    watchdog = "watchdog",
    offlineQueue = "offlineQueue",
    notSaveCustomerProject = "notSaveCustomerProject",
    currentCustomer = "currentCustomer",
    projects = "projects",
    currentProject = "currentProject",
    accessibleAccounts = "accessibleAccounts",
    help = "help",
    inspections = "inspections",
    subcontractors = "subcontractors",
    polygonsAndCfgs = "polygonsAndCfgs",
    realWorldPlanTransformations = "realWorldPlanTransformations",
    recordPoints = "recordPoints",
    currentDefects = "currentDefects",
    completions = "completions",
    projectFiles = "projectFiles",
    currentInspection = "currentInspection",
    currentStats = "currentStats",
    participants = "participants",
    attachmentSources = "attachmentSources",
    projectFileSources = "projectFileSources",
    forcedCustomerProject = "forcedCustomerProject",

}

export interface CommonStoreStatePayload {
    key: CommonStoreStateKeys,
    value: any
}

export interface CommonStoreState {
    [CommonStoreStateKeys.backboneDataUpdateTimeStamp]: any,
    [CommonStoreStateKeys.user]: any,
    [CommonStoreStateKeys.proxy]: any,
    [CommonStoreStateKeys.myaccounts]: any,
    [CommonStoreStateKeys.loading]: boolean
    [CommonStoreStateKeys.showDialog]: any,
    [CommonStoreStateKeys.showSnackbar]: any,
    [CommonStoreStateKeys.watchdog]: any,
    [CommonStoreStateKeys.offlineQueue]: any,
    [CommonStoreStateKeys.notSaveCustomerProject]: boolean,
    [CommonStoreStateKeys.currentCustomer]: any,
    [CommonStoreStateKeys.projects]: any,
    [CommonStoreStateKeys.currentProject]: any,
    [CommonStoreStateKeys.accessibleAccounts]: any,
    [CommonStoreStateKeys.help]: any,
    [CommonStoreStateKeys.inspections]: any,
    [CommonStoreStateKeys.subcontractors]: any,
    [CommonStoreStateKeys.polygonsAndCfgs]: any,
    [CommonStoreStateKeys.realWorldPlanTransformations]: any,
    [CommonStoreStateKeys.recordPoints]: any,
    [CommonStoreStateKeys.currentDefects]: any,
    [CommonStoreStateKeys.completions]: any,
    [CommonStoreStateKeys.projectFiles]: any,
    [CommonStoreStateKeys.currentInspection]: any,
    [CommonStoreStateKeys.currentStats]: any,
    [CommonStoreStateKeys.participants]: any,
    [CommonStoreStateKeys.attachmentSources]: any,
    [CommonStoreStateKeys.projectFileSources]: any,
    [CommonStoreStateKeys.forcedCustomerProject]: any,
}

const initialState: CommonStoreState = {
    [CommonStoreStateKeys.backboneDataUpdateTimeStamp]: 0,
    [CommonStoreStateKeys.user]: {},
    [CommonStoreStateKeys.proxy]: {},
    [CommonStoreStateKeys.myaccounts]: {},
    [CommonStoreStateKeys.loading]: false,
    [CommonStoreStateKeys.showDialog]: "",
    [CommonStoreStateKeys.showSnackbar]: "",
    [CommonStoreStateKeys.watchdog]: {canUpdateLocalDefectDate: true},
    [CommonStoreStateKeys.offlineQueue]: {},
    [CommonStoreStateKeys.notSaveCustomerProject]: false,
    [CommonStoreStateKeys.currentCustomer]: "",
    [CommonStoreStateKeys.projects]: [],
    [CommonStoreStateKeys.currentProject]: {},
    [CommonStoreStateKeys.accessibleAccounts]: {},
    [CommonStoreStateKeys.help]: {},
    [CommonStoreStateKeys.inspections]: [],
    [CommonStoreStateKeys.subcontractors]: [],
    [CommonStoreStateKeys.polygonsAndCfgs]: {},
    [CommonStoreStateKeys.realWorldPlanTransformations]: [],
    [CommonStoreStateKeys.recordPoints]: {},
    [CommonStoreStateKeys.currentDefects]: [],
    [CommonStoreStateKeys.completions]: {},
    [CommonStoreStateKeys.projectFiles]: {},
    [CommonStoreStateKeys.currentInspection]: {},
    [CommonStoreStateKeys.currentStats]: {subcontractorId: null, externalOnly: false},
    [CommonStoreStateKeys.participants]: [],
    [CommonStoreStateKeys.attachmentSources]: [],
    [CommonStoreStateKeys.projectFileSources]: [],
    [CommonStoreStateKeys.forcedCustomerProject]: undefined,
} as CommonStoreState

export const commonStoreSlice = createSlice({
    name: 'commonStore',
    initialState,
    reducers: {
        setStateValue: (state, action: PayloadAction<CommonStoreStatePayload>) => {
            state[action.payload.key] = action.payload.value;
        }
    }
})

export const { setStateValue } = commonStoreSlice.actions

const commonStoreReducer = commonStoreSlice.reducer

export default commonStoreReducer;
