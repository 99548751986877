import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {useLocation, useNavigate} from "react-router-dom";
import {getCustomerProjectWithPrefix, getProjectIdFromCustomerProject, translate} from "../utils/utils";
import {page} from "../utils/constants";


const WarrantyDataOverview = () => {
    const navigate = useNavigate();
    const {customerProject} = getCustomerProjectWithPrefix();
    const project = getProjectIdFromCustomerProject(customerProject);
    const {state} = useLocation();

    useEffect(()=>{
        // @ts-ignore
        window.warrantyDataOverviewUseEffect(project);
    },[])

    const goToProject = ()=>{
        navigate(`${customerProject}/settings`, {state});
    }

    return <PageContainer pageId={page.WARRANTY_DATA_OVERVIEW} newPage={false} headerText={translate("warrantyDataOverview.title")}
                          left={<StyledButton onClick={goToProject}>{translate("goback")}</StyledButton>}
    />
}

export default WarrantyDataOverview;