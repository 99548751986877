define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './PaperworkHtml', '../view/SubcontractorDetails', '../view/InspectionDetails',
	'../model/paperwork', '../model/subcontractors', '../model/ProjectFile', '../model/user', '../model/Inspection', '../model/currentProject', '../model/currentInspection',
	'../helper/iosapp', '../helper/backboneReactCommunicationUtils' ,'../lib/jquery.scrollTo'
], function (
	$, _, Backbone, Template, SubcontractorDetails, InspectionDetails,
	paperwork, subcontractors, ProjectFile, user, Inspection, currentProject, currentInspection,
	iosapp, backboneReactCommunicationUtils
) {
	return Backbone.View.extend({

		id: 'paperwork',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		checkedIndices: {},

		events: {
			'vclick .previousPage': 'gotoPreviousPage',
			'click .list .ui-checkbox': function (e) {
				var $input = $(e.currentTarget).children('input.selectItem');
				var state = $input.prop('checked');
				if (!$(e.target).is('input')) {
					state = !state;
					$input.prop('checked', state).focus();
				}
				var index = $input.parents('[data-index]').attr('data-index');
				if (state) {
					this.checkedIndices[index] = true;
				} else {
					delete this.checkedIndices[index];
				}
			},
			'vclick .sendEmail': function (e) {
				$('.sendEmail').prop('disabled', true);
				e.preventDefault();
				e.stopPropagation();
				this.send('email');
				$('.sendEmail').prop('disabled', false);
			},
			'vclick .selectAll': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.$list.find('[type="checkbox"]')
					.prop('checked', true)
					.parents('[data-index]').each(_.bind(function (index, el) {
						this.checkedIndices[$(el).attr('data-index')] = true;
					}, this));
				this.$actionsMenu.popup('close');
			},
			'vclick .download': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var index = $(e.target.parentElement.parentElement).attr('data-index');
				if (index) {
					var link =  '/onlineBauabnahme/api/downloadBundle?parts=' + index + '&registerSentEvent=' + 0 + '&bundleSessionId=' + (paperwork.get('bundleSessionId') || '');
					if (iosapp.appavailable) {
						iosapp.downloadAndSharePdf(window.location.origin + link, "Aufforderung.pdf");
					} else {
						window.open(link);
					}
				}
			},
			'vclick .sendPaper': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var checkedIndices = _.keys(this.checkedIndices);

				if (!checkedIndices.length) {
					alertPopup(user.translate('paperwork.selectdocuments'));
					return;
				}

				var link =  '/onlineBauabnahme/api/downloadBundle?parts=' + checkedIndices.join(',') + '&registerSentEvent=1' + '&bundleSessionId=' + (paperwork.get('bundleSessionId') || '');
				if (iosapp.appavailable) {
					iosapp.downloadAndSharePdf(window.location.origin + link, checkedIndices.length > 1 ? "Aufforderungen.zip" : "Aufforderung.pdf");
				} else {
					window.open(link);
				}
			},
			'vclick .openFile': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var $button = $(e.currentTarget);
				var index = $button.parents('[data-index]').attr('data-index');

				$.mobile.loading('show');
				$.post( '/onlineBauabnahme/api/viewBundlePart', { part: index, bundleSessionId: paperwork.get('bundleSessionId') })
					.done(_.bind(function (data) {
						this.router.imagePage.setModel(new ProjectFile(data));
						this.router.imagePage.setupPageForDocument();
						window.showPageCallback = function () {
							this.router.imagePage.setupPageForDocument();
						}.bind(this);
						window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/image', {callback: true});
					}, this))
					.fail(_.bind(function (jqXHR, textStatus, errorThrown) {
						if (jqXHR && jqXHR.status == 410) {
							$.mobile.loading('hide');

							alertPopup(user.translate('paperwork.showdocument.sessionexpired')).then(function() {
								window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection());
							}.bind(this));
						} else {
							alertPopup(user.translate('paperwork.showdocument.error'));
						}
						$.mobile.loading('hide');
					}, this));
			},
			'vclick .actionsTrigger': 'triggerActionsMenu',
			'vclick .actionsMenu': 'closeActionsMenu',
			'vclick .editEmail': 'editEmail'
		},

		editEmail: function () {
			this.$('.recipientEmail').each(_.bind(function (index, item) {
				var $item = $(item);

				var recipient = this.getRecipient($item.attr('data-subcontractor'));
				var $input = $item.find('input');
				if ($input.length > 0) {
					$item.text(recipient);
					return;
				}

				$item.html('<div class="ui-input-text ui-shadow-inset ui-corner-all"><input class="email" type="text"/></div>');
				$item.find('input').val(recipient);
				
			}, this));
		},

		triggerActionsMenu: function (e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			this.$actionsMenu.popup('open', { transition: 'flip', positionTo: $('.actionsTrigger') });
		},

		closeActionsMenu: function (e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			this.$actionsMenu.popup('close');
		},

		isActionsMenuOpen: function () {
			return $('div.ui-popup-container.ui-popup-active').length > 0;
		},

		initialize: function (options) {
			this.router = options.router;
			this.resize = _.bind(this.resize, this);

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);

			this.subcontractorDetails = new SubcontractorDetails();
			this.inspectionDetails = new InspectionDetails(options);
			this.$el.children('[data-role="content"]').prepend(this.subcontractorDetails.$el);
			this.$el.children('[data-role="content"]').prepend(this.inspectionDetails.$el);
			this.$actionsMenu = this.$('.actionsMenu');

			this.$list = this.$('.list');

			$(window).on('throttledresize', this.resize);
			this.$el.on('pageshow', this.resize);
		},

		render: function () {
			$('.actionsTrigger').off('click');
			$('.actionsTrigger').on('click', function (e) {
				this.triggerActionsMenu(e);
			}.bind(this));
			$('.previousPage').off('click');
			$('.previousPage').on('click', function (e) {
				this.gotoPreviousPage(e);
			}.bind(this));
			if (_.isObject(this.model) && !_.isArray(this.model)) {
				this.details.render();
			}

			this.renderList();

			return this;
		},

		renderList: function () {
			this.$list.empty();
			_.each(paperwork.get('groups'), function (pair) {
				var documents = _(pair[1])
					.filter(function (i) { return !i.isDisallowedByGroup; })
					.value();
				if (documents.length == 0) {
					return;
				}

				var subcontractor, recipient;
				// either one of the documents is principal or the entire model is principal
				const types = currentProject.get('types');
				const isPrincipal = (_.isString(this.model) && this.model == 'principal') || _.some(documents, function (document) {
					const type = document.defectType;
					return _.find(types, function (t) { return t.defectTypeId === type; }).isPrincipal;
				})
				if (isPrincipal) {
					recipient = currentProject.get('principalInfo').email;
				} else {
					subcontractor = subcontractors.get(pair[0]);
					if (subcontractor) {
						recipient = subcontractor.get('email');
					}
				}

				var li = $('<li class="recipientEmail" data-role="list-divider" data-theme="b">')
					.text(recipient ? recipient : 'keine Adresse angegeben')
					.appendTo(this.$list);
				if (subcontractor) {
					li.attr('data-subcontractor', subcontractor.id);
				}

				_.each(documents, function (document) {
					this.renderDocument(document);
				}, this);

			}, this);
			this.$list.listview('refresh');
		},

		renderDocument: function (document) {
			this.checkedIndices = {};
			var documents = paperwork.get('documents');
			var documentIndex = _.indexOf(documents, document);
			var $item = $('<li></li>').appendTo(this.$list);
			$item = $('<div class="ui-grid-a ui-corner-all"></div>').appendTo($item).attr('data-index', documentIndex);
			$item.append('<div class=" ui-checkbox"><input type="checkbox" class="selectItem ui-btn ui-block-a"></div>')
			$item = $('<div class="ui-grid-a listItem"></div>').appendTo($item);
			$item.append('<div class = "ui-block-a download"></div>');
			$item.append($('<button class="openFile ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r"></button>').text(document.filename));
			/*.append($('<span>').addClass('list-icon.download')
				.attr('style',  'background-image: url(' +  '/images/fileicon.png); background-size: 18pt;'))
		);*/
			$item.enhanceWithin();
		},

		refreshSelections: function () {
			this.$list.find('input.selectItem').prop('checked', false);
			_.each(this.checkedIndices, function (val, index) {
				this.$list.find('input.selectItem[data-index="' + index + '"]').prop('checked', true);
			}, this);
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active') && !self.$el.hasClass('hide-page')) {
					var listMargin = parseInt(self.$list.css('margin-bottom')) + parseInt(self.$list.css('margin-top'));
					self.$list.hide();
					var last = self.$('.ui-content > *:visible').eq(-1);
					var pageOffset = parseInt(self.$el.css('padding-top'));
					var height = Math.floor(self.$el.height() - last.position().top - last.outerHeight(true) +
						pageOffset - listMargin) - 1;
					self.$list.height(height).show();
				}
			}, this);
		},

		remove: function () {
			$(window).off('throttledresize', this.resize);
			Backbone.View.prototype.remove.apply(this, arguments);
		},

		setModel: function (model) {
			this.model = model;
			if (_.isString(this.model) && this.model == 'principal') {
				this.inspectionDetails.$el.hide();
				this.subcontractorDetails.$el.hide();
			} else if (_.isString(this.model)) {
				this.inspectionDetails.$el.hide();
				this.subcontractorDetails.$el.hide();
			} else {
				if (this.isInspectionPaperwork()) {
					this.details = this.inspectionDetails;
					this.subcontractorDetails.$el.hide();
				} else {
					this.details = this.subcontractorDetails;
					this.inspectionDetails.$el.hide();
				}
				this.details.setModel(model);
				this.details.$el.show();
			}
		},

		send: function () {

			var documents = paperwork.get('documents');
			var checkedIndices = _.keys(this.checkedIndices);

			if (!checkedIndices.length) {
				alertPopup(user.translate('paperwork.selectdocuments'));
				return;
			}

			//			if (_.some(checkedIndices, function (index) {
			//				var document = documents[parseInt(index)];
			//				return document.type !== 'SubcontractorRequest';
			//			}, this)) {
			//				alertPopup('Nur Auftragnehmer-Aufforderungen können versendet werden! Bitte deselektieren Sie andere Dokumente.');
			//				return;
			//			}

			var starter = $.Deferred(),
				p = starter.promise(),
				failed = [], sent = [];

			var toSend = [];
			_.each(checkedIndices, function (index) {
				var idx = parseInt(index);
				if (idx >= documents.length) {
					return;
				}
				var document = documents[idx];
				if (!document) {
					return;
				}
				if (document.type != 'SubcontractorRequest' && document.type != 'PrincipalNotification') {
					return;
				}
				var recipient = this.getRecipient(document.subcontractor);
				if (!recipient) {
					failed.push({
						document: document,
						failedAddresses: [user.translate('paperwork.specifyrecipient')]
					});
					return;
				}
				toSend.push({
					part: index,
					document: document,
					paperworkId: (document.paperworkId ? document.paperworkId : null),
					recipient: recipient
				});
			}, this);

			var sessionExpired = false;

			var p = $.ajax({
				type: 'POST',
				url: '/onlineBauabnahme/api/sendBundleParts' + '?bundleSessionId=' + (paperwork.get('bundleSessionId') || ''),
				data: JSON.stringify(toSend),
				contentType: 'application/json'
			})
				.done(function (sendStatuses) {
					_.each(sendStatuses, function (sendStatus) {
						if (sendStatus.success) {
							sent.push(document);
						} else {
							failed.push({
								//document: document,
								failedAddresses: sendStatus.failedAddresses,
								text: (sendStatus.failedAddresses && sendStatus.failedAddresses.length > 0 ? sendStatus.failedAddresses.join(', ') : (sendStatus.description || "")),
								reload: sendStatus.reload
							});
						}
					}.bind(this));
				})
				.fail(function (jqXHR) {
					if (jqXHR && jqXHR.status == 410) {
						sessionExpired = true;
					}
					_.each(toSend, function (el) {
						failed.push({
							//document: el.document,
							failedAddresses: [el.recipient],
							text: [el.recipient].join(', ')
						});
					});
				});

			p.always(_.bind(function () { //final handlers
				$.mobile.loading('hide');

				if (sessionExpired) {
					alertPopup(user.translate('paperwork.showdocument.sessionexpired')).then(function() {
						window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection());
					}.bind(this));
					return;
				}

				if (failed.length > 0) {
					alertPopup(
						user.translate('paperwork.senderror')
							.replace('%d', sent.length)
							.replace('%d', sent.length + failed.length)
							.replace('%s', _.map(failed, function (f) {
								if (f.document && f.document.filename) {
									return f.document.filename + ' (' + f.text + ')';
								} else {
									return f.text;
								}
							})
								.join('\n'))
					).then(_.bind(function () {
						_.each(failed, function (f) {
							if (f.reload) {
								$('body').data('cancelbeforeunload', true);
								window.location.reload();
								_.defer(function () { $('body').data('cancelbeforeunload', false); });
							}
						});
					}));
				} else {
					alertPopup(user.translate('paperwork.sent').replace('%d', sent.length), '').then(_.bind(function () {
						this.router.requestBundlePage.gotoPreviousPage();
					}, this));
				}
			}, this));

			$.mobile.loading('show');
			starter.resolve(); //trigger start

		},

		getRecipient: function (subcontractor) {
			var $listheader = subcontractor
				? this.$list.find('.recipientEmail[data-subcontractor="' + subcontractor + '"]')
				: this.$list.find('.recipientEmail:not([data-subcontractor])');
			var $input = $listheader.find('input');
			if ($input.length > 0) {
				return $input.val();
			}
			return $listheader.text();
		},

		gotoPreviousPage: function (e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/requestBundle');
		},

		isInspectionPaperwork: function () {
			return _.isObject(this.model) && this.model instanceof Inspection;
		}

	});
});