import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {getCustomerProjectWithPrefix, translate} from "../utils/utils";
import {CommonStoreStateKeys, setStateValue} from "../store/slices/commonStoreSlice";
import {useAppDispatch} from "../store/hooks";

const QueueTool = () => {


    useEffect(() => {
        // @ts-ignore
        window.queueToolUseEffect();
    }, []);

    const navigate = useNavigate();
    const {customerProject} = getCustomerProjectWithPrefix();


    const goToProject = () => {
        navigate(`${customerProject}/settings`);
    }

    return (
        <PageContainer newPage={false} headerText={translate("queuetool")}
                       left={<StyledButton onClick={goToProject}>Zurück</StyledButton>}
                       right={<StyledButton onClick={() => window.location.reload()}>Neu laden</StyledButton>}
        />
    )
}

export default QueueTool;