import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import store from "./store/store";
import Router from "./Router";
import {responsiveFontSizes, ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";
import {createTheme} from "@mui/material/styles";


export const CONTEXT_PATH = "/onlineBauabnahme";

const rootElement = document.getElementById('root') as HTMLElement;
rootElement.style.height = "100vh";
const root = ReactDOM.createRoot(rootElement);
// @ts-ignore
window.oldBrowser = window.navigator.userAgent.toLowerCase().indexOf('msie') !== -1; //will detect IE < 11
// @ts-ignore
window.contextPath = '/onlineBauabnahme';
// @ts-ignore
window.dev = true;


const spacing = 8;
const primaryColor = '#ee7f00';
const secondaryColor = '#7e7e7e';

let theme = createTheme({
    palette: {
        mode: 'light',
        primary: {main: primaryColor},
        secondary: {
            main: "#666",
            light: "#EEE",
            dark: "#333",
        },
        contrastThreshold: 2,
        info: {main: "#FFF", dark: "#000",}
    },
    components: {
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: `${spacing}px`
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: `0 ${spacing}px ${spacing}px ${spacing}px`
                }
            }
        }
    },
    typography: {
        allVariants: {
            fontFamily: "Rubik, sans-serif",
        },
        subtitle2: {
            color: secondaryColor
        }
    }
} );
theme=responsiveFontSizes(theme)
root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <BrowserRouter basename={CONTEXT_PATH}>
                <Router/>
            </BrowserRouter>
        </Provider>
    </ThemeProvider>
);

