import {EmailType, UpdateEmailConfig} from "../types/types";
import {getDefaultEmailBody, getDefaultEmailSubject} from "../emailDefaults";

export const useEmailDefaults = (
    updateEmailConfig: UpdateEmailConfig,
    selectedEmailType: EmailType | undefined
) => {
    const handleEditDefaultSubject = () => {
        if (selectedEmailType) {
            updateEmailConfig(selectedEmailType, 'subject', getDefaultEmailSubject(selectedEmailType));
        }
    };

    const handleEditDefaultBody = () => {
        if (selectedEmailType) {
            updateEmailConfig(selectedEmailType, 'body', getDefaultEmailBody(selectedEmailType));
        }
    };

    return [handleEditDefaultSubject, handleEditDefaultBody];
};
