define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div class=\"ui-header ui-bar-a\" data-role=\"header\" data-theme=\"a\">\n" +
        "\t<h1 class=\"ui-title\"><%- header %></h1>\n" +
        "</div>\n" +
        "<div class=\"ui-content\">\n" +
        "\t<% if (typeof message !== 'undefined') { %><p><%= _.escape(message).replace(/\\n/g, '<br/>') %></p><% } %>\n" +
        "\t<% if (typeof copyable !== 'undefined') { %><input type=\"text\" value=\"<%= _.escape(copyable).replace(/\\n/g, '<br/>') %> \" readonly/><div style=\"visibility: hidden\"><%= _.escape(copyable).replace(/\\n/g, '<br/>') %></div><!-- the div is for setting the popup size correctly --><% } %>\n" +
        "\t<% if (typeof comment !== 'undefined') { %><textarea class=\"popup-comment-textarea\" /><% } %>\n" +
        "\t<% if (typeof html    !== 'undefined') { %><%= html %><% } %>\n" +
        "\t<div class=\"buttons\">\n" +
        "\t\t<% for(var i = 0; i < buttons.length; i++) { %>\n" +
        "\t\t\t<button class=\"ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a<% if (buttons[i].class != null) { %> <%- buttons[i].class %><% } %>\" data-index=\"<%- i %>\"><%- buttons[i].text %></button>\n" +
        "\t\t<% } %>\n" +
        "\t</div>\n" +
        "</div>";
    return page;
});