import {useAppDispatch, useAppSelector} from "../store/hooks";
import {CommonStoreStateKeys, setStateValue} from "../store/slices/commonStoreSlice";
import SignalCellularAlt1BarRoundedIcon from '@mui/icons-material/SignalCellularAlt1BarRounded';
import SignalCellularAlt2BarRoundedIcon from '@mui/icons-material/SignalCellularAlt2BarRounded';
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import ChangeCircleRoundedIcon from '@mui/icons-material/ChangeCircleRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import tryConnectGif from "../images/trying-connect.gif";
import "./style.css";
// @ts-ignore
import _ from "lodash";
import {setDisconnected, versionCheck} from "../service/watchdog";
import {useEffect, useState} from "react";
import IconButton from '@mui/material/IconButton';
import {dispatchDataWithCallback} from "../utils/backboneCommunicationUtils";
import {getCurrentDefects} from "../service/apis";
import {awaitWrap, translate} from "../utils/utils";
import {setDialog} from "./PageContainer";

const StatusPanel = () => {


    const [defectSyncing, setDefectSyncing] = useState(false);

    const watchdog = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.watchdog]);
    const currentProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentProject]);
    const offlineQueue = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.offlineQueue]);

    const dispatch = useAppDispatch();

    function getPendingChangesStatusText(pending?: number) {
        if(!!pending && pending > 0) {
            let msg = translate(pending === 1 ? 'status.notsavedone' : 'status.notsaved');
            return msg.replace("%d", ""+pending);
        } else {
            return translate('status.synced');
        }
    }

    function activateOfflineMode() {
        let newWatchdog = _.cloneDeep(watchdog);
        setDisconnected(newWatchdog, true);
        dispatchDataWithCallback(false, CommonStoreStateKeys.watchdog, newWatchdog, dataFromBackbone => {
            dispatch(setStateValue({
                key: CommonStoreStateKeys.watchdog,
                value: dataFromBackbone
            }))
        })
    }

    function deactivateOfflineMode() {
        let newWatchdog = _.cloneDeep(watchdog);
        if(!!newWatchdog.forceOffline) {
            newWatchdog.forceOffline = false;
            dispatchDataWithCallback(false, CommonStoreStateKeys.watchdog, newWatchdog, dataFromBackbone => {
                dispatch(setStateValue({
                    key: CommonStoreStateKeys.watchdog,
                    value: dataFromBackbone
                }))
            });
            versionCheck();
        }
    }

    function enforceOffline() {
        let dialogInfo = {
            title: translate("warn"),
            text: translate('confirmenableoffline'),
            buttons: [
                {
                    label: translate("ok"),
                    callback: () => {
                        activateOfflineMode();
                        setDialog(null);
                    }
                },
                {
                    label: translate("cancel"),
                    callback: () => {
                        setDialog(null);
                    }
                }
            ]
        }
        setDialog(dialogInfo);
    }

    async function syncDefects() {
        setDefectSyncing(true);
        let [e,r] = await awaitWrap(getCurrentDefects(currentProject.id));
        if(!e && !!r?.data) {
            dispatchDataWithCallback(false, CommonStoreStateKeys.currentDefects, r.data, dataFromBackbone => {
                dispatch(setStateValue({
                    key: CommonStoreStateKeys.currentDefects,
                    value: dataFromBackbone
                }))
            });
            let newWatchdog = _.cloneDeep(watchdog);
            newWatchdog.defectLocalDate = newWatchdog.defectDate;
            dispatchDataWithCallback(false, CommonStoreStateKeys.watchdog, newWatchdog, dataFromBackbone => {
                dispatch(setStateValue({
                    key: CommonStoreStateKeys.watchdog,
                    value: dataFromBackbone
                }))
            });
        }
        setDefectSyncing(false);
    }

    const forceOffline = watchdog.forceOffline;
    const connected = watchdog.connected;
    const latency = watchdog.latency;
    const trying = watchdog.trying;

    const defectSync = !!currentProject.id && connected && !offlineQueue.pending && !!watchdog.defectLocalDate && !!watchdog.defectDate && (watchdog.defectLocalDate < watchdog.defectDate);

    return <div className="status-panel-container">
        <SignalCellularAltRoundedIcon/>
        {(!latency || latency === -1 || latency >= 2000) && <SignalCellularAltRoundedIcon style={{color: "gray"}}/>}
        {(latency >= 750 && latency < 2000) && <SignalCellularAlt1BarRoundedIcon/>}
        {(latency >= 100 && latency < 750) && <SignalCellularAlt2BarRoundedIcon/>}
        {(latency > 0 && latency < 100) && <SignalCellularAltRoundedIcon/>}
        <img style={{visibility: !!trying ? "visible" : "hidden"}} src={tryConnectGif}/>
        <div className="connect-status-container clickable"
             onClick={() => deactivateOfflineMode()}
             onDoubleClick={() => enforceOffline()}>
            <div>
                <span>{getPendingChangesStatusText(offlineQueue.pending)}</span>
                {<AutorenewRoundedIcon style={offlineQueue.syncing ? {} : {display: "none"}}/>}
            </div>
            <div>
                <CircleRoundedIcon style={{color: connected ? "lawngreen" : "red"}}/>
                <span>{forceOffline ? translate('status.offline') : connected ? translate('status.connected') : translate('status.disconnected')}</span>
            </div>
        </div>
        {defectSync && <IconButton className="clickable" onClick={()=>syncDefects()}>
            <ChangeCircleRoundedIcon className={`sync-defect-btn ${defectSyncing ? "defect-syncing" : ""}`}/>
        </IconButton>}
    </div>
}

export default StatusPanel;