import {useAppSelector} from "../store/hooks";
import {CommonStoreStateKeys} from "../store/slices/commonStoreSlice";
import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {useNavigate, useParams} from "react-router-dom";
// @ts-ignore
import $ from "jquery";
import {translate} from "../utils/utils";


const NewInspection = () => {

    const navigate = useNavigate();
    const {inspectionId} = useParams();

    const currentCustomer = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentCustomer]);
    const currentProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentProject]);
    const currentInspection = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentInspection]);


    useEffect(()=>{
        // @ts-ignore
        window.inspectionUseEffect(inspectionId, !!inspectionId);
    },[])


    const goBack = () => {
        if(!!inspectionId) {
            navigate(`/project/${currentCustomer}-${currentProject.id}/inspection/${currentInspection.id}`);
        } else {
            navigate(`/project/${currentCustomer}-${currentProject.id}/inspections`);
        }
    }

    const getGoBackTitle = () => {
        if(!!inspectionId) {
            return translate("inspection.new.backtoinspection");
        }
        return translate("inspection.new.backtoinspections");
    }

    const getGoAheadTitle = () => {
        if(!!inspectionId) {
            return translate("inspection.new.continue");
        }
        return translate("inspection.new.start");
    }

    return <PageContainer newPage={false} headerText={translate("inspection.new.title")}
                          left={<StyledButton onClick={goBack}>{getGoBackTitle()}</StyledButton>}
                          right={<StyledButton className="save" >{getGoAheadTitle()}</StyledButton>}
    />
}

export default NewInspection;