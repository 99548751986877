// Copyright 2014, Open Experience GmbH. All rights reserved.

define([
	'../lib/jquery-1.11.0', '../lib/backbone-1.1.2', './InspectionPageHtml', '../view/DefectsPage', '../view/Sketcher', '../view/InspectionDetails',
	'../model/currentProject', '../model/currentInspection', '../model/currentDefects', '../model/user', '../model/subcontractors', '../model/inspections', '../helper/watchdog', '../helper/iosapp', '../helper/shortcuts/shortcuts',
	'../helper/backboneReactCommunicationUtils', '../lib/numeral-1.5.3'
], function ($, Backbone, Template, DefectsPage, Sketcher, InspectionDetails, currentProject, currentInspection, currentDefects, user, subcontractors, inspections, watchdog, iosapp, shortcuts, backboneReactCommunicationUtils, numeral) {
	'use strict';

	return DefectsPage.extend({

		id: 'inspection',
		mode: 'defects',

		template2: _.template(Template),
		ajaxUploadDocumentCall: null,

		events: _.extend(DefectsPage.prototype.events, {
			'keypress #inspectionNote':         			'addNoteOnEnter',
			'vclick #addNote':                  			'addNote',
			'vclick .finishMode':               			'finishInspectionMode',
			'vclick .reloadProtocolsBtn':           		'reload',
			'vclick .requestBundle':            			'requestBundle',
			'change [name=inspection-deadline]':       		'renderDeadline',
			'vclick .finishInspection': 					'finishInspection',
			'vclick .sendInspection':   					'sendInspection',
			'change .signatures':       					'changeSignatures',
			'change .archive':          				  	'changeArchive',
			'change .attachDocumentToInspectionForm input': 'uploadDocument',
			'vclick .inspection-previousPage': function (e) {
				if (this.mode === 'finish') {
					this.mode = 'defects';
					this.render();
					e.preventDefault();
					e.stopPropagation();
				} else {
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject()+'/inspections');
				}
			},
			'vclick .edit': function () {
				if(!currentInspection || !currentInspection.get('id')) {
					return;
				}
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject()+'/inspection/'+currentInspection.get('id')+'/edit');
			},
			'click .extendedProtocol': function () {
				$('.christOverlay').show();
				$('.christOverlay iframe')[0].contentWindow.postMessage('data:' + JSON.stringify(currentInspection.get('customerProperties')), '*');
			},
			'click .attachDocumentFromBaufoto'      : 'attachDocumentFromBaufoto',
			'click .document-frame-img'				: 'closeDocumentIframeDiv',
			'click .attachDocumentFromLocalStorage' : 'attachDocumentFromLocalStorage',
			'click .deleteAttachedDocuments'        : 'deleteAttachedDocuments',
			'click .cancelInspectionAttachmentUpload'        : 'cancelInspectionAttachmentUpload',
			'change .customField ': function (e) {
				currentInspection.set('customerProperties', this.customFieldsToJson());
				currentInspection.save();
			},
			'click #inspection-other-settings-header': 'toggleOtherSettings',
			'change .selectResponsiblePerson': 'selectResponsiblePerson',


		}),

		initialize: function (options) {
			arguments[0] = _.extend({ pagevariant: 'inspection' }, arguments[0]);
			DefectsPage.prototype.initialize.apply(this, arguments);

			var template = $(this.template2({ t: user.translate }));
			this.$el.children('[data-role="content"]').prepend(template.filter('#contentAdditions').get(0).innerHTML);
			this.$actionsMenu.children('ul').prepend(template.filter('#actionsmenuAdditions').get(0).innerHTML);

			this.$inspectionDetails = this.$('#inspectionDetails');
			this.inspectionDetails = new InspectionDetails(options);
			this.$el.children('[data-role="content"]').prepend(this.inspectionDetails.$el);
			this.$el.find('.buttonContainer').appendTo(this.inspectionDetails.$el);

			this.$buttonContainer           = this.$('.buttonContainer');
			this.$reloadProtocols           = this.$buttonContainer.children('.reloadProtocolsBtn');
			this.$finishMode                = this.$buttonContainer.children('.finishMode');
			this.$requestBundle             = this.$buttonContainer.children('.requestBundle');
			this.$note                      = this.$('#inspectionNote');
			this.$notes                     = this.$('.inspectionNotes');
			this.$notesContainer            = this.$('.notesContainer');
			this.$customFields              = this.$('#customFieldsList');
			this.$customFieldsWithSections	 = this.$('#customSectionList');
			this.$customFieldsContainer     = this.$('.customFieldsContainer');
			this.$edit                      = this.$('.edit');
			this.$assign                    = this.$('.assign');
			this.$unassign                  = this.$('.unassign');
			this.$finishInspectionContainer = this.$('#finishInspectionContainer');
			this.$deadline                  = this.$('.finishDeadline');
			this.$createExtIntContainer     = this.$('.inspection-generate-externintern-container');
			this.$createExternProtocolRadio	= this.$('#inspection-generate-extern');
			this.$createInternProtocolRadio	= this.$('#inspection-generate-intern');
			this.$noDeadline                = this.$('#inspection-deadline-disable');
			this.$groupByRadioContainer     = this.$('.inspection-generate-groupby-container');
			this.$orderByRadioContainer 	= this.$('.inspection-generate-orderby-container');
            this.$groupByNone               = this.$('#inspection-groupby-none');
            this.$groupByLocation           = this.$('#inspection-groupby-location');
            this.$groupBySubcontractor      = this.$('#inspection-groupby-subcontractor');
            this.$groupByStatus             = this.$('#inspection-groupby-status');
            this.$groupByDeadline           = this.$('#inspection-groupby-deadline');
            this.$groupBySmod               = this.$('#inspection-groupby-smod');
            this.$groupByPmod               = this.$('#inspection-groupby-pmod');
			this.$signatures                = this.$('.signatures');
			this.$finishInspection          = this.$('.finishInspection');
			this.$includeAll                = this.$('.includeAll');
			this.$archive                   = this.$('.archive');
			this.$attachedDocuments         = this.$('.attachedDocuments');
			this.$attachDocumentFromBaufoto = this.$('.attachDocumentFromBaufoto');
			this.documentIframe 			= this.$('.document-frame');
			this.documentIframeDiv			= this.$('.document-frame-div');
			this.$fileName 					= this.$('#inspectionFileName');
			this.$inspectionOtherSettings 	= this.$('#inspection-other-settings');
			this.$inspectionOtherSettingsHeader = this.$('#inspection-other-settings-header');


			window.addEventListener('message', _.bind(function (e) {
				if (!e.data || typeof e.data !== 'string') {
					return;
				}
				var data = e.data.split(':');
				switch (data.shift()) {
					case 'close':
						this.$('.christOverlay').hide();
						break;
					case 'form':
						this.$('.christOverlay').hide();
						currentInspection.set('customerProperties', JSON.parse(data.join(':')));
						$.mobile.loading('show');
						currentInspection.save()
							.then(function () { return inspections.fetch(); })
							.always(function () { $.mobile.loading('hide'); });
						break;
				}
			}, this));

			this.$el.on('pageshow', _.bind(function () {
				this.$('.buttonContainer').controlgroup('refresh');
			}, this));

			this.listenTo(this, 'attachDocumentFromBaufoto', this.handleBaufotoDocumentsData.bind(this));
			this.showManage = false;
		},

		isActionsMenuOpen: function () {
			return $('div.ui-popup-container.ui-popup-active').length > 0;
		},

		triggerActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.$actionsMenu.popup('open', { transition: 'flip', positionTo: $('.actionsTrigger') });
		},

		closeActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.$actionsMenu.popup('close', { transition: 'flip', positionTo: $('.actionsTrigger') });
		},

		render: function () {
			$('.inspection-previousPage').off('click');
			$('.inspection-previousPage').on('click', function (e) {
				if (this.mode === 'finish') {
					this.mode = 'defects';
					this.render();
					e.preventDefault();
					e.stopPropagation();
				} else {
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject()+'/inspections');
				}
			}.bind(this));
			if (this.mode === 'defects') {
				DefectsPage.prototype.render.apply(this, arguments);
				this.$content.children().not(this.$inspectionDetails).not(this.$finishInspectionContainer).not(this.$defectsLocation).not(this.$addFilterSelect).show();
				this.handleFilterSmallScreenInspection();
				if (this.filterMode === 'detail') {
					this.afterChangeFilterMode();
				} else {
					this.$actionsMenu.find('input[name=filterMode][value=' + this.filterMode + ']').trigger('change');
				}
			} else {
				this.$content.children().not(this.$inspectionDetails).not(this.$finishInspectionContainer).hide();
			}
			this.$finishInspectionContainer.toggle(this.mode !== 'defects');
			this.$buttonContainer.toggle(this.mode === 'defects');

			this.$attachDocumentFromBaufoto.toggle(!iosapp.appavailable);

			var connected = watchdog.isConnected();
			var isReadOnlyInspection = !!currentInspection.get('readOnly');
			this.$note.toggleClass(         'ui-disabled', !connected || isReadOnlyInspection);
			this.$customFields.toggleClass( 'ui-disabled', !connected || isReadOnlyInspection);
			this.$customFieldsWithSections.toggleClass( 'ui-disabled', !connected || isReadOnlyInspection);
			this.$finishMode.toggleClass(   'ui-disabled', !connected);
			this.$requestBundle.toggleClass('ui-disabled', !connected);
			this.$edit.toggleClass(         'ui-disabled', !connected || isReadOnlyInspection);
			this.$assign.toggleClass(      'ui-disabled', isReadOnlyInspection);
			this.$unassign.toggleClass(      'ui-disabled', isReadOnlyInspection);
			this.$archive.toggleClass(      'ui-disabled', !connected);

			if (!user.get('requests')) {
				this.$requestBundle.parent().remove();
			}
			if (user.isPrincipal()) {
				this.$requestBundle.remove();
			}

			if(currentInspection.get("type")) {
				var protocolsDisabled = _.findWhere(user.get("inspections"), {id: currentInspection.get("type")}).protocolGenerationDeactivated;
				this.$finishMode.toggle(!isReadOnlyInspection && !protocolsDisabled);
			}
			this.$requestBundle.toggle(!isReadOnlyInspection);


			this.$heading.text(user.translate('inspection.title'));

			$('.newDefect').toggle(!user.isReadonly() && !isReadOnlyInspection && this.mode !== 'finish');//!currentInspection.get('finished') && this.mode === 'defects');
			//this.$edit.toggle(!currentInspection.get('finished'));
			this.$finishInspection.toggle(this.mode === 'finish');
			this.$archive.prop('checked', !!currentInspection.get('archive'));//.parent().toggle(!!currentInspection.get('finished')); 2014-10-29, wacker: disabled
			this.$archive.checkboxradio().checkboxradio('refresh');

			var inspectionType = _.find(user.get('inspections'), { id: currentInspection.get('type') });

			this.$notesContainer.toggle(inspectionType && inspectionType.extras.freetext);
			this.$note
			.toggle(inspectionType && inspectionType.extras.freetext)
			//.attr('disabled', !!currentInspection.get('finished')) 2016-07-14: freetext can be always edited
			//.toggleClass('disabled', !!currentInspection.get('finished'))
			.text(currentInspection.get('freetext') || '');
			this.$includeAll.prop('checked', inspectionType && inspectionType.protocolIncludeAll).checkboxradio().checkboxradio('refresh');


			this.$customFieldsContainer.toggle(!!(inspectionType && ((inspectionType.customFields && inspectionType.customFields.length > 0))
													|| (inspectionType && (inspectionType.customFieldsWithSections && inspectionType.customFieldsWithSections.length > 0))));

			this.$customFields.empty();
			this.$customFieldsWithSections.empty();
			if(inspectionType && inspectionType.customFields) {
				this.renderCustomFields(this.$customFields, inspectionType.customFields);
				this.fillCustomValues(inspectionType.customFields);
			}

			if(inspectionType && inspectionType.customFieldsWithSections) {
				this.renderCustomFieldsWithSections(inspectionType.customFieldsWithSections);
			}

			var fileName = currentInspection.get('fileName');
			if(fileName){
				this.$fileName.val(currentInspection.get('fileName'));
			} else {
				this.$fileName.val(null);
			}

			this.$('.manage').toggle(this.showManage);

			this.renderDetails();
			this.renderSignatures();
			this.renderNotes();
			this.renderAttachedDocuments();
			this.refreshButtons();
			this.refreshFavouriteFilter(currentDefects.elementFilter.favourite, true);

			var itype =  currentInspection.getType();
			var christProtocol = !!itype && itype.external && itype.external != 'no' && user.get('protocols') && !currentInspection.isNew() && currentInspection.get('customer') === 'christ';
			this.$('.extendedProtocol').toggle(christProtocol);
			if (christProtocol) {
				this.$('.buttonContainer button').css('width', '50%');
			} else {
				this.$('.buttonContainer button').css('width', 'auto');
			}

			this.$reloadProtocols.toggle(!currentInspection.get('protocols'));
			this.$requestBundle.toggle(!!currentInspection.get('protocols'));
			this.$finishMode.toggle(!!currentInspection.get('protocols'));

			this.$createExtIntContainer.toggle(!user.isPrincipal());

			if(user.isReadonly()) this.renderReadonly();

			if(currentInspection.id && currentInspection.get('topics') === true && this.$('#defects-groupBy-topics').prop('checked') !== true) this.$('#defects-groupBy-topics').click();
			if(currentInspection.id && currentInspection.get('topics') === false && this.$('#defects-groupBy-topics').prop('checked') === true) this.$('#defects-groupBy-status').click();
		},

		handleFilterSmallScreenInspection: function () {
			if($(window).width() < 850) {
				this.$filters.children('div').children('div').hide();
				this.$addFilterSelect.show();
				this.$addFilterSelect.find('select').val('').change();
				this.$addFilterSelect.attr('shown', 'true');
				this.$('div[data-filter-title="inspection"]').attr('shown', 'true').show();
				this.$addFilterSelect.find('select').children('option[value="inspection"]').hide();
				this.$addFilterSelect.find('.hideFilters, .removeFilters').show();
				this.$addFilterSelect.find('.hideFilters').removeClass('plus').addClass('minus');
				this.$addFilterSelect.find('div.ui-select').removeClass('borderRight');
			} else if($(window).width() >= 850) {
				this.$addFilterSelect.hide();
				this.$addFilterSelect.attr('shown', 'false');
			}
		},

		renderReadonly: function () {
			this.$requestBundle.remove();
			this.$finishMode.remove();
			this.$edit.addClass('ui-disabled');
			this.$archive.parent().parent().remove();
		},

		renderDetails: function () {
			this.inspectionDetails.setModel(currentInspection);
			this.inspectionDetails.render();
		},

		renderDeadline: function () {
			this.$deadline.parent().toggle(!this.$noDeadline.is(':checked'));
		},

		toggleFavouriteDefects: function() {
			this.refreshFavouriteFilter(true, false);
			this.filter();
		},

		refreshFavouriteFilter: function(value, lockState) {
			if (!user.isPrincipal() && !user.isSubcontractor()) {
                var favouriteFilterButton = '';
                if (!currentDefects.elementFilter || value == null) {
                    favouriteFilterButton = '<button class="defects-only-favourite filter-only-favourite-false"/>';
                } else if(!lockState){
                    favouriteFilterButton = '<button class="defects-only-favourite filter-only-favourite-' + ((currentDefects.elementFilter.favourite) ? 'false' : 'true') + '"/>';
                } else {
                    favouriteFilterButton = '<button class="defects-only-favourite filter-only-favourite-' + ((currentDefects.elementFilter.favourite) ? 'true' : 'false') + '"/>';
                }
                this.$heading.html(favouriteFilterButton + user.translate('inspection.title'));
			}
		},

		renderCustomFields: function(parent, customFields) {

			customFields.forEach(_.bind(function (customField) {

				switch(customField.type) {
					case 'Boolean':
						parent
							.append($('<div class="checkbox">')
								.append($('<label class="ui-btn ui-corner-all" style="text-align: left">')
									.append($('<input data-id="'+customField.key+'" class="customField" type="checkbox" >'))
									.append(customField.label))
							).trigger('create');
						break;
					case 'Datetime':
						parent.append($('<div style="text-align:left;line-height: 2.5em;">').text(customField.label))
							.append($('<input data-id="'+customField.key+'" type="date" class="ui-input-text customField ui-corner-all" data-wrapper-class="controlgroup-textinput ui-btn" style="width: 100%" placeholder="'+customField.type+'">'))
							.trigger('create');
						break;
					case 'Note':
						parent.append($('<div style="text-align:left;line-height: 2.5em;">').text("" + customField.label))
							.trigger('create');
						break;
					case 'String':
						parent.append($('<div style="text-align:left;line-height: 2.5em;">').text(customField.label))
							.append($('<input data-id="'+customField.key+'" type="text" class="ui-input-text customField ui-corner-all" data-wrapper-class="controlgroup-textinput ui-btn" style="width: 100%" placeholder="'+customField.type+'" >'))
							.trigger('create');
						break;
					case 'Text':
						parent.append($('<div style="text-align:left;line-height: 2.5em;">').text(customField.label))
							.append($('<textarea data-id="'+customField.key+'" rows="5" class="ui-input-text customField ui-corner-all" data-wrapper-class="controlgroup-textinput" placeholder="'+customField.type+'">'))
							.trigger('create');
						break;
				}

			}, this));
		},
		renderCustomFieldsWithSections: function(customFieldsWithSections) {
			customFieldsWithSections.forEach(_.bind(function (customSection) {

				var $section = $('<div class="inspection-section">'
					+ '<div class="inspection-section-header" style="display:inline;">' + '<div class="inspection-section-title">'+ customSection.name + '</div>'
					+ '<span class="arrow down" style="float:right;"></span>'
					+ '<p style="height: 24pt;margin: 0px; background: #ccc"></p>' + '</div>'
					+ '<div class="inspection-section-content"></div>'
					+ '</div>');

				$section.find(".inspection-section-header").click(_.bind(function () {
						$section.children().slice(1).toggle();
						if ($section.find("span.arrow").hasClass("down")) {
							$section.find("span.arrow").addClass("next").removeClass("down");
						} else {
							$section.find("span.arrow").addClass("down").removeClass("next");
						}
						}));
				this.$customFieldsWithSections.append(
					$section
				).trigger('create');

				this.renderCustomFields($section.find(".inspection-section-content"), customSection.customFields);
				this.fillCustomValues(customSection.customFields);
			}, this));
		},

		renderSignatures: function () {
			var itype =  currentInspection.getType();
			if ((currentProject.get('signatureSetting') === '' && user.attributes.settings.signatures === "WITHOUT" || currentProject.get('signatureSetting') === 'WITHOUT') && !(itype && itype.forceSignatureFields)){
				this.$signatures.hide();
			} else if (this.mode === 'finish') {
				var participants = currentInspection.get('participants');
				this.$signatures.empty();
				_.each(participants, function (participant, index) {
					var $div = $('<div class="ui-field-contain"><div class="sketcher" data-enhance="false" data-index="' + index + '"></div></div>').prepend($('<label/>').text(participant.role + ' (' + participant.name + ')')).appendTo(this.$signatures);
					var $sketcher = $div.find('.sketcher');
					var sketcher = new Sketcher({
						el:    $sketcher,
						size:  { width: 450, height: 150 },
						color: 'navy',
						mode:  'single'
					}).render();
					$sketcher.data('sketcher', sketcher);
					//sketcher.setModel({annotation: participant.signature}); 2015-03-11 wacker: no more stored signatures
					}, this);
			}
			this.renderResponsiblePersons();
		},

		renderAttachedDocuments: function () {
			var attachedDocuments = currentInspection.get('attachedDocuments');
			this.$attachedDocuments.empty();
			$('.attachedDocsHeader').toggle(!!attachedDocuments && attachedDocuments.length > 0);
			if (!attachedDocuments) return;
			this.$attachedDocuments.append(_.map(attachedDocuments, _.bind(function (doc, index) {
				var li = $('<li data-clear-btn="true" class="protocol-attachedDocuments">');
				li.append($('<div class="ui-btn">').text(doc.title)).on('vclick', _.bind(function () {
					e.preventDefault();
					e.stopPropagation();
					let data = {
						url: doc.url,
						name: doc.title
					}
					if(iosapp.appavailable) {
						iosapp.call("showPDF", data, null);
					} else {
						window.open(doc.url, '_blank');
					}
				}, this));
				li.append($('<button class="remove" data-enhance="false">').text(user.translate('files.remove')).on('vclick', _.bind(function (e) {
					e.preventDefault();
					e.stopPropagation();
					confirmPopup(user.translate('files.remove.confirm').replace('%s', doc.title)).then(_.bind(function () {
						$.mobile.loading('show');
						var docs = currentInspection.get("attachedDocuments").slice();
						docs.splice(index, 1);
						currentInspection.set("attachedDocuments", docs);
						this.handleBaufotoDocumentsData(docs);
						$.mobile.loading('hide');
					}, this));
				}, this)));
				return li;
			}, this)));
			this.$('ul').listview().listview('refresh');
		},

		refreshButtons: function () {
			var	$buttonContainer = this.$buttonContainer.children('.ui-controlgroup-controls');
			//2014-10-31, wacker: protocol creation always possible
			//var isFinished = !!currentInspection.get('finished'),
			//if (!isFinished) {
			//2014-11-27, wacker: only if in user config
			var itype =  currentInspection.getType();

			if (itype && user.get('protocols')) {
				if (itype.external && itype.external != 'no' || user.isPrincipal()){
					this.$createExternProtocolRadio.trigger('click').checkboxradio("refresh");
				} else if (itype.external && itype.external == 'no') {
					this.$createInternProtocolRadio.trigger('click').checkboxradio("refresh");
				}
			}
			this.$buttonContainer.controlgroup('refresh');
			this.$buttonContainer.toggle(!!$.trim($buttonContainer.html()));

			var groupBy = currentInspection.get('groupBy');
			if (!groupBy) {
			    groupBy = 'none';
			}
			$('input[name="inspection-groupby"]', this.$groupByRadioContainer).each(function(idx, e) {
			    if (e.value === groupBy) {
			        $(e).trigger('click').checkboxradio("refresh");
			    }
			});


			var orderBy = currentInspection.get('orderBy');
			if (!orderBy) {
				orderBy = 'numberasc';
			}
			$('input[name="inspection-orderby"]', this.$orderByRadioContainer).each(function (idx, e) {
				if (e.value === orderBy) {
					$(e).trigger('click').checkboxradio("refresh");

				}
			});

			this.$includeAll.prop('checked', currentInspection.get('includeAll')).checkboxradio("refresh");
			if(currentInspection.get('topics') === true && groupBy === 'none') {
				this.$('#inspection-groupby-topics').click().checkboxradio("refresh");
				this.$includeAll.prop('checked', true).checkboxradio("refresh");
			}
		},

		requestBundle: function (e) {
				e.preventDefault();
				e.stopPropagation();

				var defectCount = _(currentDefects.models)
                .filter(function (model) {
                    return _.contains(model.get('inspections'), currentInspection.id);
                })
                .size();

				if (defectCount == 0) {
					alertPopup(user.translate('inspection.nodefects'));
					return;
				}

				$.mobile.loading('show');
				this.router.requestBundlePage.setModel(currentInspection);
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/requestBundle');
		},

		reload: function (e) {
			e.preventDefault();
			e.stopPropagation();
			currentInspection.fetch().done(_.bind(function () {
				console.log(this)
				this.render();
			}, this));
		},

		finishInspectionMode: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.mode = 'finish';
			this.$deadline.val(new Date(+new Date() + 1000 * 60 * 60 * 24 * 14).toISOString().substring(0, 10));
			this.render();
			this.renderDeadline();
		},

		finishInspection: function (e, send) {
			e.preventDefault();
			e.stopPropagation();
			currentInspection.set('customerProperties',this.customFieldsToJson());
            if (this.$fileName.val().length > 0) {
                currentInspection.set('fileName', this.$fileName.val());
            } else {
                currentInspection.set('fileName', null);
            }
			var text = '';
			if (user.get('settings').signatures === 'DEVICE' && currentInspection.get('participants').length) {
				text += ' ' + user.translate('inspection.signaturesready');
			}
			confirmPopup(user.translate('inspection.confirmfinish') + text).then(_.bind(function () {
				$.mobile.loading('show');
				currentInspection.finish(this.inputAttributes(), this.$includeAll.is(':checked'), !!send, this.responsible)
				.then(_.bind(function () {
					var result = $.Deferred();
					if (send) {
						$.mobile.loading('hide');
						var sendStatus = currentInspection.get('sendStatus');
						if (sendStatus.success) {
							alertPopup(user.translate('protocol.sent')).done(_.bind(function () {
								result.resolve();
							}, this));
						} else {
							if (sendStatus.failedAddresses.length == 1 && !sendStatus.failedAddresses[0]) {
								alertPopup(user.translate('inspection.finishnorecipients'))
								.done(function () {
										result.resolve();
								});
							} else {
								alertPopup(user.translate('protocol.notsent').replace('%s', sendStatus.failedAddresses.join('\n')))
								.done(function () {
									result.reject();
								});
							}
						}
					} else {

						result.resolve();
					}
					return $.when(
						result,
						inspections.fetch(),
						currentDefects.fetch()
					);
				}, this))
				.done(_.bind(function () {
					inspections.get(currentInspection.id).set(currentInspection.attributes, { silent: true });
					window.inspectionUseEffect(currentInspection.id);
				}, this))
				.fail(function () {
					alertPopup(user.translate('inspection.finishfailed'));
					$.mobile.loading('hide');
				});
			}, this));
		},

		sendInspection: function (e) {
			this.finishInspection(e, true);
		},

		changeSignatures: function (e) {
			var $sketcher = $(e.target);
			var index = parseInt($sketcher.attr('data-index'));
			var participants = currentInspection.get('participants').slice();
			participants.splice(index, 1, {...participants[index], signature:$sketcher.data('sketcher').getCurrentAttachment().annotation});
			currentInspection.set('participants', participants);
			this.change();
		},

		changeArchive: function () {
			var state = this.$archive.is(':checked');
			if (state) {
				popup({
//					header: '',
					message: user.translate('inspection.archive.changestatus.confirm'),
					buttons: [{
						text: user.translate('inspection.archive.changestatus.showpending'),
						event: 'showOpen'
					}, {
						text: user.translate('inspection.archive.changestatus.no'),
						event: 'no'
					}, {
						text: user.translate('inspection.archive.changestatus.yes'),
						event: 'yes'
					}]
				})
				.on('yes', _.bind(function () {
					var deferreds = [];
					_(currentDefects.models)
					.filter(function (model) {
						return _.contains(model.get('inspections'), currentInspection.id);
					})
					.each(function (model) {
						model.set('status', 'irrelevant');
						deferreds.push(model.save());
					});
					$.when.apply(null, deferreds)
					.done(_.bind(function () {
						currentInspection.set('archive', state);
						this.change();
						alertPopup(user.translate('inspection.archive.changestatus.done'));
					}, this))
					.fail(function () {
						alertPopup(user.translate('inspection.archive.changestatus.failed'));
					});
				}, this))
				.on('no', _.bind(function () {
					currentInspection.set('archive', state);
					this.change();
				}, this))
				.on('showOpen', _.bind(function () {
					this.$statusFilter.val('pending').trigger('change').selectmenu('refresh');
				}, this));
			} else {
				currentInspection.set('archive', state);
				this.change();
			}
		},

		addNoteOnEnter: function(e) {
			if (e.which !== 13) return;
			this.addNote();
		},

		addNote: function() {
			var notes = currentInspection.get('freetext');
			var toAddValue = this.$note.val();
			if (!toAddValue || !toAddValue.trim()) return;
			if (!notes) {
				notes = toAddValue;
			} else {
				notes = notes + '\n' + toAddValue;
			}
			currentInspection.set('freetext', notes);
			this.$note.val("");
			this.change();
			this.renderNotes();
		},

		renderNotes: function () {
			var notes = currentInspection.get('freetext');

			this.$notes.empty();
			$('.inspectionNotes').toggle(!!notes);
			if (!notes) return;
			this.$notes.append(_.map(notes.split('\n'), _.bind(function (note, index) {
				var li = $('<li data-clear-btn="true">');
				li.append($('<input type="text" class="ui-btn-inline note-input">').val(note).on('focusout', _.bind(function (e){
					e.preventDefault();
					e.stopPropagation();
					var notes = currentInspection.get('freetext');
					var notesArray = notes.split("\n");
					notesArray[index] = $(e.currentTarget).val()
					currentInspection.set('freetext', notesArray.join("\n"));
					this.change();
					this.renderNotes();
				},this)));
				li.append($('<button class="remove" data-enhance="false">').text(user.translate('files.remove')).on('vclick', _.bind(function (e) {
					e.preventDefault();
					e.stopPropagation();
						$.mobile.loading('show');
						var notesAsList = currentInspection.get("freetext").split('\n');
						notesAsList.splice(index, 1);
						currentInspection.set("freetext", notesAsList.join('\n'));
						this.change();
						this.renderNotes();
						$.mobile.loading('hide');
				}, this)));
				return li;
			}, this)));
			this.$notes.listview().listview('refresh');
		},

		changeFreetext: function () {
			currentInspection.set('freetext', this.$note.val());
			this.change();
		},

		inputAttributes: function () {
			return {
				freetext: currentInspection.get('freetext'),
				deadline: !this.$noDeadline.is(':checked') ? this.$deadline.val() : null,
				useExternalIDs: this.$createExternProtocolRadio.is(':checked') ? true : false,
				groupBy: $('input[name="inspection-groupby"]:checked', this.$groupByRadioContainer).val(),
				orderBy: $('input[name="inspection-orderby"]:checked', this.$orderByRadioContainer).val(),
				includeAll: this.$includeAll.is(':checked')
			};
		},

		deleteAttachedDocuments: function () {
			this.handleBaufotoDocumentsData([]);
		},

		closeDocumentIframeDiv: function () {
			this.documentIframeDiv.hide();
			this.documentIframe.attr('src', '');
			window.localStorage.removeItem(window.name);
		},

		attachDocumentFromBaufoto: function () {
			this.documentIframeDiv.show();
			this.documentIframe.attr('src', window.location.origin + '/baufoto/' + '#client/general/documents/' + currentInspection.get('customer') + '-' + currentInspection.get('project'));
			window.localStorage.setItem(window.name, 'attachDocument');
		},

		attachDocumentFromLocalStorage: function () {
		    $('.attachDocumentToInspectionForm input').click();
			this.render();
		},

		uploadDocument: function () {
			$('.uploadingInspectionAttachmentDialog').show().popup('open');
			$('.uploadingInspectionAttachmentDialog .spinner-percent').text('');
			var form = this.$('.attachDocumentToInspectionForm')[0];
			var file = this.$('.attachDocumentToInspectionForm input')[0].files[0];
			if (file.size > 200 * 1024 * 1024) {//50 * 1024 * 1024) {
				alertPopup(user.translate('files.upload.limit'));
				form.reset();
				return;
			}
			var uploadData = new FormData();
			uploadData.append('projectId', currentProject.id);
			uploadData.append('inspectionId', currentInspection.id);
			uploadData.append('file', file);

			if (file.type.toLowerCase() !== 'application/pdf') {
				alertPopup(user.translate('files.onlypdf'));
				form.reset();
				return;
			}

			this.ajaxUploadDocumentCall = Backbone.ajax({
				url:  '/onlineBauabnahme/api/uploadInspectionAttachment',
				type: 'POST',
				data: uploadData,
				contentType: false,
				processData: false,
				xhr: _.bind(function () {
					var xhr = new window.XMLHttpRequest();
					//Upload progress
					xhr.upload.addEventListener("progress", _.bind(function (evt) {
						if (evt.lengthComputable) {
							var percentComplete = evt.loaded / evt.total;
							$('.uploadingInspectionAttachmentDialog .spinner-percent').text(numeral(percentComplete).format('0%'));
						}
					}, this), false);
					return xhr;
				}, this)
			}).done(_.bind(function (result) {
				if (result && result.localUUID) {
					alert('Die Datei wurde hochgeladen.');

					var data = [{title:      result.name,
							link:         '/onlineBauabnahme/api/downloadFile?project=' + window.encodeURIComponent(currentProject.id) + '&path=' + window.encodeURIComponent('_protocols/' + currentInspection.id + '/attachments/' + result.localUUID + '.pdf'),
							localUUID:   result.localUUID
					}];

					this.addToAttachedDocsList(data);
				} else {
					alertPopup(user.translate('files.upload.failed').replace('%s', user.translate('files.upload.checktype')));
				}
			}, this)).fail(function (jqXHR, textStatus, errorThrown) {
				if(errorThrown == "abort") {return;} // fail silently on user-cancel
				if(jqXHR.status == 400) {errorThrown = user.translate('files.onlypdf')}
				alertPopup(user.translate('files.upload.failed').replace('%s', errorThrown));
			}).always(function () {
				$('.uploadingInspectionAttachmentDialog').popup('close');
				form.reset();
			});
		},

		cancelInspectionAttachmentUpload: function(){
			$('.uploadingInspectionAttachmentDialog').popup('close');
			this.ajaxUploadDocumentCall.abort();
		},

		addToAttachedDocsList: function(data){
			var currentData = (currentInspection.get('attachedDocuments') || []).slice();
			var currentDataLinks = _.map(currentData, function (item) {
				return item.link;
			})
			if (!data) return;
			data.forEach(function (item) {
				if (currentDataLinks.indexOf(item.link) !== -1) return;
				currentData.push(item);
			});
			currentInspection.set('attachedDocuments', currentData || []);
			this.saveAndRenderAttachedDocuments();

		},

		handleBaufotoDocumentsData: function(data) {
			this.addToAttachedDocsList(data);
			window.localStorage.removeItem(window.name);
		},

		saveAndRenderAttachedDocuments: function() {
			this.change();
			this.renderAttachedDocuments();
		},

		change: function () {
			$.mobile.loading('show');
			currentInspection.save(this.inputAttributes())
			.then(_.bind(function () {
				return inspections.fetch();
			}, this))
			.always(function () {
				$.mobile.loading('hide');
			});
		},

		customFieldsToJson: function(){
			var customerProperties = {};
			$('.customField').toArray().forEach(function (field) {
				if($(field).attr('type') === 'checkbox') {
					customerProperties[$(field).attr('data-id')] = $(field).prop('checked');
				} else {
					customerProperties[$(field).attr('data-id')] = $(field).val();
				}

			})
			return customerProperties;
		},

		internalParseBoolean: function(str) {
	        if (!str) {
	            return false;
	        }
	        var lc = str.toLowerCase().trim();
	        return (str === '1' || lc === 'true' || lc === 'yes' || lc === 'ja');
	    },

		fillCustomValues: function (customFields) {
			var defaultValues = {};
			_.each(customFields, function (customField) {
				defaultValues[customField.key] = customField.defaultValue;
			});

			var customerProperties = currentInspection.get('customerProperties');
			$('.customField').toArray().forEach(_.bind(function (field) {
				if($(field).attr('type') === 'checkbox') {
					$(field).prop('checked', customerProperties[$(field).attr('data-id')] != null ?  customerProperties[$(field).attr('data-id')] : this.internalParseBoolean(defaultValues[$(field).attr('data-id')]) ).checkboxradio().checkboxradio('refresh');
				} else {
				    	$(field).val(customerProperties[$(field).attr('data-id')] != null ?  customerProperties[$(field).attr('data-id')] : (defaultValues[$(field).attr('data-id')] ? defaultValues[$(field).attr('data-id')] : ''));
				}

			}, this))
		},

		toggleOtherSettings: function() {


			this.$inspectionOtherSettings.toggle();
			if(this.$inspectionOtherSettings.is(':visible')){
				this.$inspectionOtherSettingsHeader.text( user.translate('inspection.othersettings.hide'));
			}
			else {
				this.$inspectionOtherSettingsHeader.text(user.translate('inspection.othersettings.show'));
			}
		},

		renderResponsiblePersons: function () {
			this.responsible = null;
			const responsiblePersons = currentProject.get('responsible');
			const $responsibleContainer = this.$('.projectResponsiblePerson');
			const $uiSelect = $responsibleContainer.find('.ui-select');

			if (!responsiblePersons) {
				$uiSelect.hide();
				return;
			}

			const personsArray = responsiblePersons.split(/[;,]+/).map(person => person.trim());

			if (personsArray.length === 1) {
				$uiSelect.hide();
				return;
			}

			const $select = $responsibleContainer.find('select').empty();
			$("<option>").text(user.translate('inspection.responsible.select.title')).val(null).appendTo($select);

			_.each(personsArray, person => {
				$("<option>").text(person).val(person).appendTo($select);
			});

			$uiSelect.show();
			$select.val(null).selectmenu('refresh');
		},

		selectResponsiblePerson: function (e) {
			this.responsible = e.target.value && e.target.value.trim();
		}
	});

});
