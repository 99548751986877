define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<h2></h2>\n" +
        "\n" +
        "<table></table>\n" +
        "\n" +
        "<ul data-role=\"listview\" data-inset=\"true\" class=\"protocols checkboxList ui-corner-all\" style=\"margin-bottom: 0\"></ul>\n" +
        "\n" +
        "<p class=\"externalWarning\"><%= t('inspection.protocols.externalhint') %></p>";
    return page;
});