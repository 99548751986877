import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {useLocation, useNavigate} from "react-router-dom";
import {getCustomerProjectWithPrefix, getProjectIdFromCustomerProject, translate} from "../utils/utils";
import {page} from "../utils/constants";


const BuyerAccess = () => {

    const navigate = useNavigate();
    const {customerProject} = getCustomerProjectWithPrefix();
    const project = getProjectIdFromCustomerProject(customerProject);
    const {state} = useLocation();

    useEffect(()=>{
        if(!state || !state.noUseEffect) {
            // @ts-ignore
            window.buyerAccessUseEffect(project);
        }
        // @ts-ignore
        !!state && !!state.callback && !!window.showPageCallback && window.showPageCallback();
        // @ts-ignore
        window.showPageCallback = null;

    },[])

    const goToProject = ()=>{
        navigate(`${customerProject}/settings`,{state});
    }


    return <PageContainer pageId={page.BUYER_ACCESS} newPage={false} headerText={translate("settings.buyeraccess.title")}
                          left={<StyledButton onClick={goToProject}>{translate("settings.buyerroles.backtoaccess")}</StyledButton>}
    />
}

export default BuyerAccess;