import React, {useState} from 'react';
import {Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField} from '@mui/material';
import {
    CustomizedInternPaper,
    InternPartDivider,
    InternPartTitle,
    SettingsButtonGroup,
    SettingsDescription
} from "../SettingsComponents";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {translate} from "../../../utils/utils";
import EmailPreviewDialog from "./EmailPreviewDialog";
import {EMAIL_TYPES, EmailCustomConfiguration, EmailType} from "./types/types";
import {useEmailConfiguration} from "./hooks/useEmailConfiguration";
import {useEmailPreview} from "./hooks/useEmailPreview";
import {getDefaultEmailBody, getDefaultEmailSubject} from "./emailDefaults";
import {useEmailFieldHandlers} from "./hooks/useEmailFieldHandlers";
import {useEmailDefaults} from "./hooks/useEmailDefaults";
import EmailCustomizerHelp from "./EmailCustomizerHelp";

type EmailCustomizerProps = {
    updateProjectSettings: (settings: { emailCustomConfiguration: EmailCustomConfiguration }) => void;
    setRef: (name: string, node: HTMLElement | null | unknown) => void;
    newProjectSettings: { emailCustomConfiguration: EmailCustomConfiguration };
    isResetTriggered: boolean;
}

const EmailCustomizer = ({
                             newProjectSettings,
                             updateProjectSettings,
                             setRef,
                             isResetTriggered
                         }: EmailCustomizerProps) => {

    const [selectedEmailType, setSelectedEmailType] = useState<EmailType>();
    const [emailConfig, updateEmailConfig] = useEmailConfiguration(newProjectSettings.emailCustomConfiguration, updateProjectSettings, isResetTriggered);
    const [previewOpen, setPreviewOpen, emailPreview, handlePreview] = useEmailPreview(emailConfig);
    const [handleSubjectChange, handleBodyChange] = useEmailFieldHandlers(updateEmailConfig, selectedEmailType)
    const [handleEditDefaultSubject, handleEditDefaultBody] = useEmailDefaults(updateEmailConfig, selectedEmailType);
    const [showEmailCustomizerHelp, setShowEmailCustomizerHelp] = useState<boolean>(false);

    return (
        <CustomizedInternPaper aria-details={`${translate("settings.project.title")}`}
                               ref={node => setRef('projectSettingsEmailCustomizer', node)}>

            <Stack aria-details={"notSearchable"} flexDirection={"row"} spacing={1} alignItems={"center"}>
                <InternPartTitle>{translate("settings.emailCustomizer.title")}</InternPartTitle>
                <IconButton onClick={() => setShowEmailCustomizerHelp(true)}
                            style={{width: 'auto', marginTop: '0', backgroundColor: 'white'}}>
                    <InfoOutlinedIcon sx={{position: 'absolute', left: '.1rem', bottom: '.3rem'}}/>
                </IconButton>

                <EmailCustomizerHelp open={showEmailCustomizerHelp} onClose={() => setShowEmailCustomizerHelp(false)} />

            </Stack>
            <SettingsDescription>{translate("settings.emailCustomizer.description")}</SettingsDescription>
            <InternPartDivider/>

            <Box ref={node => setRef('projectSettingsEmailCustomizer', node)}>
                <FormControl variant="filled">
                    <InputLabel>{translate("settings.emailCustomizer.type.select")}</InputLabel>
                    <Select
                        onChange={e => setSelectedEmailType(e.target.value as EmailType)}
                        value={selectedEmailType || ''}>
                        {EMAIL_TYPES.map((emailType, index) => (
                            <MenuItem key={index}
                                      value={emailType}>
                                {translate(`settings.emailCustomizer.type.${emailType}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {selectedEmailType && (
                    <Box>
                        <TextField
                            sx={{marginTop: '.3rem'}}
                            label={translate("settings.emailCustomizer.email.subject.title")}
                            rows={1}
                            value={emailConfig[selectedEmailType].subject || ''}
                            onChange={e => handleSubjectChange(e.target.value)}
                            placeholder={getDefaultEmailSubject(selectedEmailType)}
                            variant="filled"
                            fullWidth
                        />

                        <TextField
                            sx={{marginTop: '.3rem'}}
                            label={translate("settings.emailCustomizer.email.body.title")}
                            multiline
                            rows={10}
                            value={emailConfig[selectedEmailType].body || ''}
                            onChange={e => handleBodyChange(e.target.value)}
                            placeholder={getDefaultEmailBody(selectedEmailType)}
                            variant="filled"
                            fullWidth
                        />

                        <SettingsButtonGroup>
                            <Button onClick={() => handlePreview(selectedEmailType)}>
                                {translate("settings.emailCustomizer.preview.title")}
                            </Button>
                            <Button onClick={handleEditDefaultSubject}>
                                {translate("settings.emailCustomizer.email.defaultSubject.title")}
                            </Button>
                            <Button onClick={handleEditDefaultBody}>
                                {translate("settings.emailCustomizer.email.defaultBody.title")}
                            </Button>
                        </SettingsButtonGroup>

                        <EmailPreviewDialog open={previewOpen} onClose={() => setPreviewOpen(false)}
                                            emailPreview={emailPreview}/>
                    </Box>

                )}
            </Box>
        </CustomizedInternPaper>
    );
};

export default EmailCustomizer;
