define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2',
	'../helper/interactiveSync', '../model/proxyInfo'
], function (
	$, _, Backbone,
	interactiveSync, proxyInfo
) {
	var ProjectFile = Backbone.Model.extend({

		idAttribute: 'path',
		visibleToSub: '0',

		url: function () {
		    if (this.id) {
			     return  '/onlineBauabnahme/api/files?project=' + encodeURIComponent(this.project) + '&path=' + (this.id ? encodeURIComponent(this.id) : '');
            } else {
                //use a separate enty point to support caching in the app properly
                 return  '/onlineBauabnahme/api/filesAll/' + this.project;
            }
		},

		initialize: function (attributes, options) {
			this.on('sync', this.preload, this);
			this.on('change:children', this.enhance, this);
			if (options) {
				this.project = options.project;
				this.parent  = options.parent;
			}
			this.enhance();
		},

		sync: function (method, model, options) {
			return interactiveSync.sync.apply(interactiveSync, [method, model, proxyInfo.syncAddHeaders(options)]);
		},

		setProject: function (project) {
			if (project == this.project) {
				if (this.pending) {
					return this.pending;
				} else {
					return $.Deferred().resolve();
				}
			}
			var oldProject = this.project;
			this.project = project;
			this.clear({ silent: true });
			if (!project) {
				return $.Deferred().resolve();
			}
			this.pending = this.fetch()
			.fail(_.bind(function () {
				this.project = oldProject;
			}, this))
			.always(_.bind(function () {
				delete this.pending;
			}, this));
			return this.pending;
		},

		isLoaded: function () {
			switch (this.get('type')) {
			case 'Image':  return !!this.get('hash');
			case 'Folder': return !!this.get('children');
			}
			return false;
		},

		isOfflineAvailable: function () {
			if (this.get('type') == 'Image' && !this.get('tiles')) {
				return false;
			}
			return this.isLoaded();
		},

		load: function () {
			if (this.isLoaded()) {
				return $.Deferred().resolve();
			}
			return this.fetch();
		},

		enhance: function () {
			if (this.get('type') == 'Folder') {
				var children = this.get('children');
				if (children) {
					this.set({
						children: new (Backbone.Collection.extend({
							model: ProjectFile
						}))(children, {
							project: this.project,
							parent:  this
						})
					}, { silent: true });
				}
			}
		},

		getPath: function () {
			if (!this.parent) {
				return '';
			} else {
				return this.parent.getPath() + ' / ' + this.get('name');
			}
		},

		preload: function () {
			//deactivate this. It leads under some circumstances to preloading all rendered files.
			return;

			if (!this.isLoaded()) {
				return;
			}
			switch (this.get('type')) {
			case 'Image':
				_.each(this.get('tiles'), function (tile) {
					(new Image()).src =  '/tiles/' + this.get('hash') + '/' + tile;
				}, this);
				break;
			case 'Folder':
				this.get('children').forEach(function (item) {
					item.preload();
				});
				break;
			}
		},

		findFile: function (hash) {
			return this.get('hash') === hash ? this : _.reduce(((this.get('children') || {}).models || {}), function (value, child) {
				return value ? value : child.findFile(hash);
			}, null, this);
		}

	});
	return ProjectFile;
});