define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" data-position=\"fixed\" style=\"position: fixed; display: flex; width: 100%; justify-content: space-between;\">\n" +
        "  <!-- <div style=\"display: flex; flex-direction: row; align-items: center; justify-content: flex-start;\"> -->\n" +
        "  <!-- <div class=\"ui-btn-left ui-btn-inline\"></div> -->\n" +
        "  <!-- <div style=\"display: flex; flex-direction: row; align-items: center; justify-content: space-between;\"> -->\n" +
        "  <div style=\"flex: 1; align-items: center; justify-content: center;\">\n" +
        "    <button data-shortcut=\"leftMenu\"\n" +
        "      class=\"menutrigger ui-btn ui-btn-left ui-btn-inline ui-icon-bars ui-btn-icon-notext ui-corner-all\"></button>\n" +
        "    <!-- </div> -->\n" +
        "    <!-- <div style=\"display: flex; flex-direction: row; align-items: center; justify-content: space-between; margin-left: 3rem;\"> -->\n" +
        "    <button class=\"previousPage ui-btn ui-btn-inline ui-btn-icon-left ui-icon-back ui-corner-all\"\n" +
        "      style=\"left: 5px; width: fit-content; margin-left: 3rem\">\n" +
        "      <%= t('goback') %>\n" +
        "    </button>\n" +
        "  </div>\n" +
        "  <!-- </div> -->\n" +
        "  <!-- </div> -->\n" +
        "  <div style=\"flex: 1; display: flex; align-items: center; justify-content: center;\">\n" +
        "    <h1 data-role=\"heading\" style=\"font-size: 1.2em; text-align: center;\">\n" +
        "      <%= t('settings.title') %>\n" +
        "    </h1>\n" +
        "  </div>\n" +
        "  <div class=\"save-buttons\"\n" +
        "    style=\"display: flex; flex-direction: row-reverse; flex: 1; margin: 0 0.5em; \">\n" +
        "    <button class=\"saveChanges\" style=\"width: min-content;\" disabled=\"true\">\n" +
        "      <%= t('settings.noChangesDetected') %>\n" +
        "    </button>\n" +
        "    <button class=\"resetChanges\" style=\"width: min-content;\">Reset</button>\n" +
        "  </div>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\" id=\"new-settings-style\">\n" +
        "  <div style=\"margin: 1rem 1rem\">\n" +
        "    <div id=\"searchbar\" class=\"search-bar\">\n" +
        "      <input type=\"text\" id=\"search\" placeholder=\"<%= t('settings.search') %>\" class=\"searchInput\" />\n" +
        "    </div>\n" +
        "    <div id=\"nosearchresults\" style=\"display: none; align-items: center; justify-content: center\">\n" +
        "      <%= t('settings.nosearchresults') %>\n" +
        "    </div>\n" +
        "    <div id=\"userSettings\" class=\"settings-section\">\n" +
        "      <div class=\"settings-section-heading\">\n" +
        "        <h2 class=\"settings-section-header\">\n" +
        "          <%= t('settings.user.title') %>\n" +
        "        </h2>\n" +
        "      </div>\n" +
        "      <div class=\"settings-section-basic\">\n" +
        "        <div class=\"settingsItem settings-item\" tags=\"language, sprache\">\n" +
        "          <div class=\"settings-item-top\">\n" +
        "            <h3 class=\"languageTitle settings-item-label\" style=\"margin: 0.5em 0.5em; align-items: flex-start;\">\n" +
        "              <%= t('settings.language.title') %>\n" +
        "            </h3>\n" +
        "            <fieldset data-role=\"controlgroup\" data-type=\"horizontal\" class=\"settings-item-content\"\n" +
        "              style=\"display: flex; flex-direction: column-reverse\">\n" +
        "              <div class=\"settings-item-content\" style=\"flex-direction: row\">\n" +
        "                <select name=\"settings-language\" id=\"settings-language\">\n" +
        "                  <option value=\"EN\">English</option>\n" +
        "                  <option value=\"ES\">Español</option>\n" +
        "                  <option value=\"FR\">Français</option>\n" +
        "                  <option value=\"DE\">Deutsch</option>\n" +
        "                  <option value=\"PL\">Polski</option>\n" +
        "                  <option value=\"BG\">български</option>\n" +
        "                  <option value=\"ZH\">中文</option>\n" +
        "                </select>\n" +
        "                <button class=\"applylang\">\n" +
        "                  <%= t('settings.setlang.apply') %>\n" +
        "                </button>\n" +
        "              </div>\n" +
        "              <!-- <legend class=\"settings-item-content-hint\">\n" +
        "              <%= t('settings.setlang') %><br /><i>Choose the language of the user interface.</i>\n" +
        "            </legend> -->\n" +
        "            </fieldset>\n" +
        "          </div>\n" +
        "          <div class=\"settings-item-bottom\">\n" +
        "            <div class=\"settings-item-content-hint\">\n" +
        "              <%= t('settings.setlang') %><br /><i>Choose the language of the user interface.</i>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "\n" +
        "        <div class=\"settings-subsection\">\n" +
        "\n" +
        "        </div>\n" +
        "        <div class=\"settings-subsection\">\n" +
        "          <div class=\"settings-subsection-heading\">\n" +
        "            <h3 class=\"settings-subsection-header\">\n" +
        "              <%= t('settings.user.notifications.title') %>\n" +
        "            </h3>\n" +
        "          </div>\n" +
        "\n" +
        "          <div class=\"settingsItem settings-item settings-item-boolean ui-field-contain noSub statsSettingsContainer\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <!--\t\t\t\t<label for=\"settings-showstatsinoverview\"><%= t('settings.showstatsinoverview') %></label>-->\n" +
        "                <!--\t\t\t\t<input type=\"checkbox\" name=\"settings-showstatsinoverview\" id=\"settings-showstatsinoverview\"/>-->\n" +
        "                <label class=\"sendstatisticsemails-elements\" for=\"settings-sendstatisticsemails\">\n" +
        "                  <%= t('settings.sendstatisticsemails') %>\n" +
        "                </label>\n" +
        "                <input class=\"sendstatisticsemails-elements\" type=\"checkbox\" name=\"settings-sendstatisticsemails\"\n" +
        "                  id=\"settings-sendstatisticsemails\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <!-- <div class=\"settings-item-content-hint\">\n" +
        "                <%= t('settings.sendstatisticsemails.hint') %>\n" +
        "              </div> -->\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div\n" +
        "            class=\"settingsItem settings-item settings-item-boolean ui-field-contain noSub noReadonly notifychanges-container\"\n" +
        "            style=\"flex-direction: column\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-notifyIfAnyDefectChanged\">\n" +
        "                  <%= t('settings.changes.notifychanges') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-notifychanges\" id=\"settings-notifyIfAnyDefectChanged\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <!-- <div class=\"settings-item-content-hint\">\n" +
        "                <%= t('settings.changes.notifychanges.hint') %>\n" +
        "              </div> -->\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div\n" +
        "            class=\"settingsItem settings-item settings-item-boolean ui-field-contain noSub noReadonly notifychanges-container\"\n" +
        "            style=\"flex-direction: column\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-notifyIfAnyDefectCurUserCreatedChanged\">\n" +
        "                  <%= t('settings.changes.notifychanges2') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-notifychanges\"\n" +
        "                  id=\"settings-notifyIfAnyDefectCurUserCreatedChanged\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <!-- <div class=\"settings-item-content-hint\">\n" +
        "                <%= t('settings.changes.notifychanges2.hint') %>\n" +
        "              </div> -->\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div\n" +
        "            class=\"settingsItem settings-item settings-item-boolean ui-field-contain noSub noReadonly notifychanges-container\"\n" +
        "            style=\"flex-direction: column\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-notifyIfAgCreatedDefect\">\n" +
        "                  <%= t('settings.changes.notifychanges3') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-notifychanges\" id=\"settings-notifyIfAgCreatedDefect\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <!-- <div class=\"settings-item-content-hint\">\n" +
        "                <%= t('settings.changes.notifychanges3.hint') %>\n" +
        "              </div> -->\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "      </div>\n" +
        "\n" +
        "      <!-- <a href=\"javascript:void(0)\" class=\"configureMenu ui-btn ui-corner-all\">\n" +
        "\t\t\t<%= t('settings.configuremenu') %>\n" +
        "\t\t</a> -->\n" +
        "      <!-- the extended div is hidden at first -->\n" +
        "\n" +
        "      <div class=\"settings-section-extended extendedUserSettings\" style=\"display: none\">\n" +
        "        <div class=\"settings-section-heading\">\n" +
        "          <h2 class=\"settings-section-header\">\n" +
        "            <%= t('settings.user.extended.title') %>\n" +
        "          </h2>\n" +
        "        </div>\n" +
        "        <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly noPrinc\"\n" +
        "          tags=\"principal, auftraggeber, nummer, id, positionsnummer\">\n" +
        "          <div class=\"settings-item-top\">\n" +
        "            <fieldset data-role=\"controlgroup\">\n" +
        "              <label for=\"settings-addprincipalid\">\n" +
        "                <%= t('settings.addprincipalid') %>\n" +
        "              </label>\n" +
        "              <input type=\"checkbox\" name=\"settings-addprincipalid\" id=\"settings-addprincipalid\" />\n" +
        "            </fieldset>\n" +
        "          </div>\n" +
        "          <div class=\"settings-item-bottom\">\n" +
        "            <div class=\"settings-item-hint\">\n" +
        "              <%= t('settings.addprincipalid.hint') %>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly noPrinc\">\n" +
        "          <div class=\"settings-item-top\">\n" +
        "            <fieldset data-role=\"controlgroup\">\n" +
        "              <label for=\"settings-autoSelectDeepestLocation\">\n" +
        "                <%= t('settings.autoSelectDeepestLocation') %>\n" +
        "              </label>\n" +
        "              <input type=\"checkbox\" name=\"settings-autoSelectDeepestLocation\"\n" +
        "                id=\"settings-autoSelectDeepestLocation\" />\n" +
        "            </fieldset>\n" +
        "          </div>\n" +
        "          <div class=\"settings-item-bottom\">\n" +
        "            <div class=\"settings-item-hint\">\n" +
        "              <%= t('settings.autoSelectDeepestLocation.hint') %>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly defaultUploadSettingsContainer\"\n" +
        "          tags=\"\">\n" +
        "          <div class=\"settings-item-top\">\n" +
        "            <label for=\"settings-defaultUploadSettings\" class=\"settings-item-label\">\n" +
        "              <%= t('settings.defaultuploadsettings.title') %>\n" +
        "            </label>\n" +
        "            <select name=\"settings-defaultUploadSettings\" class=\"settings-item-content\"\n" +
        "              id=\"settings-defaultUploadSettings\">\n" +
        "              <option value=\"NONE\">\n" +
        "                <%= t('settings.defaultuploadsettings.none') %>\n" +
        "              </option>\n" +
        "              <option value=\"AG\">\n" +
        "                <%= t('settings.defaultuploadsettings.ag') %>\n" +
        "              </option>\n" +
        "              <option value=\"AN\">\n" +
        "                <%= t('settings.defaultuploadsettings.an') %>\n" +
        "              </option>\n" +
        "              <option value=\"AGAN\">\n" +
        "                <%= t('settings.defaultuploadsettings.agan') %>\n" +
        "              </option>\n" +
        "            </select>\n" +
        "          </div>\n" +
        "          <div class=\"settings-item-bottom\">\n" +
        "            <div class=\"settings-item-hint\">\n" +
        "              <%= t('settings.defaultuploadsettings.hint') %>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <div class=\"settingsItem settings-item noSub noReadonly noPrinc\"\n" +
        "          tags=\"auftragnehmer, reihenfolge, tabelle, subcontractor\">\n" +
        "          <div class=\"settings-item-top\">\n" +
        "            <label for=\"settings-subcontractororder\" class=\"settings-item-label\">\n" +
        "              <%= t('settings.subcontractororder') %>\n" +
        "            </label>\n" +
        "            <select name=\"settings-subcontractororder\" id=\"settings-subcontractororder\" class=\"settings-item-content\">\n" +
        "              <option value=\"TABLE\">\n" +
        "                <%= t('settings.subcontractororder.table') %>\n" +
        "              </option>\n" +
        "              <option value=\"NAME\">\n" +
        "                <%= t('settings.subcontractororder.name') %>\n" +
        "              </option>\n" +
        "              <option value=\"CREW\">\n" +
        "                <%= t('settings.subcontractororder.crew') %>\n" +
        "              </option>\n" +
        "            </select>\n" +
        "          </div>\n" +
        "          <div class=\"settings-item-bottom\">\n" +
        "            <div class=\"settings-item-hint\">\n" +
        "              <%= t('settings.subcontractororder.hint') %>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <div id=\"hotkeys-link\" class=\"settingsItem settings-item\" tags=\"hotkeys, shortcuts, tasten, tastatur\">\n" +
        "          <a href=\"/onlineBauabnahme/service#shortcut-settings/OX\" target=\"_blank\" data-shortcut=\"adminTables\"\n" +
        "            class=\"only-browser ui-btn ui-btn-icon-right ui-icon-carat-r\">\n" +
        "            <%= t('settings.hotkeys.link') %>\n" +
        "          </a>\n" +
        "        </div>\n" +
        "\n" +
        "        <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "          <div class=\"settings-item-top\">\n" +
        "            <fieldset data-role=\"controlgroup\">\n" +
        "              <label for=\"settings-altlocationfilter\">\n" +
        "                <%= t('settings.altlocationfilter') %>\n" +
        "              </label>\n" +
        "              <input type=\"checkbox\" name=\"settings-altlocationfilter\" id=\"settings-altlocationfilter\" />\n" +
        "            </fieldset>\n" +
        "          </div>\n" +
        "          <div class=\"settings-item-bottom\">\n" +
        "            <div class=\"settings-item-hint\">\n" +
        "              <%= t('settings.altlocationfilter.hint') %>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "\n" +
        "        <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly noPrinc\">\n" +
        "          <div class=\"settings-item-top\" style=\"flex-direction: column;\">\n" +
        "            <fieldset data-role=\"controlgroup\">\n" +
        "              <label for=\"settings-instantmessagedeadline\" style=\"display: flex\">\n" +
        "                <%= t('settings.instantmessagedeadlinecheckbox') %>\n" +
        "              </label>\n" +
        "              <input type=\"checkbox\" name=\"settings-instantmessagedeadline\" id=\"settings-instantmessagedeadline\"\n" +
        "                style=\"flex: 1; display: flex; justify-content: end\" />\n" +
        "            </fieldset>\n" +
        "            <div style=\"\n" +
        "              flex: 1;\n" +
        "              display: flex;\n" +
        "              flex-direction: row;\n" +
        "              align-items: center;\n" +
        "              justify-content: end;\n" +
        "            \" id=\"instantmessagedeadlinedays\" name=\"instantmessagedeadlinedays\" class=\"deadlineDaysContainer\">\n" +
        "              <div class=\"deadlineDays\" style=\"\n" +
        "                max-width: 4rem;\n" +
        "                flex: 1;\n" +
        "                margin-left: 1rem;\n" +
        "                margin-right: 1rem;\n" +
        "                margin-top: 0;\n" +
        "              \">\n" +
        "                <input type=\"number\" id=\"settings-instantmessagedefaultdeadlinedays\"\n" +
        "                  placeholder=\"<%= t('settings.instantmessagedeadlinedefault') %>\" />\n" +
        "              </div>\n" +
        "              <label for=\"settings-instantmessagedefaultdeadlinedays\"\n" +
        "                style=\"display: flex; align-items: center; margin: 0 0\">\n" +
        "                <%= t('settings.instantmessagedeadlinedays') %>\n" +
        "              </label>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settings-item-bottom\">\n" +
        "            <div class=\"settings-item-hint\">\n" +
        "              <%= t('settings.instantmessagedeadline.hint') %>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "\n" +
        "        <button class=\"settingsItem settings-item triggerSync noSub noReadonly\">\n" +
        "          <%- t('settings.sync.trigger') %>\n" +
        "        </button>\n" +
        "\n" +
        "        <fieldset id=\"metadata-settings\" class=\"settingsItem settings-item\" data-role=\"controlgroup\">\n" +
        "          <div style=\"flex: 1\">\n" +
        "            <legend class=\"settings-item-label\">\n" +
        "              <%- t('settings.defects.additionalinfo.title') %>\n" +
        "            </legend>\n" +
        "          </div>\n" +
        "          <div id=\"defect-metadata\" class=\"settings-item-content\">\n" +
        "            <input type=\"checkbox\" name=\"defect-deadline\" id=\"defect-deadline\" value=\"DEFECT_DEADLINE\" />\n" +
        "            <label for=\"defect-deadline\"><%- t('settings.defects.additionalinfo.deadline') %></label>\n" +
        "            <input type=\"checkbox\" name=\"defect-creator\" id=\"defect-creator\" value=\"DEFECT_CREATOR\" />\n" +
        "            <label for=\"defect-creator\"><%- t('settings.defects.additionalinfo.creator') %></label>\n" +
        "            <input type=\"checkbox\" name=\"defect-actualisation-date\" id=\"defect-actualisation-date\"\n" +
        "              value=\"ACTUALISATION_DATE\" />\n" +
        "            <label for=\"defect-actualisation-date\"><%- t('settings.defects.additionalinfo.lastupdate') %></label>\n" +
        "            <input type=\"checkbox\" name=\"defect-creation-date\" id=\"defect-creation-date\" value=\"DEFECT_CREATION_DATE\" />\n" +
        "            <label for=\"defect-creation-date\"><%- t('settings.defects.additionalinfo.created') %></label>\n" +
        "            <input type=\"checkbox\" name=\"defect-subcontractor\" id=\"defect-subcontractor\" value=\"DEFECT_SUBCONTRACTOR\" />\n" +
        "            <label for=\"defect-subcontractor\"><%- t('settings.defects.additionalinfo.subcontractor') %></label>\n" +
        "            <input class=\"noPrinc\" type=\"checkbox\" name=\"defect-escalationlevel\" id=\"defect-escalationlevel\"\n" +
        "              value=\"DEFECT_ESCALATIONLEVEL\" />\n" +
        "            <label class=\"noPrinc\" for=\"defect-escalationlevel\"><%- t('settings.defects.additionalinfo.escalationlevel')\n" +
        "                %></label>\n" +
        "            <!--\t\t\t\t<input type=\"checkbox\" name=\"defect-fixed-date\" id=\"defect-fixed-date\"value=\"DEFECT_FIXED_DATE\">-->\n" +
        "            <!--\t\t\t\t<label for=\"defect-fixed-date\"><%- t('settings.defects.additionalinfo.fixed') %></label>-->\n" +
        "          </div>\n" +
        "        </fieldset>\n" +
        "\n" +
        "        <!-- <div class=\"settingsItem settings-item fe-connection noSub noReadonly\" style=\"margin-left: 0\"\n" +
        "\t\t\t\t\ttags=\"fotoaufnahme, photoaufnahme, baufoto, bauphoto, suite\">\n" +
        "\t\t\t\t\t<h3>Open Experience <%- t('settings.bausuite.title')%></h3>\n" +
        "\t\t\t\t\t<div class=\"ui-field-contain\" style=\"margin-left: 0\">\n" +
        "\t\t\t\t\t\t<fieldset data-role=\"controlgroup\">\n" +
        "\t\t\t\t\t\t\t<label for=\"settings-use-fe\"><%- t('settings.usebaufoto')%></label>\n" +
        "\t\t\t\t\t\t\t<input type=\"checkbox\" name=\"settings-use-fe\" id=\"settings-use-fe\" />\n" +
        "\t\t\t\t\t\t</fieldset>\n" +
        "\t\t\t\t\t</div>\n" +
        "\n" +
        "\t\t\t\t</div> -->\n" +
        "\n" +
        "        <div class=\"settingsItem settings-item ui-field-contain noSub noPrinc noReadonly setfixed\">\n" +
        "          <fieldset data-role=\"controlgroup\">\n" +
        "            <!-- <legend class=\"settings-item-label\">\n" +
        "              <%= t('settings.setfixed') %>\n" +
        "            </legend> -->\n" +
        "            <label for=\"settings-sendaccount\">\n" +
        "              <%= t('settings.sendaccount') %>\n" +
        "            </label>\n" +
        "            <input type=\"checkbox\" name=\"settings-sendaccount\" id=\"settings-sendaccount\"\n" +
        "              class=\"settings-item-content\" />\n" +
        "          </fieldset>\n" +
        "        </div>\n" +
        "\n" +
        "        <!-- <div class=\"settingsItem settings-item ui-field-contain noSub noPrinc noReadonly\">\n" +
        "\t\t\t\t\t<label for=\"settings-signatures\">\n" +
        "\t\t\t\t\t\t<%= t('settings.signatures') %>\n" +
        "\t\t\t\t\t</label>\n" +
        "\t\t\t\t\t<select name=\"settings-signatures\" id=\"settings-signatures\">\n" +
        "\t\t\t\t\t\t<option value=\"WITHOUT\">\n" +
        "\t\t\t\t\t\t\t<%= t('settings.signatures.without') %>\n" +
        "\t\t\t\t\t\t</option>\n" +
        "\t\t\t\t\t\t<option value=\"PAPER\">\n" +
        "\t\t\t\t\t\t\t<%= t('settings.signatures.paper') %>\n" +
        "\t\t\t\t\t\t</option>\n" +
        "\t\t\t\t\t\t<option value=\"DEVICE\">\n" +
        "\t\t\t\t\t\t\t<%= t('settings.signatures.device') %>\n" +
        "\t\t\t\t\t\t</option>\n" +
        "\t\t\t\t\t</select>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t\t<div id=\"signatureSettingDisabledInfo\">\n" +
        "\t\t\t\t\t<%= t('settings.signatures.disabled') %>\n" +
        "\t\t\t\t</div> -->\n" +
        "\n" +
        "        <div class=\"settingsItem settings-item ui-field-contain noSub noPrinc noReadonly\" tags=\"\"\n" +
        "          style=\"margin-bottom: 2rem;\">\n" +
        "          <label class=\"settings-item-label\">\n" +
        "            <%= t('settings.signature') %>\n" +
        "          </label>\n" +
        "          <div class=\"signature sketcher settings-item-content\"></div>\n" +
        "        </div>\n" +
        "\n" +
        "        <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\"\n" +
        "          tags=\"schema, dateinamen, generieren, dokumente, documents\">\n" +
        "          <label for=\"settings-filenameschema\" class=\"settings-item-label\">\n" +
        "            <%= t('settings.filenameschema') %>\n" +
        "          </label>\n" +
        "          <select name=\"settings-filenameschema\" id=\"settings-filenameschema\" class=\"settings-item-content\"\n" +
        "            style=\"min-width: fit-content\">\n" +
        "            <option value=\"STANDARD\">\n" +
        "              <%- t('settings.filenameschema.standard') %>\n" +
        "            </option>\n" +
        "            <option value=\"OTHER\">\n" +
        "              <%- t('settings.filenameschema.other') %>\n" +
        "            </option>\n" +
        "            <option value=\"LONG\">\n" +
        "              <%- t('settings.filenameschema.long') %>\n" +
        "            </option>\n" +
        "          </select>\n" +
        "        </div>\n" +
        "\n" +
        "        <!-- <div class=\"settingsItem settings-item code-container noSub noReadonly\"\n" +
        "\t\t\t\t\ttags=\"qrcode, qr-code, ortsbestimmung, location, scancode, scanning\">\n" +
        "\t\t\t\t\t<h3 class=\"noSub noReadonly\">\n" +
        "\t\t\t\t\t\t<%= t('settings.codes') %>\n" +
        "\t\t\t\t\t</h3>\n" +
        "\n" +
        "\t\t\t\t\t<div data-role=\"controlgroup\" data-type=\"horizontal\"\n" +
        "\t\t\t\t\t\tstyle=\"display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px; margin-bottom: 10px;\">\n" +
        "\t\t\t\t\t\t<label for=\"settings-code-type\">\n" +
        "\t\t\t\t\t\t\t<%= t('settings.code.type') %>\n" +
        "\t\t\t\t\t\t\t\t<select class=\"codeOptions\"></select>\n" +
        "\t\t\t\t\t\t\t\t<button class=\"createCodes\"><%- t('settings.createcodes') %></button>\n" +
        "\t\t\t\t\t</div>\n" +
        "\n" +
        "\t\t\t\t\t<a href=\"javascript:void(0)\" class=\"downloadunits ui-btn ui-corner-all\">\n" +
        "\t\t\t\t\t\t<%= t('settings.downloadunits') %>\n" +
        "\t\t\t\t\t</a>\n" +
        "\n" +
        "\t\t\t\t</div> -->\n" +
        "\n" +
        "        <!-- <h3 class=\"settingsItem settings-item offlinecachesheading noSub noReadonly\">\n" +
        "\t\t\t\t\t<%= t('settings.offlinecaches') %>\n" +
        "\t\t\t\t</h3>\n" +
        "\n" +
        "\t\t\t\t<a href=\"javascript:void(0)\" class=\"deleteplans ui-btn ui-corner-all noSub noReadonly\">\n" +
        "\t\t\t\t\t<%= t('settings.deleteplanscache') %>\n" +
        "\t\t\t\t</a>\n" +
        "\t\t\t\t<a href=\"javascript:void(0)\" class=\"deletemetadata ui-btn ui-corner-all noSub noReadonly\">\n" +
        "\t\t\t\t\t<%= t('settings.deletemetadatacache') %>\n" +
        "\t\t\t\t</a> -->\n" +
        "      </div>\n" +
        "      <button class=\"settings-section-toggler toggleExtendedUserSettings extendedUserButton noSub noReadonly\">\n" +
        "        <%= t('settings.user.extended.show') %>\n" +
        "      </button>\n" +
        "    </div>\n" +
        "\n" +
        "    <div id=\"projectSettings\" class=\"settings-section\">\n" +
        "      <div class=\"settings-section-heading\">\n" +
        "        <h2 class=\"settings-section-header\">\n" +
        "          <%= t('settings.project.title') %>\n" +
        "        </h2>\n" +
        "      </div>\n" +
        "      <div class=\"settings-section-basic\">\n" +
        "        <div class=\"settings-subsection\">\n" +
        "          <div class=\"settings-subsection-heading\">\n" +
        "            <h3 class=\"settings-subsection-header\">\n" +
        "              <%= t('settings.groups.title') %>\n" +
        "            </h3>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-groupsActive\">\n" +
        "                  <%= t('settings.groupsActive.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-groupsActive\" id=\"settings-groupsActive\"\n" +
        "                  class=\"pmSetting only-browser\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.groupsActive.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div\n" +
        "            class=\"settingsItem settings-item ui-field-contain ui-btn-icon-right ui-icon-carat-r noSub noPrinc noReadonly\">\n" +
        "            <button name=\"goto-groups\" id=\"goto-groups\" style=\"margin-bottom: 0; margin-top: 0\">\n" +
        "              <%= t('settings.configureGroups.title') %>\n" +
        "            </button>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <div class=\"settings-subsection\">\n" +
        "          <div class=\"settings-subsection-heading\">\n" +
        "            <h3 class=\"settings-subsection-header\">\n" +
        "              <%= t('settings.documentcreation') %>\n" +
        "            </h3>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item\" style=\"flex: 1; justify-content: space-between; gap: 1em\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <div style=\"flex: 1; flex-wrap: wrap\">\n" +
        "                <select class=\"previews\"></select>\n" +
        "              </div>\n" +
        "              <div style=\"flex: 1; flex-wrap: wrap; display: flex; gap: 0.5em;\">\n" +
        "                <button class=\"downloadpreview\"\n" +
        "                  style=\"width: auto; flex: 1; min-width: min-content; height: min-content;\">\n" +
        "                  <%- t('settings.downloadpreview') %>\n" +
        "                </button>\n" +
        "                <button class=\"showpreview\" style=\"width: auto; flex: 1; min-width: min-content;  height: min-content;\">\n" +
        "                  <%- t('settings.showpreview') %>\n" +
        "                </button>\n" +
        "                <button class=\"downloadAll\" style=\"width: auto; flex: 1; min-width: min-content;  height: min-content;\">\n" +
        "                  <%- t('settings.downloadAll') %>\n" +
        "                </button>\n" +
        "                <button class=\"changeText\" style=\"width: auto; flex: 1; min-width: min-content;  height: min-content;\">\n" +
        "                  <%- t('settings.changeText') %>\n" +
        "                </button>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <!-- <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.previews.hint') %>\n" +
        "              </div> -->\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-trackPdfDownload\">\n" +
        "                  <%= t('settings.trackPdfDownload.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-trackPdfDownload\" id=\"settings-trackPdfDownload\"\n" +
        "                  class=\"pmSetting\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.trackPdfDownload.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain\"\n" +
        "            tags=\"pdf-listen, pdf-lists, normal, detail, position, bilder, abbildungen, images, groß, eco, \">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <label for=\"settings-printing\" class=\"settings-item-label\" style=\"margin: 0.5em 0.5em;\"><%-\n" +
        "                  t('settings.printing') %></label>\n" +
        "              <div style=\"display: flex; flex: 1;\">\n" +
        "                <select name=\"settings-printing\" id=\"settings-printing\" class=\"settings-item-content\">\n" +
        "                  <option value=\"NORMAL\">\n" +
        "                    <%- t('settings.printing.normal') %>\n" +
        "                  </option>\n" +
        "                  <option value=\"DETAIL\">\n" +
        "                    <%- t('settings.printing.detail') %>\n" +
        "                  </option>\n" +
        "                  <option value=\"FULLHISTORY\">\n" +
        "                    <%- t('settings.printing.fullhistory') %>\n" +
        "                  </option>\n" +
        "                  <option value=\"COMPACT\">\n" +
        "                    <%- t('settings.printing.compact') %>\n" +
        "                  </option>\n" +
        "                  <option value=\"COMPACTEXTRA\">\n" +
        "                    <%- t('settings.printing.compactextra') %>\n" +
        "                  </option>\n" +
        "                  <option value=\"COMPACTPLANFIRST\">\n" +
        "                    <%- t('settings.printing.compactplanfirst') %>\n" +
        "                  </option>\n" +
        "                  <option value=\"PLANEXTRACOLUMN\">\n" +
        "                    <%- t('settings.printing.planextracolumn') %>\n" +
        "                  </option>\n" +
        "                  <option value=\"NOIMAGES\">\n" +
        "                    <%- t('settings.printing.noimages') %>\n" +
        "                  </option>\n" +
        "                  <option value=\"ECO\"><%- t('settings.printing.eco') %></option>\n" +
        "                  <option value=\"LONG\"><%- t('settings.printing.long') %></option>\n" +
        "                </select>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <!-- <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.printing.hint') %>\n" +
        "              </div> -->\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <div class=\"settings-subsection\">\n" +
        "          <div class=\"settings-subsection-heading\">\n" +
        "            <h3 class=\"settings-subsection-header\">\n" +
        "              <%= t('settings.qrcodes.title') %>\n" +
        "            </h3>\n" +
        "            <div class=\"settings-item-hint\">\n" +
        "              <%= t('settings.qrcodes.hint') %>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <div class=\"settingsItem settings-item noSub noReadonly\">\n" +
        "          <div class=\"settings-item-top\">\n" +
        "            <!-- <div class=\"code-container\"> -->\n" +
        "            <label for=\"settings-createCodes\" class=\"settings-item-label\">\n" +
        "              <%= t('settings.createCodes.title') %>\n" +
        "            </label>\n" +
        "            <div class=\"settings-item-content\">\n" +
        "              <select class=\"codeOptions\"></select>\n" +
        "              <button class=\"createCodes\"><%- t('settings.createcodes') %></button>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <!-- </div> -->\n" +
        "        </div>\n" +
        "        <div class=\"settingsItem settings-item noSub noReadonly\">\n" +
        "          <div class=\"settings-item-top\">\n" +
        "            <!-- <div class=\"code-container\"> -->\n" +
        "            <label for=\"settings-downloadUnits\" class=\"settings-item-label\" style=\"margin: 0.5em 0.5em;\">\n" +
        "              <%= t('settings.downloadUnits.title') %>\n" +
        "            </label>\n" +
        "            <div class=\"settings-item-content\">\n" +
        "              <button class=\"downloadUnits\" style=\"width: auto; flex: 1; min-width: min-content;\">\n" +
        "                Download\n" +
        "              </button>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <!-- </div> -->\n" +
        "        </div>\n" +
        "\n" +
        "        <div class=\"settings-subsection\">\n" +
        "          <div class=\"settings-subsection-heading\">\n" +
        "            <h3 class=\"settings-subsection-header\">\n" +
        "              <%= t('settings.warranty.title') %>\n" +
        "            </h3>\n" +
        "          </div>\n" +
        "\n" +
        "\n" +
        "\n" +
        "          <div class=\"settingsItem settings-item  admin noSub noReadonly buyeraccess-container\">\n" +
        "            <div class=\"settings-item-top ui-field-contain ui-btn-icon-right ui-icon-carat-r\">\n" +
        "              <button name=\"settings-buyeraccess\" id=\"settings-buyeraccess\">\n" +
        "                <%= t('settings.tobuyeraccess') %>\n" +
        "              </button>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.buyeraccess.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "\n" +
        "          <div class=\"settingsItem settings-item\n" +
        "             admin noSub noReadonly buyeraccess-container\">\n" +
        "            <div class=\"settings-item-top ui-field-contain ui-btn-icon-right ui-icon-carat-r\">\n" +
        "              <button name=\"settings-warrantyData\" id=\"settings-warrantyData\">\n" +
        "                <%= t('settings.totopwarrantydataoverview') %>\n" +
        "              </button>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.warrantyData.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noPrinc noReadonly persons-container\">\n" +
        "            <!-- <button name=\"settings-warrantyData\" id=\"settings-warrantyData\">\n" +
        "              <%= t('settings.topwarrantydataoverview') %>\n" +
        "            </button> -->\n" +
        "            <fieldset data-role=\"controlgroup\">\n" +
        "              <label for=\"settings-showWarrantyWhenNoWarranty\">\n" +
        "                <%= t('settings.show.onlyWhenNoWarranty') %>\n" +
        "              </label>\n" +
        "              <input type=\"checkbox\" name=\"settings-showWarrantyWhenNoWarranty\"\n" +
        "                id=\"settings-showWarrantyWhenNoWarranty\" />\n" +
        "            </fieldset>\n" +
        "          </div>\n" +
        "\n" +
        "          <div class=\"settingsItem settings-item noSub noPrinc noReadonly persons-container\">\n" +
        "            <div class=\"settings-item-top ui-field-contain ui-btn-icon-right ui-icon-carat-r\">\n" +
        "              <button name=\"settings-persons\" id=\"settings-persons\">\n" +
        "                <%= t('settings.topersonsoverview') %>\n" +
        "              </button>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.persons.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "\n" +
        "\n" +
        "          <div class=\"settingsItem settings-item noSub noReadonly noPrinc\" tags=\"\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <label for=\"settings-contactIndex\" class=\"settings-item-label\">\n" +
        "                <%= t('settings.contactIndex.title') %>\n" +
        "              </label>\n" +
        "              <input type=\"number\" id=\"settings-contactIndex\" class=\"settings-item-content number-input\" />\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.contactIndex.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <div class=\"settings-subsection-heading\">\n" +
        "          <h3 class=\"settings-subsection-header\">\n" +
        "            <%= t('settings.costs.title') %>\n" +
        "          </h3>\n" +
        "          <div class=\"settings-subsection-hint\">\n" +
        "            <%= t('settings.costs.hint') %>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <div class=\"settingsItem settings-item noSub noReadonly noPrinc\" tags=\"\">\n" +
        "          <div style=\"display: flex; flex: 1\">\n" +
        "            <select name=\"settings-costType\" id=\"settings-costType\" class=\"settings-item-content\">\n" +
        "              <option value=\"\">\n" +
        "                <%= t('settings.costType.none') %>\n" +
        "              </option>\n" +
        "              <option value=\"GLOBAL\">\n" +
        "                <%= t('settings.costType.global') %>\n" +
        "              </option>\n" +
        "              <option value=\"SPECIFIC\">\n" +
        "                <%= t('settings.costType.specific') %>\n" +
        "              </option>\n" +
        "              <option value=\"SEPARATE\">\n" +
        "                <%= t('settings.costType.separate') %>\n" +
        "              </option>\n" +
        "            </select>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <div class=\"settings-subsection\">\n" +
        "          <div class=\"settings-subsection-heading\">\n" +
        "            <h3 class=\"settings-subsection-header\">\n" +
        "              <%= t('settings.anRequest.title') %>\n" +
        "            </h3>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item noSub noReadonly noPrinc\" tags=\"\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <label for=\"settings-anRequestSubject\" class=\"settings-item-label\">\n" +
        "                <%= t('settings.anRequest.subject.title') %>\n" +
        "              </label>\n" +
        "              <input type=\"text\" id=\"anRequestSubjectInput\" class=\"settings-item-content\" />\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item noSub noReadonly noPrinc\" tags=\"\">\n" +
        "            <label for=\"settings-anRequestContent\" class=\"settings-item-label\">\n" +
        "              <%= t('settings.anRequest.content.title') %>\n" +
        "            </label>\n" +
        "            <div style=\"display: flex; flex: 1;\">\n" +
        "              <textarea type=\"text\" id=\"anRequestContentInput\" class=\"settings-item-content\">\n" +
        "            </textarea>\n" +
        "            </div>\n" +
        "            <div style=\"flex-direction: row;\">\n" +
        "              <div class=\"preview-button\" style=\"flex: 1; display: flex; justify-content: flex-end;\">\n" +
        "                <button class=\"toggleAnRequestEmail\" style=\"flex: 1; align-items: right; max-width: min-content;\">\n" +
        "                  <%= t('settings.anRequest.preview.title') %>\n" +
        "                </button>\n" +
        "              </div>\n" +
        "              <div class=\"anRequestEmail\">\n" +
        "                <div class=\"anRequestEmailSubject\"></div>\n" +
        "                <p class=\"anRequestEmailContent\"></p>\n" +
        "              </div>\n" +
        "              <div class=\"variables-button\" style=\"flex: 1; display: flex; justify-content: flex-end;\">\n" +
        "                <button class=\"toggleAnRequestVariables\" style=\"flex: 1; align-items: right; max-width: min-content;\">\n" +
        "                  <%= t('settings.anRequest.variables') %>\n" +
        "                </button>\n" +
        "              </div>\n" +
        "              <div class=\"anRequestVariables\" style=\"display: none;\">\n" +
        "                Mail-Betreff für per Mail versandte Aufforderungen. Die folgenden Variablen können verwendet\n" +
        "                werden:\n" +
        "\n" +
        "              </div>\n" +
        "            </div>\n" +
        "\n" +
        "          </div>\n" +
        "        </div>\n" +
        "\n" +
        "\n" +
        "        <div class=\"settingsItem settings-item noSub noPrinc noReadonly goto-inspections\">\n" +
        "          <div class=\"settings-item-top ui-field-contain ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\n" +
        "            <button name=\"goto-auto-requests\" id=\"goto-auto-requests\" style=\"margin-bottom: 0; margin-top: 0\">\n" +
        "              <%= t('settings.configureAutomaticRequests.title') %>\n" +
        "            </button>\n" +
        "          </div>\n" +
        "          <div class=\"settings-item-bottom\">\n" +
        "            <!-- <div class=\"settings-item-hint\">\n" +
        "              <%= t('settings.configureAutomaticRequests.hint') %>\n" +
        "            </div> -->\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <div class=\"settings-subsection\">\n" +
        "          <div class=\"settings-subsection-heading\">\n" +
        "            <h3 class=\"settings-subsection-header\">\n" +
        "              <%= t('settings.inspectiontypes.title') %>\n" +
        "            </h3>\n" +
        "\n" +
        "\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item noSub noPrinc noReadonly goto-inspections\">\n" +
        "            <div class=\"settings-item-top ui-field-contain ui-btn-icon-right ui-icon-carat-r\">\n" +
        "\n" +
        "              <button name=\"goto-inspections\" id=\"goto-inspections\" style=\"margin-bottom: 0; margin-top: 0\">\n" +
        "                <%= t('settings.toInspectionTypesVisibility.title') %>\n" +
        "              </button>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.configureAutomaticRequests.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <div class=\"settings-section-extended extendedProjectSettings\">\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\"\n" +
        "            tags=\"Zeitstempel, timestamp, fotos, photos, show, anzeigen, uhr, clock\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-addtimestamp\">\n" +
        "                  <%= t('settings.addtimestamp') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-addtimestamp\" id=\"settings-addtimestamp\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <!-- <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.addtimestamp.hint') %>\n" +
        "              </div> -->\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-extManual\">\n" +
        "                  <%= t('settings.extManual.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-extManual\" id=\"settings-extManual\" class=\"pmSetting\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.extManual.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-externalDefectsOnly\">\n" +
        "                  <%= t('settings.externalDefectsOnly.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-externalDefectsOnly\" id=\"settings-externalDefectsOnly\"\n" +
        "                  class=\"pmSetting\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.externalDefectsOnly.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-showPrincipalIdsOnPdfs\">\n" +
        "                  <%= t('settings.showPrincipalIdsOnPdfs.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-showPrincipalIdsOnPdfs\" id=\"settings-showPrincipalIdsOnPdfs\"\n" +
        "                  class=\"pmSetting\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.showPrincipalIdsOnPdfs.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <fieldset data-role=\"controlgroup\">\n" +
        "              <label for=\"settings-showSubcontractorContactInFileName\">\n" +
        "                <%= t('settings.showSubcontractorContactInFileName.title') %>\n" +
        "              </label>\n" +
        "              <input type=\"checkbox\" name=\"settings-showSubcontractorContactInFileName\"\n" +
        "                id=\"settings-showSubcontractorContactInFileName\" class=\"pmSetting\" />\n" +
        "            </fieldset>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-showYellowAgPins\">\n" +
        "                  <%= t('settings.showYellowAgPins.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-showYellowAgPins\" id=\"settings-showYellowAgPins\"\n" +
        "                  class=\"pmSetting\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.showYellowAgPins.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-chooseSubAutomatically\">\n" +
        "                  <%= t('settings.chooseSubAutomatically.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-chooseSubAutomatically\" id=\"settings-chooseSubAutomatically\"\n" +
        "                  class=\"pmSetting\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.chooseSubAutomatically.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-attachVisibleFiles\">\n" +
        "                  <%= t('settings.attachVisibleFiles.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-attachVisibleFiles\" id=\"settings-attachVisibleFiles\"\n" +
        "                  class=\"pmSetting\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.attachVisibleFiles.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-sendNotifications\">\n" +
        "                  <%= t('settings.sendNotifications.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-sendNotifications\" id=\"settings-sendNotifications\"\n" +
        "                  class=\"pmSetting\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.sendNotifications.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-sendCopyWhenDownload\">\n" +
        "                  <%= t('settings.sendCopyWhenDownload.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-sendCopyWhenDownload\" id=\"settings-sendCopyWhenDownload\"\n" +
        "                  class=\"pmSetting\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.sendCopyWhenDownload.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-showSubcontractorsToPrincipal\">\n" +
        "                  <%= t('settings.showSubcontractorsToPrincipal.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-showSubcontractorsToPrincipal\"\n" +
        "                  id=\"settings-showSubcontractorsToPrincipal\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.showSubcontractorsToPrincipal.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item noSub noReadonly noPrinc\" tags=\"\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <label for=\"settings-minLocationDepth\" class=\"settings-item-label\">\n" +
        "                <%= t('settings.minLocationDepth.title') %>\n" +
        "              </label>\n" +
        "              <input type=\"number\" id=\"settings-minLocationDepth\" class=\"settings-item-content number-input\" />\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.minLocationDepth.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "\n" +
        "          <div class=\"settingsItem settings-item noSub noReadonly noPrinc\" tags=\"\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <label for=\"settings-automaticDataSync\" class=\"settings-item-label\">\n" +
        "                <%= t('settings.automaticDataSync.title') %>\n" +
        "              </label>\n" +
        "              <input type=\"text\" id=\"settings-automaticDataSync\" class=\"settings-item-content\" />\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.automaticDataSync.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "\n" +
        "          <div class=\"settingsItem settings-item noSub noReadonly noPrinc\" tags=\"\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <label for=\"settings-defectSync\" class=\"settings-item-label\">\n" +
        "                <%= t('settings.defectSync.title') %>\n" +
        "              </label>\n" +
        "              <input type=\"text\" id=\"settings-defectSync\" class=\"settings-item-content\" />\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.defectSync.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item noSub noReadonly noPrinc\" tags=\"\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <label for=\"settings-anRequestSplitByLocationDepth\" class=\"settings-item-label\">\n" +
        "                <%= t('settings.anRequestSplitByLocationDepth.title') %>\n" +
        "              </label>\n" +
        "              <input type=\"number\" id=\"settings-anRequestSplitByLocationDepth\"\n" +
        "                class=\"settings-item-content number-input\" />\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%= t('settings.anRequestSplitByLocationDepth.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-stateDefectTypeSpecial\">\n" +
        "                  <%= t('settings.stateDefectTypeSpecial.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-stateDefectTypeSpecial\" id=\"settings-stateDefectTypeSpecial\"\n" +
        "                  class=\"pmSetting\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%- t('settings.stateDefectTypeSpecial.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-AnCommentInAgList\">\n" +
        "                  <%= t('settings.AnCommentInAgList.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-AnCommentInAgList\" id=\"settings-AnCommentInAgList\"\n" +
        "                  class=\"pmSetting\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%- t('settings.AnCommentInAgList.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "          <div class=\"settingsItem settings-item ui-field-contain noSub noReadonly\">\n" +
        "            <div class=\"settings-item-top\">\n" +
        "              <fieldset data-role=\"controlgroup\">\n" +
        "                <label for=\"settings-AgCommentInAnList\">\n" +
        "                  <%= t('settings.AgCommentInAnList.title') %>\n" +
        "                </label>\n" +
        "                <input type=\"checkbox\" name=\"settings-AgCommentInAnList\" id=\"settings-AgCommentInAnList\"\n" +
        "                  class=\"pmSetting\" />\n" +
        "              </fieldset>\n" +
        "            </div>\n" +
        "            <div class=\"settings-item-bottom\">\n" +
        "              <div class=\"settings-item-hint\">\n" +
        "                <%- t('settings.AgCommentInAnList.hint') %>\n" +
        "              </div>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <button class=\"settings-section-toggler toggleExtendedProjectSettings extendedProjectButton noSub noReadonly\">\n" +
        "          <%= t('settings.project.extended.show') %>\n" +
        "        </button>\n" +
        "      </div>\n" +
        "    </div>\n" +
        "    <div id=\"customerSettings\" class=\"settings-section\">\n" +
        "      <div class=\"settings-section-heading\">\n" +
        "        <h2 class=\"settings-section-header\">\n" +
        "          <%= t('settings.customer.title') %>\n" +
        "        </h2>\n" +
        "        <div class=\"settings-section-hint\">\n" +
        "          <%= t('settings.customer.hint') %>\n" +
        "        </div>\n" +
        "      </div>\n" +
        "      <div class=\"settings-section-basic\">\n" +
        "        <div\n" +
        "          class=\"settingsItem settings-item ui-field-contain ui-btn-icon-right ui-icon-carat-r noSub noPrinc noReadonly goto-inspections\">\n" +
        "          <div class=\"settings-item-top\">\n" +
        "            <button name=\"goto-customer\" id=\"goto-customer\" style=\"margin-bottom: 0; margin-top: 0\">\n" +
        "              <%= t('settings.configureCustomer.title') %>\n" +
        "            </button>\n" +
        "          </div>\n" +
        "          <div class=\"settings-item-bottom\">\n" +
        "            <div class=\"settings-item-hint\">\n" +
        "              <%= t('settings.configureCustomer.hint') %>\n" +
        "            </div>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "        <!-- <div\n" +
        "            class=\"settingsItem settings-item ui-field-contain ui-btn-icon-right ui-icon-carat-r noSub noPrinc noReadonly persons-container\">\n" +
        "            <button name=\"topositiontypes\" id=\"topositiontypes\">\n" +
        "              <%= t('settings.topositiontypes') %>\n" +
        "            </button>\n" +
        "          </div> -->\n" +
        "      </div>\n" +
        "\n" +
        "      <div class=\"settings-section-extended extendedCustomerSettings\" style=\"display: none\">\n" +
        "        <div class=\"settings-section-heading\">\n" +
        "          <h2 class=\"settings-section-header\">\n" +
        "            <%= t('settings.customer.extended.title') %>\n" +
        "          </h2>\n" +
        "          <div class=\"settings-section-hint\">\n" +
        "            <%= t('settings.customer.extended.hint') %>\n" +
        "          </div>\n" +
        "        </div>\n" +
        "      </div>\n" +
        "\n" +
        "    </div>\n" +
        "  </div>\n" +
        "\n" +
        "\n" +
        "\n" +
        "  <div data-role=\"popup\" class=\"safetyPopup\">\n" +
        "    <h3>\n" +
        "      <%= t('requestbundle.safetyQuestion') %>\n" +
        "    </h3>\n" +
        "\n" +
        "    <input class=\"safetyWordinput\" type=\"text\" />\n" +
        "\n" +
        "    <div class=\"safetyWarning\">\n" +
        "      <%= t('requestbundle.safetyWarning') %>\n" +
        "    </div>\n" +
        "\n" +
        "    <select class=\"chooseFolder\">\n" +
        "      <option value=\"project\">\n" +
        "        <%= t('requestbundle.project') %>\n" +
        "      </option>\n" +
        "      <option value=\"customer\">\n" +
        "        <%= t('requestbundle.customer') %>\n" +
        "      </option>\n" +
        "    </select>\n" +
        "\n" +
        "    <div class=\"buttons\" style=\"display: flex; flex-direction: row\" style=\"margin: 10px\">\n" +
        "      <button class=\"cancelSafety\" style=\"margin: 10px\">\n" +
        "        <%= t('requestbundle.cancel') %>\n" +
        "      </button>\n" +
        "      <button class=\"saveAlteredFopsPermanently\" style=\"margin: 10px\">\n" +
        "        <%= t('requestbundle.save') %>\n" +
        "      </button>\n" +
        "    </div>\n" +
        "  </div>\n" +
        "\n" +
        "  <div data-role=\"popup\" class=\"alterFop\" style=\"background-color: #eeeeee\">\n" +
        "    <div style=\"\n" +
        "          height: 100%;\n" +
        "          width: 100%;\n" +
        "          overflow-y: auto;\n" +
        "          padding: 0 14px;\n" +
        "          overflow-x: auto;\n" +
        "        \">\n" +
        "      <h3 class=\"document-name\"></h3>\n" +
        "\n" +
        "      <ul class=\"textBlocks\" style=\"\n" +
        "            background-color: #eeeeee;\n" +
        "            list-style: none;\n" +
        "            padding-left: 0;\n" +
        "            width: calc(100% - 28px);\n" +
        "            height: calc(100% - 130px);\n" +
        "            overflow: auto;\n" +
        "          \"></ul>\n" +
        "\n" +
        "      <div class=\"buttons\" style=\"\n" +
        "            display: flex;\n" +
        "            flex-direction: row;\n" +
        "            bottom: 0;\n" +
        "            position: absolute;\n" +
        "            width: 100%;\n" +
        "            left: 0;\n" +
        "          \">\n" +
        "        <button class=\"cancelFopPopup\" style=\"margin: 10px\">\n" +
        "          <%= t('requestbundle.cancel') %>\n" +
        "        </button>\n" +
        "        <button class=\"openSafetyPopup\" style=\"margin: 10px\">\n" +
        "          <%= t('requestbundle.savePermanently') %>\n" +
        "        </button>\n" +
        "      </div>\n" +
        "    </div>\n" +
        "  </div>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" data-position=\"fixed\"></div>";
    return page;
});