import {awaitWrap} from "./utils";
import {CommonStoreStateKeys} from "../store/slices/commonStoreSlice";
// @ts-ignore
import _ from "lodash";
import store from "../store/store";

export function jqueryDeferredWrap<T, U = any>(fetch:any): Promise<[U | null, T | null]> {
    return awaitWrap(new Promise(((resolve, reject) => {
        fetch.done((res:T)=>{
            resolve(res);
        });
        fetch.fail((error: U) => {
            reject(error);
        })
    })));
}

export function getBackboneModel(key: CommonStoreStateKeys|string) {
    if(key === CommonStoreStateKeys.offlineQueue) {
        return require(`../webapp/helper/${key}`);
    }
    return require(`../webapp/model/${key}`);
}

export function dispatchDataWithCallback(shouldReset: boolean, key: CommonStoreStateKeys, value: any, callback: (dataFromBackbone: any)=>void){
    let source;
    if(key === CommonStoreStateKeys.offlineQueue || key === CommonStoreStateKeys.watchdog) {
        source = require(`../webapp/helper/${key}`);
    } else {
        source = require(`../webapp/model/${key}`);
    }

    if(key === CommonStoreStateKeys.currentProject || key === CommonStoreStateKeys.currentInspection) {
        source.id = value.id;
    }

    let state = store.getState();
    let project = state.commonReducer[CommonStoreStateKeys.currentProject];
    if(!!project && !!project.id) {
        source.project = project.id;
    }
    if(key === CommonStoreStateKeys.subcontractors) {
        source.validRegexp = new RegExp('^(' + _.map(value, (el:any) => el.id).join('|') + ')$');
    }

    if(Object.keys(source).includes('length')) {
        source.reset(value);
    } else {
        if(shouldReset) {
            if(key === CommonStoreStateKeys.completions
                || key === CommonStoreStateKeys.currentInspection
                || key === CommonStoreStateKeys.currentStats
                || key === CommonStoreStateKeys.user
                || key === CommonStoreStateKeys.projectFiles) {
                source.clear({ silent: true });
            } else {
                source.clear();
            }
            source.set(value);
        } else {
            let oldValue = source.toJSON();
            const changes =
                _.differenceWith(_.toPairs(value), _.toPairs(oldValue), _.isEqual)
            source.set(_.fromPairs(changes));
        }
    }

    if(key === CommonStoreStateKeys.inspections) {
        source.indexLabels();
    }

    if(key === CommonStoreStateKeys.projectFiles) {
        let json = source.toJSON();
        parseComplexModel(json);
        callback(json);
        return;
    }
    callback(source.toJSON());
}


function parseComplexModel(json:any) {
    let children = json.children;
    if(!!children) {
        json.children = children.toJSON();
        _.forEach(json.children, (el:any) => {
            parseComplexModel(el);
        });
    }
}