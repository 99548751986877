import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {translate} from "../utils/utils";


const Filter = () => {

    useEffect(()=>{
        // @ts-ignore
        window.filterUseEffect();
    },[])


    return <PageContainer newPage={false} headerText={translate("filter.title")}
                          left={<StyledButton className="previousPage">{translate("filter.back")}</StyledButton>}
                          right={<StyledButton className="apply">{translate("filter.apply")}</StyledButton>}
    />
}

export default Filter;