define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page =
        "<div class=\"browser\">\n" +
        "    <h2>Zustand übertragen</h2>\n" +
        "    <div>Status: <span class=\"sendStatus\"></span></div>\n" +
        "    <button  class=\"queuetool-send\">Daten an den Server übertragen</button>\n" +
        "\n" +
        "    <h2>Erstes Element löschen</h2>\n" +
        "    <div style=\"font-weight: bold;\">Achtung: Die Änderung geht verloren!</div>\n" +
        "    <button  class=\"queuetool-reset-one\">Element löschen</button>\n" +
        "\n" +
        "    <h2>Zustand zurücksetzen</h2>\n" +
        "    <div style=\"font-weight: bold;\">Achtung: Alle noch nicht übertragenen Änderungen werden gelöscht!</div>\n" +
        "    <button class=\"queuetool-reset-all\">Daten löschen</button>\n" +
        "</div>\n" +
        "\n" +
        "<div class=\"native\">\n" +
        "    <h2>Änderungen</h2>\n" +
        "    <div>Anzahl: <span class=\"queuetool-queuesize\"></span></div>\n" +
        "    <button class=\"queuetool-update-size\">Aktualisieren</button>\n" +
        "\n" +
        "    <h2>Erstes Element versuchen zu übertragen und aus Warteschlange löschen</h2>\n" +
        "    <div>Status: <span class=\"sendStatus\"></span></div>\n" +
        "    <div style=\"font-weight: bold;\">Verwenden Sie dies bitte nur mit Rücksprache mit dem Support.</div>\n" +
        "    <div style=\"font-weight: bold;\">Achtung: Die Änderung geht verloren!</div>\n" +
        "    <button  class=\"queuetool-sendOne\">Daten an den Server übertragen</button>\n" +
        "    <button  class=\"queuetool-removeOne\">Element löschen</button>\n" +
        "\n" +
        "    <h2>Eintrag anwenden</h2>\n" +
        "    <div>Status: <span class=\"applyStatus\"></span></div>\n" +
        "    <div>ID: <input type=\"textbox\" class=\"applyId\" /></div>\n" +
        "    <button class=\"queuetool-applyOne\">Daten anwenden</button>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div class=\"qtversion\"></div>\n";
    return page;
});