define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2',
	'../model/ma_Account'
], function (
	$, _, Backbone,
	ma_Account
) {
	return new (Backbone.Collection.extend({

		url:  '/../myaccount/api/services',

		model: ma_Account,

		findByCustomer: function (targetCustomer) {
			var accounts = this.filter(function (account) {
				return account.get('type') === 'OA' && account.get('association') === targetCustomer;
			});
			return accounts.length ? accounts[0] : null;
		},


	}));
});
