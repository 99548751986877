define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat','../lib/backbone-1.1.2', './NewsPageHtml', '../helper/iosapp', '../model/accessibleAccounts', '../view/ShortcutBaseView',
	'../model/projects', '../model/currentProject', '../model/user', '../helper/watchdog', '../helper/offlineQueue', '../model/currentDefects', '../model/subcontractors'
], function (
	$, _, Backbone, Template, iosapp, accessibleAccounts, ShortcutBaseView,
	projects, currentProject, user, watchdog, offlineQueue, currentDefects, subcontractors
) {

	return ShortcutBaseView.extend({

		id: 'news',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		events: {
		},
		
		initialize: function (options) {
			this.router = options.router;
			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.content = this.$('div.content');
			this.header = this.$('.header');
			this.footer = this.$('.footer');
		},

		render: function () {
			this.content.find('object').attr('data', 'https://openexperience.de/de/new-features');
			this.resize();
			return this;
		},
		
		resize: function () {
			var headerFooterHeight = this.header.outerHeight() + this.footer.outerHeight();
			var height = $('body').height() - headerFooterHeight;
			this.content.find('object').css('height', height);
		}
	});
});
