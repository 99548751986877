define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../model/user', '../model/projectFiles',  '../model/realWorldPlanTransformations'
], function (
	$, _, Backbone, user, projectFiles, realWorldPlanTransformations
) {
	return {

		initialize: function () {

		},

		getTransformations: function () {
			if (realWorldPlanTransformations){
				return realWorldPlanTransformations.values();
			}
			else{
				return [];
			}
		},

		createDefaultTransformation: function(hash){
			var t = {
				'name': ' - ',
				'hashes': [hash],
				'scale': 1,
				'northOffsetLeafletX': 0,
				'xRefPoint': null,
				'yRefPoint': null,
				'xLatRefPoint': null,
				'yLatRefPoint': null,
				'flip' : true
			};
			return t;
		},

		getTransformation: function (hash) {
			if (hash && realWorldPlanTransformations.values()
			){
				for (var i = 0; i < realWorldPlanTransformations.values().length; i++){
					if (realWorldPlanTransformations.get(i)
						&& realWorldPlanTransformations.get(i).hashes
						&& realWorldPlanTransformations.get(i).hashes.includes(hash)
					){
						return realWorldPlanTransformations.get(i);
					}
				}
				return null;
			} else{
				return null;
			}
		},

		getTransformationByName: function (name) {
			if (name && realWorldPlanTransformations.values()
			){
				for (var i = 0; i < realWorldPlanTransformations.values().length; i++){
					if (realWorldPlanTransformations.get(i)
						&& realWorldPlanTransformations.get(i).name
						&& realWorldPlanTransformations.get(i).name === name
					){
						return realWorldPlanTransformations.get(i);
					}
				}
			}
			return null
		},

		getTransformationsAsSelectOptions: function() {
			var selectOptions = {
				' - ': null // default: no transformation
			};

			// add all options
			if (realWorldPlanTransformations && realWorldPlanTransformations.values()){
				for (var i = 0; i < realWorldPlanTransformations.values().length; i++){
					if (realWorldPlanTransformations.get(i)
						&& realWorldPlanTransformations.get(i).name
						&& !(selectOptions.hasOwnProperty(realWorldPlanTransformations.get(i).name))
					){
						selectOptions[realWorldPlanTransformations.get(i).name] = realWorldPlanTransformations.get(i).hashes;
					}
				}
			}
			return selectOptions;
		}
	};
});