define([
	'../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../model/user', '../helper/interactiveSync'
], function (
	_, Backbone, user, interactiveSync
) {
	return new (Backbone.Model.extend({

		url: function () {
			 return  '/onlineBauabnahme/api/recordPoints/' + this.project;
		},

		sync: function () {
			return interactiveSync.sync.apply(interactiveSync, arguments);
		},

		setProject: function (project) {
			if (project == this.project) {
				if (this.pending) {
					return this.pending;
				} else {
					return $.Deferred().resolve();
				}
			}
			var oldProject = this.project;
			this.project = project;
			if (!project) {
				this.clear();
				return $.Deferred().resolve();
			}
			this.pending = this.fetch({ reset: true })
				.fail(_.bind(function () {
					this.project = oldProject;
				}, this))
				.always(_.bind(function () {
					delete this.pending;
				}, this));
			return this.pending;
		},
	}));
});