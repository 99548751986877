define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id='instant-message-page-header' data-position=\"fixed\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\t<div class=\"location\" data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\">\n" +
        "\t\t<input type=\"text\" placeholder=\"<%= t('defect.location') %>\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t<span class=\"qrcodetrigger\" data-enhance=\"false\"></span>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<!-- BEGIN COST -->\n" +
        "\n" +
        "\t<div class=\"cost-container global\" style=\"display: none\">\n" +
        "\t\t<label><input type=\"checkbox\" data-mini=\"true\"/> <%- t('defect.significant') %></label>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"cost-container specific\" style=\"display: none\">\n" +
        "\t\t<span class=\"cost-label\"><%- t('cost.title.specific') %>:</span>\n" +
        "\t\t<input type=\"text\" data-wrapper-class=\"ui-btn-inline ui-mini\"/>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"cost-container separate\" style=\"display: none\">\n" +
        "\t\t<span class=\"cost-label\"><%- t('cost.title.specific') %>:</span>\n" +
        "\t\t<div data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\">\n" +
        "\t\t\t<!--<input type=\"text\" data-wrapper-class=\"ui-btn-inline ui-mini\" placeholder=\"AN\"/>-->\n" +
        "\t\t\t<!--<input type=\"text\" data-wrapper-class=\"ui-btn-inline ui-mini\" placeholder=\"AG\"/>-->\n" +
        "\t\t\t<input type=\"text\" data-wrapper-class=\"controlgroup-textinput ui-btn ui-mini\" class=\"ag\" placeholder=\"AG\" title=\"Auftraggeber\"/>\n" +
        "\t\t\t<input type=\"text\" data-wrapper-class=\"controlgroup-textinput ui-btn ui-mini\" class=\"an\" placeholder=\"AN\" title=\"Auftragnehmer\"/>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<!-- END COST -->\n" +
        "\n" +
        "\t<div class=\"description-container\">\n" +
        "\t\t<input data-shortcut=\"focusInput1\" class=\"description\" type=\"text\" placeholder=\"<%= t('defect.description') %>\" maxlength=\"255\"/>\n" +
        "\t</div>\n" +
        "\t<div class=\"descriptionlengthindicator\" style=\"position: absolute; z-index: -1; visibility: hidden\"></div>\n" +
        "\n" +
        "\t<div class=\"options\" data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\">\n" +
        "\n" +
        "\t\t<input data-shortcut=\"focusInput2\" class=\"subcontractors mode-defect-type-not-principal\" placeholder=\"<%= t('defect.subcontractor') %>\" type=\"text\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\n" +
        "\t\t<select class=\"type\">\n" +
        "\t\t</select>\n" +
        "\n" +
        "\t</div>\n" +
        "\t<div data-role=\"popup\" class=\"descriptionDialog\" data-dismissible=\"false\" style=\"width: 400px; display: none;\">\n" +
        "\t\t<div class=\"ui-content\" style=\"padding: 1ex 1ex 0\">\n" +
        "\t\t\t<textarea rows=\"4\" style=\"margin: 0\"></textarea>\n" +
        "\t\t\t<button class=\"ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\"><%= t('defect.comment.apply') %></button>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<table style=\"width: 100%; margin-top: -0.5em;\" class=\"ui-mini\">\n" +
        "\t        <tr class=\"customProperties\">\n" +
        "\t                <td class = \"customProp1\">\n" +
        "\t                        <label class = \"property1\">\n" +
        "\t                                <input type=\"checkbox\" class=\"property1 boolean\" />\n" +
        "\t                        </label>\n" +
        "\t                        <input type=\"text\" class=\"property1 string\"/>\n" +
        "\t                </td>\n" +
        "\t                <td class = \"customProp2\">\n" +
        "\t                        <label class = \"property2\">\n" +
        "\t                                <input type=\"checkbox\" class=\"property2 boolean\"/>\n" +
        "\t                        </label>\n" +
        "\t                        <input type=\"text\" class=\"property2 string\" />\n" +
        "\t                </td>\n" +
        "\t                <td class = \"customProp3\">\n" +
        "\t                        <label class = \"property3\">\n" +
        "\t                                <input type=\"checkbox\" class=\"property3 boolean\"/>\n" +
        "\t                        </label>\n" +
        "\t                        <input type=\"text\" class=\"property3 string\" />\n" +
        "\t                </td>\n" +
        "\t        </tr>\n" +
        "\t</table>\n" +
        "\n" +
        "    <div class=\"sketcher\" data-enhance=\"false\" style=\"clear: right\"></div>\n" +
        "\n" +
        "\t<div class=\"deadlineContainer\" data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\">\n" +
        "\t\t<input class=\"deadline\" type=\"date\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t<label style=\"text-align: center\">\n" +
        "\t\t\t<input type=\"checkbox\" class=\"noDeadline\" checked> <%= t('instant.nodeadline') %>\n" +
        "\t\t</label>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<input class=\"recipient\" type=\"text\" placeholder=\"<%= t('instant.email') %>\"/>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='instant-message-page-footer' data-position=\"fixed\"></div>";
    return page;
});