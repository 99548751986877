define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id='subcontractor-bundle-page-header' data-position=\"fixed\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\t<div class=\"proxy-hint only-proxy\"><%= t('subcontractor.bundle.offlinenotavailable') %></div>\n" +
        "\t<button class=\"send-credentials ui-btn ui-corner-all not-proxy\" style=\"float: right; width: auto; font-size: 12px; margin-left: 1em\"><%= t('subcontractor.bundle.sendcredentials') %></button>\n" +
        "\t<button class=\"copy-credentials ui-btn ui-corner-all not-proxy\" style=\"float: right; width: auto; font-size: 12px; margin-left: 1em; clear: both;\"><%= t('subcontractor.bundle.copycredentials') %></button>\n" +
        "\n" +
        "\t<div class=\"not-proxy\" style=\"overflow: hidden\">\n" +
        "\t\t<button class=\"requestBundle ui-btn ui-btn-active ui-corner-all\"><%= t('subcontractor.bundle.createdocuments') %></button>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<p class=\"not-proxy\" style=\"margin-bottom: 1ex\"><%= t('subcontractor.bundle.sentdocuments') %></p>\n" +
        "\n" +
        "\t<ul data-role=\"listview\" data-inset=\"true\" class=\"list checkboxList ui-corner-all not-proxy\"></ul>\n" +
        "\n" +
        "\t<button class=\"removeFromList ui-btn-active not-proxy\" style=\"display: none\"><%= t('subcontractor.bundle.removedocuments') %></button>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='subcontractor-bundle-page-footer' data-position=\"fixed\"></div>";
    return page;
});