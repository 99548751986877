import React from 'react';
import {Button, DialogActions, DialogContent, Stack} from '@mui/material';
import {translate} from '../../../utils/utils';
import {CustomizedDialog, CustomizedDisabledTextField} from "../../../components/muiCustomizedComponents";
import {EmailTemplate} from "./types/types";


type EmailPreviewDialogProps = {
    open: boolean;
    onClose: () => void;
    emailPreview: EmailTemplate | null;
};

const EmailPreviewDialog = ({open, onClose, emailPreview}: EmailPreviewDialogProps) => {
    return (
        <CustomizedDialog open={open} onClose={onClose}>
            <DialogContent>
                <Stack className="an-request-preview" flexDirection={"column"} alignItems={"center"}
                       justifyContent={"center"}>
                    <CustomizedDisabledTextField
                        disabled
                        variant="filled"
                        value={emailPreview?.subject || ""}
                        multiline
                        label={translate("settings.anRequest.subject.title")}
                    />
                    <CustomizedDisabledTextField
                        disabled
                        sx={{marginTop: '.3rem'}}
                        variant="filled"
                        value={emailPreview?.body || ""}
                        label={translate("settings.anRequest.content.title")}
                        multiline
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{translate("close")}</Button>
            </DialogActions>
        </CustomizedDialog>
    );
};

export default EmailPreviewDialog;
