define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './DocumentsHtml', '../model/user', '../model/currentProject', '../model/projectFiles', '../helper/iosapp', '../helper/watchdog', '../model/currentInspection', '../model/DocumentsPaperwork', '../model/SubcontractorPaperwork',
	'../view/Autocomplete', '../model/completions', '../model/subcontractors', '../model/TrackedPaperworkInfo', '../helper/backboneReactCommunicationUtils'
], function (
	$, _, Backbone, Template, user, currentProject, projectFiles, iosapp, watchdog, currentInspection, DocumentsPaperwork, SubcontractorPaperwork,
	Autocomplete, completions, subcontractors, TrackedPaperworkInfo, backboneReactCommunicationUtils
) {
	return Backbone.View.extend({

		id: 'documents',

		attributes: {
			'data-role':           'page'
			// 'data-theme':          'a',
			// 'data-position':       'left',
			// 'data-display':        'overlay',
			// 'data-position-fixed': 'true'
		},

		template: _.template(Template),
		pageSize: 30,
		files: [],
		subs: [],
		from: null,
		to: null,

		events: {
			'change #documentsSubcontractorFilter': 'filter',
			'vclick .moreRows button':  function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.renderList({ dontEmpty: true });
				var $children = this.$list.children();
				var $lastElement = $children.get($children.length - 1);
				this.$list.scrollTo($lastElement, 500);
			},
			'vclick .requestBundle':    function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.router.requestBundlePage.setModel(this.model);
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/requestBundle');
			},
			'vclick .openFile':         function (e) {
				e.preventDefault();
				e.stopPropagation();
				var $button = $(e.currentTarget);
				var index = $button.parents('[data-index]').attr('data-index');
				var file = this.files[index];
				this.router.imagePage.setModel(new SubcontractorPaperwork(file));
				this.router.imagePage.setupPageForDocument();
				window.showPageCallback = function () {
					this.router.imagePage.setupPageForDocument();
				}.bind(this);
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/image', {callback:true});
			},
			'vclick .setFrom': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.$fromDialog.show().popup('open');
				var $from = this.$fromDialog.find('input[type=date]');
				if(this.maxDate || this.maxDate === '') $from.attr('max', this.maxDate);
				
				var $buttons = this.$fromDialog.find('button');
				$buttons.one('vclick', _.bind(function (e) {
					e.preventDefault();
					e.stopPropagation();
					if ($(e.currentTarget).hasClass('ok')) {
						this.from = $from.val();
						this.$('.setFrom').text(user.translate('documents.filter.from') + ($from.val() !== '' ? ': ' + $from.val() : ''));
						this.minDate = $from.val();
					}
					$buttons.off('vclick');
					this.$fromDialog.popup('close');
					this.filter();
				}, this));
			},
			'vclick .setTo': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.$toDialog.show().popup('open');
				var $to = this.$toDialog.find('input[type=date]');
				if(this.minDate || this.minDate === '') $to.attr('min', this.minDate);
				
				var $buttons = this.$toDialog.find('button');
				$buttons.one('vclick', _.bind(function (e) {
					e.preventDefault();
					e.stopPropagation();
					if ($(e.currentTarget).hasClass('ok')) {
						this.to = $to.val();
						this.$('.setTo').text(user.translate('documents.filter.to') + ($to.val() !== '' ? ': ' + $to.val() : ''));
						this.maxDate = $to.val();
					}
					$buttons.off('vclick');
					this.$toDialog.popup('close');
					this.filter();
				}, this));
			}
		},
		
		initialize: function (options) {
			this.router = options.router;
			
			this.model = projectFiles;
			
			this.trackedPaperworkInfo = new TrackedPaperworkInfo();
			
			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.$previousPage      	= this.$('.previousPage');
			this.actionstrigger     	= this.$('.actionstrigger');
			this.actionsmenu        	= this.$('.actionsmenu');
			this.$location          	= this.$('.location');
			this.$parent            	= this.$('.parent');
			this.$files             	= this.$('.files');
			this.$recentFiles       	= this.$('.recent-files');
			this.$mostCommonFiles   	= this.$('.mostcommon-files');
			this.$offlineWarning    	= this.$('.offlineWarning');
			this.$path              	= this.$('.path');
			this.$manageFilesButton 	= this.actionsmenu.find('.manageFiles');
			this.$defaultUploadSettings = this.$('#files-defaultUploadSettings');
			this.$subcontractorFilter   = window.innerWidth <= 440 ? this.$('#filters .on-small-screen #documentsSubcontractorFilter') : this.$('#filters.on-big-screen #documentsSubcontractorFilter');
			this.$typeFilter            = this.$('#documentsDefectTypeFilter');
			
			this.$fromDialog = this.$('.fromDialog');
			this.$toDialog = this.$('.toDialog');
			
			this.$list = this.$('.list');
			
			if (window.oxmobile || iosapp.appavailable) {
				this.actionstrigger.remove();
			}
			
			this.listenTo(currentProject, 'change:id', function () {
				this.recent = [];
			});
			
			if(!user.isSubcontractor()) {
				new Autocomplete({
					input: this.$subcontractorFilter,
					leftOffset: 8.0,
					rightOffset: 8.0,
					autowidth: true,
					lookup: function (str) {
						var result = [];
						if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
							result.push(user.translate('defects.filter.anycrew'));
							_.forEach(currentProject.get('crews'), function (crew) {
								result.push(crew);
							})
						} else {
							result.push(user.translate('defects.filter.anysubcontractor'));
							if (!user.isSubcontractor()) {
								result.push(user.translate('defects.filter.emptysubcontractor'));
							}
							_.forEach(completions.subcontractorLookup(str), function (sub) {
								result.push(sub);
							})
						}
						if (user.isPrincipal()) {
							result.push(_.flatten([user.translate('defect.primecontractor'), result], true));
						}
						return result;
					},
					toggleButton: true
				}).render();
			} else {
				this.$subcontractorFilter.parent().remove();
			}
			
			this.listenTo(watchdog, 'change:connected', _.bind(function () {
				if (!this.$el.is(':visible')) {
					return;
				}
				this.render();
			}, this));
		},
		
		render: function (alreadyInManageMode) {
			$('.previousPage').off('click');
			$('.previousPage').on('click', function (e) {
				this.previousPageFunction(e);
			}.bind(this));
			var settings = user.get('settings');
			if(alreadyInManageMode != undefined) {
				this.manage = alreadyInManageMode;
			}
			$('.previousPage').toggleClass('ui-disabled', !(watchdog.isConnected() || this.previousPage == 'defect' || this.previousPage == 'instant'));
			
			var defectTypes = currentProject.get('types');
			_.forEach(defectTypes, function (type) {
				if (this.$typeFilter.find('option[value="' + type.defectTypeId + '"]').length === 0) {
					this.$typeFilter.append($('<option value="' + type.defectTypeId + '">').text(type.content + ' (' + user.translate(type.label) + ')'))
				}
			}.bind(this));
			this.$typeFilter.trigger('create');
			
			var previousLabel;
			switch (this.previousPage) {
				case 'defect':  previousLabel = user.translate('files.backtodefect');  break;
				case 'instant': previousLabel = user.translate('files.backtoinstant'); break;
				default:        previousLabel = user.translate('files.backtoproject'); break;
			}
			$('.previousPage').text(previousLabel);
			
			if (this.currentProjectId !== currentProject.id) {
				if(this.combinedFilter) this.combinedFilter.subFilter = '';
				this.$subcontractorFilter.val('');
			}
			this.currentProjectId = currentProject.id;
			
			this.$path.val(this.model.getPath());
			this.$location.toggle(!!this.model.parent);
			
			this.$('.recent').toggle(this.previousPage === 'defect' && this.recent.length > 0 && !this.defectMode);
			if (this.previousPage === 'defect') {
				this.$recentFiles.empty();
				_.each(this.recent, function (item) {
					if (user.isSubcontractor()){
						if (item.get('visibleToSub')) {
							this.createItem(item, alreadyInManageMode).appendTo(this.$recentFiles);
						}
					} else {
						this.createItem(item, alreadyInManageMode).appendTo(this.$recentFiles);
					}
				}, this);
				this.$recentFiles.listview('refresh');
			}
			this.refreshList();
			this.resize();
		},
		
		filter: function (e, choice, favourite) {
			var subcontractorVal = this.$subcontractorFilter.val();
			if (subcontractorVal === user.translate('defects.filter.anysubcontractor') || subcontractorVal === user.translate('defects.filter.anycrew')) {
				this.$subcontractorFilter.val('');
			}
			var from = (this.from && this.from !== '' ? new Date(this.from) : null);
			var to = (this.to && this.to !== '' ? new Date(this.to) : null);
			var dates = this.correctDates({from: from, to: to});
			
			this.combinedFilter = {
				subFilter:this.subcontractorId(subcontractorVal),
				dateFilter: {
					from: dates.from,
					to: dates.to
				}
			}
			this.doFilter();
		},
		
		doFilter: function () {
			var filter = this.combinedFilter;
			this.files = [];
			this.itemCount = 0;
			if(filter && (filter.subFilter || (filter.dateFilter && (filter.dateFilter.from || filter.dateFilter.to)))) {
				//Check for files that match the subcontractor filter
				if(filter.subFilter) {
					_.forEach(this.subs, function (sub) {
						if(sub.name && sub.name === filter.subFilter){
							_.forEach(sub.children, function (file) {
								this.files.push(file);
							}.bind(this));
						}
					}.bind(this));
				}
				//Check for files that match the date filter + the subcontractor filter
				if(filter.subFilter && filter.dateFilter && (filter.dateFilter.from || filter.dateFilter.to)) {
					var tempFiles = this.files;
					this.files = [];
					_.forEach(tempFiles, function (file) {
						if((filter.dateFilter.from ? file.creationDateMillies >= filter.dateFilter.from.getTime() : true) && (filter.dateFilter.to ? file.creationDateMillies <= filter.dateFilter.to.getTime() : true)) this.files.push(file);
					}.bind(this));
				//Check for files that match the date filter without the subcontractor filter
				} else if(filter.dateFilter && (filter.dateFilter.from || filter.dateFilter.to)) {
					_.forEach(this.subs, function (sub) {
						_.forEach(sub.children, function (file) {
							if((filter.dateFilter.from ? file.creationDateMillies >= filter.dateFilter.from.getTime() : true) && (filter.dateFilter.to ? file.creationDateMillies <= filter.dateFilter.to.getTime() : true)) this.files.push(file);
						}.bind(this));
					}.bind(this));
				}
			} else {
				_.forEach(this.subs, function (sub) {
					_.forEach(sub.children, function (file) {
						this.files.push(file);
					}.bind(this));
				}.bind(this));
			}
			this.filesCount = this.files.length;
			this.renderList();
		},
		
		refreshList: function (options) {
			this.subs = [];
			_.forEach(this.documentsPaperwork.attributes, function (sub) {
				this.subs.push(sub);
			}.bind(this))
			
			if (options && options.newListContext) {
				this.showFirstCount = 0;
			}
			
			this.doFilter();
		},
		
		renderList: function (options) {
			options = options || {};
			if (!options.dontEmpty) {
				this.$list.empty();
			}
			
			var files = this.files;
			
			files = _.sortBy(files, function (el) {
				return el.creationDateMillies;
			}).reverse();
			
			_.forEach(files, function (file) {
				file.tiles = null;
			})
			
			
			if (this.filesCount === 0) {
				$('<li class="none">').text(user.translate('subcontractor.bundle.nodocuments')).appendTo(this.$list);
			} else {
				for (var i = 0; i < this.pageSize && this.hasMoreItemsInList(); i++, this.itemCount++) {
					this.renderDocument(files[this.itemCount]);
				}
				
				if (this.hasMoreItemsInList()) {
					if (!options.noRecursion) {
						var recursionOptions = {
							dontEmpty:   true,
							noRefresh:   true,
							noRecursion: true
						};
						while (this.itemCount < this.showFirstCount && this.hasMoreItemsInList()) {
							this.renderList(recursionOptions);
						}
						if (this.hasMoreItemsInList()) {
							if (this.$moreRows) {
								this.$moreRows.detach();
								this.$list.append(this.$moreRows);
							} else {
								this.$moreRows = $('<li class="moreRows"><button>' + user.translate('subcontractor.bundle.more') + '</button></li>').appendTo(this.$list).enhanceWithin();
							}
						}
					}
					
				} else if (!options.noRecursion && this.$moreRows) {
					this.$moreRows.detach();
				}
				
				if (!options.noRecursion) {
					this.showFirstCount = this.itemCount;
				}
			}
			
			if (!options.noRefresh) {
				this.$list.listview('refresh');
			}
		},
		
		correctDates: function (dates) {
			if (dates.from) {
				dates.from.setHours(0);
			}
			if (dates.to) {
				dates.to.setHours(23);
				dates.to.setMinutes(59);
				dates.to.setSeconds(59);
			}
			return dates;
		},
		
		subcontractorId: function (val) {
			if (!val || val === user.translate('defects.filter.anysubcontractor') || val === user.translate('defects.filter.anycrew')) {
				return null;
			}
			if (val === user.translate('defects.filter.emptysubcontractor')) {
				return 'null';
			}
			var subcontractor = subcontractors.find(function (model) { return model.getLabel() === val; });
			return subcontractor ? subcontractor.id : val;
		},
		
		hasMoreItemsInList: function () {
			return this.itemCount < this.filesCount;
		},
		
		renderDocument: function (file) {
			var files = this.files;
			var documentIndex = _.indexOf(files, file);
			var $item = $('<li></li>').appendTo(this.$list);
			$item = $('<div class="ui-grid-a ui-corner-all"></div>').appendTo($item).attr('data-index', documentIndex);
			//$item.append('<input type="checkbox" class="selectItem ui-btn ui-block-a"/>');
			var test = function () {
				if (user.isSubcontractor() && this.trackedPaperworkInfo && this.trackedPaperworkInfo.get('downloaded') && _.has(this.trackedPaperworkInfo.get('downloaded'), file.path) && !this.trackedPaperworkInfo.get('downloaded')[file.path]) {
					var link =  '/onlineBauabnahme/api/attachment/update';
					$.post(link, {path: file.path}, function (data) {
						this.trackedPaperworkInfo.fetch();
					}.bind(this));
				}
			}.bind(this);
			$('<div class="selectItem ui-btn ui-block-a" style="height: 38px; background: url(' +  '/onlineBauabnahme/images/fileicon.png) no-repeat center bottom"/></div>')
				.appendTo($item)
				.click(_.bind(function () {
					test();
					var link =  '/onlineBauabnahme/api/downloadFile?project=' + window.encodeURIComponent(currentProject.id) +'&path=' + window.encodeURIComponent(file.path);
					if (iosapp.appavailable) {
						var path = file.path;
						var slashIdx = path.lastIndexOf('/');
						if (slashIdx >= 0) path = path.substr(slashIdx + 1);
						iosapp.downloadAndSharePdf(window.location.origin + link, path);
					} else {
						window.open(link);
					}
				}, this));
			var $filebtn = $('<button class="openFile ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r ui-btn-icon"></button>')
				.append($('<a>').text(file.name))
				.click(function () {
					test();
				}.bind(this));
			$item.append($filebtn);
			if (this.trackedPaperworkInfo && this.trackedPaperworkInfo.get('downloaded')) {
				var $trackedbtn, handler;
				if (_.has(this.trackedPaperworkInfo.get('downloaded'), file.path)) {
					$trackedbtn = $('<div class="ui-btn ui-btn-icon-right ui-icon-search ui-vis-jqmicon-center"></div>');
					var downloaded = this.trackedPaperworkInfo.get('downloaded')[file.path] && _.pairs(this.trackedPaperworkInfo.get('downloaded')[file.path]).length;
					if (downloaded) {
						$trackedbtn.addClass('downloaded');
						$filebtn.removeClass('highlight');
					} else {
						if(user.isSubcontractor()) $filebtn.addClass('highlight');
					}
					handler = (function(e) {
						e.stopPropagation();
						e.preventDefault();
						
						var text;
						if (downloaded) {
							text = user.translate('subcontractor.bundle.tracked.downloaded').replace('%date%', moment(downloaded).format('lll'));
							text = user.translate('subcontractor.bundle.tracked.downloaded');
							_.forEach(this.trackedPaperworkInfo.get('downloaded')[file.path], function (value, key) {
								text += '\n' + moment(value).format('lll') + (key ? ': ' + key : '');
							});
						} else {
							text = user.translate('subcontractor.bundle.tracked.notdownloaded');
						}
						window.alertPopup(text);
					}.bind(this));
				} else {
					$trackedbtn = $('<div class="ui-btn ui-btn-icon-right ui-icon-mail ui-vis-jqmicon-center"></div>');
					handler = (function(e) {
						e.stopPropagation();
						e.preventDefault();
						
						var text = user.translate('subcontractor.bundle.untracked')
						window.alertPopup(text);
					}.bind(this));
				}
				$filebtn.append($trackedbtn);
				$trackedbtn.mousedown(handler);
				$trackedbtn.touchstart(handler);
				$trackedbtn.click(function(e) {
					e.stopPropagation();
					e.preventDefault();
				})
			}
			
			$item.enhanceWithin();
		},
		
		resize: function () {
			// if(this.$('#filters').hasClass('on-big-screen')) {
			// 	var filterElements = this.$('#filters > div.ui-controlgroup-controls').children('div').filter(function () {
			// 		return $(this).css('display') !== 'none';
			// 	});
			// 	var width = 100 / filterElements.size();
			// 	_.forEach(filterElements, function (elem) {
			// 		$(elem).css('width', width + '%');
			// 	});
			// } else {
			//
			// }
		},

		previousPageFunction: function (e) {
			e.preventDefault();
			e.stopPropagation();
			var selectedIndex;
			if (this.previousPage === 'defect') {
				selectedIndex = this.router.defectPage.sketcher.getSelectedIndex();
				window.showPageCallback = function () {
					this.router.show(this.router.defectPage, true);
					this.router.defectPage.sketcher.selectAttachment(selectedIndex);
				}.bind(this);
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+this.router.defectPage.previousDefectPrefixId, {noUseEffect:true, callback:true});
			} else if (this.previousPage == 'instant') {
				selectedIndex = this.router.instantMessagePage.sketcher.getSelectedIndex();
				window.showPageCallback = function () {
					this.router.show(this.router.instantMessagePage, true);
					this.router.instantMessagePage.sketcher.selectAttachment(selectedIndex);
				}.bind(this);
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+"/instantMessage", {noUseEffect:true, callback:true});
			} else {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection());
			}
		}
		

	});
});
