import {translate} from "../../../utils/utils";

export enum REQUEST_VARIABLE {
    PROJECT_NAME = "%Projektname%",
    PROJECT_SHORT = "%ProjektKurz%",
    CREW = "%Gewerk%",
    DATE = "%DatumHeute%",
    DATE_SHORT = "%DatumHeuteReverseKurz%",
    DATE_TIME = "%DatumZeitHeute%",
    PRINCIPAL = "%AgName%",
    PRINCIPAL_NUMBER = "%AgNummer%",
    SUBCONTRACTOR = "%Auftragnehmer%",
    SUBCONTRACTOR_SHORT = "%AuftragnehmerKurz%",
    SUBCONTRACTOR_CONTACT = "%AuftragnehmerKontakt%",
    SUBCONTRACTOR_SALUTATION = "%AuftragnehmerAnrede%",
    DEFECT_NUMBER = "%MangelNr%",
    DEFECT_TYPE = "%Mangeltypen%",
    ESCALATION = "%Eskalationsstufe%",
    PRINCIPAL_ACCOUNT = "%AnAccount%",
    PRINCIPAL_LINK = "%AnAccountLink%",
    AUTHOR = "%Autor%",
    STRUCTURE = "%Projektstruktur%",
    INSPECTION_TITLE = "%INSPECTION_TITLE%",
    INSPECTION_DATE = "%INSPECTION_DATE%",
    INSPECTION_FILE_NAME = "%INSPECTION_FILE_NAME%",
}

const VARIABLE_DESCRIPTION_MAP: Record<REQUEST_VARIABLE, string> = {
    [REQUEST_VARIABLE.PROJECT_NAME]: "settings.variable.projectname",
    [REQUEST_VARIABLE.PROJECT_SHORT]: "settings.variable.projectshort",
    [REQUEST_VARIABLE.CREW]: "settings.variable.crew",
    [REQUEST_VARIABLE.DATE]: "settings.variable.date",
    [REQUEST_VARIABLE.DATE_SHORT]: "settings.variable.dateshort",
    [REQUEST_VARIABLE.DATE_TIME]: "settings.variable.datetime",
    [REQUEST_VARIABLE.PRINCIPAL]: "settings.variable.principal",
    [REQUEST_VARIABLE.PRINCIPAL_NUMBER]: "settings.variable.principalshort",
    [REQUEST_VARIABLE.SUBCONTRACTOR]: "settings.variable.subcontractor",
    [REQUEST_VARIABLE.SUBCONTRACTOR_SHORT]: "settings.variable.subcontractorshort",
    [REQUEST_VARIABLE.SUBCONTRACTOR_CONTACT]: "settings.variable.subcontractorcontact",
    [REQUEST_VARIABLE.SUBCONTRACTOR_SALUTATION]: "settings.variable.subcontractorsalutation",
    [REQUEST_VARIABLE.DEFECT_NUMBER]: "settings.variable.defectnumber",
    [REQUEST_VARIABLE.DEFECT_TYPE]: "settings.variable.defecttype",
    [REQUEST_VARIABLE.ESCALATION]: "settings.variable.escalation",
    [REQUEST_VARIABLE.PRINCIPAL_ACCOUNT]: "settings.variable.principalaccount",
    [REQUEST_VARIABLE.PRINCIPAL_LINK]: "settings.variable.principallink",
    [REQUEST_VARIABLE.AUTHOR]: "settings.variable.author",
    [REQUEST_VARIABLE.STRUCTURE]: "settings.variable.structure",
    [REQUEST_VARIABLE.INSPECTION_TITLE]: "settings.variable.inspectionTitle",
    [REQUEST_VARIABLE.INSPECTION_DATE]: "settings.variable.inspectionDate",
    [REQUEST_VARIABLE.INSPECTION_FILE_NAME]: "settings.variable.inspectionFileName"
};

export function getVariableDescription(variable: REQUEST_VARIABLE): string {
    const key = VARIABLE_DESCRIPTION_MAP[variable];
    return translate(key);
}