// Copyright 2014, Open Experience GmbH. All rights reserved.

define([
	'../lib/jquery-1.11.0', '../lib/backbone-1.1.2', '../view/SubcontractorDetails', '../view/DefectsPage', '../model/user', '../helper/watchdog',
	'../helper/backboneReactCommunicationUtils'
], function ($, Backbone, SubcontractorDetails, DefectsPage, user, watchdog, backboneReactCommunicationUtils) {
	'use strict';

	return DefectsPage.extend({

		id: 'subcontractor',

		events: _.extend(DefectsPage.prototype.events, {
		}),

		initialize: function (options) {
			arguments[0] = _.extend({ pagevariant: 'subcontractor' }, arguments[0]);
			DefectsPage.prototype.initialize.apply(this, arguments);
			this.subcontractorDetails = new SubcontractorDetails();
			this.$el.children('[data-role="content"]').prepend(this.subcontractorDetails.$el);

			this.$newDefect.remove();
		},

		render: function () {
			DefectsPage.prototype.render.apply(this, arguments);

			var previousLabel;
			switch (this.previousPage) {
				default:               previousLabel = user.translate('subcontractor.bundle.back'); break;
			}
			$('.subcontractor-previousPage')
				.on('vclick', function () {
					if(!!this.previousPage) {
						window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/subcontractors');
						return;
					}
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject());
				}.bind(this))
			.text(previousLabel)
			.toggleClass('ui-disabled', !watchdog.isConnected());

//			this.$filters.hide();

			this.$heading.text(user.translate('subcontractor.title'));
			this.subcontractorDetails.$el.show();
			this.subcontractorDetails.render();
		},

		setModel: function (model) {
			this.model = model;
			this.subcontractorDetails.setModel(model);
		}

	});

});