define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id='principal-bundle-page-header' data-position=\"fixed\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\t<button class=\"requestBundle ui-btn ui-btn-active ui-corner-all\"><%= t('principal.bundle.createdocuments') %></button>\n" +
        "\n" +
        "\t<p style=\"margin-bottom: 1ex\"><%= t('principal.bundle.sentdocuments') %></p>\n" +
        "\n" +
        "\t<ul data-role=\"listview\" data-inset=\"true\" class=\"list checkboxList ui-corner-all\"></ul>\n" +
        "\n" +
        "\t<button class=\"removeFromList ui-btn-active\" style=\"display: none\"><%= t('principal.bundle.removedocuments') %></button>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='principal-bundle-page-footer' data-position=\"fixed\"></div>";
    return page;
});