define([
	'../../lib/jquery-1.11.0', '../../lib/lodash-2.4.1.compat', '../../lib/backbone-1.1.2', '../../model/user', './infoText'
], function (
	$, _, Backbone, user, infoText
) {
	var shortcuts;
	var language;

//	Default Key List
//	Keys as follows:
//	0: Name of key as String
//	1: keyCode of key as int
//	2: strg pressed as boolean
//	3: shift pressed as boolean
//	4: alt pressed as boolean
//	5: activated as boolean
//	6: editable as boolean
//	7: usage rights (user, admin, an, ag) as string

//	BE CAREFUL NOT TO ADD DUPLICATE NAMES TO THE ARRAY
	var parentKeyList = [
		['projectManager', 'KeyM', true, false, false, true, true, 'admin'],
		['adminTables', 'KeyM', true, true, false, true, true, 'admin'],
		['settingsPage', 'KeyE', true, false, false, true, true, 'admin, user, an, ag, agro, ro'],
		['leftMenu', 'KeyM', false, false, false, true, true, 'admin, user, an, ag, agro, ro'],
		['rightMenu', 'KeyM', false, true, false, true, true, 'admin, user, an, ag, agro, ro'],
		
		['newDefect', 'KeyN', false, false, true, true, true, 'admin, user, ag'],
		['newInspection', 'KeyN', true, false, true, true, true, 'admin, user, ag'],
		['newInstantMessage', 'KeyN', false, true, true, true, true, 'admin, user'],
		['defectList', 'KeyL', true, false, false, true, true, 'admin, user, ag, an, agro, ro'],
		['defectSave', 'KeyS', true, false, false, true, true, 'admin, user, ag, ro'],
		['defectSaveAndNew', 'KeyS', true, true, false, true, true, 'admin, user, ag'],
		['defectSaveAndDuplicate', 'KeyS', true, true, true, true, true, 'admin, user, ag'],
		['defectSaveAndNext', 'KeyS', true, false, true, true, true, 'admin, user, ag'],
		['defectNext', 'ArrowRight', true, false, false, true, true, 'admin, user, ag, an, agro, ro'],
		['defectPrevious', 'ArrowLeft', true, false, false, true, true, 'admin, user, ag, an, agro, ro'],
		
		['inspectionList', 'KeyL', true, true, false, true, true, 'admin, user, ag, ro'],
		['inspectionCurrent', 'KeyU', true, true, false, true, true, 'admin, user, ag, ro'],
		
		['focusInput1', 'Digit1', true, true, false, true, true, 'admin, user, ag'],
		['focusInput2', 'Digit2', true, true, false, true, true, 'admin, user, ag'],
		['focusInput3', 'Digit3', true, true, false, true, true, 'admin, user, ag'],
	];

//	Array for special characters for keyCodes
//	F1 - F12 is included without having to write it in the list
//	Num 1 - Num 9 is included without having to write it in the list
//	As follows:
//	0: keyCode as int
//	1: Character that should be displayed as String	
	var charKeyCode = [
		['Escape', 'Esc'],
		['ArrowLeft', '←'],
		['ArrowUp', '↑'],
		['ArrowRight', '→'],
		['ArrowDown', '↓'],
		['PageUp', 'shortcut.charKeyCode.PageUp'],
		['PageDown', 'shortcut.charKeyCode.PageDown'],
		['End', 'shortcut.charKeyCode.End'],
		['Home', 'shortcut.charKeyCode.Home'],
		['Insert', 'shortcut.charKeyCode.Insert'],
		['Delete', 'shortcut.charKeyCode.Delete'],
		
		['Minus', 'shortcut.charKeyCode.Minus'],
		['Equal', 'shortcut.charKeyCode.Equal'],
		['BracketRight', 'shortcut.charKeyCode.BracketRight'],
		['BracketLeft', 'shortcut.charKeyCode.BracketLeft'],
		['Backslash', 'shortcut.charKeyCode.Backslash'],
		['Quote', 'shortcut.charKeyCode.Quote'],
		['Semicolon', 'shortcut.charKeyCode.Semicolon'],
		['Slash', 'shortcut.charKeyCode.Slash'],
		['Period', 'shortcut.charKeyCode.Period'],
		['Comma', 'shortcut.charKeyCode.Comma'],
		
		['NumpadDivide', 'shortcut.charKeyCode.NumpadDivide'],
		['NumpadMultiply', 'shortcut.charKeyCode.NumpadMultiply'],
		['NumpadSubtract', 'shortcut.charKeyCode.NumpadSubtract'],
		['NumpadAdd', 'shortcut.charKeyCode.NumpadAdd'],
		['NumpadEnter', 'shortcut.charKeyCode.NumpadEnter'],
		
		['Enter', 'shortcut.charKeyCode.Enter'],
		['IntlBackslash', 'shortcut.charKeyCode.IntlBackslash'],
		['Backquote', 'shortcut.charKeyCode.Backquote'],
	];
	
	var matchers = [
		/(Numpad)(\d)/,
		/(Digit)(\d)/,
		/(Key)(\w)/,
	];
	
	
	var extendedExplanations = [];
	
	var keyList = [];
	
	var shortcutsHoverSetting;
	var shortcutsHighlightSetting;
	
	
	var shortcutsKeys = {
		initialize: function (settings, sc) {
			shortcuts = sc;
			language = settings.lang
			shortcutsKeys.loadKeys(settings.shortcuts);
			shortcutsHoverSetting = settings.shortcutsHoverSetting;
			shortcutsHighlightSetting = settings.shortcutsHighlightSetting;
			shortcutsKeys.loadAdditionalSettings(shortcutsHoverSetting, shortcutsHighlightSetting);
		},
		
		loadKeys: function (kList, shortcutsSettingsList) {
			if (shortcuts) {
				if (!kList) {
					keyList = _.cloneDeep(parentKeyList);
				} else {
					keyList = kList;
					shortcutsKeys.updateKeyList();
				}
				if (shortcutsSettingsList) {
					shortcutsKeys.helpButton()
					if (keyList) {
						shortcutsSettingsList.empty();
						shortcutsSettingsList.append(
							$('<table id="shortcutsSettingsTable">').append(
								$('<colgroup>').append(
									$('<col style="width: 10%;">') //Activated column
								).append(
									$('<col style="width: 30%;">') //Name column
								).append(
									$('<col style="width: 20%;">') //Button column
								).append(
									$('<col style="width: 10%;">') //Shift column
								).append(
									$('<col style="width: 10%;">') //Ctrl column
								).append(
									$('<col style="width: 10%;">') //Shift column
								).append(
									$('<col style="width: 10%;">') //Reset key column
								)
							).append(
								$('<tbody>').append(
									$('<tr>').append(
										$('<th>').text(user.translate('shortcut.tableColumn.activated'))
									).append(
										$('<th style="text-align: left; padding-left: 0;">').text(user.translate('shortcut.tableColumn.name'))
									).append(
										$('<th>').text(user.translate('shortcut.tableColumn.key'))
									).append(
										$('<th>').append($('<img alt="" src="' +  '/helper/shortcuts/img/' + (language === 'DE' ? 'strg.png' : 'ctrl.png') + '" height="30px" class="keyPicture">')).append($('<span>'))
									).append(
										$('<th>').append($('<img alt="" src="' +  '/helper/shortcuts/img/shift.png" height="30px" class="keyPicture">')).append($('<span>'))
									).append(
										$('<th>').append($('<img alt="" src="' +  '/helper/shortcuts/img/alt.png" height="30px" class="keyPicture">')).append($('<span>'))
									).append(
										$('<th>').text('')
									)
								)
							)
						);
						keyList.forEach(function (s) {
							if (s[6] && shortcutsKeys.checkRights(s)) {
								ctrl = s[2] ? 'ui-checkbox-on' : 'ui-checkbox-off';
								shift = s[3] ? 'ui-checkbox-on' : 'ui-checkbox-off';
								alt = s[4] ? 'ui-checkbox-on' : 'ui-checkbox-off';
								activated = s[5] ? 'ui-checkbox-on' : 'ui-checkbox-off';
								sameShortcut = '';
								parentKeyList.forEach(function (v, index, array) {
									if (v[0] === s[0]) {
										sameShortcut = v[1] === s[1] && v[2] === s[2] && v[3] === s[3] && v[4] === s[4] ? '' : ' changed';
									}
								})
								var currentRow = $('<tr>').append(
									$('<td>').append($('<label for="5_' + s[0] + '" class="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ' + activated + ' ui-first-child">'))
								).append(
									$('<td style="text-align: left;">').append($('<span>').text(user.translate('shortcut.settingsPage.' + s[0])))
								).append(
									$('<td>').append($('<input for="1_' + s[0] + '" type="text">').val(shortcutsKeys.characterFromKeyCode(s[1])))
								).append(
									$('<td>').append($('<label for="2_' + s[0] + '" class="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ' + ctrl + ' ui-first-child">'))
								).append(
									$('<td>').append($('<label for="3_' + s[0] + '" class="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ' + shift + ' ui-first-child">'))
								).append(
									$('<td>').append($('<label for="4_' + s[0] + '" class="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ' + alt + ' ui-first-child">'))
								).append(
									$('<td>').append($('<input for="0_' + s[0] + '" class="resetButton' + sameShortcut + '"' +
										' type="button">').val(user.translate('shortcut.resetButtonText')))
								)
								$('#shortcutsSettingsTable tbody').append(currentRow);
								extendedExplanations.forEach(function (x) {
									if (x[0] === s[0]) {
										shortcutsKeys.shortcutHelpButton(currentRow, x[0], x[1])
									}
								})
							}
						});
					}
					shortcutsKeys.loadAdditionalSettings(shortcutsHoverSetting, shortcutsHighlightSetting)
					shortcutsKeys.addListeners(shortcutsSettingsList, keyList);
				}
			} else {
				$('#shortcutsSettings').toggle(false)
			}
		},
		
		loadAdditionalSettings: function (shortcutsHoverSetting, shortcutsHighlightSetting) {
			var shortcutsAdditionalSettingsList = $('#shortcutsAdditionalSettings');
			if (shortcutsAdditionalSettingsList) {
				shortcutsAdditionalSettingsList.empty();
				shortcutsAdditionalSettingsList
					.append(
						$('<label id="shortcutsHighlightSetting" class="ui-btn ui-corner-all ui-btn-inherit' +
							' ui-btn-icon-left ui-first-child ui-last-child ui-checkbox-on">')
							.text(user.translate('shortcut.additionalSettings.highlight')))
					.append(
						$('<label id="shortcutsHoverSetting" class="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ui-first-child ui-last-child ui-checkbox-on">')
							.text(user.translate('shortcut.additionalSettings.hover'))
					);
			}
			shortcutsKeys.changeAdditionalConfig('shortcutsHoverSetting', shortcutsHoverSetting, false);
			shortcutsKeys.changeAdditionalConfig('shortcutsHighlightSetting', shortcutsHighlightSetting, false);
		},
		
		changeAdditionalConfig: function (name, setting, save) {
			if (setting) {
				$('#shortcutsAdditionalSettings #' + name).addClass('ui-checkbox-on')
				$('#shortcutsAdditionalSettings #' + name).removeClass('ui-checkbox-off')
			} else {
				$('#shortcutsAdditionalSettings #' + name).addClass('ui-checkbox-off')
				$('#shortcutsAdditionalSettings #' + name).removeClass('ui-checkbox-on')
			}
			name === 'shortcutsHoverSetting' ? shortcutsHoverSetting = setting : shortcutsHighlightSetting = setting
			shortcutsKeys.toggleHighlighting(name, setting);
			if (save) shortcuts.getRouter().settingsPage.change();
		},
		
		toggleHighlighting: function (name, value) {
			if (name === 'shortcutsHighlightSetting' && value === true) {
				$('body').addClass('shortcutsHighlightActive');
			} else if (name === 'shortcutsHighlightSetting' && value === false) {
				$('body').removeClass('shortcutsHighlightActive');
			}
		},
		
		shortcutHelpButton: function (currentRow, shortcut, lines) {
			$(currentRow.find('span')).css('display', 'flex').append('<span' +
				' class="ui-btn ui-icon-info ui-btn-icon-notext ui-corner-all">')
			$(currentRow).find('span.ui-icon-info').hover(function (e) {
				$('body').append(shortcuts.getShortcutsHighlighter().getShortcutsHover().hoverBox);
				var info = ''
				for (var i = 1; i <= lines; i++) {
					info = info + user.translate('shortcut.extendedInformation.' + shortcut + i) + '<br>'
				}
				$('.hoverBoxText').html(info);
				var width = $('body').find('.hoverBox').width() + parseInt($('body').find('.hoverBox').css('padding-left')) + parseInt($('body').find('.hoverBox').css('padding-right'));
				var height = $('body').find('.hoverBox').height() + parseInt($('body').find('.hoverBox').css('padding-top')) + parseInt($('body').find('.hoverBox').css('padding-bottom'));
				$('body').find('.hoverBox').css({
					left: e.clientX >= ($(window).innerWidth() - (width + 5)) ? e.pageX - width - 5 : e.pageX + 5,
					top: e.clientY >= ($(window).innerHeight() - (height + 5)) ? e.pageY - height - 5 : e.pageY + 5
				});
			}, function () {
				$('body').find('.hoverBox').remove();
			});
		},
		
		helpButton: function () {
			$('#shortcutsSettings h2 > span > span').hover(function (e) {
				$('body').append(shortcuts.getShortcutsHighlighter().getShortcutsHover().getHoverBox());
				$('.hoverBoxText').html(infoText.getInfoText());
				var width = $('body').find('.hoverBox').width() + parseInt($('body').find('.hoverBox').css('padding-left')) + parseInt($('body').find('.hoverBox').css('padding-right'));
				if (width >= ($(window).innerWidth() - 200)) width = ($(window).innerWidth() - 200);
				var height = $('body').find('.hoverBox').height() + parseInt($('body').find('.hoverBox').css('padding-top')) + parseInt($('body').find('.hoverBox').css('padding-bottom'));
				$('body').find('.hoverBox').css({
					left: e.clientX >= ($(window).innerWidth() - (width + 5)) ? e.pageX - width - 5 : e.pageX + 5,
					top: e.clientY >= ($(window).innerHeight() - (height + 10)) ? e.pageY - height - 10 : e.pageY + 10,
					width: width + 'px'
				});
			}, function () {
				$('body').find('.hoverBox').remove();
			});
		},
		
		addListeners: function (shortcutsSettingsList, keyList) {
			$('#shortcutsSettingsTable input').on('keydown', function (e) {
				e.preventDefault();
				e.stopPropagation();
				val = e.originalEvent.code;
				if (val !== 'ShiftLeft' && val !== 'ControlLeft' && val !== 'AltLeft' && val !== 'ShiftRight' && val !== 'ControlRight' && val !== 'AltRight') {
					var target = $($(e).get(0).currentTarget);
					i = target.attr('for').split('_')[0];
					var keyName = target.attr('for').split('_')[1];
					var valid = shortcutsKeys.changeKey(i, keyName, val);
					target.blur();
					if (valid) {
						var keyText = shortcutsKeys.characterFromKeyCode(val);
						target.val(keyText);
						var currentKey = shortcutsKeys.getKey(keyName)
						parentKeyList.forEach(function (v, index, array) {
							if (v[0] === currentKey[0]) {
								if (v[1] === currentKey[1] && v[2] === currentKey[2] && v[3] === currentKey[3] && v[4] === currentKey[4]) {
									$(target).closest('tr').find('input[type=button]').removeClass('changed');
								} else {
									$(target).closest('tr').find('input[type=button]').addClass('changed');
								}
							}
						});
					}
				}
			});
			$('#shortcutsSettingsTable label').on('click', function (e) {
				var target = $($(e).get(0).currentTarget);
				var val = null;
				if (target.attr('class').includes('ui-checkbox-on')) {
					val = false;
				} else {
					val = true;
				}
				i = target.attr('for').split('_')[0];
				var keyName = target.attr('for').split('_')[1];
				var valid = shortcutsKeys.changeKey(i, keyName, val);
				if (valid) {
					if (val) {
						target.removeClass('ui-checkbox-off');
						target.addClass('ui-checkbox-on');
					} else {
						target.removeClass('ui-checkbox-on');
						target.addClass('ui-checkbox-off');
					}
					// sameShortcut = '';
					var currentKey = shortcutsKeys.getKey(keyName)
					parentKeyList.forEach(function (v, index, array) {
						if (v[0] === currentKey[0]) {
							if (v[1] === currentKey[1] && v[2] === currentKey[2] && v[3] === currentKey[3] && v[4] === currentKey[4]) {
								$(target).closest('tr').find('input[type=button]').removeClass('changed');
							} else {
								$(target).closest('tr').find('input[type=button]').addClass('changed');
							}
						}
					});
				}
			});
			$('#shortcutsSettingsTable .resetButton').on('click', function (e) {
				shortcutsKeys.resetShortcut(e);
			});
			$('#shortcutsAdditionalSettings label').on('click', function (e) {
				var target = $($(e).get(0).currentTarget);
				if (target.attr('class').includes('ui-checkbox-on')) {
					shortcutsKeys.changeAdditionalConfig(target[0].id, false, true);
				} else {
					shortcutsKeys.changeAdditionalConfig(target[0].id, true, true);
				}
			});
		},
		
		resetShortcut: function (e) {
			var target = $($(e).get(0).currentTarget);
			i = target.attr('for').split('_')[0];
			var keyName = target.attr('for').split('_')[1];
			var valid = shortcutsKeys.changeKey(0, keyName, null)
			if (valid) {
				shortcutsKeys.loadKeys(keyList, shortcutsSettingsList)
			}
		},
		
		resetAllShortcuts: function () {
			keyList = _.cloneDeep(parentKeyList);
			this.loadKeys(_.cloneDeep(keyList), shortcutsSettingsList);
			shortcuts.getRouter().settingsPage.change();
		},
		
		characterFromKeyCode: function (key) {
			var keyText = '';
//			Check if there is a special character for this key
//			If so, assign it to keyText
			charKeyCode.forEach(function (e) {
				if (e[0] == key) {
					keyText = user.translate(e[1]);
				}
			});
//			If there is no special character for this key
//			Assign the casted keyCode
			if (!keyText) {
				matchers.forEach(function (m) {
					if (m.test(key)) {
						keyText = m.exec(key)[1] === 'Numpad' ? 'Num ' : '';
						keyText += m.exec(key)[2].toUpperCase();
					}
				});
			}
			if (!keyText) {
				keyText = key;
			}
			return keyText;
		},
		
		changeKey: function (i, keyName, val) {
			var valid = true;
			var t = null;
			keyList.forEach(function (e) {
				if (e[0] == keyName) {
					t = _.cloneDeep(e);
				}
			});
			if (i !== 0) {
				t[i] = val;
			} else {
				parentKeyList.forEach(function (e) {
					if (e[0] == keyName) t = _.cloneDeep(e);
				})
			}
			var duplicate = null;
			keyList.forEach(function (e) {
				if (t[0] != e[0] && t[1] == e[1] && t[2] == e[2] && t[3] == e[3] && t[4] == e[4]) {
					valid = false;
					duplicate = e;
				}
			});
			if (valid) {
				if (i === 0) {
					keyList.forEach(function (e) {
						if (e[0] == keyName) {
							parentKeyList.forEach(function (ee) {
								if (ee[0] == keyName) {
									e[1] = ee[1];
									e[2] = ee[2];
									e[3] = ee[3];
									e[4] = ee[4];
									e[5] = ee[5];
								}
							})
						}
					});
				} else {
					keyList.forEach(function (e) {
						if (e[0] == keyName) {
							e[i] = val;
						}
					});
				}
//				Trigger a change to save the shortcuts everytime something changes
				shortcuts.getRouter().settingsPage.change();
			} else {
				alertPopup('');
				$('.generic-popup div.ui-content p').html(user.translate('shortcut.duplicatePopup.1') + '<br><br><b' +
					' style="color: red;">' + user.translate('shortcut.settingsPage.' + duplicate[0]) + '</b><br><br><br><u>' + user.translate('shortcut.duplicatePopup.2') + '</u>');
			}
			return valid;
		},
		
		addNonExistingKeys: function () {
			var toAdd = [];
			parentKeyList.forEach(function (e) {
				var keyToUpdate = false;
				keyList.forEach(function (e1) {
					if (e[0] == e1[0]) {
						keyToUpdate = true;
					}
				});
				if (!keyToUpdate) {
					toAdd.push(_.cloneDeep(e));
				}
			});
			return toAdd;
		},
		
		removeNonExistingKeys: function () {
			var toRemove = [];
			keyList.forEach(function (e) {
				var keyToUpdate = false;
				parentKeyList.forEach(function (e1) {
					if (e[0] == e1[0]) {
						keyToUpdate = true;
					}
				});
				if (!keyToUpdate) {
					toRemove.push(_.cloneDeep(e));
				}
			});
			return toRemove;
		},
		
		updateKeys: function () {
			var toUpdate = [];
			keyList.forEach(function (e) {
				var keyToUpdate = null;
				parentKeyList.forEach(function (e1) {
					if (e[0] === e1[0] && (e.length !== e1.length || e[6] !== e1[6] || e[7] !== e1[7])) {
						keyToUpdate = _.cloneDeep(e1);
					}
				});
				if (keyToUpdate) {
					toUpdate.push([keyList.indexOf(e), keyToUpdate]);
				}
			});
			return toUpdate;
		},
		
		sortKeys: function () {
			var sortedKeyList = [];
			parentKeyList.forEach(function (e) {
				keyList.forEach(function (e1) {
					if (e[0] == e1[0]) {
						sortedKeyList.push(e1);
					}
				});
			});
			return sortedKeyList;
		},
		
		updateKeyList: function () {
			var toAdd = shortcutsKeys.addNonExistingKeys();

			toAdd.forEach(function (e) {
				console.log("keyList", keyList, e)
				keyList.push(e);
			})
			var toRemove = shortcutsKeys.removeNonExistingKeys();
			toRemove.forEach(function (e) {
				keyList.splice(keyList.indexOf(e), 1);
			});
			var toUpdate = shortcutsKeys.updateKeys();
			toUpdate.forEach(function (e) {
				if (keyList[e[0]].length != e[1].length) {
					keyList[e[0]] = e[1];
				}
				if (keyList[e[0]][6] != e[1][6]) {
					keyList[e[0]][6] = e[1][6];
				}
				if (keyList[e[0]][7] != e[1][7]) {
					keyList[e[0]][7] = e[1][7];
				}
			});
			var sortedKeyList = shortcutsKeys.sortKeys();
			
			if (toAdd.length > 0 || toRemove.length > 0 || toUpdate.length > 0 || sortedKeyList != keyList) {
				keyList = sortedKeyList;
			}
		},
		
		getKey: function (name) {
			var key = null;
			keyList.forEach(function (e) {
				if (e[0] == name) {
					key = e;
				}
			});
			return key;
		},
		
		checkKey: function (e, name) {
			var key = shortcutsKeys.getKey(name);
			var hasRights = shortcutsKeys.checkRights(key);
			var isOk = key ? (e.originalEvent.code == key[1] && e.ctrlKey == key[2] && e.shiftKey == key[3] && e.altKey == key[4] && key[5] && hasRights) : false;
			if (isOk) $('body').find('.hoverBox').remove();
			return isOk;
		},
		
		checkRights: function (key, keyname) {
			var hasRights = false
			if(!key) key = this.getKey(keyname);
			var rightGroups = key[7].split(',');
			rightGroups.forEach(function (s) {
				if(!hasRights) {
					s = s.replace(/\s/g, '');
					if (s === 'admin' && user.isFullAdmin()) hasRights = true;
					if (s === 'user' && !user.isPrincipal() && !user.isSubcontractor() && !user.isReadonly()) hasRights = true;
					if (s === 'an' && user.isSubcontractor()) hasRights = true;
					if (s === 'ag' && user.isPrincipal() && !user.isReadonly()) hasRights = true;
					if (s === 'agro' && user.isPrincipal() && user.isReadonly()) hasRights = true;
					if (s === 'ro' && user.isReadonly() && !user.isSubcontractor() && !user.isPrincipal()) hasRights = true;
				}
			});
			//
			// if (_.contains(key[7], 'admin')) {
			// 	if (user.isFullAdmin()) hasRights = true
			// }
			// if (_.contains(key[7], 'user')) {
			// 	if (!user.isSubcontractor() && !user.isPrincipal()) hasRights = true
			// }
			// if (_.contains(key[7], 'ag')) {
			// 	if (user.isPrincipal()) hasRights = true
			// }
			// if (_.contains(key[7], 'an')) {
			// 	if (user.isSubcontractor()) hasRights = true
			// }
			return hasRights
		},
		
		getNumberOfKeyCode: function (e) {
			if (e >= 48 && e <= 57) {
				if (e == 48) {
					return 9;
				} else {
					return e - 48 - 1;
				}
			} else {
				if (e == 96) {
					return 9;
				} else {
					return e - 96 - 1;
				}
			}
		},
		
		getParentKeyList: function () {
			return parentKeyList;
		},
		
		getKeyList: function () {
			return keyList;
		},
		
		getHoverSetting: function () {
			return shortcutsHoverSetting;
		},
		
		getHighlightSetting: function () {
			return shortcutsHighlightSetting;
		},
		
		getLanguage: function () {
			return language;
		}
	};
	return shortcutsKeys;
});