define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../model/user', '../model/polygonsAndCfgs', '../model/projectFiles', '../model/Defect'
], function (
	$, _, Backbone, user, polygonsAndCfgs, projectFiles, Defect
) {
	var rocketModeHelper =  {

		initialize: function () {

		},

		getFileIdsToLocation: function (location) {
			var fileIds = {};
			var cfgs = polygonsAndCfgs.get('polygonConfigs');
			for (var fileHash in cfgs) {
				if (cfgs[fileHash]
					&& cfgs[fileHash]['locationId'] !== ""
					&& !cfgs[fileHash]['disablePolygons']
					&& (location.lastIndexOf(cfgs[fileHash]['locationId'], 0) === 0 || cfgs[fileHash]['locationId'].lastIndexOf(location, 0) === 0) //matching location id
					&& projectFiles.findFile(fileHash)	// file is available
					&& this.checkLocationIdColumnsIdentical(location, cfgs[fileHash]['locationId'])
				) {
					fileIds[fileHash] = cfgs[fileHash]['locationId'];
				}
			}
			return fileIds;
		},
		
		checkLocationIdColumnsIdentical: function (chosenLocation, cfgLocation) {
			var chosenlocations = chosenLocation.split('-');
			var cfglocations = cfgLocation.split('-');
			var identical = false;
			_.each(cfglocations, function (l, i) {
				_.each(chosenlocations, function (ll, ii) {
					if(i === ii && l === ll) identical = true;
				})
			})
			return identical;
		},

		getLocationToCheckForFileHashes: function (loc) {
			loc = this.cleanLocationId(loc);
			if (polygonsAndCfgs.get('polygons')[loc]) {
				// polygon of location available
				return this.parseLocationToLocationOfStoreyDepth(polygonsAndCfgs.get('polygons')[loc], loc);
			} else {
				// check for subpolygons
				var polygonLocationIds = Object.keys(polygonsAndCfgs.get('polygons'));
				_.each(polygonLocationIds, function (polyLoc) {
					if (polyLoc.substr(0, loc.length) === loc) {
						// subpolygon found
						return this.parseLocationToLocationOfStoreyDepth(polygonsAndCfgs.get('polygons')[polyLoc], loc);
					}
				}.bind(this))
				// for (var polyLoc of polygonLocationIds) {
				// 	if (polyLoc.substr(0, loc.length) === loc) {
				// 		// subpolygon found
				// 		return this.parseLocationToLocationOfStoreyDepth(polygonsAndCfgs.get('polygons')[polyLoc], loc);
				// 	}
				// }
				//no subpolygons
				return loc;
			}
		},

		parseLocationToLocationOfStoreyDepth: function (polygon, loc) {
			var storeyDepth = polygon.storey_location_depth;
			var locationsUnits = loc.split('-');
			var locToReturn = loc;
			if (locationsUnits.length > storeyDepth) {
				for (var i = 0; i <= storeyDepth; i++) {
					locToReturn += locationsUnits[i] + '-';
				}
			}
			return this.cleanLocationId(locToReturn);
		},

		cleanLocationId: function (locID) {
			return (locID && locID.substring(locID.length - 1) === '-') ? locID.slice(0, -1) : locID;
		},

		getAllPlanFilesOfProject: function(children){  // children <- projectFiles.get(children)
			var pFiles = [];
			if (children){
				children.forEach(function (item) {
					if (item.get('name').indexOf('_') !== 0){
						if (item.get('type') === 'Image' && item.get('hash')) {
							pFiles.push(item);
						} else if (item.get('type') === 'Folder') {
							var addFiles = this.getAllPlanFilesOfProject(item.get('children'));
							addFiles.forEach(function (file) {
								pFiles.push(file);
							}, this);
						}
					}
				}, this);
			}
			return pFiles;
		},

		locationToLocationId: function (loc) {
			return this.cleanLocationId(_.map(loc.slice(0, loc.length - 1), function (name) {
				return Defect.toId(name);
			}, this).join('-'));
		}
	};
	return rocketModeHelper;
});