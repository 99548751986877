define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', 'moment', '../model/user',
	'../model/subcontractors', '../model/inspections', '../model/projects', '../model/Project', '../model/currentProject',
	'../helper/interactiveSync'
], function (
	$, _, Backbone, moment, user,
	subcontractors, inspections, projects, Project, currentProject,
	interactiveSync
) {
	return new (Backbone.Model.extend({

		url: function () {
			 return  '/onlineBauabnahme/api/completions/' + this.project;
		},

		setProject: function (project) {
			if (project == this.project) {
				if (this.pending) {
					return this.pending;
				} else {
					return $.Deferred().resolve();
				}
			}
			var oldProject = this.project;
			this.project = project;
			this.clear({ silent: true });
			if (!project) {
				return $.Deferred().resolve();
			}
			this.pending = this.fetch()
			.fail(_.bind(function () {
				this.project = oldProject;
			}, this))
			.always(_.bind(function () {
				delete this.pending;
			}, this));
			return this.pending;
		},

		fetch: function () {
			return Backbone.Model.prototype.fetch.apply(this, arguments).done(_.bind(function () {
				_.each([ 'locationFragments', 'descriptionFragments' ], function (collectionKey) {
					var collection = this.get(collectionKey), result = {};
					_.each(_.keys(collection), function (key) {
						var simplified = [], match;
						while (match = this.recognizedWords.exec(key)) {
							simplified.push(key.substring(
									match.index,
									this.recognizedWords.lastIndex
							).toLowerCase());
						}
						result[simplified.join(' ')] = {
								count: this.get(collectionKey)[key],
								orig:  key
						};
					}, this);
					this.set(collectionKey, result);
				}, this);
			}, this));
		},

		//unicode letters + digits
		recognizedWords:  /[\u00C0-\u1FFF\u2C00-\uD7FF\w\d]{3,}/g,
		recognizedSuffix: /[\u00C0-\u1FFF\u2C00-\uD7FF\w\d]+/,

		lookup: function (str, collection) {
			var simplified = this.simplify(str);
			return _.pluck(_.sortBy(_.values(_.reduce(_.flatten(_.map(_.range(simplified.words.length), function (i) {
				var word = simplified.words.slice(i).join(' ');
				return _.map(_.filter(_.keys(collection), function (entry) {
					return entry.length > word.length && entry.indexOf(word) == 0;
				}), function (entry) {
					return {
						str: simplified.prefixes[i] + collection[entry].orig,
						weight: collection[entry].count * (simplified.words.length - i)
					};
				});
			})), function (hashmap, value) {
				var member = hashmap[value.str.toLowerCase()];
				if (member) {
					member.weight += value.weight;
				} else {
					hashmap[value.str.toLowerCase()] = value;
				}
				return hashmap;
			}, {})), function (entry) {
				return -entry.weight;
			}), 'str').slice(0, 5);
		},
		locationLookup: function (tree, result) {
                for (var child in tree.children) {
                    if (tree.children) {
                    	var title = this.locationLookup(tree.children[child], result);
                            result.push(title);
                        }
                    }
                return tree.title ? tree.title : result;
        },

		locationTitleLookup: function (tree, paths, result) {

			for (var child in tree.children) {

				if (tree.children) {
					this.locationTitleLookup(tree.children[child], paths,  result);
				}
			}

			if (_.contains(paths, tree.path)) {
				result.push(tree.title);
			}

			return result;
		},


		subcontractorLookup: function (str) {
			if (str) {
				var regex = new RegExp(str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i');
				// var byName = _(subcontractors.models)
				// 	.filter(function (model) { return regex.test(model.get('name')); })
				// 	.map(function (model) { return model.getLabel(); });
				//
				// var byLabel = _(subcontractors.models)
				// 	.map(function (model) { return model.getLabel(); })
				// 	.filter(function (label) { return regex.test(label); });
				//
				// return byName.union(byLabel.value()).value();
				
				return _(subcontractors.models)
					.filter(function (model) {
						var r = false;
						if(regex.test(model.get('name'))) r = true;
						if(regex.test(model.get('crew'))) r = true;
						if(regex.test(model.get('shortname'))) r = true;
						return r;
					})
					.map(function (model) { return model.getLabel(); })
					.value();
			} else {
				return _(subcontractors.models)
					.map(function (model) { return model.getLabel(); })
					.value();
			}
		},

		projectLookup: function (str) {
			var noProjectName = currentProject.isNew() ? user.translate('overview.choose2') : user.translate('overview.choose');
			var dropdownList = [];

			if (!user.isSubcontractor()) {
				dropdownList = [[noProjectName, '']];
			}

			var chain = _(projects.models);
			if (str) {
				var regex = new RegExp(str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i');
				chain = chain.filter(function (model) {
					return regex.test(model.get('name')) || regex.test(model.get('id'));
				});
			}
			chain
				.filter(function (f) {
					return (!f.get('isArchived'));
				})
				.sortBy(function(p) {
					return (p.get('name') && p.get('name').toLowerCase());
				})
				.map(function (model) {
					return [model.get('name'), model.get('id')];
				})
				.value()
				.forEach(function(e) {
					dropdownList.push(e);
				});
			return dropdownList;
		},

		inspectionLookup: function (str, onlyExternal) {
			var regex = str ? new RegExp(str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i') : null;
			return _.filter(inspections.labelIndex, function (label, index) {
				if (onlyExternal) {
					var inspection = inspections.at(index), type = inspection.getType();
					if (!type || !type.external) {
						return false;
					}
				}
				if (regex) {
					return regex.test(label);
				}
				return true;
			}, this);
		},

		addString: function (str, collection) {
			var simplified = this.simplify(str);
			for (var i = 0; i < simplified.words.length; i++) {
				var fragment = simplified.words.slice(i, simplified.words.length).join(' ');
				if (fragment in collection) {
					collection[fragment].count++;
				} else {
					collection[fragment] = {
						count: 1,
						orig: simplified.suffixes[i]
					};
				}
			}
		},

		simplify: function (str) {
			var match, lastIndex = 0, result = {
					words: [],
					prefixes: [],
					suffixes: []
			};
			while (match = this.recognizedWords.exec(str)) {
				result.words.push(str.substring(match.index, lastIndex = this.recognizedWords.lastIndex).toLowerCase());
				result.prefixes.push(str.substring(0, match.index));
				result.suffixes.push(str.substring(match.index));
			}
			var rest = str.substring(lastIndex);
			if (match = this.recognizedSuffix.exec(rest)) {
				result.words.push(rest.substring(match.index).toLowerCase());
				result.prefixes.push(str.substring(0, lastIndex + match.index));
				result.suffixes.push(str.substring(lastIndex + match.index));
			}
			return result;
		},

        sync: function (method, model, options) {
            return interactiveSync.sync.apply(interactiveSync, [method, model, proxyInfo.syncAddHeaders(options)]);
        }


	}))();
});