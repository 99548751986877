define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './LoginPageHtml',
	'../model/user',
	'../helper/iosapp', '../helper/watchdog'
], function (
	$, _, Backbone, template,
	user, iosapp, watchdog
) {
	return Backbone.View.extend({

		id: 'login',

		attributes: {
			'data-role': 'page'
		},

		events: {
			'submit form': 'login',
			'keypress input[name="username"]': 'usernameKeypress',
			'keypress input[name="password"]': 'passwordKeypress',
//			'blur .hover *':                   'unflip'
		},

		initialize: function (options) {
			// this.flip   = _.bind(this.flip, this);
			// this.unflip = _.debounce(_.bind(this.unflip, this), 1000);
			//
			// this.$el.html(template).appendTo($.mobile.pageContainer);
			//
			// this.info     = this.$('.info');
			// this.hoverbox = this.$('.hover');
			// this.username = this.$('input[name="username"]');
			// this.password = this.$('input[name="password"]');
			//
			// //this.hoverbox.hover(this.flip);//, this.unflip);
			// this.flip();
			//
			// if (!user.isNew()) {
			// 	this.username.val(user.id);
			// }

			// if (oldBrowser) {
			// 	this.$el.on('pagecreate', _.bind(function () {
			// 		this.username.placeholder();
			// 		this.password.placeholder();
			// 	}, this));
			// }
			//
		    // var islogout = false;
		    // var pageurl = window.location.search.substring(1);
		    // var urlvars = pageurl.split('&');
		    // for (var i = 0; i < urlvars.length; i++)
		    // {
		    //     var paramname = urlvars[i].split('=');
		    //     if (paramname[0] == "logout" && paramname[1] && paramname[1].toLowerCase() == "true") {
		    //         islogout = true;
		    //         break;
		    //     }
		    // }

		},

		render: function() {
			//for teams
			// window.parent.postMessage('ox.event.loginpage', 'https://openexperience.de/');
			// window.parent.postMessage('ox.event.loginpage', 'https://tomt.openexperience.de/');
		},

		flip: function () {
			// this.hoverbox.addClass('flip');
			// if (!iosapp.appavailable) {
			// 	this.info.show();
			// }
//			if (!oldBrowser) {
//				_.defer(function (self) {
//					if (self.username.val()) {
//						self.password.focus();
//					} else {
//						self.username.focus();
//					}
//				}, this);
//			}
		},

		unflip: function () {
			// if (!this.hoverbox.find('*').is('.ui-focus')) {
			// 	this.hoverbox.removeClass('flip');
			// }
		},

		login: function (e) {
			// if (e) {
			// 	e.preventDefault();
			// 	e.stopPropagation();
			// }
			// $.mobile.loading('show');
			// watchdog.setConnected(); //this is a hack to prevent being offline because of delayed version calls due to loading the js files
			// user.login(this.username.val(), this.password.val())
			// .done(_.bind(function () {
			// 	Backbone.Events.trigger('loginSuccess');
			// 	if (Backbone.history.fragment === 'login') {
			// 		Backbone.history.navigate('overview', { trigger: true });
			// 	}
			// 	this.password.val(null);
			// }, this))
			// .fail(_.bind(function (cause) {
			// 	var resetPW = true;
			// 	if (cause.status !== 0 && cause.status !== 403) {
			// 		window.alertPopup('Beim Login ist ein unerwarteter Fehler aufgetreten! (' + cause.status + ')\n\nBitte wenden Sie sich an den Support.');
			// 	}
			//
			// 	if (cause.responseJSON && cause.responseJSON.errorCode === 'login.error.noaccess') {
			// 		window.alertPopup('Die Logindaten sind korrekt, aber Sie haben keinen Zugang zum Service Bau-Mängel. Bitte kontaktieren Sie Ihren Projektadminstrator.');
			// 	}
			//
			// 	if (cause.status === 0 || cause == 'offline') {
			// 		window.alertPopup('Der Server konnte nicht erreicht werden.\nBitte prüfen Sie Ihre Internetverbindung\nund versuchen Sie es erneut.');
			// 		resetPW = false;
			// 	}
			//
			// 	if (cause.status !== 0 && cause != 'offline') {
			// 		this.$('form .ui-controlgroup-controls').shake(3, 10, 300);
			// 		this.password.click();
			// 	}
			// 	if (resetPW) {
			// 		this.password.val(null);
			// 	}
			// }, this))
			// .always(_.bind(function () {
			// 	$.mobile.loading('hide');
			// }, this));
		},

		usernameKeypress: function (e) {
			// if (e.keyCode == 13) {
			// 	e.preventDefault();
			// 	e.stopPropagation();
			// 	if (!oldBrowser) {
			// 		this.password.focus();
			// 	}
			// }
		},

		passwordKeypress: function (e) {
			// if (e.keyCode == 13) {
			// 	if (oldBrowser) {
			// 		e.preventDefault();
			// 		e.stopPropagation();
			// 		this.login();
			// 	}
			// }
		}
		
	});
});