define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat',
	'../model/Project'
], function (
	$, _,
	Project
) {
	return new (Project.extend({

		setProject: function (project) {
			if (project == this.id) {
				if (this.pending) {
					return this.pending;
				} else {
					return $.Deferred().resolve();
				}
			} else {
				if (this.pending) {
					//please make sure there is only one setProject/fetch running simultaneously.
					//Otherwise this oldProject mechanism will not work properly and will be dependent on the order
					//of fetch completion
					console.log("Problem detected: multiple setProject calls");
				}
			}
			var oldProject = this.id;
			if (!project) {
				this.clear({ silent: true });
				return $.Deferred().resolve();
			}
			this.set('id', project);

			//yes, do this with a local variable and step by step, because the .fail/.always handlers MIGHT BE EXECUTED RIGHT AWAY
			//so, e.g. this.pending = this.fetch().always(function() {delete this.pending}) CAN result in this.pending being set and the .always handler already executed! (e.g. if you are offline, test via dev tools offline)
			//WTF?!
			var pending = this.pending = this.fetch({ oxclear: true });
			pending.fail(_.bind(function () {
			    console.log("arguments: ", arguments);
				this.set('id', oldProject);
			}, this));
			pending.always(_.bind(function () {
				delete this.pending;
			}, this));
			return pending;
		}

	}))();
});
