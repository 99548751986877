
export function clampImageSize(file: File, max: number) {
    return new Promise<File>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (e) {
            const img = new Image();
            img.src = e.target!.result as string;

            img.onerror = reject;
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = max, maxHeight = max;
                let newWidth = img.width;
                let newHeight = img.height;

                if (img.width > maxWidth || img.height > maxHeight) {
                    const aspectRatio = img.width / img.height;

                    if (aspectRatio > 1) {
                        newWidth = maxWidth;
                        newHeight = maxWidth / aspectRatio;
                    } else {
                        newHeight = maxHeight;
                        newWidth = maxHeight * aspectRatio;
                    }
                } else {
                    resolve(file);
                    return;
                }

                canvas.width = newWidth;
                canvas.height = newHeight;

                ctx!.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob(
                    (blob) => {
                        const compressedFile = new File([blob as Blob], file.name, {
                            type: file.type,
                            lastModified: Date.now(),
                        });
                        //shouldn't I remove the canvas here?
                        resolve(compressedFile);
                    },
                    file.type, 0.9
                );
            };
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

export function fileToBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
        if(!file.size) {
            reject();
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            if(e.target){
                resolve(e.target.result as string);
            } else {
                reject();
            }
        }
        reader.onerror = reader.onabort = reject;
        reader.readAsDataURL(file);
    });
}
