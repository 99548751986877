define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../model/user', 'moment',
	'../helper/interactiveSync', '../model/proxyInfo'
], function (
	$, _, Backbone, user, moment,
	interactiveSync, proxyInfo
) {
	return Backbone.Model.extend({

		urlRoot:  '/onlineBauabnahme/api/project',

		//sync: function () {
		//	return interactiveSync.sync.apply(interactiveSync, arguments);
		//},

		sync: function (method, model, options) {
			var success = options.success;
			var that = this;
			options.success = function() {
				if (options.oxclear) {
					that.clear({ silent: true });
				}
				if (success) success.apply(this, arguments);
			};
            return Backbone.sync.apply(this, [method, model, proxyInfo.syncAddHeaders(options)]);
        },

		parse: function (response) {
			response.units = new Backbone.Collection(response.units);
			if (response.info == null) {
				response.info = {
					headline: '',
					image: null,
					table: []
				};
			}
			return response;
		},

		saveInfo: function () {
			return Backbone.ajax({
				url:  '/api/projectInfo/' + this.id,
				type: 'POST',
				data: JSON.stringify(this.get('info')),
				contentType: 'application/json'
			});
		},

		getUnit: function(path) {
			if (path === null || path === undefined) {
				return null;
			}

			var tree = this.get('unitTree');
			var choice = path.split('-');
			var location = [];
			var lastUnit = null;

			for(var i = 0; i < choice.length; i++) {
				tree = (tree && tree.children) ? tree.children[choice[i]] : null;
				if(tree){
					location.push(tree.name);
					lastUnit = tree;
				}
			}

			if (!lastUnit) {
				return null;
			}

			return {
				fullMatch: (choice.length === location.length),
				name: lastUnit.name,
				path: lastUnit.path,
				title: lastUnit.title,
				locationArray: location
			};
		},

		getUnitTitle: function (path) {
			var node = this.get('unitTree');
			var toVisit = _.map(node.children);

			while (toVisit.length > 0) {
				node = toVisit.pop();
				if (node.path === path) {
					return node.title;
				}
				if (node.children) {
					toVisit = toVisit.concat(_.map(node.children));
				}
			}
			return null;
		},

		getStructureKey: function (index) {
			var structureKeys = this.get('structureKeys');
			if (index < structureKeys.length && structureKeys[index] && structureKeys[index].trim()) {
				return '\u2014 ' + structureKeys[index] + ' (' + user.translate('defects.anylocation') + ') \u2014';
			}
			return '...';
		},

		unitAvail: function (unit) {
			var children = _.pairs(this.get('unitTree').children),
				parts = unit.split('-');
			while (parts.length > 0 && parts[parts.length - 1] == '*') {
				parts.pop();
			}
			for (var i = 0; i < parts.length; i++) {
				var part = parts[i], newChildren = [];
				var filtered = (part == '*' ? children : _.filter(children, function (child) {
					return child[0] == part;
				}));
				if (filtered.length > 0) {
					_.each(filtered, function (child) {
						_.each(_.pairs(child[1].children), function (pair) {
							newChildren.push(pair);
						});
					});
				} else {
					return false;
				}
				children = newChildren;
			}
			return true;
		}

	});
});