define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" data-position=\"fixed\">\n" +
        "    <h1 data-role=\"heading\"><%- t('choosecustomer.title') %></h1>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "    <div class=\"listlabel\">\n" +
        "        <br/>\n" +
        "        <%- t('choosecustomer.message') %>\n" +
        "    </div>\n" +
        "    <ul data-role=\"listview\" data-inset=\"true\" class=\"content\">\n" +
        "\n" +
        "    </ul>\n" +
        "    <div class=\"auto-choose-message\" style=\"display: none;\"><%- t('choosecustomer.autowait') %></div>\n" +
        "    <div class=\"auto-choose-notfound\" style=\"display: none;\">\n" +
        "        <div class=\"message\"><%- t('choosecustomer.auto-notfound') %></div>\n" +
        "        <button class=\"logout ui-btn\"><%- t('sidepanel.logout') %></button>\n" +
        "    </div>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" data-position=\"fixed\"></div>\n";
    return page;
});