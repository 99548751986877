define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id='statistics-page-header' data-position=\"fixed\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\t<div class=\"projectstats\"></div>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='statistics-page-footer' data-position=\"fixed\"></div>";
    return page;
});