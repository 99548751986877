define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" data-position=\"fixed\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "    <div class=\"accesses\">\n" +
        "        <h2><%= t('settings.buyeraccess.header') %></h2>\n" +
        "        <div class=\"section-existing-accesses\">\n" +
        "            <h3>Bestehende Zugänge</h3>\n" +
        "            <div class=\"accesses-existing\">\n" +
        "                <div class=\"accesses-filter\">\n" +
        "                    <span class=\"accesses-filter-role-container\">\n" +
        "                    </span>\n" +
        "                    <span class=\"accesses-filter-location-container\">\n" +
        "                    </span>\n" +
        "                </div>\n" +
        "                <div class=\"accessesTable-container\">\n" +
        "                    <table class=\"accessTable\">\n" +
        "                        <thead>\n" +
        "                            <tr>\n" +
        "                                <td class=\"accessTable-cb-td\"><input type=\"checkbox\" id=\"accessTable-cb-all\" name=\"accessTable-cb-all\" /></td>\n" +
        "                                <td class=\"column-role\"><%= t('settings.buyer.accessRole') %></td>\n" +
        "                                <td class=\"column-location\"><%= t('settings.buyer.location') %></td>\n" +
        "                                <td class=\"column-comment\"><%= t('settings.buyer.comment') %></td>\n" +
        "                            </tr>\n" +
        "                        </thead>\n" +
        "                        <tbody>\n" +
        "                        </tbody>\n" +
        "                    </table>\n" +
        "                </div>\n" +
        "                <div class=\"accessesTable-noexistingfilter\" style=\"display: none\">\n" +
        "                    <%= t('settings.buyeraccess.noexistingfilter') %>\n" +
        "                </div>\n" +
        "                <div class=\"access-qr-codes\">\n" +
        "                    <button class=\"access-create-qr-codes\"><%= t('settings.buyer.createQrCodes') %></button>\n" +
        "                    <button class=\"access-remove-selected\"><%= t('settings.buyer.removeSelected') %></button>\n" +
        "                </div>\n" +
        "            </div>\n" +
        "            <div class=\"accesses-noexisting\" style=\"display: none\">\n" +
        "                <%= t('settings.buyeraccess.noexisting') %>\n" +
        "            </div>\n" +
        "        <div class=\"section-new-accesses\">\n" +
        "            <h3>Zugang erstellen</h3>\n" +
        "            <div class=\"newAccess\">\n" +
        "                <div class=\"newAccess-location-container\">\n" +
        "                    <label for=\"newAccess-location\"><%= t('settings.buyer.newAccessLocation') %></label>\n" +
        "                    <select class=\"newAccess-location\" name=\"newAccess-location\" id=\"newAccess-location\"></select>\n" +
        "                </div>\n" +
        "                <div class=\"newAccess-role-container\">\n" +
        "                    <label for=\"newAccess-role\"><%= t('settings.buyer.newAccessRole') %></label>\n" +
        "                    <select class=\"newAccess-role\" name=\"newAccess-role\" id=\"newAccess-role\"></select>\n" +
        "                </div>\n" +
        "                <div class=\"newAccess-comment-container\">\n" +
        "                    <label for=\"newAccess-comment\"><%= t('settings.buyer.newAccessComment') %></label>\n" +
        "                    <input type=\"text\" class=\"newAccess-comment\" name=\"newAccess-comment\" id=\"newAccess-comment\"></input>\n" +
        "                </div>\n" +
        "                <button class=\"newAccess-create\"><%= t('settings.buyer.createAccess') %></button>\n" +
        "            </div>\n" +
        "        </div>\n" +
        "    </div>\n" +
        "    <h2>Rollen</h2>\n" +
        "        <div class=\"section-content-roles\">\n" +
        "            <div class=\"access-mustConfigureRoles\"><%= t('settings.buyer.mustConfigureRoles') %></div>\n" +
        "            <button class=\"access-toRoles\"><%= t('settings.buyer.toRoles') %></button>\n" +
        "        </div>\n" +
        "    <div class=\"documents\">\n" +
        "        <h2>Übergreifende Dokumente<!-- <%= t('settings.buyer.documentsHeader') %> --></h2>\n" +
        "        <div>Sie haben die Möglichkeit Dokumente zu hinterlegen, die von den Nutzern eingesehen werden können. Sie können die Dokumente für alle, für eine Rolle, für ein Projektstrukturelement (z. B. alle Zugänge auf der 3. Etage), oder einen einzelnen Ort hochladen. Es sind keine Unterverzeichnisse erlaubt, alle Dokumente müssen in einem Verzeichnis liegen.</div>\n" +
        "        <button class=\"documents-toroot\"><%= t('settings.buyer.toDocumentsRoot') %></button>\n" +
        "        <div class=\"ui-field-contain\">\n" +
        "            <fieldset data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "                <select class=\"documents-torole-select\"></select>\n" +
        "                <button class=\"documents-torole\"><%= t('settings.buyer.toDocumentRole') %></button>\n" +
        "            </fieldset>\n" +
        "        </div>\n" +
        "    </div>\n" +
        "\n" +
        "    <div class=\"page-end-spacer\">\n" +
        "    </div>\n" +
        "\n" +
        "        <div data-role=\"popup\" class=\"qrCodeDialog\" data-dismissible=\"false\" style=\"max-width:400px; display: none;\">\n" +
        "        <div class=\"ui-header ui-bar-a\" data-role=\"header\" data-theme=\"a\">\n" +
        "            <h1 class=\"ui-title\"><%= t('settings.buyer.qrdoc.dialogheader') %></h1>\n" +
        "        </div>\n" +
        "        <div class=\"ui-content\">\n" +
        "            <fieldset class=\"qrCodeFirstLine\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "                <legend><%= t('settings.buyer.qrdoc.firstline') %></legend>\n" +
        "                <input type=\"text\" class=\"firstline\" data-wrapper-class=\"controlgroup-textinput\"/>\n" +
        "            </fieldset>\n" +
        "            <fieldset class=\"qrCodeSecondLine\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "                <legend><%= t('settings.buyer.qrdoc.secondline') %></legend>\n" +
        "                <input type=\"text\" class=\"secondline\" data-wrapper-class=\"controlgroup-textinput\"/>\n" +
        "            </fieldset>\n" +
        "            <button class=\"cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\" data-transition=\"flow\"><%= t('defect.deadline.cancel') %></button>\n" +
        "            <button class=\"one ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\"><%= t('settings.buyer.qrdoc.one') %></button>\n" +
        "            <button class=\"separate ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\"><%= t('settings.buyer.qrdoc.separate') %></button>\n" +
        "        </div>\n" +
        "    </div>\n" +
        "\n" +
        "</div>\n";
    return page;
});