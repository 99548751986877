define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../model/AttachmentSource',
	'../helper/interactiveSync', '../model/proxyInfo'
], function (
	$, _, Backbone, AttachmentSource,
	interactiveSync, proxyInfo
) {
	return new (Backbone.Collection.extend({

		model: AttachmentSource,

		url: function () {
			 return  '/onlineBauabnahme/api/attachment-sources/' + this.project;
		},

		setProject: function (project) {
			if (project == this.project) {
				if (this.pending) {
					return this.pending;
				} else {
					return $.Deferred().resolve();
				}
			}
			var oldProject = this.project;
			this.project = project;
			if (!project) {
				this.reset();
				return $.Deferred().resolve();
			}
			this.pending = this.fetch({ reset: true })
			.fail(_.bind(function () {
				this.project = oldProject;
			}, this))
			.always(_.bind(function () {
				delete this.pending;
			}, this));
			return this.pending;
		},

		sync: function (method, model, options) {
			return interactiveSync.sync.apply(interactiveSync, [method, model, proxyInfo.syncAddHeaders(options)]);
		}

	}));
});
