define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2',
	'../model/Participant', '../helper/interactiveSync', '../model/proxyInfo'
], function (
	$, _, Backbone,
	Participant, interactiveSync, proxyInfo
) {
	return new (Backbone.Collection.extend({

		model: Participant,

		url: function () {
			 return  '/onlineBauabnahme/api/participant/' + this.project;
		},

		setProject: function (project) {
			if (project == this.project) {
				if (this.pending) {
					return this.pending;
				} else {
					return $.Deferred().resolve();
				}
			}
			var oldProject = this.project;
			this.project = project;
			if (!project) {
				this.reset();
				return $.Deferred().resolve();
			}
			this.pending = this.fetch({ reset: true })
			.fail(_.bind(function () {
				this.project = oldProject;
			}, this))
			.always(_.bind(function () {
				delete this.pending;
			}, this));
			return this.pending;
		},

		lookup: function (str) {
			str = (str || '').toLowerCase();
			if (str.length > 0) {
				return _.map(this.filter(function (participant) {
					var name = (participant.get('name') || '').toLowerCase(),
						label = (participant.getLabel() || '').toLowerCase();
					return str.length > 1
						? name.indexOf(str) !== -1 || label.indexOf(str) !== -1
						: name.indexOf(str) === 0 || label.indexOf(str) === 0;
				}, this), function (participant) { return participant.getLabel(); }).slice(0, 5);
			} else {
				return [];
			}
		},

		findByLabel: function (label) {
			return this.find(function (participant) {
				return label == participant.getLabel();
			}, this);
		},

		sync: function (method, model, options) {
			return interactiveSync.sync.apply(interactiveSync, [method, model, proxyInfo.syncAddHeaders(options)]);
		}

	}))();
});