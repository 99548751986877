define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id='files-page-header'>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\t<div class=\"rocketModeFiles\" style=\"border-bottom: 1px solid black; margin-bottom: 1em\">\n" +
        "\t\t<p style=\"margin-bottom: 0\"><%= t('files.rocketMode') %></p>\n" +
        "\t\t<ul data-role=\"listview\" data-inset=\"true\" class=\"rocketMode-files\"></ul>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"mostcommon\" style=\"border-bottom: 1px solid black; margin-bottom: 1em\">\n" +
        "\t\t<p style=\"margin-bottom: 0\"><%= t('files.mostCommon') %></p>\n" +
        "\t\t<ul data-role=\"listview\" data-inset=\"true\" class=\"mostcommon-files\"></ul>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"recent\" style=\"border-bottom: 1px solid black; margin-bottom: 1em\">\n" +
        "\t\t<p style=\"margin-bottom: 0\"><%= t('files.recent') %></p>\n" +
        "\t\t<ul data-role=\"listview\" data-inset=\"true\" class=\"recent-files\"></ul>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"location\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t<button class=\"parent ui-btn ui-btn-icon-left ui-icon-carat-l\"><%= t('files.parent') %></button>\n" +
        "\t\t<input class=\"path\" readonly=\"readonly\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<ul data-role=\"listview\" data-inset=\"true\" class=\"files\"></ul>\n" +
        "\n" +
        "\t<div class=\"manage\" data-enhance=\"false\">\n" +
        "\n" +
        "\t\t<form enctype=\"multipart/form-data\">\n" +
        "\t\t\t<label><%= t('files.upload') %> <input type=\"file\" name=\"file\" class=\"fileupload\"/> <%= t('files.types') %></label>\n" +
        "\t\t\t<label class=\"fileuploadcairocontainer\"><%= t('files.upload.simplify') %> <input type=\"checkbox\" name=\"cairo\" class=\"fileuploadcairo\" /></label>\n" +
        "\t\t</form>\n" +
        "\t\n" +
        "\t\t<div class=\"foldercontrol\">\n" +
        "            <input id=\"foldername\" type=\"text\" placeholder=\"<%= t('files.dir.placeholder') %>\"/> <button><%= t('files.dir.create') %></button>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t</div>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"popup\" class=\"actionsmenu\">\n" +
        "\t<div class=\"actionsmenucg\" data-role=\"controlgroup\" style=\"margin: 0\">\n" +
        "\t\t<ul data-role=\"listview\" style=\"font-size: 14px; width: 300px\">\n" +
        "\n" +
        "\t\t\t<li><a class=\"manageFiles\"></a></li>\n" +
        "\n" +
        "\t\t\t<li id=\"defectsMenuSelectionDivider\" data-role=\"list-divider\"><%= t('defects.menu.selection') %></li>\n" +
        "\t\t\t<li><a class=\"deleteFiles\"><%- t('files.multiple.remove') %></a></li>\n" +
        "\t\t\t<li class=\"hideFilesForSubcontractorContainer\"><a class=\"hideFilesForSubcontractor\"><%- t('files.multiple.hide') %></a></li>\n" +
        "\t\t\t<li class=\"showFilesForSubcontractorContainer\"><a class=\"showFilesForSubcontractor\"><%- t('files.multiple.show') %></a></li>\n" +
        "\t\t\t<li class=\"hideFilesForPrincipalContainer\"><a class=\"hideFilesForPrincipal\"><%- t('files.multiple.hideprinc') %></a></li>\n" +
        "\t\t\t<li class=\"showFilesForPrincipalContainer\"><a class=\"showFilesForPrincipal\"><%- t('files.multiple.showprinc') %></a></li>\n" +
        "\t\t\t<li id=\"defectsMenuSettingsDivider\" data-role=\"list-divider\"><%= t('sidepanel.settings') %></li>\n" +
        "\t\t\t<li class=\"fileUploadSettingsMenu\">\n" +
        "\t\t\t\t<label for=\"files-defaultUploadSettings\" class=\"filesPageLabel\"><%= t('files.defaultuploadsettings.title') %></label>\n" +
        "\t\t\t\t<select name=\"files-defaultUploadSettings\" id=\"files-defaultUploadSettings\">\n" +
        "\t\t\t\t\t<option value=\"NONE\"><%= t('settings.defaultuploadsettings.none') %></option>\n" +
        "\t\t\t\t\t<option value=\"AG\"><%= t('settings.defaultuploadsettings.ag') %></option>\n" +
        "\t\t\t\t\t<option value=\"AN\"><%= t('settings.defaultuploadsettings.an') %></option>\n" +
        "\t\t\t\t\t<option value=\"AGAN\"><%= t('settings.defaultuploadsettings.agan') %></option>\n" +
        "\t\t\t\t</select>\n" +
        "\t\t\t</li>\n" +
        "\t\t\t<li class=\"addAsImageContainer\"><a class=\"addAsImage\"><%= t('filespage.imageToDefect.menu') %></a></li>\n" +
        "\t\t</ul>\n" +
        "\t</div>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"popup\" class=\"confirm-delete-file-dialog\" data-dismissible=\"false\" style=\"width: 350px; display: none;\">\n" +
        "\t<div class=\"ui-content ui-mini\">\n" +
        "\n" +
        "\t\t<div class=\"message\" style=\"margin-top: 1em\"></div>\n" +
        "\t\t<div style=\"text-align: right\">\n" +
        "\t\t\t<button class=\"delete ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\"><%- t('files.remove.deletebtn') %></button>\n" +
        "\t\t\t<button class=\"replace ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" style=\"margin-right: 0\"><%- t('files.remove.replacebtn') %></button>\n" +
        "\t\t\t<button class=\"cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" style=\"margin-right: 0\"><%- t('popup.default.cancel') %></button>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t</div>\n" +
        "</div>\n" +
        "\n" +
        "\n" +
        "<div data-role=\"footer\" id='files-page-footer' data-position=\"fixed\"></div>\n" +
        "\n" +
        "<div id=\"popupInfoBox\" class=\"popup\" style=\"display: none\">Placeholder</div>";
    return page;
});