define([
	'../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', 'moment',
	'../model/user', '../helper/interactiveSync', '../model/proxyInfo'
], function (
	_, Backbone, moment,
	user, interactiveSync, proxyInfo
) {

	var Inspection = window.Inspection = Backbone.Model.extend({

		defaults: function () {
			return {
				participants: [],
                emailReceivers: [],
				finished: false,
				archive: false,
				useExternalIDs: false,
				deadline: null,
				freetext: '',
				groupBy: 'none',
				orderBy: null,
				fileName: null,
				customerProperties: {}
			};
		},

		clear: function () {
			Backbone.Model.prototype.clear.apply(this, arguments);
			this.set(_.result(this, 'defaults'), { silent: true });
		},

		sync: function (method, model, options) {
			return interactiveSync.sync.apply(interactiveSync, [method, model, proxyInfo.syncAddHeaders(options)]);
		},

		getTypeName: function () {
			var type = this.getType();
			return type ? type.name : this.get('type');
		},

		getType: function () {
			return _.findWhere(user.get('inspections'), { id: this.get('type') });
		},

		getLabel: function () {
			var date = this.get('date');
			return (date ? moment(date).format('L') : '') + ', ' + this.get('title');
		}

	});

	return Inspection;
});
