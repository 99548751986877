define([
	'../../lib/jquery-1.11.0', '../../lib/lodash-2.4.1.compat', '../../lib/backbone-1.1.2', '../../model/user', './shortcutsHover'
], function (
	$, _, Backbone, user, shortcutsHover
) {
	var firstTime;
	
	var shortcuts;
	
	var shortcutsHighlighter = {
		
		initialize: function (sc) {
			shortcuts = sc;
			firstTime = true;
			shortcutsHover.initialize(shortcuts);
			shortcutsHighlighter.highlightShortcuts();
		},

//		Highlighting
		highlightShortcuts: function () {
			if (shortcuts && shortcuts.getIsActive()) {
				$('*[data-shortcut]:not(.shortcut)').each(function () {
					var name = $(this).data('shortcut');
					if(shortcuts.getShortcutsKeys().checkRights(null, name)) {
						$(this).addClass('shortcut')
						shortcutsHover.addHoverToShortcut(this, name, $(this).data('inputname'))
					}
				})
			}
		},
		
		getShortcutsHover: function () {
			return shortcutsHover
		}
	};
	return shortcutsHighlighter;
});