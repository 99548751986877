define([
	'../lib/jquery-1.11.0', '../lib/backbone-1.1.2', '../model/currentProject',
	'../helper/interactiveSync'
], function (
	$, Backbone, currentProject,
	interactiveSync
) {
	return new (Backbone.Model.extend({

		defaults: function () {
			return {
				documents: []
			};
		},

		url:  function() {
			return '/onlineBauabnahme/api/paperwork?bundleSessionId=' + (this.get('bundleSessionId') || '');
		},

		sync: function () {
			return interactiveSync.sync.apply(interactiveSync, arguments);
		},

		parse: function (response) {
			response.groups = _.pairs(_.groupBy(response.documents, function (document) { return document.subcontractor }));
			return response;
		},

		preparePaperwork: function (data) {
			return $.ajax({
				type: 'POST',
				url:'/onlineBauabnahme/api/preparePaperwork?bundleSessionId=' + (this.get('bundleSessionId') || ''),
				data: data,
				success: function (responseData, textStatus, jqXHR) {
					if (responseData && responseData.bundleSessionId) {
						this.set('bundleSessionId', responseData.bundleSessionId);
					}
				}.bind(this),
				async: false
			});
		},

	}));
});