import {EmailType, UpdateEmailConfig} from "../types/types";

export const useEmailFieldHandlers = (
    updateEmailConfig: UpdateEmailConfig,
    selectedEmailType: EmailType | undefined
) => {
    const handleSubjectChange = (value: string) => {
        if (selectedEmailType) {
            updateEmailConfig(selectedEmailType, 'subject', value);
        }
    };

    const handleBodyChange = (value: string) => {
        if (selectedEmailType) {
            updateEmailConfig(selectedEmailType, 'body', value);
        }
    };

    return [handleSubjectChange, handleBodyChange] as const;
};
