import {EmailCustomConfiguration, EmailTemplate, EmailType} from "../types/types";
import {useState} from "react";
import {useAppSelector} from "../../../../store/hooks";
import {CommonStoreStateKeys} from "../../../../store/slices/commonStoreSlice";
import {getEmailPreview} from "../api/emailPreview";
import {getDefaultEmailBody, getDefaultEmailSubject} from "../emailDefaults";

export const useEmailPreview = (emailConfig: EmailCustomConfiguration) => {
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [emailPreview, setEmailPreview] = useState<EmailTemplate | null>(null);

    const currentProjectId = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentProject]).id;

    const handlePreview = async (selectedEmailType: EmailType | undefined): Promise<any> => {
        if (selectedEmailType) {
            const emailTemplate = {...emailConfig[selectedEmailType]};

            emailTemplate.subject = emailTemplate.subject || getDefaultEmailSubject(selectedEmailType);
            emailTemplate.body = emailTemplate.body || getDefaultEmailBody(selectedEmailType);

            try {
                const response = await getEmailPreview(currentProjectId, emailTemplate);
                setEmailPreview(response.data);
                setPreviewOpen(true);
            } catch (error) {
                console.error("Error fetching email preview", error);
            }
        }
    };

    return [previewOpen, setPreviewOpen, emailPreview, handlePreview] as const;
};