define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './SettingsPageHtml', '../view/Sketcher', '../helper/iosapp',
	'../model/user', '../model/subcontractors', '../model/inspections', '../model/currentProject', '../model/ProjectFile', '../helper/shortcuts/shortcuts',
	'../projectmanager/state'
], function (
	$, _, Backbone, Template, Sketcher, iosapp,
	user, subcontractors, inspections, currentProject, ProjectFile, shortcuts, state
) {
	return Backbone.View.extend({

		id: 'settings',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		events: {
			'vclick .saveChanges': 'saveChanges',
			'vclick .resetChanges': 'resetChanges',
			'keyup .searchInput': 'search',
			'change select, input, .sketcher': 'change',
			'vclick .applylang': 'applylang',
			'click .configureMenu': 'configuremenu',
			'click .toggleExtendedUserSettings': 'toggleExtendedUserSettings',
			'click .toggleExtendedProjectSettings': 'toggleExtendedProjectSettings',
			'click .toggleExtendedCustomerSettings': 'toggleExtendedCustomerSettings',
			'click .createCodes': 'createCodes',
			'click .toggleAnRequestVariables': 'toggleAnRequestVariables',
			'click .toggleAnRequestEmail': 'toggleAnRequestEmail',
			'change #anRequestSubjectInput': 'changeAnRequestEmail',
			'keyup #anRequestSubjectInput': 'changeAnRequestEmail',
			'change #anRequestContentInput': 'changeAnRequestEmail',
			'keyup #anRequestContentInput': 'changeAnRequestEmail',
			'click .changeText': function () {
				if (!user.isAdmin()) {
					alertPopup(user.translate('requestbundle.noAdminRights'))
					return
				}
				this.currentRequest = -1;
				var $option = this.$('.previews option:selected'),
					action;
				if ($option.attr('data-inspection')) {
					alertPopup(user.translate('requestbundle.noChangeInspections'));
					return;
				} else if ($option.attr('data-single')) {
					action = $.get(window.contextPath + '/api/getTextFromTemplateSingle/' + currentProject.id + '/' + $option.attr('data-type').trim());
				} else if ($option.attr('notify')) {
					action = $.get(window.contextPath + '/api/getTextFromTemplateNotify/' + currentProject.id);
				} else {
					action = $.get(window.contextPath + '/api/getTextFromTemplate/' + currentProject.id + '/' + $option.attr('class').trim() + '/' + $option.attr('data-request'));
					this.currentRequest = $option.attr('data-request');
				}
				$.mobile.loading('show');
				action
					.done(_.bind(function (data) {
						if (_.isEmpty(data.fileMap)) alertPopup(user.translate('settings.noEditableText'));
						this.resetFopPopup();
						var _func = function () {
							var width = window.innerWidth - 200;
							var height = window.innerHeight - 200;
							this.$alterFopPopup.css({
								width: width,
								height: height
							})
							this.$alterFopPopup.popup('open');
							this.$alterFopPopup.popup('close');
							this.$alterFopPopup.popup('open');
						}.bind(this)

						// data = this.fixData(data);

						this.fillFopPopup(data, $option.val(), _func);
						if (window.innerWidth <= 500) this.$('.textBlocks').css('width', '88%');
						$('textarea').trigger('input');

					}, this))
					.fail(_.bind(function () {
						alertPopup(user.translate('paperwork.showdocument.error'));
					}, this))
					.always(_.bind(function () {
						$.mobile.loading('hide');
					}, this));
			},
			'click .showpreview': function () {
				var $option = this.$('.previews option:selected'),
					action;
				if ($option.attr('data-inspection')) {
					action = $.post(window.contextPath + '/api/showProtocolPreview', {
						project: currentProject.id,
						inspection: $option.attr('data-inspection').trim()
					});
				} else if ($option.attr('data-single')) {
					action = $.post(window.contextPath + '/api/showSinglePreview', {
						project: currentProject.id,
						type: $option.attr('data-type').trim()
					});
				} else {
					action = $.post(window.contextPath + '/api/showPreview', {
						project: currentProject.id,
						type: $option.attr('class').trim(),
						request: $option.attr('data-request')
					});
				}
				$.mobile.loading('show');
				action
					.done(_.bind(function (data) {
						this.router.imagePage.setModel(new ProjectFile(data));
						this.router.imagePage.setupPageForDocument();
						this.router.image();
					}, this))
					.fail(_.bind(function () {
						alertPopup(user.translate('paperwork.showdocument.error'));
					}, this))
					.always(_.bind(function () {
						$.mobile.loading('hide');
					}, this));
			},
			'click .downloadpreview': function () {
				var $option = this.$('.previews option:selected');
				if ($option.attr('data-inspection')) {
					$('body').data('cancelbeforeunload', true);
					window.location.href = window.contextPath + '/api/downloadProtocolPreview?' + $.param({
						project: currentProject.id,
						inspection: $option.attr('data-inspection').trim()
					}) + '&' + new Date().getTime();
					_.defer(function () { $('body').data('cancelbeforeunload', false); });
					return;
				} else if ($option.attr('data-single')) {
					$('body').data('cancelbeforeunload', true);
					window.location.href = window.contextPath + '/api/downloadSinglePreview?' + $.param({
						project: currentProject.id,
						type: $option.attr('data-type').trim()
					}) + '&' + new Date().getTime();
					_.defer(function () { $('body').data('cancelbeforeunload', false); });
					return;
				} else {
					$('body').data('cancelbeforeunload', true);
					window.location.href = window.contextPath + '/api/downloadPreview?' + $.param({
						project: currentProject.id,
						type: $option.attr('class').trim(),
						request: $option.attr('data-request')
					}) + '&' + new Date().getTime();
					_.defer(function () { $('body').data('cancelbeforeunload', false); });
					return;
				}
			},
			'click .downloadAll': function () {
				$('body').data('cancelbeforeunload', true);
				window.location.href = window.contextPath + '/api/downloadAllPreview?' + $.param({
					project: currentProject.id,
					protocols: this.protocols,
					singles: this.singles,
					requests1: this.requests[0],
					requests2: this.requests[1],
					requests3: this.requests[2]
				}) + '&' + new Date().getTime();
				_.defer(function () { $('body').data('cancelbeforeunload', false); });
			},
			'click .deleteplans': 'deleteplans',
			'click .deletemetadata': 'deletemetadata',
			'click .downloadSchema': function () {
				$('body').data('cancelbeforeunload', true);
				window.location.href = window.contextPath + '/api/importDraft?' + $.param({ project: currentProject.id });
				_.defer(function () { $('body').data('cancelbeforeunload', false); });
			},
			'vclick #settings-qrcodes': function (e) {
				e.preventDefault();
				e.stopPropagation();
				if (user.isSubcontractor() || user.isPrincipal()) {
					alertPopup(user.translate('person.forbidden'));
					return;
				}
				Backbone.history.navigate('qrcode-settings/' + currentProject.get('id'), { trigger: true });
			},
			'vclick #settings-persons': function (e) {
				e.preventDefault();
				e.stopPropagation();
				if (user.isSubcontractor() || user.isPrincipal()) {
					alertPopup(user.translate('person.forbidden'));
					return;
				}
				Backbone.history.navigate('personcsv/' + currentProject.get('id'), { trigger: true });
			},
			'vclick #settings-buyeraccess': function (e) {
				e.preventDefault();
				e.stopPropagation();
				if (user.isSubcontractor() || user.isPrincipal()) {
					alertPopup(user.translate('person.forbidden'));
					return;
				}
				this.previousPage = 'settings';
				Backbone.history.navigate('buyeraccess/' + currentProject.get('id'), { trigger: true, previousPage: 'settings' });
			},
			'vclick #settings-warrantyData': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.previousPage = 'settings';
				Backbone.history.navigate('warrantyDataOverview/' + currentProject.get('id'), { trigger: true });
			},
			'vclick #topositiontypes': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.previousPage = 'settings';
				Backbone.history.navigate('projectmanager/' + currentProject.get('id'), { trigger: true });
			},
			'vclick .openSafetyPopup': 'openSafetyPopup',
			'vclick .safetyPopup .cancelSafety': 'cancelSafetyPopup',
			'vclick .safetyPopup .saveAlteredFopsPermanently': 'saveAlteredFopsPermanently',
			'vclick .cancelFopPopup': 'cancelFopPopup',
			'click .previousPage': 'goBack',
			'click #goto-groups': function () {
				var projectId = currentProject.get('id')
				this.gotoAndAskBefore(window.contextPath + '/projectmanager-groups-entry?projectId=' + encodeURIComponent(projectId));
				this.previousPage = 'settings';
			},
			'click #goto-defect-type-config': function () {
				var projectId = currentProject.get('id')
				this.gotoAndAskBefore(window.contextPath + '/projectmanager-defect-type-config-entry?projectId=' + encodeURIComponent(projectId));
				this.previousPage = 'settings';
			},
			'click #goto-auto-requests': function () {
				var projectId = currentProject.get('id')
				this.gotoAndAskBefore(window.contextPath + '/projectmanager-autorequests-entry?projectId=' + encodeURIComponent(projectId));
				this.previousPage = 'settings';
			},
			'click #goto-inspections': function () {
				var projectId = currentProject.get('id')
				this.gotoAndAskBefore(window.contextPath + '/projectmanager-inspections-entry?projectId=' + encodeURIComponent(projectId));
				this.previousPage = 'settings';
			},
			'click .downloadUnits': function () {
				$('body').data('cancelbeforeunload', true);
				window.location.href = window.contextPath + '/api/codes/' + encodeURIComponent(currentProject.id);
				_.defer(function () { $('body').data('cancelbeforeunload', false); });
			},

		},

		'vclick .openSafetyPopup': 'openSafetyPopup',
		'vclick .safetyPopup .cancelSafety': 'cancelSafetyPopup',
		'vclick .safetyPopup .saveAlteredFopsPermanently': 'saveAlteredFopsPermanently',
		'vclick .cancelFopPopup': 'cancelFopPopup',
		'vclick .preview-anRequest': 'previewAnRequest',
		'vclick .toggleVariablesBox': 'toggleVariablesBox',
		'vclick .menutrigger': 'triggerMenu',
		'click .previousPage': 'goBack',
		'click #goto-groups': function () {
			var projectId = currentProject.get('id')
			this.gotoAndAskBefore(window.contextPath + '/projectmanager-groups-entry?projectId=' + encodeURIComponent(projectId));
		},
		'click #goto-defect-type-config': function () {
			var projectId = currentProject.get('id')
			this.gotoAndAskBefore(window.contextPath + '/projectmanager-defect-type-config-entry?projectId=' + encodeURIComponent(projectId));
		},
		'click #goto-auto-requests': function () {
			var projectId = currentProject.get('id')
			this.gotoAndAskBefore(window.contextPath + '/projectmanager-autorequests-entry?projectId=' + encodeURIComponent(projectId));
		},
		'click #goto-inspections': function () {
			var projectId = currentProject.get('id')
			this.gotoAndAskBefore(window.contextPath + '/projectmanager-inspections-entry?projectId=' + encodeURIComponent(projectId));
		},
		'click .downloadUnits': function () {
			$('body').data('cancelbeforeunload', true);
			window.location.href = window.contextPath + '/api/codes/' + encodeURIComponent(currentProject.id);
			_.defer(function () { $('body').data('cancelbeforeunload', false); });
		},

		triggerMenu: function () {
			if (this.isChanged) {
				// show a popup to ask if the user wants to save the changes
				return this.confirmation();
			} else {
				return this.router.defectPage.triggerMenu();
			}
		},

		confirmation: function () {
			if (!this.isChanged) {
				return $.Deferred().resolve();
			}
			if (this.readonly && user.isPrincipal()) {
				return this.save();
			} else {
				return yesNoCancelPopup(user.translate('defect.save.popup'))
					.then(_.bind(function () {
						var deferred = $.Deferred();
						this.saveChanges().then(function () {
							return deferred.resolve();
						}, function () {
							deferred.reject('cancel');
						});
					}, this), _.bind(function (option) {
						if (option === 'no') {
							this.resetChanges();
							return $.Deferred().resolve();
						}
						if (option === 'cancel') {
							return $.Deferred().reject();
						}
					}, this));
			}
		},

		gotoAndAskBefore: function (url) {
			var defer = $.Deferred().resolve();
			defer.then(function () {
				window.location.href = url;
			}.bind(this));
		},

		initialize: function (options) {
			state.fetch();
			this.router = options.router;

			this.listenTo(Backbone.Events, this.modelType + 's:save', this._save);
			this.listenTo(Backbone.Events, this.modelType + ':new', this.newModel);
			this.listenTo(Backbone.Events, this.modelType + ':reset', this.resetEditView);
			this.listenTo(Backbone.Events, this.modelType + ':save', this.saveEditView);
			this.listenTo(Backbone.Events, this.modelType + ':remove', this.removeModel);
			this.listenTo(Backbone.Events, this.modelType + ':table', this.openTable);
			this.listenTo(Backbone.Events, this.modelType + ':archiveproject', this.archiveModel);

			this.$previousPage = this.$('.previousPage');

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.$search = this.$('.searchInput');
			this.$nosearchresults = this.$('#nosearchresults');
			this.$language = this.$('#settings-language');
			this.$extendedUserSettings = this.$('.extendedUserSettings');
			this.$anRequestEmail = this.$('.anRequestEmail');
			this.$anRequestVariables = this.$('.anRequestVariables');
			this.$anRequestEmailSubject = this.$('.anRequestEmailSubject');
			this.$anRequestEmailContent = this.$('.anRequestEmailContent');
			this.$extendedUserButton = this.$('.extendedUserButton');
			this.$extendedProjectSettings = this.$('.extendedProjectSettings');
			this.$extendedProjectButton = this.$('.extendedProjectButton');
			this.$extendedCustomerSettings = this.$('.extendedCustomerSettings');
			this.$extendedCustomerButton = this.$('.extendedCustomerButton');
			this.$sendaccount = this.$('#settings-sendaccount');
			this.$addtimestamp = this.$('#settings-addtimestamp');
			this.$addprincipalid = this.$('#settings-addprincipalid');
			this.$autoSelectDeepestLocation = this.$('#settings-autoSelectDeepestLocation');
			// this.$showstatsinoverview    = this.$('#settings-showstatsinoverview');
			this.$sendStatisticsEmails = this.$('#settings-sendstatisticsemails');
			this.$sendStatisticsEmailsElements = this.$('.sendstatisticsemails-elements');
			this.$signatures = this.$('#settings-signatures');
			this.$signature = this.$('.signature');
			this.$printing = this.$('#settings-printing');
			this.$altlocationfilter = this.$('#settings-altlocationfilter');
			this.$subcontractororder = this.$('#settings-subcontractororder');
			this.$defaultUploadSettings = this.$('#settings-defaultUploadSettings');
			this.$admin = this.$('.admin');
			this.$adminLinks = this.$('.adminlinks');
			this.$previews = this.$('.previews');
			this.$statsSettingsContainer = this.$('.statsSettingsContainer');
			this.$filenameSchema = this.$('#settings-filenameschema');
			this.$codeOptions = this.$('.codeOptions');
			this.$deleteplans = this.$('.deleteplans');
			this.$deletemetadata = this.$('.deletemetadata');
			this.$notifyIfAnyDefectChanged = this.$('#settings-notifyIfAnyDefectChanged');
			this.$notifyIfAnyDefectCurUserCreatedChanged = this.$('#settings-notifyIfAnyDefectCurUserCreatedChanged');
			this.$notifyIfAgCreatedDefect = this.$('#settings-notifyIfAgCreatedDefect');
			this.$showWarrantyWhenNoWarranty = this.$('#settings-showWarrantyWhenNoWarranty');
			this.$radioInterval = this.$('#settings-radiointerval');
			this.$radioIncludeChanged = this.$('#settings-radioincludechanged');
			this.$radiodeadline = this.$('#settings-radioautodeadline');
			this.$hideNews = this.$('#settings-hidenews');
			this.$instantmessagedeadline = this.$('#settings-instantmessagedeadline');
			this.$instantmessagedefaultdeadlinedays = this.$('#settings-instantmessagedefaultdeadlinedays');
			this.$deadlineDaysContainer = this.$('.deadlineDaysContainer');
			this.$feContainer = this.$('.fe-connection').hide();

			this.$showSubcontractorsToPrincipal = this.$('#settings-showSubcontractorsToPrincipal');

			this.$defectCreationDate = this.$('#defect-creation-date');
			this.$defectCreator = this.$('#defect-creator');
			this.$defectActualisationDate = this.$('#defect-actualisation-date');
			this.$defectDeadline = this.$('#defect-deadline');
			this.$defectSubcontractor = this.$('#defect-subcontractor');
			this.$defectEscalationlevel = this.$('#defect-escalationlevel');
			// this.$defectFixed		 	    = this.$('#defect-fixed-date');
			this.$resetButton = this.$('.resetChanges');
			this.$saveButton = this.$('.saveChanges');

			this.$alterFopPopup = this.$('.alterFop');
			this.$safetyPopup = this.$('.safetyPopup');
			this.$safetyWordinput = this.$('.safetyWordinput');
			this.$chooseFolder = this.$('.chooseFolder');
			this.$safetyWarning = this.$('.safetyWarning');
			this.alteredDocument = null;

			this.$costType = this.$('#settings-costType');
			this.$contactIndex = this.$('#settings-contactIndex');
			this.$anRequestSubjectInput = this.$('#anRequestSubjectInput');
			this.$anRequestContentInput = this.$('#anRequestContentInput');
			this.$previewAnRequest = this.$('.preview-anRequest');

			this.$extManual = this.$('#settings-extManual');
			this.$externalDefectsOnly = this.$('#settings-externalDefectsOnly');
			this.$showPrincipalIdsOnPdfs = this.$('#settings-showPrincipalIdsOnPdfs');
			this.$showSubcontractorContactInFileName = this.$('#settings-showSubcontractorContactInFileName');
			this.$showYellowAgPins = this.$('#settings-showYellowAgPins');
			this.$chooseSubAutomatically = this.$('#settings-chooseSubAutomatically');
			this.$attachVisibleFiles = this.$('#settings-attachVisibleFiles');
			this.$sendNotifications = this.$('#settings-sendNotifications');
			this.$sendCopyWhenDownload = this.$('#settings-sendCopyWhenDownload');
			this.$groupsActive = this.$('#settings-groupsActive');
			this.$trackPdfDownload = this.$('#settings-trackPdfDownload');
			this.$stateDefectTypeSpecial = this.$('#settings-stateDefectTypeSpecial');
			this.$AnCommentInAgList = this.$('#settings-AnCommentInAgList');
			this.$AgCommentInAnList = this.$('#settings-AgCommentInAnList');

			this.$minLocationDepth = this.$('#settings-minLocationDepth');
			this.$automaticDataSync = this.$('#settings-automaticDataSync');
			this.$defectSync = this.$('#settings-defectSync');
			this.$anRequestSplitByLocationDepth = this.$('#settings-anRequestSplitByLocationDepth');

			iosapp.isFEavailable(_.bind(function (result) {
				if (result.available) {
					this.$feContainer.show();
				}
			}, this));

			if (!iosapp.appavailable && window.supportsStorage) {
				this.$feContainer.show();
			}

			$('<option>')
				.val('')
				.text(user.translate('settings.noautodeadline'))
				.appendTo(this.$radiodeadline);
			for (var days = 1; days <= 31; days++) {
				$('<option>')
					.val(days)
					.text(user.translate('settings.autodeadline.days').replace('%d', days))
					.appendTo(this.$radiodeadline);
			}

			this.sketcher = new Sketcher({
				el: this.$signature,
				size: { width: 450, height: 150 },
				color: 'navy',
				mode: 'single',
				annotationStyle: "position: absolute; bottom: -48px; left: 0; width: 115px"
			});
			this.$clearTriggers = this.$('.clearTriggers');
		},

		render: function (options) {
			// this.$clearTriggers.prop('position', undefined);
			this.protocols = [];
			this.singles = ['GW_NOTICE', 'GW_NOTICE_GOODWILL', 'GW_SENDSTATUS', 'GW_FINISHED', 'GW_REJECT', 'AN_REJECT_OBJECTION', 'AN_REJECT_OBJECTION_WARRANTY', 'NOTIFY', 'REJECT'];
			this.requests = [[], [], [], []];
			var authorities = user.get('authorities'),
				isAdmin = user.isAdmin(),
				isSubcontractor = user.isSubcontractor(),
				isPrincipal = user.isPrincipal(),
				isReadonly = user.isReadonly(),
				settings = options && options.settings ? options.settings : user.get('settings');

			if (!settings) {
				return;
			}
			this.priorSettings = settings;

			var keys = currentProject.get('structureKeys');
			if (iosapp.appavailable || iosapp.androidappavailable || iosapp.windowsappavailable) {
				this.$('.code-container').addClass('not-avail-mobile');
			} else {
				if (keys && keys.length > 0) {
					this.$('.code-container').removeClass('not-avail');
					this.$('.code-container').removeClass('not-avail-mobile');
					this.$codeOptions.empty();
					for (var i = 0; i < keys.length; i++) {
						//var parts = [];
						//for (var j = 0; j <= i; j++) {
						//	parts.push(keys[j]);
						//}
						$('<option>').attr('value', i + 1).text(keys[i] /*parts.join(', ')*/).appendTo(this.$codeOptions);
					}
					this.$codeOptions.selectmenu('refresh');
				} else {
					this.$('.code-container').addClass('not-avail');
				}
			}

			// hide certain elements in mobile app
			if (iosapp.appavailable || iosapp.androidappavailable || iosapp.windowsappavailable) {
				this.this.$('.only-browser').disable();
			}

			this.$language.val(settings.lang).selectmenu('refresh');
			this.$sendaccount.prop('checked', settings.sendAccount).checkboxradio('refresh');
			this.$addtimestamp.prop('checked', settings.addTimestamp).checkboxradio('refresh');
			this.$addprincipalid.prop('checked', settings.addPrincipalId).checkboxradio('refresh');
			this.$autoSelectDeepestLocation.prop('checked', settings.autoSelectDeepestLocation).checkboxradio('refresh');
			this.$hideNews.prop('checked', settings.hideNews).checkboxradio('refresh');
			this.$instantmessagedeadline.prop('checked', settings.instantMessageDeadline).checkboxradio('refresh');
			this.$instantmessagedefaultdeadlinedays.val(settings.instantMessageDefaultDeadlineDays && settings.instantMessageDefaultDeadlineDays !== 0 ? settings.instantMessageDefaultDeadlineDays : '');
			this.$altlocationfilter.prop('checked', settings.altLocationFilter).checkboxradio('refresh');
			// this.$showstatsinoverview.prop('checked', settings.showStats).checkboxradio('refresh');
			this.$sendStatisticsEmails.prop('checked', !settings.sendStatisticsEmails).checkboxradio('refresh');

			this.$defectCreationDate.prop('checked', settings.defectMetadata.defect_show_creation_date).checkboxradio('refresh');
			this.$defectCreator.prop('checked', settings.defectMetadata.defect_show_creator).checkboxradio('refresh');
			this.$defectActualisationDate.prop('checked', settings.defectMetadata.defect_show_actualisation_date).checkboxradio('refresh');
			this.$defectDeadline.prop('checked', settings.defectMetadata.defect_show_deadline).checkboxradio('refresh');
			this.$defectSubcontractor.prop('checked', settings.defectMetadata.defect_show_subcontractor).checkboxradio('refresh');
			this.$defectEscalationlevel.prop('checked', settings.defectMetadata.defect_show_escalationlevel).checkboxradio('refresh');
			// this.$defectFixed.prop('checked',settings.defectMetadata.defect_show_fixed_date).checkboxradio('refresh');

			this.$signatures.val(settings.signatures).selectmenu('refresh');
			this.sketcher.setModel({ annotation: settings.signature, index: 0 });

			this.$printing.val(settings.printing || 'NORMAL').selectmenu('refresh');
			this.$subcontractororder.val(settings.subcontractorOrder || 'TABLE').selectmenu('refresh');
			this.$defaultUploadSettings.val(settings.defaultUploadSettings || 'AGAN').selectmenu('refresh');
			this.$filenameSchema.val(settings.filenameSchema || 'STANDARD').selectmenu('refresh');

			this.$anRequestSubjectInput.val(settings.anRequestSubject);
			this.$anRequestContentInput.val(settings.requestEmailText);

			// set the value of this text input to the value of the select

			// this.$statsSettingsContainer.toggle(!(user.isPrincipal() && user.isReadonly()));
			this.$sendStatisticsEmailsElements.toggle(!user.isPrincipal());

			var notifyConfig = settings.notifyChanges[currentProject.id] || {
				notify: false,
				notify2: false,
				notify3: false
			};

			var showFileUploadSettings = !(user.isPrincipal() || user.isSubcontractor());
			this.$('.defaultUploadSettingsContainer').toggle(showFileUploadSettings);

			this.$notifyIfAnyDefectChanged.prop('checked', !!notifyConfig.notify).checkboxradio('refresh');
			this.$notifyIfAnyDefectCurUserCreatedChanged.prop('checked', !!notifyConfig.notify2).checkboxradio('refresh');
			this.$notifyIfAgCreatedDefect.prop('checked', !!notifyConfig.notify3).checkboxradio('refresh');

			this.$showWarrantyWhenNoWarranty.prop('checked', settings.showWarrantyWhenNoWarranty).checkboxradio('refresh');

			var radioConfig = settings.projectRadios[currentProject.id] || {
				interval: 'none',
				includeChanged: false,
				autodeadline: null
			};
			this.$radioInterval.val(radioConfig.interval).selectmenu('refresh');
			this.$radioIncludeChanged.prop('checked', radioConfig.includeChanged).checkboxradio('refresh');
			this.$radiodeadline.val(radioConfig.autodeadline ? String(radioConfig.autodeadline) : '').selectmenu('refresh');

			this.$('.triggerSync').toggle(currentProject.get('hasSync'));
			this.$('.downloadSchema').toggle(!iosapp.appavailable && !iosapp.androidappavailable && !iosapp.windowsappavailable);

			var defectTypes = currentProject.get('types');

			//--------

			var projectSettings = options && options.projectSettings ? options.projectSettings : currentProject.attributes;
			this.priorProjectSettings = projectSettings;

			this.$costType.val(projectSettings.costType); // .selectmenu('refresh');
			this.$contactIndex.val(projectSettings.contactIndex);
			this.$previewAnRequest.html(settings.anRequestContent);

			this.$extManual.prop('checked', projectSettings.extManual).checkboxradio('refresh');
			this.$externalDefectsOnly.prop('checked', projectSettings.externalDefectsOnly).checkboxradio('refresh');
			this.$showPrincipalIdsOnPdfs.prop('checked', projectSettings.showPrincipalIdsOnPdfs).checkboxradio('refresh');
			this.$showSubcontractorContactInFileName.prop('checked', projectSettings.showSubcontractorContactInFileName).checkboxradio('refresh');
			this.$showYellowAgPins.prop('checked', projectSettings.showYellowAgPins).checkboxradio('refresh');
			this.$chooseSubAutomatically.prop('checked', projectSettings.chooseSubAutomatically).checkboxradio('refresh');
			this.$attachVisibleFiles.prop('checked', projectSettings.attachVisibleFiles).checkboxradio('refresh');
			this.$sendNotifications.prop('checked', projectSettings.sendNotifications).checkboxradio('refresh');
			this.$sendCopyWhenDownload.prop('checked', projectSettings.sendCopyWhenDownload).checkboxradio('refresh');
			this.$groupsActive.prop('checked', projectSettings.groupsActive).checkboxradio('refresh');
			this.$trackPdfDownload.prop('checked', projectSettings.trackPdfDownload).checkboxradio('refresh');
			this.$stateDefectTypeSpecial.prop('checked', projectSettings.stateDefectTypeSpecial).checkboxradio('refresh');
			this.$AnCommentInAgList.prop('checked', projectSettings.AnCommentInAgList).checkboxradio('refresh');
			this.$AgCommentInAnList.prop('checked', projectSettings.AgCommentInAnList).checkboxradio('refresh');

			this.$minLocationDepth.val(projectSettings.minLocationDepth);
			this.$automaticDataSync.val(projectSettings.automaticDataSync);
			this.$defectSync.val(projectSettings.defectSync);
			this.$anRequestSplitByLocationDepth.val(projectSettings.anRequestSplitByLocationDepth);

			this.$anRequestSubjectInput.val(projectSettings.anRequestSubject);
			this.$anRequestContentInput.val(projectSettings.requestEmailText);
			//--------

			this.changeAnRequestEmail(); // just for rendering
			this.$resetButton.hide();

			this.$previews.empty();
			_.forEach(defectTypes, function (type) {
				if (this.$previews.find('option[value="' + type.defectTypeId + '"]').length === 0) {

					// var requests = parseInt(type.requests);
					var typeConfig = new Map(_.pairs(currentProject.get('typeConfiguration'))).get(type.defectTypeId);
					var requests = 0;
					var terminationLevel = false;
					if (typeConfig.firstEscalationLevel) requests = 1;
					if (typeConfig.secondEscalationLevel) requests = 2;
					if (typeConfig.thirdEscalationLevel) requests = 3;
					if (typeConfig.terminationLevel) terminationLevel = true;


					if (user && user.get('customer') === 'schuett') {
						if (type.defectTypeId === 'deadline' || type.defectTypeId === 'obstruction') {
							requests = 3;
						}
					}
					if (user && user.get('customer') === 'kriegerschramm') {
						if (type.defectTypeId === 'unfinished' || type.defectTypeId === 'additional' || type.defectTypeId === 'followup') {
							requests = 3;
						}
					}
					var ii = 0;
					if (requests > 1) {
						for (var i = 1; i <= requests; i++) {
							var requestLabel = i === 1 ? user.translate('settings.firstrequest') : i === 2 ? user.translate('settings.secondrequest') : user.translate('settings.thirdrequest');
							var itemLabel = user.translate('settings.preview') + ' ' + user.translate(type.label) + ' ' + requestLabel;

							var ignore = false;

							//nething special part 1/2
							if (user && user.get('customer') === 'nething') {
								if (type.defectTypeId === 'technical') {
									if (i <= 2) {
										itemLabel = 'Vorschau Mangelanzeige ' + (i == 2 ? 'Nachfrist ' : '') + 'BM';
									} else {
										ignore = true;
									}
								} else if (type.defectTypeId === 'safety') {
									itemLabel = 'Vorschau Arbeitseinstellung wg. Sicherheit AS';
									if (i > 1) {
										ignore = true;
									}
								} else if (type.defectTypeId === 'waste') {
									itemLabel = 'Vorschau Ordnung auf der Baustelle OR';
									if (i > 1) {
										ignore = true;
									}
								} else if (type.defectTypeId === 'warranty') {
									if (i <= 2) {
										itemLabel = 'Vorschau Gewährleistungsmängel ' + (i == 2 ? 'Nachfrist ' : '') + 'GW';
									} else {
										ignore = true;
									}
								}
							}

							if (!ignore) {
								this.$previews.append($('<option class="' + type.defectTypeId + '" data-request="' + i + '">').text(itemLabel));
								(this.requests[i - 1]).push(type.defectTypeId);
								ii = i;
							}
						}
					} else {
						var itemLabel = user.translate('settings.preview') + ' ' + user.translate(type.label);

						//nething special part 2/2
						if (user && user.get('customer') === 'nething') {
							if (type.defectTypeId === 'deadline') {
								itemLabel = 'Vorschau Terminverzug VZ';
							}
						}
						this.requests[0].push(type.defectTypeId);
						this.$previews.append($('<option class="' + type.defectTypeId + '" data-request="1">').text(itemLabel));
					}
					if (terminationLevel) {
						var requestLabel = user.translate('settings.terminationrequest') + ' (' + typeConfig.terminationLevelName + ')';
						var itemLabel = user.translate('settings.preview') + ' ' + user.translate(type.label) + ' ' + requestLabel;
						this.$previews.append($('<option class="' + type.defectTypeId + '" data-request="4">').text(itemLabel));
						(this.requests[3]).push(type.defectTypeId);
					}
				}
			}.bind(this));
			this.$previews.append($('<option class="REJECT" data-single="1" data-type="REJECT">').text(user.translate('settings.preview') + ' ' + user.translate('filename.reject')))
			this.$previews.append($('<option class="NOTIFY" data-single="1" data-type="NOTIFY">').text(user.translate('settings.preview') + ' ' + user.translate('settings.notify')));

			this.$previews.append($('<option disabled="disabled">--</option>'));

			this.$previews.append($('<option class="additional" data-single="1" data-type="GW_NOTICE">').text(user.translate('settings.preview') + ' ' + user.translate('defect.type.warranty') + ' ' + user.translate('filename.gw.notice')))
			this.$previews.append($('<option class="additional" data-single="1" data-type="GW_NOTICE_GOODWILL">').text(user.translate('settings.preview') + ' ' + user.translate('defect.type.warranty') + ' ' + user.translate('filename.gw.noticegoodwill')))
			this.$previews.append($('<option class="additional" data-single="1" data-type="GW_SENDSTATUS">').text(user.translate('settings.preview') + ' ' + user.translate('defect.type.warranty') + ' ' + user.translate('filename.gw.sendstatus')))
			this.$previews.append($('<option class="additional" data-single="1" data-type="GW_FINISHED">').text(user.translate('settings.preview') + ' ' + user.translate('defect.type.warranty') + ' ' + user.translate('filename.gw.finished')))
			this.$previews.append($('<option class="additional" data-single="1" data-type="GW_REJECT">').text(user.translate('settings.preview') + ' ' + user.translate('defect.type.warranty') + ' ' + user.translate('filename.gw.reject')))
			this.$previews.append($('<option class="additional" data-single="1" data-type="AN_REJECT_OBJECTION">').text(user.translate('settings.preview') + ' ' + user.translate('filename.subcontractor.rejectobjection')));
			this.$previews.append($('<option class="additional" data-single="1" data-type="AN_REJECT_OBJECTION_WARRANTY">').text(user.translate('settings.preview') + ' ' + user.translate('filename.subcontractor.rejectobjectionwarranty')));


			if (user && user.get('customer') !== 'nething') {
				this.$previews.append($('<option disabled="disabled">--</option>'));

				this.$previews.find('option[data-inspection]').remove();
				_(user.get('inspections'))
					// .filter(function (inspection) {
					// 	return inspection.external !== 'no';
					// })
					.each(function (inspection) {
						this.protocols.push(inspection.id);
						$('<option>')
							.attr('class', 'inspection')
							.attr('data-inspection', inspection.id)
							.text(user.translate('settings.preview') + ' ' + user.translate('settings.preview.protocol') + ' ' + inspection.name)
							.appendTo(this.$previews);
					}, this)
					.value();
			}

			this.$showSubcontractorsToPrincipal.prop('checked', currentProject.get('showSubcontractorsToPrincipal')).checkboxradio('refresh');


			this.$previews.selectmenu('refresh');

			if (window.oxmobile) {
				this.$('.downloadpreview').remove();
				this.$('.downloadAll').remove();
			}

			if (currentProject && currentProject.get('categories') && !currentProject.get('categories').warranty &&
				user.get('settings') && user.get('settings').printing !== 'LONG') {
				this.$('#settings-printing option[value="LONG"]').remove();
				this.$printing.selectmenu('refresh');
			}

			this.$('.fulladmin').toggle(user.isFullAdmin());

			this.$admin.toggle(isAdmin);
			if (isAdmin && !iosapp.appavailable) {
				this.$adminLinks.empty();
				var customerLink = user.get('customerLink');
				if (customerLink) {
					$('<li>')
						.appendTo(this.$adminLinks)
						.append(
							$('<a>')
								.text(user.translate('settings.admin.customer'))
								.attr({
									// href: window.contextPath + customerLink, target: '_blank',
									href: window.contextPath + '/docs/customers/' + user.get('customer') + '/inspections.json', target: '_blank',
									'data-shortcut': 'adminTables'
								})
						);
					$('<li>')
						.appendTo(this.$adminLinks)
						.append(
							$('<a>')
								.text(user.translate('settings.admin.projectmanager'))
								.attr({
									href: window.contextPath + '/projectmanager/?from=' + encodeURIComponent(window.location), target: '_blank',
									'data-shortcut': 'projectManager'
								})
						);
					$('<li>')
						.appendTo(this.$adminLinks)
						.append(
							$('<a>')
								.text(user.translate('settings.admin.newAdminView'))
								.attr({
									href: 'https://openexperience.de/teams/mainPage/customers/' + user.get('customer') + '/projects/' + currentProject.id + '#returnUrl=' + window.location.href, target: '_blank'
								})
						);
				}
				var projectLinks = user.get('projectLinks');
				if (projectLinks) {
					_.each(projectLinks, function (projectLink) {
						$('<li>')
							.appendTo(this.$adminLinks)
							.append(
								$('<a>')
									.text(projectLink.name)
									.attr({ href: window.contextPath + projectLink.link, target: '_blank' })
							);
					}, this);
				}
				this.$adminLinks.listview('refresh');
			}

			this.$('.offlinecachesheading').toggle(
				(iosapp.appavailable && iosapp.functionavailable('syncplans')) ||
				false
			);
			this.$deleteplans.toggle(iosapp.appavailable && iosapp.functionavailable('syncplans'));
			//this.$deletemetadata.toggle(iosapp.appavailable && iosapp.functionavailable('attachments'));
			this.$deletemetadata.toggle(false);

			this.$('.notifychanges-title').toggle(!isPrincipal);
			this.$('.notifychanges-container').toggle(!isPrincipal);

			this.$('.radiohint').text(user.translate('settings.radio.hint').replace('%s', '"' + currentProject.get('name') + '"'));

			this.$('.ui-popup-container').css('height', '100%');
			this.$('.ui-popup-container').css('overflow-x', 'auto');

			this.showExtendedSettings(isSubcontractor, isPrincipal, isReadonly);

			var cantChange = !!(currentProject.get('signatureSetting') && currentProject.get('signatureSetting') !== '');
			if (cantChange) {
				this.$signatures.val(currentProject.get('signatureSetting'));
				this.$signatures.parent().find('span').text(this.$signatures[0].options[this.$signatures[0].selectedIndex].text);
				$('#signatureSettingDisabledInfo').toggle(true);
			} else {
				$('#signatureSettingDisabledInfo').toggle(false);
			}
			// this.$signatures.on('mousedown', function (e) {
			// 	if(cantChange) {
			// 		e.preventDefault();
			// 		e.stopPropagation();
			// 		window.alertPopup(user.translate('settings.signatures.disabled'));
			// 	}
			// });
			this.$signatures.prop('disabled', cantChange).closest('.ui-select').prop('readonly', cantChange).toggleClass('disabled', cantChange).toggleClass('anf', cantChange);

			this.$defectSubcontractor.parent().find('label').text(((!user.isPrincipal() || currentProject.get('showSubcontractorsToPrincipal')) ? user.translate('defects.header.subcontractor') : user.translate('defects.header.crew')));

			this.$('.settingsItem').each(function () {
				var $this = $(this);
				var text = $this.text();
				text = text.replace(/[\n\r]/g, ' ').replace(/[^a-zäöüßA-ZÄÖÜ0-9 ]/g, '');

				var words = text.split(' ');

				words = _.filter(words, function (word) {
					return word.length > 0;
				});

				var tags = words.join(', ');
				$this.attr('tags', tags);
			});
			this.$clearTriggers.prop('bottom', -50);
			this.$clearTriggers.prop('left', 0);
			this.$clearTriggers.prop('top', undefined);

			this.toggleInstantMessageDeadline(this.$instantmessagedeadline.prop('checked'));
			return this;
		},

		showExtendedSettings: function (isSub, isPrinc, isReadonly) {
			if (isSub) {
				this.$extended.children('.noSub').toggle(false);
			}
			if (isPrinc) {
				this.$extended.children('.noPrinc').toggle(false);
				this.$extended.find('#metadata-settings').find('#defect-metadata').find('.noPrinc').parent().remove();
				this.$extended.find('#metadata-settings').find('#defect-metadata').children(':last').find('label').css({
					borderTopRightRadius: '7px',
					borderBottomRightRadius: '7px'
				})
			}
			if (isReadonly) {
				this.$extended.children('.noReadonly').toggle(false);
			}

			return this;
		},

		goBack: function (e) {
			e && e.preventDefault();
			e && e.stopPropagation();
			if (this.isChanged) {
				// show a popup to ask if the user wants to save the changes
				return this.confirmation()
					.then(_.bind(function (result) {
						var previous = this.previousPage;
						Backbone.history.navigate(previous, { trigger: true });
					}, this));
			} else {
				var previous = this.previousPage;
				Backbone.history.navigate(previous, { trigger: true });
			}
		},

		search: function () {
			// read the search string from the input field
			var filters = this.$search.val()
				.trim()
				.toLowerCase();
			if (filters === '') {
				// show all settingsItems and return
				this.$('.settingsItem').show();
				this.$('.settings-section-heading').show();
				this.$('.settings-subsection-heading').show();
				this.$('h3').show();
				this.$('.settings-section-toggler').show();
				if (this.$extendedUserButton.attr('checked')) {
					this.$extendedUserSettings.show();
				} else {
					this.$extendedUserSettings.hide();
				}
				if (this.$extendedProjectButton.attr('checked')) {
					this.$extendedProjectSettings.show();
				} else {
					this.$extendedProjectSettings.hide();
				}
				if (this.$extendedCustomerButton.attr('checked')) {
					this.$extendedCustomerSettings.show();
				} else {
					this.$extendedCustomerSettings.hide();
				}
				return;
			}
			this.$('.settings-section-toggler').hide();
			this.$('.settings-section-heading').hide();
			this.$('.settings-subsection-heading').hide();
			this.$('h3').hide();

			//if extended settings are hidden, show them
			this.$extendedUserSettings.show();
			this.$extendedProjectSettings.show();
			this.$extendedCustomerSettings.show();

			// split the filter string and generate a RegExp out of each token
			filters = filters.split(' ');

			// hide all items and show only the ones matching some filter
			this.$('.settingsItem').hide();
			// call a function for each item
			var foundSomething = false;
			this.$('.settingsItem').each(function (index, item) {
				// select the item by index and read the text
				var $item = $(item);

				var tags = _.map($item.attr('tags').split(','), function (tag) {
					return tag.toLowerCase().trim()
				});
				if (filters.some(function (filter) {
					return tags.some(function (tag) {
						result = tag.indexOf(filter) !== -1;

						return result;
					});
				})) {

					$item.show();
					foundSomething = true;
					return;
				}
			});
			if (!foundSomething) {
				this.$nosearchresults.show();
			} else {
				this.$nosearchresults.hide();
			}

		},

		// previewAnRequest: function () {
		// 	$('#settings').find('#emailPreviewBox, #closePreviewBox').remove();
		// 	var emailPreviewBox = "\
		// 							<div id='emailPreviewBox'>\
		// 							<button id='closeEmailPreview' class='close' type='button' data-dismiss='modal' aria-hidden='true' style='position: absolute; top: 20px;right: 20px;'>x</button>\
		// 								<span id='emailPreviewText'></span>\
		// 							</div>\
		// 							";
		// 	var closePreviewBox = "\
		// 							<div id='closePreviewBox'>\
		// 							</div>\
		// 							";
		// 	$('#settings').append(emailPreviewBox);
		// 	$('#settings').append(closePreviewBox);
		// 	$('#emailPreviewBox').css({
		// 		// height: height,
		// 		// width: width,
		// 		display: 'block',
		// 		top: 25,
		// 		left: 25,
		// 		position: 'fixed',
		// 		zIndex: 1000
		// 	}).find('#closeEmailPreview').on('click', this.closeEmailPreview);
		// 	$('#closePreviewBox').css({
		// 		width: $(window).innerWidth(),
		// 		height: $(window).innerHeight()
		// 	}).on('click', this.closeEmailPreview);
		// 	this.parseEmailTextForPreview($('#settings-anRequestContent').val(), $('#settings-anRequestSubject').val())
		// },

		// toggleEmailBox: function () {
		// 	// check if variableBox is visible
		// 	if ($('#anRe').is(':visible')) {
		// 		$('#variablesBox').hide();
		// 	} else {
		// 		$('#variablesBox').show();
		// 	}
		// },

		// toggleVariablesBox: function () {
		// 	// check if variableBox is visible
		// 	if ($('#variablesBox').is(':visible')) {
		// 		$('#variablesBox').hide();
		// 	} else {
		// 		$('#variablesBox').show();
		// 	}
		// },

		change: function () {
			// deactivate the instantmessagedeadlinedays input if the instantmessage checkbox is not checked
			this.$instantmessagedefaultdeadlinedays.attr('disabled', !this.$instantmessagedeadline.is(':checked'));

			var oldSettings = user.get('settings'),
				oldSubcontractorOrder = oldSettings.subcontractorOrder,
				notifyChanges = oldSettings.notifyChanges || {},
				projectRadios = oldSettings.projectRadios || {},
				showWarrantyWhenNoWarranty = oldSettings.showWarrantyWhenNoWarranty || {},
				autodeadline = this.$radiodeadline.val();
			notifyChanges[currentProject.id] = {
				notify: this.$notifyIfAnyDefectChanged.is(':checked'),
				notify2: this.$notifyIfAnyDefectCurUserCreatedChanged.is(':checked'),
				notify3: this.$notifyIfAgCreatedDefect.is(':checked')
			};
			autodeadline = autodeadline ? parseInt(autodeadline) : null;
			projectRadios[currentProject.id] = {
				interval: this.$radioInterval.val(),
				includeChanged: this.$radioIncludeChanged.is(':checked'),
				autodeadline: autodeadline
			};
			var settings = {
				lang: this.$language.val(),
				sendAccount: this.$sendaccount.is(':checked'),
				addTimestamp: this.$addtimestamp.is(':checked'),
				addPrincipalId: this.$addprincipalid.is(':checked'),
				autoSelectDeepestLocation: this.$autoSelectDeepestLocation.is(':checked'),
				hideNews: this.$hideNews.is(':checked'),
				instantMessageDeadline: this.$instantmessagedeadline.is(':checked'),
				instantMessageDefaultDeadlineDays: (this.$instantmessagedefaultdeadlinedays.val() && parseInt(this.$instantmessagedefaultdeadlinedays.val()) ? (parseInt(this.$instantmessagedefaultdeadlinedays.val()) < 0 ? 0 : parseInt(this.$instantmessagedefaultdeadlinedays.val())) : 0),
				altLocationFilter: this.$altlocationfilter.is(':checked'),
				subcontractorOrder: this.$subcontractororder.val(),
				defaultUploadSettings: this.$defaultUploadSettings.val(),
				showStats: false,
				signatures: this.$signatures.val(),
				signature: this.sketcher.getAnnotation(),
				printing: this.$printing.val(),
				sendStatisticsEmails: !this.$sendStatisticsEmails.is(':checked'),
				filenameSchema: this.$filenameSchema.val(),
				notifyChanges: notifyChanges,
				projectRadios: projectRadios,
				defectMetadata: this.getDefectMetadata(),
				showWarrantyWhenNoWarranty: this.$showWarrantyWhenNoWarranty.is(':checked'),
				shortcuts: shortcuts.getShortcutsKeys().getKeyList(),
				shortcutsHoverSetting: shortcuts.getShortcutsKeys().getHoverSetting(),
				shortcutsHighlightSetting: shortcuts.getShortcutsKeys().getHighlightSetting(),
				defectSync: this.$defectSync.val(),
				// ot2Sync: this.$ot2Sync.val(),

			};
			user.get('settings').shortcuts = shortcuts.getShortcutsKeys().getKeyList();
			user.get('settings').shortcutsHoverSetting = shortcuts.getShortcutsKeys().getHoverSetting();
			user.get('settings').shortcutsHighlightSetting = shortcuts.getShortcutsKeys().getHighlightSetting();

			this.$saveButton.text(user.translate('settings.save'));
			this.$saveButton.prop('disabled', false);

			this.$resetButton.show();
			this.isChanged = true;

		},

		resetChanges: function () {
			this.$resetButton.hide();
			this.$saveButton.text(user.translate('settings.noChangesDetected'));
			this.$saveButton.prop('disabled', true);
			this.isChanged = false;

			this.render({ projectSettings: this.priorProjectSettings, settings: this.priorSettings });
		},

		saveChanges: function () {
			currentProject.set('showSubcontractorsToPrincipal', this.$showSubcontractorsToPrincipal.is(':checked'));
			currentProject.saveInfo();
			this.isChanged = false;
			var settings = {
				lang: this.$language.val(),
				sendAccount: this.$sendaccount.is(':checked'),
				addTimestamp: this.$addtimestamp.is(':checked'),
				addPrincipalId: this.$addprincipalid.is(':checked'),
				autoSelectDeepestLocation: this.$autoSelectDeepestLocation.is(':checked'),
				hideNews: this.$hideNews.is(':checked'),
				instantMessageDeadline: this.$instantmessagedeadline.is(':checked'),
				instantMessageDefaultDeadlineDays: (this.$instantmessagedefaultdeadlinedays.val() && parseInt(this.$instantmessagedefaultdeadlinedays.val()) ? (parseInt(this.$instantmessagedefaultdeadlinedays.val()) < 0 ? 0 : parseInt(this.$instantmessagedefaultdeadlinedays.val())) : 0),
				altLocationFilter: this.$altlocationfilter.is(':checked'),
				subcontractorOrder: this.$subcontractororder.val(),
				defaultUploadSettings: this.$defaultUploadSettings.val(),
				showStats: false,
				signatures: this.$signatures.val(),
				signature: this.sketcher.getAnnotation(),
				printing: this.$printing.val(),
				sendStatisticsEmails: !this.$sendStatisticsEmails.is(':checked'),
				filenameSchema: this.$filenameSchema.val(),
				notifyChanges: this.priorSettings.notifyChanges,
				projectRadios: this.priorSettings.projectRadios,
				defectMetadata: this.getDefectMetadata(),
				showWarrantyWhenNoWarranty: this.$showWarrantyWhenNoWarranty.is(':checked'),
				shortcuts: shortcuts.getShortcutsKeys().getKeyList(),
				shortcutsHoverSetting: shortcuts.getShortcutsKeys().getHoverSetting(),
				shortcutsHighlightSetting: shortcuts.getShortcutsKeys().getHighlightSetting(),
				defectSync: this.$defectSync.val(),
			};

			var projectSettings = {
				costType: this.$costType.val(),
				showSubcontractorsToPrincipal: this.$showSubcontractorsToPrincipal.is(':checked'),
				showPrincipalIdsOnPdfs: this.$showPrincipalIdsOnPdfs.is(':checked'),
				showSubcontractorContactInFileName: this.$showSubcontractorContactInFileName.is(':checked'),
				minLocationDepth: this.$minLocationDepth.val(),
				contactIndex: this.$contactIndex.val(),
				extManual: this.$extManual.is(':checked'),
				sendNotifications: this.$sendNotifications.is(':checked'),
				owners: null,
				participantLists: null,
				anRequestSubject: this.$anRequestSubjectInput.val(),
				requestEmailText: this.$anRequestContentInput.val(),
				externalDefectsOnly: this.$externalDefectsOnly.is(':checked'),
				anRequestSplitByLocationDepth: this.$anRequestSplitByLocationDepth.val(),
				showYellowAgPins: this.$showYellowAgPins.is(':checked'),
				stateDefectTypeSpecial: this.$stateDefectTypeSpecial.is(':checked'),
				AnCommentInAgList: this.$AnCommentInAgList.is(':checked'),
				AgCommentInAnList: this.$AgCommentInAnList.is(':checked'),
				sendCopyWhenDownload: this.$sendCopyWhenDownload.is(':checked'),
				attachVisibleFiles: this.$attachVisibleFiles.is(':checked'),
				chooseSubAutomatically: this.$chooseSubAutomatically.is(':checked'),
				groupsActive: this.$groupsActive.is(':checked'),
				ot2Sync: false,
				sync: null,
				defectSync: this.$defectSync.val(),
			};

			$('.pmSetting').prop("disabled", true);
			// make a POST request to the server to save the settings using ajax
			$.mobile.loading('show');
			var projectUpdate = $.ajax({
				url: window.contextPath + "/api/projectmanager/projects/" + currentProject.id + "?lastModified=" + Date.now().toString(),
				type: 'POST',
				data: JSON.stringify(
					_.merge({}, {
						"id": currentProject.get('id'),
						"name": currentProject.get('name'),
					}, projectSettings)),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				success: _.bind(
					function (data) {
						$.mobile.loading('hide');
						$('.pmSetting').prop("disabled", false);
						this.$saveButton.text(user.translate('settings.noChangesDetected'));
						this.$saveButton.prop('disabled', true);
						this.$resetButton.hide();
					}, this)
			});
			return projectUpdate.then(function () {

				this.toggleInstantMessageDeadline(this.$instantmessagedeadline.prop('checked'));
				this.priorSettings = settings;
				this.priorProjectSettings = projectSettings;
				return user.save({ settings: _.merge({}, user.get('settings'), settings) }, { url: window.contextPath + '/api/settings' })
					.then(function () {
						if (oldSubcontractorOrder != settings.subcontractorOrder) {
							return subcontractors.fetch();
						}
						window.alertPopup(user.translate('settings.saved'));
					});
			});
		},

		getDefectMetadata: function () {
			return {
				defect_show_creation_date: this.$defectCreationDate.prop('checked'),
				defect_show_creator: this.$defectCreator.prop('checked'),
				defect_show_actualisation_date: this.$defectActualisationDate.prop('checked'),
				defect_show_deadline: this.$defectDeadline.prop('checked'),
				defect_show_subcontractor: this.$defectSubcontractor.prop('checked'),
				defect_show_escalationlevel: this.$defectEscalationlevel.prop('checked'),
				// defect_show_fixed_date: this.$defectFixed.prop('checked'),
			}
		},

		applylang: function (e) {
			e.stopPropagation();
			e.preventDefault();
			window.confirmPopup(user.translate('settings.setlang.confirm')).done(function () {
				window.location.reload();
			});
		},

		configuremenu: function () {
			this.router.sidePanel.edit();
		},


		toggleAnRequestVariables: function () {
			this.$anRequestVariables.toggle();
		},

		toggleAnRequestEmail: function () {
			this.$anRequestEmail.toggle();
			this.changeAnRequestEmail();

		},

		changeAnRequestEmail: function () {
			var subject = this.$anRequestSubjectInput.val();
			this.$anRequestEmailSubject.text(subject)
			var finishedText = this.$anRequestContentInput.val();
			finishedText = finishedText.replaceAll("\n", "<br/>");
			const now = new Date();
			finishedText = finishedText.replace("%Projektname%", currentProject.get('name'));
			finishedText = finishedText.replace(/%ProjektKurz%/g, currentProject.get('id'));
			finishedText = finishedText.replace(/%ProjektID%/g, currentProject.get('id'));
			finishedText = finishedText.replace(/%Gewerk%/g, currentProject.get('crew'));
			finishedText = finishedText.replace(/%DatumHeute%/g, now.toLocaleDateString('de-DE'));
			finishedText = finishedText.replace(/%DatumHeuteReverseKurz%/g, now.toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, ''));
			finishedText = finishedText.replace(/%DatumZeitHeute%/g, now.toLocaleDateString('de-DE') + " " + now.toLocaleTimeString('de-DE'));
			finishedText = finishedText.replace(/%AgName%/g, currentProject.get('principalInfo').name && "Hoch und Tief AG");
			finishedText = finishedText.replace(/%AgNummer%/g, "N1");
			finishedText = finishedText.replace(/%Auftragnehmer%/g, "Groß und Klein GmbH");
			finishedText = finishedText.replace(/%AuftragnehmerKurz%/g, "GuK GmbH");
			finishedText = finishedText.replace(/%MangelNr%/g, "N1");
			finishedText = finishedText.replace(/%Mangeltyp(en)%/g, "Pfusch");
			finishedText = finishedText.replace(/%MEskalationsstufe%/g, Math.random() > 0.5 ? "1" : "2");
			finishedText = finishedText.replace(/%Projektstruktur[1-6]%/g, "Flur");
			this.$anRequestEmailContent.html(finishedText);
		},

		toggleExtendedUserSettings: function () {
			this.$extendedUserSettings.toggle();
			var visible = this.$extendedUserSettings.is(':visible');
			this.$extendedUserButton.attr('checked', visible)
			this.$extendedUserButton.html(user.translate(visible ? 'settings.user.extended.hide' : 'settings.user.extended.show'));
		},

		toggleExtendedProjectSettings: function () {
			this.$extendedProjectSettings.toggle();
			var visible = this.$extendedProjectSettings.is(':visible');
			this.$extendedProjectButton.prop('checked', visible)
			this.$extendedProjectButton.html(user.translate(visible ? 'settings.project.extended.hide' : 'settings.project.extended.show'));
		},

		toggleExtendedCustomerSettings: function () {
			this.$extendedCustomerSettings.toggle();
			var visible = this.$extendedCustomerSettings.is(':visible');
			this.$extendedCustomerButton.prop('checked', visible)
			this.$extendedCustomerButton.html(user.translate(visible ? 'settings.customer.extended.hide' : 'settings.customer.extended.show'));
		},

		createCodes: function () {
			var level = this.$codeOptions.val();
			window.open('/api/codeDocument/' + window.encodeURIComponent(currentProject.id) + '?level=' + level);
		},

		deleteplans: function () {
			if (iosapp.appavailable && iosapp.functionavailable('syncplans')) {
				iosapp.deleteplans();
			}
		},

		deletemetadata: function () {
			if (iosapp.appavailable && iosapp.functionavailable('attachments')) {
				iosapp.deleteattachments();
			}
		},

		openSafetyPopup: function () {
			if (user.isAdmin()) {
				this.$alterFopPopup.popup({
					afterclose: _.bind(function () {
						this.$safetyWarning.hide();
						this.$safetyWordinput.val('');
						this.$safetyPopup.popup('open');
						if (this.$safetyPopup.parent().css('top') === '0px') {
							var posY = (window.scrollY + (window.innerHeight / 2)) - (this.$safetyPopup.height() / 2)
							this.$safetyPopup.css({
								top: posY
							})
						} else {
							this.$safetyPopup.css({
								top: 0
							})
						}
					}, this)
				}).popup('close');
				this.$alterFopPopup.popup({
					afterclose: null,
				});
			} else {
				alertPopup(user.translate('requestbundle.noAdminRights'))
			}
		},

		cancelSafetyPopup: function (shouldOpenFopPopup) {
			this.$safetyPopup.popup({
				afterclose: _.bind(function () {
					if (shouldOpenFopPopup) this.$alterFopPopup.popup('open');
					this.$safetyWordinput.val('');

					this.alteredDocument = null;
				}, this)
			}).popup('close')
		},

		saveAlteredFopsPermanently: function () {
			var isProjectSelected = this.$chooseFolder.val() === 'project';
			if (!user.get('authorities').includes('ROLE_ADMIN') ||
				(user.get('authorities').includes('ROLE_PROJECTADMIN') && !isProjectSelected)) {
				alertPopup('requestbundle.noSuitableAdminRights');
				return;
			}
			var document = this.alteredDocument;
			if (this.$safetyWordinput.val() !== user.translate('requestbundle.change')) {
				this.$safetyWarning.show();
				this.$safetyWordinput.val('');
				return;
			}

			for (var fopElement in document.fileMap) {
				document.fileMap[fopElement] = this.$('.input_' + fopElement).val();
			}
			// var project = isProjectSelected ? currentProject.get('id') : 'null'
			var project = currentProject.get('id');
			var $option = this.$('.previews option:selected')
			$.ajax({
				url: window.contextPath + '/api/saveFopToTemplate/' + project + '/' + $option.attr('class').trim() + '/' + $option.attr('data-request') + '/' + isProjectSelected,
				type: 'POST',
				data: JSON.stringify(document.fileMap),
				contentType: 'application/json'
			}).done(_.bind(function () {
				this.cancelSafetyPopup();

			}, this))
				.fail(_.bind(function () {
					alertPopup(user.translate('requestbundle.error'));
				}, this));
		},
		resetFopPopup: function () {
			this.$('.project-name').text('');
			this.$('.textBlocks').html('');
		},

		cancelFopPopup: function () {
			this.alteredDocument = null;
			this.$alterFopPopup.popup({
				afterclose: null
			}).popup('close');
		},

		addFopElementTo: function ($textBlock, val, key, isEditable) {
			if (isEditable)
				$textBlock.append($('<li>').append($('<textarea class="input_' + key + '" style="white-space: pre-wrap; width: calc(100% - 8px);">').text(val)));
			else
				$textBlock.append($('<li>').append($('<div style="margin: 10px; width: 96%; white-space: pre-wrap">').text(val)));
		},

		fillFopPopup: function (doc, docTitle, callback) {
			this.$('.document-name').text(docTitle);
			var $textBlock = this.$('.textBlocks');
			var j = 0;
			if (doc.fileMap && doc.isEditable) {
				var sortedKeyList = this.sortFileMap(doc.fileMap);
				var promise = new Promise(function (resolve, reject) {
					_.each(sortedKeyList, function (fopElement) {
						this.addFopElementTo($textBlock, doc.fileMap[fopElement], fopElement, doc.isEditable[fopElement]);
						j++;
						if (j === sortedKeyList.length) resolve();
					}.bind(this))
				}.bind(this));
				promise.then(function () {
					callback();
				});
			}
			this.alteredDocument = doc;
		},

		sortFileMap: function (fileMap) {
			return Object.keys(fileMap).slice().sort();
		},

		toggleInstantMessageDeadline: function (toggle) {
			if (toggle) {
				this.$deadlineDaysContainer.show();
			} else {
				this.$deadlineDaysContainer.hide();
			}
		},

		save: function () {
			var onSync = function () {
				Backbone.Events.trigger('project:edit', this.model.id || this.model.cid);
			};
			this.listenToOnce(currentProject, 'sync', onSync);
			this.listenToOnce(currentProject, 'error', function () {
				this.stopListening(currentProject, 'sync', onSync);
			});
			Backbone.Events.trigger('project:save');
			this.dirty = false;
		},
	});
});
