import {serviceDataCommunicationSource} from "./constants";


export const handleLocalstorageItemFromOtherServices = (callback:((data:any,key:string)=>void)) => {
    Object.values(serviceDataCommunicationSource)
        .find(key => {
            let data = localStorage.getItem(key);
            if(!!data) {
                localStorage.removeItem(key);
                callback(data, key);
            }
            return !!data;
        });
}
