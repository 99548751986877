import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton, StyledIconButton} from "@bau/material";
import {useLocation} from "react-router-dom";
import {EllipsisHorizontalIcon} from "@heroicons/react/24/outline";
import WarningIcon from "@mui/icons-material/Warning";
import {Button} from "@mui/material";
import {translate} from "../utils/utils";


const Files = () => {
    const {state} = useLocation();

    useEffect(()=>{
        // @ts-ignore
        window.filesUseEffect(!!state && state.directlyShowPage);
    },[])


    let right = [
        <Button key="offlineWarning" className="offlineWarning" variant="outlined" startIcon={<WarningIcon/>}>
            {translate("files.offlinewarning")}
        </Button>,
        <StyledIconButton key="actionstrigger" className="options-menu-button actionstrigger">
            <EllipsisHorizontalIcon/>
        </StyledIconButton>
    ]




    return <PageContainer newPage={false} headerText={translate("files.title")}
                          left={<StyledButton className="previousPage" >{translate("goback")}</StyledButton>}
                          right={right}
    />
}

export default Files;