// Copyright 2014, Open Experience GmbH. All rights reserved.

define([
	'../lib/jquery-1.11.0', '../lib/backbone-1.1.2', './SubcontractorDetailsHtml',
	'../model/user', '../model/ma_accounts'
], function (
	$, Backbone, Template,
	user, ma_accounts
) {
	'use strict';

	return Backbone.View.extend({

		className: 'subcontractorDetails ui-bar ui-bar-a textselect',

		//gotoSyncTargetAccount: null,

		events: {
		//	'click .gotoSyncTarget button': function () {
		//		this.gotoSyncTargetAccount.go();
		//	}
		},

		initialize: function () {
			this.$el.append(Template);

			this.$header = this.$('h2');
			this.$table  = this.$el.children('table');
			//this.$sync   = this.$('.gotoSyncTarget');
		},

		render: function () {
			if (!this.model) {
				this.$header.text(user.translate('defects.filter.emptysubcontractor'));
				this.$table.empty();
				//this.$sync.hide();
			} else {
				this.$header.text(this.model.getLabel());
				this.$table.empty();
				if (this.model.get('address')) {
					this.$table.append($('<tr><td>' + user.translate('subcontractor.contact') + '</td></tr>').append($('<td></td>').html(this.model.get('address').replace(/\n/g, '<br/>'))));
				}
				if (this.model.get('phone')) {
					this.$table.append($('<tr><td>' + user.translate('subcontractor.phone') + '</td></tr>').append($('<td></td>').text(this.model.get('phone'))));
				}
				if (this.model.get('email')) {
					this.$table.append($('<tr><td>' + user.translate('subcontractor.email') + '</td></tr>').append($('<td></td>').text(this.model.get('email'))));
				}
				//this.$sync.toggle(this.gotoSyncTargetAccount !== null);
			}
		},

		setModel: function (model) {
			this.model = model;
			//this.gotoSyncTargetAccount = null;

			//if (!model) {
			//	return;
			//}
			//var targetCustomer = this.model.get('syncTargetCustomer');
			//if (targetCustomer) {
			//	this.gotoSyncTargetAccount = ma_accounts.findByCustomer(targetCustomer);
			//	this.render();
			//}
		}

	});

});
