define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', 'moment', './SubcontractorBundlePageHtml', '../view/SubcontractorDetails', '../view/BaseView',
	'../model/SubcontractorPaperwork', '../model/TrackedPaperworkInfo', '../model/paperwork', '../model/currentProject', '../model/currentInspection', '../model/user',
	'../helper/iosapp', '../helper/watchdog', '../model/proxyInfo', '../helper/backboneReactCommunicationUtils'
], function (
	$, _, Backbone, moment, Template, SubcontractorDetails, BaseView,
	SubcontractorPaperwork, TrackedPaperworkInfo, paperwork, currentProject, currentInspection, user,
	iosapp, watchdog, proxyInfo, backboneReactCommunicationUtils
) {
	return BaseView.extend({

		id: 'subcontractor-bundle',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		showFirstCount: 0,
		itemCount: 0,
		pageSize: 30,
		checkedIndices: {},

		events: {
			'orientationchange':                 function() { $.mobile.resetActivePageHeight(); this.resize(); },
			'click .list .ui-checkbox': function (e) {
				var $input = $(e.currentTarget).children('input.selectItem');
				var state = $input.prop("checked");
				if (!$(e.target).is('input')) {
					state = !state;
					$input.prop("checked", state).focus();
				}
				var index = $input.parents('[data-index]').attr('data-index');
				if (state) {
					this.checkedIndices[index] = true;
				} else {
					delete this.checkedIndices[index];
				}
			},
			'vclick .moreRows button':  function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.renderList({ dontEmpty: true });
				var $children = this.$list.children();
				var $lastElement = $children.get($children.length - 1);
				this.$list.scrollTo($lastElement, 500);
			},
			'vclick .requestBundle':    function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.router.requestBundlePage.setModel(this.model);
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/requestBundle');
			},
			'vclick .send-credentials': function (e) {
				e.preventDefault();
				e.stopPropagation();

				if (!watchdog.isConnected()) {
					alertPopup(user.translate('popup.connectionerror'));
					return;
				}

				$.mobile.loading('show');
				$.post( '/onlineBauabnahme/api/send-subcontractor-credentials', { subcontractor: this.model.get('id') })
				.done(_.bind(function (sendStatus) {
					$.mobile.loading('hide');
					if (sendStatus.success) {
						alertPopup(user.translate('protocol.sent'));
					} else if (!!sendStatus.failedAddresses && sendStatus.failedAddresses.length) {
						alertPopup(user.translate('instant.notsent').replace('%s', sendStatus.failedAddresses.join('\n')));
					} else if (sendStatus.description) {
						alertPopup(sendStatus.description);
					} else {
						alertPopup(user.translate('paperwork.showdocument.error'));
					}
				}, this))
				.fail(function () {
					$.mobile.loading('hide');
					alertPopup(user.translate('paperwork.showdocument.error'));
				});

			},
			'vclick .copy-credentials': function (e) {
				e.preventDefault();
				e.stopPropagation();

				if (!watchdog.isConnected()) {
					alertPopup(user.translate('popup.connectionerror'));
					return;
				}

				$.mobile.loading('show');
				$.post( '/onlineBauabnahme/api/get-subcontractor-credentials', { subcontractor: this.model.get('id') })
					.done(_.bind(function (link) {
						$.mobile.loading('hide');
						if (link) {
                            linkPopup(link);
							this.copyText(link);
						}
					}, this))
					.fail(function () {
						$.mobile.loading('hide');
						alertPopup(user.translate('paperwork.showdocument.error'));
					});

			},
			'vclick .openFile':         function (e) {
				e.preventDefault();
				e.stopPropagation();
				$.mobile.loading('show');
				setTimeout(function () {
					var $button = $(e.currentTarget);
					var index = $button.parents('[data-index]').attr('data-index');
					var files = this.subcontractorPaperwork.get('children');
					var file = files[index];
					this.router.imagePage.setModel(new SubcontractorPaperwork(file));
					this.router.imagePage.setupPageForDocument();
					window.showPageCallback = function () {
						this.router.imagePage.setupPageForDocument();
					}.bind(this);
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/image', {callback: true});
				}.bind(this), 100);
				 
			}/*,
		    'click .removeFromList': function () {
			    var files = this.subcontractorPaperwork.get('children');
			    _.each(this.checkedIndices, function (value, index) {
				    var file = files[index];

			    }, this)
		    }*/
		},

		initialize: function (options) {
			this.router = options.router;
			this.resize = _.bind(this.resize, this);
			this.subcontractorPaperwork = new SubcontractorPaperwork();
			this.trackedPaperworkInfo = new TrackedPaperworkInfo();

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.subcontractorDetails = new SubcontractorDetails();
			this.$el.children('[data-role="content"]').prepend(this.subcontractorDetails.$el);

			this.$list = this.$('.list');
			this.$whichDefects = this.$('.whichDefects');

			$(window).on('throttledresize', this.resize);
			this.$el.on('pageshow', this.resize);
		},

		render: function () {

			this.subcontractorDetails.render();
			
			// this.timeouts = [];

			this.updateDefectCounts();

			if (proxyInfo.get('proxyMode')) {
				this.$el.children('[data-role="content"]').addClass('proxy-mode');
			} else {
				this.refreshList();
			}

			return this;
		},

		updateDefectCounts: function () {
			var request1 = this.subcontractorPaperwork.get('request1defects');
			var request1Unsent = this.subcontractorPaperwork.get('request1defectsUnsent');
			var request2 = this.subcontractorPaperwork.get('request2defects');
			var request2Unsent = this.subcontractorPaperwork.get('request2defectsUnsent');

			var $options = this.$whichDefects.children();
			var $option, text;
			text = ($option = $options.filter('[value=allUnsent]')).text()      .replace(/ \[\d+\]$/, '') + ' [' + (request1Unsent + request2Unsent) + ']'; $option.text(text);
			text = ($option = $options.filter('[value=all]')).text()            .replace(/ \[\d+\]$/, '') + ' [' + (request1 + request2) + ']';             $option.text(text);
			text = ($option = $options.filter('[value=firstUnsent]')).text()    .replace(/ \[\d+\]$/, '') + ' [' + (request1Unsent) + ']';                  $option.text(text);
			text = ($option = $options.filter('[value=first]')).text()          .replace(/ \[\d+\]$/, '') + ' [' + (request1) + ']';                        $option.text(text);
			text = ($option = $options.filter('[value=secondUnsent]')).text()   .replace(/ \[\d+\]$/, '') + ' [' + (request2Unsent) + ']';                  $option.text(text);
			text = ($option = $options.filter('[value=second]')).text()         .replace(/ \[\d+\]$/, '') + ' [' + (request2) + ']';                        $option.text(text);
		},

		refreshList: function (options) {
			var files = this.subcontractorPaperwork.get('children');
			this.filesCount = files.length;
			this.itemCount = 0;

			if (options && options.newListContext) {
				this.showFirstCount = 0;
			}

			this.renderList();
		},

		renderList: function (options) {
			options = options || {};
			if (!options.dontEmpty) {
				this.$list.empty();
			}
			var files = this.subcontractorPaperwork.get('children');

			if (this.filesCount === 0) {
				$('<li class="none">').text(user.translate('subcontractor.bundle.nodocuments')).appendTo(this.$list);
			} else {
				for (var i = 0; i < this.pageSize && this.hasMoreItemsInList(); i++, this.itemCount++) {
					this.renderDocument(files[this.itemCount]);
				}

				if (this.hasMoreItemsInList()) {
					if (!options.noRecursion) {
						var recursionOptions = {
							dontEmpty:   true,
							noRefresh:   true,
							noRecursion: true
						};
						while (this.itemCount < this.showFirstCount && this.hasMoreItemsInList()) {
							this.renderList(recursionOptions);
						}
						if (this.hasMoreItemsInList()) {
							if (this.$moreRows) {
								this.$moreRows.detach();
								this.$list.append(this.$moreRows);
							} else {
								this.$moreRows = $('<li class="moreRows"><button>' + user.translate('subcontractor.bundle.more') + '</button></li>').appendTo(this.$list).enhanceWithin();
							}
						}
					}

				} else if (!options.noRecursion && this.$moreRows) {
					this.$moreRows.detach();
				}

				if (!options.noRecursion) {
					this.showFirstCount = this.itemCount;
				}
			}

			if (!options.noRefresh) {
				this.$list.listview('refresh');
			}
		},

		hasMoreItemsInList: function () {
			return this.itemCount < this.filesCount;
		},

		renderDocument: function (file) {
			var files = this.subcontractorPaperwork.get('children');
			var documentIndex = _.indexOf(files, file);
			var $item = $('<li></li>').appendTo(this.$list);
			$item = $('<div class="ui-grid-a ui-corner-all"></div>').appendTo($item).attr('data-index', documentIndex);
			//$item.append('<input type="checkbox" class="selectItem ui-btn ui-block-a"/>');
			var test = function () {
				if (user.isSubcontractor() && this.trackedPaperworkInfo && this.trackedPaperworkInfo.get('downloaded') && _.has(this.trackedPaperworkInfo.get('downloaded'), file.path) && !this.trackedPaperworkInfo.get('downloaded')[file.path]) {
					var link =  '/onlineBauabnahme/api/attachment/update';
					$.post(link, {path: file.path}, function (data) {
						this.trackedPaperworkInfo.fetch();
					}.bind(this));
				}
			}.bind(this);
			$('<div class="selectItem ui-btn ui-block-a" style="height: 38px; background: url(' +  '/onlineBauabnahme/images/fileicon.png) no-repeat center bottom"/></div>')
				.appendTo($item)
				.click(_.bind(function () {
					test();
					var link =  '/onlineBauabnahme/api/downloadFile?project=' + window.encodeURIComponent(currentProject.id) +'&path=' + window.encodeURIComponent(file.path);
					if (iosapp.appavailable) {
						var path = file.path;
						var slashIdx = path.lastIndexOf('/');
						if (slashIdx >= 0) path = path.substr(slashIdx + 1);
						iosapp.downloadAndSharePdf(window.location.origin + link, path);
					} else {
						window.open(link);
					}
				}, this));
			var $filebtn = $('<button class="openFile ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r ui-btn-icon"></button>')
				.append($('<a>').text(file.name))
				.click(function () {
					test();
				}.bind(this));
			$item.append($filebtn);
			if (this.trackedPaperworkInfo && this.trackedPaperworkInfo.get('downloaded')) {
				var $trackedbtn, handler;
				if (_.has(this.trackedPaperworkInfo.get('downloaded'), file.path)) {
					$trackedbtn = $('<div class="ui-btn ui-btn-icon-right ui-icon-search ui-vis-jqmicon-center"></div>');
					var downloaded = this.trackedPaperworkInfo.get('downloaded')[file.path] && _.pairs(this.trackedPaperworkInfo.get('downloaded')[file.path]).length;
					if (downloaded) {
						$trackedbtn.addClass('downloaded');
					}
					handler = (function(e) {
						e.stopPropagation();
						e.preventDefault();
						
						var text;
						if (downloaded) {
							text = user.translate('subcontractor.bundle.tracked.downloaded').replace('%date%', moment(downloaded).format('lll'));
							text = user.translate('subcontractor.bundle.tracked.downloaded');
							_.forEach(this.trackedPaperworkInfo.get('downloaded')[file.path], function (value, key) {
								text += '\n' + moment(value).format('lll') + (key ? ': ' + key : '');
							});
						} else {
							text = user.translate('subcontractor.bundle.tracked.notdownloaded');
						}
						window.alertPopup(text);
					}.bind(this));
				} else {
					$trackedbtn = $('<div class="ui-btn ui-btn-icon-right ui-icon-mail ui-vis-jqmicon-center"></div>');
					handler = (function(e) {
						e.stopPropagation();
						e.preventDefault();
						
						var text = user.translate('subcontractor.bundle.untracked')
						window.alertPopup(text);
					}.bind(this));
				}
				$filebtn.append($trackedbtn);
				$trackedbtn.mousedown(handler);
				$trackedbtn.touchstart(handler);
				$trackedbtn.click(function(e) {
					e.stopPropagation();
					e.preventDefault();
				})
			}

			$item.enhanceWithin();
		},

		renderTextDivider: function (key) {
			$('<li data-role="list-divider" data-theme="b"></li>').text(key).appendTo(this.$list);
		},

		refreshSelections: function () {
			this.$list.find('input.selectItem').prop('checked', false);
			_.each(this.checkedIndices, function (val, index) {
				this.$list.find('[data-index="' + index + '"] input.selectItem').prop('checked', true);
			}, this);
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active') && !self.$el.hasClass('hide-page')) {
					var listMargin = parseInt(self.$list.css('margin-bottom')) + parseInt(self.$list.css('margin-top'));
					self.$list.hide();
					var last = self.$('.ui-content > *:visible').eq(-1);
					var pageOffset = parseInt(self.$el.css('padding-top'));
					var height = Math.floor(self.$el.height() - last.position().top - last.outerHeight(true) + pageOffset - listMargin) - 1;
					self.$list.height(height).show();
				}
			}, this);
		},

		remove: function() {
			$(window).off('throttledresize', this.resize);
			Backbone.View.prototype.remove.apply(this, arguments);
		},

		setModel: function (model) {
			this.model = model;
			this.subcontractorDetails.setModel(model);
		},
		copyText: function(text){
			if (window.clipboardData && window.clipboardData.setData) {
				// IE specific code path to prevent textarea being shown while dialog is visible.
				return clipboardData.setData("Text", text);

			} else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
				var textarea = document.createElement("textarea");
				textarea.textContent = text;
				textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
				document.body.appendChild(textarea);
				textarea.select();
				try {
					return document.execCommand("copy");  // Security exception may be thrown by some browsers.
				} catch (ex) {
					console.warn("Copy to clipboard failed.", ex);
					return false;
				} finally {
					document.body.removeChild(textarea);
				}
			}
		}

	});
});