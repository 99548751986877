import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import WarningIcon from "@mui/icons-material/Warning";
import {Button} from "@mui/material";
import {translate} from "../utils/utils";
import {useLocation} from "react-router-dom";


const InstantMessage = () => {

    const {state} = useLocation();

    useEffect(()=>{
        if(!state || !state.noUseEffect ) {
            // @ts-ignore
            window.instantMessageUseEffect();
        }
        // @ts-ignore
        !!state && !!state.callback && !!window.showPageCallback && window.showPageCallback();
        // @ts-ignore
        window.showPageCallback = null;

    },[])

    const right = [
        <Button key="offlineWarning" className="offlineWarning" variant="outlined" startIcon={<WarningIcon/>}>
            {translate("instant.noconnection")}
        </Button>,
        <StyledButton key="send" className="send">{translate("instant.send")}</StyledButton>
    ]

    return <PageContainer newPage={false} headerText={translate("instant.title")}
                          left={<StyledButton className="previousPage">{translate("instant.back")}</StyledButton>}
                          right={right}
    />
}

export default InstantMessage;