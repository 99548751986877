define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './SubcontractorsPageHtml',
	'../model/currentDefects', '../model/subcontractors', '../model/Subcontractor', '../model/currentProject', '../model/currentInspection', '../model/user',
	'../helper/backboneReactCommunicationUtils'
], function (
	$, _, Backbone, Template,
	currentDefects, subcontractors, Subcontractor, currentProject, currentInspection, user, backboneReactCommunicationUtils
) {
	return Backbone.View.extend({

		id: 'subcontractors',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		events: {
			'vclick .select-inspection': function (e) {
				e.preventDefault();
				e.stopPropagation();
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/selectInspection');
			},
			'vclick .actionstrigger': 'triggerActionsMenu',
			'vclick .moreRows button':             function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.renderList({ dontEmpty: true });
				var $children = this.$list.children();
				var $lastElement = $children.get($children.length - 1);
				this.$list.scrollTo($lastElement, 500);
			},
		    'change input[name=order]:radio': function (e) {
			    var comparator = this[$(e.currentTarget).val()];
			    if (comparator !== this.comparator) {
				    this.comparator = comparator;
				    this.refreshList();
			    }
			    this.$actionsMenu.popup('close');
		    },
			'vclick .statusAll': function (e) {

				e.preventDefault();
				e.stopPropagation();

				var subcontractorId = $(e.currentTarget).parent().parent().attr('data-id');
				currentDefects.resetFilter();
				currentDefects.elementFilter.subcontractor = subcontractorId;

				var newDefects = window.parseInt($(e.currentTarget).find('.newDefects').text());
				if (!user.isPrincipal() && !user.isSubcontractor() && newDefects > 0) {
					this.router.subcontractorPage.orderBy = 'smod';
					currentDefects.elementFilter.status = 'pending-subcontractor-fixed';
					this.router.subcontractorPage.$el.one('pageshow', _.bind(function () {
						this.router.subcontractorPage.filter();
						window.alertPopup(user.translate('notification.popup.subcontractor').replace('%d', newDefects));
					}, this));

					//update impression indicator
					$.ajax({
						type: 'POST',
						url:  '/onlineBauabnahme/api/subcontractorImpression',
						global: false,
						data: { project: currentProject.id, subcontractor: subcontractorId }
					});
					subcontractors.get(subcontractorId).set('newDefects', 0);
				}

				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/subcontractor/'+subcontractorId);
			},
			'vclick .createall': function (e) {
				e.preventDefault();
			    e.stopPropagation();
				var value = e.currentTarget.getAttribute("value") === 'alldefects' ? null: e.currentTarget.getAttribute("value");
				this.router.requestBundlePage.setModel(value);
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/requestBundle');
			},
			'vclick .goto-noassign': function () {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/subcontractor');
			}

		},

		showFirstCount: 0,
		itemCount: 0,
		pageSize: 30,
		comparator: null,

		initialize: function (options) {
			this.router = options.router;
			this.resize       = _.bind(this.resize, this);
			this.total        = _.bind(this.total, this);
			this.pending      = _.bind(this.pending, this);

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);

			this.$header      = this.$('[data-role=header]');
			this.$footer      = this.$('[data-role=footer]');
			this.$list        = this.$('.list');
			this.$actionsMenu = this.$('.actionsmenu');
			this.$defecttype  = this.$('.defecttype');
			this.$defectTypeContainer = this.$('.defect-types');

			this.comparator = null;
			this.$('input[name=order][value=standard]').prop('checked', true);




			this.$el.on('pageshow', _.bind(function () {
				this.resize();
			}, this));
			$(window).on('throttledresize', this.resize);
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active')  && !self.$el.hasClass('hide-page')) {
					var listMargin = parseInt(self.$list.css('margin-bottom'));
					self.$list.hide();
					var visible = self.$('.ui-content > *:visible');
					var last = $(visible[visible.length - 1]);
					var pageOffset = parseInt(self.$el.css('padding-top').split('px')[0]);
					var height = Math.floor(self.$el.height() - (last.length > 0 ? last.position().top : 0) - (last.outerHeight(true)||0) + pageOffset - listMargin);
					self.$list.height(height).show();
				}
			}, this);
		},

		render: function () {
			$('.actionstrigger').off('click');
			$('.actionstrigger').on('click', function (e) {
				this.triggerActionsMenu(e);
			}.bind(this));
			currentDefects.resetFilter();
			currentDefects.elementFilter.subcontractor = "null";
			var unassignedDefects = currentDefects.filtered().length;
			currentDefects.resetFilter();
			this.countInfo = {};
			currentDefects.each(function (defect) {
				var subcontractor = defect.get('subcontractor');
				if (!subcontractors.get(subcontractor)) {
					subcontractor = null;
				}
				if (!this.countInfo[subcontractor]) {
					this.countInfo[subcontractor] = {
						total: 0,
						pending: 0
					};
				}

				this.countInfo[subcontractor].total++;
				if (defect.isStatusPending()) {
					this.countInfo[subcontractor].pending++;
				}
			}, this);

			var $hint = this.$('.subcontractors-noassign-hint');
			$hint.toggle(!user.isPrincipal() && !!this.countInfo[null] && this.countInfo[null].total > 0 && unassignedDefects > 0);
			if (!!this.countInfo[null] && this.countInfo[null].total > 0 && unassignedDefects > 0) { //unassignedDefects is more accurate because countInfo will also contain principal defects! TODO: This needs rework
				$hint.find('span').text(user.translate('subcontractors.noassign.hint').replace('%d', unassignedDefects));
			}

            var defectTypes = currentProject.get('types');
            var domElements = [];
            _.forEach(defectTypes, function (type) {
                var style = 'color: ' + type.textColor + '; background-color: ' + type.backgroundColor + ( !!type.borderColor ? '; border: 1px solid ' + type.borderColor : '') + (type.isRounded ? '; border-radius: 5px' : '');
                if (this.$('.createall.'+type.defectTypeId).length === 0) {
                    domElements.push(
                        '<li><a class="ui-btn ui-btn-icon-right ui-icon-carat-r createall ' + type.defectTypeId + '" value = "'+ type.defectTypeId +'" ><span class="defect-type-indicator" style="' + style + '">' + type.content + '</span>' + user.translate(type.label) + '</a></li>'
                    )
                }
            }.bind(this));
            this.$defectTypeContainer.after(domElements.join(''));
            this.$('subcontractor-option-popup')

			this.refreshList();

			return this;
		},

		refreshList: function (options) {
			var modelsAttributes = [];
			for (var i = 0; i < subcontractors.models.length; i++) {
				modelsAttributes.push(subcontractors.models[i].attributes);
			}
			this.subcontractorKeys = _.pluck(this.comparator ? modelsAttributes.sort(this.comparator) : modelsAttributes, 'id');

			this.itemCount = 0;

			if (options && options.newListContext) {
				this.showFirstCount = 0;
			}

			this.renderList();
		},

		renderList: function (options) {
			options = options || {};
			if (!options.dontEmpty) {
				this.$list.empty();
			}

			for (var i = 0; i < this.pageSize && this.hasMoreItemsInList(); i++, this.itemCount++) {
				this.renderSubcontractor(subcontractors.get(this.subcontractorKeys[this.itemCount]));
			}

			if (this.hasMoreItemsInList()) {
				if (!options.noRecursion) {
					var recursionOptions = {
						dontEmpty:   true,
						noRefresh:   true,
						noRecursion: true
					};
					while(this.itemCount < this.showFirstCount && this.hasMoreItemsInList()) {
						this.renderList(recursionOptions);
					}
					if (this.hasMoreItemsInList()) {
						if (this.$moreRows) {
							this.$moreRows.detach();
							this.$list.append(this.$moreRows);
						} else {
							this.$moreRows = $('<li class="moreRows"><button>' + user.translate('subcontractors.more') + '</button></li>').appendTo(this.$list).enhanceWithin();
						}
					}
				}

			} else if (!options.noRecursion && this.$moreRows) {
				this.$moreRows.detach();
			}

			if (!options.noRecursion) {
				this.showFirstCount = this.itemCount;
			}

			if (!options.noRefresh) {
				this.$list.listview('refresh');
			}
		},

		hasMoreItemsInList: function () {
			return this.itemCount < this.subcontractorKeys.length;
		},

		renderSubcontractor: function (subcontractor) {
//			var defectIndex = currentDefects.indexOf(subcontractor);

			var pendingCount = 0;
			var totalCount = 0;

			if (this.countInfo[subcontractor.id]) {
				pendingCount = this.countInfo[subcontractor.id].pending;
				totalCount = this.countInfo[subcontractor.id].total;
			}

			var $item = $('<li data-id="' + subcontractor.id + '"></li>').appendTo(this.$list);
			$item = $('<div class="ui-grid-a"></div>').appendTo($item);

			var newDefects = subcontractor.get('newDefects');
			$item.append(
				'<button class="statusAll ui-btn ui-block-a' + (!totalCount ? ' noDefects' : '') + '">' +
					'<span class="ui-btn-icon-notext ui-icon-arrow-r"></span>' +
					'<span class="count">' +
						'<span class="pending">' + pendingCount + '</span>' +
						'<span class="slash">/</span>' +
						'<span class="total">' + totalCount + '</span>' +
						'<span class="newDefects' + (newDefects == 0 ? ' zarro' : '') + '">' + newDefects + '</span>' +
					'</span>' +
				'</button>'
			);

			$item.append($('<a class="ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r"></a>')
				.on('vclick', function () {
					$.mobile.loading('show');
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/subcontractorBundle/'+subcontractor.id);
				}.bind(this))
				.text(subcontractor.getLabel()));
//			$item.enhanceWithin();
		},
		
		isActionsMenuOpen: function () {
			return $('div.ui-popup-container.ui-popup-active').length > 0;
		},

		triggerActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.$actionsMenu.popup('open', { transition: 'flip', positionTo: $('.actionstrigger') });
		},
		
		closeActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.$actionsMenu.popup('close', { transition: 'flip', positionTo: $('.actionstrigger') });
		},

		alphabetical: function (a, b) {
			if (a.crew == b.crew) {
				if (a.name > b.name || !b.name) return 1;
				if (a.name < b.name || !a.name) return -1;
				return 0;
			}
			if (a.crew > b.crew || !b.crew) return 1;
			if (a.crew < b.crew || !a.crew) return -1;
			return 0;
		},

		total: function (a, b) {
			var totalA = this.countInfo[a.id] ? this.countInfo[a.id].total : 0;
			var totalB = this.countInfo[b.id] ? this.countInfo[b.id].total : 0;
			if (totalA < totalB) return 1;
			if (totalA > totalB) return -1;
			return this.alphabetical(a, b);
		},

		pending: function (a, b) {
			var pendingA = this.countInfo[a.id] ? this.countInfo[a.id].pending : 0;
			var pendingB = this.countInfo[b.id] ? this.countInfo[b.id].pending : 0;
			if (pendingA < pendingB) return 1;
			if (pendingA > pendingB) return -1;
			return this.alphabetical(a, b);
		}

	});
});