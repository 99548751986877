define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './FilesPageHtml', '../lib/numeral-1.5.3',
	'../model/user', '../model/currentProject', '../model/currentDefects', '../model/projectFiles', '../model/projectFileSources', '../model/attachmentSources',
	'../helper/watchdog', '../helper/iosapp', '../helper/backboneReactCommunicationUtils'
], function (
	$, _, Backbone, Template, numeral,
	user, currentProject, currentDefects, projectFiles, projectFileSources, attachmentSources,
	watchdog, iosapp, backboneReactCommunicationUtils
) {
	return Backbone.View.extend({

		id: 'files',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		events: {
			'vclick .previousPage':        'previousPage',
			'vclick .parent':              'toParent',
			'vclick .actionstrigger':      'triggerActionsMenu',
			'vclick .manageFiles':         'triggerManageMode',
			'change .manage .fileupload':  'upload',
			'change  select': 'change',
			'vclick .manage button':       'createFolder',


			'vclick .files .ui-checkbox': function (e) {
				var $input = $(e.currentTarget).children('input.selectItem');
				var state = $input.prop("checked");
				if (!$(e.target).is('input')) {
					state = !state;
					$input.prop("checked", state).focus();
				}

				var name = $input.context.getAttribute('name');
				var project = $input.context.getAttribute('project');
				var path = $input.context.getAttribute('path');

				if (state) {
					this.checkedIds.push([project, path, name]);
				} else {
					this.checkedIds.splice(this.findIndexOf([project, path, name]),1) ;
				}
			},

            //FIXME: refactor this code for the following 3 functions (delete, showAN, hideAN) to use a common code base
			//TODO: the code would be better if it would run the calls in sequence and not all in parallel
			'vclick .actionsmenu .deleteFiles': function() {
				var names = "\n\n ";
				if (this.checkedIds.length == 0) {
					alertPopup(user.translate('files.multiple.noselection'));
					return;
				}
				for (var i = 0; i < this.checkedIds.length; i++) {
					names += (i+1) + ". " + this.checkedIds[i][2] + " \n ";
				}
				confirmPopup(user.translate('files.multiple.remove.confirm').replace('%s', names)).then(_.bind(function () {
					$.mobile.loading('show');
                    var changes = false;
                    var ajaxCalls = [];
					for (var i = 0; i < this.checkedIds.length; i++) {
						var currentProject = this.checkedIds[i][0];
						var path = this.checkedIds[i][1];
						var name = this.checkedIds[i][2];
						var ajax = $.post( '/onlineBauabnahme/api/removefile', {
							project: currentProject,
							path: path,
							name: name
						}).done(_.bind(function (result) {
                            if (result) {
                                changes = true;
                            }
						}, this)).fail(function (jqXHR, textStatus, errorThrown) {
							alertPopup(user.translate('files.remove.failed').replace('%s', errorThrown));
						}).always(function () {
							$.mobile.loading('hide');
						});
                        ajaxCalls.push(ajax);
					}

					$.when.apply(this, ajaxCalls).always(function() {
						this.reloadAfterChange();
					}.bind(this));
				}, this));
			},


			'vclick .actionsmenu .hideFilesForSubcontractor,.actionsmenu .hideFilesForPrincipal': function(e) {
				var names = "\n\n ";
				var sub_bool = null;
				var princ_bool = null;

				if (_.contains(e.target.className,'hideFilesForSubcontractor')) {
					sub_bool = false;
				} else {
					princ_bool = false;
				}

				if (this.checkedIds.length == 0) {
					alertPopup(user.translate('files.multiple.noselection'));
					return;
				}
				for (var i = 0; i < this.checkedIds.length; i++) {
					names += (i+1) + ". " + this.checkedIds[i][2] + " \n ";

				}
				var popupBodyText = '';
				if (sub_bool === false && princ_bool === false) {
					popupBodyText = user.translate('files.multiple.hide.confirm.anag');
				}else if(sub_bool === false){
					popupBodyText = user.translate('files.multiple.hide.confirm.an');
				}else if(princ_bool === false){
					popupBodyText = user.translate('files.multiple.hide.confirm.ag');
				}

				confirmPopup(popupBodyText.replace('%s', names)).then(_.bind(function () {
					$.mobile.loading('show');
					var changes = false;
					var ajaxCalls = [];
						for (var i = 0; i < this.checkedIds.length; i++) {
							var currentProject = this.checkedIds[i][0];
							var path = this.checkedIds[i][1];
							var name = this.checkedIds[i][2];
							var ajax = $.post( '/onlineBauabnahme/api/setFileVisibility', {
								project: currentProject,
								path: path,
								name: name,
								visibleToSub: sub_bool,
								visibleToPrinc: princ_bool
							}).done(_.bind(function (result) {
								if (result) {
								changes = true;
								}
							}, this)).fail(function (jqXHR, textStatus, errorThrown) {
								alertPopup(user.translate('files.remove.failed').replace('%s', errorThrown));
							}).always(function () {
								$.mobile.loading('hide');
							});
							ajaxCalls.push(ajax);
						}

					$.when.apply(this, ajaxCalls).always(function() {
						this.reloadAfterChange();
					}.bind(this));
				}, this));
			},

			'vclick .actionsmenu .showFilesForSubcontractor,.actionsmenu .showFilesForPrincipal': function(e){
				var sub_bool = null;
				var princ_bool = null;

				if (_.contains(e.target.className,'showFilesForSubcontractor')) {
					sub_bool = true;
				} else {
					princ_bool = true;
				}

				var names = "\n\n ";
				if (this.checkedIds.length === 0) {
					alertPopup(user.translate('files.multiple.noselection'));
					return;
				}
				for (var i = 0; i < this.checkedIds.length; i++) {
					names += (i+1) + ". " + this.checkedIds[i][2] + " \n ";

				}

				var popupBodyText = '';
				if (sub_bool && princ_bool) {
					popupBodyText = user.translate('files.multiple.show.confirm.anag');
				}else if(sub_bool){
					popupBodyText = user.translate('files.multiple.show.confirm.an');
				}else if(princ_bool){
					popupBodyText = user.translate('files.multiple.show.confirm.ag');
				}

				confirmPopup(popupBodyText.replace('%s', names)).then(_.bind(function () {
					$.mobile.loading('show');
					var changes = false;
					var ajaxCalls = [];
					for (var i = 0; i < this.checkedIds.length; i++) {
						var currentProject = this.checkedIds[i][0];
						var path = this.checkedIds[i][1];
						var name = this.checkedIds[i][2];
						var ajax = $.post( '/onlineBauabnahme/api/setFileVisibility', {
							project: currentProject,
							path: path,
							name: name,
							visibleToSub: sub_bool,
							visibleToPrinc: princ_bool
						}).done(_.bind(function (result) {
							if (result) {
								changes = true;
							}
						}, this)).fail(function (jqXHR, textStatus, errorThrown) {
							alertPopup(user.translate('files.remove.failed').replace('%s', errorThrown)); //TODO: is wrong but doesnt matter
						}).always(function () {
							$.mobile.loading('hide');
						});
						ajaxCalls.push(ajax);
					}

					$.when.apply(this, ajaxCalls).always(function() {
						this.reloadAfterChange();
					}.bind(this));
				}, this));
			},
			
			'vclick .addAsImage': 'saveFilesAsImage'
		},
		
		saveFilesAsImage: function (e) {
			this.closeActionsMenu(e);
			if(this.checkedIds.length > 0) {
				var fileNames = [];
				var incompatibleFiles = [];
				_.forEach(this.checkedIds, function (id) {
					if(id[2].endsWith('.png') || id[2].endsWith('.jpg') || id[2].endsWith('.jpeg')) {
						fileNames.push(id[2]);
					} else {
						incompatibleFiles.push((id[2]));
					}
				});
				// fileNames = fileNames.join();
				this.saveFileAsImage(fileNames).done(function (r) {
					if(r === 'Ok') {
						var text = user.translate('filespage.imageToDefect.success') + '\n\n' + (incompatibleFiles.length > 0 ? user.translate('filespage.imageToDefect.success.filesFailed') + '\n\n' : '');
						if(incompatibleFiles.length > 0) text += incompatibleFiles.join();
						window.alertPopup(text);
					} else {
						window.alertPopup(user.translate('filespage.imageToDefect.fail'));
					}
				});
			}
		},
		
		saveFileAsImage: function (fileNames) {
			var def = $.Deferred();
			var string = '';
			if(Array.isArray(fileNames)) {
				_.each(fileNames, function (name) {
					if(string.length === 0) {
						string += '?fileNames=' + name;
					} else {
						string += '&fileNames=' + name;
					}
				})
			} else {
				string = '?fileName=' + fileNames;
			}
			$.post( '/onlineBauabnahme/api/saveFile' + (Array.isArray(fileNames) ? 's' : '') + 'InDefectImages' + string, {
				project: currentProject.id,
				defectId: this.router.defectPage.model.get('id')
			}).done(function (r) {
				if(r === 'Ok') this.imagesAddedToDefect = true;
				return def.resolve(r);
			}.bind(this))
			return def;
		},

		initialize: function (options) {
			this.router = options.router;

			this.model = projectFiles;
			this.recent = [];

			this.listenTo(watchdog, 'change:connected', _.bind(function () {
				if (!this.$el.is(':visible')) {
					return;
				}
				this.render();
			}, this));

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.actionsmenu        = this.$('.actionsmenu');
			this.$location          = this.$('.location');
			this.$parent            = this.$('.parent');
			this.$files             = this.$('.files');
			this.$recentFiles       = this.$('.recent-files');
			this.$rocketModeFiles 	= this.$('.rocketMode-files');
			this.$mostCommonFiles   = this.$('.mostcommon-files');
			this.$path              = this.$('.path');
			this.$manageFilesButton = this.actionsmenu.find('.manageFiles');
			this.$defaultUploadSettings  = this.$('#files-defaultUploadSettings');
			this.checkedIds = [];
			this.rocketModeFiles = [];
			this.mostCommon = [];

			if (window.oxmobile || iosapp.appavailable) {
				$('.actionstrigger').remove();
			}

			this.listenTo(currentProject, 'change:id', function () {
				this.recent = [];
				this.rocketModeFiles = [];
			});



			this.listenTo(watchdog, 'change:connected', _.bind(function () {
				if (!this.$el.is(':visible')) {
					return;
				}
				this.render();
			}, this));

		},

		render: function (alreadyInManageMode) {
			$('.previousPage').off('click');
			$('.previousPage').on('click', function (e) {
				this.previousPageFunction(e);
			}.bind(this));
			$('.actionstrigger').off('click');
			$('.actionstrigger').on('click', function (e) {
				this.triggerActionsMenu(e);
			}.bind(this));
			var settings = user.get('settings');
			if(alreadyInManageMode != undefined) {
				this.manage = alreadyInManageMode;
			}
			if (this.model.get('readonly')) {
				this.manage = false;
			}
			$('.previousPage').toggleClass('ui-disabled', !(watchdog.isConnected() || this.previousPage == 'defect' || this.previousPage == 'instant'));

			this.$('h1').text(
				!!this.defectMode ? user.translate('files.defectmode.title') : user.translate('files.title')
			);
			this.$('.foldercontrol').toggle(!this.defectMode);

			var previousLabel;
			switch (this.previousPage) {
				case 'defect':  previousLabel = user.translate('files.backtodefect');  break;
				case 'instant': previousLabel = user.translate('files.backtoinstant'); break;
				default:        previousLabel = user.translate('files.backtoproject'); break;
			}
			$('.previousPage').text(previousLabel);

			this.$path.val(this.model.getPath());
			this.$location.toggle(!!this.model.parent);

			var children = this.model.get('children');

			if ((this.previousPage == 'defect' || this.previousPage == 'instant') && !this.defectMode && !user.isSubcontractor()) {
				this.$rocketModeFiles.empty();
				if (this.rocketModeFiles && this.rocketModeFiles.length > 0){
					_.each(this.rocketModeFiles, function (item) {
						if (user.isPrincipal()){
							if (item.get('visibleToPrinc')) {
								this.createItem(item, alreadyInManageMode).appendTo(this.$rocketModeFiles);
							}
						} else {
							this.createItem(item, alreadyInManageMode).appendTo(this.$rocketModeFiles);
						}
					}, this);
					this.$('.rocketModeFiles').toggle(true);
				} else {
					this.$('.rocketModeFiles').toggle(false);
				}
				this.$rocketModeFiles.listview('refresh');
			}else{
				this.$('.rocketModeFiles').toggle(false);
			}
			this.rocketModeFiles = []; // reset rocket Mode

			this.$('.recent').toggle(this.previousPage == 'defect' && this.recent.length > 0 && !this.defectMode);
			if (this.previousPage == 'defect') {
				this.$recentFiles.empty();
				_.each(this.recent, function (item) {
					if (user.isSubcontractor()){
						if (item.get('visibleToSub')) {
							this.createItem(item, alreadyInManageMode).appendTo(this.$recentFiles);
						}
					} else {
						this.createItem(item, alreadyInManageMode).appendTo(this.$recentFiles);
					}
				}, this);
				this.$recentFiles.listview('refresh');
			}

			this.$mostCommonFiles.empty();

			this.$('.mostcommon').toggle(this.mostCommon.length > 0);
			if (this.previousPage == 'defect' || true) {

				this.mostCommon = _.sortBy(this.mostCommon, 'score');

				_.each(this.mostCommon, function (obj) {
					var item = obj.item;
					if (user.isSubcontractor()){
						if (item.get('visibleToSub')) {
							this.createItem(item, alreadyInManageMode).appendTo(this.$mostCommonFiles);
						}
					} else {
						this.createItem(item, alreadyInManageMode).appendTo(this.$mostCommonFiles);
					}
				}, this);
				this.$mostCommonFiles.listview('refresh');
			}

			this.$files.empty();
			this.$files.toggleClass('checkboxList', !this.router.imagePage.selector);
			children.forEach(function (item) {
				if (item.get('name').indexOf('_') !== 0) {
					if (user.isSubcontractor()){
						if (item.get('visibleToSub')) {
							this.createItem(item, alreadyInManageMode).appendTo(this.$files);
						}
					} else {
						this.createItem(item, alreadyInManageMode).appendTo(this.$files);
					}
				}
			}, this);
			if (children.size() == 0) {
				$('<li class="none">').text(user.translate('files.none')).appendTo(this.$files);
			}
			if (this.defectMode) {
				var fileDefectIds = currentProject.get('fileDefectIds').slice(),
					defectId = this.router.defectPage.model.id;
				if (children.size() > 0) {
					fileDefectIds.push(defectId);
					currentProject.set('fileDefectIds', fileDefectIds);
				} else {
					currentProject.set('fileDefectIds', _.without(fileDefectIds, defectId));
				}
			}
			this.$files.listview('refresh');

			var connected = watchdog.isConnected(),
				canManage = connected && !user.isSubcontractor() && !user.isPrincipal() && !user.isReadonly() && !this.model.get('readonly'); //&& !window.oxmobile

			this.$('.manage').toggle(connected && !!this.manage);
			$('.actionstrigger').toggle(canManage || this.defectMode && !user.isReadonly());
			if(!canManage) {
				this.$('.deleteFiles').toggle(false);
			}

			var showSubcontractorMenuEntries = !!(this.defectMode || this.manage && canManage);
			var showManageFilesButton = !this.defectMode && connected;
			this.$('#defectsMenuSelectionDivider').toggle(showManageFilesButton && showSubcontractorMenuEntries);
			this.$('.hideFilesForSubcontractorContainer').toggle(showSubcontractorMenuEntries);
			this.$('.showFilesForSubcontractorContainer').toggle(showSubcontractorMenuEntries);
			this.$('#defectsMenuSettingsDivider').toggle(showManageFilesButton && showSubcontractorMenuEntries);
			this.$manageFilesButton.toggle(showManageFilesButton);
			this.$manageFilesButton.text(!this.manage ? user.translate('files.manage') : user.translate('files.manage.finish'));

			var showFileUploadSettings = !(user.isPrincipal() || user.isSubcontractor());
			this.$('.fileUploadSettingsMenu').toggle(showFileUploadSettings);
			this.$defaultUploadSettings.val(settings.defaultUploadSettings || 'AGAN').selectmenu('refresh');

			if (!connected) {
				if (iosapp.appavailable && iosapp.functionavailable('syncplans')) {
					$('.offlineWarning').show();
				} else {
					if (!this.model.get('offline')) {
						this.switchFolder(this.model.parent);
					} else {
						$('.offlineWarning').show();
					}
				}
			} else {
				$('.offlineWarning').hide();
			}

			if (user.get('customer') !== 'westermann') {
				$('.fileuploadcairocontainer').hide();
			}

			if (this.manage && canManage) {
				setTimeout(function () {
					$('#files .openFile:not(.isRequest)').css('width', 'calc(100% - 165px)');
					if (alreadyInManageMode) {
						$('#files .selectItem').addClass("adjusted");
					} else {
						$('#files .selectItem').css("left", "50%");
					}

					$('#files .ui-checkbox').css({"width": "80px", "padding": "16px"});
				}.bind(this), 100);
			}

			if (this.manage) {
				this.actionsmenu.addClass("managemode")

			} else {
				this.actionsmenu.removeClass("managemode")
				$('#files .files .openFile').removeClass("adjustedWidth");
			}
			this.checkedIds = [];
			this.mostCommon = [];
			
			this.$('.addAsImageContainer').toggle(this.defectMode);
			
			this.imagesAddedToDefect = false;
		},

		previousPageFunction: function (e) {
			e.preventDefault();
			e.stopPropagation();
			var selectedIndex;
			if (this.previousPage === 'defect') {
				var def = $.Deferred();
				if(this.imagesAddedToDefect) {
					this.router.defectPage.model.fetch().done(function () {
						def.resolve();
					});
				} else {
					def.resolve();
				}
				def.done(function () {
					selectedIndex = this.router.defectPage.sketcher.getSelectedIndex();
					window.showPageCallback = function () {
						this.router.show(this.router.defectPage, true);
						this.router.defectPage.sketcher.selectAttachment(selectedIndex);
					}.bind(this);
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defect/'+this.router.defectPage.previousDefectPrefixId, {noUseEffect:true,callback:true});
				}.bind(this));
			} else if (this.previousPage === 'instant') {
				selectedIndex = this.router.instantMessagePage.sketcher.getSelectedIndex();
				window.showPageCallback = function () {
					this.router.show(this.router.instantMessagePage, true);
					this.router.instantMessagePage.sketcher.selectAttachment(selectedIndex);
				}.bind(this);
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/instantMessage', {noUseEffect: true, callback:true});
			} else if (this.previousPage === 'buyeraccess') {
				window.showPageCallback = function () {
					this.router.show(this.router.buyerAccessPage, true);
				}.bind(this);
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject()+'/buyerAccess', {noUseEffect: true, callback:true});
			} else {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject());
			}
		},

		switchFolder: function (model) {
			$.mobile.loading('show');
			return model.load().done(_.bind(function () {
				this.model = model;
				this.render(this.manage);
			}, this)).always(function () {
				$.mobile.loading('hide');
			}).done(_.bind(function () {
				var iossyncplans = (iosapp.appavailable && iosapp.functionavailable('syncplans'));
				if (!watchdog.isConnected() && !this.model.get('offline') && !iossyncplans) {
					this.switchFolder(this.model.parent);
				}
			}, this));
		},

		openImage: function (model) {
			if (!_.some(this.recent, function (item) { return item.get('path') === model.get('path'); })) {
				this.recent.unshift(model);
				this.recent = _.first(this.recent, 3);
			}

			// reset Image Page to PLAN mode
			this.router.imagePage.mapMode = 'PLAN';
			// showonline: Executed if connected to web or if webview in app is able to render tiles
			var showonline = _.bind(function (offlineAvailable) {

					model.load()
						.done(function () {
							this.router.imagePage.setModel(model);
							this.router.imagePage.enableMarkers();
							this.router.imagePage.offlineAvailable = !!offlineAvailable;
							window.showPageCallback = function () {
								if (!!this.router.imagePage.selector) {
									this.router.imagePage.removeMarkers();
									this.router.imagePage.enableMarkerPlacement(true);
								} else {
									this.router.imagePage.disableMarkerPlacement();
									this.router.imagePage.addAttachmentSourceMarkers();
								}
							}.bind(this);
							window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/image', {callback:true});
						}.bind(this));

			}, this);
			// showoffline: Executed if plans are rendered natively (should not be used anymore and probably is not used anymore)
			var showoffline = _.bind(function() {
				if (this.router.imagePage.isSelectionModeSet()) {
					this.router.imagePage.setModel(model); // for filename post processing
					iosapp.showplan(currentProject.id, model.get('path'), model.get('fsfilesize'), model.get('fsmodified'), _.bind(this.router.imagePage.setImageData, this.router.imagePage));
				} else {
					iosapp.showplan(currentProject.id, model.get('path'), model.get('fsfilesize'), model.get('fsmodified'), null);
				}
			}, this);

			if (iosapp.appavailable && iosapp.functionavailable('syncplans')) {
				iosapp.plansuptodate(currentProject.id, [{path: model.get('path'), filesize: model.get('fsfilesize'), fsmodified: model.get('fsmodified')}], _.bind(function(arg) {
					if (arg.insync === true) {
						if(iosapp.functionavailable("getTile")) {
							showonline(arg.insync === true);
						} else {
							showoffline();
						}
					} else if (!watchdog.isConnected()) {
						if(iosapp.functionavailable("getTile")) {
							showonline(arg.insync === true);
						} else {
							showoffline();
						}
					} else {
						showonline(arg.insync === true);
					}
				}, this));
			} else {
				showonline(false);
			}
		},

		openImageInRocketMode: function(model, location){
			if (!_.some(this.recent, function (item) { return model ? item.get('path') === model.get('path') : false; })) {
				this.recent.unshift(model);
				this.recent = _.first(this.recent, 3);
			}
			var show = function (offlineAvailable) {
				model.load()
					.done(function () {
						this.router.imagePage.defectGoToPlanRocketMode = true;
						this.router.imagePage.mapMode = 'PLAN';
						this.router.imagePage.setModel(model);
						this.router.imagePage.setSelectionMode(true);
						this.router.imagePage.offlineAvailable = !!offlineAvailable;
						this.router.imagePage.removePreviousPoint();
						window.showPageCallback = function () {
							this.router.imagePage.removeMarkers();
							this.router.imagePage.enableMarkerPlacement(true);
							if (location) {
								setTimeout(function () {
									this.router.imagePage.fitViewAroundLocation(location);
								}.bind(this), 500);
							}
						}.bind(this);
						window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/image', {callback:true});
					}.bind(this));
			}.bind(this);
			if (iosapp.appavailable && iosapp.functionavailable('syncplans') && iosapp.functionavailable("getTile")) {
				iosapp.plansuptodate(currentProject.id, [{path: model.get('path'), filesize: model.get('fsfilesize'), fsmodified: model.get('fsmodified')}], _.bind(function(arg) {
					show(arg.insync === true)
				}, this));
			} else {
				show(false);
			}

		},

		canOpen: function (item) {
			var name = item.get('name'); idx = name.lastIndexOf('.'), ext = (idx !== -1) ? name.substr(idx).toLowerCase() : '';
			if (ext === '.ifc' || ext === '.digibau') {
				return false
			}
			//return !!item.get('hash') || _.contains([ '.pdf', '.eps', '.svg', '.png', '.jpg', '.jpeg', '.tif', '.tiff', '.gif', '.bmp', '.psd', '.ai' ], ext);
			//2019-04-08: remove tif/tiff: android is not supporting it
			return !!item.get('hash') || _.contains([ '.pdf', '.png', '.jpg', '.jpeg', '.gif', '.bmp' ], ext); //also consider the ios app, use only formats which are supported by both renderers
		},

		createItem: function (item, alreadyInManageMode) {
			var isFolder = item.get('type') == 'Folder';
			var name = item.get('name');
			var pmgData = item.get('pmgData');

			var li = $('<li>').data('hash', item.get('hash'));
			var caption = isFolder ? '[ ' + name + ' ]' : name;
			if (pmgData) {
				var pmgParts = [];
				if (pmgData.beschreibung) {
					pmgParts.push(pmgData.beschreibung);
				}
				if (pmgData.index) {
					pmgParts.push('Index: ' + pmgData.index);
				}
				if (pmgParts.length) {
					caption += ' (' + pmgParts.join(', ') + ')';
				}
			}

			var connected = watchdog.isConnected();
			var iossyncplans = (iosapp.appavailable && iosapp.functionavailable('syncplans'));
			if (connected || item.get('offline') || iossyncplans) {

				var $item = li;

				if (!this.router.imagePage.selector) {

					$item = $('<div class="ui-grid-a ui-corner-all"></div>').appendTo(li);

					var $checkbox = $('<div class="ui-checkbox"><input type="checkbox" class="selectItem ui-btn ui-block-a"></div>')
						.attr({project: currentProject.id,
							name: item.get('name'),
							path:item.parent.get('path')});

					if (user.isAdmin() && alreadyInManageMode && this.manage) {
						$checkbox.width("80px");
					} else if (!this.manage || !user.isAdmin()) {
						$checkbox.width("0%");
					}

					if(!item.get('isRequest')) $checkbox.appendTo($item);

					var show_mobile_view = (window.innerWidth < 700);

					if(connected){
						if(!show_mobile_view){
							if(isFolder){
								$('<div class="iconItem ui-btn ui-block-a" style="height: 38px">')
									.appendTo($item)
								$el = $('<button class="openFile ui-btn ui-btn-a ui-block-b">');
							}else {
								$('<div class="iconItem ui-btn ui-block-a" style="height: 38px; background: url(' +  '/onlineBauabnahme/images/fileicon.png) no-repeat center bottom">')
									.appendTo($item)
									.click(_.bind(function () {
										var link =  '/onlineBauabnahme/api/downloadFile?project=' + window.encodeURIComponent(currentProject.id) + '&path=' + window.encodeURIComponent(item.get('path'));
										if (iosapp.appavailable) {
											var path = item.get('path');
											var slashIdx = path.lastIndexOf('/');
											if (slashIdx >= 0) path = path.substr(slashIdx + 1);
											iosapp.downloadAndSharePdf(window.location.origin + link, path);
										} else {
											window.open(link);
										}
									}, this));
								$el = $('<button class="openFile ui-btn ui-btn-a ui-block-b">');
							}
						}else{
							$el = $('<button class="openFileMobile ui-btn ui-btn-a ui-block-b">');
						}
					}else{
						if(!show_mobile_view){
							$el = $('<button class="openFile ui-btn ui-btn-a ui-block-b">');
						}else{
							$el = $('<button class="openFileMobile ui-btn ui-btn-a ui-block-b">');
						}
					}

				} else {
					$el = $('<a class="openFile ui-btn">');
				}
				if (isFolder || this.canOpen(item)) {
					$el.addClass('ui-btn-icon-right ui-icon-carat-r');
				} else {
					$el.addClass('ui-disabled');
				}

				if (!show_mobile_view){
					if(item.get('isRequest')) {
						$el.css('width', 'calc(100% - 80px)');
					} else {
						$el.css('width', 'calc(100% - 165px)');
					}
				}else{
					if((alreadyInManageMode || this.manage) && !item.get('isRequest')){
						$el.css('width', 'calc(100% - 80px)');
					}else{
						$el.css('width', '100%');
					}
				}



				$el
					.appendTo($item)
					.text(caption)
					.on('vclick', _.bind(function (e){
						if(e.target.outerHTML !== '<div class="ui-btn ui-btn-icon-right ui-icon-eye"></div>') {
							e.preventDefault();
							e.stopPropagation();
							if(isFolder) {
								this.switchFolder(item);
							} else if(this.canOpen(item)) {
								this.openImage(item);
							}
						}
					}, this));
				if($el[0].nodeName === 'BUTTON') {
					var tEl = $('<a>').text($el.text());
					$el.text('');
					$el.prepend(tEl);
				}
				
				if(item.get('isRequest')) $el.addClass('isRequest');

				if(!(user.isSubcontractor() || user.isPrincipal())) {
					//$el.append($('<div class="ui-btn  ui-icon-eye"></div>'));

					if(item.get('visibleToSub') && item.get('visibleToPrinc')){
						$VisEl = $('<div class="ui-btn ui-vis-text-top">'+user.translate('files.visibility.princ')+'</div> ' +
							'<div class="ui-btn ui-vis-text-bottom">'+user.translate('files.visibility.sub')+'</div>');
					}else if(item.get('visibleToSub')){
						$VisEl = $('<div class="ui-btn ui-vis-text-bottom">'+user.translate('files.visibility.sub')+'</div>');
					}else if(item.get('visibleToPrinc')){
						$VisEl = $('<div class="ui-btn ui-vis-text-top">'+user.translate('files.visibility.princ')+'</div>');
					}else{
						$VisEl = $('<div class="ui-btn ui-vis-no-text"></div>');
					}

					//                    $popUp = $('<div id="popupInfoBox" class="popup" style="display: none; position: absolute; border: 1px solid; background-color: wheat; width:100px">This is a placeholder help text that is meant to be long.</div>');
					$popUp = $('#popupInfoBox');
					$popUp.css({display: 'none', position: 'absolute', border: '1px solid', backgroundColor: 'wheat', width: '100px', borderRadius: '5px', padding: '5px'});

					$VisEl.click(function (e){
						e.stopPropagation()
						e.preventDefault()
						if($popUp.css('display') === 'none') {
							$popUp.css({left: e.pageX - parseInt($popUp.css('width')) - (2 * parseInt($popUp.css('paddingLeft')))});
							$popUp.css({top: e.pageY});
							$popUp.html(user.translate('files.popupinfobox'));
							$popUp.show();
						} else {
							$popUp.hide();
						}
					});

					$popUp.mouseleave(function (e){
						$popUp.hide();
					});

					// not sure if this is also needed
					$popUp.click(function (e){
						$popUp.hide();
					});


					$el.append($VisEl);
					
					if(this.defectMode && window.outerWidth > 500 && (item.get('name').endsWith('.png') || item.get('name').endsWith('.jpg') || item.get('name').endsWith('.jpeg'))) {
						var $btn = $('<button>').text(user.translate('filespage.imageToDefect.image')).css({
							position: 'absolute',
							right: '90px',
							top: '9px',
							cursor: 'pointer'
						});
						$btn.on('vclick', function (e) {
							e.preventDefault(); e.stopPropagation();
							this.saveFileAsImage(item.get('name')).done(function (r) {
								if(r === 'Ok') {
									window.alertPopup(user.translate('filespage.imageToDefect.success'));
								} else {
									window.alertPopup(user.translate('filespage.imageToDefect.fail'));
								}
							});
						}.bind(this))
						$el.append($btn)
					}
				}

			} else {
				li.addClass('offline').text(caption);
			}

			var source = projectFileSources.get(item.get('hash'));
			if (source) {
				li.append($('<span class="file-source-' + source.get('type').toLowerCase() + '">'));
			}

			return li;
		},

		findIndexOf: function(element) {
			var index = -1;
			for (var i = 0; i < this.checkedIds.length; i++){
				if (this.checkedIds[i][0] == element[0] && this.checkedIds[i][1] == element[1] && this.checkedIds[i][2] == element[2]){
					index = i;
				}
			}
			return index;
		},

		toParent: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.switchFolder(this.model.parent);
		},
		
		isActionsMenuOpen: function () {
			return $('div.ui-popup-container.ui-popup-active').length > 0;
		},

		triggerActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.actionsmenu.popup('open', { transition: 'flip', positionTo: $('.actionstrigger') });
		},
		
		closeActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.actionsmenu.popup('close', { transition: 'flip', positionTo: $('.actionstrigger') });
		},

		triggerManageMode: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.manage = !this.manage;
			if (this.manage) {
				this.actionsmenu.addClass("managemode")
				$('#files .files .openFile').css('width', '100%').css('width', '-=165px');
			} else {
				this.actionsmenu.removeClass("managemode")
				$('#files .files .openFile').removeClass("adjustedWidth");
			}

			this.actionsmenu.popup('close');
			this.render();
		},



		upload: function () {
			$.mobile.loading('show');
			var form = this.$('.manage form')[0], file = this.$('.manage form input')[0].files[0];
			if(file.name.trim().startsWith("_")) {
				alertPopup(user.translate('files.upload.invalidFilenamePrefix'));
				$.mobile.loading('hide');
				form.reset();
				return;
			}
			if (file.size > 1024 * 1024 * 1024) {//50 * 1024 * 1024) {
				alertPopup(user.translate('files.upload.limit'));
				$.mobile.loading('hide');
				form.reset();
				return;
			}
			var data = new FormData();
			data.append('project', currentProject.id);
			data.append('path', this.model.get('path'));
			data.append('file', file);
			data.append('cairo', $('.fileuploadcairo').is(":checked"));
			Backbone.ajax({
				url:  '/onlineBauabnahme/api/uploadfile',
				type: 'POST',
				data: data,
				contentType: false,
				processData: false,
				xhr: _.bind(function () {
					var xhr = new window.XMLHttpRequest();
					//Upload progress
					xhr.upload.addEventListener("progress", _.bind(function (evt) {
						if (evt.lengthComputable) {
							var percentComplete = evt.loaded / evt.total;
							$.mobile.loading('show', { text: numeral(percentComplete).format('0%'), textVisible: true });
						}
					}, this), false);
					return xhr;
				}, this)
			}).done(_.bind(function (result) {
				if (result) {
					//alert('Die Datei wurde hochgeladen.');
					this.reloadAfterChange();
				} else {
					alertPopup(user.translate('files.upload.failed').replace('%s', user.translate('files.upload.checktype')));
				}
			}, this)).fail(function (jqXHR, textStatus, errorThrown) {
				alertPopup(user.translate('files.upload.failed').replace('%s', errorThrown));
			}).always(function () {
				$.mobile.loading('hide');
				form.reset();
			});
		},

		createFolder: function (e) {
			e.preventDefault();
			e.stopPropagation();
			$.mobile.loading('show');
			var foldername = this.$('.manage #foldername').val();
			$.post( '/onlineBauabnahme/api/createfolder', {
				project: currentProject.id,
				path: this.model.get('path'),
				name: foldername
			}).done(_.bind(function (result) {
				if (result) {
					//alert('Verzeichnis wurde erstellt.');
					this.$('.manage #foldername').val(null);
				}
				this.reloadAfterChange();
			}, this)).fail(function (jqXHR, textStatus, errorThrown) {
				alertPopup(user.translate('files.dir.create.failed').replace('%s', errorThrown));
			}).always(function () {
				$.mobile.loading('hide');
			});
		},

		reloadAfterChange: function () {
			this.model.fetch().done(_.bind(function () {
				this.makeAllParentsVisible();
				this.render(this.manage);

			}, this));
		},
		makeAllParentsVisible: function() {
			var markAsSubVisible = false;
			var markAsPRVisible = false;
			if(this.model.attributes.children.length > 0)
			{
				for(var i=0;i<this.model.attributes.children.length;i++)
				{
					if(this.model.attributes.children.models[i].attributes.visibleToPrinc==true)
					{
						markAsPRVisible = true;
					}
					if(this.model.attributes.children.models[i].attributes.visibleToSub==true)
					{
						markAsSubVisible = true;
					}
					if(markAsPRVisible && markAsSubVisible)
					{
						break;
					}
				}
			}
			else
			{
				markAsSubVisible = this.model.attributes.visibleToSub;
				markAsPRVisible = this.model.attributes.visibleToPrinc;
			}
			var parent = this.model.parent;
			while( parent !== undefined)
			{
				if(markAsSubVisible===true)
				{
					parent.attributes.visibleToSub = true;
				}
				if(markAsPRVisible===true)
				{
					parent.attributes.visibleToPrinc = true;
				}
				parent = parent.parent;
			}
		},

		change: function () {
			var settings = {
				defaultUploadSettings: this.$defaultUploadSettings.val()
			}
			return user.save({ settings: _.merge({}, user.get('settings'), settings) }, { url:  '/onlineBauabnahme/api/settings' })
		}


	});
});
