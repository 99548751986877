define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div class=\"header\" data-role=\"header\" data-position=\"fixed\">\n" +
        "\t<button data-shortcut=\"leftMenu\" class=\"menutrigger ui-btn ui-btn-left ui-btn-inline ui-icon-bars ui-btn-icon-notext ui-corner-all\"></button>\n" +
        "\t<h1 data-role=\"heading\"><%= t('sidepanel.whatsnew') %></h1>\n" +
        "</div>\n" +
        "\n" +
        "<div class=\"content\" data-role=\"content\">\n" +
        "\t<object />\n" +
        "</div>\n" +
        "\n" +
        "<div class=\"footer\" data-role=\"footer\" data-position=\"fixed\"></div>";
    return page;
});