define([
	'../lib/backbone-1.1.2'
], function (
	Backbone
) {
	return Backbone.Model.extend({

		defaults: function () {
			return {
				id: window.generateUUID()
			};
		},

		toBimMarkerJSON: function (defect) {
			var defectProperties = {};
			if (defect) {
				var value;
				if (value = defect.get('description')) {
					defectProperties.description = value;
				}
				if (value = defect.get('status')) {
					defectProperties.status = value;
				}
				if (value = defect.get('type')) {
					defectProperties.type = value;
				}
				if (value = defect.get('projectId')) {
					defectProperties.defectID = value;
				}
			}
			return $.extend({
				position: this.get('location'),
				defectID: this.get('defect')
			}, this.get('properties'), defectProperties);
		},

		toDigiBauDemoMarkerJSON: function (defect) {
            var defectProperties = {};
            if (defect) {
                var value;
                if (value = defect.get('description')) {
                    defectProperties.description = value;
                }
                if (value = defect.get('status')) {
                    defectProperties.status = value;
                }
                if (value = defect.get('type')) {
                    defectProperties.type = value;
                }
                if (value = defect.get('projectId')) {
                    defectProperties.defectID = value;
                }
            }
            return $.extend({
                position: this.get('location'),
                defectID: this.get('defect')
            }, this.get('properties'), defectProperties);
        }

	});
});
