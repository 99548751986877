define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../model/user'
], function (
	$, _, Backbone, user
) {

	var hoverBox = "\
					<div class='hoverBox'>\n\
						<span class='hoverBoxText'></span>\n\
					</div>\
					";
	
	var imagePagePopups = {
		
		initialize: function () {

		},
		
		addHover: function (thisEl, text) {
			var timer;
			$(thisEl).hover(function (e) {

				timer = setTimeout(function () {
					$('body').append(hoverBox);
					$('.hoverBoxText').html(text);
					imagePagePopups.positionHover(e);
				}.bind(this), 1000);

			}, function () {
				clearTimeout(timer);
				$('body').find('.hoverBox').remove();
			});
		},

		positionHover: function (e) {
			var width = $('body').find('.hoverBox').width() + parseInt($('body').find('.hoverBox').css('padding-left')) + parseInt($('body').find('.hoverBox').css('padding-right'));
			var height = $('body').find('.hoverBox').height() + parseInt($('body').find('.hoverBox').css('padding-top')) + parseInt($('body').find('.hoverBox').css('padding-bottom'));
			$('body').find('.hoverBox').css({
				left: e.clientX >= ($(window).innerWidth() - (width + 5)) ? e.pageX - width - 5 : e.pageX + 5,
				top: e.clientY >= ($(window).innerHeight() - (height + 5)) ? e.pageY - height - 5 : e.pageY + 5
			});
		},
		
		getHoverBox: function () {
			return hoverBox;
		}
	};
	return imagePagePopups;
});