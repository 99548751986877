import {CenterContainer, FullPage} from "../components/styledComponents";
import logo from "../images/oa.png";
import experienceId from "../images/experienceID.png";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {SimpleChoiceDialog, StyledButton} from "@bau/material";
import {styled} from '@mui/material/styles';
import "./style.css";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {APP_FUNCTION_NAME, callFunctionInApp, isWebApp} from "../utils/appCommunicationUtils";
import {awaitWrap, getCustomerIdFromCustomerProject, getProjectIdFromCustomerProject} from "../utils/utils";
import {login} from "../service/apis";
import {DialogInfo} from "../components/PageContainer";
import {localStorageItem} from "../utils/constants";
import {CONTEXT_PATH} from "../index";


const CssTextField = styled(TextField)({

    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: 'lightgray',
        },
        '& fieldset': {
            borderRadius: '1em',
            border: '1px solid darkgray'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#ee7f00',
        },
    },
});

const Login = () => {

    const navigate = useNavigate();

    const locationState = useLocation().state;
    const [dialog, setDialog] = useState<DialogInfo|null>(null);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [keyDownHandler, setKeyDownHandler] = useState<any>(null);

    useEffect(()=>()=>document.removeEventListener('keydown', keyDownHandler), [])

    useEffect(()=>{
        document.removeEventListener('keydown', keyDownHandler);
        const handler = (e:any)=>{
            if(!!e && e.keyCode === 13) {
                doLogin(username, password);
            }
        }
        setKeyDownHandler(()=>handler)
        document.addEventListener('keydown', handler);
    }, [username, password])

    useEffect(()=>{
        if(!!locationState && locationState.fromLogout) {
            return;
        }

        callFunctionInApp(APP_FUNCTION_NAME.AUTO_LOGIN, {}, (res:any) => {
            if(typeof res === "string") {
                res = JSON.parse(res);
            }
            let username = res.username;
            let password = res.password;
            setUsername(username);
            setPassword(password);
            doLogin(username, password, res.customerProject);
        });
    },[])

    async function doLogin(username: string, password: string, customerProject?: string) {
        if(!username || !password) {
            setDialog({
                title: "Fehler",
                text: "Benutzername oder Passwort darf nicht leer sein!",
                buttons: [
                    {
                        label: "OK",
                        callback: ()=>setDialog(null)
                    }
                ]
            })
            return;
        }
        let [e,r] = await awaitWrap(login(username, password));
        if(!!e) {
            setDialog({
                title: "Fehler",
                text: "Benutzername oder Passwort ist falsch!",
                buttons: [
                    {
                        label: "OK",
                        callback: ()=>setDialog(null)
                    }
                ]
            });
            return;
        }

        callFunctionInApp(APP_FUNCTION_NAME.AUTO_LOGIN_SAVE, {username, password});
        if(!!customerProject) {
            let customerId = getCustomerIdFromCustomerProject(customerProject, "::");
            let projectId = getProjectIdFromCustomerProject(customerProject, "::");
            if(!!customerId) {
                localStorage.setItem(localStorageItem.OA_CUSTOMER, customerId);
            }
            if(!!projectId) {
                localStorage.setItem(localStorageItem.OA_PROJECT, projectId);
            }

        }
        navigate("/");
    }

    function resetPassword() {
        if(isWebApp) {
            window.open("https://openexperience.de/de/Myaccount-Passwort-Wiederherstellen");
        } else {
            callFunctionInApp(APP_FUNCTION_NAME.OPEN_LINK,
                {url: "https://openexperience.de/de/Myaccount-Passwort-Wiederherstellen"});
        }

    }


    return <FullPage style={{backgroundColor: "#eee"}}>
        <CenterContainer className="login-container">
            <Grid container spacing={0} >
                <Grid item xs={12} sm={12} md={12} xl={12}>
                    <img src={logo}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                    <img src={experienceId}/>
                </Grid>
                {(!!locationState && locationState.error) && <Grid item xs={12} sm={12} md={12} xl={12}>
                    <span>password or username not correct</span>
                </Grid>}
                <Grid item xs={12} sm={12} md={12} xl={12} className="experienceId-input">
                    <CssTextField
                        label="Experience Id"
                        value={username}
                        onChange={(e)=>setUsername(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                    <CssTextField
                        label={"Passwort"}
                        type="password"
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6} xl={6}>
                    <StyledButton onClick={()=> resetPassword()}>
                        Passwort vergessen
                    </StyledButton>
                </Grid>
                <Grid item xs={6} sm={6} md={6} xl={6}>
                    <StyledButton onClick={()=> doLogin(username, password)}>
                        Anmelden
                    </StyledButton>
                </Grid>
            </Grid>
        </CenterContainer>
        {!!dialog && <SimpleChoiceDialog open={true}
                        title={dialog!.title}
                        text={dialog!.text}
                        buttons={dialog!.buttons}/>}
    </FullPage>
}

export default Login;