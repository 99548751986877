define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './SidePanelHtml', '../view/Documents',
	'../model/user', '../model/currentProject', '../model/currentInspection', '../model/subcontractors', '../helper/watchdog', '../helper/iosapp', '../model/ma_accounts', '../helper/externalParameters'
], function (
	$, _, Backbone, Template, Documents,
	user, currentProject, currentInspection, subcontractors, watchdog, iosapp, ma_accounts, externalParameters
) {
	return Backbone.View.extend({

		id: 'side',

		attributes: {
			'data-role':           'panel',
			'data-theme':          'a',
			'data-position':       'left',
			'data-display':        'overlay',
			'data-position-fixed': 'true'
		},

		template: _.template(Template),

		events: {
			'vclick .logout':     'logout',
			'click .toggleEdit':  'applyEdit',
			'click .myaccount-link': function () {
				if (!window.oxapp || !oxapp.ma_widget) {
					console.error('ma_widget not installed');
					return;
				}
				oxapp.ma_widget.open();
			},
			'click .fe-link a': function () {
				iosapp.gotoFE(user.get('customer'), currentProject.get('id'), user.get('experienceId'));
			},
			'vclick .mainMenuPopupButton, img.popupMenuArrow': 'triggerMainMenuPopup',
			'mouseover img.popupMenuArrow': function (e) {
				$(e.target).closest('li').find('a').addClass('hovered');
			},
			'mouseout img.popupMenuArrow': function (e) {
				$(e.target).closest('li').find('a').removeClass('hovered');
			},
			'vclick .openLink': function (e) {
				if(iosapp.appavailable || iosapp.androidappavailable ||iosapp.windowsappavailable) {
					if(e) {
						e.preventDefault();
						e.stopPropagation();
					}
					iosapp.openUrlInNativeBrowser(e.target.href);
				}
			}
		},
		
		triggerMainMenuPopup: function (e) {
			if(e) {
				e.preventDefault();
				e.stopPropagation();
			}
			var button = $(e.target);
			if(!button.hasClass('mainMenuPopupButton')) button = button.closest('li').find('a');
			var popupbox = $('#' + button[0].dataset.popup + 'Popup-popup');
			
			button.parent().find('img').addClass('opened');
			
			$('#' + button[0].dataset.popup + 'Popup').popup('open', { /* transition: 'flip' [not working properly in ios] */});
			var top = 0, left = 0;
			if($(window).outerWidth() > 800) {
				top = button.offset().top;
				left = button.outerWidth();
			} else {
				top = button.offset().top + button.outerHeight();
				left = button.outerWidth() - popupbox.outerWidth();
			}
			popupbox.css({top: top, left: left});
		},

		initialize: function (options) {
			this.router = options.router;

			this.editMode = false;

			this.mousemove   = _.bind(this.mousemove, this);
			this.open        = _.bind(this.open, this);
			this.close       = _.bind(this.close, this);
			this.render      = _.bind(this.render, this);
			this.resize      = _.bind(this.resize, this);
			this.beforeclose = _.bind(this.beforeclose, this);

			this.$el
				.html(this.template({ t: user.translate }))
				.appendTo($.mobile.pageContainer)
				.panel({
					beforeclose: this.beforeclose
				}).enhanceWithin();

			this.$logo        = this.$('.logo');
			this.$title       = this.$('h2 a');

			this.$inspections      = this.$('.inspections');
			this.$inspection       = this.$('.inspection');
			this.$location         = this.$('.location');
			this.$files            = this.$('.files');
			this.$documents        = this.$('.documents');
			this.$subcontractors   = this.$('.subcontractors');
			this.$instant          = this.$('.instant');
			this.$principal        = this.$('.principal');
			this.$cost             = this.$('.cost');
			this.$statistics       = this.$('.statistics');
			this.$settings         = this.$('.settings');
			this.$chooseCustomer   = this.$('.chooseCustomer');
			this.$help             = this.$('.help');
			this.$logout           = this.$('.logout');
			this.$projectSyncLinks = this.$('.project-sync-links');
			this.$feLink           = this.$('.fe-link').hide();
			this.newNewsIndicator  = this.$('#newNewsIndicator');
			
			//if (iosapp.appavailable || !oxapp || !oxapp.ma_widget) {
			// 	this.$('.myaccount-link').remove();
			//}

			// iosapp.isFEavailable(_.bind(function (result) {
			// 	if (result.available) {
			// 		this.$feLink.show();
			// 	}
			// }, this));

			// $.mobile.pageContainer
			// 	.on('click', '.menutrigger', this.open);
			//	.on('mousemove', this.mousemove);

			// this.listenTo(user,           'change', this.render);
			// this.listenTo(currentProject, 'change', function () {
			// 	this.render();
			// 	this.updateCustomer();
			// });
			// this.listenTo(currentInspection, 'change', this.render);

			// this.listenTo(watchdog, 'change:connected', this.render);
			// $(window).on('resize', this.resize);

			//this is quite hacky, but I don't see any other way to handle it.
			// this.$('.files a').click(function() {
			//     window.localStorage.setItem("attachmentSource_filter", JSON.stringify(currentDefects.freshFilter()));
			//     return true;
			// });
			
			// $('.logout').on('click', this.logout);
			// $('.myaccount-link').on('click', function () {
			// 	if (!window.oxapp || !oxapp.ma_widget) {
			// 		console.error('ma_widget not installed');
			// 		return;
			// 	}
			// 	oxapp.ma_widget.open();
			// });
		},

		render: function () {
			var items = this.getUserItems(),
				connected = watchdog.isConnected();

			// this.$('.offlineWarning').toggle(!connected);

			// this.$title.text(currentProject.get('name'));
			// this.$title[0].title = currentProject.get('name');
			// if (!currentInspection.isNew()) {
			// 	this.$inspection.find('a').text(currentInspection.getLabel());
			//get }

			var costType = currentProject.get('costType');
			switch (costType) {
				case 'SPECIFIC':
				case 'SEPARATE':
					this.$cost.find('a').text(user.translate('sidepanel.cost'));
					break;
				case 'GLOBAL':
					this.$cost.find('a').text(user.translate('sidepanel.evaluation'));
					break;
			}

			var isSubcontractor = user.isSubcontractor(),
				isPrincipal = user.isPrincipal(),
				isReadonly = user.isReadonly();

			this.$inspections.toggle(!isSubcontractor && /*!isReadonly &&*/ (items.inspection || this.editMode));//&& currentInspection.isNew()
			this.$inspection.toggle(!currentInspection.isNew() && !isSubcontractor && /*!isReadonly &&*/ (items.inspection || this.editMode));
			this.$location.toggle(items.location || this.editMode);
			this.$files.toggle((items.files || this.editMode));
			this.$documents.toggle(!isPrincipal && (items.documents || this.editMode));
			this.$subcontractors.toggle(user.get('requests') && !isSubcontractor && !isPrincipal && !isReadonly && (items.subcontractors || this.editMode));
			this.$instant.toggle(!isSubcontractor && !isPrincipal && !isReadonly && (items.instant || this.editMode));
			this.$principal.toggle(!isSubcontractor && !isPrincipal && !isReadonly && (items.principal || this.editMode));
			this.$cost.toggle(!!costType && !isSubcontractor && !isPrincipal && !isReadonly && (items.cost || this.editMode));
			this.$statistics.toggle(!(isPrincipal && isReadonly) && (items.statistics || this.editMode));
			this.$help.toggle(items.help || this.editMode);
			this.$chooseCustomer.toggle(!externalParameters.isForceCustomerProject());
			//this.$settings.toggle(items.settings || this.editMode);
			this.$logout.toggle(!externalParameters.hideLogout());

			this.$projectSyncLinks.empty();
			var sourceCustomer = currentProject.get('syncSourceCustomer');
			if (sourceCustomer) {
				var principalAccount = ma_accounts.findByCustomer(sourceCustomer);
				if (principalAccount) {
					$('<a href="javascript:void(0)" class="darkicon ui-btn ui-btn-icon-left ui-icon-arrow-r"></a>')
					.text(currentProject.get('syncSourceCustomerName'))
					.appendTo($('<li>').appendTo(this.$projectSyncLinks))
					.click(function () {
						principalAccount.go(currentProject.get('syncSourceProject'));
					});
				}
			}
			subcontractors.each(function (subcontractor) {
				var targetCustomer = subcontractor.get('syncTargetCustomer');
				if (targetCustomer) {
					var account = ma_accounts.findByCustomer(targetCustomer);
					if (account) {
						$('<a href="javascript:void(0)" class="darkicon ui-btn ui-btn-icon-left ui-icon-arrow-r"></a>')
						.text(subcontractor.get('syncTargetCustomerName'))
						.appendTo($('<li>').appendTo(this.$projectSyncLinks))
						.click(function () {
							account.go(subcontractor.get('syncTargetProject'));
						});
					}
				}
			}, this);

			//this.$('.toggleEdit').text(this.editMode ? '\u25B4' : '\u25BE').toggle(connected);
			this.$('.toggleEdit').toggle(this.editMode && connected);
			this.$('.check')
			.toggle(!!this.editMode)
			.each(_.bind(function (i, element) {
				var $box = $(element);
				$box.prop('checked', items[this.getBoxItemName($box)]);
			}, this));

			this.$('ul').each(function () {
				$(this).children('li').removeClass('ui-last-child').not(':hidden').last().addClass('ui-last-child');
			});

			var newDefectsSubcontractor = subcontractors.reduce(function (memo, subcontractor) {
				return memo + subcontractor.get('newDefects');
			}, 0);
			this.$subcontractors.find('.newDefects').text(newDefectsSubcontractor).toggleClass('zarro', newDefectsSubcontractor == 0);

			var newDefectsPrincipal = currentProject.get('newDefectsPrincipal');
			this.$principal.find('.newDefects')
			.text(!!newDefectsPrincipal ? newDefectsPrincipal : '')
			.toggleClass('zarro', !newDefectsPrincipal || newDefectsPrincipal == 0);

			this.$subcontractors.children('a').toggleClass('ui-disabled', !connected);
			this.$cost.children('a').toggleClass('ui-disabled',           !connected);
			//this.$instant.children('a').toggleClass('ui-disabled',        !connected);   //2015-05-10 instant messaging always possible
			this.$settings.children('a').toggleClass('ui-disabled',       !connected);
			this.$logout.children('a').toggleClass('ui-disabled',         !connected);
			this.$principal.children('a').toggleClass('ui-disabled',      !connected);
			this.$('a.account').toggleClass('ui-disabled',      !connected);
			this.$('.project-sync-links').children('a').toggleClass('ui-disabled', !connected);
			this.$('.overviewlink').attr('href', connected ? '#overview' : 'javascript:void(0)');

			$('#sidePanelTitle').text('Open Experience ' + new Date().getFullYear());
			
			this.addAdminOnlyButtons();
			
			// $('li a.dataprotection')[0].href = 'https://openexperience.de/' + (user.get('settings') && user.get('settings').lang !== 'DE' ? 'en' : 'de') + '/Datenschutzerklaerung';
			// $('li a.newslink')[0].href = 'https://websitetest.opxp.de/' + (user.get('settings') && user.get('settings').lang !== 'DE' ? 'en' : 'de') + '/new-features';
			
			$('.mainMenuPopup').popup({
				afterclose: function(e) {
					$('#side img.popupMenuArrow').removeClass('opened');
				}
			});
			
			$('#settingsPageButton').unbind('click');
			$('#settingsPageButton').click(this.handleSettingsPageOpen);
			
			$.get('https://openexperience.de/myaccount/api/whatsnew/status').then(function (response) {
			// $.get( '/api/log/' + self.model.get('id')).then(function (log) {
				if(response && typeof response === 'object' && response.unread) {
					this.newNewsIndicator.toggle(response.unread > 0);
				} else {
					this.newNewsIndicator.toggle(false);
					console.error('Response is not an object...');
				}
			}.bind(this), function (e) {
				this.newNewsIndicator.toggle(false);
				console.error(e);
			}.bind(this));
			
			return this;
		},
		
		handleSettingsPageOpen: function (e) {
			$('#settingsPopup').popup('close');
		},
		
		addAdminOnlyButtons: function () {
			if (user.isFullAdmin() && !(iosapp.appavailable || iosapp.windowsappavailable || iosapp.androidappavailable)) {
				var customerLink = user.get('customerLink');
				if (customerLink) {
					$('#settingsPopup .projectManager a')
						.text(user.translate('sidepanel.customer'))
						.attr({
							href:  customerLink, target: '_blank',
							'data-shortcut': 'adminTables'
						})
					$('#settingsPopup .adminTables a')
						.text(user.translate('sidepanel.projectmanager'))
						.attr({
							href:  '/projectmanager/?from=' + encodeURIComponent(window.location), target: '_blank',
							'data-shortcut': 'projectManager'
						})
				}
			} else {
				$('#settingsPopup .projectManager, #settingsPopup .adminTables').remove();
			}
		},
		
		isSidePanelOpen: function () {
			return $('#side').hasClass('ui-panel-open');
		},

		updateCustomer: function () {
			this.$logo.attr('src',  '/api/customer.png');
		},

		open: function () {
			var later;
			if (this.router.currentPage && this.router.currentPage.triggerMenu) {
				later = this.router.currentPage.triggerMenu();
			} else {
				later = $.Deferred().resolve();
			}
			later.done(_.bind(function () {
				this.render();
				_.defer(function (self) {
					self.$el.panel('open');
					self.resize();
				}, this);
			}, this));
		},

		close: function () {
			if (this.$el.data('mobilePanel')) {
				_.defer(function (self) {
					self.$el.panel('close');
				}, this);
			}
			$(document).off('resize');
		},

		mousemove: function (e) {
			if (!window.oxmobile && e.pageX < 20 && e.pageY > 50 && !this.isOpen() && $.mobile.activePage.find('.menutrigger').is(':visible')) {
				this.$el.one('panelopen', _.bind(function () {
					this.$el.one('mouseleave', this.close);
				}, this));
				this.open();
			}
		},

		isOpen: function () {
			return this.$el.hasClass('ui-panel-open') && !this.$el.hasClass('ui-panel-close');
		},

		logout: function (e) {
			e.preventDefault();
			e.stopPropagation();
			//copy exists in OverviewPage.js
			localStorage.removeItem('location', location.hash);
			if (iosapp.appavailable) {
				location.href =  '/logout?iosapp=true';
			} else if (window.location !== window.parent.location) {
				location.href =  '/logout?iframe=true';
			} else {
				location.href =  '/logout';
			}
		},

		resize: function () {
			if (this.$el.hasClass('ui-panel-open')) {
				this.$el.children('.ui-panel-inner').css('max-height', $(window).innerHeight() - this.$el.find('.ui-footer').outerHeight(true));
			}
		},

		getUserItems: function () {
			var settings = user.get('settings');
			return (settings && settings.menuItems) || {
				inspection:     true,
				location:       true,
				files:          true,
				documents:      false,
				subcontractors: true,
				instant:        true,
				principal:      false,
				cost:           true,
				help:           true,
				settings:       true
			};
		},

		getBoxItemName: function ($box) {
			return _.find($box.attr('class').split(/\s+/), function (className) { return className.indexOf('check-') === 0; }).substring(6)
		},

		beforeclose: function () {
			if (this.editMode != false) {
				this.editMode = false;
				this.render();
			}
		},

		applyEdit: function () {
			var settings = _.clone(user.get('settings'));
			settings.menuItems = this.getUserItems();
			this.$('.check').each(_.bind(function (i, element) {
				var $box = $(element);
				if ($box.parent().is(':visible')) {
					settings.menuItems[this.getBoxItemName($box)] = $box.is(':checked');
				}
			}, this));
			this.editMode = false;
			this.render();
			user.save({ settings: settings }, { silent: true, url:  '/api/settings' });
		},

		edit: function () {
			this.editMode = true;
			this.render();
			this.open();
		}

	});
});
