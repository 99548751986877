import {Navigate, useParams} from "react-router-dom";
import {useAppDispatch} from "../store/hooks";
import {CommonStoreStateKeys, setStateValue} from "../store/slices/commonStoreSlice";

const ForceCustomerProject = () => {

    const dispatch = useAppDispatch();
    const {customer, project} = useParams();
    dispatch(setStateValue({
        key: CommonStoreStateKeys.forcedCustomerProject,
        value: ((!!customer && !!project) ? (customer + "-" + project) : "")
    }))
    return <Navigate to={"/account"} state={{customerProjectAutoSelect: true}}/>
}

export default ForceCustomerProject;