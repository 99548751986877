define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" data-position=\"fixed\">\n" +
        "\t\n" +
        "\t<button data-shortcut=\"leftMenu\" class=\"menutrigger ui-btn ui-btn-left ui-btn-inline ui-icon-bars ui-btn-icon-notext ui-corner-all\"></button>\n" +
        "\t\n" +
        "\t<h1 data-role=\"heading\"><%= t('serviceName') %></h1>\n" +
        "\t\n" +
        "\t<!--\t<div class=\"ui-btn-right ui-btn-inline options\">-->\n" +
        "\t<!--\t\t<button data-shortcut=\"rightMenu\" class=\"actionstrigger ui-btn ui-btn-inline ui-icon-dots ui-btn-icon-notext ui-corner-all\"></button>-->\n" +
        "\t<!--\t</div>-->\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\t\n" +
        "\t<div class=\"accountInfo ui-corner-all\">\n" +
        "\t\t<table  class=\"accountInfo\">\n" +
        "\t\t\t<colgroup width=\"40%\"/>\n" +
        "\t\t\t<colgroup width=\"60%\"/>\n" +
        "\t\t\t<tbody>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td colspan=\"2\" style=\"font-weight: bold\"><%= t('account.loggedinas') %>:</td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td>\n" +
        "\t\t\t\t\t<%= t('account.loggedinas.name') %>:\n" +
        "\t\t\t\t</td>\n" +
        "\t\t\t\t<td id=\"nameValue\">\n" +
        "\t\t\t\t\n" +
        "\t\t\t\t</td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td>\n" +
        "\t\t\t\t\t<%= t('account.loggedinas.experienceid') %>:\n" +
        "\t\t\t\t</td>\n" +
        "\t\t\t\t<td id=\"idValue\">\n" +
        "\t\t\t\t\n" +
        "\t\t\t\t</td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t\t</tbody>\n" +
        "\t\t</table>\n" +
        "\t\t<div id=\"accountButtons\">\n" +
        "\t\t\t<a class=\"myaccount-link ui-btn darkicon ui-corner-all ui-shadow\"><%= t('sidepanel.myaccount') %></a>\n" +
        "\t\t\t<a class=\"logout darkicon ui-btn ui-corner-all ui-shadow\"><%= t('sidepanel.logout') %></a>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\t\n" +
        "\t<div class=\"customerInfo ui-corner-all\">\n" +
        "\t\t<b><%= t('account.customerlist.headline') %>:</b>\n" +
        "\t\t<ul id=\"customerList\" class=\"ui-listview ui-listview-inset ui-corner-all ui-shadow\" data-role=\"listview\" data-inset=\"true\">\n" +
        "\t\t\n" +
        "\t\t</ul>\n" +
        "\t</div>\n" +
        "\t\n" +
        "\t<div class=\"dataprotection ui-corner-all\">\n" +
        "\t\t<a class=\"dataprotection darkicon ui-btn ui-corner-all ui-shadow\"><%= t('account.dataprotection') %></a>\n" +
        "\t</div>\n" +
        "</div>\n" +
        "\n" +
        "<!--<div data-role=\"popup\" class=\"actionsmenu\">-->\n" +
        "<!--\t<div class=\"actionsmenucg\" data-role=\"controlgroup\" style=\"margin: 0\">-->\n" +
        "<!--\t\t<button class=\"editProjectInfo\"></button>-->\n" +
        "<!--\t\t<button class=\"backup\"><%- t('overview.backup') %></button>-->\n" +
        "<!--\t\t<button class=\"reload\"><%- t('overview.reload') %></button>-->\n" +
        "<!--\t</div>-->\n" +
        "<!--</div>-->\n" +
        "\n" +
        "\n" +
        "<div data-role=\"footer\" data-position=\"fixed\"></div>";
    return page;
});