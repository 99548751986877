import {awaitWrap, translate} from "../utils/utils";
import {versionPing} from "./apis";
// @ts-ignore
import _ from "lodash";
import store from "../store/store";
import {CommonStoreStateKeys, setStateValue} from "../store/slices/commonStoreSlice";
import {getLastModified} from "../utils/appCommunicationUtils";
import {dispatchDataWithCallback} from "../utils/backboneCommunicationUtils";
import {setDialog} from "../components/PageContainer";
import {CONTEXT_PATH} from "../index";

const timeframe = 60 * 1000;
const disconnectAvgLatency = 8000;
const connectAvgLatency = 6000;

window.addEventListener('offline', versionCheck);
window.addEventListener('online', versionCheck);

function updateLatencies(watchdog: any, start?:number) {
    let now = +new Date();
    let latency = !!start ? now - start : -1;
    watchdog.latency = latency;

    let latencies = _.cloneDeep(watchdog.latencies || []);
    latencies.push({time: now, latency: latency});

    watchdog.latencies  = _.filter(latencies, (e:any) => e.time > now - timeframe);
}

function updateConnectionStatusAfterVersionPing(watchdog: any, start: number) {
    updateLatencies(watchdog, start);
    let cnt = 0;
    let sum = _.reduce(watchdog.latencies, (summary:number, l:any) => {
        if(l.latency !== -1) {
            summary += l.latency;
            cnt++;
        }
        return summary;
    }, 0);
    let averageLatency = cnt > 0 ? sum/cnt : -1;

    if(watchdog.connected) {
        if(averageLatency !== -1 && averageLatency > disconnectAvgLatency) {
            watchdog.connected = false;
        }
    } else {
        if(!watchdog.forceOffline && averageLatency !== -1 && averageLatency < connectAvgLatency) {
            watchdog.connected = true;
        }
    }
}

export function setDisconnected(watchdog: any, force: boolean) {
    watchdog.forceOffline = force;
    updateLatencies(watchdog);
    watchdog.connected = false;
}

export async function versionCheck() {
    let watchdog1 = _.cloneDeep(store.getState().commonReducer[CommonStoreStateKeys.watchdog]);
    if(watchdog1.forceOffline) {
        return;
    }
    if(watchdog1.trying) {
        return;
    }
    watchdog1.trying = true;
    dispatchDataWithCallback(false, CommonStoreStateKeys.watchdog, watchdog1, dataFromBackbone => {
        store.dispatch(setStateValue({key: CommonStoreStateKeys.watchdog, value: dataFromBackbone}));
    });

    let state = store.getState();
    let watchdog2 = _.cloneDeep(state.commonReducer[CommonStoreStateKeys.watchdog]);
    let currentProject = state.commonReducer[CommonStoreStateKeys.currentProject];
    let start = +new Date();
    let [e, r] = await awaitWrap(versionPing((watchdog2.latency || 0), (currentProject.id || "")));
    watchdog2.trying = false;

    if(!!e) {
        setDisconnected(watchdog2, false);
    } else {
        updateConnectionStatusAfterVersionPing(watchdog2, start);
    }

    if(!!r && !!r.data) {
        let serverVersion = r?.data;
        if(!!serverVersion && !!serverVersion.defectdate && watchdog2.canUpdateLocalDefectDate) {
            watchdog2.defectLocalDate = serverVersion.defectdate;
            //1. current user made the change
            //2. no defect changes detected while current user made the change
            //3. still a problem: if another user and current user both made a change during the 20s, it wont be detected
            watchdog2.canUpdateLocalDefectDate = false;
        }
        watchdog2.defectDate = serverVersion.defectdate;

        if(serverVersion.session === false && !window.location.pathname.includes('/login') && window.location.pathname !== `${CONTEXT_PATH}/`) {
            setDialog({
                title: translate("warn"),
                text: translate("sessionexpired"),
                buttons: [
                    {
                        label: translate("ok"),
                        callback: () => window.location.reload()
                    },
                    {
                        label: translate("cancel"),
                        callback: () => setDialog(null)
                    }
                ]
            })
        }

        // @ts-ignore
        if(!!window.version && window.version !== serverVersion.version) {
            setDialog({
                title: translate("warn"),
                text: translate("newversionavailable"),
                buttons: [
                    {
                        label: translate("cancel"),
                        callback:()=> {
                            setDialog(null);
                        }
                    },
                    {
                        label: translate("ok"),
                        callback: ()=>window.location.reload()
                    }
                ]
            });
        }


        if(serverVersion.plansmodified !== undefined && serverVersion.plansmodified !== null && !!currentProject.id) {
            let pm = getLastModified(true, currentProject.id);
            watchdog2.plansInSync = {project:currentProject.id, value: pm === serverVersion.plansmodified};
        }

        if(serverVersion.attachmentsmodified !== undefined && serverVersion.attachmentsmodified !== null && !!currentProject.id) {
            let am = getLastModified(false, currentProject.id);
            watchdog2.attchmentsInSync = {project:currentProject.id, value: am === serverVersion.attachmentsmodified};
        }
    }
    dispatchDataWithCallback(false, CommonStoreStateKeys.watchdog, watchdog2, dataFromBackbone => {
        store.dispatch(setStateValue({key: CommonStoreStateKeys.watchdog, value: dataFromBackbone}));
    });
}
