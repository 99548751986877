define([
    '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2'
], function (
    $, _, Backbone
) {

    var externalParameters = {

        _forceCustomer: null,
        _forceProject: null,
        _createDefectParams: null,
        _showDefectParams: null,
        _hideLogout: false,

        _init: function() {
            var customer = sessionStorage.getItem('oa-forceCustomer');
            var project = sessionStorage.getItem('oa-forceProject');

            if (customer && project) {
                this._forceCustomer = customer;
                this._forceProject = project;
            }
        },

        isForceCustomerProject: function() {
            return this._forceCustomer && this._forceProject;
        },

        hideLogout: function() {
            return this._hideLogout;
        },

        getForcedCustomer: function() {
            return this._forceCustomer;
        },

        getForcedProject: function() {
            return this._forceProject;
        },

        setForcedCustomerProject: function(customer, project) {
            this._forceCustomer = customer;
            this._forceProject = project;
            this._hideLogout = true;
            sessionStorage.setItem('oa-forceCustomer', customer);
            sessionStorage.setItem('oa-forceProject', project);
        },

        isCreateDefect: function() {
            return !!this._createDefectParams;
        },

        getCreateDefectParams: function() {
            return this._createDefectParams;
        },
        setCreateDefectParams: function(params) {
            this._createDefectParams = params;
        },

        isShowDefect: function() {
            return !!this._showDefectParams;
        },
        getShowDefectParams: function() {
            return this._showDefectParams;
        },
        setShowDefectParams: function(params) {
            this._showDefectParams = params;
        }
    };

    externalParameters._init();

    return externalParameters;
});
