import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {useNavigate} from "react-router-dom";
import {getCustomerProjectWithPrefix, translate} from "../utils/utils";


const SelectInspection = () => {

    const navigate = useNavigate();

    const {customerProjectWithInspection} = getCustomerProjectWithPrefix();
    useEffect(()=>{
        // @ts-ignore
        window.selectInspectionUseEffect();
    },[])

    const goToSubcontractorsPage = ()=>{
        navigate(customerProjectWithInspection+'/subcontractors');
    }


    return <PageContainer newPage={false} headerText={translate("selectinspection.title")}
                          left={<StyledButton onClick={goToSubcontractorsPage}>{translate("goback")}</StyledButton>}
    />
}

export default SelectInspection;