define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../model/user', '../model/currentProject', '../helper/iosapp', '../model/proxyInfo', '../helper/commonusercomm'
], function (
	$, _, Backbone, user, currentProject, iosapp, proxyInfo, commonusercomm
) {
	return new (Backbone.Model.extend({

//PARAMETERS
		requestTimeout:        10 * 1000,
		interval:              20 * 1000,
		timeframe:             60 * 1000,
		disconnectAvgLatency:  8000,
		connectAvgLatency:     6000,

//STATE
		timeout:       null,
		checkRequest:  null,
		proxyPort:     0,

//PUBLIC PROPERTIES
		defaults: function () {
			return { 
				connected:     true,
				latency:       -1, //-1 = unknown
				latencies:     [],
				trying:        false,
				forceOffline:  false,
				canUpdate:     true,
			};
		},

		initialize: function () {
			this.check          = _.bind(this.check,          this);
			this.setDisonnected = _.bind(this.setDisonnected, this);

			//navigator connectivity events
			// $(window).on('offline', this.setDisonnected);
			// $(window).on('online',  this.check);

			//app connectivity events
			// iosapp.appavailable && iosapp.setConnectivityCallback(function (connected) {
			// 	if (this.iosconnectivitylastmessage !== connected) {
			// 		this.iosconnectivitylastmessage = connected;
			// 		if (connected) {
			// 			this.check();
			// 		} else {
			// 			this.setDisabled();
			// 		}
			// 	}
			// }, this);

			//appversioncalltrigger events
			// iosapp.appavailable && iosapp.addversioncalltriggerhandler(_.bind(function() {
			// 	this.check();
			// }, this));
		},

		isConnected: function () {
			return !!this.get('connected');
		},

		setDisonnected: function () {
			// if (proxyInfo.get('proxyMode')) {
			// 	return;
			// }
			// this.addLatencyEvent(); //add fail latency event
			// this.set('connected', false);
		},

		setConnected: function () {
			// if (!this.get('forceOffline')) {
			// 	this.set('connected', true);
			// }
		},
		
		setPlansInSync: function(project, value) {
			// this.set('plansinsync', {project: project, value: value});
		},
		
		setAttachmentsInSync: function(project, value) {
			// this.set('attachmentsinsync', {project: project, value: value});
		},

		check: function () {

		},

		// check: function () {
		//
		// 	//cancel another check if scheduled
		// 	// if (this.timeout !== null) {
		// 	// 	window.clearTimeout(this.timeout);
		// 	// 	this.timeout = null;
		// 	// }
		//
		// 	//cancel request if one 'hangs' currently
		// 	// if (this.checkRequest !== null && this.checkRequest.abort) {
		// 	// 	this.checkRequest.abort();
		// 	// }
		//
		// 	//no request if forced offline
		// 	// if (this.get('forceOffline')) {
		// 	// 	return;
		// 	// }
		//
		// 	// if (!proxyInfo.wasFetchCalled) {
		// 	//     return;
		// 	// }
		//
		// 	// proxyInfo.fetch().done(function() {
		// 	// 	var currentPort = proxyInfo.get('proxyPort');
		// 	// 	console.log('current port: ' + currentPort);
		// 	// 	if (this.proxyPort && currentPort) {
		// 	// 		if (this.proxyPort != currentPort) {
		// 	// 			console.log("proxy port changed!");
		// 	// 			window.confirmPopup('proxy port changed')
		// 	// 				.done(function () {
		// 	// 					window.location.reload();
		// 	// 				})
		// 	// 				.fail(_.bind(function () {
		// 	// 					this.schedule();
		// 	// 				}, this));
		// 	// 		}
		// 	// 	} else {
		// 	// 		this.proxyPort = currentPort;
		// 	// 	}
		// 	// });
		//
		// 	// if (proxyInfo.get('proxyMode')) {
		// 	// 	return;
		// 	// }
		// 	//start request
		// 	this.set('trying', true);
		// 	var start = +new Date(),
		// 		request = this.checkRequest = $.ajax({
		// 			type: 'GET',
		// 			url:  '/onlineBauabnahme/api/version?' + $.param({ l: this.get('latency'), p: currentProject.id }), //submit previous latency
		// 			timeout: this.requestTimeout
		// 		})
		// 	.then(_.bind(function (serverVersion, textStatus, jqXHR) {
		// 		//SUCCESS
		// 		this.addLatencyEvent(start);
		// 		this.analyzeLatencyQueue();
		//
		// 		this.checkRequest = null;
		//
		// 		this.set('trying', false);
		// 		if (!!serverVersion.defectdate && this.get('setlocalonnextupdate')) {
		// 			this.set('defectlocaldate', serverVersion.defectdate);
		// 			this.set('setlocalonnextupdate', false);
		// 		}
		// 		this.set('defectdate', serverVersion.defectdate);
		//
		// 		//version check
		// 		if (window.version != serverVersion.version && this.get('canUpdate')) {
		// 			window.confirmPopup(user.translate('popup.updateapp'))
		// 			.done(function () {
		// 				window.location.reload();
		// 			})
		// 			.fail(_.bind(function () {
		// 				this.schedule();
		// 			}, this));
		// 			return;
		// 		}
		//
		// 		//planssync version
		// 		if (typeof serverVersion.plansmodified !== 'undefined' && serverVersion.plansmodified != null) {
		// 			var result = null;
		// 			if (iosapp.appavailable) {
		// 				var appmodified = iosapp.getplanslastmodifiedforproject(currentProject.id);
		// 				if (appmodified != null) {
		// 					if (appmodified == serverVersion.plansmodified) {
		// 						result = true;
		// 					} else {
		// 						result = false;
		// 					}
		// 				}
		// 			}
		// 			this.setPlansInSync(currentProject.id, result);
		// 		}
		//
		// 		//attachmentsmodified
		// 		if (typeof serverVersion.attachmentsmodified !== 'undefined' && serverVersion.attachmentsmodified != null) {
		// 			var result = null;
		// 			if (iosapp.appavailable) {
		// 				var appmodified = iosapp.getattachmentslastmodifiedforproject(currentProject.id);
		// 				if (appmodified != null) {
		// 					if (appmodified == serverVersion.attachmentsmodified) {
		// 						result = true;
		// 					} else {
		// 						result = false;
		// 					}
		// 				}
		// 			}
		// 			this.setAttachmentsInSync(currentProject.id, result);
		// 		}
		//
		// 		if (serverVersion.session === false && currentProject && currentProject.id) {
		// 			commonusercomm.showSessionTimeoutError();
		// 		}
		//
		// 		this.schedule();
		//
		// 	}, this), _.bind(function (jqXHR, textStatus, errorThrown) {
		// 		//FAIL
		//
		// 		if (this.checkRequest != request && this.checkRequest != null) { //aborted, new request already active
		// 			return;
		// 		}
		//
		// 		this.checkRequest = null;
		// 		this.set('trying', false);
		//
		// 		if (jqXHR.status != 403) {
		// 			this.setDisonnected();
		// 		}
		//
		// 		this.schedule();
		//
		// 	}, this));
		//
		// 	return request.promise();
		// },

		schedule: function () {
			// if (this.timeout === null) { //only if not scheduled already
			// 	this.timeout = window.setTimeout(this.check, this.interval);
			// }
		},

		addLatencyEvent: function (start) {

			//set latency
			// var now = +new Date(), latency = start ? now - start : -1;
			// this.set('latency', latency);
			//
			// //set latencies
			// var latencies = _.cloneDeep(this.get('latencies'));
			// latencies.push({time: now, latency: latency});
			// this.set('latencies', _.filter(latencies, function (entry) {
			// 	return entry.time > now - this.timeframe;
			// }, this));

		},

		analyzeLatencyQueue: function () {

			// var avg = this.averageLatency();
			// if (this.isConnected()) {
			//
			// 	if (avg != -1 && avg > this.disconnectAvgLatency) { //disconnect on bad latencies
			// 		this.setDisonnected();
			// 	}
			//
			// } else {
			//
			// 	if (avg != -1 && avg < this.connectAvgLatency) { //connect on good latencies
			// 		this.setConnected();
			// 	}
			//
			// }

		},

		averageLatency: function () {
			// var count = 0,
			// 	sum = _.reduce(this.get('latencies'), function (memo, entry) {
			// 		if (entry && entry.latency != -1) {
			// 			memo += entry.latency;
			// 			count++;
			// 		}
			// 		return memo;
			// }, 0);
			// return count > 0 ? sum / count : -1;
		}

	}))();
});