import React, {forwardRef, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppSelector} from "../store/hooks";
import {CommonStoreStateKeys, setStateValue} from "../store/slices/commonStoreSlice";
import {
    Alert,
    AlertColor,
    Box, Button,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Snackbar,
    useMediaQuery
} from "@mui/material";
import {CenterContainer} from "./styledComponents";
import {
    getCustomerIdFromCustomerProject,
    getCustomerProjectWithPrefix,
    getLastSavedScrollTop, getProjectIdFromCustomerProject,
    isUserPrincipal,
    isUserReadonly,
    isUserSubcontractor,
    saveLastSavedScrollTop,
    translate
} from "../utils/utils";
import {ConfirmDialog, Footer, Header, SidePanel, SimpleChoiceDialog, StyledButton} from "@bau/material";
import {
    ArrowLeftCircleIcon,
    CalculatorIcon,
    ChartBarIcon,
    DocumentMagnifyingGlassIcon,
    EnvelopeIcon,
    HomeModernIcon,
    MagnifyingGlassIcon,
    MapIcon,
    MapPinIcon,
    UsersIcon,
    QuestionMarkCircleIcon,
    GiftIcon,
    Cog6ToothIcon,
    UserIcon
} from "@heroicons/react/24/outline";
import StatusPanel from "./StatusPanel";
import store from "../store/store";
import {costType, page} from "../utils/constants";
import moment from "moment";
// @ts-ignore
import $ from 'jquery';
import styled from "@emotion/styled";
import {theme} from "./muiCustomizedComponents";


interface PageContainerProps {
    children?: React.ReactNode,
    pageId?: page,
    newPage: boolean,
    headerText?: string,
    right?: React.ReactNode
    left?: React.ReactNode
    middle?: React.ReactNode,
    onScroll?:(top:number)=>void,
    ref?: any,
    sidePanelItemOnClick?:(path:string)=>void,
    additionalFooter?: React.ReactNode,
}

export enum OxService  {
    OA = "OA", FD2 = "FD2", QM = "QM", OT2 = "OT2", PM2 = "PM2"
}

export interface DialogInfo {
    title: string;
    text: string;
    buttons: {label: string, callback: ()=>void}[];
}

export interface SnackbarInfo {
    duration?: number,
    onClose?: ()=>void,
    severity: AlertColor,
    message: string,
}

enum SidePanelItemKey {
    inspections = "inspections",
    inspection = "inspection",
    location = "location",
    files = "files",
    requests = "requests",
    subcontractors = "subcontractors",
    instantMessages = "instantMessages",
    principalView = "principalView",
    cost = "cost",
    statistics = "statistics",
}

let dialog: DialogInfo | null = null;
let snackbar: SnackbarInfo | null = null;

export function setDialog(info: DialogInfo | null) {
    dialog = info;
    store.dispatch(setStateValue({
        key: CommonStoreStateKeys.showDialog,
        value: !!info ? +new Date() : ""
    }))
}

export function simpleChoiceDialogPromise(title: string, text: string, onlyYes?: boolean) {
    return new Promise<boolean>((resolve, reject) => {
        setDialog({
            title,
            text,
            buttons: (()=>{
                let res = [
                    {
                        label: translate("ok"),
                        callback: ()=>{
                            setDialog(null);
                            resolve(true);
                        }
                    },
                    {
                        label: translate("cancel"),
                        callback: ()=>{
                            setDialog(null);
                            reject(true);
                        }
                    }
                ];
                if(onlyYes) {
                    res.splice(1, 1);
                }
                return res;
            })()
        })
    });
}

export function setSnackbar(info: SnackbarInfo | null) {
    snackbar = info;
    store.dispatch(setStateValue({
        key: CommonStoreStateKeys.showSnackbar,
        value: !!info ? +new Date() : ""
    }))
}

const PageContainer:React.FC<PageContainerProps> = forwardRef((props: PageContainerProps, pageRef) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const loading = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.loading]);
    const currentProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentProject]);
    const currentCustomer = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentCustomer]);
    const currentInspection = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentInspection]);
    const user = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.user]);
    const showDialog = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.showDialog]);
    const showSnackbar = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.showSnackbar]);
    const forceCustomerProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.forcedCustomerProject]);
    const {connected} = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.watchdog]);

    let {customerProject, customerProjectWithInspection} = getCustomerProjectWithPrefix();

    function checkIfLinkActive(pathname: string) {
        return location.pathname === pathname;
    }

    const panelOnClick = (path:string)=>{
        if(!!props.sidePanelItemOnClick) {
            props.sidePanelItemOnClick(path);
        } else {
            navigate(path);
        }
    }

    function getVisibleItems() {
        if(!currentProject || !currentProject.id || currentlyProjectSelection) {
            return [];
        }

        let costItemLabel = currentProject.costType === costType.GLOBAL ? translate("sidepanel.evaluation") : translate("sidepanel.cost");
        let inspectionItemLabel = translate("inspection.title");
        if(!!currentInspection && !!currentInspection.id) {
            inspectionItemLabel = (currentInspection.date ? moment(currentInspection.date).format('L') : '') + ', ' + currentInspection.title;
        }
        let allItems = {
            [SidePanelItemKey.inspections]: {
                icon: MagnifyingGlassIcon,
                name: translate("sidepanel.inspections"),
                onClick: ()=>{panelOnClick(`${customerProject}/inspections`)},
                active: checkIfLinkActive(`${customerProject}/inspections`)
            },
            [SidePanelItemKey.inspection]: {
                icon: ArrowLeftCircleIcon,
                name: inspectionItemLabel,
                onClick: ()=>{panelOnClick(customerProjectWithInspection)},
                active: checkIfLinkActive(customerProjectWithInspection)
            },
            [SidePanelItemKey.location]: {
                icon: MapPinIcon,
                name: translate('sidepanel.defects'),
                onClick: ()=>{panelOnClick(`${customerProjectWithInspection}/location`)},
                active: checkIfLinkActive(`${customerProjectWithInspection}/location`)
            },
            [SidePanelItemKey.files]: {
                icon: MapIcon,
                name: translate("sidepanel.files"),
                onClick: ()=>{panelOnClick(`${customerProjectWithInspection}/files`)},
                active: checkIfLinkActive(`${customerProjectWithInspection}/files`)
            },
            [SidePanelItemKey.requests]: {
                icon: DocumentMagnifyingGlassIcon,
                name: translate("sidepanel.documents"),
                disabled: !connected,
                onClick: ()=>{panelOnClick(`${customerProjectWithInspection}/documents`)},
                active: checkIfLinkActive(`${customerProjectWithInspection}/documents`)
            },
            [SidePanelItemKey.subcontractors]: {
                icon: UsersIcon,
                name: translate("sidepanel.subcontractors"),
                disabled: !connected,
                onClick: ()=>{panelOnClick(`${customerProjectWithInspection}/subcontractors`)},
                active: checkIfLinkActive(`${customerProjectWithInspection}/subcontractors`),
            },
            [SidePanelItemKey.instantMessages]: {
                icon: EnvelopeIcon,
                name: translate("sidepanel.instant"),
                onClick: ()=>{panelOnClick(`${customerProjectWithInspection}/instantMessage`)},
                active: checkIfLinkActive(`${customerProjectWithInspection}/instantMessage`)
            },
            [SidePanelItemKey.principalView]: {
                icon: HomeModernIcon,
                name: translate("sidepanel.principal"),
                disabled: !connected,
                onClick: ()=>{panelOnClick(`${customerProjectWithInspection}/principalBundle`)},
                active: checkIfLinkActive(`${customerProjectWithInspection}/principalBundle`)
            },
            [SidePanelItemKey.cost]: {
                icon: CalculatorIcon,
                name: costItemLabel,
                disabled: !connected,
                onClick: ()=>{panelOnClick(`${customerProjectWithInspection}/cost`)},
                active: checkIfLinkActive(`${customerProjectWithInspection}/cost`)
            },
            [SidePanelItemKey.statistics]: {
                icon: ChartBarIcon,
                name: translate("sidepanel.statistics"),
                onClick: ()=>{panelOnClick(`${customerProjectWithInspection}/statistics`)},
                active: checkIfLinkActive(`${customerProjectWithInspection}/statistics`)
            }
        };

        let subcontractor = isUserSubcontractor(user);
        let principal = isUserPrincipal(user);
        let readonly = isUserReadonly(user);

        let items: any[] = [];
        Object.keys(allItems).forEach((key, index) => {
            let show = (key === SidePanelItemKey.inspections && !subcontractor)
                || (key === SidePanelItemKey.inspection && !!currentInspection && !!currentInspection.id && !subcontractor)
                || (key === SidePanelItemKey.location)
                || (key === SidePanelItemKey.files)
                || (key === SidePanelItemKey.requests && !principal)
                || (key === SidePanelItemKey.subcontractors && !!user.requests && !subcontractor && !principal && !readonly)
                || (key === SidePanelItemKey.instantMessages && !subcontractor && !principal && !readonly)
                || (key === SidePanelItemKey.principalView && !subcontractor && !principal && !readonly)
                || (key === SidePanelItemKey.cost && !!currentProject.costType && !subcontractor && !principal && !readonly)
                || (key === SidePanelItemKey.statistics && !(principal && readonly))
            ;
            if(show) {
                // @ts-ignore
                items.push(allItems[key]);
            }
        })
        items.forEach(item => item.backgroundColor = theme.palette.primary.main);
        return items;
    }

    const getItems = () => {
        let items = [];
        const projectRelatedItems = getVisibleItems();

        items.push(projectRelatedItems);

        let generalItems = [
            {
                icon: QuestionMarkCircleIcon,
                name: translate("sidepanel.help"),
                onClick: ()=>{panelOnClick("/help")},
                active: checkIfLinkActive("/help")
        },
            {
                icon: GiftIcon,
                name: translate("sidepanel.whatsnew"),
                onClick: ()=>{panelOnClick("/news")},
                active: checkIfLinkActive("/news")
        },
        ]
        if(!!currentProject && !!currentProject.id && !currentlyProjectSelection) {

            generalItems.push({
                icon: Cog6ToothIcon,
                name: translate("sidepanel.settings"),
                // @ts-ignore
                disabled: !connected,
                onClick: ()=>{panelOnClick(`${customerProject}/settings`)},
                active: checkIfLinkActive(`${customerProject}/settings`)
            });
        }
        if(!forceCustomerProject) {
            generalItems.push({
                icon: UserIcon,
                name: translate("sidepanel.accountDataProtection"),
                onClick: ()=>{navigate("/account")},
                active: checkIfLinkActive("/account")
            })
        }

        items.push(generalItems);
        return items;
    }

    let header = {
        headerText: props.headerText,
        right: props.right,
        left: props.left,
        middle: props.middle
    }

    let currentlyProjectSelection = location.pathname === "/projects";

    let sidePanel = {
        oxService: OxService.OA,
        project: (!!currentProject && !!currentProject.id && !currentlyProjectSelection) ? {
            name: currentProject.name,
            onClick: ()=>{panelOnClick(customerProject)},
            active: checkIfLinkActive(customerProject)
        } : undefined,
        items: getItems()
    }

    useEffect(()=>{
        let elements = document.querySelectorAll("[data-role='page']");
        if(elements.length > 0){
            let element = elements[0];
            // @ts-ignore
            element.style.display = "block";
        }
        if(props.pageId) {
            let lastSavedScrollTop = getLastSavedScrollTop();
            if(!(
                (props.pageId === page.PERSON_CSV && lastSavedScrollTop.scrolledPage === page.SETTINGS)
                || (props.pageId === page.WARRANTY_DATA_OVERVIEW && lastSavedScrollTop.scrolledPage === page.SETTINGS)
                || (props.pageId === page.BUYER_ACCESS && lastSavedScrollTop.scrolledPage === page.SETTINGS)
                || (props.pageId === page.BUYER_ROLES && lastSavedScrollTop.scrolledPage === page.SETTINGS)
                || (props.pageId === page.SETTINGS && lastSavedScrollTop.scrolledPage === page.SETTINGS)
            )) {
                saveLastSavedScrollTop();
            } else {
                let newTop = 0;
                if (props.pageId === page.SETTINGS) {
                    newTop = lastSavedScrollTop.top;
                    saveLastSavedScrollTop();
                }

                // @ts-ignore
                if (!!pageRef && !!pageRef.current) {
                    // @ts-ignore
                    pageRef.current.scrollTop = newTop;
                }
            }
        } else {
            saveLastSavedScrollTop();
        }

        return () => {
            if(!props.newPage) {
                // @ts-ignore
                window.hidePage();
            }
        }
    },[])


    const toggleDrawer = (open: boolean) =>  (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setSidebarOpen(open);
    };

    const onScroll = (e:any) => {
        let scrollTop = !!e && !!e.target ? (e.target.scrollTop || 0) : 0;
        if(!!props.onScroll) {
            props.onScroll(scrollTop);
        }
    }


    const length = useMediaQuery(theme.breakpoints.up('sm')) ? 100 : 60;
    return (
        <Box sx={{height: '100%'}}>
            <Header {...header} handleDrawer={toggleDrawer}>
                <SidePanel
                    {...sidePanel}
                    open={sidebarOpen}
                    onClose={toggleDrawer}
                />
            </Header>
            <PageContentWrapper>
                <PageContent ref={pageRef} onScroll={onScroll}>
                    {props.children}
                    <Dialog open={loading}>
                        <CenterContainer>
                            <CircularProgress color={"primary"} style={{width: length, height: length}}/>
                        </CenterContainer>
                    </Dialog>
                    {showDialog && !!dialog && <SimpleChoiceDialog open={true} title={dialog.title} text={dialog.text} buttons={dialog.buttons}/>}
                    {showSnackbar && !!snackbar && <Snackbar open={true} autoHideDuration={snackbar.duration || 3000} onClose={snackbar.onClose}>
                        <Alert onClose={snackbar.onClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>}
                </PageContent>
            </PageContentWrapper>
            <Footer>
                <StatusPanel/>
                {props.additionalFooter}
            </Footer>
        </Box>
    )
});

const PageContentWrapper = styled(Box)`
	padding: 65px 0 35px 0;
	height:  100%;
	overflow: hidden;
  @media (max-width: 599px) {
    padding: 57px 0 35px 0; 
  }
  
`;

const PageContent = styled(Box)`
	overflow-y:                 auto;
	height:                     100%;
	-webkit-overflow-scrolling: touch;
`;
export default PageContainer;