define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2'
], function ($, _, Backbone) {
	return {

		getUser: function () {
			return require('../model/user');
		},

		getIosApp: function () {
			return require('./iosapp');
		},

		sync: function (method, model, options) {
			var result = $.Deferred();

			Backbone.sync(method, model, options).then(function () {

				result.resolve();

			}, _.bind(function (xhr, textStatus, errorThrown) {
				if (textStatus === 'abort' || errorThrown === 'abort') {
					return; //manually aborted
				}

				if (xhr && xhr.readyState > 0 && xhr.readyState != 4) { //request was aborted, probably part a of broken $.when() chain
					xhr.abort();
					return;
				}

				delete options.xhr;

				if (xhr && xhr.status == 403) { //gets handled in router
					Backbone.Events.once('loginSuccess', function () {
						this.sync(method, model, options).then(function () {
							result.resolve();
						}, function () {
							result.reject();
						});
					}, this);
					return;
				}

				if (xhr && xhr.status == 404) { //gets handled in router -- wrong customer
					return;
				}

				if (xhr && xhr.status == 400 && model) {
					var data = {
						message: textStatus + ' ' + errorThrown,
						source: method + ' ' + JSON.stringify(model.toJSON())
					};
					var iosapp = this.getIosApp();
					iosapp.getsysteminfo(function (systeminfo) {
						data.systeminfo = systeminfo;
						$.ajax({
							type: 'POST',
							url:  '/api/error',
							data: data,
							global: false
						});
					});
				}

				var wasLoading = $('.ui-loading').length > 0;
				if (wasLoading) {
					$.mobile.loading('hide');
				}

				if (options && options.oxNoConnectionErrorMessage) {
					result.reject();
					return;
				}

				var user = this.getUser();
				confirmPopup(user && user.translate('popup.connectionerror') || 'Verbindungsfehler') //for some reason user is sometimes null... I have no clue why
				.always(function () {
					if (wasLoading) {
						$.mobile.loading('show');
					}
				})
				.then(_.partial(_.bind(this.sync, this), method, model, options)) //retry
				.then(function () {
					result.resolve();
				}, function () {
					result.reject();
				 });

			}, this));

			return result;
		}

	};
});