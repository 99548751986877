define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './StatisticsViewHtml',
	'../model/projects', '../model/currentProject', '../model/user', '../model/subcontractors', '../model/currentStats', '../lib/c3-0.4.10', '../helper/watchdog'
], function (
	$, _,Backbone, Template,
	projects, currentProject, user, subcontractors, currentStats, c3, watchdog
) {

	// fix for X-Axis labels
	c3.chart.internal.fn.categoryName = function (i) {
		var config = this.config, categoryIndex = Math.ceil(i);
		return i < config.axis_x_categories.length ? config.axis_x_categories[categoryIndex] : i;
	};

	return Backbone.View.extend({

		className: 'projectstats',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		events: {
			'change select':              'reloadStats',
			'change [name=onlyExternal]': 'reloadStats'
		},

		showNumbers: true,

		initialize: function (options) {
			if (options && options.showNumbers != null) {
				this.showNumbers = !!options.showNumbers;
			}
			_.bindAll(this, 'renderConnectivityState');
			this.$el.html(this.template({ t: user.translate, showNumbers: this.showNumbers }));

			this.$chartContainer      = this.$el.find('#chartContainer');
			this.$subcontractor       = this.$el.find('select');
			this.$external            = this.$el.find('input[name=onlyExternal]');
			this.$numbers             = this.$el.find('.numbers');
			this.$offlineMessage      = this.$el.find('.offline');
			this.$noStatsMessage      = this.$el.find('.noStats');
			this.$noStatsGroupMessage = this.$el.find('.noStatsGroup');

			this.listenTo(watchdog, 'change:connected', this.renderConnectivityState);
		},

		render: function () {
			this.renderConnectivityState();

			var stats = currentStats.get('stats');
			this.$noStatsMessage.toggle(!stats && !currentProject.get('groupFilterActive'));
			this.$noStatsGroupMessage.toggle(!stats && currentProject.get('groupFilterActive'));
			this.$chartContainer.toggle(!!stats);
			this.$numbers.toggle(!!stats);

			var subcontractor = user.isSubcontractor() ? user.get('id') : null;
			if (subcontractor == null) {
				subcontractor = '<NULL>';
			}
			this.$subcontractor.empty();

			createOption('<NULL>', user.translate('statistics.allsubcontractors')).appendTo(this.$subcontractor);
			subcontractors.each(function (subcontractor) {
				createOption(subcontractor.id, subcontractor.getLabel()).appendTo(this.$subcontractor);
			}, this);
			this.$subcontractor.val(subcontractor).selectmenu('refresh');
			this.$external.prop('checked',  user.isPrincipal()).checkboxradio('refresh');

			this.$subcontractor.closest('.ui-select').add(this.$external.closest('.ui-checkbox')).toggle(!user.isSubcontractor() && !user.isPrincipal());

			if (!stats) {
				return this;
			}

			this.createChart();

			if (this.showNumbers) {
				var deadlineSummary = currentStats.get('deadlineSummary');
				this.$numbers.find('.deadlineexpired').text(deadlineSummary.countExpired);
				this.$numbers.find('.deadlineexpiringin3days').text(deadlineSummary.countExpiringIn3Days);
				this.$numbers.find('.deadlineexpiringin7days').text(deadlineSummary.countExpiringIn7Days);

				this.$numbers.find('.lastweekall').text(currentStats.get('lastWeekAll'));
				this.$numbers.find('.lastweekfixed').text(currentStats.get('lastWeekFixed'));
				this.$numbers.find('.lastweekopen').text(currentStats.get('lastWeekOpen'));
				this.$numbers.find('.lastweeksubcontractorokandpending').text(currentStats.get('lastWeekSubcontractorOkAndPending'));

				this.$numbers.find('.last4weeksall').text(currentStats.get('last4WeeksAll'));
				this.$numbers.find('.last4weeksfixed').text(currentStats.get('last4WeeksFixed'));
				this.$numbers.find('.last4weeksopen').text(currentStats.get('last4WeeksOpen'));
				this.$numbers.find('.last4weekssubcontractorokandpending').text(currentStats.get('last4WeeksSubcontractorOkAndPending'));

				this.$numbers.find('.totalall').text(currentStats.get('totalAll'));
				this.$numbers.find('.totalfixed').text(currentStats.get('totalFixed'));
				this.$numbers.find('.totalopen').text(currentStats.get('totalOpen'));
				this.$numbers.find('.totalsubcontractorokandpending').text(currentStats.get('totalSubcontractorOkAndPending'));
			}

			function createOption (subcontractorId, label) {
				return $('<option>').val(subcontractorId).text(label);
			}

			return this;
		},

		renderConnectivityState: function () {
			if (arguments.length) { //triggered by event
				if (!this.$el.parents('.ui-page-active')) {
					return;
				}
			}
			var connected = watchdog.isConnected();
			this.$offlineMessage.toggle(!connected);
			this.$subcontractor.toggleClass('ui-disabled', !connected);
			this.$external.toggleClass('ui-disabled', !connected).prop('disabled', !connected);
		},

		reloadStats: function () {
			var subcontractor = this.$subcontractor.val();
			var externalOnly = this.$external.prop('checked');
			subcontractor === '<NULL>' ? subcontractor = null : subcontractor;
			$.mobile.loading('show');
			currentStats.getStats(subcontractor, externalOnly)
				.then(_.bind(function () {
					this.render();
					$.mobile.loading('hide');
				}, this))
		},

		createChart: function () {
			var stats = currentStats.get('stats');
			var statsYearLines = currentStats.get('yearLines');

			var labelLimit = Math.floor($(window).width() / 40);

			if (this.projectStatsChart) {
				this.projectStatsChart.load({
					columns: stats
				});
				this.projectStatsChart.xgrids(statsYearLines);
			} else {
				this.projectStatsChart = c3.generate({
					size: { height: 430 },
					data:  {
						x:       'weeks',
						columns: stats,
						type:    'bar',
						types:   {
							countAll:   'area',
							countFixed: 'area'
						},
						/*					groups:  [
						 ['deltaAll', 'deltaFixed']
						 ],*/
						colors:  {
							countAll:   '#888',
							countFixed: '#ee7f00',
							deltaAll:   '#888',
							deltaFixed: '#ee7f00'
						},
						axes:    {
							deltaAll:   'y2',
							deltaFixed: 'y2'
						},
						names: {
							countAll:   user.translate('statistics.countAll'),
							countFixed: user.translate('statistics.countFixed'),
							deltaAll:   user.translate('statistics.deltaAll'),
							deltaFixed: user.translate('statistics.deltaFixed')
						},
					    order: null
					},
					axis:  {
						x:  {
							type:  'category',
							tick:  {
								fit: true,
								count: labelLimit,
								culling: {
									max: labelLimit
								}
							},
							label: user.translate('statistics.week')
						},
						y:  {
							label: user.translate('statistics.sum')
						},
						y2: {
							show:  true,
							label: user.translate('statistics.delta')
						}
					},
					grid: {
						x: {
							lines: statsYearLines
						}
					},
					zoom:  {
						enabled: true
					},
					point: {
						show: false
					}
				});
				this.$chartContainer.children('.c3').remove();
				this.$chartContainer.append(this.projectStatsChart.element);

				// put area types in the background
				var $groups = this.$chartContainer.find('svg').children('g');
				$groups.each(function () {
					$(this).find('.c3-chart-bars').insertAfter($(this).find('.c3-chart-lines'));
				});
			}
		}

	});
});