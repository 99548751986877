define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id='filter-page-header'>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\t<div class=\"ui-field-contain\">\n" +
        "\t\t<label for=\"saved-options\">Voreinstellung:</label>\n" +
        "\n" +
        "\t\t<table class=\"preset-box ui-mini\">\n" +
        "\t\t<tr>\n" +
        "\t\t\t<td colspan=\"2\"><select name=\"saved-options\" id=\"saved-options\"></select></td>\n" +
        "\t\t\t<td><button class=\"preset-delete ui-btn ui-btn-inline ui-corner-all\"><%- t('filter.preset.delete') %></button></td>\n" +
        "\t\t</tr>\n" +
        "\t\t<tr>\n" +
        "\t\t\t<td><%- t('filter.preset.name') %></td>\n" +
        "\t\t\t<td style=\"width: 100%\"><input type=\"text\" class=\"preset-name\"/></td>\n" +
        "\t\t\t<td><button class=\"preset-save ui-btn ui-btn-inline ui-corner-all\"><%- t('filter.preset.save') %></button></td>\n" +
        "\t\t</tr>\n" +
        "\t\t</table>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"external-list\" data-mini=\"true\"><legend><%- t('filter.external') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"type-list\" data-mini=\"true\"><legend><%- t('filter.type') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain limited-height\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"locations-list\" data-mini=\"true\"><legend><%- t('filter.locations') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain limited-height\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"crews-list\" data-mini=\"true\"><legend><%- t('filter.crew') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain limited-height\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"subcontractors-list\" data-mini=\"true\"><legend><%- t('filter.subcontractors') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain limited-height\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"inspections-list\" data-mini=\"true\"><legend><%- t('filter.inspections') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain combined-status-container\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"cobined-status-list\" data-mini=\"true\"><legend><%- t('filter.combinedstatus') %></legend>\n" +
        "\t\t\t\t<select id=\"combinedStatusFilter\" data-filter=\"status\">\n" +
        "\t\t\t\t\t<optgroup>\n" +
        "\t\t\t\t\t\t<option value=\"\"><%- t('defects.filter.anystatus') %></option>\n" +
        "\t\t\t\t\t</optgroup>\n" +
        "\t\t\t\t\t<optgroup>\n" +
        "\t\t\t\t\t\t<option value=\"pending\"><%- t('defects.filter.status.pending') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"done\"><%- t('defects.filter.status.done') %></option>\n" +
        "\t\t\t\t\t</optgroup>\n" +
        "\t\t\t\t\t<optgroup>\n" +
        "\t\t\t\t\t\t<option value=\"pending-subcontractor-pending\" class=\"onlyshow-subcontractor\"><%- t('defects.filter.pendingsubcontractorpending') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"pending-subcontractor-fixed\"   class=\"noshow-subcontractor noshow-principal\"><%- t('defects.filter.pendingsubcontractorfixed') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"all-principal-rejected\"        class=\"noshow-subcontractor\"><%- t('defects.filter.allprincipalrejected') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"all-principal-discount\"        class=\"noshow-subcontractor\"><%- t('defects.filter.allprincipaldiscount') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"all-principal-accepted\"        class=\"noshow-subcontractor\"><%- t('defects.filter.allprincipalaccepted') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"all-principal-pendingrejected\" class=\"noshow-subcontractor\"><%- t('defects.filter.allprincipalpendingrejected') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"done-principal-pending\"        class=\"noshow-subcontractor\"><%- t('defects.filter.doneprincipalpending') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"all-principal-pending\"         class=\"noshow-subcontractor\"><%- t('defects.filter.allprincipalpending') %></option>\n" +
        "\n" +
        "\t\t\t\t\t\t<option value=\"extrastatusp\"  class=\"noshow-subcontractor\"><%- t('defects.filter.extrastatusp')  %></option>\n" +
        "\t\t\t\t\t\t<option value=\"extrastatusg\"  class=\"noshow-subcontractor\"><%- t('defects.filter.extrastatusg')  %></option>\n" +
        "\n" +
        "\t\t\t\t\t\t<option value=\"extrastatusas\" class=\"noshow-subcontractor\"><%- t('defects.filter.extrastatusas') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"extrastatusag\" class=\"noshow-subcontractor\"><%- t('defects.filter.extrastatusag') %></option>\n" +
        "\n" +
        "\t\t\t\t\t\t<option value=\"request1-overdue\" class=\"noshow-subcontractor\"><%- t('defects.filter.request1-overdue') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"request2-overdue\" class=\"noshow-subcontractor\"><%- t('defects.filter.request2-overdue') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"agoverdue\" class=\"noshow-subcontractor\"><%- t('defects.filter.agoverdue') %></option>\n" +
        "\n" +
        "\t\t\t\t\t\t<option value=\"subcontractor-pending-commented\" class=\"noshow-subcontractor\"><%- t('defects.filter.subcontractor-pending-commented') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"subcontractor-fixed-commented\" class=\"noshow-subcontractor\"  ><%- t('defects.filter.subcontractor-fixed-commented')   %></option>\n" +
        "\t\t\t\t\t\t<option value=\"subcontractor-has-new-comments\" class=\"noshow-subcontractor\" ><%-  t('defects.filter.subcontractor-has-new-comments') %></option>\n" +
        "\n" +
        "\t\t\t\t\t\t<option value=\"subcontractor-pending-all-changes\" class=\"noshow-subcontractor\"><%- t('defects.filter.subcontractor-pending-all-changes') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"subcontractor-fixed-all-changes\" class=\"noshow-subcontractor\"  ><%- t('defects.filter.subcontractor-fixed-all-changes')   %></option>\n" +
        "\t\t\t\t\t\t<option value=\"subcontractor-all-changes\" class=\"noshow-subcontractor\"        ><%- t('defects.filter.subcontractor-all-changes')         %></option>\n" +
        "\t\t\t\t\t\t<option value=\"principal-all-changes\" class=\"noshow-subcontractor\"            ><%- t('defects.filter.principal-all-changes')             %></option>\n" +
        "\t\t\t\t\t</optgroup>\n" +
        "\t\t\t\t\t<optgroup>\n" +
        "\t\t\t\t\t\t<option value=\"fix\"><%- t('defect.status.fix') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"open\"><%- t('defect.status.open') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"irrelevant\"><%- t('defect.status.irrelevant') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"discount\"><%- t('defect.status.discount') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"fixed\"><%- t('defect.status.fixed') %></option>\n" +
        "\t\t\t\t\t</optgroup>\n" +
        "\t\t\t\t\t<optgroup>\n" +
        "\t\t\t\t\t\t<option value=\"observed\"><%- t('defect.status.observed') %></option>\n" +
        "\t\t\t\t\t\t<option value=\"rejected\"><%- t('defect.status.rejected') %></option>\n" +
        "\t\t\t\t\t</optgroup>\n" +
        "\t\t\t\t\t<optgroup>\n" +
        "\t\t\t\t\t\t<option value=\"costnone\"><%- t('defects.filter.costnone') %></option><!-- referenced by cost page -->\n" +
        "\t\t\t\t\t\t<option value=\"agcostnone\"><%- t('defects.filter.agcostnone') %></option>\n" +
        "\t\t\t\t\t\t<!-- 2016-01-20: no more cost filter here (should be in extended filter)\n" +
        "                                            <option value=\"cost1000\"><%- t('defects.filter.costsignificant') %></option>\n" +
        "                                            <option value=\"cost0\"><%- t('defects.filter.cost0') %></option>\n" +
        "                                            <option value=\"costto200\"><%- t('defects.filter.costto200') %></option>\n" +
        "                                            <option value=\"costto1000\"><%- t('defects.filter.costto1000') %></option>\n" +
        "                                            <option value=\"costfrom1000\"><%- t('defects.filter.costfrom1000') %></option>\n" +
        "                                            <option value=\"costfrom5000\"><%- t('defects.filter.costfrom5000') %></option>\n" +
        "                        -->\n" +
        "\t\t\t\t\t</optgroup>\n" +
        "\t\t\t\t</select>\n" +
        "\t\t</fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain not-combined-status-container\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"status-list\" data-mini=\"true\"><legend><%- t('filter.status') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain not-combined-status-container\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"subcontractorstatus-list\" data-mini=\"true\"><legend><%- t('filter.subcontractorstatus') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain not-combined-status-container\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"principalstatus-list\" data-mini=\"true\"><legend><%- t('filter.principalstatus') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain not-combined-status-container\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"deadline-list\" data-mini=\"true\"><legend><%- t('filter.deadline') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain not-combined-status-container\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"requests-list\" data-mini=\"true\"><legend><%- t('filter.escalationLevels') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"createdby-list\" data-mini=\"true\"><legend><%- t('filter.createdby') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div class=\"ui-field-contain\">\n" +
        "\t\t<fieldset data-role=\"controlgroup\" class=\"description-list\" data-mini=\"true\"><legend><%- t('filter.description') %></legend></fieldset>\n" +
        "\t</div>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='filter-page-footer' data-position=\"fixed\"></div>";
    return page;
});