define([
	'./lib/jquery-1.11.0', './lib/lodash-2.4.1.compat', './lib/backbone-1.1.2', './helper/watchdog', './helper/iosapp', './helper/shortcuts/shortcuts', './view/AccountPage', './view/NewsPage', './helper/externalParameters',
	'./model/user', './model/projects', './model/currentProject', './model/inspections', './model/subcontractors', './model/participants',
	'./model/currentDefects', './model/currentInspection', './model/completions', './model/projectFiles', './model/attachmentSources', './model/projectFileSources', './model/Defect', './model/paperwork',
	'./model/help', './model/currentStats', './model/accessibleAccounts', './model/persons', './model/warrantyData', './model/polygonsAndCfgs', './model/realWorldPlanTransformations', './model/recordPoints', './model/buyerAccesses', './model/buyerRoles',
	'./view/SidePanel', './view/StatusPanel',
	'./view/LoginPage', './view/OverviewPage',   './view/InspectionsPage', './view/InspectionPage', './view/InspectionPageNew', './view/LocationPage',
	'./view/DefectsPage', './view/DefectPage', './view/FilesPage', './view/Documents', './view/ImagePage', './view/SubcontractorsPage', './view/CostPage', './view/InstantPage', './view/SelectInspectionPage',
	'./view/SettingsPage', './view/SubcontractorPage', './view/SubcontractorBundlePage', './view/PrincipalBundlePage', './view/RequestBundlePage', './view/Paperwork',
	'./view/FilterPage', './view/HelpPage', './view/HelpArticlePage', './view/StatisticsPage', './model/AttachmentSource', './view/ChooseCustomerPage', './view/PersonCSV', './view/BuyerAccess', './view/BuyerRoles', './view/WarrantyDataOverview', './view/QueueTool',
	'./helper/rocketMode', './model/DocumentsPaperwork', './view/Sketcher', './helper/realWorldPlanTransformation', './helper/backboneReactCommunicationUtils', './model/proxyInfo', './model/ma_accounts', './helper/offlineQueue'
], function (
	$, _, Backbone, watchdog, iosapp, shortcuts, AccountPage, NewsPage, externalParameters,
	user, projects, currentProject, inspections, subcontractors, participants,
	currentDefects, currentInspection, completions, projectFiles, attachmentSources, projectFileSources, Defect, paperwork,
	help, currentStats, accessibleAccounts, persons, warrantyData, polygonsAndCfgs, realWorldPlanTransformations, recordPoints, buyerAccesses, buyerRoles,
	SidePanel, StatusPanel,
	LoginPage, OverviewPage, InspectionsPage, InspectionPage, InspectionPageNew, LocationPage,
	DefectsPage, DefectPage, FilesPage, Documents, ImagePage, SubcontractorsPage, CostPage, InstantPage, SelectInspectionPage,
	SettingsPage, SubcontractorPage, SubcontractorBundlePage, PrincipalBundlePage, RequestBundlePage, PaperworkPage,
	FilterPage, HelpPage, HelpArticlePage, StatisticsPage, AttachmentSource, ChooseCustomerPage, PersonCSV, BuyerAccess, BuyerRoles, WarrantyDataOverview, QueueTool,
	rocketModeHelper, DocumentsPaperwork, Sketcher, realWorldPlanTransformationHelper, backboneReactCommunicationUtils, proxyInfo, ma_accounts, offlineQueue
) {

	return Backbone.Model.extend({
		initialize: function () {
			this.registerBackboneModelChangedListener();
			window.name = window.generateUUID();
			Backbone.ajax = function () {
				var settings = _.last(arguments);
				if (!watchdog.isConnected() && !(settings && settings.offlineQueueRequest)) {
					return $.Deferred().reject('offline');
				}
				var ajaxDefer = $.ajax.apply($, arguments);
				var defer = $.Deferred();
				ajaxDefer.done(function() {
					defer.resolve.apply(defer, arguments); //just forward
				});
				ajaxDefer.fail(function(xhr) {
					if (xhr.status === 901) {
						defer.reject('proxyOffline');
					} else {
						defer.reject.apply(defer, arguments);
					}
				});

				return defer;
			};

			var options = {
				router: this
			};

			this.inspectionsPage          = new InspectionsPage(options);
			this.inspectionPage           = new InspectionPage(options);
			this.inspectionPageNew        = new InspectionPageNew(options);
			this.locationPage             = new LocationPage(options);
			this.defectPage               = new DefectPage(options);
			this.defectsPage              = new DefectsPage(options);
			this.subcontractorsPage       = new SubcontractorsPage(options);
			this.costPage                 = new CostPage(options);
			this.subcontractorPage        = new SubcontractorPage(options);
			this.selectInspectionPage     = new SelectInspectionPage(options);
			this.subcontractorBundlePage  = new SubcontractorBundlePage(options);
			this.principalBundlePage      = new PrincipalBundlePage(options);
			this.filesPage                = new FilesPage(options);
			this.documentsPage            = new Documents(options);
			this.imagePage                = new ImagePage(options);
			this.instantMessagePage       = new InstantPage(options);
			this.settingsPage             = new SettingsPage(options);
			this.requestBundlePage        = new RequestBundlePage(options);
			this.paperworkPage            = new PaperworkPage(options);
			this.helpPage                 = new HelpPage(options);
			this.helpArticlePage          = new HelpArticlePage(options);
			this.statisticsPage           = new StatisticsPage(options);
			this.filterPage               = new FilterPage(options);
			this.personCsvPage			  = new PersonCSV(options);
			this.buyerAccessPage		  = new BuyerAccess(options);
			this.buyerRolesPage			  = new BuyerRoles(options);
			this.warrantyDataOverviewPage = new WarrantyDataOverview(options);
			this.newsPage 		 	  	  = new NewsPage(options);
			this.queueToolPage            = new QueueTool(options);

			$(document).on('pagebeforeshow', _.bind(function () {
				if (this.currentPage) {
					this.currentPage.render();
				}
				$(window).scrollTop(0);
			}, this));
			$(document).on('pageshow', function () {
				$.mobile.loading('hide');
			});

			//fix wrong footer position after mobile keyboard is hidden
			if (window.oxmobile) {
				var $body = $(document.body);
				$(document)
					.on('focus', 'input', function (e) {
						var $el = $(e.currentTarget);
						if (($el.attr('type') || 'text') === 'text') {
							$body.addClass('hideFooters');
						}
					})
					.on('blur', 'input', function (e) {
						if (!e.relatedTarget || e.relatedTarget.tagName !== 'INPUT') {
							_.defer(function () {
								window.scrollTo(document.body.scrollLeft, document.body.scrollTop);
								$body.removeClass('hideFooters');
							});
						}
					});
			}

			if (iosapp.appavailable) {
				iosapp.setOABIMCallback(function (data) {
					var obj = JSON.parse(data);
					switch (obj.command) {
						case 'markerSelected':
							var projectId = obj.output;
							window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defect/n' + projectId);
							break;
						case 'markerPlaced':
							var file = obj.modelIdentifier.file;
							delete obj.modelIdentifier.file;
							var context = obj.modelIdentifier;
							var location = obj.output.position;
							delete obj.output.position;
							var properties = obj.output;

							if (obj.modelIdentifier.originalCommand !== 'placeMarker') {
								//new defect

								this.defectPage.setNewModel();

								//set description, type status
								if (properties.description && !this.defectPage.model.get('description')) {
									this.defectPage.model.set('description', properties.description);
								}
								if (properties.type) {
									this.defectPage.model.set('type', properties.type);
								}
								if (properties.status) {
									this.defectPage.model.set('status', properties.status);
								}

								this.defectPage.model.set('attachmentSources', [ new AttachmentSource({
									index:      0,
									type:       'BIM_AC',
									file:       file,
									context:    context,
									location:   location,
									properties: properties
								}).toJSON() ]);

								//switch to defect page
								if (this.currentPage == this.defectsPage) {
									this.defectPage.previousPage = currentDefects.elementFilter.external ? 'principal' : this.currentPage.id;
								} else if (this.currentPage == this.subcontractorPage) {
									this.defectPage.previousPage = this.currentPage.id + (this.currentPage.model ? '/' + this.currentPage.model.id : '/null');
								} else if (this.currentPage == this.inspectionPage) {
									this.defectPage.previousPage = this.currentPage.id;
								}
								this.currentPage = this.defectPage;
								window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defect/c');

								this.defectPage.sketcher
									.setImage(obj.screenshot)
									.done(function () {
										this.defectPage.sketcher.selectAttachment(0);
									}.bind(this));

							} else {
								//existing defect

								//add attachment source meta data
								var defectAttachmentSources = this.defectPage.model.get('attachmentSources') || [];
								var index = this.defectPage.sketcher.getEffectiveSelectedIndex();
								defectAttachmentSources = _.filter(defectAttachmentSources, function (defectAttachmentSource) {
									return defectAttachmentSource.index !== index;
								}, this);
								defectAttachmentSources.push(new AttachmentSource({
									index:      index,
									type:       'BIM_AC',
									file:       file,
									context:    context,
									location:   location,
									properties: properties
								}).toJSON());
								this.defectPage.model.set('attachmentSources', defectAttachmentSources);

								//set screenshot
								this.defectPage.sketcher
									.setImage(obj.screenshot)
									.done(function () {

										//switch to defect page
										if (this.currentPage == this.defectsPage) {
											this.defectPage.previousPage = currentDefects.elementFilter.external ? 'principal' : this.currentPage.id;
										} else if (this.currentPage == this.subcontractorPage) {
											this.defectPage.previousPage = this.currentPage.id + (this.currentPage.model ? '/' + this.currentPage.model.id : '/null');
										} else if (this.currentPage == this.inspectionPage) {
											this.defectPage.previousPage = this.currentPage.id;
										}
										this.currentPage = this.defectPage;
										this.defectPage.sketcher.selectAttachment(index);

									}.bind(this));
							}

							break;
					}
				}.bind(this));

				iosapp.setOADigiBauDemoCallback(function (data) {
					var obj = JSON.parse(data);
					switch (obj.command) {
						case 'markerSelected':
							var projectId = obj.output;
							window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'defect/n' + projectId);
							break;
						case 'markerPlaced':
							var file = obj.modelIdentifier.file;
							delete obj.modelIdentifier.file;
							var context = obj.modelIdentifier;
							var location = obj.output.position;
							delete obj.output.position;
							var properties = obj.output;

							if (obj.modelIdentifier.originalCommand !== 'placeMarker') {
								//new defect

								this.defectPage.setNewModel();

								//set description, type status
								if (properties.description && !this.defectPage.model.get('description')) {
									this.defectPage.model.set('description', properties.description);
								}
								if (properties.type) {
									this.defectPage.model.set('type', properties.type);
								}
								if (properties.status) {
									this.defectPage.model.set('status', properties.status);
								}

								if (!obj.noAttachmentSource) {
									this.defectPage.model.set('attachmentSources', [ new AttachmentSource({
										index:      0,
										type:       'DIGIBAU_AC',
										file:       file,
										context:    context,
										location:   location,
										properties: properties
									}).toJSON() ]);
								}

								//switch to defect page
								if (this.currentPage == this.defectsPage) {
									this.defectPage.previousPage = currentDefects.elementFilter.external ? 'principal' : this.currentPage.id;
								} else if (this.currentPage == this.subcontractorPage) {
									this.defectPage.previousPage = this.currentPage.id + (this.currentPage.model ? '/' + this.currentPage.model.id : '/null');
								} else if (this.currentPage == this.inspectionPage) {
									this.defectPage.previousPage = this.currentPage.id;
								}
								this.defectPage.sketcher
									.setImage(obj.screenshot)
									.done(function () {
										this.defectPage.sketcher.selectAttachment(0);
										if (obj.annotation) {
											this.defectPage.sketcher.getCurrentAttachment().annotation = obj.annotation;
											this.defectPage.sketcher.loadAnnotation(obj.annotation);
										}
										this.currentPage = this.defectPage;
										window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defect/c');
									}.bind(this));


							} else {
								//existing defect

								//add attachment source meta data
								var defectAttachmentSources = this.defectPage.model.get('attachmentSources') || [];
								var index = this.defectPage.sketcher.getEffectiveSelectedIndex();
								defectAttachmentSources = _.filter(defectAttachmentSources, function (defectAttachmentSource) {
									return defectAttachmentSource.index !== index;
								}, this);
								defectAttachmentSources.push(new AttachmentSource({
									index:      index,
									type:       'DIGIBAU_AC',
									file:       file,
									context:    context,
									location:   location,
									properties: properties
								}).toJSON());
								this.defectPage.model.set('attachmentSources', defectAttachmentSources);

								//set screenshot
								this.defectPage.sketcher
									.setImage(obj.screenshot)
									.done(function () {

										//switch to defect page
										if (this.currentPage == this.defectsPage) {
											this.defectPage.previousPage = currentDefects.elementFilter.external ? 'principal' : this.currentPage.id;
										} else if (this.currentPage == this.subcontractorPage) {
											this.defectPage.previousPage = this.currentPage.id + (this.currentPage.model ? '/' + this.currentPage.model.id : '/null');
										} else if (this.currentPage == this.inspectionPage) {
											this.defectPage.previousPage = this.currentPage.id;
										}
										this.currentPage = this.defectPage;
										this.defectPage.sketcher.selectAttachment(index);
									}.bind(this));
							}

							break;
					}
				}.bind(this));
			}

			if (iosapp.windowsappavailable || iosapp.appavailable || iosapp.androidappavailable) {
				iosapp.setCallbackFromBaufoto(function(data) {
					$('.ui-loader').show();
					var json = JSON.parse(data);
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject()+'/defect/g' + json.defectId);
				}.bind(this));
			}

			if (iosapp.windowsappavailable || iosapp.appavailable) {
				iosapp.createDoorDefect (function (projectId, doorId, location, status, type, image, secondImage) {
					this.defectPage.confirmation().done(function () {
						this.defectPage.setNewModel();
						this.defectPage.model.set("project", projectId);
						this.defectPage.model.set("type", type);
						this.defectPage.model.set("location", JSON.parse(location));
						this.defectPage.model.set("status", status);

						var defectAttachmentSources = this.defectPage.model.get('attachmentSources') || [];
						defectAttachmentSources = _.filter(defectAttachmentSources, function (defectAttachmentSource) {
							return defectAttachmentSource.index !== index;
						}, this);
						defectAttachmentSources.push(new AttachmentSource({
							index:      0,
							type:       'AUFMASS',
							file:       null,
							context:    {},
							location:   {},
							properties: {}
						}).toJSON());
						defectAttachmentSources.push(new AttachmentSource({
							index:      1,
							type:       'AUFMASS',
							file:       null,
							context:    {},
							location:   {},
							properties: {}
						}).toJSON());

						this.defectPage.model.set('attachmentSources', defectAttachmentSources);
						this.defectPage.sketcher.selectAttachment(0);
						this.defectPage.sketcher.setImage(image).done(function () {
							this.defectPage.sketcher.selectAttachment(1);
							this.defectPage.sketcher.setImage(secondImage);
							this.currentPage = this.defectPage;
							window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject()+'/defect/c');
						}.bind(this));
					}.bind(this));
				}.bind(this));

				iosapp.addMeasurePhoto(function (projectId, image,  clientId, secondImage, state) {
					if(currentProject.id != projectId ){
						alertPopup(user.translate("aufmass.project.projectwasswitched.error"));
						return;
					}
					var defect = currentDefects.findWhere({clientId: clientId});
					this.defectPage.confirmation().done(function () {
						if (defect != null && (this.defectPage.model || clientId !== this.defectPage.model.get('clientId'))) {
							this.defectPage.setModel(defect);
						} else {
							alertPopup(user.translate("aufmass.project.projectwasswitched.error"));
							return;
						}

						this.defectPage.model.set('status', 'fixed');
						var index = this.defectPage.sketcher.getEffectiveSelectedIndex();
						var defectAttachmentSources = this.defectPage.model.get('attachmentSources') || [];
						defectAttachmentSources = _.filter(defectAttachmentSources, function (defectAttachmentSource) {
							return defectAttachmentSource.index !== index;
						}, this);
						defectAttachmentSources.push(new AttachmentSource({
							index: index,
							type: 'AUFMASS',
							file: null,
							context: {},
							location: {},
							properties: {}
						}).toJSON());

						this.defectPage.model.set('attachmentSources', defectAttachmentSources);
						//this.navigate('defect' + this.stateParams());
						$.Deferred().resolve().then(function () {
							this.defectPage.sketcher.selectAttachment(index);
							this.defectPage.sketcher.setImage(image).done(function () {
								if(index < 3) {
									index = this.defectPage.sketcher.getEffectiveSelectedIndex();
									defectAttachmentSources.push(new AttachmentSource({
										index: index,
										type: 'AUFMASS',
										file: null,
										context: {},
										location: {},
										properties: {}
									}).toJSON());
									this.defectPage.sketcher.selectAttachment(index);
									this.defectPage.sketcher.setImage(secondImage);
								}
							}.bind(this));
						}.bind(this)).done(function () {
							this.defectPage.renderStatus();
							if(state === "NOT_OK") {

								window.confirmPopup(user.translate("defect.create.notok"))
									.then(function () {
										this.defectPage.saveAndNew();
									}.bind(this))
									.done(function () {
										this.defectPage.notOkDefect(defect,image,secondImage);
									}.bind(this));
							}
						}.bind(this));
					}.bind(this));
				}.bind(this));

				iosapp.showDoorDefects (function (projectId, doorId) {
					this.defectPage.confirmation().done(function(){
						currentDefects.elementFilter.search = doorId;
						this.defectsPage.filterMode = "search";
						this.defectsPage.$defectSearchFilter.val(doorId);
						this.currentPage = this.defectPage;
						window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject()+'/defects');
					}.bind(this));
				}.bind(this));
			}

			$(window).on('storage', this.handleStorageEvent.bind(this));

			iosapp.onloadevent('routerinit');

			// shortcuts.initialize(options);
			rocketModeHelper.initialize();
			realWorldPlanTransformationHelper.initialize();

			this.currentProject = currentProject;
			this.currentInspection = currentInspection;
			this.attachPagesAndFunctionsOnWindow();
		},

		show: function (page) {
			if(!!this.currentPage) {
				this.currentPage.$el.removeClass('hide-page');
			}
			if (page == this.currentPage) {
				this.currentPage.render();
				$.mobile.loading('hide');
				return;
			}
			this.currentPage = page;
			$.mobile.pageContainer.pagecontainer('change', page.$el, {
				transition: 'none', //previousPage ? 'slide' : 'fade',
				reverse: false
			});
		},

		queueTool: function () {
			currentInspection.clear();
			this.show(this.queueToolPage);
		},

		inspections: function () {
			currentInspection.clear();
			this.inspectionPageNew.titleModified = false;
			this.show(this.inspectionsPage);
		},

		inspection: function (inspection, isEdit) {
			this.inspectionPage.mode = 'defects';
			if (!currentDefects.elementFilter.inspection) {
				currentDefects.resetFilter();
				currentDefects.elementFilter.inspection = inspection ? inspection : (currentInspection && currentInspection.id || null);
			}
			currentDefects.elementFilter.external = user.isPrincipal();

			if (currentInspection.isNew() || isEdit) {
				if (this.currentPage == this.inspectionPage
					|| this.currentPage == this.inspectionsPage
				) {
					this.inspectionPageNew.previousPage = this.currentPage.id;
				}
				this.show(this.inspectionPageNew);
			} else {
				this.show(this.inspectionPage);
			}
		},

		defect: function (prefixId, inspection, params) {
			if (this.currentPage == this.defectsPage) {
				this.defectPage.previousPage = currentDefects.elementFilter.external ? 'principal' : this.currentPage.id;
			} else if (this.currentPage == this.subcontractorPage) {
				this.defectPage.previousPage = this.currentPage.id + (this.currentPage.model ? '/' + this.currentPage.model.id : '/null');
			} else if (this.currentPage == this.inspectionPage) {
				this.defectPage.previousPage = this.currentPage.id;
			}
			var firstChar = prefixId && prefixId.toLowerCase().substr(0, 1);

			if (firstChar === 'n') {
				return this.defectinternal(prefixId.substr(1), 'n', inspection);
			} else if (firstChar === 'g') {
				return this.defectinternal(prefixId.substr(1), 'g', inspection);
			} else if (firstChar === 'c') {
				var alternateDefectType = prefixId.substr(1);
				if(alternateDefectType && alternateDefectType.length > 0) {
					this.defectPage.setNewModel(alternateDefectType);
				} else {
					this.defectPage.setNewModel();
					if(!!params){
						this.defectPage.createDefectWithParams(params);
					}
				}
				this.show(this.defectPage);
			} else {
				window.navigateCallback(`${backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()}/defects`);
			}

		},
		defectinternal: function (defectId, type, inspection) {
			if (defectId) {
				defectId = parseInt(defectId);

				var model;
				if (type == 'n') {
					model = currentDefects.get(defectId);
				} else if (type == 'g') {
					model = currentDefects.filter(function(d) { return d.get('id') === defectId }).pop();
				}
				var tryToLoadAttachments = $.Deferred();
				if (!model || model.has('attachments')) {
					tryToLoadAttachments.reject();
				} else {
					if (!watchdog.isConnected() && !!model && model.has('id') && iosapp.appavailable && iosapp.functionavailable('attachments')) {
						iosapp.getattachment(model.get('id'), model.get('amod'), function(att) {
							if (att) {
								var indexdefined = false;
								_.each(att, function(i) {
									if (typeof i.index !== 'undefined') {
										indexdefined = true;
									}
								})
								if (!indexdefined) {
									_.each(att, function(i, idx) {
										i.index = idx;
									})
								}
							}
							model.set('attachments', att, { silent: true });
							tryToLoadAttachments.resolve();
						});
					} else {
						tryToLoadAttachments = model.fetch({ noExtensions: true });
					}
				}
				tryToLoadAttachments.done(_.bind(function () {
					//unload previously loaded attachments
					if (!window.attachmentDefects) {
						window.attachmentDefects = [];
					}
					window.attachmentDefects.push(model);
					while (window.attachmentDefects.length > 20) {
						var oldDefect = window.attachmentDefects.splice(0, 1)[0];
						oldDefect.unset('attachments');
					}
				}, this));
				tryToLoadAttachments.always(_.bind(function () {
					if (model) {
						this.defectPage.setModel(model, model.get('projectId'));
						this.show(this.defectPage);
					} else {
						window.navigateCallback(`${backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()}/defects`);
					}
				}, this));
			} else {
				window.navigateCallback(`${backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()}/defects`)
			}
		},


		defects: function (inspection, linkDefectsParams) {
			if (this.currentPage === this.inspectionPage
				|| this.currentPage === this.inspectionPageNew
				|| this.currentPage === this.inspectionsPage
				|| this.currentPage === this.locationPage
				|| this.currentPage === this.subcontractorsPage
			) {
				this.defectsPage.previousPage = this.currentPage.id;
			}
			if (!_.isNull(inspection)) {
				currentDefects.elementFilter.inspection = inspection;
			}
			currentDefects.elementFilter.external = user.isPrincipal();
			currentDefects.elementFilter.hideDefectAttachmentSources = !!linkDefectsParams || currentDefects.elementFilter.hideDefectAttachmentSources;
			currentDefects.elementFilter.unit = (!!linkDefectsParams && linkDefectsParams.locationId) || currentDefects.elementFilter.unit;
			var date = ((!!linkDefectsParams && linkDefectsParams.reportDate) ? new Date(linkDefectsParams.reportDate) : null);
			var creationDateFilter = this.defectsPage.correctDates({from: date, to: date});
			currentDefects.elementFilter.creationDateFilter = creationDateFilter || currentDefects.elementFilter.creationDateFilter;
			this.defectsPage.linkDefectsParams = linkDefectsParams;
			this.show(this.defectsPage);
		},

		hide: function () {
			if(!!this.currentPage) {
				this.currentPage.$el.addClass('hide-page');
			}

		},

		attachPagesAndFunctionsOnWindow: function () {
			window.renderPage =  _.bind(function () {
				if (this.currentPage) {
					this.currentPage.render();
				}
				$(window).scrollTop(0);
			}, this)
			window.showPage = _.bind(this.show, this);
			window.hidePage = _.bind(this.hide, this);
			window.queueToolUseEffect = _.bind(this.queueTool, this);
			window.inspectionsUseEffect = _.bind(this.inspections, this);
			window.inspectionUseEffect = _.bind(this.inspection, this);
			window.defectUseEffect = _.bind(this.defect, this);
			window.defectsUseEffect = _.bind(this.defects, this);
			window.locationUseEffect = _.bind(this.location, this);
			window.filesUseEffect = _.bind(this.files, this);
			window.imageUseEffect = _.bind(this.image, this);
			window.documentsUseEffect = _.bind(this.documents, this);
			window.requestBundleUseEffect = _.bind(this.requestBundle, this);
			window.subcontractorsUseEffect = _.bind(this.subcontractors, this);
			window.subcontractorUseEffect = _.bind(this.subcontractor, this);
			window.subcontractorBundleUseEffect = _.bind(this.subcontractorBundle, this);
			window.costUseEffect = _.bind(this.cost, this);
			window.selectInspectionUseEffect = _.bind(this.selectInspection, this);
			window.principalUseEffect = _.bind(this.principal, this);
			window.principalBundleUseEffect = _.bind(this.principalBundle, this);
			window.instantMessageUseEffect = _.bind(this.instant, this);
			window.paperworkUseEffect = _.bind(this.paperwork, this);
			window.statisticsUseEffect = _.bind(this.statistics, this);
			window.filterUseEffect = _.bind(this.filter, this);
			window.personCSVUseEffect = _.bind(this.personcsv, this);
			window.buyerAccessUseEffect = _.bind(this.buyeraccess, this);
			window.buyerRolesUseEffect = _.bind(this.buyerroles, this);
			window.warrantyDataOverviewUseEffect = _.bind(this.warrantyDataOverview, this);
			window.testSettingsUseEffect = _.bind(this.settings, this);
			window.imagePage = this.imagePage;
		},


		registerBackboneModelChangedListener: function () {
			this.listenTo(user, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(user, backboneReactCommunicationUtils.user);
			});
			this.listenTo(proxyInfo, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(proxyInfo, backboneReactCommunicationUtils.proxyInfo);
			});
			this.listenTo(ma_accounts, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(ma_accounts, backboneReactCommunicationUtils.ma_accounts);
			});
			this.listenTo(projects, 'reset', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(projects, backboneReactCommunicationUtils.projects);
			});
			this.listenTo(projects, 'update', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(projects, backboneReactCommunicationUtils.projects);
			});
			this.listenTo(currentProject, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(currentProject, backboneReactCommunicationUtils.currentProject);
			});
			this.listenTo(accessibleAccounts, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(accessibleAccounts, backboneReactCommunicationUtils.accessibleAccounts);
			});
			this.listenTo(help, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(help, backboneReactCommunicationUtils.help);
			});
			this.listenTo(inspections, 'reset', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(inspections, backboneReactCommunicationUtils.inspections);
			});
			this.listenTo(inspections, 'update', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(inspections, backboneReactCommunicationUtils.inspections);
			});
			this.listenTo(subcontractors, 'reset', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(subcontractors, backboneReactCommunicationUtils.subcontractors);
			});
			this.listenTo(subcontractors, 'update', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(subcontractors, backboneReactCommunicationUtils.subcontractors);
			});
			this.listenTo(polygonsAndCfgs, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(polygonsAndCfgs, backboneReactCommunicationUtils.polygonsAndCfgs);
			});
			this.listenTo(realWorldPlanTransformations, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(realWorldPlanTransformations, backboneReactCommunicationUtils.realWorldPlanTransformations);
			});
			this.listenTo(recordPoints, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(recordPoints, backboneReactCommunicationUtils.recordPoints);
			});
			this.listenTo(currentDefects, 'reset', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(currentDefects, backboneReactCommunicationUtils.currentDefects);
			});
			this.listenTo(currentDefects, 'update', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(currentDefects, backboneReactCommunicationUtils.currentDefects);
			});
			this.listenTo(completions, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(completions, backboneReactCommunicationUtils.completions);
			});
			this.listenTo(projectFiles, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(projectFiles, backboneReactCommunicationUtils.projectFiles);
			});
			this.listenTo(currentInspection, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(currentInspection, backboneReactCommunicationUtils.currentInspection);
			});
			this.listenTo(currentStats, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(currentStats, backboneReactCommunicationUtils.currentStats);
			});
			this.listenTo(participants, 'reset', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(participants, backboneReactCommunicationUtils.participants);
			});
			this.listenTo(participants, 'update', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(participants, backboneReactCommunicationUtils.participants);
			});
			this.listenTo(attachmentSources, 'reset', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(attachmentSources, backboneReactCommunicationUtils.attachmentSources);
			});
			this.listenTo(attachmentSources, 'update', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(attachmentSources, backboneReactCommunicationUtils.attachmentSources);
			});
			this.listenTo(projectFileSources, 'reset', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(projectFileSources, backboneReactCommunicationUtils.projectFileSources);
			});
			this.listenTo(projectFileSources, 'update', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(projectFileSources, backboneReactCommunicationUtils.projectFileSources);
			});
			this.listenTo(watchdog, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(watchdog, backboneReactCommunicationUtils.watchdog);
			});
			this.listenTo(offlineQueue, 'change', function () {
				backboneReactCommunicationUtils.saveBackboneDataInReact(offlineQueue, backboneReactCommunicationUtils.offlineQueue);
			});
		},

		handleStorageEvent: function (event) {
			var oe = event.originalEvent;
			if (!oe.isTrusted) {
				return;
			}
			switch(oe.key) {
				case 'attachDocument':
					var data;
					try {
						data = JSON.parse(oe.newValue);
					} catch (e) {
						// ignored if not parsable
					}
					if (!data || !data.targetedWindow || data.targetedWindow !== window.name|| !data.content) return;
					this.triggerAttachDocumentEvent(data.content);
					break;
				case 'chosenPhotoFD':
					if (oe.value !== null) {
						var photo;
						try {
							photo = JSON.parse(oe.newValue);
						} catch (e) {
							// ignored if not parsable
						}
						if (photo) {
							Sketcher.vent.trigger('chosenPhotoFD', photo);
						}
					}
					break;
			}
		},

		triggerAttachDocumentEvent: function (content) {
			if (this.currentPage !== this.inspectionPage) return;
			this.currentPage.trigger('attachDocumentFromBaufoto', content);
			window.localStorage.removeItem("attachDocument");
		},

		createDefectInternal: function(params) {
			//return false: re-execute the command after reloading the page
			//return true: command was accepted/executed

			if (params.user) {
				if (params.user.toLowerCase() !== user.get('experienceId')) {
					alertPopup('In der Quell-App ist ein anderer Benutzer angemeldet. Für diese Funktion muss in beiden Apps der gleiche Benutzer angemeldet sein.');
					return;
				}
			}

			if (params.projectId && !params.project) {
				//fix for consistency with showDefect
				params.project = params.projectId;
			}

			if (!params.customer || !params.project) {
				console.log('illegal parameters for createDefect, ignoring');
				return;
			}

			if (!(user && user.get('customer') === params.customer)) {
				//
				this.chooseCustomer();
			} else {
				//
				this.entry();
			}

		},
		triggerFiles: function (project, inspection, defectMode, buyerMode) {
			if (this.currentPage != this.imagePage) {
				this.filesPage.previousPage = this.currentPage.id;
			}
			this.filesPage.defectMode = !!defectMode;
			this.filesPage.manageable = !!defectMode;
			this.filesPage.manage = (!!defectMode || !!buyerMode) && !window.oxmobile && !(!user.isSubcontractor() && user.isReadonly()); //subcontractor is also readonly, but allowed to upload files
			if (!defectMode && !buyerMode) { //otherwise model is already set by defect page
				this.filesPage.model = projectFiles;
			}

			this.imagePage.setSelectionMode(!defectMode && !buyerMode);
			this.imagePage.previousPage = this.filesPage.id;
			window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/files', {directlyShowPage: true});
		},

		location: function () {
			this.show(this.locationPage);
		},
		files: function (directlyShowPage) {
			if(!directlyShowPage) {
				this.filesPage.previousPage = 'overview';
				this.filesPage.manage = false;
				this.filesPage.manageable = true;
				this.filesPage.model = projectFiles;
				this.filesPage.defectMode = false;
				this.imagePage.setSelectionMode(false);
			}
			this.show(this.filesPage);
		},

		image: function (pageId) {
			if ( !pageId &&
				this.currentPage != this.filesPage
				&& this.currentPage != this.subcontractorBundlePage
				&& this.currentPage != this.documentsPage
				&& this.currentPage != this.paperworkPage
				&& this.currentPage != this.inspectionPage
				&& this.currentPage != this.principalBundlePage
				&& this.currentPage != this.defectPage
				&& !(!!this.currentPage && this.currentPage.id === 'instant' && this.imagePage.defectGoToPlanRocketMode === true)
			) {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/files');
				return;
			}
			if (((this.currentPage && this.currentPage.id === 'instant') || (this.currentPage && this.currentPage.previousPage === 'instant')) && this.imagePage.defectGoToPlanRocketMode === true) {
				// rocket mode from instant page
				this.imagePage.previousPage = 'instant';
			} else {
				this.imagePage.previousPage = pageId || this.currentPage.id;
			}

			if (this.currentPage == this.subcontractorBundlePage) {
				this.imagePage.previousPageModelId = this.currentPage.model.id;
			}
			this.show(this.imagePage);
		},

		documents: function () {
			$.mobile.loading('show');
			this.documentsPage.manageable   = true;
			this.documentsPage.model        = projectFiles;
			this.documentsPage.defectMode = false;
			this.imagePage.setSelectionMode(false);
			this.documentsPage.documentsPaperwork = new DocumentsPaperwork();
			var chain = this.documentsPage.documentsPaperwork.fetch();
			chain = chain.then(_.bind(this.documentsPage.trackedPaperworkInfo.fetch, this.documentsPage.trackedPaperworkInfo))
			chain.then(_.bind(function () {
				$.mobile.loading('hide');
				this.show(this.documentsPage);
			}, this), this.recover);
		},

		requestBundle: function () {
			this.requestBundlePage.requestBundle()
				.then(_.bind(function () {
					$.mobile.loading('hide');
					this.show(this.requestBundlePage);
				}, this), this.recover)

		},
		subcontractors: function (requestBundle) {
			this.show(this.subcontractorsPage);
			if(requestBundle) {
				$.mobile.loading('show');
				this.requestBundlePage.setModel(null);
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/requestBundle');
			}
		},
		subcontractor: function (id) {
			if (this.currentPage && this.currentPage != this.defectPage) {
				this.subcontractorPage.previousPage = this.currentPage.id;
			}
			if (id) {
				this.subcontractorPage.orderBy = 'smod';
				this.subcontractorPage.groupBy = '';

				if (this.subcontractorPage.previousPage === 'subcontractors' && this.currentPage.id !== 'defect') {
					currentDefects.resetFilter();
					currentDefects.elementFilter.subcontractor = id;
					currentDefects.elementFilter.status = id === 'null' ? null : 'pending';
				}
				if (id === 'null') {
					this.subcontractorPage.setModel(null);
				} else {
					this.subcontractorPage.setModel(subcontractors.get(id));
				}
				this.subcontractorPage.currentSubcontractorId = id;
				this.show(this.subcontractorPage);
			} else {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/subcontractors');
			}
		},
		subcontractorBundle: function (id) {
			$.mobile.loading('show');
			this.subcontractorBundlePage.subcontractorPaperwork.set('subcontractor', id);
			this.subcontractorBundlePage.trackedPaperworkInfo.set('subcontractor', id);
			var chain = $.Deferred().resolve();
			if (proxyInfo.get('proxyMode')) {
				//no loading
			} else {
				chain = chain.then(_.bind(this.subcontractorBundlePage.subcontractorPaperwork.fetch, this.subcontractorBundlePage.subcontractorPaperwork))
				chain = chain.then(_.bind(this.subcontractorBundlePage.trackedPaperworkInfo.fetch, this.subcontractorBundlePage.trackedPaperworkInfo))
			}
			chain.then(_.bind(function () {
				if (id || proxyInfo.get('proxyMode')) {
					this.subcontractorBundlePage.setModel(subcontractors.get(id));
					$.mobile.loading('hide');
					this.show(this.subcontractorBundlePage);
				} else {
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/subcontractors');
				}
			}, this), this.recover);
		},
		cost: function () {
			this.show(this.costPage);
		},
		selectInspection: function () {
			this.show(this.selectInspectionPage);
		},
		principal: function () {
			if (this.currentPage == this.principalBundlePage) {
				this.defectsPage.previousPage = this.currentPage.id;
			}
			if (this.currentPage !== this.defectPage && this.currentPage !== this.filterPage) {
				currentDefects.resetFilter();
				currentDefects.elementFilter.external = true;
				this.defectsPage.orderBy = 'pmod';
				this.defectsPage.groupBy = '';
			}
			var newDefects = currentProject.get('newDefectsPrincipal');
			if (!user.isPrincipal() && !user.isSubcontractor() && newDefects > 0) {
				currentDefects.elementFilter.status = 'all-principal-rejected';
				this.defectsPage.$el.one('pageshow', _.bind(function () {
					this.defectsPage.filter();
					window.alertPopup(user.translate('notification.popup.principal').replace('%d', newDefects));
				}, this));

				//update impression indicator
				$.ajax({
					type: 'POST',
					url:  '/onlineBauabnahme/api/principalImpression',
					global: false,
					data: { project: currentProject.id }
				});
				currentProject.set('newDefectsPrincipal', 0);
			}
			this.show(this.defectsPage);
		},
		principalBundle: function () {
			this.principalBundlePage.principalPaperwork.fetch()
				.then(_.bind(function () {
					this.show(this.principalBundlePage);
				}, this), this.recover);
		},
		instant: function () {
			this.show(this.instantMessagePage);
		},
		paperwork: function () {
			if (this.currentPage == this.subcontractorBundlePage) {
				this.paperworkPage.previousPage = this.currentPage.id;
			}

			$.Deferred().resolve()
				.then(_.bind(paperwork.fetch, paperwork))
				.then(_.bind(function () {
					this.imagePage.setSelectionMode(false);
					this.show(this.paperworkPage, false);
				}, this), this.recover);
		},
		statistics: function () {
			$.Deferred().resolve()
				.then(function () {
					return currentStats.getStats();
				})
				.then(_.bind(function () {
					this.show(this.statisticsPage);
				}, this), this.recover);
		},
		filter: function () {
			this.filterPage.previousPage = currentDefects.elementFilter.external ? 'principal' : (this.currentPage ? this.currentPage.id : 'defects');
			this.show(this.filterPage);
		},
		personcsv: function (project) {
			$.mobile.loading('show');
			$.Deferred().resolve()
				.then(_.bind(function () {
					persons.setProject(project).then(_.bind(function () {
						this.show(this.personCsvPage);
					}, this), this.recover);
				}, this))

		},
		buyeraccess: function (project) {
			$.Deferred().resolve()
				.then(_.bind(function () {
					return $.when(
						buyerAccesses.setProject(project),
						buyerRoles.setProject(project)
					);
				}, this))
				.then(_.bind(function () {
					this.show(this.buyerAccessPage);
				}, this), this.recover);
		},
		buyerroles: function (project) {
			$.Deferred().resolve()
				.then(_.bind(function () {
					return $.when(
						buyerAccesses.setProject(project),
						buyerRoles.setProject(project)
					);
				}, this))
				.then(_.bind(function () {
					this.show(this.buyerRolesPage);
				}, this), this.recover);
		},
		warrantyDataOverview: function () {
			$.Deferred().resolve()
				.then(_.bind(function () {
					this.show(this.warrantyDataOverviewPage);
				}, this), this.recover);
		},
		settings: function () {
			this.show(this.settingsPage, false);
		},
		recover: function () {
			$.mobile.loading('hide');
			window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection());
		}

	});
	return Backbone.Router.extend({

		queueTool: function() {
			this.navigate('queuetool');
			this.show(this.queueToolPage);
		},

	});
});
