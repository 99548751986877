define([
    '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './ChooseCustomerPageHtml', '../view/ShortcutBaseView',
	'../model/user', '../model/accessibleAccounts',
    '../helper/iosapp', '../helper/externalParameters'
], function (
    $, _, Backbone, Template, ShortcutBaseView,
    user, accessibleAccounts, iosapp, externalParameters,
    router
) {
    return ShortcutBaseView.extend({

        id: 'chooseCustomer',

        attributes: {
            'data-role': 'page'
        },

        events: {
          'click .content li': function (e) {
              // this.chooseCustomerClicked(e.target);
          },
		  'click .logout': function() {
          	  // window.location.href =  '/logout';
		  }
        },
	
		shortcuts: {
			'num': function (num) {
				// var e = $(this.$list.children().get(num)).find('a');
				// this.chooseCustomer(e);
			}
		},
		
		chooseCustomerClicked: function (e) {
			// var account = $(e).data('account');
			// this.chooseCustomer(account);
		},

		chooseCustomer: function(account) {
			// sessionStorage && sessionStorage.setItem('oa-customer', account.id);
			// $.post( '/api/myaccount/oa/' + encodeURIComponent(account.id))
			// 	.then(function () {
			// 		// update user
			// 		user.fetch()
			// 			.then(function () {
			// 				$('body').data('cancelbeforeunload', true);
			// 				if (this.loadAfter) {
			// 					Backbone.history.navigate(this.loadAfter);
			// 				} else {
			// 					Backbone.history.navigate('');
			// 				}
			// 				if (externalParameters.isShowDefect()) {
			// 					iosapp.sessionstoreSet('showdefect', JSON.stringify(externalParameters.getShowDefectParams()));
			// 				} else if (externalParameters.isCreateDefect()) {
			// 					iosapp.sessionstoreSet('createdefect', JSON.stringify(externalParameters.getCreateDefectParams()));
			// 				}
			// 				location.reload();
			// 			}.bind(this));
			// 	}.bind(this));
		},



        template: _.template(Template),


        initialize: function (options) {
	        // this.router = options.router;
            // this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
	        // this.$list = this.$('ul');
	        // this.$autowait = this.$('.auto-choose-message');
	        // this.$listlabel = this.$('.listlabel');
	        // this.$autoerror = this.$('.auto-choose-notfound');
        },

        render: function () {
	        // this.$('.content').empty();
			// if (externalParameters.isForceCustomerProject()) {
			// 	this.$listlabel.hide();
			// 	this.$autowait.show();
			// 	this.$autoerror.hide();
			//
			// 	var forcedCustomerId = externalParameters.getForcedCustomer();
			// 	var forcedCustomer = _.find(accessibleAccounts.get('accounts'), function (account) {
			// 		return (account.id === forcedCustomerId);
			// 	});
			//
			// 	if (forcedCustomer) {
			// 		this.chooseCustomer(forcedCustomer);
			// 	} else {
			// 		this.$autowait.hide();
			// 		this.$autoerror.show();
			// 	}
			// }
			// else if (externalParameters.isCreateDefect() || externalParameters.isShowDefect()) {
			// 	var params = externalParameters.getCreateDefectParams() || externalParameters.getShowDefectParams();
			// 	var customer = _.findWhere(accessibleAccounts.get('accounts'), { id: params.customer });
			// 	if (customer) {
			// 		this.chooseCustomer(customer);
			// 	} else {
			// 		alertPopup("Der Mandant konnte nicht gefunden werden, bitte stellen Sie sicher, dass die Zugriffsrechte korrekt konfiguriert sind.")
			// 			.then(function() {
			// 				externalParameters.setCreateDefectParams(null);
			// 				externalParameters.setShowDefectParams(null);
			// 				window.location.reload();
			// 			});
			// 	}
			// }
			// else if (sessionStorage && sessionStorage.getItem('oa-customer')) {
			// 	//this must be AFTER create defect in the if cascade!
			// 	var id = sessionStorage.getItem('oa-customer');
			// 	var account = _.find(accessibleAccounts.get('accounts'), function (account) {
			// 		return (account.id === id);
			// 	});
			// 	if (account) {
			// 		this.chooseCustomer(account);
			// 	} else {
			// 		sessionStorage.removeItem('oa-customer');
			// 		this.render();
			// 	}
			// }
			// else {
			// 	this.$listlabel.show();
			// 	this.$autowait.hide();
			// 	this.$autoerror.hide();
			// 	this.$('.content').append(_.map(accessibleAccounts.get('accounts'), function (account) {
			// 		return $('<li>').append($('<a href="javascript:void(0)">').text(account.label).data('account', account));
			// 	}));
			// 	this.$list.listview('refresh');
			// }
			//
	        // return this;
        }

    });
});
