
export const ElementFilter = {
    status_subcontractorPending: "pending-subcontractor-pending",
    status_pending: "pending",
    status_fixed: "fixed",
    extraStatus_P: "P",
    escalation_one: "one",
    escalation_two: "two",
    escalation_three: "three",
    escalation_terminationReady: "readyfortermination",
    deadline_expired: "expired",
    principalStatus_rejected: "rejected",
}