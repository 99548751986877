define([
	'../lib/backbone-1.1.2', '../model/currentProject', '../model/user',
	'../helper/interactiveSync'
], function (
	Backbone, currentProject, user,
	interactiveSync
) {
	return Backbone.Model.extend({

		defaults: function () {
			return {
				// folder: {
				// 	children: []
				// }
			}
		},

		urlRoot: function () {
			 return  '/onlineBauabnahme/api/documentsPaperworkInfo?project=' + window.encodeURIComponent(currentProject.id) + '&isSubcontractor=' + user.isSubcontractor() + '&isPrincipal=' + user.isPrincipal();
		},

		sync: function () {
			return interactiveSync.sync.apply(interactiveSync, arguments);
		}

	});
});