import styled from '@emotion/styled';

export const CenterContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const FullPage = styled.div`
    background-color: lightgray;
    width: calc(100vw);
    height: calc(100vh);
`;