define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id=\'defects-page-header\'>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"popup\" class=\"actionsMenu\">\n" +
        "\t<ul data-role=\"listview\" style=\"font-size: 14px; width: 300px\">\n" +
        "\t\t<li><a class=\"reloadlist darkicon ui-icon-refresh ui-btn ui-btn-icon-left ui-icon-carat-r\"><%= t(\'defects.menu.reloadlist\') %></a></li>\n" +
        "\t\t<li data-role=\"list-divider\"><%= t(\'defects.menu.selection\') %></li>\n" +
        "\t\t<li><a class=\"selectAll darkicon\"></a></li>\n" +
        "\t\t<li><a class=\"adjustColumns darkicon ui-icon-gear\"><%= t(\'defects.menu.metadata.settings\') %></a></li>\n" +
        "\t\t<li><a class=\"fixChosen darkicon\"><%= t(\'defects.menu.fixchosen\') %></a></li>\n" +
        "\t\t<!--<li><a class=\"changeSelectedDefects darkicon\"><%= t(\'defects.menu.changeselecteddefects\') %></a></li>-->\n" +
        "\t\t<!--<li><a class=\"sendChosen darkicon\"><%= t(\'defects.menu.sendchosen\') %></a></li>-->\n" +
        "\t\t<!--\t\t\t<li><a class=\"assign darkicon\"><%= t(\'defects.menu.assign\') %></a></li>\n" +
        "                    <li><a class=\"unassign darkicon\"><%= t(\'defects.menu.unassign\') %></a></li>-->\n" +
        "\t\t<li data-role=\"list-divider\" class=\"divider1\"><%= t(\'defects.menu.orderby\') %></li>\n" +
        "\t\t<li class=\"twocolumns\">\n" +
        "\t\t\t<div>\n" +
        "\t\t\t\t<input type=\"radio\" name=\"orderBy\" class=\"orderBy\" value=\"numberdesc\" id=\"defects-orderBy-numberdesc\"/>\n" +
        "\t\t\t\t<label for=\"defects-orderBy-numberdesc\"><%- t(\'defects.menu.orderbynumberdesc\') %></label>\n" +
        "\t\t\t</div>\n" +
        "\t\t\t<div>\n" +
        "\t\t\t\t<input type=\"radio\" name=\"orderBy\" class=\"orderBy\" value=\"numberasc\" id=\"defects-orderBy-numberasc\"/>\n" +
        "\t\t\t\t<label for=\"defects-orderBy-numberasc\"><%- t(\'defects.menu.orderbynumberasc\') %></label>\n" +
        "\t\t\t</div>\n" +
        "\t\t</li>\n" +
        "\t\t<li class=\"twocolumns\">\n" +
        "\t\t\t<div>\n" +
        "\t\t\t\t<input type=\"radio\" name=\"orderBy\" class=\"orderBy\" value=\"created\" id=\"defects-orderBy-created\"/>\n" +
        "\t\t\t\t<label for=\"defects-orderBy-created\"><%- t(\'defects.menu.orderbycreated\') %></label>\n" +
        "\t\t\t</div>\n" +
        "\t\t\t<div>\n" +
        "\t\t\t\t<input type=\"radio\" name=\"orderBy\" class=\"orderBy\" value=\"cost\" id=\"defects-orderBy-cost\"/>\n" +
        "\t\t\t\t<label for=\"defects-orderBy-cost\" class=\"defects-order-by-cost-label\"><%- t(\'defects.menu.orderbycost\') %></label>\n" +
        "\t\t\t</div>\n" +
        "\t\t</li>\n" +
        "\t\t<!--\n" +
        "                    <li class=\"twocolumns\">\n" +
        "                        <div>\n" +
        "                            <input type=\"radio\" name=\"orderBy\" class=\"orderBy\" value=\"smod\" id=\"defects-orderBy-smod\"/>\n" +
        "                            <label for=\"defects-orderBy-smod\"><%- t(\'defects.menu.orderbysmod\') %></label>\n" +
        "                        </div>\n" +
        "                        <div>\n" +
        "                            <input type=\"radio\" name=\"orderBy\" class=\"orderBy\" value=\"pmod\" id=\"defects-orderBy-pmod\"/>\n" +
        "                            <label for=\"defects-orderBy-pmod\"><%- t(\'defects.menu.orderbypmod\') %></label>\n" +
        "                        </div>\n" +
        "                    </li>\n" +
        "        -->\n" +
        "\t\t<li data-role=\"list-divider\" class=\"divider1\"><%= t(\'defects.menu.groupby\') %></li>\n" +
        "\t\t<li class=\"twocolumns\">\n" +
        "\t\t\t<div>\n" +
        "\t\t\t\t<input type=\"checkbox\" name=\"groupBy\" class=\"groupBy\" value=\"location\" id=\"defects-groupBy-location\"/>\n" +
        "\t\t\t\t<label for=\"defects-groupBy-location\"><%- t(\'defects.menu.groupbylocation\') %></label>\n" +
        "\t\t\t</div>\n" +
        "\t\t\t<div>\n" +
        "\t\t\t\t<input type=\"checkbox\" name=\"groupBy\" class=\"groupBy\" value=\"subcontractor\" id=\"defects-groupBy-subcontractor\"/>\n" +
        "\t\t\t\t<label for=\"defects-groupBy-subcontractor\"><%- t(\'defects.menu.groupbysubcontractor\') %></label>\n" +
        "\t\t\t</div>\n" +
        "\t\t</li>\n" +
        "\t\t<li class=\"twocolumns\">\n" +
        "\t\t\t<div>\n" +
        "\t\t\t\t<input type=\"checkbox\" name=\"groupBy\" class=\"groupBy\" value=\"status\" id=\"defects-groupBy-status\"/>\n" +
        "\t\t\t\t<label for=\"defects-groupBy-status\"><%- t(\'defects.menu.groupbystatus\') %></label>\n" +
        "\t\t\t</div>\n" +
        "\t\t\t<div>\n" +
        "\t\t\t\t<input type=\"checkbox\" name=\"groupBy\" class=\"groupBy\" value=\"deadline\" id=\"defects-groupBy-deadline\"/>\n" +
        "\t\t\t\t<label for=\"defects-groupBy-deadline\"><%- t(\'defects.menu.groupbydeadline\') %></label>\n" +
        "\t\t\t</div>\n" +
        "\t\t</li>\n" +
        "\t\t<li class=\"twocolumns\">\n" +
        "\t\t\t<div>\n" +
        "\t\t\t\t<input type=\"checkbox\" name=\"groupBy\" class=\"groupBy\" value=\"smod\" id=\"defects-groupBy-smod\"/>\n" +
        "\t\t\t\t<label for=\"defects-groupBy-smod\"><%- t(\'defects.menu.groupbysmod\') %></label>\n" +
        "\t\t\t</div>\n" +
        "\t\t\t<div>\n" +
        "\t\t\t\t<input type=\"checkbox\" name=\"groupBy\" class=\"groupBy\" value=\"pmod\" id=\"defects-groupBy-pmod\"/>\n" +
        "\t\t\t\t<label for=\"defects-groupBy-pmod\"><%- t(\'defects.menu.groupbypmod\') %></label>\n" +
        "\t\t\t</div>\n" +
        "\t\t</li>\n" +
        "\t\t<li class=\"twocolumns\">\n" +
        "\t\t\t<div>\n" +
        "\t\t\t\t<input type=\"checkbox\" name=\"groupBy\" class=\"groupBy\" value=\"topics\" id=\"defects-groupBy-topics\"/>\n" +
        "\t\t\t\t<label for=\"defects-groupBy-topics\"><%- t(\'defects.menu.groupbytopics\') %></label>\n" +
        "\t\t\t</div>\n" +
        "\t\t\t<div>\n" +
        "\t\t\t\t<input type=\"checkbox\" name=\"groupBy\" class=\"groupBy\" value=\"creationdate\" id=\"defects-groupBy-creationdate\"/>\n" +
        "\t\t\t\t<label for=\"defects-groupBy-creationdate\"><%- t(\'defects.menu.groupbycreationdate\') %></label>\n" +
        "\t\t\t</div>\n" +
        "\t\t</li>\n" +
        "\t\t<li data-role=\"list-divider\"><%= t(\'defects.menu.constraints\') %></li>\n" +
        "\t\t<li class=\"extended-filter\">\n" +
        "\t\t\t<a class=\'extended-filter-trigger\'><%= t(\'defects.menu.extendedfilter\') %></a>\n" +
        "\t\t</li>\n" +
        "\t\t<!--\t\t\t<li data-role=\"list-divider\" class=\"downloadheading\"><%= t(\'defects.menu.download\') %></li>\n" +
        "                    <li class=\"threecolumns\">\n" +
        "                        <div><button class=\"ui-btn download-pdf\"><%= t(\'defects.menu.downloadpdf\') %></button></div>\n" +
        "                        <div><button class=\"ui-btn download-excel\"><%= t(\'defects.menu.downloadexcel\') %></button></div>\n" +
        "                        <div><button class=\"ui-btn export\"><%= t(\'defects.menu.downloadpack\') %></button></div>\n" +
        "                    </li>-->\n" +
        "\t</ul>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"popup\" class=\"dateDialog\" data-dismissible=\"false\" style=\"max-width:400px; display: none;\">\n" +
        "\t<div class=\"ui-content\">\n" +
        "\t\t<div class=\"description\" style=\"font-weight: bold; padding-top: 15px;\"><%= t(\'defects.changeselected.description\') %></div>\n" +
        "\n" +
        "\t\t<fieldset class=\"deadlinecg\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t<legend><%= t(\'defect.deadline\') %></legend>\n" +
        "\t\t\t<input type=\"date\" class=\"deadline\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t</fieldset>\n" +
        "\t\t<button class=\"cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\" data-transition=\"flow\"><%= t(\'defect.deadline.cancel\') %></button>\n" +
        "\t\t<button class=\"ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\"><%= t(\'defect.deadline.apply\') %></button>\n" +
        "\t</div>\n" +
        "</div>\n" +
        "\n" +
        "\n" +
        "<div data-role=\"popup\" class=\"selectedDefectsChangeDataDialog\" data-dismissible=\"false\" style=\"text-align: center; display: none\">\n" +
        "\t<div class=\"ui-content\" style=\"padding: 8px\">\n" +
        "\n" +
        "\t\t<div class=\"description\" style=\"font-weight: bold;\"><%= t(\'defects.changeselected.description\') %></div>\n" +
        "\n" +
        "\n" +
        "\t\t<div class=\"keepSelectedDefectsCb\">\n" +
        "\t\t\t<fieldset class=\"keepSelectedDefectscg\" data-role=\"controlgroup\">\n" +
        "\t\t\t\t<div class=\"defectcount\" style=\"margin-top: 15px; margin-left:2px; text-align: left; float: left;\"></div>\n" +
        "\t\t\t\t<label style=\"float: right;\" title=\"<%= t(\'defects.editpopup.keepselecteddefects.description\') %>\">\n" +
        "\t\t\t\t\t<input type=\"checkbox\" name=\"keepSelected\" value=\"keep\"/>\n" +
        "\t\t\t\t\t<%= t(\'defects.editpopup.keepselecteddefects\') %>\n" +
        "\t\t\t\t</label>\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"defects-setagstatus\" data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\">\n" +
        "\t\t\t<label style=\"padding-right: 25px\">\n" +
        "\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setstatus\" value=\"rejected\" data-pf=\"0\"/>\n" +
        "\t\t\t\t<%= t(\'defect.principalstatus.rejected\') %>\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<label style=\"padding-right: 25px\">\n" +
        "\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setstatus\" value=\"discount\" data-pf=\"0\"/>\n" +
        "\t\t\t\t<%= t(\'defect.principalstatus.discount\') %>\n" +
        "\t\t\t</label>\n" +
        "\n" +
        "\t\t\t<label style=\"padding-right: 20px\">\n" +
        "\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setstatus\" value=\"accepted\" data-pf=\"0\"/>\n" +
        "\t\t\t\t<%= t(\'defect.principalstatus.accepted\') %>\n" +
        "\t\t\t</label>\n" +
        "\n" +
        "\t\t\t<label style=\"padding-right: 25px\">\n" +
        "\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setstatus\" value=\"accepted\" data-pf=\"1\"/>\n" +
        "\t\t\t\t<%= t(\'defect.principalstatus.fixed\') %>\n" +
        "\t\t\t</label>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"defectTypeChooser\">\n" +
        "\t\t\t<fieldset class=\"defecttypecg\" data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\" style=\"margin-top: 15px;\">\n" +
        "\t\t\t\t<legend style=\"text-align: left;\"><%= t(\'defect.type.title\') %></legend>\n" +
        "\t\t\t\t<select class=\"defecttype\" data-wrapper-class=\"controlgroup-textinput ui-btn\">\n" +
        "\t\t\t\t\t<option value=\"\"                >-</option>\n" +
        "\t\t\t\t</select>\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"deadlineChooserSelectedDefects\">\n" +
        "\t\t\t<fieldset class=\"deadlinecg\" data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\">\n" +
        "\t\t\t\t<legend><%= t(\'defect.deadline\') %></legend>\n" +
        "\t\t\t\t<label>\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-deadline\" value=\"\" checked=\"checked\"/>\n" +
        "\t\t\t\t\t<%- t(\'defect.deadline.unchanged\') %>\n" +
        "\t\t\t\t</label>\n" +
        "\n" +
        "\t\t\t\t<label style=\"border-top-right-radius:7px; border-bottom-right-radius:7px; border-right-width:1px;\">\n" +
        "\t\t\t\t\t<input id=\"set-deadline-for-selected-defects\" type=\"radio\" name=\"defects-<%= pagevariant %>-deadline\" value=\"fixed\"/>\n" +
        "\t\t\t\t\t<%- t(\'defect.deadline.set\') %>\n" +
        "\t\t\t\t</label>\n" +
        "\n" +
        "\t\t\t\t<input id=\"deadline-for-selected-defects-picker\" type=\"date\" class=\"deadline\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div id=\"escalationLevelChooserSelectedDefects\">\n" +
        "\t\t\t<fieldset class=\"escalationcg\" data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\">\n" +
        "\t\t\t\t<legend><%= t(\'defect.escalationlevel.defectmenu.setescalationlevel\') %></legend>\n" +
        "\t\t\t\t<label>\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"escal\" value=\"nochange\" checked=\"checked\"/>\n" +
        "\t\t\t\t\t<%= t(\'defect.escalationlevel.defectmenu.setescalationlevel.nochange\') %>\n" +
        "\t\t\t\t</label>\n" +
        "\t\t\t\t<label>\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"escal\" value=\"1\" />\n" +
        "\t\t\t\t\t1\n" +
        "\t\t\t\t</label>\n" +
        "\t\t\t\t<label>\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"escal\" value=\"2\" />\n" +
        "\t\t\t\t\t2\n" +
        "\t\t\t\t</label>\n" +
        "\t\t\t\t<label>\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"escal\" value=\"3\" />\n" +
        "\t\t\t\t\t3\n" +
        "\t\t\t\t</label>\n" +
        "\t\t\t\t<label class=\"termination\">\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"escal\" value=\"4\" />\n" +
        "\t\t\t\t\tX\n" +
        "\t\t\t\t</label>\n" +
        "\t\t\t\t<label class=\"next\">\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"escal\" value=\"next\" />\n" +
        "\t\t\t\t\t<%= t(\'defect.escalationlevel.defectmenu.setescalationlevel.next\') %>\n" +
        "\t\t\t\t</label>\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"defects-setstatus\" data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\">\n" +
        "\t\t\t<label style=\"padding-right: 25px\">\n" +
        "\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setstatus\" value=\"irrelevant\"/>\n" +
        "\t\t\t\t<%= t(\'defect.status.irrelevant\') %>\n" +
        "\t\t\t\t<span class=\"status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-irrelevant\"></span>\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<label style=\"padding-right: 25px\">\n" +
        "\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setstatus\" value=\"fix\"/>\n" +
        "\t\t\t\t<%= t(\'defect.status.fix\') %>\n" +
        "\t\t\t\t<span class=\"status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-fix\"></span>\n" +
        "\t\t\t</label>\n" +
        "\n" +
        "\t\t\t<label style=\"padding-right: 20px\">\n" +
        "\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setstatus\" value=\"open\"/>\n" +
        "\t\t\t\t<%= t(\'defect.status.open\') %>\n" +
        "\t\t\t\t<span class=\"status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-open\"></span>\n" +
        "\t\t\t</label>\n" +
        "\n" +
        "\t\t\t<label style=\"padding-right: 25px\">\n" +
        "\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setstatus\" value=\"discount\"/>\n" +
        "\t\t\t\t<%= t(\'defect.status.discount\') %>\n" +
        "\t\t\t\t<span class=\"status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-discount\"></span>\n" +
        "\t\t\t</label>\n" +
        "\n" +
        "\t\t\t<label style=\"padding-right: 25px\">\n" +
        "\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setstatus\" value=\"fixed\"/>\n" +
        "\t\t\t\t<%= t(\'defect.status.fixed\') %>\n" +
        "\t\t\t\t<span class=\"status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-fixed\"></span>\n" +
        "\t\t\t</label>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"defects-setsubcontractor\">\n" +
        "\t\t\t<div class=\"subcontractorInfo\" style=\"margin-top: 10px; margin-left:2px; text-align: left;\"><%= t(\'defects.changeselected.nochangesubcontractorforsent\') %></div>\n" +
        "\t\t\t<input class=\"select-subcontractor\" type=\"text\" data-mini=\"true\" data-wrapper-class=\"controlgroup-textinput ui-btn\" placeholder=\"<%- t(\'defect.subcontractor\') %>\"/>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div id=\"automatic-subcontractor-select\">\n" +
        "\t\t\t<input type=\"checkbox\" id=\"automatic-subcontractor-select-input\" data-cacheval=\"true\" />\n" +
        "\t\t\t<label for=\"automatic-subcontractor-select-input\" id=\"automatic-subcontractor-select-label\">\n" +
        "\t\t\t\tAutomatische AN Auswahl\n" +
        "\t\t\t</label>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"defects-setanstatus ui-field-contain\">\n" +
        "\t\t\t<fieldset data-role=\"controlgroup\" class=\"type-list\" data-mini=\"true\" data-type=\"horizontal\" style=\"margin-top: 10px\">\n" +
        "\t\t\t\t<legend><%- t(\'defect.status.an-title\') %></legend>\n" +
        "\n" +
        "\t\t\t\t<label>\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setanstatus\" value=\"\" checked=\"checked\"/>\n" +
        "\t\t\t\t\t<%- t(\'defect.status.an-unchanged\') %>\n" +
        "\t\t\t\t</label>\n" +
        "\n" +
        "\t\t\t\t<label>\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setanstatus\" value=\"pending\"/>\n" +
        "\t\t\t\t\t<%- t(\'defect.status.pending\') %>\n" +
        "\t\t\t\t</label>\n" +
        "\n" +
        "\t\t\t\t<label>\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setanstatus\" value=\"fixed\"/>\n" +
        "\t\t\t\t\t<%- t(\'defect.status.fixed\') %>\n" +
        "\t\t\t\t</label>\n" +
        "\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"defects-setexternal ui-field-contain\" style=\"padding-top: 0px\">\n" +
        "\t\t\t<fieldset data-role=\"controlgroup\" class=\"type-list\" data-mini=\"true\" data-type=\"horizontal\" style=\"margin-top: 0px\">\n" +
        "\t\t\t\t<!-- <legend style=\"text-align: left\"><%- t(\'defect.status.external-title\') %></legend>-->\n" +
        "\n" +
        "\t\t\t\t<label>\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setexternal\" value=\"\" checked=\"checked\"/>\n" +
        "\t\t\t\t\t<%- t(\'defect.external.unchanged\') %>\n" +
        "\t\t\t\t</label>\n" +
        "\n" +
        "\t\t\t\t<label>\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"defects-<%= pagevariant %>-setexternal\" value=\"true\"/>\n" +
        "\t\t\t\t\t<%- t(\'defect.external\') %>\n" +
        "\t\t\t\t</label>\n" +
        "\n" +
        "\t\t\t\t<legend style=\"text-align: left\"><span style=\"font-weight: bold\"><%- t(\'defects.menu.external.warning.header\') %></span><%- t(\'defects.menu.external.warning.message\') %></legend>\n" +
        "\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t</div>\n\n   \n        <div>\n            <label style=\"text-align: left\">\n                <%- t(\'defect.multichangetext.header\') %>\n            </label>\n            <fieldset style=\"display: grid; grid-template-columns: 1fr 1fr;\" class=\"textTypeChooser\">\n\n            <input class=\'textinput\' type=\"text\">\n\n            <select class=\"texttype\" data-wrapper-class=\"controlgroup-textinput ui-btn\">\n                <option value=\"scmt\"><%= t(\'defect.comment.addnewancomment\') %></option>\n                <option value=\"cmt\"><%= t(\'defect.comment.addnewagcomment\') %></option>\n                <option value=\"h\"><%= t(\'defect.hint.addtocurrent\') %></option>\n                <option value=\"description\"><%= t(\'defect.description.addtocurrent\') %></option>\n                <option value=\"ic\"><%= t(\'defect.userinternalcomment.addtocurrent\') %></option>\n            </select>\n\n        </div>\n" +
        "\n" +
        "\t\t<button class=\"cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\"><%= t(\'defects.statuspopup.cancel\') %></button>\n" +
        "\t\t<button class=\"ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\"><%= t(\'defects.changeselected.savechanges\') %></button>\n" +
        "\n" +
        "\t\t<fieldset class=\"updateProgress\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t<div class=\"defectcountRatio\"></div>\n" +
        "\t\t</fieldset>\n" +
        "\t</div>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"popup\" class=\"showDefectData\" data-dismissible=\"false\" style=\"text-align: center; display: none\">\n" +
        "\t<div class=\"title\"><%= t(\'defects.metadata.popup.title\') %></div>\n" +
        "\t<div class=\"metadata-columns\">\n" +
        "\t\t<label>\n" +
        "\t\t\t<input type=\"checkbox\" name=\"defect-creator\"><%= t(\'defects.header.created.by\') %></input>\n" +
        "\t\t</label>\n" +
        "\t\t<label>\n" +
        "\t\t\t<input type=\"checkbox\" name=\"actualisation-date\"><%= t(\'defects.header.actualisation.date\') %></input>\n" +
        "\t\t</label>\n" +
        "\t\t<label>\n" +
        "\t\t\t<input type=\"checkbox\" name=\"creation-date\"><%= t(\'defects.header.creation.date\') %></input>\n" +
        "\t\t</label>\n" +
        "\t\t<label>\n" +
        "\t\t\t<input type=\"checkbox\" name=\"deadline-date\"><%= t(\'defects.header.deadline\') %></input>\n" +
        "\t\t</label>\n" +
        "\t\t<label>\n" +
        "\t\t\t<input type=\"checkbox\" name=\"escalationlevel\"><%= t(\'defects.header.escalation.level\') %></input>\n" +
        "\t\t</label>\n" +
        "\t\t<label>\n" +
        "\t\t\t<input type=\"checkbox\" name=\"subcontractor\"><%= t(\'defects.header.subcontractor\') %></input>\n" +
        "\t\t</label>\n" +
        "<!--\t\t<label>-->\n" +
        "<!--\t\t\t<input type=\"checkbox\" name=\"fixed-date\"><%= t(\'defects.header.fixed.date\') %></input>-->\n" +
        "<!--\t\t</label>-->\n" +
        "\t</div>\n" +
        "\t<button class=\"ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\">Anwenden</button>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\n" +
        "\t<div id=\"detailsFilterHeader\" class=\"ui-grid-a\" data-mini=\"true\" data-role=\"controlgroup\" data-type=\"horizontal\"></div>\n" +
        "\t\n" +
        "\t<fieldset style=\"display: block\" data-role=\"controlgroup\" data-type=\"horizontal\" class=\"addFilter\">\n" +
        "\t\t<select>\n" +
        "\t\t\t<option value=\"\">+ Filter</option>\n" +
        "\t\t\t<option value=\"location\"><%- t(\'filter.mobile.location\') %></option>\n" +
        "\t\t\t<option value=\"externalinternal\"><%- t(\'filter.mobile.externalinternal\') %></option>\n" +
        "\t\t\t<option value=\"subcontractor\"><%- t(\'filter.mobile.subcontractor\') %></option>\n" +
        "\t\t\t<option value=\"inspection\"><%- t(\'filter.mobile.inspection\') %></option>\n" +
        "\t\t\t<option value=\"status\"><%- t(\'filter.mobile.status\') %></option>\n" +
        "\t\t\t<option value=\"type\"><%- t(\'filter.mobile.type\') %></option>\n" +
        "\t\t\t<option value=\"creationdate\"><%- t(\'filter.mobile.creationdate\') %></option>\n" +
        "\t\t\t<option value=\"requests\"><%- t(\'filter.mobile.requests\') %></option>\n" +
        "\t\t</select>\n" +
        "\t\t<button class=\"ui-btn-inline ui-btn-icon-notext ui-icon-carat-d removeFilters\">\n" +
        "\t\t\t<svg viewBox=\"64 64 896 896\" focusable=\"false\" class=\"\" data-icon=\"delete\" width=\"1.6em\" height=\"1.6em\" fill=\"currentColor\" aria-hidden=\"true\">\n" +
        "\t\t\t\t<path d=\"M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32\n" +
        "\t\t\t\t\t32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z\">\n" +
        "\t\t\t\t</path>\n" +
        "\t\t\t</svg>\n" +
        "\t\t</button>\n" +
        "\t\t<button class=\"ui-btn-inline hideFilters minus\">-</button>\n" +
        "\t</fieldset>\n" +
        "\t\n" +
        "\t<div id=\"filters\" class=\"ui-grid-c\" data-mini=\"true\" data-role=\"controlgroup\" data-type=\"horizontal\" >\n" +
        "\t\t<div data-filter-title=\"location\" id=\"defectsLocation\" class=\"ui-grid-a\" data-mini=\"true\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\n" +
        "\t\t\t<div class=\"ui-block-a ui-corner-all\">\n" +
        "\t\t\t\t<div class=\"ui-block-b ui-corner-all\">\n" +
        "\t\t\t\t\t<div id=\"locationFilter\" data-mini=\"true\" data-role=\"controlgroup\" data-type=\"horizontal\"></div>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t</div>\n" +
        "\n" +
        "\t\t\t<div class=\"ui-block-b ui-corner-all\">\n" +
        "\t\t\t\t<div class=\"search-location\" data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\">\n" +
        "\t\t\t\t\t<div>\n" +
        "\t\t\t\t\t\t<input id=\"defectSearchFilter\" type=\"text\" placeholder=\"<%- t('defects.location.search.defect') %>\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t\t\t\t\t<span class=\"qrcodetrigger\" data-enhance=\"false\"></span>\n" +
        "\t\t\t\t\t</div>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t</div>\n" +
        "\n" +
        "\t\t</div>\n" +
        "\t\t<div data-filter-title=\"externalinternal\" class=\"ui-block-a defectExternalBox ui-field-contain\">\n" +
        "\t\t\t<fieldset data-role=\"controlgroup\">\n" +
        "\t\t\t\t<label for=\"defectExternal\"><%- t('defects.filter.external.both') %></label>\n" +
        "\t\t\t\t<input name=\"defectExternal\" id=\"defectExternal\" type=\"checkbox\">\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t</div>\n" +
        "\t\t<div data-filter-title=\"subcontractor\" class=\"ui-block-b ui-corner-all\" data-icon=\"false\">\n" +
        "\t\t\t<input id=\"defectSubcontractorFilter\" type=\"text\" data-icon=\"false\" data-wrapper-class=\"controlgroup-textinput ui-btn\" placeholder=\"<%- t('defects.filter.anysubcontractor') %>\" data-filter=\"subcontractor\"/>\n" +
        "\t\t</div>\n" +
        "\t\t<div data-filter-title=\"inspection\" class=\"ui-block-c ui-corner-all\">\n" +
        "\t\t\t<!--<select id=\"defectInspectionFilter\" data-filter=\"inspection\"></select>-->\n" +
        "\t\t\t<input id=\"defectInspectionFilter\" type=\"text\" data-wrapper-class=\"controlgroup-textinput ui-btn\" placeholder=\"<%- t('defects.filter.anyinspection') %>\" data-filter=\"inspection\"/>\n" +
        "\t\t</div>\n" +
        "\t\t<div data-filter-title=\"status\" class=\"ui-block-d ui-corner-all\">\n" +
        "\t\t\t<select id=\"defectStatusFilter\" data-filter=\"status\">\n" +
        "\t\t\t\t<optgroup>\n" +
        "\t\t\t\t\t<option value=\"\"><%- t('defects.filter.anystatus') %></option>\n" +
        "\t\t\t\t</optgroup>\n" +
        "\t\t\t\t<optgroup>\n" +
        "\t\t\t\t\t<option value=\"pending\"><%- t('defects.filter.status.pending') %></option>\n" +
        "\t\t\t\t\t<option value=\"done\"><%- t('defects.filter.status.done') %></option>\n" +
        "\t\t\t\t</optgroup>\n" +
        "\t\t\t\t<optgroup>\n" +
        "\t\t\t\t\t<option value=\"pending-subcontractor-pending\" class=\"onlyshow-subcontractor\"><%- t('defects.filter.pendingsubcontractorpending') %></option>\n" +
        "\t\t\t\t\t<option value=\"pending-subcontractor-fixed\" class=\"noshow-subcontractor noshow-principal\"><%- t('defects.filter.pendingsubcontractorfixed') %></option>\n" +
        "\t\t\t\t\t<option value=\"all-principal-rejected\" class=\"noshow-subcontractor\"><%- t('defects.filter.allprincipalrejected') %></option>\n" +
        "\t\t\t\t\t<option value=\"all-principal-discount\" class=\"noshow-subcontractor\"><%- t('defects.filter.allprincipaldiscount') %></option>\n" +
        "\t\t\t\t\t<option value=\"all-principal-accepted\" class=\"noshow-subcontractor\"><%- t('defects.filter.allprincipalaccepted') %></option>\n" +
        "\t\t\t\t\t<option value=\"all-principal-pendingrejected\" class=\"noshow-subcontractor\"><%- t('defects.filter.allprincipalpendingrejected') %></option>\n" +
        "\t\t\t\t\t<option value=\"done-principal-pending\" class=\"noshow-subcontractor\"><%- t('defects.filter.doneprincipalpending') %></option>\n" +
        "\t\t\t\t\t<option value=\"all-principal-pending\" class=\"noshow-subcontractor\"><%- t('defects.filter.allprincipalpending') %></option>\n" +
        "\n" +
        "\t\t\t\t\t<option value=\"extrastatusp\" class=\"noshow-subcontractor\"><%- t('defects.filter.extrastatusp') %></option>\n" +
        "\t\t\t\t\t<option value=\"extrastatusg\" class=\"noshow-subcontractor\"><%- t('defects.filter.extrastatusg') %></option>\n" +
        "\n" +
        "\t\t\t\t\t<option value=\"extrastatusas\" class=\"noshow-subcontractor\"><%- t('defects.filter.extrastatusas') %></option>\n" +
        "\t\t\t\t\t<option value=\"extrastatusag\" class=\"noshow-subcontractor\"><%- t('defects.filter.extrastatusag') %></option>\n" +
        "\n" +
        "\t\t\t\t\t<option value=\"request1-overdue\" class=\"noshow-subcontractor\"><%- t('defects.filter.request1-overdue') %></option>\n" +
        "\t\t\t\t\t<option value=\"request2-overdue\" class=\"noshow-subcontractor\"><%- t('defects.filter.request2-overdue') %></option>\n" +
        "\t\t\t\t\t<option value=\"agoverdue\" class=\"noshow-subcontractor\"><%- t('defects.filter.agoverdue') %></option>\n" +
        "\n" +
        "\t\t\t\t\t<option value=\"subcontractor-pending-commented\" class=\"noshow-subcontractor\"><%- t('defects.filter.subcontractor-pending-commented') %></option>\n" +
        "\t\t\t\t\t<option value=\"subcontractor-fixed-commented\" class=\"noshow-subcontractor\"><%- t('defects.filter.subcontractor-fixed-commented') %></option>\n" +
        "\t\t\t\t\t<option value=\"subcontractor-has-new-comments\" class=\"noshow-subcontractor\"><%- t('defects.filter.subcontractor-has-new-comments') %></option>\n" +
        "\n" +
        "\t\t\t\t\t<option value=\"subcontractor-pending-all-changes\" class=\"noshow-subcontractor\"><%- t('defects.filter.subcontractor-pending-all-changes') %></option>\n" +
        "\t\t\t\t\t<option value=\"subcontractor-fixed-all-changes\" class=\"noshow-subcontractor\"><%- t('defects.filter.subcontractor-fixed-all-changes') %></option>\n" +
        "\t\t\t\t\t<option value=\"subcontractor-all-changes\" class=\"noshow-subcontractor\"><%- t('defects.filter.subcontractor-all-changes') %></option>\n" +
        "\t\t\t\t\t<option value=\"principal-all-changes\" class=\"noshow-subcontractor\"><%- t('defects.filter.principal-all-changes') %></option>\n" +
        "\t\t\t\t</optgroup>\n" +
        "\t\t\t\t<optgroup>\n" +
        "\t\t\t\t\t<option value=\"fix\"><%- t('defect.status.fix') %></option>\n" +
        "\t\t\t\t\t<option value=\"open\"><%- t('defect.status.open') %></option>\n" +
        "\t\t\t\t\t<option value=\"irrelevant\"><%- t('defect.status.irrelevant') %></option>\n" +
        "\t\t\t\t\t<option value=\"discount\"><%- t('defect.status.discount') %></option>\n" +
        "\t\t\t\t\t<option value=\"fixed\"><%- t('defect.status.fixed') %></option>\n" +
        "\t\t\t\t</optgroup>\n" +
        "\t\t\t\t<optgroup>\n" +
        "\t\t\t\t\t<option value=\"observed\"><%- t('defect.status.observed') %></option>\n" +
        "\t\t\t\t\t<option value=\"rejected\"><%- t('defect.status.rejected') %></option>\n" +
        "\t\t\t\t</optgroup>\n" +
        "\t\t\t\t<optgroup>\n" +
        "\t\t\t\t\t<option value=\"costnone\"><%- t('defects.filter.costnone') %></option><!-- referenced by cost page -->\n" +
        "\t\t\t\t\t<option value=\"agcostnone\"><%- t('defects.filter.agcostnone') %></option>\n" +
        "\t\t\t\t\t<!-- 2016-01-20: no more cost filter here (should be in extended filter)\n" +
        "\t\t\t\t\t\t\t\t\t\t<option value=\"cost1000\"><%- t('defects.filter.costsignificant') %></option>\n" +
        "\t\t\t\t\t\t\t\t\t\t<option value=\"cost0\"><%- t('defects.filter.cost0') %></option>\n" +
        "\t\t\t\t\t\t\t\t\t\t<option value=\"costto200\"><%- t('defects.filter.costto200') %></option>\n" +
        "\t\t\t\t\t\t\t\t\t\t<option value=\"costto1000\"><%- t('defects.filter.costto1000') %></option>\n" +
        "\t\t\t\t\t\t\t\t\t\t<option value=\"costfrom1000\"><%- t('defects.filter.costfrom1000') %></option>\n" +
        "\t\t\t\t\t\t\t\t\t\t<option value=\"costfrom5000\"><%- t('defects.filter.costfrom5000') %></option>\n" +
        "\t\t\t\t\t-->\n" +
        "\t\t\t\t</optgroup>\n" +
        "\t\t\t</select>\n" +
        "\t\t</div>\n" +
        "\t\t<div data-filter-title=\"type\" class=\"ui-block-e ui-corner-all\">\n" +
        "\t\t\t<select id=\"defectTypeFilter\" data-filter=\"type\">\n" +
        "\t\t\t\t<option value=\"\"><%- t('defects.filter.anydefecttype') %></option>\n" +
        "\t\t\t</select>\n" +
        "\t\t</div>\n" +
        "\t\t<div data-filter-title=\"creationdate\" class=\"ui-block-e ui-corner-all creationdate\">\n" +
        "\t\t\t<button class=\"setFromTo\" style=\"width: 100%; height: 37px; overflow: hidden;\">Erstelldatum: - | -</button>\n" +
        "\t\t</div>\n" +
        "\t\t<div data-filter-title=\"requests\" class=\"ui-block-e ui-corner-all\" data-icon=\"false\">\n" +
        "\t\t\t<div style=\"padding-left: 4px;\" class=\"ui-btn ui-btn-icon-right ui-icon-carat-d\" id=\"defectRequestsFilter\" type=\"text\" data-icon=\"false\" data-wrapper-class=\"controlgroup-textinput ui-btn\" placeholder=\"RequestsFilter\" data-filter=\"requests\">\n" +
        "\t\t\t\t<span style=\"opacity: 0.5\">\n" +
        "\t\t\t\t\t— <%- t('defects.filter.empty') %> —\n" +
        "\t\t\t\t</span>\n" +
        "\t\t\t</div>\n" +
        "\t\t</div>\n" +
        "\t</div>" +
        "\t<!--<input id=\"defectSearchFilter\" data-filter=\"search\" type=\"text\" placeholder=\"<%= t(\'defects.filter.text\') %>\"/>-->\n" +
        "\n" +
        "\t<div class=\"list-separator ui-grid-e\" data-mini=\"true\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t<!--<div class=\"current-inspection-name\">Inspection ist ausgeswählt</div>-->\n" +
        "\t\t<div class=\"ui-block-a ui-corner-all\">\n" +
        "\t\t\t<div class=\"customDefectsNotifier ui-btn ui-btn-inline ui-btn-icon-left ui-icon-delete ui-corner-all\" data-mini=\"true\" data-role=\"controlgroup\" data-type=\"horizontal\"><%- t(\'defects.customdefectsnotifier\') %></div>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\t\n" +
        "\t<ul data-role=\"listview\" data-inset=\"true\" class=\"defects-list-header listheader checkboxList\">\n" +
        "\t\t<li>\n" +
        "\t\t\t<div class=\"ui-grid-a\">\n" +
        "\t\t\t\t<input type=\"checkbox\" class=\"select-all-defects ui-btn ui-block-a\">\n" +
        "\t\t\t\t<div class=\"header-types\">\n" +
        "<!--\t\t\t\t\t<div class=\"project-name\"></div>-->\n" +
        "<!--\t\t\t\t\t<div class=\"defect-data\"></div>-->\n" +
        "\t\t\t\t\t<div class=\"defect-metadata\"></div>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t</div>\n" +
        "\t\t</li>\n" +
        "\t</ul>\n" +
        "\t<ul data-role=\"listview\" data-inset=\"true\" class=\"defects-list list checkboxList ui-corner-all\"></ul>\n" +
        "\t\n" +
        "\t<div data-role=\"popup\" class=\"fromToDialog\" data-dismissible=\"true\" style=\"max-width:400px; display: none;\">\n" +
        "\t\t<div class=\"ui-content\">\n" +
        "\t\t\t<fieldset class=\"fromcg\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t\t<legend><%= t(\'documents.filter.from\') %></legend>\n" +
        "\t\t\t\t<input type=\"date\" class=\"from\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t\t<fieldset class=\"tocg\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t\t<legend><%= t(\'documents.filter.to\') %></legend>\n" +
        "\t\t\t\t<input type=\"date\" class=\"to\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t\t<button class=\"cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\" data-transition=\"flow\"><%= t(\'defect.deadline.cancel\') %></button>\n" +
        "\t\t\t<button class=\"ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\"><%= t(\'defect.deadline.apply\') %></button>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\t\n" +
        "<!--\t<div data-role=\"popup\" class=\"toDialog\" data-dismissible=\"true\" style=\"max-width:400px; display: none;\">-->\n" +
        "<!--\t\t<div class=\"ui-content\">-->\n" +
        "<!--\t\t\t<fieldset class=\"tocg\" data-role=\"controlgroup\" data-type=\"horizontal\">-->\n" +
        "<!--\t\t\t\t<legend><%= t(\'documents.filter.to\') %></legend>-->\n" +
        "<!--\t\t\t\t<input type=\"date\" class=\"to\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>-->\n" +
        "<!--\t\t\t</fieldset>-->\n" +
        "<!--\t\t\t<button class=\"cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\" data-transition=\"flow\"><%= t(\'defect.deadline.cancel\') %></button>-->\n" +
        "<!--\t\t\t<button class=\"ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\"><%= t(\'defect.deadline.apply\') %></button>-->\n" +
        "<!--\t\t</div>-->\n" +
        "<!--\t</div>-->\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id=\'defects-page-footer\' data-position=\"fixed\"></div>\n" +
        "\n" +
        "<div id=\"defect-actions\">\n" +
        "\t<div id=\"defects-actions-menu\" class=\"defects-quick-actions\">\n" +
        "\t\t<div class=\"inspection-title\"><p>Begehung</p></div>\n" +
        "\t\t<div class=\"ui-btn ui-btn-icon-left ui-corner-all defects-count\" style=\"float: left; height:47px\">0</div>\n" +
        "\t\t<span class=\"defects-count-label\" style=\"float: left;\"><%= t(\'defects.quick-actions.defectsnumber\') %></span>\n" +
        "\n" +
        "\n" +
        "\t\t<div class=\"ui-btn ui-btn-icon-left ui-icon-delete ui-corner-all close-defects-menu-btn\" style=\"float: right;\"></div>\n" +
        "\t\t<div class=\"ui-btn ui-btn-icon-left ui-icon-gear ui-corner-all edit-defects action\" tabindex=\"1\" style=\"width: 5.5em; float: right;\"><label style=\"left: 13px\"><%= t(\'defects.quick-actions.editdefects\') %></label></div>\n" +
        "\n" +
        "\t\t<div class=\"ui-btn ui-corner-all defect-action-export action\" tabindex=\"1\" style=\"width: 4em;float: right; \">\n" +
        "\t\t\t<div class=\"defects-download\"> </div><label  style=\"left: 14px\">Export</label>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"ui-btn ui-btn-icon-left ui-icon-action ui-corner-all defects-more-actions action\"  tabindex=\"1\" style=\"width: 5em;float: right; \">\n" +
        "\t\t\t<label  style=\"left: 13px\"><%= t(\'defects.quick-actions.moreactions\') %></label>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"defect-export-options\" style=\"display: none\">\n" +
        "\t\t\t<div class=\"ui-btn ui-corner-all download-pdf\"><%= t(\'defects.menu.downloadpdf\') %></div>\n" +
        "\t\t\t<div class=\"ui-btn ui-corner-all download-excel\"><%= t(\'defects.menu.downloadexcel\') %></div>\n" +
        "\t\t\t<div class=\"ui-btn ui-corner-all download-excel-fullhistory\"><%= t(\'defects.menu.downloadexcel.history\') %></div>\n" +
        "\t\t\t<div class=\"ui-btn ui-corner-all export\"><%= t(\'defects.menu.downloadpack\') %></div>\n" +
        "\t\t\t<div class=\"ui-btn ui-corner-all export-fullhistory\"><%= t(\'defects.menu.downloadpack.history\') %></div>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"defects-more-actions-options\" style=\"display: none\">\n" +
        "\t\t\t<div class=\"ui-btn ui-corner-all sendChosen\"><%= t(\'defects.menu.sendchosen\') %></div>\n" +
        "\t\t\t<div class=\"ui-btn ui-corner-all assign\"><%= t(\'defects.menu.assign\') %></div>\n" +
        "\t\t\t<div class=\"ui-btn ui-corner-all unassign\"><%= t(\'defects.menu.unassign\') %></div>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"ui-btn ui-btn-icon-left ui-icon-mail ui-corner-all sendChosen action\"  tabindex=\"1\"  style=\"width: 8em; float: right; display: none\">\n" +
        "\t\t\t<label style=\"left: 13px;\"><%= t(\'defects.quick-actions.requests\') %></label>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"ui-btn ui-btn-icon-left ui-icon-back ui-corner-all link-defects action\"  tabindex=\"1\"  style=\"width: 8em; display: none\">\n" +
        "\t\t\t<label style=\"left: 13px;\"><%= t(\'defects.quick-actions.linkdefects\') %></label>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t</div>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "\n" +
        "<div class=\"christOverlay\" style=\"position: absolute; top: 0; left: 0; bottom: 0; right: 0; z-index: 9999; display: none; border: 10px solid #DDD\">\n" +
        "\t<iframe src=\"\" style=\"width: 100%; height: 100%; border: none\"></iframe>\n" +
        "</div>\n";
    return page;
});