import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {CommonStoreStateKeys, setStateValue} from "../store/slices/commonStoreSlice";
import {AccountPage} from "@bau/material";
import {getCustomerIdFromCustomerProject, translate} from "../utils/utils";
import {useLocation, useNavigate} from "react-router-dom";
// @ts-ignore
import Cookies from "js-cookie";

const Account = () =>{

    let dispatch = useAppDispatch();
    let navigate = useNavigate();
    let {state} = useLocation();

    let accessibleAccounts = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.accessibleAccounts]);
    const customers = accessibleAccounts.accounts;
    let user = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.user]);
    let currentCustomer = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentCustomer]);
    let forcedCustomerProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.forcedCustomerProject]);

    useEffect(()=>{
        if(!!state && state.customerProjectAutoSelect) {
            if(!!forcedCustomerProject) {
                let customerId = getCustomerIdFromCustomerProject(forcedCustomerProject);
                if(!!customerId) {
                    chooseCustomer(customerId, state);
                    return;
                }
            }
            if(!!currentCustomer) {
                chooseCustomer(currentCustomer, state);
                return;
            }
            if(!!customers && customers.length === 1) {
                chooseCustomer(customers[0], state);
                return;
            }
            let customer = localStorage.getItem("oa-customer");
            if(!!customer && !!customers && customers.find((c:any) => c.id === customer)) {
                chooseCustomer(customer, state);
            }
        }

    }, [])

    async function chooseCustomer(customerId: string, state?: any) {
        if(currentCustomer === customerId) {
            navigate("/projects", {state});
            return;
        }
        dispatch(setStateValue({key: CommonStoreStateKeys.currentCustomer, value: customerId}))
        navigate("/");
    }

    function logout() {
        Cookies.remove("ox.auth.token");
        dispatch(setStateValue({key: CommonStoreStateKeys.currentCustomer, value: ""}));
        navigate("/logout");
    }

    let customerProps = !!customers ? customers.map((customer:any) => {
        return {name: customer.label, id: customer.id}
    }) : [];

    return (
        <PageContainer headerText={translate("sidepanel.account")} newPage={true}>
            <AccountPage
                userName={user.name}
                experienceId={user.experienceId}
                customers={customerProps}
                onPickCustomer={(customerId) => chooseCustomer(customerId)}
                onLogout={()=>logout()}
                switchToTestServerString={"use test server"}
                switchToProductiveServerString={"use productive server"}
                loginAsString={translate("account.loggedinas")}
                dataProtectionString={translate("account.dataprotection")}
                logoutString={translate("sidepanel.logout")}
                myExperienceIdString={translate("account.loggedinas.experienceid")}
                availableClientsString={translate("account.customerlist.headline")}
                nameString={translate("account.loggedinas.name")}/>
        </PageContainer>
    )
}


export default Account;