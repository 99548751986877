define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2',
	'../helper/interactiveSync', '../model/proxyInfo'
], function (
	$, _, Backbone,
	interactiveSync, proxyInfo
) {

	function escapeHtml (text) { return text; }

	return new (Backbone.Model.extend({

		url:  '/onlineBauabnahme/api/help',

		fetchIfEmpty: function () {
			if (this.has('paragraphs')) {
				return $.Deferred().resolve();
			}
			return this.fetch();
		},

		sync: function (method, model, options) {
			return interactiveSync.sync.apply(interactiveSync, [method, model, proxyInfo.syncAddHeaders(options)]);
		},

		getParagraph: function (index) { //index of heading
			var paragraphs = this.get('paragraphs'), last = index, head = index, next = index + 1, previous = index - 1;
			while (last + 1 < paragraphs.length && paragraphs[last + 1].level >= 2) {
				last++;
			}
			while (head >= 0 && head < paragraphs.length && paragraphs[head].level > 0) {
				head--;
			}
			while (previous - 1 >= 0 && previous - 1 < paragraphs.length && paragraphs[previous].level != 1) {
				previous--;
			}
			while (next < paragraphs.length && paragraphs[next].level != 1) {
				next++;
			}
			return {
				title1:   paragraphs[head].text,
				title2:   paragraphs[index].text,
				contents: paragraphs.slice(index + 1, last + 1),
				next:     next < paragraphs.length ? next : -1,
				previous: previous > 0 ? previous : -1
			};
		},

		filter: function (search) {
			this.search = _.compact(search.trim().split(/\s+|"(.+?)(?:"|$)|'(.+?)(?:'|$)/));
			_.each(this.get('paragraphs'), function (paragraph) {
				if (paragraph.level < 2) {
					paragraph.matches = paragraph.exfiltrated = false;
				}
			}, this);
			if (this.search.length > 0) {
				var group = null, heading = null;
				_.each(this.get('paragraphs'), function (paragraph) {
					if (paragraph.level === 0) {
						group = paragraph;
						heading = null;
					}
					if (paragraph.level === 1) {
						heading = paragraph;
					}
					if (heading && !heading.matches) {
						heading.matches = _.reduce(this.search, function (memo, input) {
							if (memo) {
								return paragraph.text.toLowerCase().indexOf(input.toLowerCase()) !== -1;
							}
							return memo;
						}, true);
					}
					if (group && heading && !group.matches) {
						group.matches = heading.matches;
					}
				}, this);
				_.each(this.get('paragraphs'), function (paragraph) {
					if (paragraph.level < 2) {
						paragraph.exfiltrated = !paragraph.matches;
					}
				}, this);
			}
		},

		hilight: function (text) {
			var occurrences = [];
			_.each(this.search || [], function (term) {
				if (!term) {
					return;
				}
				var pattern = new RegExp('(' + term.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&") + ')', 'gi');
				var match;
				while ((match = pattern.exec(text)) !== null) {
					occurrences.push({
						start: match.index,
						end:   match.index + term.length
					});
				}
			}, this);
			if (!occurrences.length) {
				return escapeHtml(text);
			}
			occurrences.sort(function (a, b) {
				return a.start - b.start;
			});
			for (var i = 0; i < occurrences.length; i++) {
				while (i < occurrences.length - 1 && occurrences[i].end >= occurrences[i + 1].start) {
					occurrences[i].end = Math.max(occurrences[i].end, occurrences[i + 1].end);
					occurrences.splice(i + 1, 1);
				}
			}
			var pos = 0;
			var html = '';
			for (var j = 0; j < occurrences.length; j++) {
				if (occurrences[j].start > pos) {
					html += escapeHtml(text.substring(pos, occurrences[j].start));
				}
				html += '<span class="h">' + escapeHtml(text.substring(occurrences[j].start, occurrences[j].end)) + '</span>';
				pos = occurrences[j].end;
			}
			if (pos < text.length) {
				html += escapeHtml(text.substr(pos));
			}
			return html;
		}

	}));
});
