define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" data-position=\"fixed\">\n" +
        "\n" +
        "\t<div class=\"ui-btn-left ui-btn-inline\">\n" +
        "\t\t<button data-shortcut=\"leftMenu\" class=\"menutrigger ui-btn ui-btn-inline ui-icon-bars ui-btn-icon-notext ui-corner-all\"></button>\n" +
        "\t\t <a href=\"#help\" class=\"previousPage ui-btn ui-btn-inline ui-btn-icon-left ui-icon-back ui-corner-all\" style=\"margin-left: 5px\"><%= t('helparticle.back') %></a>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<h1 data-role=\"heading\"><%= t('helparticle.title') %></h1>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\" class=\"content\">\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" data-position=\"fixed\">\n" +
        "\n" +
        "\t<button class=\"previous ui-btn ui-btn-left ui-btn-inline ui-icon-carat-l ui-btn-icon-left ui-corner-all\"><%= t('helparticle.previous') %></button>\n" +
        "\n" +
        "\t<button class=\"next ui-btn ui-btn-right ui-btn-inline ui-icon-carat-r ui-btn-icon-right ui-corner-all\"><%= t('helparticle.next') %></button>\n" +
        "\n" +
        "</div>";
    return page;
});