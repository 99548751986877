// Copyright 2014, Open Experience GmbH. All rights reserved.

define([
	'../lib/jquery-1.11.0', '../lib/backbone-1.1.2', './PopupHtml', '../model/user'
], function ($, Backbone, Template, user) {
	"use strict";

	return Backbone.View.extend({

		className: 'generic-popup',
		attributes: {
			'data-role':        'popup',
			'data-theme':       'a',
			'data-dismissible': 'false',
			'data-history':     'false'
		},

		template: _.template(Template),

		events: {
	        'vclick .buttons button': 'clickedButton'
		},

		model: { buttons: [], header: '' },
		
		initialize: function (options) {

			this.model = _.defaults(options, this.model);

			var buttonDefaults = {
				text: '',
				close: true
			};
			var predefinedButtons = {
				ok: {
					text:  user.translate('popup.default.ok'),
					event: 'confirm',
				    close: true
				},
				cancel: {
					text:  user.translate('popup.default.cancel'),
				    event: 'cancel',
					close: true
				},
				yes: {
					text:  user.translate('popup.yes'),
					event: 'confirm',
				    close: true
				},
				no: {
					text:  user.translate('popup.no'),
				    event: 'cancel',
					close: true
				},
				copylink: {
					text: user.translate('popup.copylink'),
					event: 'copylink',
					close: false
				}
			};

			this.model.buttons = this.model.buttons || [];
			for (var i = 0; i < this.model.buttons.length; i++) {
				var button = this.model.buttons[i];
				if (_.isObject(button)) {
					this.model.buttons[i] = _.defaults(button, buttonDefaults);
				} else if (_.isString(button) && predefinedButtons[button] != null) {
					this.model.buttons[i] = predefinedButtons[button];
				}
			}

			var content = this.model.content;
			if (content) {
				delete this.model.content;
			}
			
			this.closedDeferred = $.Deferred();

			this.$el.html(this.template(this.model))
				.appendTo($('body'))
				.popup({
					afterclose: function () {
						this.remove()
						this.closedDeferred.resolve();
					}.bind(this)
				})
				.children('.ui-content').prepend(content).enhanceWithin();
			
		},

		render: function () {
			if ($.mobile.popup.active) {
				$.mobile.popup.active._close(true);
			}
			this.$el.popup('open', { transition: 'pop' });
			return this;
		},

		close: function () {
			this.$el.popup('close');
		},

		clickedButton: function (e) {
			e.preventDefault();
			e.stopPropagation();
			var button = this.model.buttons[parseInt($(e.currentTarget).attr('data-index'))];
			if (button.close) {
				this.close();
			}
			if (_.isString(button.event)) {
				this.trigger(button.event);
			}
		}

	});

});