define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id='inspections-page-header'>\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\t<p class=\"header\" style=\"margin-bottom: 1ex\">\n" +
        "\t\t<%= t('inspections.header') %><button\n" +
        "\t\t\t\tclass=\"header-info ui-btn ui-btn-icon-right ui-icon-info ui-btn-inline ui-corner-all\"></button>\n" +
        "\t</p>\n" +
        "\t<div class=\"search\" style=\"display: flex; flex-direction: row; align-items: center; gap: 1rem;\">\n" +
        "\t\t<div class=\"search-bar\" style=\"flex: 3\">\n" +
        "\t\t\t<input type=\"search\" placeholder=\"<%= t('inspections.search') %>\">\n" +
        "\t\t</div>\n" +
        "\t\t<!-- <div class=\"search-options\" style=\"flex: 1; min-width: min-content;\">\n" +
        "\t\t\t<label for=\"search-options-include-fulltext\"><%= t('inspections.search.includeFulltext') %></label>\n" +
        "\t\t\t<input type=\"checkbox\" class=\"search-options-include-fulltext\" id=\"search-options-include-fulltext\" />\n" +
        "\t\t</div> -->\n" +
        "\t</div>\n" +
        "\n" +
        "\n" +
        "\t<div class=\"unit\" data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\">\n" +
        "\t\t<select class=\"filter type\">\n" +
        "\t\t\t<option value=\"all\">\n" +
        "\t\t\t\t<%= t('inspections.filter.all') %>\n" +
        "\t\t\t</option>\n" +
        "\t\t\t<option value=\"external\">\n" +
        "\t\t\t\t<%= t('inspections.filter.external') %>\n" +
        "\t\t\t</option>\n" +
        "\t\t\t<!--<option value=\"externalfinished\"><%= t('inspections.filter.externalfinished') %></option>-->\n" +
        "\t\t\t<option value=\"internal\">\n" +
        "\t\t\t\t<%= t('inspections.filter.internal') %>\n" +
        "\t\t\t</option>\n" +
        "\t\t\t<option value=\"archived\">\n" +
        "\t\t\t\t<%= t('inspections.filter.archived') %>\n" +
        "\t\t\t</option>\n" +
        "\t\t</select>\n" +
        "\t\t<select class=\"filter inspection-type\"></select>\n" +
        "\t\t<div class=\"filter creationdate\">\n" +
        "\t\t\t<button class=\"setFromTo\" style=\"width: 100%; height: 37px; overflow: hidden;\">Erstelldatum: - |\n" +
        "\t\t\t\t-</button>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div data-role=\"popup\" class=\"fromToDialog\" data-dismissible=\"true\" style=\"max-width:400px; display: none;\">\n" +
        "\t\t\t<div class=\"ui-content\">\n" +
        "\t\t\t\t<fieldset class=\"fromcg\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t\t\t<legend><%= t('documents.filter.from') %></legend>\n" +
        "\t\t\t\t\t<input type=\"date\" class=\"from\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t\t\t</fieldset>\n" +
        "\t\t\t\t<fieldset class=\"tocg\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t\t\t<legend><%= t('documents.filter.to') %></legend>\n" +
        "\t\t\t\t\t<input type=\"date\" class=\"to\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t\t\t</fieldset>\n" +
        "\t\t\t\t<button class=\"cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\" data-transition=\"flow\"><%= t('defect.deadline.cancel') %></button>\n" +
        "\t\t\t\t<button class=\"ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\" data-rel=\"back\"><%= t('defect.deadline.apply') %></button>\n" +
        "\t\t\t</div>\n" +
        "\t\t</div>\n" +
        "\t\t<!--\n" +
        "\t\t<label style=\"width: 100px; text-align: center\"><input type=\"checkbox\" class=\"finished\" data-mini=\"true\"> <%= t('inspections.filter.finished') %></label>\n" +
        "\t\t<label style=\"width: 50px; text-align: center\"><input type=\"checkbox\" class=\"archive\" data-mini=\"true\"> <%= t('inspections.filter.archive') %></label>\n" +
        "-->\n" +
        "\t</div>\n" +
        "\n" +
        "\t<ul data-role=\"listview\" data-inset=\"true\" class=\"list defectButtonsList\"></ul>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='inspections-page-footer' data-position=\"fixed\"></div>";
    return page;
});