import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {translate} from "../utils/utils";


const RequestBundle = () => {



    useEffect(()=>{
        // @ts-ignore
        window.requestBundleUseEffect();
    },[])


    return <PageContainer newPage={false} headerText={translate("paperwork.title")}
                          left={<StyledButton className="previousPage">{translate("goback")}</StyledButton>}
    />
}

export default RequestBundle;