define([
    '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../model/currentInspection'
], function (
    $, _, currentInspection
) {

    var backboneReactCommunicationUtils = {
        getCustomerProject: function () {
            var customerProject =  window.location.pathname.replaceAll(/^\/.*project\//g, '').replaceAll(/\/.*/g,'');
            return '/project/'+customerProject;
        },

        getCustomerProjectConsideringInspection: function () {
            return this.getCustomerProject()+((!!currentInspection && !!currentInspection.get('id')) ? '/inspection/'+currentInspection.get('id') : '');
        },

        parseProjectFiles: function(json){
            let children = json.children;
            if(!!children) {
                json.children = children.toJSON();
                _.forEach(json.children, function (el) {
                    this.parseProjectFiles(el);
                }.bind(this));
            }
        },

        saveBackboneDataInReact: function (model, key) {
            var data = {};
            data.reactStateIdentifier = key;
            if(key === this.projectFiles) {
                var json = model.toJSON();
                this.parseProjectFiles(json);
                data.reactStateValue = json;
            } else {
                data.reactStateValue = !!model.toJSON ? model.toJSON() : model;
            }
            window.saveBackboneDataInReact(data);
        },
        user: "user",
        proxyInfo: "proxyInfo",
        ma_accounts: "ma_accounts",
        projects: "projects",
        currentProject: "currentProject",
        accessibleAccounts: "accessibleAccounts",
        help: "help",
        inspections: "inspections",
        subcontractors: "subcontractors",
        polygonsAndCfgs: "polygonsAndCfgs",
        realWorldPlanTransformations: "realWorldPlanTransformations",
        recordPoints: "recordPoints",
        currentDefects: "currentDefects",
        completions: "completions",
        projectFiles: "projectFiles",
        currentInspection: "currentInspection",
        currentStats: "currentStats",
        participants: "participants",
        attachmentSources: "attachmentSources",
        projectFileSources: "projectFileSources",
        watchdog: "watchdog",
        offlineQueue: "offlineQueue",
        backboneDataUpdateTimeStamp: "backboneDataUpdateTimeStamp",
    };

    return backboneReactCommunicationUtils;
});