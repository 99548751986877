define([
	'../../lib/jquery-1.11.0', '../../lib/lodash-2.4.1.compat', '../../lib/backbone-1.1.2', '../../model/user'
], function (
	$, _, Backbone, user
) {
	var shortcuts;
	
	var language;
	
	var hoverBox = "\
					<div class='hoverBox'>\n\
						<span class='hoverBoxText'></span>\n\
					</div>\
					";
	
	var shortcutsHover = {
		
		initialize: function (sc) {
			shortcuts = sc;
			language = shortcuts.getShortcutsKeys().getLanguage()
		},
		
		addHoverToShortcut: function (element, name, inputname) {
			shortcutsHover.addHover(element, name, inputname);
		},
		
		getHoverText: function (name, inputname) {
			var text = '';
			var explanation = '';
			if (inputname) {
				explanation = user.translate('shortcut.hover.' + name + '.' + inputname);
			} else {
				explanation = user.translate('shortcut.hover.' + name);
			}
			text = shortcutsHover.getKeyText(name) + '\n\
						' + explanation;
			return text;
		},
		
		getKeyText: function (name) {
			var key = shortcuts.getShortcutsKeys().getKeyList().find(function (k) {
				return k[0] === name
			});
			if (key) {
				var keyText = '';
				keyText += (key[2] ? '<b><img alt="' + user.translate('shortcut.tableColumn.ctrl') + '" src="' +  '/helper/shortcuts/img/' + (language === 'DE' ? 'strg.png' : 'ctrl.png') + '" height="30px" class="keyPicture"></b>' : '');
				if (key[2]) keyText += ' + ';
				keyText += (key[3] ? '<b><img alt="' + user.translate('shortcut.tableColumn.shift') + '" src="' +  '/helper/shortcuts/img/shift.png" height="30px" class="keyPicture"></b>' : '');
				if (key[3]) keyText += ' + ';
				keyText += (key[4] ? '<b><img alt="' + user.translate('shortcut.tableColumn.alt') + '" src="' +  '/helper/shortcuts/img/alt.png" height="30px" class="keyPicture"></b>' : '');
				if (key[4]) keyText += ' + ';
				keyText += '<b>' + shortcuts.getShortcutsKeys().characterFromKeyCode(key[1]) + '</b>';
				return keyText
			}
			return ''
		},
		
		addHover: function (thisEl, name, inputname) {
			var timer;
			$(thisEl).hover(function (e) {
				if (shortcuts.getShortcutsKeys().getHoverSetting() && shortcuts.getShortcutsKeys().checkRights(null, name) && (thisEl.tagName !== 'INPUT' || thisEl.tagName === 'INPUT' && !thisEl.readOnly)) {
					timer = setTimeout(function () {
						$('body').append(hoverBox);
						$('.hoverBoxText').html(shortcutsHover.getHoverText(name, inputname));
						shortcutsHover.positionHover(e);
					}.bind(this), 750)
				}
			}, function () {
				if (shortcuts.getShortcutsKeys().getHoverSetting() && shortcuts.getShortcutsKeys().checkRights(null, name) && (thisEl.tagName !== 'INPUT' || thisEl.tagName === 'INPUT' && !thisEl.readOnly)) {
					clearTimeout(timer);
					$('body').find('.hoverBox').remove();
				}
			});
		},
		
		positionHover: function (e) {
			var width = $('body').find('.hoverBox').width() + parseInt($('body').find('.hoverBox').css('padding-left')) + parseInt($('body').find('.hoverBox').css('padding-right'));
			var height = $('body').find('.hoverBox').height() + parseInt($('body').find('.hoverBox').css('padding-top')) + parseInt($('body').find('.hoverBox').css('padding-bottom'));
			$('body').find('.hoverBox').css({
				left: e.clientX >= ($(window).innerWidth() - (width + 5)) ? e.pageX - width - 5 : e.pageX + 5,
				top: e.clientY >= ($(window).innerHeight() - (height + 5)) ? e.pageY - height - 5 : e.pageY + 5
			});
		},
		
		getHoverBox: function () {
			return hoverBox;
		}
	};
	return shortcutsHover;
});