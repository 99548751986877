define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div data-role=\"header\" id='cost-page-header' data-position=\"fixed\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\t<fieldset data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\" class=\"separate-switch\">\n" +
        "\t\t<input type=\"radio\" name=\"cost-separate\" id=\"cost-separate-subcontractor\" value=\"subcontractor\" checked=\"checked\">\n" +
        "\t\t<label for=\"cost-separate-subcontractor\">AN</label>\n" +
        "\t\t<input type=\"radio\" name=\"cost-separate\" id=\"cost-separate-principal\" value=\"principal\">\n" +
        "\t\t<label for=\"cost-separate-principal\">AG</label>\n" +
        "\t</fieldset>\n" +
        "\n" +
        "\t<p class=\"legend\"></p>\n" +
        "\n" +
        "\t<ul data-role=\"listview\" class=\"list defectButtonsList\"></ul>\n" +
        "\n" +
        "\n" +
        "\t<div data-role=\"popup\" class=\"costDownloadDialog\" data-dismissible=\"false\" style=\"max-width: 95%; width: 400px; display: none;\">\n" +
        "\t\t<div class=\"ui-content ui-mini\">\n" +
        "\t\t\t<div style=\"padding-top: 5px\"><%- t('cost.pdfoptions') %></div>\n" +
        "\t\t\t<fieldset data-role=\"controlgroup\">\n" +
        "\t\t\t\t<div class=\"defect-costpdf-options ui-controlgroup-controls\">\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"costSplit\" id=\"no-cost-split\" value=\"NO_COST_SPLIT\" checked />\n" +
        "\t\t\t\t\t<label for=\"no-cost-split\"><%= t('cost.nocostsplit') %></label>\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"costSplit\" id=\"principal-cost-split\" value=\"PRINCIPAL_COST_SPLIT\" />\n" +
        "\t\t\t\t\t<label for=\"principal-cost-split\"><%= t('cost.principalcostsplit') %></label>\n" +
        "\t\t\t\t\t<input type=\"radio\" name=\"costSplit\" id=\"user-cost-split\" value=\"USER_COST_SPLIT\" />\n" +
        "\t\t\t\t\t<label for=\"user-cost-split\"><%= t('cost.gucostsplit') %></label>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t</fieldset>\n" +
        "\t\t\t<div style=\"text-align: right\">\n" +
        "\t\t\t\t<button class=\"cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\"><%= t('defect.comment.cancel') %></button>\n" +
        "\t\t\t\t<button class=\"ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a\"><%= t('defect.comment.apply') %></button>\n" +
        "\t\t\t</div>\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='cost-page-footer' data-position=\"fixed\"></div>";
    return page;
});