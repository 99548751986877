
define([
    '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', '../model/user', '../helper/interactiveSync'
], function (
    _, Backbone, user, interactiveSync
) {
    return new (Backbone.Model.extend({

        url: function () {
             return  '/onlineBauabnahme/api/warrantyData/' + this.get('subcontractor') + '/'  + user.get('customer') + '/' + this.get('project') + '/single' + '?location=' + this.get('location').join(',');
        },
        sync: function () {
            return interactiveSync.sync.apply(interactiveSync, arguments);
        },

        fetch: function (subcontractor, project, location) {
            this.subcontractor = subcontractor;
            this.project = project;
            this.location = location;
            return Backbone.Model.prototype.fetch.apply(this, arguments);
        },

        setSubcontractorAndLocation: function (subcontractor, project , location) {
            this.set('subcontractor', subcontractor);
            this.set('project', project);
            this.set('location', location ||[]);

            if (!location || !project || !subcontractor) {
                this.reset();
                return $.Deferred().resolve();
            }
            return this.fetch({ reset: true })
        },
        getPath: function () {
            var location = this.get('location');
            for (var i = 0; i < location.length; i++) {
                location[i] = Defect.toId(location[i]);
            }
            return location.join('-');
        },
    }))();
});
