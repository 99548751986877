import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {getCustomerProjectWithPrefix, translate} from "../utils/utils";
import {useNavigate} from "react-router-dom";


const Statistics = () => {

    const navigate = useNavigate();
    const {customerProject} = getCustomerProjectWithPrefix();

    useEffect(()=>{
        // @ts-ignore
        window.statisticsUseEffect();
    },[])

    const goToProjectPage = ()=>{
        navigate(customerProject);
    }

    return <PageContainer newPage={false} headerText={translate("statistics.title")} left={<StyledButton onClick={goToProjectPage} >{translate("project")}</StyledButton>}/>
}

export default Statistics;