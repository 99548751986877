define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', 'moment', './SelectInspectionPageHtml',
	'../model/user', '../model/currentProject', '../model/inspections', '../model/currentInspection',
	'../helper/backboneReactCommunicationUtils'
], function (
		$, _, Backbone, moment, Template,
		user, currentProject, inspections, currentInspection, backboneReactCommunicationUtils
) {
	return Backbone.View.extend({

		id: 'selectInspection',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		events: {
			'vclick .list a': function (e) {
				$.mobile.loading('show');
				var inspectionId = $(e.currentTarget).parent().attr('data-id');
				this.router.requestBundlePage.setModel(inspections.get(inspectionId));
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/requestBundle');
			}
		},

		initialize: function (options) {
			this.router = options.router;

			this.resize = _.bind(this.resize, this);

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);

			this.$list = this.$('.list');

			this.$el.on('pageshow', this.resize);
    		$(window).on('throttledresize', this.resize);
		},

		remove: function() {
			$(window).off('throttledresize', this.resize);
			Backbone.View.prototype.remove.apply(this, arguments);
		},

		render: function () {
			this.$list.empty();
			if (inspections.size() === 0) {
				$('<li class="none">').text(user.translate('inspections.noinspections')).appendTo(this.$list);
			}
			inspections.each(function (inspection) {
				var $item = $('<li>')
					.attr('data-id', inspection.id)
					.appendTo(this.$list);
				$('<a>')
					.attr('href', 'javascript:void(0)')
					.text(inspection.getLabel())
					.appendTo($item);
			}, this);
			this.$list.listview('refresh');
			return this;
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active') && !self.$el.hasClass('hide-page')) {
					var listMargin = parseInt(self.$list.css('margin-bottom')) + parseInt(self.$list.css('margin-top'));
					self.$list.hide();
					var last = self.$('.ui-content > *:visible').eq(-1);
					var pageOffset = parseInt(self.$el.css('padding-top'));
					var height = Math.floor(self.$el.height() - last.position().top - last.outerHeight(true) +
						pageOffset - listMargin) - 1;
					self.$list.height(height).show();
				}
			}, this);
		}

	});
});