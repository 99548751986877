// @ts-nocheck

import {versionCheck} from "../service/watchdog";
import {serviceDataCommunicationCommand, serviceDataCommunicationSource} from "./constants";
import {v4 as uuidv4} from 'uuid';
import store from "../store/store";
import {CommonStoreStateKeys} from "../store/slices/commonStoreSlice";
import {getAttachmentSources} from "../service/apis";

export enum APP_FUNCTION_NAME {
    SAVE_LOGIN_COOKIE = "saveLoginCookie",
    SAVE_CUSTOMER_PROJECT = "saveCustomerProject",
    AUTO_LOGIN = "doAutologin",
    OPEN_LINK = "openLink",
    AUTO_LOGIN_SAVE = "autologinSave",
    GET_INFO_FOR_STARTING_APP = "getInfoForStartingApp",
    GET_LAST_MODIFIED = "getLastModified",
    SYNC_PLANS = "plansSync",
    SYNC_ATTACHMENTS = "attachmentsSync",
    GO_TO_APP_STORE = "goToAppStore",
    TO_QM = 'toQm',
    TO_FD2 = 'toFd2',
    TO_OT2 = 'toOt2',
    SHOW_QUESTION = 'showQuestion'

}

let plansSyncLastModified = [];
let attachmentsSyncLastModified = [];

export function getLastModified(isPlan, projectId) {
    let lm = (isPlan ? plansSyncLastModified : attachmentsSyncLastModified)
        .find(m => m.project === projectId);
    return !!lm ? lm.lastmodified : undefined;
}

export async function operateDataFromAnApplication(json:any, dataSource) {
    if(typeof json === "string"){
        json = JSON.parse(json);
    }
    if(dataSource === serviceDataCommunicationSource.FROM_QM || dataSource === serviceDataCommunicationSource.FROM_OT2) {
        const fromQm = dataSource === serviceDataCommunicationSource.FROM_QM;
        if(json.command === serviceDataCommunicationCommand.CREATE_DEFECT) {
            let attachmentSource = {
                id:uuidv4(),
                index:      -1,
                type:       fromQm ? 'CHECKLIST' : "BAUTAGEBUCH",
                file:       null,
                context:    {},
                properties: fromQm ? { checklistId: json.checklistId, questionId: json.questionId, defectUuid: uuidv4() } : {reportDate: json.reportDate, reportIndex: json.reportIndex, defectUuid: uuidv4()}
            }
            let params = {
                ...json,
                type: 'technical',
                status: 'fix',
                attachmentSources: [ attachmentSource ],
                mode: fromQm ? 'saveandchecklist' : "saveandbautagebuch"
            }
            window.navigateCallback(`/project/${params.customerId}-${params.projectId}/defect/c`, params);
        } else if(json.command === serviceDataCommunicationCommand.SHOW_DEFECT) {
            let defectUuid  = json.defectUuid;

            let defectId = null;
            let attachmentSources = store.getState().commonReducer[CommonStoreStateKeys.attachmentSources];

            if (attachmentSources.length === 0) {
                attachmentSources = (await getAttachmentSources(json.projectId)).data;
            }

            defectId = attachmentSources.find((attachmentSource: any) =>
                attachmentSource?.properties?.defectUuid === defectUuid
            )?.defect || null;
            if(!defectId) {
                return;
            }
            let customerId = json.customerId;
            let projectId = json.projectId;
            window.navigateCallback(`/project/${customerId}-${projectId}/defect/n${defectId}`);
        } else if(json.command === serviceDataCommunicationCommand.LINK_DEFECTS) {
            const params = {
                ...json,
                command: json.command,
                type:       fromQm ? 'CHECKLIST' : "BAUTAGEBUCH",
                location:   json.location,
                locationId: json.locationId,
                properties: fromQm ? { checklistId: json.checklistId, questionId: json.questionId} : {reportDate: json.reportDate, reportIndex: json.reportIndex}
            }
            const uuid = uuidv4();
            window.navigateCallback(`/project/${params.customerId}-${params.projectId}/defects/${uuid}`, params);
        }
    }

}

window.msgcallbacks = {
    cntr: 0,

    connectivity: function () {

    },

    setPlansSyncLastModified: function (project, lastmodified) {
        if(!project || !lastmodified) {
            return;
        }
        plansSyncLastModified = plansSyncLastModified.filter(pm => pm.project !== project);
        plansSyncLastModified.push({project, lastmodified})
    },

    setAttachmentsLastModified: function (project, lastmodified) {
        if(!project || !lastmodified) {
            return
        }
        attachmentsSyncLastModified = attachmentsSyncLastModified.filter(am => am.project !== project);
        attachmentsSyncLastModified.push({project, lastmodified})
    },

    versionCallTrigger: function () {
        versionCheck();
    },

    [serviceDataCommunicationSource.FROM_QM]: (data:any) => {
        operateDataFromAnApplication(data, serviceDataCommunicationSource.FROM_QM);
    },
    [serviceDataCommunicationSource.FROM_FD2]: (data:any) => {
        operateDataFromAnApplication(data, serviceDataCommunicationSource.FROM_FD2);
    },
    [serviceDataCommunicationSource.FROM_OT2]: (data:any) => {
        operateDataFromAnApplication(data, serviceDataCommunicationSource.FROM_OT2);
    },
    testSendDataFromAnotherApp: function (path:any) {
        window.navigateCallback(path)
    },
}


export const isIOSApp     = !!(window.webkit && window.webkit.messageHandlers != null);
export const isAndroidApp = navigator.userAgent.match(/(android)/i) != null && typeof androidapp !== 'undefined';
export const isWindowsApp = navigator.userAgent.match(/(Windows)/i) != null && typeof window.windowsapp !== 'undefined';
export const isWebApp     = !isIOSApp && !isAndroidApp && !isWindowsApp;

export function isFunctionAvailableInAPP(functionName: APP_FUNCTION_NAME | string) {
    if(isAndroidApp) {
        return ((functionName) in window.androidapp)
    } else if(isIOSApp) {
        return typeof window.webkit.messageHandlers[functionName] !== 'undefined' &&
            typeof window.webkit.messageHandlers[functionName].postMessage !== 'undefined'
    } else if(isWindowsApp) {
        return window.windowsapp[''+functionName] !== undefined
    }
    return false;
}

export function callFunctionInApp(funcName: APP_FUNCTION_NAME|string, argument: any, callback?: ((res:any)=>void) | {callbackName: string, callback: ((res:any)=>void)}, notAvailableCallback?:(()=>void)) {
    if(!isFunctionAvailableInAPP(funcName)) {
        if(!!notAvailableCallback) {
            notAvailableCallback();
        }
        return;
    }
    if(!window.msgcallbacks) {
        return;
    }
    if(!!callback && !callback.callbackName) {
        let id = 'f'+window.msgcallbacks.cntr;
        window.msgcallbacks.cntr++;
        window.msgcallbacks[id] = (res) => {
            callback(res);
            window.msgcallbacks[id] = null;
        }
        if(isIOSApp) {
            window.webkit.messageHandlers['' + funcName].postMessage({
                callback: id,
                argument: argument
            });
        } else if(isAndroidApp) {
            window.androidapp['' + funcName](JSON.stringify({
                callback: id,
                argument: argument
            }));
        }
    } else {
        if(!!callback && !!callback.callbackName && !!callback.callback) {
            window.msgcallbacks[callback.callbackName] = (res) => {
                callback.callback(res);
                window.msgcallbacks[callback.callbackName] = null;
            }
        }
        if(isIOSApp) {
            window.webkit.messageHandlers['' + funcName].postMessage({argument});
        } else if(isAndroidApp) {
            window.androidapp['' + funcName](JSON.stringify({argument}));
        }
    }
}
