define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './PrincipalBundlePageHtml', '../view/PrincipalDetails',
	'../model/PrincipalPaperwork', '../model/paperwork', '../model/currentProject', '../model/currentInspection', '../model/user',
	'../helper/backboneReactCommunicationUtils', '../lib/jquery.scrollTo'
], function (
	$, _, Backbone, Template, PrincipalDetails,
	PrincipalPaperwork, paperwork, currentProject, currentInspection, user, backboneReactCommunicationUtils
) {
	return Backbone.View.extend({

		id: 'principal-bundle',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		showFirstCount: 0,
		itemCount: 0,
		pageSize: 30,
		checkedIndices: {},

		events: {
			'click .list .ui-checkbox': function (e) {
				var $input = $(e.currentTarget).children('input.selectItem');
				var state = $input.prop("checked");
				if (!$(e.target).is('input')) {
					state = !state;
					$input.prop("checked", state).focus();
				}
				var index = $input.parents('[data-index]').attr('data-index');
				if (state) {
					this.checkedIndices[index] = true;
				} else {
					delete this.checkedIndices[index];
				}
			},
			'vclick .moreRows button':  function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.renderList({ dontEmpty: true });
				var $children = this.$list.children();
				var $lastElement = $children.get($children.length - 1);
				this.$list.scrollTo($lastElement, 500);
			},
			'vclick .requestBundle':    function (e) {
				$.mobile.loading('show');
				e.preventDefault();
				e.stopPropagation();
				this.router.requestBundlePage.setModel('principal');
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/requestBundle');
			},
			'vclick .openFile':         function (e) {
				e.preventDefault();
				e.stopPropagation();

					var $button = $(e.currentTarget);
					var index = $button.parents('[data-index]').attr('data-index');
					var files = this.principalPaperwork.get('children');
					var file = files[index];
				this.router.imagePage.setModel(new PrincipalPaperwork(file));
				this.router.imagePage.setupPageForDocument();
					window.showPageCallback = function () {
						this.router.imagePage.setupPageForDocument();
					}.bind(this);
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/image', {callback: true});

			}
		},

		initialize: function (options) {
			this.router = options.router;
			this.resize = _.bind(this.resize, this);
			this.principalPaperwork = new PrincipalPaperwork();

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.principalDetails = new PrincipalDetails();
			this.$el.children('[data-role="content"]').prepend(this.principalDetails.$el);

			this.$list = this.$('.list');
			this.$whichDefects = this.$('.whichDefects');

			$(window).on('throttledresize', this.resize);
			this.$el.on('pageshow', this.resize);
		},

		render: function () {

			var newDefectsPrincipal = currentProject.get('newDefectsPrincipal');
			this.$('.ui-header .newDefects')
			.text(!!newDefectsPrincipal ? newDefectsPrincipal : '')
			.toggleClass('zarro', !newDefectsPrincipal || newDefectsPrincipal == 0);

			this.principalDetails.render();

			this.refreshList();

			return this;
		},

		refreshList: function (options) {
			var files = this.principalPaperwork.get('children');
			this.filesCount = files.length;
			this.itemCount = 0;

			if (options && options.newListContext) {
				this.showFirstCount = 0;
			}

			this.renderList();
		},

		renderList: function (options) {
			options = options || {};
			if (!options.dontEmpty) {
				this.$list.empty();
			}
			var files = this.principalPaperwork.get('children');

			if (this.filesCount === 0) {
				$('<li class="none">').text(user.translate('subcontractor.bundle.nodocuments')).appendTo(this.$list);
			} else {
				for (var i = 0; i < this.pageSize && this.hasMoreItemsInList(); i++, this.itemCount++) {
					this.renderDocument(files[this.itemCount]);
				}

				if (this.hasMoreItemsInList()) {
					if (!options.noRecursion) {
						var recursionOptions = {
							dontEmpty:   true,
							noRefresh:   true,
							noRecursion: true
						};
						while (this.itemCount < this.showFirstCount && this.hasMoreItemsInList()) {
							this.renderList(recursionOptions);
						}
						if (this.hasMoreItemsInList()) {
							if (this.$moreRows) {
								this.$moreRows.detach();
								this.$list.append(this.$moreRows);
							} else {
								this.$moreRows = $('<li class="moreRows"><button>' + user.translate('subcontractor.bundle.more') + '</button></li>').appendTo(this.$list).enhanceWithin();
							}
						}
					}

				} else if (!options.noRecursion && this.$moreRows) {
					this.$moreRows.detach();
				}

				if (!options.noRecursion) {
					this.showFirstCount = this.itemCount;
				}
			}

			if (!options.noRefresh) {
				this.$list.listview('refresh');
			}
		},

		hasMoreItemsInList: function () {
			return this.itemCount < this.filesCount;
		},

		renderDocument: function (file) {
			var files = this.principalPaperwork.get('children');
			var documentIndex = _.indexOf(files, file);
			var $item = $('<li></li>').appendTo(this.$list);
			$item = $('<div class="ui-grid-a ui-corner-all"></div>').appendTo($item).attr('data-index', documentIndex);
			//$item.append('<input type="checkbox" class="selectItem ui-btn ui-block-a"/>');
			$('<div class="selectItem ui-btn ui-block-a" style="height: 38px; background: url(' +  '/onlineBauabnahme/images/fileicon.png) no-repeat center bottom"/></div>')
				.appendTo($item)
				.click(_.bind(function () {
					window.open('/onlineBauabnahme/api/downloadFile?project=' + window.encodeURIComponent(currentProject.id) + '&path=' + window.encodeURIComponent(file.path));
				}, this));
			$item.append($('<button class="openFile ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r"></button>').text(file.name));
			$item.enhanceWithin();
		},

		renderTextDivider: function (key) {
			$('<li data-role="list-divider" data-theme="b"></li>').text(key).appendTo(this.$list);
		},

		refreshSelections: function () {
			this.$list.find('input.selectItem').prop('checked', false);
			_.each(this.checkedIndices, function (val, index) {
				this.$list.find('[data-index="' + index + '"] input.selectItem').prop('checked', true);
			}, this);
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active' && !self.$el.hasClass('hide-page'))) {
					var listMargin = parseInt(self.$list.css('margin-bottom')) + parseInt(self.$list.css('margin-top'));
					self.$list.hide();
					var last = self.$('.ui-content > *:visible').eq(-1);
					var pageOffset = parseInt(self.$el.css('padding-top'));
					var height = Math.floor(self.$el.height() - last.position().top - last.outerHeight(true) +
					                        pageOffset - listMargin) - 1;
					self.$list.height(height).show();
				}
			}, this);
		},

		remove: function() {
			$(window).off('throttledresize', this.resize);
			Backbone.View.prototype.remove.apply(this, arguments);
		}

	});
});