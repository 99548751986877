import {createTheme} from '@mui/material/styles';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {Dialog, TableCell, TextField} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#ee7f00",
        },
        secondary: {
            main: '#E0C2FF',
            light: '#F5EBFF',
            contrastText: '#47008F',
        },
    },
});


export const CustomizedDialog = styled(Dialog)({
    '& .MuiPaper-root': {
        width: '100%',
        maxWidth: 'calc(70vw)'
    }
})

export const TableHeadCell = styled(TableCell)({
    fontSize: '1rem',
    fontWeight: 'bold'
})

export const CustomizedDisabledTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        '&:disabled': {
            '-webkit-text-fill-color': '#444'
        }
    }
})

export const FlexContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
})