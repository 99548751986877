// Copyright 2014, Open Experience GmbH. All rights reserved.

define([
    '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './BuyerRolesHtml', '../model/user', '../model/currentProject', '../model/currentInspection', '../model/buyerAccesses', '../model/BuyerAccess', '../model/buyerRoles', '../model/BuyerRole', '../model/ProjectFile',
    '../view/Popup', '../helper/backboneReactCommunicationUtils'
], function ($, _, Backbone, Template, user, currentProject, currentInspection, buyerAccesses, BuyerAccess, buyerRoles, BuyerRole, ProjectFile,
             Popup, backboneReactCommunicationUtils) {
    'use strict';

    return Backbone.View.extend({

        id: 'buyerroles',

        attributes: {
            'data-role': 'page'
        },

        events: {
            'change select.role': 'roleChanged',
            'vclick .role-save': 'roleSave',
            'vclick .role-delete': 'roleDelete',
            'vclick a.back': 'back',
        },

        roleCheckboxes: {
            'enableLocation': 'role-enable-location',
            'enableCrew': 'role-enable-crew',
            'enableImages': 'role-enable-images',
            'enableComments': 'role-enable-comments',
            'enableDocuments': 'role-enable-documents',
            'enableContactData': 'role-enable-contactdata'
        },

        template: _.template(Template),
        initialize: function (options) {
            this.router = options.router;

            this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);

            this.$back = this.$('a.back');
            this.$roleSelect = this.$('select.role');
            this.$roleDetails = this.$('.role-details');
            this.$roleName = this.$('.role-name');
            this.$roleSave = this.$('.role-save');
            this.$roleDelete = this.$('.role-delete');
        },

        render: function () {
            $('.back').on('vclick', function (e) {
                this.back(e);
            }.bind(this));
            if (buyerRoles.length === 0 && !this.currentRole) {
                this.currentRole = '__new';
            }

            this.renderRoleSelect();
            this.renderRoleDetails();
        },

        renderRoleSelect: function() {
            this.$roleSelect.empty();
            this.$roleSelect.append(
                $('<option>')
                    .text(user.translate('settings.buyer.chooseRole'))
                    .val('')
            );
            this.$roleSelect.append(
                $('<option>')
                    .text(user.translate('settings.buyer.newRole')) //TODO: translations
                    .val('__new')
            );
            _.each(buyerRoles.sortBy(function(i) { return i.get('name').toLowerCase(); }), function(role) {
                if (!role.id) {
                    return;
                }
                $('<option>')
                    .text(role.get('name'))
                    .val(role.id)
                    .appendTo(this.$roleSelect);
                $('<option>')
                    .text(role.get('name'))
                    .val(role.id)
                    .appendTo(this.$documentsRoleSelect);
            }.bind(this));

            this.$roleSelect.selectmenu().val(this.currentRole || '');
            this.$roleSelect.selectmenu('refresh');
        },

        renderRoleDetails: function() {
            var isNew = !!(this.currentRole === '__new');
            console.log('isnew', isNew);
            this.$roleDelete.toggle( !isNew );
            this.$roleSave.toggleClass('button-alone', isNew);

            if (this.currentRole) {
                var roleData = buyerRoles.findWhere({id: this.currentRole});
                if (!roleData) {
                    roleData = new BuyerRole();
                    var settings = {};
                    _.each(_.keys(this.roleCheckboxes), function(i) {
                        settings[i] = true;
                    }.bind(this));
                    roleData.set('settings', settings);
                }
                this.$roleName.val(roleData.get('name'));

                var settings = roleData.get('settings') || {};
                _.each(_.keys(this.roleCheckboxes), function(i) {
                    var $el = $('#' + this.roleCheckboxes[i]);
                    $el.checkboxradio();
                    $el.prop('checked', !!settings[i]).checkboxradio('refresh');
                }.bind(this));
                $('#role-notes').val(settings.notes || '');

                this.$roleDetails.show();
            } else {
                this.$roleDetails.hide();
            }
        },

        roleSave: function() {
            if (this.currentRole) {
                var name = this.$roleName.val();

                if (!name.trim()) {
                    alertPopup("Name darf nicht leer sein");
                    return;
                }

                var roleData;
                if (this.currentRole === '__new') {
                    roleData = new BuyerRole();
                    roleData.set('project', currentProject.id);
                } else {
                    roleData = buyerRoles.findWhere({id: this.currentRole});
                    if (!roleData) {
                        alertPopup("Interner Fehler");
                        return;
                    }
                }
                roleData.set('name', name);

                var settings = roleData.get('settings') || {};
                _.each(_.keys(this.roleCheckboxes), function (i) {
                    var $el = $('#' + this.roleCheckboxes[i]);
                    settings[i] = !!$el.is(':checked');
                }.bind(this));
                settings.notes = $('#role-notes').val();
                roleData.set('settings', settings);


                var isNew = roleData.isNew();
                if (isNew) {
                    buyerRoles.add(roleData);
                }
                $.mobile.loading('show');
                $("body").addClass('ui-disabled');
                roleData.save().then(function() {
                    if (isNew) {
                        this.currentRole = roleData.id;
                    }
                    $.mobile.loading('hide');
                    $("body").removeClass('ui-disabled');
                    this.render();
                }.bind(this), function() {
                    $.mobile.loading('hide');
                    $("body").removeClass('ui-disabled');
                    alertPopup("Fehler beim Speichern der Rolle");
                }.bind(this));
            }
        },

        roleDelete: function() {
            if (this.currentRole) {
                var roleData = buyerRoles.findWhere({id: this.currentRole});
                if (!roleData) {
                    alertPopup("Rolle konnte nicht gefunden werden.");
                    return;
                }

                window.confirmPopup("Sind Sie sicher?").then(function() {
                    $.mobile.loading('show');
                    $("body").addClass('ui-disabled');
                    roleData.destroy().then(function() {
                        //TODO: hide spinner
                        this.currentRole = null;
                        this.render();
                    }.bind(this), function() {
                        window.alertPopup("Fehler beim Löschen der Rolle");
                    }.bind(this))
                    .always(function() {
                        $.mobile.loading('hide');
                        $("body").removeClass('ui-disabled');
                    });
                }.bind(this));
            }
        },

        roleChanged: function() {
            this.currentRole = this.$roleSelect.val();
            console.log('ff', this.currentRole)
            this.renderRoleDetails();
        },

        documentsToRole: function(e) {
            e.preventDefault();
            e.stopPropagation();

            var roleId = this.$documentsRoleSelect.val();
            if (!roleId) {
                return;
            }

            this.router.filesPage.model = new ProjectFile({
                path: '/_buyer/_' + roleId + '/',
                type: 'Folder'
            });
            this.router.filesPage.model.project = currentProject.id;
            this.router.filesPage.model.load().done(_.bind(function () {
                this.router.triggerFiles(currentProject.id, currentInspection.id, false, true);
            }, this));
        },

        documentsPreview: function(e) {
            e.preventDefault();
            e.stopPropagation();

            var $target = $(e.target);
            var roleId = $target.data('role');
            var locationId = $target.data('location');

            this.router.filesPage.model = new ProjectFile({
                path: '/_buyer/_preview/_' + roleId + '/_' + locationId + '/',
                type: 'Folder'
            });
            this.router.filesPage.model.project = currentProject.id;
            this.router.filesPage.model.load().done(_.bind(function () {
                this.router.triggerFiles(currentProject.id, currentInspection.id, false, true);
            }, this));
        },

        back: function(e) {
            window.navigateCallback(backboneReactCommunicationUtils.getCustomerProject()+'/buyerAccess');
        }

    });
});
