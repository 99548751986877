// Copyright 2014, Open Experience GmbH. All rights reserved.

define([
	'../lib/jquery-1.11.0', '../lib/backbone-1.1.2', './InspectionDetailsHtml', '../model/user',
	'../helper/iosapp', '../helper/backboneReactCommunicationUtils'
], function (
	$, Backbone, Template, user,
		iosapp, backboneReactCommunicationUtils
) {
	'use strict';

	return Backbone.View.extend({

		className: 'inspectionDetails ui-bar ui-bar-a textselect',

		events: {},

		template: _.template(Template),

		initialize: function (options) {
			this.router = options.router;

			this.$el.append(this.template({ t: user.translate }));

			this.$heading   = this.$('h2');
			this.$table     = this.$el.children('table');
			this.$protocols = this.$('.protocols');
		},

		render: function () {
			var participants = _.compact(_.map(this.model.get('participants'), function (participant) {
				if (!participant.name && !participant.role) {
					return null;
				}
				if (!participant.name) {
					return participant.role;
				}
				if (!participant.role) {
					return participant.name;
				}
				return participant.name + ' (' + participant.role + ')';
			}));

            var emailReceivers = _.compact(_.map(this.model.get('emailReceivers') || [], function (participant) {
                if (!participant.name && !participant.role) {
                    return null;
                }
                if (!participant.name) {
                    return participant.role;
                }
                if (!participant.role) {
                    return participant.name;
                }
                return participant.name + ' (' + participant.role + ')';
            }));

			this.$heading.text(this.model.getLabel());

			this.$table.empty();
			if (participants.length > 0) {
				this.$table.append($('<tr><td>' + user.translate('inspection.participants') + ' </td></tr>').append($('<td>').append($('<div>').text(participants.join(', ')).css({"max-height":"55px","overflow":"hidden"}))));
			}
            if (emailReceivers.length > 0) {
                this.$table.append($('<tr><td>' + user.translate('inspection.email.receivers') + ' </td></tr>').append($('<td>').append($('<div>').text(emailReceivers.join(', ')).css({"max-height":"55px","overflow":"hidden"}))));
            }
			//this.$table.append($('<tr><td>' + user.translate('inspection.date') + ' </td></tr>').append($('<td></td>').text(moment(this.model.get('date'), 'YYYY-MM-DD').format('LL'))));

			var protocols = this.model.get('protocols');
			this.$protocols.empty();
			if (protocols && protocols.children && protocols.children.length > 0) {
				_.each(protocols.children, function (file) {
					if (file.name.toLowerCase().indexOf('.pdf') !== file.name.length - '.pdf'.length) {
						return;
					}

					if (user.isPrincipal()) {
						if (file.name.toLowerCase().indexOf('.intern.pdf') == file.name.length - '.intern.pdf'.length) {
							return;
						}
					}
					
					var $item = $('<li></li>').appendTo(this.$protocols);
					$item = $('<div class="ui-grid-a ui-corner-all"></div>').appendTo($item);
					$('<div class="selectItem ui-btn ui-block-a" style="height: 38px; background: url(' +  '/onlineBauabnahme/images/fileicon.png) no-repeat center bottom">')
						.appendTo($item)
						.click(_.bind(function () {
							var link =  '/onlineBauabnahme/api/downloadFile?project=' + this.model.get('project') + '&path=' + window.encodeURIComponent(file.path);
							if (iosapp.appavailable) {
								var path = file.path;
								var slashIdx = path.lastIndexOf('/');
								if (slashIdx >= 0) path = path.substr(slashIdx + 1);
								iosapp.downloadAndSharePdf(window.location.origin + link, path);
							} else {
								window.open(link);
							}
						}, this));
					$('<button class="openFile ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r">')
						.text(file.name)
						.appendTo($item)
						.on('vclick', _.bind(function (e) {
							e.preventDefault();
							e.stopPropagation();
							this.router.imagePage.setModel(new Backbone.Model(file));
							this.router.imagePage.setupPageForDocument();
							window.showPageCallback = function () {
								this.router.imagePage.setupPageForDocument();
							}.bind(this);

							window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/image', {callback:true});

						}, this));
					$item.enhanceWithin();

				}, this);
				//this.$protocols.enhanceWithin();
				this.$protocols.listview('refresh');
			}  else if (!protocols) {
				this.$protocols.css('overflow', 'hidden');
				$('<div class="offlineMsg">').text(user.translate('inspections.protocols.offline')).appendTo(this.$protocols);
			}

			var itype = this.model.getType();
			this.$('.externalWarning').toggle(
					(protocols && protocols.children && protocols.children.length > 0
					&& itype && itype.external == 'yes'
					&& !user.isSubcontractor() && !user.isPrincipal() && !user.isReadonly()) || !!protocols
			);

		},

		setModel: function (model) {
			this.model = model;
		}

	});

});