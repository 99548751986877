define([
	'../lib/jquery-1.11.0', '../lib/backbone-1.1.2',
	'../model/Project', '../model/proxyInfo'
], function (
	$, Backbone,
	Project, proxyInfo
) {
	return new (Backbone.Collection.extend({

		model: Project,

		url:  '/onlineBauabnahme/api/projects',

		fetchIfEmpty: function () {
			if (this.size() > 0) {
				return $.Deferred().resolve();
			}
			return this.fetch({ reset: true });
		},

		sync: function (method, model, options) {
			return Backbone.sync.apply(this, [method, model, proxyInfo.syncAddHeaders(options)]);
		}

	}));
});