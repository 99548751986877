define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div id='inspection-new-page-header' data-role=\"header\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\t<select id=\"inspectionType\"></select>\n" +
        "\n" +
        "\t<div class=\"inspection-details\">\n" +
        "\n" +
        "\t\t<div class=\"unit\" data-mini=\"true\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t<fieldset data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\"></fieldset>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<input id=\"subcontractor\" type=\"text\" placeholder=\"<%= t('inspection.new.subcontractor') %>\" />\n" +
        "\n" +
        "\t\t<input id=\"inspectionReference\" type=\"text\" placeholder=\"<%= t('inspection.new.inspection') %>\" />\n" +
        "\n" +
        "\t\t<input id=\"inspectionDate\" type=\"date\" data-wrapper-class=\"controlgroup-textinput ui-btn\" />\n" +
        "\n" +
        "\t\t<input id=\"inspectionDescription\" type=\"text\" />\n" +
        "\n" +
        "\t\t<input id=\"inspectionDescription2\" type=\"text\" />\n" +
        "\n" +
        "\t\t<label style=\"margin-top: 2em\">\n" +
        "\t\t\t<%= t('inspection.new.name') %>\n" +
        "\t\t</label>\n" +
        "\t\t<input data-shortcut=\"focusInput1\" data-inputname=\"inspectionTitle\" id=\"inspectionName\" type=\"text\"\n" +
        "\t\t\tplaceholder=\"<%= t('inspection.new.name') %>\" />\n" +
        "\n" +
        "\t\t<label>\n" +
        "\t\t\t<input id=\"linkToCurrent\" type=\"checkbox\" />\n" +
        "\t\t\t<%= t('inspection.linkto.current') %>\n" +
        "\t\t</label>\n" +
        "\n" +
        "\t\t<label title=\"<%= t('inspection.topics.tonext.explanation') %>\">\n" +
        "\t\t\t<input id=\"topicsToNext\" type=\"checkbox\" title=\"<%= t('inspection.topics.tonext.explanation') %>\" />\n" +
        "\t\t\t<%= t('inspection.topics.tonext') %>\n" +
        "\t\t</label>\n" +
        "\n" +
        "\t\t<label>\n" +
        "\t\t\t<input id=\"topics\" type=\"checkbox\" />\n" +
        "\t\t\t<%= t('inspection.topics') %>\n" +
        "\t\t</label>\n" +
        "\t\t<div class=\"topics\">\n" +
        "\t\t\t<label for=\"topics-upload\"><%= t('inspection.topics.upload') %>\n</label>\n" +
        "<input id=\"topics-upload\" class=\"uploadTopics\" type=\"file\" accept=\".xls,.xlsx\"/>" +
        "\t\t\t<button class=\"downloadTopics\"><%= t('inspection.topics.download') %></button>\n" +
        "\t\t<div class=\"topics-editmode\">\n" +
        "\t\t\t<label><%= t('inspection.topics.edit') %>:</label>\n" +
        "\t\t\t<input type=\"radio\" name=\"edit-mode\" value=\"subtopics-mode\">\n" +
        "\t\t\t<label class=\"edit-mode-subtopics ui-btn ui-btn-inline ui-corner-all\" for=\"subtopics-mode\"><%= t('inspection.topics.edit.subtopics') %></label>\n" +
        "\t\t\t<input type=\"radio\" name=\"edit-mode\" value=\"delete-mode\">\n" +
        "\t\t\t<label class=\"edit-mode-delete ui-btn ui-btn-inline ui-corner-all\" for=\"delete-mode\"><%= t('inspection.topics.edit.delete') %></label>\n" +
        "\t\t\t<input type=\"radio\" name=\"edit-mode\" value=\"move-mode\">\n" +
        "\t\t\t<label class=\"edit-mode-move ui-btn ui-btn-inline ui-corner-all\" for=\"move-mode\"><%= t('inspection.topics.edit.move') %></label>\n" +
        "\t\t</div>\n" +
        "\t\t\t<div class=\"headline ui-field-contain\">\n" +
        "\t\t\t\t<label class=\"topics-headline-index\">\n" +
        "\t\t\t\t\t<%= t('inspection.topics.headline.index') %>\n" +
        "\t\t\t\t</label>\n" +
        "\t\t\t\t<label class=\"topics-headline-name\">\n" +
        "\t\t\t\t\t<%= t('inspection.topics.headline.name') %>\n" +
        "\t\t\t\t</label>\n" +
        "\t\t\t</div>\n" +
        "\t\t\t<div class=\"topicslist\">\n" +
        "\t\t\t</div>\n" +
        "\t\t\t<div class=\"add-topic-button-box\">\n" +
        "\t\t\t\t<button class=\"addtopic ui-btn ui-btn-inline ui-corner-all ui-btn-icon-left ui-icon-plus\"><%= t('inspection.topics.addnewtopic') %></button>\n" +
        "\t\t\t</div>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"syncInspectionContainer\">\n" +
        "\t\t\t<label style=\"margin-top: 2em\">\n" +
        "\t\t\t\t<%= t('inspection.sync.title') %>\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<label>\n" +
        "\t\t\t\t<input id=\"syncInspection\" type=\"checkbox\" />\n" +
        "\t\t\t\t<%= t('inspection.sync.option') %>\n" +
        "\t\t\t</label>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t</div>\n" +
        "\n" +
        "\n" +
        "\t<fieldset id=\"inspectionParticipants\">\n" +
        "\t\t<legend>\n" +
        "\t\t\t<%= t('inspection.participants') %>\n" +
        "\t\t</legend>\n" +
        "\t\t<table></table>\n" +
        "\t</fieldset>\n" +
        "\n" +
        "\t<fieldset id=\"inspectionEmailReceivers\">\n" +
        "\t\t<legend>\n" +
        "\t\t\t<%= t('inspection.email.receivers') %>\n" +
        "\t\t</legend>\n" +
        "\t\t<table></table>\n" +
        "\t</fieldset>\n" +
        "\n" +
        "\t<div class=\"dataimport textselect\">\n" +
        "\t\t<form class=\"importform\"><input type=\"file\" /></form>\n" +
        "\t\t<ul class=\"messages\" style=\"display: none\"></ul>\n" +
        "\t\t<div class=\"preview\"></div>\n" +
        "\t\t<button class=\"doImport\">\n" +
        "\t\t\t<%= t('inspection.new.import.do') %>\n" +
        "\t\t</button>\n" +
        "\t\t<a href=\"#\" class=\"continue ui-btn ui-btn-a\" style=\"display: none\">\n" +
        "\t\t\t<%= t('inspection.new.import.continue') %>\n" +
        "\t\t</a>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div data-role=\"popup\" class=\"newParticipantDialog\" style=\"width: 500px; display: none;\">\n" +
        "\t\t<div class=\"ui-content\" style=\"overflow: scroll; height: 80vh\">\n" +
        "\n" +
        "\t\t\t<h2 style=\"font-weight: normal; margin-top: .5em\">\n" +
        "\t\t\t\t<%= t('inspection.participant.known') %>\n" +
        "\t\t\t</h2>\n" +
        "\t\t\t<input type=\"text\" id=\"inspectionNewParticipantChoice\"\n" +
        "\t\t\t\tplaceholder=\"<%= t('inspection.new.searchPerson') %>\" />\n" +
        "\t\t\t<button class=\"addChosenParticipant\">\n" +
        "\t\t\t\t<%= t('terms.add') %>\n" +
        "\t\t\t</button>\n" +
        "\n" +
        "\t\t\t<h2 style=\"font-weight: normal; margin-top: .5em\">\n" +
        "\t\t\t\t<%= t('inspection.new.list') %>\n" +
        "\t\t\t</h2>\n" +
        "\t\t\t<input type=\"text\" id=\"inspectionNewParticipantsListChoice\"\n" +
        "\t\t\t\tplaceholder=\"<%= t('inspection.new.searchPerson') %>\" />\n" +
        "\t\t\t<button class=\"addChosenParticipantsList\">\n" +
        "\t\t\t\t<%= t('terms.add') %>\n" +
        "\t\t\t</button>\n" +
        "\n" +
        "\t\t\t<h2 style=\"font-weight: normal\">\n" +
        "\t\t\t\t<%= t('inspection.participant.new') %>\n" +
        "\t\t\t</h2>\n" +
        "\t\t\t<label>\n" +
        "\t\t\t\t<%= t('inspection.participant.new.name') %> <input type=\"text\" id=\"inspectionNewParticipantName\" />\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<label>\n" +
        "\t\t\t\t<%= t('inspection.participant.new.role') %> <input type=\"text\" id=\"inspectionNewParticipantRole\" />\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<label>\n" +
        "\t\t\t\t<%= t('inspection.participant.new.email') %> <input type=\"email\" id=\"inspectionNewParticipantEmail\" />\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<label>\n" +
        "\t\t\t\t<%= t('inspection.participant.new.phoneNumber') %> <input type=\"text\" id=\"inspectionNewParticipantPhoneNumber\"/>\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<label>\n" +
        "\t\t\t\t<%= t('inspection.participant.new.postalAddress') %> <input type=\"text\" id=\"inspectionNewParticipantPostalAddress\"/>\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<span class=\"warning\" id=\"newParticipantErrorMsg\"></span>\n" +
        "\t\t\t<button class=\"addNewParticipant\">\n" +
        "\t\t\t\t<%= t('terms.add') %>\n" +
        "\t\t\t</button>\n" +
        "\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div data-role=\"popup\" class=\"newReceiverDialog\" style=\"width: 500px; display: none;\">\n" +
        "\t\t<div class=\"ui-content\" style=\"overflow: scroll; height: 80vh\">\n" +
        "\n" +
        "\t\t\t<h2 style=\"font-weight: normal; margin-top: .5em\">\n" +
        "\t\t\t\t<%= t('inspection.participant.known') %>\n" +
        "\t\t\t</h2>\n" +
        "\t\t\t<input type=\"text\" id=\"inspectionNewReceiverChoice\" placeholder=\"<%= t('inspection.new.searchPerson') %>\" />\n" +
        "\t\t\t<span class=\"warning\" id=\"receiverChoiceNoEmail\"></span>\n" +
        "\t\t\t<button class=\"addChosenReceiver\">\n" +
        "\t\t\t\t<%= t('terms.add') %>\n" +
        "\t\t\t</button>\n" +
        "\n" +
        "\t\t\t<h2 style=\"font-weight: normal; margin-top: .5em\">\n" +
        "\t\t\t\t<%= t('inspection.new.list') %>\n" +
        "\t\t\t</h2>\n" +
        "\t\t\t<input type=\"text\" id=\"inspectionNewReceiverListChoice\"\n" +
        "\t\t\t\tplaceholder=\"<%= t('inspection.new.searchPerson') %>\" />\n" +
        "\t\t\t<span class=\"warning\" id=\"receiverListChoiceNoEmail\"></span>\n" +
        "\t\t\t<button class=\"addChosenReceiverList\">\n" +
        "\t\t\t\t<%= t('terms.add') %>\n" +
        "\t\t\t</button>\n" +
        "\n" +
        "\t\t\t<h2 style=\"font-weight: normal\">\n" +
        "\t\t\t\t<%= t('inspection.participant.new') %>\n" +
        "\t\t\t</h2>\n" +
        "\t\t\t<label>\n" +
        "\t\t\t\t<%= t('inspection.participant.new.name') %> <input type=\"text\" id=\"inspectionNewReceiverName\" />\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<label>\n" +
        "\t\t\t\t<%= t('inspection.participant.new.role') %> <input type=\"text\" id=\"inspectionNewReceiverRole\" />\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<label>\n" +
        "\t\t\t\t<%= t('inspection.participant.new.email') %> <input type=\"email\" id=\"inspectionNewReceiverEmail\" />\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<label>\n" +
        "\t\t\t\t<%= t('inspection.participant.new.phoneNumber') %> <input type=\"text\" id=\"inspectionNewReceiverPhoneNumber\"/>\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<label>\n" +
        "\t\t\t\t<%= t('inspection.participant.new.postalAddress') %> <input type=\"text\" id=\"inspectionNewReceiverPostalAddress\"/>\n" +
        "\t\t\t</label>\n" +
        "\t\t\t<span class=\"warning\" id=\"newReceiverNoEmail\"></span>\n" +
        "\t\t\t<button class=\"addNewReceiver\">\n" +
        "\t\t\t\t<%= t('terms.add') %>\n" +
        "\t\t\t</button>\n" +
        "\n" +
        "\t\t</div>\n" +
        "\t</div>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"footer\" id='inspection-new-page-footer' data-position=\"fixed\">\n" +
        "</div>";
    return page;
});