import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton, StyledIconButton} from "@bau/material";
import {EllipsisHorizontalIcon} from "@heroicons/react/24/outline";
import {translate} from "../utils/utils";

const Paperwork = () => {

    useEffect(()=>{
        // @ts-ignore
        window.paperworkUseEffect();
    }, [])


    let right = [
        <StyledIconButton className="options-menu-button actionsTrigger">
            <EllipsisHorizontalIcon/>
        </StyledIconButton>
    ]

    return <PageContainer newPage={false} headerText={translate("paperwork.title")}
                          left={<StyledButton className="previousPage">{translate("paperwork.back")}</StyledButton>}
                          right={right}
    />
}

export default Paperwork;