define([
    'require', '../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2'
], function (
    require, $, _, Backbone
) {
    //this file only exists to not copy code. Here are some error popups which are used at multiple places.
    return {

        //circular dependency
        getUser: function() {
            return require("../model/user");
        },

    };
});