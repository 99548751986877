define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<div id='select-inspection-page-header' data-role=\"header\">\n" +
        "</div>\n" +
        "\n" +
        "<div data-role=\"content\">\n" +
        "\n" +
        "\t<p style=\"margin-bottom: 1ex\"><%= t('selectinspection.header') %></p>\n" +
        "\n" +
        "\t<ul data-role=\"listview\" data-inset=\"true\" class=\"list\"></ul>\n" +
        "\n" +
        "</div>\n" +
        "\n" +
        "<div id='select-inspection-page-footer' data-role=\"footer\" data-position=\"fixed\"></div>\n";
    return page;
});