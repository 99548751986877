define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2',  '../model/recordPoints', '../helper/commonusercomm'
], function (
	$, _, Backbone, recordPoints, commonusercomm
) {

	var iosapp = {
	    _loadevents: [ 'pageshow', 'routerinit' ],
	    _loadeventsseen: [],

		_init: function () {
			//check for ios app
			if (typeof window.webkit !== 'undefined'
				&& typeof window.webkit.messageHandlers !== 'undefined'
				&& typeof window.webkit.messageHandlers.proaapp !== 'undefined'
				&& typeof window.webkit.messageHandlers.proaapp.postMessage !== 'undefined'
			) {
				this.appavailable = true;
				this.iosappavailable = true;
				this.androidappavailable = false;
				this.windowsappavailable = false;
				var that = this;
				window.msgcallbacks.qrcodescanned = function(code) {
					_.each(that._qrcodehandlers, function(callback) {
						if (callback !== null && typeof callback !== 'undefined') {
							if (code !== null) {
								callback(decodeURIComponent(code));
							} else {
								callback(null);
							}
						}
					})
				}
				window.webkit.messageHandlers.proaapp_init.postMessage(null);

				console.log("using ios app");
			}
			else if (navigator.userAgent.match(/(android)/i) != null && typeof androidapp !== 'undefined') {
				this.appavailable = true;
				this.iosappavailable = false;
				this.androidappavailable = true;
				this.windowsappavailable = false;
				var that = this;
				window.msgcallbacks.qrcodescanned = function (code) {
					_.each(that._qrcodehandlers, function (callback) {
						if (callback !== null && typeof callback !== 'undefined') {
							callback(decodeURIComponent(code));
						}
					})
				};
				window.msgcallbacks.handlePhoto =  function (file) {
					if (typeof window.msgcallbacks['photohandler'] !== 'undefined' &&
						window.msgcallbacks['photohandler'] != null) {
						window.msgcallbacks['photohandler'](file);
					}
				};
				console.log("using android app")
			}
			else if (navigator.userAgent.match(/(Windows)/i) != null && typeof window.windowsapp !== 'undefined') {
				this.appavailable = true;
				this.iosappavailable = false;
				this.androidappavailable = false;
				this.windowsappavailable = true;
				var that = this;
				window.msgcallbacks.qrcodescanned = function (code) {
					_.each(that._qrcodehandlers, function (callback) {
						if (callback !== null && typeof callback !== 'undefined') {
							callback(decodeURIComponent(code));
						}
					})
				};
				window.msgcallbacks.handlePhoto =  function (file) {
					if (typeof window.msgcallbacks['photohandler'] !== 'undefined' &&
						window.msgcallbacks['photohandler'] != null) {
						window.msgcallbacks['photohandler'](file);
					}
				};
				console.log("using windows app");
			} else {
				this.appavailable = false;
				this.iosappavailable = false;
				this.androidappavailable = false;
				this.windowsappavailable = false;
			}
		},

		// _connectivitycallback: null,
		// _connectivitycallbackcontext: null,
		_qrcodehandlers: [],
		// _versioncalltriggerhandlers: [],

		setOABIMCallback: function (callback) {
			window.msgcallbacks['fromoabim'] = callback;
		},

		setOADigiBauDemoCallback: function (callback) {
			window.msgcallbacks['fromoadigibaudemo'] = callback;
		},

		createDoorDefect: function (callback) {
			window.msgcallbacks['createDoorDefect'] = callback;
		},

		showDoorDefects: function (callback) {
			window.msgcallbacks['showDoorDefects'] = callback;
		},

		addMeasurePhoto: function (callback) {
			window.msgcallbacks['addMeasurePhoto'] = callback;
		},

		setCallbackFromBaufoto: function(callback) {
			window.msgcallbacks['frombaufoto'] = callback;
		},

		createObject: function(msgCallback) {
			if (this.windowsappavailable) {
				window.windowsapp.createObject(msgCallback);
			} else if (this.appavailable) {
				if (typeof window.webkit !== 'undefined'
		                && typeof window.webkit.messageHandlers !== 'undefined'
		                && typeof window.webkit.messageHandlers.createObject !== 'undefined'
		                && typeof window.webkit.messageHandlers.createObject.postMessage !== 'undefined') {

	                window.webkit.messageHandlers.createObject.postMessage({
                    				callbackCode: msgCallback
                    });
	            }

			}
		},

		showObject: function(msgCallback) {
			if (this.windowsappavailable) {
				window.windowsapp.showObject(msgCallback);
			} else if (this.appavailable) {

				if (typeof window.webkit !== 'undefined'
		                && typeof window.webkit.messageHandlers !== 'undefined'
		                && typeof window.webkit.messageHandlers.showObject !== 'undefined'
		                && typeof window.webkit.messageHandlers.showObject.postMessage !== 'undefined') {

	                window.webkit.messageHandlers.showObject.postMessage({
                    				callbackCode: msgCallback
                    });
	            }
			}
		},

		// setConnectivityCallback: function(callback, context) {
		// 	if (typeof callback === 'undefined') {
		// 		this._connectivitycallback = null;
		// 	} else {
		// 		this._connectivitycallback = callback;
		// 	}
		//
		// 	this._connectivitycallbackcontext = context; //undefined is ok
		// },

		sendEvent: function (event, argument) {
			window.webkit.messageHandlers['' + event].postMessage(argument);
		},

		call: function (name, argument, callback, thisptr) {
			if(!this.iosappavailable && !this.androidappavailable && !this.windowsappavailable) {
				return;
			}
			if(!this.functionavailable(name)) {
				return;
			}
			if(!!callback && !callback.callbackName) {
				var id = 'f' + window.msgcallbacks.cntr;
				window.msgcallbacks.cntr++;
				window.msgcallbacks[id] = function(id, callback, retval) {
					window.msgcallbacks[id] = null;
					callback.call(thisptr, retval);
				}.bind(typeof thisptr !== 'undefined' ? thisptr : this, id, callback);

				if(this.iosappavailable) {
					window.webkit.messageHandlers['' + name].postMessage({
						callback: id,
						argument: argument
					});
				} else if(this.androidappavailable) {
					window.androidapp['' + name](JSON.stringify({
						callback: id,
						argument: argument
					}));
				}
			} else {
				if(!!callback && !!callback.callbackName && !!callback.callback) {
					var funcName = callback.callbackName;
					var func = callback.callback;
					window.msgcallbacks[funcName] = function(funcName, func, retval) {
						window.msgcallbacks[funcName] = null;
						func.call(thisptr, retval);
					}.bind(typeof thisptr !== 'undefined' ? thisptr : this, funcName, func);
				}
				if(this.iosappavailable) {
					window.webkit.messageHandlers['' + name].postMessage({argument: argument});
				} else if(this.androidappavailable) {
					window.androidapp['' + name](JSON.stringify({argument: argument}));
				}
			}

		},

		functionavailable: function (fstr) {
			if (this.androidappavailable) {
				if (fstr == 'qrcode') {
					return (typeof androidapp.scanQrCode !== 'undefined');
				} else if (fstr == 'autologin') {
					return (typeof androidapp.doAutologin !== 'undefined');
				} else if (fstr == 'syncplans') {
					return (typeof androidapp.plansSync !== 'undefined');
				} else if (fstr == 'attachments') {
					return (typeof androidapp.attachmentsSync !== 'undefined');
				} else if (fstr == 'getforceattachment') {
					return (typeof androidapp.getForceAttachment !== 'undefined');
				} else if (fstr == 'getsysteminfo') {
					return (typeof androidapp.getSystemInfo !== 'undefined');
				} else if (fstr == 'fotoexperience') {
					return (typeof androidapp.isFeAvailable !== 'undefined');
				} else if (fstr == 'baufotoShowPhoto') {
					return (typeof androidapp.baufotoShowPhoto !== 'undefined');
				} else if (fstr == 'proaapp_onload') {
					return (typeof androidapp.oaAppOnLoad !== 'undefined');
				} else if (fstr == 'downloadAndSharePdf') {
					return (typeof androidapp.downloadAndSharePdf !== 'undefined');
				} else if (fstr == 'takephotov2') {
					return (typeof androidapp.takePhotoV2 !== 'undefined');
				} else if (fstr == 'getTile') {
                    return (typeof androidapp.getTile !== 'undefined');
                } else if (fstr == 'checklist') {
                    return (typeof androidapp.checklist !== 'undefined');
                }
				return ((fstr) in window.androidapp);
			} else if (this.windowsappavailable) {
				if (fstr == 'qrcode') {
					return (typeof window.windowsapp.scanQrCode !== 'undefined');
				} else if (fstr == 'autologin') {
					return (typeof window.windowsapp.doAutologin !== 'undefined');
				} else if (fstr == 'syncplans') {
					return (typeof window.windowsapp.plansSync !== 'undefined');
				} else if (fstr == 'attachments') {
					return (typeof window.windowsapp.attachmentsSync !== 'undefined');
				} else if (fstr == 'getforceattachment') {
					return (typeof window.windowsapp.getForceAttachment !== 'undefined');
				} else if (fstr == 'getsysteminfo') {
					return (typeof window.windowsapp.getSystemInfo !== 'undefined');
				} else if (fstr == 'fotoexperience') {
					return (typeof window.windowsapp.fotoexperience !== 'undefined');
				} else if (fstr == 'baufotoShowPhoto') {
					return false; //not implemented yet
				} else if (fstr == 'proaapp_onload') {
					return (typeof window.windowsapp.oaAppOnLoad !== 'undefined');
				} else if (fstr == 'downloadAndSharePdf') {
					return (typeof window.windowsapp.downloadAndSharePdf !== 'undefined');
				} else if (fstr == 'takephotov2') {
					return (typeof window.windowsapp.takePhotoV2 !== 'undefined');
				} else if (fstr == 'appLoggedInAndOverviewLoaded') {
					return (typeof window.windowsapp.appLoggedInAndOverviewLoaded !== 'undefined');
				} else if (fstr == 'aufmassShowObject') {
					return (typeof window.windowsapp.showObject !== 'undefined');
				} else if (fstr == 'checklist') {
                    return (typeof window.windowsapp.checklist !== 'undefined');
				} else if (fstr == 'getTile') {
                    return (typeof window.windowsapp.getTile !== 'undefined');
                }
				return window.windowsapp[''+fstr] !== undefined;
			} else {
				if (fstr == 'qrcode') {
					return (typeof window.webkit.messageHandlers.scanqrcode !== 'undefined');
				} else if (fstr == 'autologin') {
					return (typeof window.webkit.messageHandlers.doAutologin !== 'undefined');
				} else if (fstr == 'syncplans') {
					return (typeof window.webkit.messageHandlers.plansSync !== 'undefined');
				} else if (fstr == 'attachments') {
					return (typeof window.webkit.messageHandlers.attachmentsSync !== 'undefined');
				} else if (fstr == 'getforceattachment') {
					return (typeof window.webkit.messageHandlers.getforceattachment !== 'undefined');
				} else if (fstr == 'getsysteminfo') {
					return (typeof window.webkit.messageHandlers.getsysteminfo !== 'undefined');
				} else if (fstr == 'fotoexperience') {
					return (typeof window.webkit.messageHandlers.fotoexperience !== 'undefined');
				} else if (fstr == 'baufotoShowPhoto') {
					return (typeof window.webkit.messageHandlers.fotoexperienceshowphoto !== 'undefined');
				} else if (fstr == 'downloadAndSharePdf') {
					return (typeof window.webkit.messageHandlers.downloadAndSharePdf !== 'undefined');
				} else if (fstr == 'takephotov2') {
					return (typeof window.webkit.messageHandlers.takephoto !== 'undefined' && typeof window.webkit.messageHandlers.takephotov2 !== 'undefined');
				} else if (fstr == 'aufmassShowObject') {
					return (typeof window.webkit.messageHandlers.showObject !== 'undefined');
				} else if (fstr == 'checklist') {
					return (typeof window.webkit.messageHandlers.checklist !== 'undefined');
				} else if (fstr == 'digibau360') {
					return (typeof window.webkit.messageHandlers.digibau360 !== 'undefined');
				} else if (fstr == 'getTile') {
                    return (typeof window.webkit.messageHandlers.gettile !== 'undefined');
                } else if (fstr == 'proxyConfig') {
                    return (typeof window.webkit.messageHandlers.proxyConfig !== 'undefined');
                } else if (fstr == 'sessionstore') {
					return (typeof window.webkit.messageHandlers.sessionstoreget !== 'undefined');
				}
				return typeof window.webkit.messageHandlers[fstr] !== 'undefined' &&
					typeof window.webkit.messageHandlers[fstr].postMessage !== 'undefined';
			}

		},

		takePhoto: function(size, handler, thisptr, id, data) {
			window.msgcallbacks['photohandler'] = _.bind(handler, thisptr);
			if (this.androidappavailable) {
				if (this.functionavailable('takephotov2')) {
					androidapp.takePhotoV2();
				} else {
					androidapp.takePhoto();
				}
			} else if (this.windowsappavailable) {
				window.windowsapp.takePhotoV2(id, data);
			} else {
				this.sendEvent('takephoto', size);
			}
		},

		importPhotoAndroid : function(size, handler, thisptr) {
			window.msgcallbacks['photohandler'] = _.bind(handler, thisptr);
			if (this.androidappavailable) {
				androidapp.takePhoto();
			}
		},

		scanQrCode: function() {
			if (this.androidappavailable) {
				androidapp.scanQrCode();
			} else if (this.windowsappavailable) {
				window.windowsapp.scanQrCode();
			} else {
				this.sendEvent('scanqrcode', null);
			}
		},
		
		openUrlInNativeBrowser: function (url) {
			if (this.androidappavailable) {
				androidapp.openUrlInNativeBrowser(url);
			} else if (this.windowsappavailable) {
				window.windowsapp.openUrlInNativeBrowser(url);
			} else {
				this.sendEvent('openInBrowser', { link: url })
			}
		},


		addQrCodeHandler: function(callback) {
			this._qrcodehandlers.push(callback);
		},

		syncplans: function(project) {
			if (this.androidappavailable) {
				androidapp.syncPlans(project);
			} else if (this.windowsappavailable) {
				window.windowsapp.syncPlans(project);
			} else {
				this.sendEvent('syncplans', project);
			}
		},

		plansuptodate: function(project, files, handler) {
			window.msgcallbacks['plansuptodatehandler'] = handler;
			if (this.androidappavailable) {
				androidapp.plansUpToDate(project, JSON.stringify(files));
			} else if (this.windowsappavailable) {
				window.windowsapp.plansUpToDate(project, JSON.stringify(files));
			} else {
				this.sendEvent('plansuptodate', {project: project, files: files});
			}
		},

		showplan: function(project, file, filesize, fsmodified, imagehandler) {
			var params = new Object();
			params.project = project;
			params.file = file;
			params.filesize = filesize;
			params.fsmodified = fsmodified;
			params.showapply = (typeof imagehandler !== 'undefined' && imagehandler !== null);
			window.msgcallbacks['showplanhandler'] = imagehandler;
			if (this.androidappavailable) {
				androidapp.showPlan(params.project, params.file, params.filesize, params.fsmodified, params.showapply);
			} else if (this.windowsappavailable) {
				window.windowsapp.showPlan(params.project, params.file, params.filesize, params.fsmodified, params.showapply);
			} else {
				this.sendEvent('showplan', params);
			}
		},

		deleteplans: function() {
			if (this.androidappavailable) {
				androidapp.deletePlans();
			} else if (this.windowsappavailable) {
				window.windowsapp.deletePlans();
			} else {
				this.sendEvent('deleteplans', null);
			}
		},

		// planssynclastmodified: null,
		// getprojectplanslastmodified: function() {
		// 	if (this.appavailable && this.functionavailable('projectplanslastmodified')) {
		// 		if (this.androidappavailable) {
		// 			androidapp.projectPlansLastmodified();
		// 		} else if (this.windowsappavailable) {
		// 			window.windowsapp.projectPlansLastmodified();
		// 		} else {
		// 			this.sendEvent('projectplanslastmodified', null);
		// 		}
		// 	}
		// },

		// getplanslastmodifiedforproject: function(project) {
		// 	if (this.planssynclastmodified != null && project != null) {
		// 		var retval = "noteversynced";
		// 		_.each(this.planssynclastmodified, function(el) {
		// 			if  (el.project == project) {
		// 				retval = el.lastmodified;
		// 			}
		// 		});
		// 		return retval;
		// 	} else {
		// 		return null;
		// 	}
		// },

		// attachmentssynclastmodified: null,
		// getattachmentslastmodified: function() {
		// 	if (this.appavailable && this.functionavailable('attachmentslastmodified')) {
		// 		if (this.androidappavailable) {
		// 			androidapp.attachmentsLastmodified();
		// 		} else if (this.windowsappavailable) {
		// 			window.windowsapp.attachmentsLastmodified()
		// 		} else {
		// 			this.sendEvent('attachmentslastmodified', null);
		// 		}
		// 	}
		// },

		// getattachmentslastmodifiedforproject: function(project) {
		// 	if (this.attachmentssynclastmodified != null && project != null) {
		// 		var retval = "noteversynced";
		// 		_.each(this.attachmentssynclastmodified, function(el) {
		// 			if  (el.project == project) {
		// 				retval = el.lastmodified;
		// 			}
		// 		});
		// 		return retval;
		// 	} else {
		// 		return null;
		// 	}
		// },

		// addversioncalltriggerhandler: function(callback) {
		// 	this._versioncalltriggerhandlers.push(callback);
		// },

		syncattachments: function(project) {
			if (this.androidappavailable) {
				androidapp.syncAttachments(project);
			} else if (this.windowsappavailable) {
				window.windowsapp.syncAttachments(project);
			} else {
				this.sendEvent('syncattachments', project);
			}
		},

		getattachment: function(id, amod, handler) {
			window.msgcallbacks['getattachmenthandler'] = handler;
			if (this.androidappavailable) {
				androidapp.getAttachment(id, amod);
			} else if (this.windowsappavailable) {
				window.windowsapp.getAttachment(id, amod);
			} else {
				this.sendEvent('getattachment', {id: id, amod: amod});
			}
		},
		getforceattachment: function(id, amod, handler) {
			window.msgcallbacks['getforceattachmenthandler'] = handler;
			if (this.androidappavailable) {
				androidapp.getForceAttachment(id, amod);
			} else if (this.windowsappavailable) {
				// TODO not really needed
			} else {
				this.sendEvent('getforceattachment', {id: id, amod: amod});
			}
		},

		getTile: function(project, file, x, y, z, handler, hash, filesize, fsmodified) {
			if(this.functionavailable('getTile')) {
                var id = hash + '_' + z + '_' + x + '_' + y;
                window.msgcallbacks[id] = function(id, callback, retval) {
                    window.msgcallbacks[id] = null;
                    callback.call(this, retval);
                }.bind(typeof this !== 'undefined' ? this : this, id, handler);

                if (this.androidappavailable) {
                    androidapp.getTile(project, file, x, y, z, id);
                } else if (this.windowsappavailable) {
                    window.windowsapp.getTile(project, file, x, y, z, id, filesize, fsmodified);
                } else {
					this.sendEvent('gettile', {
						project: project,
						file: file,
						x: x,
						y: y,
						z: z,
						id: id,
						filesize: filesize,
						fsmodified: fsmodified
					});
				}
			}
            return null;
		},

		proxyConfig: function(data, callback) {
		    if (this.appavailable) {
                if (this.functionavailable('proxyConfig')) {
                    if (this.iosappavailable) {
                        this.call('proxyConfig', data, callback);
                    } else {
                        callback(null);
                    }
                } else {
                    callback(null);
                }
            } else {
                callback(null);
            }
		},

		deleteattachments: function() {
			if (this.androidappavailable) {
				androidapp.deleteAttachments();
			} else if (this.windowsappavailable) {
				window.windowsapp.deleteAttachments();
			} else {
				this.sendEvent('deleteattachments', null);
			}
		},

		getCookie: function() {
			var cookie = window.localStorage['iosapp-cookie'];
			if (typeof cookie === 'undefined') return null;
			return cookie;
		},

		setCookie: function(cookie) {
			if (typeof cookie !== 'undefined' && cookie != null) {
				//delete path
				var cdict = cookie.split( ';' ).map( function( x ) { return x.trim().split( '=' ); } ).reduce(function( a, b ) { a[ b[ 0 ] ] = b[ 1 ]; return a; }, {} );
				delete cdict['Path'];
				delete cdict['PATH'];
				delete cdict['path'];
				var newcookie = _.reduce(cdict, function(str, value, key) { return str + (str.length > 0 ? ";" : "") + key + "=" + value}, "");
				cookie = newcookie;
			}
			window.localStorage['iosapp-cookie'] = cookie;

			if (this.functionavailable('setcookie')) {
				this.sendEvent('setcookie', cookie);
			}
		},

		getsysteminfo: function(callback) {
			if (this.appavailable && this.functionavailable('getsysteminfo')) {
				if (this.androidappavailable) {
					callback(JSON.parse(androidapp.getSystemInfo()));
				} else if (this.windowsappavailable) {
					callback(JSON.parse(window.windowsapp.getSystemInfo()));
				} else {
					this.call('getsysteminfo', null, callback);
				}
			} else {
				callback(null);
			}
		},

		// _connectivity: function(connected) {
		// 	if (this._connectivitycallback != null) {
		// 		try {
		// 			this._connectivitycallback.call(this._connectivitycallbackcontext, connected);
		// 		} catch(e) {
		// 			console.log("connectivity callback threw error: " + e.message + ", ignoring");
		// 		}
		// 	}
		// },

		onloadevent: function(source) {
		    this._loadeventsseen.push(source);

            //only notify the app if ALL events in _loadevents have been triggered
		    if (_.difference(this._loadevents, this._loadeventsseen).length == 0) {

                if (this.appavailable) {
                    if (this.androidappavailable) {
                        if (this.functionavailable('proaapp_onload')) {
                            androidapp.oaAppOnLoad();
                        }
                    } else if (this.windowsappavailable) {
                        if (this.functionavailable('proaapp_onload')) {
                            window.windowsapp.oaAppOnLoad();
                        }
                    } else {
                        this.sendEvent('proaapp_onload', null);
                    }
                }
		    }
		},

		appLoggedInAndOverviewLoaded : function () {
			if (this.appavailable) {
				if (this.windowsappavailable) {
					window.windowsapp.appLoggedInAndOverviewLoaded();
				}
			}
		},

		isFEavailable: function (callback) {
			//callback({ available: true, version: 1 }); return;
			if (!this.appavailable || !this.functionavailable('fotoexperience')) {
				return;
			}
			if (this.androidappavailable) {
					callback(JSON.parse(androidapp.isFeAvailable()));
			} else {
				this.call('fotoexperience', null, callback);
			}

		},

		gotoFE: function (customer, project, user) {
			//console.log(customer, project);
			if (!this.appavailable || !this.functionavailable('fotoexperience')) {
				return;
			}
			if (this.androidappavailable) {
				androidapp.toBaufoto(customer, project, user);
			} else {
				window.webkit.messageHandlers.fotoexperiencetobaufoto.postMessage({
					customer: customer,
					project: project,
					user: user
				});
			}
		},

		feTakePhoto: function (tags, user) {
			//console.log(tags);
			if (!this.appavailable || !this.functionavailable('fotoexperience')) {
				return;
			}
			if (this.androidappavailable) {
				androidapp.baufotoTakePhoto(tags, user);
			} else {
				window.webkit.messageHandlers.fotoexperiencetakephoto.postMessage({
					tags: tags,
					user: user
				});
			}
		},

		feShowPhoto: function (tags, customer,project, imageId) {
			if (!this.appavailable || !this.functionavailable('baufotoShowPhoto')) {
				return;
			}
			if (this.androidappavailable) {
				androidapp.baufotoShowPhoto(tags, customer,project, imageId);
			} else {
				window.webkit.messageHandlers.fotoexperienceshowphoto.postMessage({
					tags: tags,
					customer: customer,
					project: project,
					imageId: imageId
				});
			}
		},

		checklist: function (data) {
			//console.log(customer, project);
			if (!this.functionavailable('checklist')) {
				return;
			} else {
                if(this.windowsappavailable) {
                    window.windowsapp.checklist(_.isString(data) ? data : JSON.stringify(data));
                } else if(this.appavailable && !!window.webkit && !!window.webkit.messageHandlers) {
                    window.webkit.messageHandlers.checklist.postMessage(_.isString(data) ? data : JSON.stringify(data));
                } else if(this.androidappavailable) {
                	androidapp.checklist(_.isString(data) ? data : JSON.stringify(data));
				}
			}

		},

		digibau360: function (data) {
			if (!this.appavailable || !this.functionavailable('digibau360')) {
				return;
			} else {
				if(this.windowsappavailable) {
					//no support yet
					//window.windowsapp.digibau360(_.isString(data) ? data : JSON.stringify(data));
				} else if(this.appavailable && !!window.webkit && !!window.webkit.messageHandlers) {
					window.webkit.messageHandlers.digibau360.postMessage(_.isString(data) ? data : JSON.stringify(data));
				} else if(this.androidappavailable) {
					//no support yet
					//androidapp.digibau360(_.isString(data) ? data : JSON.stringify(data));
				}
			}

		},

		downloadAndSharePdf: function(link, filename) {
			if (!this.appavailable || !this.functionavailable('downloadAndSharePdf')) {
				return
			}
			if (this.androidappavailable) {
				androidapp.downloadAndSharePdf(link, filename);
			} else if (this.windowsappavailable) {
				// TODO
			} else {
				window.webkit.messageHandlers.downloadAndSharePdf.postMessage({
					link: link,
					filename: filename
				});
			}
		},

		sessionstoreGet: function(key, callback) {
			if (!this.appavailable || !this.functionavailable('sessionstore')) {
				window.setTimeout(function() {
					callback(null)
				}, 1)
				return
			}
			if (this.androidappavailable) {
				// TODO
			} else if (this.windowsappavailable) {
				// TODO
			} else {
				this.call('sessionstoreget', { key: key }, callback)
			}
		},

		sessionstoreSet: function(key, value) {
			if (!this.appavailable || !this.functionavailable('sessionstore')) {
				return
			}
			if (this.androidappavailable) {
				// TODO
			} else if (this.windowsappavailable) {
				// TODO
			} else {
				window.webkit.messageHandlers.sessionstoreset.postMessage({
					key: key,
					value: value
				});
			}
		},

		sessionstoreReset: function(key) {
			if (!this.appavailable || !this.functionavailable('sessionstore')) {
				return
			}
			if (this.androidappavailable) {
				// TODO
			} else if (this.windowsappavailable) {
				// TODO
			} else {
				window.webkit.messageHandlers.sessionstoreset.postMessage({
					key: key
				});
			}
		},

	};

	iosapp._init();

	return iosapp;
});
