import {EmailType} from "./types/types";

export function getDefaultEmailSubject(emailType: EmailType): string {
    switch (emailType) {
        case 'subcontractorRequest':
            return '%Projektname%: Aufforderung zur Problembeseitigung, %Gewerk%, %Auftragnehmer% (%AuftragnehmerKontakt%)';
        case 'inspection':
            return '%Projektname%: %INSPECTION_TITLE%';
        default:
            return '%Projektname%'
    }
}

export function getDefaultEmailBody(emailType: EmailType): string {
    switch (emailType) {
        case 'subcontractorRequest':
            return 'Sehr geehrte Damen und Herren,\n\n' +
                'Sie sind im Bauprojekt \'%Projektname%\' tätig. Hiermit erhalten Sie ein Formschreiben von Ihrem Auftraggeber, siehe angehängtes Dokument.\n\n' +
                'Projekt: %Projektname% (%ProjektKurz%)\n' +
                'Gewerk: %Gewerk% (%Auftragnehmer%)\n\n' +
                '%AnAccount%Mit freundlichen Grüßen\n' +
                '%Autor%\n';
        case 'warrantyFinished':
        case 'warrantyNotice':
        case 'warrantyReject':
        case 'rejectLetter':
            return 'Sehr geehrte Damen und Herren,\n\n' +
                'im Bauprojekt \'%Projektname%\' wurde ein Mangel begutachtet, siehe angehängtes Dokument.\n\n' +
                'Mit freundlichen Grüßen';
        case 'warrantySendStatus':
            return 'Sehr geehrte Damen und Herren,\n\n' +
                'im Bauprojekt \'%Projektname%\' wurde der Status der Position geändert, siehe angehängtes Dokument.\n\n' +
                'Mit freundlichen Grüßen';
        case 'subcontractorRejectObjection':
            return 'Sehr geehrte Damen und Herren,\n\n' +
                'im Bauprojekt \'%Projektname%\' wurde ein Widerspruch abgelehnt, siehe angehängtes Dokument.\n\n' +
                'Mit freundlichen Grüßen';
        case 'inspection':
            return 'Sehr geehrte Damen und Herren,\n\n'+
                'im Bauprojekt \'%Projektname%\' fand eine Begehung statt, siehe angehängtes Protokoll.\n\n'+
                'Mit freundlichen Grüßen\n' +
                '%Autor%'
        default:
            return 'unknownEmailType'
    }
}