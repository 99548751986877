import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton} from "@bau/material";
import {useNavigate, useParams} from "react-router-dom";
import {getCustomerProjectWithPrefix, translate} from "../utils/utils";


const SubcontractorBundle = () => {

    const navigate = useNavigate();
    const {subcontractorId} = useParams();

    const {customerProjectWithInspection} = getCustomerProjectWithPrefix();


    useEffect(()=>{
        // @ts-ignore
        window.subcontractorBundleUseEffect(subcontractorId);
    },[])


    const goToSubcontractors = ()=>{
        navigate(customerProjectWithInspection+'/subcontractors');
    }


    return <PageContainer newPage={false} headerText={translate("subcontractor.bundle.title")}
                          left={<StyledButton onClick={goToSubcontractors}>{translate("goback")}</StyledButton>}
    />
}

export default SubcontractorBundle;