// Copyright 2014, Open Experience GmbH. All rights reserved.

define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', 'moment', './InspectionPageNewHtml', '../view/ShortcutBaseView',
	'../model/user', '../model/currentProject', '../model/currentInspection', '../model/inspections', '../model/currentDefects', '../model/completions', '../model/subcontractors', '../model/participants', '../model/proxyInfo', '../helper/offlineQueue',
	'../view/Autocomplete', '../view/Popup', '../helper/backboneReactCommunicationUtils', '../lib/exceljs.min', '../lib/xlsx.full.min'
], function (
	$, _, Backbone, moment, Template, ShortcutBaseView,
	user, currentProject, currentInspection, inspections, currentDefects, completions, subcontractors, participants, proxyInfo, offlineQueue,
	Autocomplete, Popup, backboneReactCommunicationUtils, exceljs, xlsx
) {
	'use strict';


	return ShortcutBaseView.extend({

		id: 'inspectionNew',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),
		titleModified: false,

		events: {
			'vclick  .save': 'save',
			'vclick  .dataimport .continue': function (e) {
				var currentTarget = $(e.currentTarget);
				var inspectionId = currentTarget.attr('data-id');
				if(!inspectionId) {
					return;
				}
				currentDefects.resetFilter();
				currentDefects.elementFilter.inspection = inspectionId;
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection());
			},

			'change .unit select': 'selectUnit',
			'change #inspectionType': 'changeType',
			'change #noDate': 'toggleDate',
			'change #subcontractor': 'changeSubcontractor',
			'change #inspectionReference': 'changeReference',
			'change #inspectionDate': 'changeDate',
			'change #inspectionDescription': 'changeDescription',
			'change #inspectionDescription2': 'changeDescription2',

			'click .addParticipant': 'addParticipant',
			'click .addEmailReceiver': 'addEmailReceiver',
			'vclick .removeParticipant': 'removeParticipant',
			'vclick .removeReceiver': 'removeReceiver',
			'change #inspectionParticipants input': 'changeParticipant',
			'focus #subcontractor': function () {
				this.$subcontractor.val('');
			},
			'focus #inspectionReference': function () {
				this.$inspectionReference.val('');
			},
			'focus #inspectionParticipants input': function (e) {
				var $input = $(e.target);
				$input.val(($input.val() || '').replace(/^(\w+).*$/, '$1'));
			},
			'change #inspectionName': function () {
				this.titleModified = true;
			},
			'change #linkToCurrent': function () {
				currentInspection.set('linkToCurrentInspection', this.$linktoCurrent.is(':checked'));
			},
			'change #syncInspection': function () {
				currentInspection.set('syncInspection', this.$syncInspection.is(':checked'));
			},

			'change .importform input': 'uploadArchive',
			'vclick .dataimport button': 'applyImport',


			'change .uploadTopics': 'uploadTopics',

			'vclick .downloadTopics': 'downloadTopics',

			'vclick .addChosenParticipant': function () {
				var participant = participants.findByLabel(this.$newParticipantChoice.val()),
					p = currentInspection.get('participants').slice();
				if (participant) {
					p.push({
						name: participant.get('name'),
						role: participant.get('role'),
						email: participant.get('email'),
						phoneNumber: participant.get('phoneNumber'),
						postalAddress: participant.get('postalAddress')
					});
					currentInspection.set('participants', p);
					this.$newParticipantChoice.val('');
					this.renderParticipantRow(p.length - 1);
					this.$newParticipantDialog.popup('close');
				}
			},

			'vclick .addChosenParticipantsList': function () {
				var participantLists = currentProject.get('participantLists') || [];
				var listName = this.$newParticipantsListChoice.val();
				var participants = [];
				for (var j = 0; j < participantLists.length; j++) {
					if (listName === participantLists[j].name) {
						participants = participantLists[j].participants;
					}
				}

				var p = currentInspection.get('participants').slice();
				if (participants.length !== 0) {
					for (var i = 0; i < participants.length; i++) {
						p.push({
							name: participants[i].name,
							role: participants[i].role,
                            email: participants[i].email,
							phoneNumber: participants[i].phoneNumber,
							postalAddress: participants[i].postalAddress
						});
						currentInspection.set('participants', p);
						p = currentInspection.get('participants').slice();
						this.renderParticipantRow(p.length - 1);
					}
					this.$newParticipantsListChoice.val('');
					this.$newParticipantDialog.popup('close');
				}
			},

			'vclick .addNewParticipant': function () {
				var participant = {
					name: this.$('#inspectionNewParticipantName').val(),
					role: this.$('#inspectionNewParticipantRole').val(),
					email: this.$('#inspectionNewParticipantEmail').val(),
					phoneNumber: this.$('#inspectionNewParticipantPhoneNumber').val(),
					postalAddress: this.$('#inspectionNewParticipantPostalAddress').val()
				}, p = currentInspection.get('participants').slice();
				var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if ((participant.name || participant.role) && (emailRegex.test(participant.email) || _.isEmpty(participant.email))) {
					$.ajax({
						type: 'POST',
						data: JSON.stringify(participant),
						contentType: 'application/json',
						url:  '/onlineBauabnahme/api/participant/' + window.encodeURIComponent(currentProject.id),
						global: false
					}).done(function () {
						participants.fetch();
					});
					p.push(participant);
					currentInspection.set('participants', p);
					this.$('#inspectionNewParticipantName').val('');
					this.$('#inspectionNewParticipantRole').val('');
					this.$('#inspectionNewParticipantEmail').val('');
					this.$('#inspectionNewParticipantPhoneNumber').val('');
					this.$('#inspectionNewParticipantPostalAddress').val('');
					this.renderParticipantRow(p.length - 1);
					this.$newParticipantDialog.popup('close');
				} else {
					var warning = document.getElementById('newParticipantErrorMsg');
					warning.style.color = 'red';
					if (!(participant.name || participant.role)) {
						warning.textContent = user.translate('inspection.new.participant.nonameorrole');
					} else if (!emailRegex.test(participant.email) && !_.isEmpty(participant.email)) {
						warning.textContent = user.translate('inspection.new.participant.invalidemail');
					}
				}
			},

			'vclick .addChosenReceiver': function () {
				var receiver = participants.findByLabel(this.$newReceiverChoice.val()),
					r = currentInspection.get('emailReceivers').slice();
				if (receiver) {
					if (_.isEmpty(receiver.get('email'))) {
						var warning = document.getElementById('receiverChoiceNoEmail');
						warning.textContent = user.translate('inspection.chosen.receiver.noemail');
						warning.style.color = 'red';
					} else {
						r.push({
							name: receiver.get('name'),
							role: receiver.get('role'),
                            email: receiver.get('email'),
							phoneNumber: receiver.get('phoneNumber'),
							postalAddress: receiver.get('postalAddress')
						});
						currentInspection.set('emailReceivers', r);
						this.$newReceiverChoice.val('');
						this.renderEmailReceiversRow(r.length - 1);
						this.$newReceiverDialog.popup('close');
					}
				}
			},

			'vclick .addChosenReceiverList': function () {
				var participantLists = currentProject.get('participantLists');
				var listName = this.$newReceiverListChoice.val();
				var receivers = [];
				for (var i = 0; i < participantLists.length; i++) {
					if (listName === participantLists[i].name) {
						receivers = participantLists[i].participants;
					}
				}

				var r = currentInspection.get('emailReceivers').slice();
				if (receivers.length !== 0) {
					for (var i = 0; i < receivers.length; i++) {
						r.push({
							name: receivers[i].name,
							role: receivers[i].role,
                            email: receivers[i].email,
							phoneNumber: receivers[i].phoneNumber,
							postalAddress: receivers[i].postalAddress
						});
						currentInspection.set('emailReceivers', r);
						r = currentInspection.get('emailReceivers').slice();
						this.renderEmailReceiversRow(r.length - 1);
					}
					this.$newReceiverListChoice.val('');
					this.$newReceiverDialog.popup('close');
				}
			},

			'vclick .addNewReceiver': function () {
				var receiver = {
					name: this.$('#inspectionNewReceiverName').val(),
					role: this.$('#inspectionNewReceiverRole').val(),
                    email: this.$('#inspectionNewReceiverEmail').val(),
                    phoneNumber: this.$('#inspectionNewReceiverPhoneNumber').val(),
                    postalAddress: this.$('#inspectionNewReceiverPostalAddress').val()
				}, r = currentInspection.get('emailReceivers').slice();
				var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (!_.isEmpty(receiver.name || receiver.role) && !_.isEmpty(receiver.email) && emailRegex.test(receiver.email)) {
					$.ajax({
						type: 'POST',
						data: JSON.stringify(receiver),
						contentType: 'application/json',
						url:  '/onlineBauabnahme/api/participant/' + window.encodeURIComponent(currentProject.id),
						global: false
					}).done(function () {
						participants.fetch();
					});
					r.push(receiver);
					currentInspection.set('emailReceivers', r);
					this.$('#inspectionNewReceiverName').val('');
					this.$('#inspectionNewReceiverRole').val('');
					this.$('#inspectionNewReceiverEmail').val('');
                    this.$('#inspectionNewReceiverPhoneNumber').val('');
                    this.$('#inspectionNewReceiverPostalAddress').val('');
					this.renderEmailReceiversRow(r.length - 1);
					this.$newReceiverDialog.popup('close');
				} else {
					var warning = document.getElementById('newReceiverNoEmail');
					warning.style.color = 'red';
					if (_.isEmpty(receiver.email)) {
						warning.textContent = user.translate('inspection.new.receiver.noemail');
					} else if (!emailRegex.test(receiver.email)) {
						warning.textContent = user.translate('inspection.new.receiver.invalidemail');
					} else if (_.isEmpty(receiver.name || receiver.role)) {
						warning.textContent = user.translate('inspection.new.receiver.nonameorrole');
					}
				}
			},
			'change #topics': function () {
				currentInspection.set('topics', this.$topics.is(':checked'));
				this.$topicsList.parent().toggle(this.$topics.is(':checked'));
				this.$topicsList.toggle(this.$topics.is(':checked'));
				this.render();
			},
			'change #topicsToNext': function () {
				currentInspection.set('topicsToNext', this.$topicsToNext.is(':checked'));
			},
			'vclick .edit-mode-delete': function () {
				this.setTopicsEditMode('delete');
			},
			'vclick .edit-mode-move': function () {
				this.setTopicsEditMode('move');
			},
			'vclick .edit-mode-subtopics': function () {
				this.setTopicsEditMode('subtopics');
			},
			'vclick .addtopic': 'addTopicField',
			'vclick .topicfielddeletebutton': 'removeTopicField',
			'vclick .topicfieldmovedownbutton': function (e) {
				this.switchTopicFieldWith(null, e.target.attributes.topicId.value, 1);
			},
			'vclick .topicfieldmoveupbutton': function (e) {
				this.switchTopicFieldWith(null, e.target.attributes.topicId.value, -1);
			},
			'vclick .subtopicfielddeletebutton': 'removeSubtopicField',
			'vclick .subtopicfieldaddbutton': 'addSubtopicField',
			'vclick .subtopicfieldmovedownbutton': function (e) {
				var parentId = e.target.attributes.parentId.value;
				var id = e.target.attributes.topicId.value;
				this.switchTopicFieldWith(parentId, id, 1);
			},
			'vclick .subtopicfieldmoveupbutton': function (e) {
				var parentId = e.target.attributes.parentId.value;
				var id = e.target.attributes.topicId.value;
				this.switchTopicFieldWith(parentId, id, -1);
			},
			'change .topicfield': function (e) {
				var id = e.target.attributes[2].value;
				var value = e.target.value;
				var topicsList = _.map(currentInspection.get('topicslist'), function (topic){
					if(topic.id === id){
						return {...topic, value: value};
					}
					return topic;
				}.bind(this));
				currentInspection.set('topicslist', topicsList);
			},
			'change .subtopicfield': function (e) {
				var parentId = e.target.attributes.parentId.value;
				var id = e.target.attributes.topicId.value;
				var value = e.target.value;
				var topicsList = _.map(currentInspection.get('topicslist'), function (topic) {
					if(topic.id === parentId){
						var subTopics = _.map(topic.subTopics, function (subTopic) {
							if(subTopic.id === id){
								return {...subTopic, value: value};
							}
							return subTopic;
						}.bind(this));
						return {...topic, subTopics: subTopics};
					}
					return topic;
				}.bind(this));
				currentInspection.set('topicslist', topicsList);
			}
		},

		setTopicsEditMode: function (mode) {
			$('.topics-editmode label.current-edit-mode').removeClass('current-edit-mode');

			if (mode === this.topicsEditMode) {
				mode = null;
			}

			if (mode !== null) $('.edit-mode-' + mode).addClass('current-edit-mode');

			$('.topicfielddeletebutton').toggle(mode === 'delete');
			$('.subtopicfielddeletebutton').toggle(mode === 'delete');

			$('.topicfieldmovedownbutton').toggle(mode === 'move');
			$('.topicfieldmoveupbutton').toggle(mode === 'move');
			$('.subtopicfieldmovedownbutton').toggle(mode === 'move');
			$('.subtopicfieldmoveupbutton').toggle(mode === 'move');

			$('.subtopicfieldaddbutton').toggle(mode === 'subtopics');

			this.topicsEditMode = mode;
		},

		removeTopicField: function (e) {
			var group = $(e.target).closest('div.ui-field-contain');
			group.remove();
			var id = e.target.attributes[1].value;
			if(this.$topicsList.children(':not(button, .headline)').length <= 0) {
				this.$topicsList.children('.headline').hide();
				this.$topicsEditMode.hide();
			}
			var list = currentInspection.get('topicslist');
			var index = list.find(function(topic) {
				return topic.id === id;
			}).index;
			var nL = list.filter(function (topic)  {
				return topic.id !== id;
			});
			_.each(nL, function (topic, i) {
				if (parseInt(topic.index) > index) {
					nL[i] = {...topic, index: parseInt(topic.index) - 1};
				}
			});
			currentInspection.set('topicslist', nL);
			this.render();
		},

		addTopicField: function (e, value) {
			var topicList = currentInspection.get('topicslist') ? currentInspection.get('topicslist') : [];

			this.$topicsEditMode.show();

			// value is not null when called from render function with existing topics
			var id = (value && value.id) ? value.id : this.generateUUID();
			var index = (value && value.index) ? value.index : topicList.length + 1;
			var name = value ? value.value : '';

			var textfield = $('<div class="ui-field-contain">').append($('<fieldset data-role="controlgroup" data-type="horizontal">')
				.append($('<span class="topicindex ui-corner-all">' + index.toString() + '</span>'),
					$('<input type="text" class="topicfield ui-corner-all ui-input-text" data-id="' + id + '">').val(name),
					$('<button class="topicfielddeletebutton ui-btn ui-corner-all ui-btn-icon-notext ui-icon-delete" topicId="' + id + '"/>').toggle(this.topicsEditMode === 'delete'),
					$('<button class="topicfieldmovedownbutton ui-btn ui-corner-all ui-btn-icon-notext ui-icon-arrow-d" topicId="' + id + '"/>').toggle(this.topicsEditMode === 'move'),
					$('<button class="topicfieldmoveupbutton ui-btn ui-corner-all ui-btn-icon-notext ui-icon-arrow-u" topicId="' + id + '"/>').toggle(this.topicsEditMode === 'move'),
					$('<button class="subtopicfieldaddbutton ui-btn ui-corner-all ui-btn-icon-notext ui-icon-plus" topicId="' + id + '"/>').toggle(this.topicsEditMode === 'subtopics'),
					$('<div class="subtopicslist ui-field-contain" topicId="' + id + '"/>')
				)
			);

			this.$topicsList.append(textfield);

			if ((value && !value.id) ||!value) {
				var newList = [...topicList];
				var newTopic = {id: id, index: index, value: name, subTopics: []};
				newList.push(newTopic);
				currentInspection.set('topicslist', newList);
			}
		},

		switchTopicFieldWith: function (parentId, id, offset) {
			var list = currentInspection.get('topicslist');

			if (parentId) {
				var parent = _.find(list, function (t) {
					return t.id === parentId;
				});

				if (parent === null) {
					return;
				}
				list = parent.subTopics;
			}

			var currentTopic = _.find(list, function(t) {
				return t.id === id;
			});

			if (parseInt(currentTopic.index) + offset > list.length || parseInt(currentTopic.index) + offset < 1) return;

			var nextTopic = _.find(list, function(t) {
				return parseInt(t.index) === parseInt(currentTopic.index) + offset;
			});

			var newList = _.filter(list, function (t) {
				return (t.index !== currentTopic.index) && (t.index !== nextTopic.index);
			});

			newList.push({...currentTopic, index: nextTopic.index}, {...nextTopic, index: currentTopic.index});

			if (parentId) {
				var newTopics = _.filter(currentInspection.get('topicslist'), function (t) {
					return t.id !== parent.id;
				});
				newTopics.push({...parent, subTopics: newList});
				newList = newTopics;
			}

			currentInspection.set('topicslist', newList);
			this.render();
		},

		searchForTopicId: function (el, id){

			if (el.attributes && el.attributes[1] && el.attributes[1].value === id) {
				return el;
			}

			for (var i = 0; i < el.children.length; i++) {
				var foundElement = this.searchForTopicId(el.children[i], id);
				if (foundElement) {
					return foundElement;
				}
			}

			return null;
		},

		addSubtopicField: function (e, value) {
			var currentList = currentInspection.get('topicslist');
			// value is not null when called from render function with existing subtopics
			var parentId = value ? (value.parentId? value.parentId : value.parentIndex) : e.target.attributes.topicId.value;

			var parentListIndex = (value && value.parentId || !value) ? currentList.findIndex(function (element) {
				return element.id === parentId;
			}): (value && value.parentIndex ? value.parentIndex : 0);

			var parent = currentList[parentListIndex];
			var currentSubtopics = (parent && parent.subTopics) ? [...parent.subTopics] : [];
			var id = (value && value.id) ? value.id : this.generateUUID();
			var index = value && value.index ? value.index : currentSubtopics.length + 1;
			var name = value ? value.value : '';

			var textfield = $('<fieldset data-role="controlgroup" data-type="horizontal">')
				.append($('<span class="subtopicindex ui-corner-all">' + parent.index.toString() + '.' + index.toString() + '</span>'),
					$('<input type="text" class="subtopicfield ui-corner-all ui-input-text" data-id="' + id + '" topicId="' + id + '" parentId="' + (e ? parentId : parent.id) + '"/>').val(name),
					$('<button class="subtopicfielddeletebutton ui-btn ui-corner-all ui-btn-icon-notext ui-icon-delete" topicId="' + id + '" parentId="' + (e ? parentId : parent.id) + '"/>').toggle(this.topicsEditMode === 'delete'),
					$('<button class="subtopicfieldmovedownbutton ui-btn ui-corner-all ui-btn-icon-notext ui-icon-arrow-d" topicId="' + id + '" parentId="' + (e ? parentId : parent.id) + '"/>').toggle(this.topicsEditMode === 'move'),
					$('<button class="subtopicfieldmoveupbutton ui-btn ui-corner-all ui-btn-icon-notext ui-icon-arrow-u" topicId="' + id + '" parentId="' + (e ? parentId : parent.id) + '"/>').toggle(this.topicsEditMode === 'move')
				);

			$('div.subtopicslist[topicId="' + (e ? parentId : parent.id) + '"]').append(textfield);

			if (!value || (value && value.parentIndex)) {
				var newTopic = {id: id, index: index, value: name};
				currentSubtopics.push(newTopic);
				var newList = [...currentList];
				newList[parentListIndex] = {...parent, subTopics: currentSubtopics};
				currentInspection.set('topicslist', newList);
			}
		},

		removeSubtopicField: function (e) {
			e.preventDefault();
			var currentList = currentInspection.get('topicslist');
			if (!currentList) {
				return;
			}
			var parentId = e.target.attributes.parentId.value;
			var id = e.target.attributes.topicId.value;

			var parentIndex = currentList.findIndex(function (element) { return element.id === parentId;});
			if (parentIndex === -1) {
				return;
			}

			var parent = currentList[parentIndex];
			var index = (parent.subTopics ? parent.subTopics : []).find(function (subtopic) {
				return subtopic.id === id;
			}).index;

			var newSubTopicList = [];
			_.each(parent.subTopics, function (subtopic) {
				if (parseInt(subtopic.index) > index) {
					newSubTopicList.push({...subtopic, index: parseInt(subtopic.index) - 1});
				} else if (subtopic.id !== id) {
					newSubTopicList.push(subtopic);
				}
			}.bind(this));

			var newTopicList = _.filter(currentList, function (list, index) {
				return index !== parentIndex;
			});
			newTopicList.push({...parent, subTopics: newSubTopicList});

			currentInspection.set('topicslist', newTopicList);
			this.render();
		},

		//Copied from https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/8809472#8809472 :)
		generateUUID: function () { // Public Domain/MIT
			var d = new Date().getTime();//Timestamp
			var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = Math.random() * 16;//random number between 0 and 16
				if (d > 0) {//Use timestamp until depleted
					r = (d + r) % 16 | 0;
					d = Math.floor(d / 16);
				} else {//Use microseconds since page-load if supported
					r = (d2 + r) % 16 | 0;
					d2 = Math.floor(d2 / 16);
				}
				return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
			});
		},

		initialize: function () {
			this.resize = _.bind(this.resize, this);
			this.changeType = _.bind(this.changeType, this);
			this.topicsEditMode = null;

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);

			this.$unit = this.$('.unit');
			this.$date = this.$('#inspectionDate');
			this.$type = this.$('#inspectionType');
			this.$name = this.$('#inspectionName');
			this.$inspectionParticipants = this.$('#inspectionParticipants');
			this.$participantsTable = this.$inspectionParticipants.children('table');
			this.$emailReceivers = this.$('#inspectionEmailReceivers');
			this.$emailReceiversTable = this.$emailReceivers.children('table');
			this.$noDate = this.$('#noDate');
			this.$subcontractor = this.$('#subcontractor');
			this.$inspectionDetails = this.$('.inspection-details');
			this.$inspectionReference = this.$('#inspectionReference');
			this.$description = this.$('#inspectionDescription');
			this.$description2 = this.$('#inspectionDescription2');
			this.$dataimport = this.$('.dataimport');
			this.$importMessages = this.$('.dataimport .messages');
			this.$importPreview = this.$('.dataimport .preview');
			this.$previousPage = this.$('.previousPage');
			this.$newParticipantDialog = this.$('.newParticipantDialog');
			this.$newParticipantChoice = this.$('#inspectionNewParticipantChoice');
			this.$newParticipantsListChoice = this.$('#inspectionNewParticipantsListChoice');
			this.$newReceiverDialog = this.$('.newReceiverDialog');
			this.$newReceiverChoice = this.$('#inspectionNewReceiverChoice');
			this.$newReceiverListChoice = this.$('#inspectionNewReceiverListChoice');
			this.$linktoCurrent = this.$('#linkToCurrent');
			this.$syncInspectionContainer = this.$('.syncInspectionContainer');
			this.$syncInspection = this.$('#syncInspection');
			this.$topics = this.$('#topics');
			// this.$addTopicButton = this.$('.addtopic');
			this.$topicsEditMode = this.$('.topics-editmode');
			this.$topicsList = this.$('.topicslist');
			this.$topicsToNext = this.$('#topicsToNext');

			new Autocomplete({
				input: this.$subcontractor,
				lookup: completions.subcontractorLookup,
				toggleButton: true
			}).render();
			new Autocomplete({
				input: this.$inspectionReference,
				lookup: completions.inspectionLookup,
				toggleButton: true
			}).render();
			new Autocomplete({
				input: this.$('#inspectionNewParticipantChoice'),
				lookup: _.bind(participants.lookup, participants),
				toggleButton: true
			}).render();
			new Autocomplete({
				input: this.$('#inspectionNewParticipantsListChoice'),
				lookup: function (str) {
					var result = [];
					var participantLists = currentProject.get('participantLists');
					if (participantLists) {
						for (var i = 0; i < participantLists.length; i++) {
							if (str) {
								if (participantLists[i].name.toUpperCase().indexOf(str.toUpperCase()) > -1) {
									result.push(participantLists[i].name);
								}
							} else {
								result.push(participantLists[i].name);
							}
						}
					}
					return result;
				},
				toggleButton: true
			}).render();
			new Autocomplete({
				input: this.$('#inspectionNewReceiverChoice'),
				lookup: _.bind(participants.lookup, participants),
				toggleButton: true
			}).render();
			new Autocomplete({
				input: this.$('#inspectionNewReceiverListChoice'),
				lookup: function (str) {
					var result = [];
					var participantLists = currentProject.get('participantLists');
					if (participantLists) {
						for (var i = 0; i < participantLists.length; i++) {
							if (str) {
								if (participantLists[i].name.toUpperCase().indexOf(str.toUpperCase()) > -1) {
									result.push(participantLists[i].name);
								}
							} else {
								result.push(participantLists[i].name);
							}
						}
					}
					return result;
				},
				toggleButton: true
			}).render();

			this.$el.on('pageshow', this.resize);
			this.$el.on('pageshow', this.changeType);
			$(window).on('throttledresize', this.resize);
			// $('#inspection-new-page-header').on('click', '.save', function () {
			// 	console.log("trigger save")
			// 	// this.save();
			// }.bind(this));
			this.$topicsEditMode.hide();
		},

		render: function () {
			$('.save').off('click');
			$('.save').on('click', function (e) {
				this.save(e);
			}.bind(this));
			var previousLabel;
			switch (this.previousPage) {
				case 'inspection': previousLabel = user.translate('inspection.new.backtoinspection'); break;
				default: previousLabel = user.translate('inspection.new.backtoinspections'); break;
			}

			this.$linktoCurrent.prop('checked', currentInspection.get('linkToCurrentInspection')).checkboxradio().checkboxradio('refresh');
			this.$topics.prop('checked', !!currentInspection.get('topics')).checkboxradio().checkboxradio('refresh');
			this.$topicsToNext.prop('checked', !!currentInspection.get('topicsToNext')).checkboxradio().checkboxradio('refresh');
			this.$topicsList.parent().toggle(!!currentInspection.get('topics'));
			this.$topicsList.toggle(!!currentInspection.get('topics'));
			this.$topicsList.children(':not(button, .headline)').remove();
			this.$topicsList.children('.headline').hide();
			if (currentInspection.get('topicslist')) {
				var topicslist = currentInspection.get('topicslist').filter(function(item){
					return item !== null;
				});
				if (topicslist && topicslist.length > 0) {
					var list = [];
					_.each(topicslist, function (v) {
						list.push(v);
					});
					list = _.sortBy(list, function (o) { return parseInt(o.index); });
					_.each(list, function (v) {
						this.addTopicField(null, v);
						var stList = [];
						_.each(v.subTopics, function(sv) {
							var newSV = {...sv, parentId: v.id};
							stList.push(newSV);
						});
						stList = _.sortBy(stList, function (o) { return parseInt(o.index); });

						_.each(stList, function(sv) {
							this.addSubtopicField(null, sv);
						}.bind(this));
					}.bind(this));
				} else {
					this.$topicsEditMode.hide();
				}
			}

			var showSync =
				user.get('customer') === 'ox' ||
				user.get('customer') === 'ox2' ||
				(user.get('customer') === 'obg_gruppe' && currentProject.get('id') === 'LQ') ||
				(user.get('customer') === 'gww' && currentProject.get('id') === 'BV_433_02') ||
				(user.get('customer') === 'implenia_muenchen' && currentProject.get('id') === 'PROJEKT_ATLAS') ||
				(user.get('customer') === 'bv_atlas' && currentProject.get('id') === 'ATLAS') ||
					(user.get('customer') === 'rom' && currentProject.get('id') === 'PROJEKT_ATLAS') ||
					(user.get('customer') === 'zechbau' && currentProject.get('id') === '2010528052') ||
					(user.get('customer') === 'zechbau_dlh_ag' && currentProject.get('id') === 'DLH') ||
					(user.get('customer') === 'htc' && currentProject.get('id') === 'DHH') ||
					(user.get('customer') === 'zechbau' && currentProject.get('id') === 'DLH_300');
			if (!showSync) {
				this.$syncInspectionContainer.remove();
			}
			this.$syncInspection.prop('checked', !!currentInspection.get('syncInspection')).checkboxradio().checkboxradio('refresh');

			this.renderUnit();

			this.$noDate.prop('checked', currentInspection.get('date') == null);
			this.toggleDate();

			this.$type.empty().append($('<option>').text(user.translate('inspection.new.type')));

			var visibleInspectionTypes = inspections.getVisibleInspectionTypes();
			_.each(visibleInspectionTypes, function (i) {
				$('<option>')
					.val(i.id).text(i.extendedName)
					.appendTo(this.$type);
			}, this);
			if (user.get('dataImport') /*&& !user.isPrincipal()*/ && !user.isSubcontractor() && !user.isReadonly()) {
				$('<option>').val('import').text(user.translate('inspection.new.import')).appendTo(this.$type);
			}
			if (currentInspection.get('type')) { //TODO remove
				this.$type.val(currentInspection.get('type'));
			}
			this.$type.selectmenu().selectmenu('refresh');

			$('.save').toggle(this.getType() != null).text(currentInspection.isNew() ? user.translate('inspection.new.start') : user.translate('inspection.new.continue'));
			this.renderParticipants();
			this.updateName();
			this.$('.dataimport .doImport').prop('disabled', true).show();
			this.$('.dataimport .continue').hide();

		},

		shortcuts: {
			'focusinput1': function () {
				$('#inspectionNew input#inspectionName').focus();
			}
		},

		renderUnit: function () {

			var cg = this.$unit.find('.ui-controlgroup'),
				container = cg.controlgroup('container');
			container.find('select, .ui-btn').remove();

			var tree = currentProject.get('unitTree');
			if (!tree.children) {
				this.$unit.hide();
				return;
			}
			this.$unit.show();

			var parts = (currentInspection.get('unit') || '').split('-');

			var choices = [];
			for (var i = 0; i <= parts.length && tree.children; i++) {
				var choice = $('<select>').attr('disabled', currentInspection.get('finished') ? true : false);
				$('<option>').text(currentProject.getStructureKey(i)).val(tree.path).appendTo(choice);
				_.each(_.compact(_.values(tree.children)), function (child) {
					$('<option>')
						.text(child.name)
						.val(child.path)
						.appendTo(choice);
				}, this);
				choices.push(choice);
				if (i < parts.length && parts[i]) {
					tree = tree.children[parts[i]];
					choice.val(tree.path);
				} else {
					break;
				}
			}

			for (var i = choices.length - 1; i >= 0; i--) {
				choices[i]
					.prependTo(container)
					.selectmenu().parent().toggleClass('disabled', !!currentInspection.get('finished'));
			}
			cg.controlgroup('refresh');

			this.resizeUnit();
		},

		renderParticipants: function () {
			var participants = currentInspection.get('participants');
			this.$participantsTable.empty().append('<tr class="lastRow"><td colspan="3"><button class="addParticipant ui-btn ui-corner-all ui-btn-inline ui-btn-active">' + user.translate('inspection.new.addparticipant') + '</button></td></tr>');
			for (var i = 0; i < participants.length; i++) {
				this.renderParticipantRow(i);
			}

			var emailReceivers = currentInspection.get('emailReceivers');
			this.$emailReceiversTable.empty().append('<tr class="lastRow"><td colspan="3"><button class="addEmailReceiver ui-btn ui-corner-all ui-btn-inline ui-btn-active">' + user.translate('inspection.new.addemailreceiver') + '</button></td></tr>');
			for (var i = 0; i < emailReceivers.length; i++) {
				this.renderEmailReceiversRow(i);
			}
		},

		renderParticipantRow: function (i) {
			var $row = $(
				this.$participantsTable.find('tr[data-index="' + i + '"]').get(0) ||
				$('<tr>').attr('data-index', i).insertBefore(this.$participantsTable.find('tr.lastRow'))
			).empty();
			var participant = currentInspection.get('participants')[i];
			var parts = [];
			if (participant.role) {
				parts.push(participant.role);
			}
			if (participant.name) {
				parts.push(participant.name);
			}
			var value = parts.join(', ');
			if (participant.email) {
				value += ' (' + participant.email + ')';
			}
			$('<td>').text(value).appendTo($row);
			$('<button class="removeParticipant ui-btn ui-btn-icon-notext ui-icon-delete ui-btn-active ui-btn-corner-all"></button>').appendTo($('<td>').appendTo($row));
			//$('<input class="role" type="text">').val(participant.role).appendTo($('<td>').appendTo($row));
			//var $name = $('<input class="name" type="text">').appendTo($('<td>').appendTo($row));
			//new Autocomplete({
			//	input: $name,
			//	lookup: _.bind(participants.lookup, participants),
			//	toggleButton: true
			//}).render();
			//$name.val(parts.join(', '));
			//$('<button class="removeParticipant ui-btn ui-btn-icon-notext ui-icon-delete ui-btn-active ui-btn-corner-all"></button>').appendTo($('<td>').appendTo($row));
			$row.enhanceWithin();
		},

		renderEmailReceiversRow: function (i) {
			var $row = $(
				this.$emailReceivers.find('tr[data-index="' + i + '"]').get(0) ||
				$('<tr>').attr('data-index', i).insertBefore(this.$emailReceivers.find('tr.lastRow'))
			).empty();
			var receiver = currentInspection.get('emailReceivers')[i];
			var parts = [];
			if (receiver.role) {
				parts.push(receiver.role);
			}
			if (receiver.name) {
				parts.push(receiver.name);
			}
			var $warning;
			var value = parts.join(', ');
			if (receiver.email) {
				value += ' (' + receiver.email + ')';
			} else {
				$warning = $('<span>').text(user.translate('inspection.chosen.receiver.noemail')).css({ 'color': 'red', 'margin-left': '5px' });
			}
			$('<td>').text(value).append($warning).appendTo($row);
			$('<button class="removeReceiver ui-btn ui-btn-icon-notext ui-icon-delete ui-btn-active ui-btn-corner-all"></button>').appendTo($('<td>').appendTo($row));

			$row.enhanceWithin();
		},

		//clearParticipants: function () {
		//	currentInspection.set('participants', []);
		//	this.$participantsTable.find('tr[data-index]').remove();
		//},

		addParticipant: function () {
			this.$newParticipantDialog.show().popup('open');
			var warning = document.getElementById('newParticipantErrorMsg');
			warning.textContent = '';
		},

		addEmailReceiver: function () {
			this.$newReceiverDialog.show().popup('open');
			var warning = document.getElementById('newReceiverNoEmail');
			warning.textContent = '';
		},

		removeParticipant: function (e) {
			var i = e;
			if (!_.isNumber(e)) {
				var $row = $(e.currentTarget).parents('tr');
				i = parseInt($row.attr('data-index'));
				e.preventDefault();
				e.stopPropagation();
			}
			var participants = currentInspection.get('participants').slice();
			participants.splice(i, 1);
			currentInspection.set('participants', participants);
			this.$participantsTable.find('tr[data-index="' + i + '"]').remove();
			for (var j = i + 1; j <= participants.length; j++) {
				this.$participantsTable.find('tr[data-index="' + j + '"]').attr('data-index', j - 1);
			}
		},

		removeReceiver: function (e) {
			var i = e;
			if (!_.isNumber(e)) {
				var $row = $(e.currentTarget).parents('tr');
				i = parseInt($row.attr('data-index'));
				e.preventDefault();
				e.stopPropagation();
			}
			var receivers = currentInspection.get('emailReceivers').slice();
			receivers.splice(i, 1);
			currentInspection.set('emailReceivers', receivers);
			this.$emailReceiversTable.find('tr[data-index="' + i + '"]').remove();
			for (var j = i + 1; j <= receivers.length; j++) {
				this.$emailReceivers.find('tr[data-index="' + j + '"]').attr('data-index', j - 1);
			}
		},

		changeParticipant: function (e) {
			var $row = $(e.currentTarget).parents('tr');
			var i = parseInt($row.attr('data-index'));
			var inspectionParticipants = currentInspection.get('participants');
			var val = $row.find('input').val();
			var participant = participants.findByLabel(val);
			if (!participant) {
				participant = {
					name: val
				};
			} else {
				participant = participant.toJSON();
			}
			//$row.find('input, select').each(function (index, el) {
			//	var $el = $(el);
			//	if ($el.is('.role')) {
			//		participant.role = $el.val();
			//	} else if ($el.is('.name')) {
			//		participant.name = $el.val();
			//	}
			//});
			inspectionParticipants[i] = participant;
		},

		changeType: function (e) {
			this.$dataimport.hide();
			this.$inspectionDetails.hide();
			this.$inspectionParticipants.hide();
			this.$emailReceivers.hide();

			var type = this.getType();
			if (!type) {
				return;
			}
			currentInspection.set('type', type);

			if (type === 'import') {
				this.$dataimport.show();
				this.resizeImport();
				return;
			}
			$('.save').show();
			this.$inspectionDetails.show()
				.children().toggle(true);
			this.$inspectionParticipants.show();
			this.$emailReceivers.show();

			var inspectionType = _.find(user.get('inspections'), { id: type });
			_.each(inspectionType.fields, function (value, key) {
				switch (key) {
					case 'inspection': this.$inspectionReference.parent().toggle(value); break;
					case 'location': this.$unit.toggle(value); break;
					case 'subcontractor': this.$subcontractor.parent().toggle(value); break;
					case 'descrPlaceholder': this.$description.attr('placeholder', value != null ? value : '').parent().toggle(!!value && currentInspection.isNew()); break;
					case 'descrPlaceholder2': this.$description2.attr('placeholder', value != null ? value : '').parent().toggle(!!value && currentInspection.isNew()); break;
					case 'topics': this.$topics.parent().toggle(value); this.$topicsList.parent().toggle(value && currentInspection.get('topics')); this.$topicsList.toggle(value && currentInspection.get('topics')); this.$topicsToNext.parent().toggle(value); if (value) this.newTopicsInspection(e); break;
				}
			}, this);
			if (currentInspection.isNew()) {
				//this.clearParticipants();
				//_.each(inspectionType.participants, function (participant) {
				//	this.addParticipant(participant.name, '');
				//}, this);
			}

			this.resize();
			this.updateName();
		},

		newTopicsInspection: function (e) {
			if (!e || e.type !== 'change') return;
			currentInspection.set('topics', true).set('topicsToNext', true);
			this.$topics.prop('checked', true).checkboxradio('refresh');
			this.$topicsList.parent().toggle(true);
			this.$topicsList.toggle(true);
			currentInspection.set('topicslist', []);
			this.$topicsToNext.prop('checked', true).checkboxradio('refresh');
			var typename = currentInspection.get('type');
			var currentInspectionDate = !currentInspection.get('date') ? new Date() : new Date(currentInspection.get('date'));
			var inspection = null;
			_.each(inspections.models, function (i) {
				if (inspection === null && typename === i.get('type') && !!i.get('topics') && i.get('id') !== currentInspection.get('id') && currentInspectionDate >= new Date(i.get('date'))) {
					inspection = i;
				}
			});
			if (inspection !== null && !!inspection.get('topicsToNext')) {
				this.$topicsList.children(':not(button)').remove();
				currentInspection.set('topicslist', inspection.get('topicslist'));
				this.lastInspectionForTopics = inspection;
				this.render();
			}
		},

		addOpenDefectsFromPreviousInspection: function (inspection) {
			currentDefects.resetFilter();
			currentDefects.elementFilter.inspection = inspection.get('id');
			currentDefects.elementFilter.status = 'pending';
			var defectList = currentDefects.filtered();
			_.each(defectList, function (defect) {
				var inspections = [...defect.get('inspections')];
				inspections.push(currentInspection.get('id'));
				defect.set('inspections', inspections);
				if (!defect.get('initialInspectionDate')) {
					defect.set('initialInspectionDate', inspection.get('date'));
				}
				defect.save();
			});
		},

		getType: function () {
			var type = this.$type.val();
			return type !== this.$type.children().first().val() ? type : null;
		},

		updateName: function () {
			var type = this.getType();
			if (type != null && currentInspection.isNew() && !this.titleModified) {
				this.$name.val(this.generateName(type));
			} else if (!currentInspection.isNew() && !this.titleModified) {
				this.$name.val(currentInspection.get('title'));
			}
		},

		selectUnit: function (e) {
			currentInspection.set('unit', $(e.target).val());
			this.renderUnit();
			this.updateName();
		},

		changeSubcontractor: function () {
			var subcontractor = this.getSubcontractor();
			currentInspection.set('subcontractor', subcontractor ? subcontractor.id : null);
			this.updateName();
		},

		changeReference: function () {
			var inspection = _.find(user.get('inspections'), { id: this.getType() });
			var insp = this.getInspection();
			if (inspection.fields.inspection && insp) {
				currentInspection.set('inspection', insp.id);
			}
			this.updateName();
		},

		changeDate: function () {
			currentInspection.set('date', this.$date.val());
			this.updateName();
		},

		changeDescription: function () {
			currentInspection.set('descr', this.$description.val());
			this.updateName();
		},

		changeDescription2: function () {
			currentInspection.set('descr2', this.$description2.val());
		},

		toggleDate: function () {
			if (this.$noDate.is(':checked')) {
				this.$date.val(null).parent().detach();
			} else {
				var date = currentInspection.get('date') || moment().format('YYYY-MM-DD');
				this.$noDate.parent().parent().prepend(this.$date.val(date).parent());
			}
			this.$noDate.checkboxradio('refresh');
		},

		remove: function () {
			$(window).off('throttledresize', this.resize);
			Backbone.View.prototype.remove.apply(this, arguments);
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active') && !self.$el.hasClass('hide-page')) {
					if (self.$('.unit').is(':visible')) {
						self.resizeUnit();
					}
					if (self.$dataimport.is(':visible')) {
						self.resizeImport();
					}
				}
			}, this);
		},

		resizeUnit: function () {
			var checkboxesWidth = 0;
			this.$('.unit .ui-checkbox').each(function () {
				checkboxesWidth += Math.floor($(this).width());
			});
			var availWidth = this.$unit.find('.ui-controlgroup-controls').width() - checkboxesWidth,
				locationChoice = this.$('.unit .ui-select, .unit button');
			locationChoice.each(function () {
				$(this).css('width', availWidth / locationChoice.length);
			});
		},

		resizeImport: function () {
			this.$importPreview.hide();
			var last = this.$('.ui-content > *:visible').eq(-1);
			var pageOffset = parseInt(this.$el.css('padding-bottom'));
			var height = Math.floor(this.$el.innerHeight() - last.position().top - last.outerHeight() - pageOffset - 18);
			this.$importPreview.height(height).show();
		},

		generateName: function (type) {
			//			var date = currentInspection.get('date') || moment().format('L');
			var unit = currentInspection.get('unit');
			var subcontractor = this.getSubcontractor();
			var description = this.$description.val();
			var inspectionType = _.findWhere(user.get('inspections'), { id: type });
			var inspectionRef = this.$inspectionReference.val();
			return inspectionType.name +
				(inspectionType.fields.unit && unit ? ' in ' + currentProject.getUnitTitle(unit) : '') +
				(inspectionType.fields.inspection && inspectionRef ? ' bzgl. Begehung ' + inspectionRef : '') +
				(inspectionType.fields.subcontractor && subcontractor ? ' für ' + subcontractor.getLabel() : '') +
				(description ? ', ' + description : '');
		},

		inputAttributes: function () {
			var type = this.$type.val();

			var result = {
				project: currentProject.id,
				//				archive:  this.archive.is(':checked'),
				type: type,
				title: this.$name.val(),
				participants: currentInspection.get('participants'),
				linkToCurrent: this.$linktoCurrent.val()
			};

			var inspection = _.find(user.get('inspections'), { id: type });
			if (inspection && inspection.fields) {
				_.each(inspection.fields, function (value, key) {
					switch (key) {
						case 'location':
							if (value) {
								result.unit = this.$unit.find('select').last().val();
							}
							break;
						case 'descrPlaceholder':
							if (value != null) {
								result.descr = this.$description.val();
							}
							break;
						case 'descrPlaceholder2':
							if (value != null) {
								result.descr2 = this.$description2.val();
							}
							break;
					}
				}, this);
			}

			var date = this.$date.val();
			if (date) {
				result.date = date;
			}
			return result;
		},

		save: function (e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			var inputAttributes = this.inputAttributes();
			var error = false;

			if (this.$topics.is(':checked')) {
				_.each(this.$topicsList.find('.topicfield, .subtopicfield'), function (e) {
					if ($(e).data('status') && $(e).data('status') === 'error') error = user.translate('inspection.topics.duplicated.error');
				});
			} else if (currentInspection.get('topicslist')) {
				currentInspection.set('topicslist', []);
			}

			if (!inputAttributes.type) {
				error = user.translate('inspection.new.notype');
			} else {
				var inspection = _.find(user.get('inspections'), { id: inputAttributes.type });
				_.each(inspection.fields, function (value, key) {
					switch (key) {
						case 'inspection':
							if (value && !currentInspection.get('inspection')) {
								error = user.translate('inspection.new.noref');
							}
							break;
						case 'subcontractor':
							if (value && !currentInspection.get('subcontractor')) {
								error = user.translate('inspection.new.nosubcontractor');
							}
							break;
					}
				}, this);
			}

			if (error) {
				alertPopup(error);
				return;
			}

			$.mobile.loading('show');
			var deferred;
			if (proxyInfo.get('proxyMode')) {
				if (currentInspection.isNew()) {
					currentInspection.set('id', currentInspection.generateOfflineId());
				}
				deferred = $.Deferred().resolve();

				//
				currentInspection.save(inputAttributes)
					.always(function () {
						deferred.resolve();
					});
			} else {
				deferred = currentInspection.save(inputAttributes)
					.then(function () { return inspections.fetch(); });
			}
			deferred
				.then(_.bind(function () {
					if (this.lastInspectionForTopics != null) {
						this.addOpenDefectsFromPreviousInspection(this.lastInspectionForTopics);
						this.lastInspectionForTopics = null;
					}
					var deferred = $.Deferred(),
						inspection = inspections.get(currentInspection.get('inspection'));
					if (inspection) {
						$.mobile.loading('hide');
						var popup = new Popup({
							header: user.translate('popup.title'),
							message: user.translate('inspection.new.assigndefects'),
							buttons: ['yes', 'no']
						}).render();
						popup.on('confirm', _.bind(function () {
							var tasks = [];
							_.each(currentDefects.filter(function (defect) {
								return defect.get('external') && _.contains(defect.get('inspections'), inspection.id);
							}), function (defect) {
								tasks.push(defect.save({
									inspections: _.union(defect.get('inspections'), [currentInspection.id])
								}));
							}, this);
							$.when(tasks).always(function () {
								deferred.resolve();
							});
						}, this));
						popup.on('cancel', function () {
							deferred.resolve();
						});
					} else {
						deferred.resolve();
					}
					return deferred.promise();
				}, this))
				.done(_.bind(function () {
					currentDefects.resetFilter();
					currentDefects.elementFilter.inspection = currentInspection.id;
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection())
				}, this))
                .fail(_.bind(function () {
	                var deferred = $.Deferred();
					currentDefects.resetFilter();
					currentDefects.elementFilter.inspection = currentInspection.id;
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection())
                    if (proxyInfo.get('proxyMode')) {
    	                console.log('SOMETHING FAILED!');
                        // TODO: need to check whether inspection already exists?!

                        // make everything for new inspection, that `currentInspection.save()` would save and some stuff that
                        //.		`inspections.fetch()` would return

                        var offlineId = currentInspection.generateOfflineId();

                        inspections.add(_.extend(inputAttributes, {'id': offlineId}));
                        currentInspection.clear({silent: true});
                        currentInspection.set(_.clone(inspections.get({'id': offlineId}).attributes));

                        // TODO fehlerstatus rauslesen, ähnlich wie interactiveSync. 502-504 als trigger für die offlineQueue???

                        offlineQueue.sync.apply(offlineQueue, arguments);

                        deferred.resolve();
                        return deferred.promise();
                    } else {
                        deferred.reject();
                        return deferred.promise();
                    }
                }, this))
				.always(function () {
					$.mobile.loading('hide');
				});
		},

		getSubcontractor: function () {
			var subcontractorLabel = this.$subcontractor.val();
			var subcontractor = subcontractors.find(function (model) { return model.getLabel() === subcontractorLabel; });
			return subcontractor ? subcontractor : null;
		},

		getInspection: function () {
			var inspectionTitle = this.$inspectionReference.val();
			var inspection = inspections.find(function (model) { return model.getLabel() == inspectionTitle; });
			return inspection ? inspection : null;
		},

		uploadArchive: function () {
			$.mobile.loading('show');
			var data = new FormData();
			data.append('project', currentProject.id);
			data.append('file', this.$('.importform input')[0].files[0]);
			$.ajax({
				url:  '/onlineBauabnahme/api/import',
				type: 'POST',
				data: data,
				contentType: false,
				processData: false
			})
				.done(_.bind(function (result) {
					this.$('.dataimport .doImport').prop('disabled', false);
					this.renderImport(result);
				}, this))
				.fail(_.bind(function () {
				}, this))
				.always(_.bind(function () {
					this.$('.importform')[0].reset();
					$.mobile.loading('hide');
				}, this));
		},

		uploadTopics: function () {

			var reader = new FileReader();
			reader.onload = function(e) {
				var data = new Uint8Array(e.target.result);
				var workbook = xlsx.read(data, { type: 'array' });

				var excelJSWorkbook = new exceljs.Workbook();

				workbook.SheetNames.forEach(function(sheetName) {
					var sheet = workbook.Sheets[sheetName];
					var json = xlsx.utils.sheet_to_json(sheet, { header: 1 });
					var newSheet = excelJSWorkbook.addWorksheet(sheetName);

					json.forEach(function(row, rowIndex) {
						newSheet.addRow(row);
					});
				});
				this.$topicsList.empty();
				currentInspection.set('topicslist', []);
				var output = '';
				excelJSWorkbook.eachSheet(function(worksheet, sheetId) {
					worksheet.eachRow(function(row, rowNumber) {
						console.log(row.values);
						if(!isNaN(row.values[1]) && row.values[2]){
							if(row.values[1].toString().indexOf('.') === -1){
								var topic = {
									id: null,
									//index: row.values[1],
									value: row.values[2]};
								this.addTopicField(null, topic);
							}else{
								var subtopic = {
									id: null,
									parentIndex: row.values[1].toString().split('.')[0] - 1,
									//index: row.values[1].toString().split('.')[1] - 1,
									value: row.values[2]
								};
								this.addSubtopicField(null, subtopic);
							}
						}
					}.bind(this));
				}.bind(this));
			}.bind(this);
			reader.readAsArrayBuffer(this.$('.uploadTopics')[0].files[0]);
		},

		downloadTopics: function() {
            var workbook = new exceljs.Workbook();
            var worksheet = workbook.addWorksheet('Export');
			currentInspection.get('topicslist').forEach((topic) => {
				worksheet.addRow([topic.index, topic.value]);
				topic.subTopics.forEach((subtopic) => {
					worksheet.addRow([topic.index + "." + subtopic.index, subtopic.value]);
				})
			})

			// Generate Excel file and prompt download
			workbook.xlsx.writeBuffer().then(function(buffer) {
				var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				var url = URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = 'topics_export.xlsx';
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				URL.revokeObjectURL(url);
			}).catch(function(error) {
				console.error('Error generating Excel file:', error);
			});
		},

		applyImport: function () {
			$.mobile.loading('show');
			var data = new FormData();
			data.append('project', currentProject.id);
			data.append('doImport', 1);
			var result;
			$.ajax({
				url:  '/onlineBauabnahme/api/import',
				type: 'POST',
				data: data,
				contentType: false,
				processData: false,
				success: function (r) {
					result = r;
				}
			})
				.then(function () { return inspections.fetch(); })
				.then(function () { return currentDefects.fetch(); })
				.done(_.bind(function () {
					this.renderImport(result);
					$.mobile.loading('hide');
				}, this));
		},

		renderImport: function (result) {
			this.$importMessages
				.empty()
				.toggle(result.messages.length > 0);
			_.each(result.messages, function (message) {
				$('<li>').text(message).appendTo(this.$importMessages);
			}, this);
			this.$importPreview.empty();
			var $table = $('<table>').appendTo(this.$importPreview);
			_.each(result.importRows, function (importRow, index) {
				var $tr = $('<tr>').appendTo($table);
				$tr.toggleClass('success', importRow.success);
				$tr.toggleClass('warn', importRow.warn);
				var $importStatus = $(index == 0 ? '<th>' : '<td>').appendTo($tr);
				if (index == 0) {
					$importStatus.text('Status');
				}
				if (importRow.success) {
					$importStatus.text('OK');
				}
				if (importRow.warn) $importStatus.text(user.translate('defects.import.rowIgnored')).addClass('warn');
				_.each(importRow.importCells, function (importCell) {
					var $td = $(index == 0 ? '<th>' : '<td>').appendTo($tr);
					$td.text(importCell.value !== null ? importCell.value : '');
					$td.toggleClass('recognized', importCell.recognized === true);
					$td.toggleClass('unrecognized', importCell.recognized === false);
					if (importRow.warn && importCell.value) {
						$td.toggleClass('ignorewarn', importCell.warn);
					} else {
						$td.toggleClass('warn', importCell.warn);
					}
				}, this);
			}, this);
			this.resizeImport();
			if (result.inspection && result.inspection !== null) {
				this.$('.dataimport .doImport').hide();
				this.$('.dataimport .continue').attr({
					'data-id': result.inspection,
				}).show();
			}
		}

	});

});
